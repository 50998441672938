import { DateToSecondsInput } from '../../../commons/helpers/functions';
import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  FormControl,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import {
  convertFormattedTimeToSeconds,
  removeDirtyInputs,
  secondsInputToDate,
} from 'src/app/commons/helpers/functions';
import {
  TranslationsLibService,
  TerminalDataModel,
} from '@nutricontrol/app360-shared';
import { ConfigurableService } from '../configurable.service';
import {
  ConfigurableGroupModel,
  ConfigurableProgramModel,
  ConfigurableStatesModel,
} from '../configurable.model';
import { EquipmentsLibService } from 'src/app/services/libraries/equipments-lib.service';
import Swal from 'sweetalert2';
import { map, startWith } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { HelpersLibService } from 'src/app/services/libraries/helpers-lib.service';
import { Breadcrumb } from 'src/app/farming/irrigation/shared/shared.model';
import { environment } from 'src/environments/environment';
import { NavigationPreferencesLibService } from '../../../services/libraries/navigation-preferences-lib.service';

@Component({
  selector: 'app-programs',
  templateUrl: './programs.component.html',
  styleUrls: ['./programs.component.scss'],
})
export class ProgramsComponent implements OnInit, OnDestroy {
  programForm: UntypedFormGroup;
  terminalVid: string;
  programId: number;
  isLoading = false;
  program: ConfigurableProgramModel;
  pristineProgram: ConfigurableProgramModel;
  programs: ConfigurableProgramModel[];
  programName: string;
  breadcrumbs: Breadcrumb[] = [];
  isConnected: boolean;
  connectedInterval: ReturnType<typeof setInterval>;
  programControl = new FormControl<string | ConfigurableProgramModel>('');
  filteredPrograms: Observable<ConfigurableProgramModel[]>;
  mode = this.navigationPreferences.getMode();
  isFormSubmitted = false;

  isIntervalClearedForGroup: boolean;
  isGroupsDirty = false;

  terminalData: TerminalDataModel;
  states: ConfigurableStatesModel;

  constructor(
    public translationsLib: TranslationsLibService,
    private fb: UntypedFormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private configurableService: ConfigurableService,
    private equipmentLib: EquipmentsLibService,
    private helpersLib: HelpersLibService,
    private navigationPreferences: NavigationPreferencesLibService
  ) {
    this.programForm = this.fb.group({
      program: [null],
      name: [''],
      active: [null, Validators.required],
      startTime: [null, Validators.required],
      endTime: [null, Validators.required],
      week_days: [null, Validators.required],
      week: [null],
      days: [null],
      dirty: [''],
      diesel: [null],
      groups: [null],
    });
  }

  async startInterval() {
    this.connectedInterval = setInterval(() => {
      this.performTasks();
    }, environment.intervalDefaultTimeout);
  }

  handleClearInterval(event: boolean) {
    this.isIntervalClearedForGroup = event;
  }

  async performTasks() {
    this.programForm.reset();

    await this.getProgram();
    await this.generateBreadcrumbs();
    removeDirtyInputs(this.programForm);

    this.getEquipment();

    if (!this.isConnected) {
      this.equipmentLib.showConnectivityAlert();
    }

    this.isFormSubmitted = false;
  }

  ngOnInit() {
    this.programForm.reset();
    this.terminalVid = this.route.snapshot.paramMap.get('vid');
    this.programId = Number(this.route.snapshot.paramMap.get('program_id'));
    if (this.terminalVid === null || this.programId === null) {
      this.router.navigate(['/home/dashboard']);
    }

    this.route.params.subscribe(async () => {
      await this.getProgram();
      await this.generateBreadcrumbs();
      removeDirtyInputs(this.programForm);
    });

    this.programForm.valueChanges.subscribe((_) => {
      const isDirty = this.programForm.pristine ? false : true;

      if (isDirty || this.isIntervalClearedForGroup || this.isGroupsDirty) {
        clearInterval(this.connectedInterval);
      } else {
        clearInterval(this.connectedInterval);
        this.startInterval();
      }
    });

    this.filteredPrograms = this.programControl.valueChanges.pipe(
      startWith(''),
      map((value) => {
        const program = typeof value === 'string' ? value : value?.name;
        return program
          ? this.filterPrograms(program as string)
          : this.programs.slice();
      })
    );

    this.getEquipment();

    this.startInterval();
  }

  ngOnDestroy() {
    clearInterval(this.connectedInterval);
  }

  filterPrograms(program: string) {
    const filter = program.toLowerCase();
    return this.programs.filter(
      (option) =>
        option.name?.toLowerCase().includes(filter) ||
        this.getProgramName(option).toLowerCase().includes(filter)
    );
  }

  getEquipment(): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      this.configurableService.getEquipment(this.terminalVid).subscribe(
        (response) => {
          this.states = response.states;
          this.isConnected = response.connected;
          if (!this.isConnected) this.equipmentLib.showConnectivityAlert();
          resolve(true);
        },
        (error) => {
          console.error(error);
          resolve(false);
        }
      );
    });
  }

  getProgram(): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      this.isLoading = true;
      this.configurableService.getPrograms(this.terminalVid).subscribe(
        (response) => {
          this.programs = response;
          const program = response.find(
            (prog) => prog.program === this.programId
          );
          if (!program)
            this.router.navigate([`/farming/${this.terminalVid}/configurable`]);
          this.program = program;
          this.pristineProgram = JSON.parse(JSON.stringify(program));
          this.programName = this.getProgramName(program);
          this.helpersLib.setTitle('', this.programName, null);
          this.isLoading = false;
          this.initProgramForm();
          resolve(true);
        },
        (error) => {
          console.error(error);
          this.isLoading = false;
          resolve(false);
        }
      );
    });
  }

  initProgramForm() {
    this.programForm.patchValue({
      program: this.program.program,
      dirty: this.program.dirty,
      name: this.program.name,
      active: this.program.active,
      startTime: secondsInputToDate(this.program.startTime),
      endTime: secondsInputToDate(this.program.endTime),
      week_days: this.program.week_days,
      week: this.program.week,
      days: this.program.days,
      diesel: this.program.diesel,
      groups: this.program.groups,
    });
  }

  getProgramName(program: ConfigurableProgramModel): string {
    return program.name
      ? program.name
      : this.translationsLib.get('configurable_program') +
          ' ' +
          (program.program + 1);
  }

  generateBreadcrumbs(): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      this.breadcrumbs = [];
      this.configurableService.getTerminal(this.terminalVid).subscribe(
        (response) => {
          this.terminalData = response;
          if (response.field) {
            this.breadcrumbs.push({
              text: response.field.name,
              disabled: false,
              to: ['/field', { vid: response.field.vid }],
              active: false,
            });
          }
          this.breadcrumbs.push(
            {
              text: response.name,
              disabled: false,
              to: `/farming/${response.vid}/configurable`,
              active: false,
            },
            {
              text: this.programName,
              disabled: false,
              active: true,
            }
          );
          resolve(true);
        },
        (error) => {
          console.error(error);
          resolve(false);
        }
      );
    });
  }

  manualAction(action: 'START' | 'STOP') {
    Swal.fire({
      title: this.translationsLib.get('cannot_be_undone1'),
      text: this.translationsLib.get(
        `configurable_program_manual_${action.toLowerCase()}_warning`
      ),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: this.translationsLib.get(
        `configurable_program_manual_${action.toLowerCase()}`
      ),
    }).then((result) => {
      if (!result.isConfirmed) return;
      const payload = {
        action: `PROGRAM_${action}`,
        number: this.programId,
      };
      this.configurableService
        .postManualActionsProgram(this.terminalVid, payload)
        .subscribe(
          () => {
            Swal.fire({
              icon: 'success',
              title: this.translationsLib.get('success'),
              text: this.translationsLib.get(
                `configurable_program_manual_${action.toLowerCase()}_done`
              ),
              confirmButtonText: this.translationsLib.get('accept'),
            }).then((response) => {
              if (response.isConfirmed) location.reload();
            });
          },
          () => {
            Swal.fire({
              icon: 'error',
              text: this.translationsLib.get('something_was_wrong'),
              showConfirmButton: true,
              confirmButtonText: this.translationsLib.get('accept'),
            });
          }
        );
    });
  }

  onChangePagination(event) {
    this.router
      .navigate([
        `/farming/${this.terminalVid}/configurable/program/${event.program}/group/0`,
      ])
      .then((res) => {
        this.initProgramForm();
      });
  }

  onChangeInputTimeWithKeyboard(event, formInput: string) {
    let timeString = event.target.value;
    if (timeString.split(':').length === 2) timeString += ':00';
    const toSeconds = convertFormattedTimeToSeconds(timeString);
    const toDate = secondsInputToDate(toSeconds);
    this.programForm.get(formInput).setValue(toDate);
    this.programForm.markAsDirty();
  }

  autocompleteDisplayFn(program: ConfigurableProgramModel) {
    return program
      ? program.name
        ? program.name
        : program.program
        ? this.getProgramName(program)
        : ''
      : '';
  }

  isDataSaved(): boolean {
    return this.programForm.dirty;
  }

  hasWritePermissions(): boolean {
    return this.terminalData.authed_user_can_write;
  }

  submitForm() {
    // Preparamos los datos para el post
    const program = { ...this.programForm.value };
    program.startTime = DateToSecondsInput(program.startTime);
    program.endTime = DateToSecondsInput(program.endTime);
    this.isFormSubmitted = true;
    this.isIntervalClearedForGroup = false;
    try {
      this.configurableService
        .postPrograms(this.terminalVid, [program])
        .subscribe(
          (res) => {
            setTimeout(() => {
              Swal.fire({
                text: this.translationsLib.get('save_changes_success'),
                showConfirmButton: true,
                confirmButtonText: this.translationsLib.get('accept'),
                icon: 'success',
              }).then((result) => {
                if (result.isConfirmed) {
                  this.programForm.reset();
                  this.getProgram();
                  this.isFormSubmitted = false;
                }
              });
            }, 3000);
          },
          (_) => {
            Swal.fire({
              text: this.translationsLib.get('something_was_wrong'),
              showConfirmButton: true,
              confirmButtonText: this.translationsLib.get('accept'),
              icon: 'error',
            });
            this.isFormSubmitted = false;
          }
        );
    } catch (err) {
      Swal.fire({
        text: this.translationsLib.get('something_was_wrong'),
        showConfirmButton: true,
        confirmButtonText: this.translationsLib.get('accept'),
        icon: 'error',
      });
      this.isFormSubmitted = false;
    } finally {
      this.isGroupsDirty = false;
    }
  }

  onDirtyGroups(groups: ConfigurableGroupModel[]) {
    this.isGroupsDirty = true;
    this.programForm.patchValue({ groups });
    if (
      JSON.stringify(this.pristineProgram.groups) !== JSON.stringify(groups)
    ) {
      this.programForm.markAsDirty();
    }
  }

  get isProgramDirty() {
    if (
      this.programs &&
      this.programs[this.programId] &&
      (this.programs[this.programId].dirty !== false ||
        this.programs[this.programId].groups.some((g) => g.dirty !== false) ||
        this.programs[this.programId].groups.some((g) =>
          g.fertilizers.some((f) => f.dirty !== false)
        ) ||
        this.programs[this.programId].groups.some((g) =>
          g.pumps.some((p) => p.dirty !== false)
        ) ||
        this.programs[this.programId].groups.some((g) =>
          g.valves.some((v) => v.dirty !== false)
        ))
    ) {
      return true;
    }
    return false;
  }

  refreshDirtyProgram() {
    this.programForm.reset();
    this.getProgram();
  }

  currentProgramState() {
    return (
      this.states?.programs?.filter(
        (program) => program.program === this.programId
      )[0]?.state ?? 'STOPPED'
    );
  }
}
