import { Component, Input, OnInit } from '@angular/core';
import { AlarmsLibService } from '../../services/libraries/alarms-lib.service';
import { HelpersLibService } from '../../services/libraries/helpers-lib.service';
import { ReportsService } from '../reports.service';
import {
  CreatedReportModel,
  IrrigationReportDataModel,
  ReportRequestData,
} from '../reports.model';
import { TimeLibService } from '../../services/libraries/time-lib.service';
import { HighchartsLibService } from '../../services/libraries/highcharts-lib.service';
import * as Highcharts from 'highcharts';
import theme from 'highcharts/themes/high-contrast-light';
import Exporting from 'highcharts/modules/exporting';
import ExportData from 'highcharts/modules/export-data';
import { TranslationsLibService } from '@nutricontrol/app360-shared';
theme(Highcharts);
Exporting(Highcharts);
ExportData(Highcharts);

@Component({
  selector: 'app-reports-irrigations',
  templateUrl: './reports-irrigations.component.html',
  styleUrls: ['./reports-irrigations.component.scss'],
  animations: [],
})
export class ReportsIrrigationsComponent implements OnInit {
  fertilizerColumnsToDisplay: string[] = [
    'groupName',
    'phase',
    'timeBegin',
    'timeEnd',
    'duration',
    'recipe',
    'valves',
    'water',
  ];
  irrigationColumnsToDisplay = [
    'progName',
    'numActivation',
    'timeBegin',
    'timeEnd',
    'duration',
    'pH',
    'CE',
    'progConsumptionWater',
    'realConsumptionWater',
    'activationTypeStr',
  ];
  hasRealWaterConsumption = false;
  Highcharts = Highcharts;
  chart;
  updateFlag = false;
  chartConstructor = 'chart';
  chartCallback;
  @Input() createdReport: CreatedReportModel;
  loader = false;
  chartWaterSeries: any = {};
  chartFertilizerSeries: any = {};
  filterProgramList: string[] = [];
  filterProgramIndexes: any = {};
  reportsData: IrrigationReportDataModel[] = [];
  requestData: ReportRequestData;
  ready = false;
  reportName = '';
  constructor(
    private highchartsLib: HighchartsLibService,
    private alarmsLib: AlarmsLibService,
    private helpersLib: HelpersLibService,
    private reportsService: ReportsService,
    private timeLib: TimeLibService,
    public translationsLib: TranslationsLibService
  ) {}
  ngOnInit() {
    Highcharts.setOptions({ lang: this.highchartsLib.getLangOptions() });
    this.chartCallback = (chart) => {};
    this.resetAll();
    this.processIrrigation();
  }

  public processIrrigation(): void {
    const begin = this.timeLib.beginDate(
      this.createdReport.dateRange.start,
      0,
      'days'
    );
    const end = this.timeLib.endDate(
      this.createdReport.dateRange.end,
      0,
      'days'
    );

    this.loader = true;
    const terminal_id_max = this.createdReport.terminals.length - 1;
    const terminals = [];
    this.createdReport.terminals.forEach((terminalReport, terminalId) => {
      terminals.push(terminalReport.vid);
    });
    this.reportsService
      .getIrrigationReport({ terminals_vid: terminals }, begin, end, null)
      .subscribe((res) => {
        const fertilizersWithoutOrder = [];
        res.irrigation_reports.forEach((irrigation, index) => {
          console.log(irrigation);
          this.helpersLib.sendEventPageView(
            'Reports | Irrigations | ' +
              irrigation.terminal_name +
              '-' +
              irrigation.terminal_vid
          );
          this.createdReport.terminals.forEach((terminalReport, terminalId) => {
            if (irrigation.terminal_vid === terminalReport.vid) {
              this.reportName = this.reportName + terminalReport.name_vid + '_';
              irrigation.terminal_field_name = terminalReport.field_name;
              irrigation.terminal_field_vid = terminalReport.field_vid;
              irrigation.terminal_name_vid = terminalReport.name_vid;
            }
          });
          irrigation.ready = true;
          this.requestData = {
            terminals_vid: [irrigation.terminal_vid],
            begin,
            end,
            type: 'irrigations',
            order_type: null,
          };
          this.reportsData.push(irrigation);
          irrigation.items.forEach((items) => {
            if (!(items.program in this.filterProgramIndexes)) {
              this.filterProgramIndexes[items.program] = true;
              this.filterProgramList.push(items.progName);
            }
            items.fertilizationReport.items.forEach((fertilization) => {
              const length = Object.keys(fertilization.progConsumption).length;
              if (fertilization.realConsumption['water'] > 0) {
                this.hasRealWaterConsumption = true;
              }
              for (let i = 0; i < length; i++) {
                if (
                  fertilization.progConsumption[
                    Object.keys(fertilization.progConsumption)[i]
                  ] > 0 ||
                  fertilization.realConsumption[
                    Object.keys(fertilization.realConsumption)[i]
                  ] > 0
                ) {
                  if (
                    !fertilizersWithoutOrder.includes(
                      Object.keys(fertilization.progConsumption)[i]
                    ) &&
                    Object.keys(fertilization.progConsumption)[i] !== 'water' &&
                    Object.keys(fertilization.progConsumption)[i] !== 'types' &&
                    Object.keys(fertilization.progConsumption)[i] !== 'recipe'
                  ) {
                    fertilizersWithoutOrder.push(
                      Object.keys(fertilization.progConsumption)[i]
                    );
                  }
                }
              }
            });
          });
        });
        const sortedFertilizers = fertilizersWithoutOrder.sort((a, b) => {
          const numA = Number(a.replace('fertilizer', ''));
          const numB = Number(b.replace('fertilizer', ''));
          return numA - numB;
        });
        this.fertilizerColumnsToDisplay =
          this.fertilizerColumnsToDisplay.concat(sortedFertilizers);
        this.ready = true;
      });
  }
  resetAll() {
    this.chartWaterSeries = {};
    this.chartFertilizerSeries = {};
    this.filterProgramList = [];
    this.filterProgramIndexes = {};
    this.reportsData = [];
  }
}
