<div class="my-4">
  <lib-loader *ngIf="ready === false"></lib-loader>
  <app-permissions-table
    *ngIf="ready === true"
    [vid]="vid"
    [canAdmin]="canAdmin"
    [installer]="installer"
    [owner]="owner"
    [regularUsers]="regularUsers"
    [contacts]="contacts"
    [mode]="'terminal'"
    [authedUserCanAdminOwner]="permissions.authed_user_can_admin_owner"
    [authedUserCanAdminInstaller]="permissions.authed_user_can_admin_installer"
    (getPermissionsList)="getPermissionsList()"
  ></app-permissions-table>
</div>
