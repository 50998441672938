import {
  Component,
  OnInit,
  OnChanges,
  Input,
  SimpleChanges,
} from '@angular/core';
import { ClimateEquipmentModel } from '../../../farming/climate/climate.model';
import { WeatherCalculateET0Request } from '../../../weather/weather.model';
import { TranslationsLibService } from '@nutricontrol/app360-shared';

@Component({
  selector: 'app-meteo-grid',
  templateUrl: './meteo-grid.component.html',
  styleUrls: ['./meteo-grid.component.scss'],
})
export class MeteoGridComponent implements OnInit, OnChanges {
  @Input() yesterday_ET0: string;
  @Input() isFetchingET0: boolean;
  @Input() meteoRows = [];
  @Input() terminalType:
    | 'climate'
    | 'irrigation'
    | 'configurable'
    | 'datalogger' = 'irrigation';
  @Input() equipmentResponse: ClimateEquipmentModel;
  @Input() calculateET0Request: WeatherCalculateET0Request;
  cardClass: any;
  meteoRowClass: string;
  iconsMap: { [key: string]: string } = {};

  constructor(public translationsLib: TranslationsLibService) {}

  ngOnInit() {
    this.cardClass = this.getCardClass();
    this.meteoRowClass = this.getMeteoRowClass();
    this.initializeIconsMap();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.meteoRows) {
      this.meteoRowClass = this.getMeteoRowClass();
    }
  }
  getCardClass() {
    return {
      'irrigation-card-border': this.terminalType === 'irrigation',
      'climate-card-border': this.terminalType === 'climate',
      'configurable-card-border': this.terminalType === 'configurable',
      'datalogger-card-border': this.terminalType === 'datalogger',
    };
  }

  getMeteoRowClass() {
    const length = this.meteoRows.length;
    if (length === 1) {
      return 'separator-custom col-12';
    } else if (length >= 2 && length <= 5) {
      return 'separator-custom col-6 col-sm-6';
    } else if (length === 6) {
      return 'separator-custom-case-6 col-6 col-sm-4';
    } else if (length === 7) {
      return 'separator-custom-case-7 col-6 col-sm-4';
    } else if (length === 8) {
      return 'separator-custom col-6 col-sm-6 col-xl-3';
    } else if (length === 9) {
      return 'separator-custom-case-9 col-6 col-sm-4';
    } else if (length >= 10 && length <= 11) {
      return 'separator-custom col-6 col-sm-6';
    } else {
      return 'separator-custom col-6 col-sm-4 col-xl-3';
    }
  }

  initializeIconsMap() {
    const iconKeys = [
      'RAIN',
      'ET0',
      'TEMPERATURE',
      'ACCUMULATED_RADIATION',
      'HUMIDITY',
      'PLUVIOMETER',
      'RADIATION',
      'WIND_DIRECTION',
      'WIND_VELOCITY',
      'WIND_LEVEL_0',
      'WIND_LEVEL_1',
      'SUNRISE',
      'SUNSET',
    ];

    iconKeys.forEach((key) => {
      this.iconsMap[key] = `${key}`;
    });
  }

  getSensorIcon(key: string): string {
    return this.iconsMap[key] || '';
  }

  isVirtualOrigin(field): boolean {
    return field.origin === 'VIRTUAL';
  }

  getFieldUnit(field): string {
    if (
      field.key === 'SUNRISE' ||
      field.key === 'SUNSET' ||
      field.key === 'WIND_DIRECTION'
    ) {
      return '';
    }

    return field.unit || '';
  }
  getDataHeader(field): string {
    return this.translationsLib.get('climate_meteo_grid_' + field.key);
  }

  getVirtualTooltip(): string {
    return this.translationsLib.get('irrigation_grid_virtual_title');
  }
}
