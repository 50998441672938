import {
  AfterViewInit,
  Component,
  HostListener,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ValvesIrrigationInstallerModel } from '../../../irrigation.model';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { Breadcrumb } from '../../../shared/shared.model';
import { IrrigationService } from '../../../irrigation.service';
import { ActivatedRoute, Router } from '@angular/router';
import { HelpersLibService } from '../../../../../services/libraries/helpers-lib.service';
import { map, startWith } from 'rxjs/operators';
import {
  TranslationsLibService,
  TerminalDataModel,
} from '@nutricontrol/app360-shared';
import { IonContent } from '@ionic/angular';
import { TerminalsService } from '../../../../../terminals/terminals.service';

@Component({
  selector: 'app-valves-edit',
  templateUrl: './valves-edit.component.html',
  styleUrls: ['./valves-edit.component.scss'],
})
export class ValvesEditComponent implements OnInit, AfterViewInit {
  isFetchingData = false;
  valves: ValvesIrrigationInstallerModel[];
  isFormFieldWithErrors = false;
  valveControl = new FormControl<string | ValvesIrrigationInstallerModel>('');
  filteredOptions: Observable<ValvesIrrigationInstallerModel[]>;

  valve_id: number;
  terminal_vid: string;
  valve: any;
  breadcrumbs: Breadcrumb[] = [];
  terminalData: TerminalDataModel;
  isValvesWithoutData = false;
  valveValue: string;
  isFetchingTitle = false;
  isDirty = false;
  mode = '';
  manageValve;
  @ViewChild(IonContent, { static: false }) private content: IonContent;
  hasScrollbar = false;
  saveButtonTimeElapsed = false;

  constructor(
    private irrigationService: IrrigationService,
    private route: ActivatedRoute,
    private router: Router,
    private helpersLib: HelpersLibService,
    private terminalsService: TerminalsService,
    public translationsLib: TranslationsLibService
  ) {}

  /**
   * https://stackoverflow.com/questions/56882873/how-to-detect-if-ion-content-has-a-scrollbar/58579938#58579938
   * Apply CSS class based on vertical scroll (save button)
   */
  ngAfterViewInit() {
    setTimeout(() => {
      this.saveButtonTimeElapsed = true;
      this.checkForScrollbar();
    }, 3000);
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.checkForScrollbar();
  }

  async checkForScrollbar() {
    const scrollElement = await this.content.getScrollElement();
    this.hasScrollbar = scrollElement.scrollHeight > scrollElement.clientHeight;
  }

  isDataSaved(): boolean {
    return this.isDirty;
  }

  hasWritePermissions(): boolean {
    return this.terminalData.authed_user_can_write;
  }

  ngOnInit() {
    this.terminal_vid = this.route.snapshot.paramMap.get('vid');

    this.valve_id = Number(this.route.snapshot.paramMap.get('valve'));

    if (!this.terminal_vid || this.valve_id === null) {
      this.router.navigate(['/home/dashboard']);
    }

    this.getValvesList();
    this.filteredOptions = this.valveControl.valueChanges.pipe(
      startWith(''),
      map((value) => {
        const valve = typeof value === 'string' ? value : value?.valve;
        return valve ? this._filter(valve as string) : this.valves.slice();
      })
    );

    this.generateTitleAndBreadcrumbs();
  }
  getValvesManager() {
    this.terminalsService
      .getTerminalValves(this.terminal_vid)
      .subscribe((res) => {
        res.forEach((valve) => {
          if (this.valve.valve === Number(valve.valve)) {
            this.manageValve = valve;
          }
        });
      });
  }
  generateTitleAndBreadcrumbs() {
    this.isFetchingTitle = true;
    this.breadcrumbs = [];
    this.irrigationService.getTerminal(this.terminal_vid).subscribe((res) => {
      this.terminalData = res;
      if (res.field) {
        this.breadcrumbs.push({
          text: res.field.name,
          disabled: false,
          to: ['/field', { vid: res.field.vid }],
          active: false,
        });
      }

      this.breadcrumbs.push({
        text: res.name,
        disabled: false,
        to: `/farming/${this.terminal_vid}/irrigation`,
        active: false,
      });

      this.irrigationService
        .getIrrigationInstallerValve(this.terminal_vid, this.valve_id)
        .subscribe((res) => {
          this.valveValue = res[0].name
            ? res[0].name
            : `${this.translationsLib.get('irrigation_valve')} ${
                this.valve_id + 1
              }`;

          this.breadcrumbs.push({
            text: 'Configuración',
            disabled: false,
            to: `/farming/${this.terminal_vid}/irrigation/5`,
            active: false,
          });

          this.breadcrumbs.push({
            text: this.valveValue,
            disabled: false,
            active: true,
          });

          this.helpersLib.setTitle('', this.valveValue, null);

          this.isFetchingTitle = false;
        });
    });
  }

  private _filter(valve: string): ValvesIrrigationInstallerModel[] {
    const filterValue = valve.toLowerCase();

    return this.valves.filter(
      (option) =>
        option.name?.toLowerCase().includes(filterValue) ||
        (
          this.translationsLib.get('irrigation_valve') +
          ' ' +
          (option.valve + 1).toString()
        )
          .toLowerCase()
          .includes(filterValue)
    );
  }

  onChangePagination(event) {
    this.router.navigate([
      `/farming/${this.terminal_vid}/irrigation/irrigation_installer/valves/${event.valve}`,
    ]);
  }

  autocompleteDisplayFn(valve: ValvesIrrigationInstallerModel) {
    return valve
      ? valve.name
        ? valve.name
        : valve.valve
        ? this.translationsLib.get('irrigation_valve') + ' ' + (valve.valve + 1)
        : ''
      : '';
  }

  getValvesList() {
    this.isFetchingData = true;
    this.irrigationService
      .getIrrigationInstallerValves(this.terminal_vid)
      .subscribe((res) => {
        this.valves = res;

        if (this.valves.length === 0) this.isValvesWithoutData = true;

        this.isFetchingData = false;
      });
  }
}
