import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  SessionLibService,
  TranslationsLibService,
  TerminalDataModel,
  TERMINAL_ENABLED_TEMPORARY_DISABLED,
} from '@nutricontrol/app360-shared';
import { ConfigurableService } from '../configurable.service';
import {
  ConfigurableEquipmentModel,
  ConfigurableProgramModel,
} from '../configurable.model';
import { ActivatedRoute } from '@angular/router';
import { PermissionsTerminalsService } from 'src/app/services/libraries/permissions-terminals.service';
import { HelpersLibService } from 'src/app/services/libraries/helpers-lib.service';
import { Breadcrumb } from 'src/app/farming/irrigation/shared/shared.model';
import {
  generateAlarmGrid,
  generateMeteoGrid,
} from 'src/app/commons/helpers/functions';
import {
  WeatherCalculateET0Request,
  WeatherPredictionDataModel,
} from 'src/app/weather/weather.model';
import { WeatherService } from 'src/app/weather/weather.service';
import { TerminalsLibService } from 'src/app/services/libraries/terminals-lib.service';
import { TimeLibService } from 'src/app/services/libraries/time-lib.service';
import { AppCommonsService } from 'src/app/commons/app-commons.service';
import { Et0LibService } from '../../libraries/et0-lib.service';
import { IrrigationService } from '../../irrigation/irrigation.service';
import { FertilizerFormulasModel } from '../../irrigation/irrigation.model';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-terminal-dashboard',
  templateUrl: './terminal-dashboard.component.html',
  styleUrls: ['./terminal-dashboard.component.scss'],
})
export class TerminalDashboardComponent implements OnInit, OnDestroy {
  isLoading = false;
  isTableLoading = false;
  breadcrumbs: Breadcrumb[] = [];
  terminalVid: string;
  terminalData: TerminalDataModel;
  terminalEnabled: number;
  equipmentResponse: ConfigurableEquipmentModel;
  programsResponse: ConfigurableProgramModel[];
  alarmRows = [];

  private intervalId: any;

  isFetchingFertilizerFormulasData = false;
  fertilizerFormulasResponse: FertilizerFormulasModel[];

  weather: WeatherPredictionDataModel;
  meteoRows = [];
  configurableEquipmentVirtualPostResponse: WeatherCalculateET0Request;
  configurableEquipmentVirtualPostResponseTerminal:
    | WeatherCalculateET0Request
    | ConfigurableEquipmentModel;
  calculateET0Request: WeatherCalculateET0Request;
  yesterday_ET0 = '-';

  protected readonly TERMINAL_ENABLED_TEMPORARY_DISABLED =
    TERMINAL_ENABLED_TEMPORARY_DISABLED;

  constructor(
    public translationsLib: TranslationsLibService,
    private activatedRoute: ActivatedRoute,
    private configurableService: ConfigurableService,
    private permissionsTerminal: PermissionsTerminalsService,
    private helpersLib: HelpersLibService,
    public weatherService: WeatherService,
    private terminalsLib: TerminalsLibService,
    private timeLib: TimeLibService,
    public commonService: AppCommonsService,
    public et0Service: Et0LibService,
    private irrigationService: IrrigationService,
    public sessionLib: SessionLibService
  ) {}

  ngOnInit() {
    this.terminalVid = this.activatedRoute.snapshot.paramMap?.get('vid');
    this.activatedRoute.params.subscribe(async () => {
      await this.intervalTasks();
    });

    this.setInterval();
  }

  ngOnDestroy(): void {
    this.removeInterval();
  }

  ionViewDidLeave() {
    this.removeInterval();
  }

  async ionViewDidEnter() {
    this.setInterval();
  }

  setInterval() {
    this.intervalId = setInterval(async () => {
      await this.intervalTasks();
    }, environment.intervalDefaultTimeout);
  }

  removeInterval() {
    if (this.intervalId) {
      clearInterval(this.intervalId);
      this.intervalId = null;
    }
  }

  async intervalTasks() {
    await this.getTerminalData();

    if (this.terminalEnabled !== TERMINAL_ENABLED_TEMPORARY_DISABLED) {
      if (this.terminalData.field === null) {
        await this.getWeatherByTerminal();
      } else {
        await this.getWeatherByField();
      }

      await this.postTerminalDataForVirtualInfo();
      await this.getProgramsData();
    }
  }

  generateTooltip(groups: any[]): string {
    let tooltipContent = '';
    groups.forEach((group, index) => {
      tooltipContent +=
        this.translationsLib.get('irrigation_group') + ' ' + (group.group + 1);
      if (index < groups.length - 1) {
        tooltipContent += ', ';
      }
    });
    return tooltipContent;
  }

  getTerminalData(): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      this.isLoading = true;
      this.breadcrumbs = [];
      this.configurableService.getTerminal(this.terminalVid).subscribe(
        async (response) => {
          this.terminalData = response;
          this.terminalEnabled = response.enabled;
          this.permissionsTerminal.setPermissions(this.terminalVid, {
            authed_user_can_admin: response.authed_user_can_admin,
            authed_user_can_read: response.authed_user_can_read,
            authed_user_can_write: response.authed_user_can_write,
          });
          const { field, name } = response;
          if (field) {
            this.breadcrumbs.push({
              text: field.name,
              disabled: false,
              to: ['/field', { vid: field.vid }],
              active: false,
            });
          }
          this.breadcrumbs.push({
            text: name,
            disabled: false,
            active: true,
          });

          if (this.terminalEnabled !== TERMINAL_ENABLED_TEMPORARY_DISABLED) {
            this.et0Service
              .getHistoricWeather(this.terminalData)
              .then((result) => {
                this.calculateET0Request = result.calculateET0Request;
              })
              .catch((error) => {
                console.log(error);
              });
          }

          this.isLoading = false;
          resolve(true);
        },
        (error) => {
          this.terminalsLib.terminalNoRights();
          console.error(error);
          this.isLoading = false;
          resolve(false);
        }
      );
    });
  }

  postTerminalDataForVirtualInfo(): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      this.isLoading = true;
      try {
        const virtualInfo = {
          info: {
            meteo: {
              date: this.weather?.date,
              main_temp: this.weather?.main_temp,
              main_pressure: this.weather?.main_pressure,
              wind_speed: this.weather?.wind_speed,
              solar_rad: this.weather?.solar_rad,
              precip: this.weather?.precip,
              main_humidity: this.weather?.main_humidity,
              wind_direction: this.weather?.wind_deg,
            },
          },
        };
        this.configurableService
          .postConfigurableTerminal(this.terminalVid, virtualInfo)
          .subscribe(
            async (res) => {
              this.configurableEquipmentVirtualPostResponse = res;
              this.configurableEquipmentVirtualPostResponseTerminal = res;

              this.equipmentResponse = res as any;

              // Subscribe to route event to change title between route navigations
              this.activatedRoute.paramMap.subscribe((_) => {
                this.helpersLib.setTitle('', (res as any).name, null);
              });

              this.alarmRows = generateAlarmGrid((res as any).alarms);

              await this.et0Service
                .getET0(this.calculateET0Request, this.equipmentResponse)
                .then((result) => {
                  this.yesterday_ET0 = result.yesterday_ET0;

                  if (this.calculateET0Request !== undefined) {
                    if (this.terminalData.field === null) {
                      this.updateMeteoRows(
                        this.configurableEquipmentVirtualPostResponseTerminal,
                        this.calculateET0Request
                      );
                    } else {
                      this.updateMeteoRows(
                        this.configurableEquipmentVirtualPostResponse,
                        this.calculateET0Request
                      );
                    }
                  } else {
                    if (this.terminalData.field === null) {
                      this.updateMeteoRows(
                        this.configurableEquipmentVirtualPostResponseTerminal,
                        this.calculateET0Request
                      );
                    } else {
                      this.updateMeteoRows(
                        this.configurableEquipmentVirtualPostResponse,
                        this.calculateET0Request
                      );
                    }
                  }
                });

              resolve(true);
              this.isLoading = false;
            },
            (error) => {
              this.terminalsLib.terminalNoRights();
              console.error(error);
              this.isLoading = false;
              resolve(false);
            }
          );
      } catch (err) {
        throw new Error(err);
      }
    });
  }

  getFertilizerFormulas(): Promise<boolean> {
    return new Promise<boolean>((resolve, _) => {
      this.isFetchingFertilizerFormulasData = true;

      try {
        this.irrigationService
          .getFertilizerFormulas(this.terminalVid)
          .subscribe((res) => {
            this.fertilizerFormulasResponse = res;

            this.isFetchingFertilizerFormulasData = false;
            resolve(true);
          });
      } catch (err) {
        throw new Error(err);
      }
    });
  }

  getEquipmentData(): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      this.isLoading = true;
      this.configurableService.getEquipment(this.terminalVid).subscribe(
        async (response) => {
          this.equipmentResponse = response;
          this.helpersLib.setTitle('', this.equipmentResponse.name, null);
          this.alarmRows = generateAlarmGrid(response.alarms);

          if (this.terminalData.field === null) {
            await this.getWeatherByTerminal();
          } else {
            await this.getWeatherByField();
          }
          await this.postTerminalDataForVirtualInfo();

          await this.et0Service
            .getET0(this.calculateET0Request, this.equipmentResponse)
            .then((result) => {
              this.yesterday_ET0 = result.yesterday_ET0;

              if (this.calculateET0Request !== undefined) {
                if (this.terminalData.field === null) {
                  this.updateMeteoRows(
                    this.configurableEquipmentVirtualPostResponseTerminal,
                    this.calculateET0Request
                  );
                } else {
                  this.updateMeteoRows(
                    this.configurableEquipmentVirtualPostResponse,
                    this.calculateET0Request
                  );
                }
              } else {
                if (this.terminalData.field === null) {
                  this.updateMeteoRows(
                    this.configurableEquipmentVirtualPostResponseTerminal,
                    this.calculateET0Request
                  );
                } else {
                  this.updateMeteoRows(
                    this.configurableEquipmentVirtualPostResponse,
                    this.calculateET0Request
                  );
                }
              }
            });

          this.isLoading = false;
          resolve(true);
        },
        (error) => {
          this.terminalsLib.terminalNoRights();
          console.error(error);
          this.isLoading = false;
          resolve(false);
        }
      );
    });
  }

  getProgramsData(): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      this.isTableLoading = true;
      this.configurableService.getPrograms(this.terminalVid).subscribe(
        (response) => {
          this.programsResponse = response;
          this.isTableLoading = false;
          resolve(true);
        },
        (error) => {
          console.error(error);
          this.isTableLoading = false;
          resolve(false);
        }
      );
    });
  }

  getState(state: number): string {
    const DEFAULT_STATE = false;
    const STATES = {
      false: this.translationsLib.get('configurable_program_inactive'),
      true: this.translationsLib.get('configurable_program_active'),
    };
    return STATES[state] || DEFAULT_STATE;
  }

  getProgramState(programNumber: number) {
    return this.equipmentResponse.states.programs.find(
      (p) => p.program === programNumber
    );
  }

  getPhaseLabel(phase: number) {
    switch (phase) {
      case 1:
        return this.translationsLib.get('configurable_phase_PRE_FERTILIZE');
      case 2:
        return this.translationsLib.get('configurable_phase_FERTILIZE');
      case 3:
        return this.translationsLib.get('configurable_phase_POST_FERTILIZE');
      case 4:
        return this.translationsLib.get('configurable_phase_OVERLAPPING');
      default:
        return '';
    }
  }

  getWeatherByField(): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      try {
        this.weatherService
          .getWeatherCurrentByField(this.terminalData.field.vid)
          .subscribe((res) => {
            this.weather = res;
            resolve(true);
          });
      } catch (err) {
        throw new Error(err);
      }
    });
  }

  getWeatherByTerminal(): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      try {
        this.weatherService
          .getCurrentWeatherByTerminal(this.terminalVid)
          .subscribe(
            (res) => {
              this.weather = res;
              if (res?.error) resolve(false);
              else {
                this.configurableEquipmentVirtualPostResponseTerminal =
                  this.equipmentResponse;
                resolve(true);
              }
            },
            (error) => {
              console.log(error);
              resolve(false);
            }
          );
      } catch (err) {
        throw new Error(err);
      }
    });
  }

  updateMeteoRows(res: any, calculateET0Request: any) {
    if (res?.meteo === null || res?.meteo.length === 0)
      res = this.equipmentResponse;

    if (res.meteo instanceof Array && res.meteo.length === 0) {
      this.meteoRows = generateMeteoGrid([
        {
          key: 'ET0',
          value: Number(this.yesterday_ET0),
          name: null,
          unit: 'mm',
          origin: null,
        },
      ]);
    } else {
      if (
        calculateET0Request !== undefined &&
        this.terminalData.field_vid !== undefined
      ) {
        if (this.yesterday_ET0 !== 'N/A') {
          res.meteo.unshift({
            key: 'ET0',
            value: Number(this.yesterday_ET0),
            name: 'ET0',
            unit: 'mm',
            origin: null,
          });
        }
      }

      this.meteoRows = generateMeteoGrid(res.meteo);
    }
  }
}
