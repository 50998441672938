import {
  AfterViewInit,
  Component,
  ContentChild,
  ElementRef,
  Input,
  OnInit,
  QueryList,
  TemplateRef,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { TerminalTableDataDirective } from '../../directives/terminal-table-data.directive';
import { TranslationsLibService } from '@nutricontrol/app360-shared';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import {
  SortEvent,
  SortableHeaderDirective,
  compare,
} from '../../directives/sortable-header.directive';
import * as moment from 'moment';

@Component({
  selector: 'app-terminal-table-wrapper-irrigation',
  templateUrl: './terminal-table-wrapper-irrigation.component.html',
  styleUrls: ['./terminal-table-wrapper-irrigation.component.scss'],
})
export class TerminalTableWrapperIrrigationComponent
  implements OnInit, AfterViewInit
{
  @ContentChild(TerminalTableDataDirective, { read: TemplateRef })
  dataTemplate: any;

  @ViewChildren(SortableHeaderDirective)
  headers: QueryList<SortableHeaderDirective>;

  @ViewChild('sortableHeader') sortableHeader: ElementRef;

  @Input() screenType: 'irrigation' | 'configurable' | '' = '';
  @Input() states?;
  @Input() fertilizerFormulas?;
  @Input()
  includeText: string;
  @Input() data;
  @Input() type: 'climate' | 'irrigation';
  @Input() isFetchingData = false;
  @Input() terminal_vid: string;
  @Input() searchPlaceholder: string;
  @Input() tableHeaders;
  @Input() name: string;
  @Input() filterProperty = 'name';
  @Input() isIndex: boolean;
  searchText = '';
  page = 1;
  cardClass: any;
  isMobile = false;

  sortedArray: any[];

  onSort({ column, direction }: SortEvent) {
    this.headers.forEach((header) => {
      if (header.sortable !== column) {
        header.direction = '';
      } else {
        header.direction = direction;
      }
    });

    /** PROGRAMS columns
     * states
     * name
     * date
     * groups
     * fertilizers
     */

    if (direction === '' || column === '') {
      this.sortedArray = this.data;
    } else {
      this.sortedArray = [...this.data].sort((a, b) => {
        let res: number;

        /** It doesn't sort by name in groups, recipes, drainages, and blends because
         * name arrives as both null and with a name (BROCOLI, QUINTA, etc).
         * Setting the name to 'Group 1' when name === null also doesn't work
         * since it sorts but doesn't pick the maximum element.
         *
         * The solution has been, when dealing with the name column, to sort by the
         * index that comes in the corresponding key (group, formula, drainage, etc)
         */

        if (column === 'date') {
          res = compare(this.parseDate(a.begin), this.parseDate(b.begin));
        } else if (column === 'states') {
          res = this.compareStates(a, b);
        } else if (
          column === 'time' &&
          a.hasOwnProperty('postFertilizerFormula') &&
          b.hasOwnProperty('postFertilizerFormula')
        ) {
          res = this.compareTimes(a, b);
        } else if (column === 'time') {
          res = this.compareBeginEnd(a, b);
        } else if (column === 'idealCE') {
          res = this.compareIdealEC(a, b);
        } else if (column === 'fertilizer') {
          res = this.compareFertilizers(a, b);
        } else if (column === 'groups') {
          res = this.compareGroups(a, b);
        } else if (
          // COMPARE PROGRAMS NAME
          column === 'name' &&
          a.hasOwnProperty('program') &&
          b.hasOwnProperty('program')
        ) {
          res = compare(
            a['name'].toString().trim(),
            b['name'].toString().trim()
          );
        } else if (
          // COMPARE GROUPS NAME
          column === 'name' &&
          a.hasOwnProperty('group') &&
          b.hasOwnProperty('group')
        ) {
          res = compare(a['group'], b['group']);
        } else if (
          // COMPARE FERTILIZER FORMULAS NAME
          column === 'name' &&
          a.hasOwnProperty('formula') &&
          b.hasOwnProperty('formula')
        ) {
          res = compare(a['formula'], b['formula']);
        } else if (
          // COMPARE DRAINAJES NAME
          column === 'name' &&
          a.hasOwnProperty('drainage') &&
          b.hasOwnProperty('drainage')
        ) {
          res = compare(a['drainage'], b['drainage']);
        } else if (
          // COMPARE MIXTURE FORMULAS NAME. Same as fertilizer formulas
          column === 'name' &&
          a.hasOwnProperty('formula') &&
          b.hasOwnProperty('formula')
        ) {
          res = compare(a['formula'], b['formula']);
        } else {
          res = compare(a[column], b[column]);
        }

        return direction === 'asc' ? res : -res;
      });
    }
  }

  statePriority: { [key: string]: number } = {
    /** STATE PRIORITY
     * 1) ACTIVE+IRRIGATING
     * 2) ACTIVE+STANDBY
     * 3) ACTIVE+OTHER STATE
     * 4) INACTIVE (active: false)
     */

    IRRIGATING: 1,
    STANDBY: 2,
    TIME_MIN_VERIFYING: 3,
    WAITING_VM: 3,
    STOPPED: 4,
  };

  compareTimes(a: any, b: any): number {
    const timeA =
      a.fertilizationTime !== undefined
        ? a.fertilizationTime
        : Number.MAX_SAFE_INTEGER;
    const timeB =
      b.fertilizationTime !== undefined
        ? b.fertilizationTime
        : Number.MAX_SAFE_INTEGER;

    return timeA - timeB;
  }

  parseDate(dateStr: string): Date {
    const [dayMonth, time] = dateStr.split(' ');
    const [day, month] = dayMonth.split('/').map(Number);
    const [hours, minutes, seconds] = time.split(':').map(Number);
    const now = new Date();
    const year = now.getFullYear();

    return new Date(year, month - 1, day, hours, minutes, seconds);
  }

  compareStates(a: any, b: any): number {
    const activeA = a.active ? 0 : 1;
    const activeB = b.active ? 0 : 1;

    if (activeA !== activeB) {
      return activeA - activeB;
    }

    const stateA = a.states?.[0]?.state || '';
    const stateB = b.states?.[0]?.state || '';

    const defaultPriority = 99;
    const priorityA =
      this.statePriority[stateA] !== undefined
        ? this.statePriority[stateA]
        : defaultPriority;
    const priorityB =
      this.statePriority[stateB] !== undefined
        ? this.statePriority[stateB]
        : defaultPriority;

    return priorityA - priorityB;
  }

  compareIdealEC(a: any, b: any): number {
    const idealECA =
      a.idealEC !== undefined ? a.idealEC : Number.MAX_SAFE_INTEGER;
    const idealECB =
      b.idealEC !== undefined ? b.idealEC : Number.MAX_SAFE_INTEGER;

    return idealECA - idealECB;
  }

  compareGroups(a: any, b: any): number {
    const getGroupName = (group: any): string => {
      return group && group.group_info && group.group_info.name
        ? group.group_info.name.toString().trim()
        : '-';
    };

    const nameA =
      a.groups && a.groups.length > 0 ? getGroupName(a.groups[0]) : ' ';
    const nameB =
      b.groups && b.groups.length > 0 ? getGroupName(b.groups[0]) : ' ';

    return compare(nameA, nameB);
  }

  compareFertilizers(a: any, b: any): number {
    const getFertilizerFormulaText = (elem: any): string => {
      if (elem.fertilicerFormula === 255 || elem.fertilicerFormula === null) {
        return '-';
      } else if (elem.fertilicerFormula === 254) {
        return this.translationsLib.get('irrigation_group_selected');
      } else if (elem.fertilizer_formula && elem.fertilizer_formula.name) {
        return elem.fertilizer_formula.name;
      } else {
        return (
          this.translationsLib.get('irrigation_fertilizer_formula') +
          ' ' +
          (elem.fertilicerFormula + 1)
        );
      }
    };

    const nameA = getFertilizerFormulaText(a);
    const nameB = getFertilizerFormulaText(b);

    return compare(nameA.toLowerCase(), nameB.toLowerCase());
  }

  formatAndParseDate(dateStr: string): moment.Moment {
    const [date, time] = dateStr.split(' ');

    const [month, day] = date.split('/');

    return moment(`${day}-${month}-${time}`, 'DD-MM-HH:mm:ss');
  }

  compareDates(date1: string, date2: string): number {
    const mDate1 = this.formatAndParseDate(date1);
    const mDate2 = this.formatAndParseDate(date2);

    if (mDate1.isBefore(mDate2)) {
      return -1;
    } else if (mDate1.isAfter(mDate2)) {
      return 1;
    } else {
      return 0;
    }
  }

  compareBeginEnd(a: any, b: any): number {
    const resultBegin = this.compareDates(a.begin, b.begin);
    if (resultBegin !== 0) {
      return resultBegin;
    }

    return this.compareDates(a.end, b.end);
  }

  loadData() {
    setTimeout(() => {
      this.sortedArray = this.data;

      if (this.name === 'programs' && !this.isFetchingData) {
        // This code add all the information of the fertilizer formula response
        // That match with the object key 'fertilizerFormula' inside programs API response
        this.sortedArray.forEach((activeItem: any) => {
          const formulaItem = this.fertilizerFormulas?.find(
            (formula) => formula.formula === activeItem.fertilicerFormula
          );

          if (formulaItem) {
            activeItem.formulaData = formulaItem;
          }
        });

        // This code add a new object key called 'states' inside the original program API response
        // to match the states response with the programs response
        this.states.states.programs.forEach((state: any) => {
          const program = this.sortedArray[state.program];
          state.active = program.active;

          if (program) {
            if (!program.states) {
              program.states = [];
            }

            program.states.push(state);
          }
        });
      }

      this.sortDefaultColumn();
    }, 3000);
  }

  sortDefaultColumn() {
    if (this.name === 'programs') {
      if (this.sortableHeader) {
        this.sortableHeader.nativeElement.classList.add('asc');
      }
      // @ts-ignore
      this.onSort({ column: 'states', direction: 'asc' });
    }
  }

  ngAfterViewInit(): void {
    this.loadData();

    this.breakpointObserver
      .observe([Breakpoints.Small, Breakpoints.XSmall])
      .subscribe((result) => {
        this.isMobile = result.matches;
      });
  }

  constructor(
    public translationsLib: TranslationsLibService,
    private breakpointObserver: BreakpointObserver
  ) {}

  ngOnInit() {
    this.cardClass = this.getClass();
  }

  getClass() {
    return {
      'irrigation-card-border irrigation-table': this.type === 'irrigation',
      'climate-card-border climate-table': this.type === 'climate',
    };
  }
}
