import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppCommonsModule } from '../commons/app-commons.module';
import { ReportsPageComponent } from './reports-page/reports-page.component';
import { ReportsIrrigationsComponent } from './reports-irrigations/reports-irrigations.component';
import { ReportsDrainagesComponent } from './reports-drainages/reports-drainages.component';
import { IonicModule } from '@ionic/angular';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatInputModule } from '@angular/material/input';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { AuthModule } from '../auth/auth.module';
import { MatTableModule } from '@angular/material/table';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { HighchartsChartModule } from 'highcharts-angular';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NgSelectModule } from '@ng-select/ng-select';
import { MatPaginatorModule } from '@angular/material/paginator';
import { ReportsIrrigationsTableComponent } from './reports-irrigations/reports-irrigations-table/reports-irrigations-table.component';
import { ReportsDrainagesTableComponent } from './reports-drainages/reports-drainages-table/reports-drainages-table.component';
import { ReportsConsumptionsComponent } from './reports-consumptions/reports-consumptions.component';
import { ReportsProgramsConsumptionsComponent } from './reports-consumptions/reports-programs-consumptions/reports-programs-consumptions.component';
import { ReportsGroupingConsumptionsComponent } from './reports-consumptions/reports-grouping-consumptions/reports-grouping-consumptions.component';
import { ReportsConsumptionsChartsComponent } from './reports-consumptions/reports-consumptions-charts/reports-consumptions-charts.component';
import { ReportsConsumptionsTableComponent } from './reports-consumptions/reports-consumptions-table/reports-consumptions-table.component';
import { ReportsProgRealComponent } from './reports-widgets/reports-prog-real/reports-prog-real.component';
import { MatSortModule } from '@angular/material/sort';
import { ReportsFertilizersTableComponent } from './reports-irrigations/reports-fertilizers-table/reports-fertilizers-table.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatTableExporterModule } from 'mat-table-exporter';
import { MatMenuModule } from '@angular/material/menu';
import { ReportsAlarmsComponent } from './reports-alarms/reports-alarms.component';
import { ReportsAlarmsTableComponent } from './reports-alarms/reports-alarms-table/reports-alarms-table.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatTabsModule } from '@angular/material/tabs';
import { ReportsTasksComponent } from './reports-tasks/reports-tasks.component';
import { ReportsTasksItemsComponent } from './reports-tasks/reports-tasks-items/reports-tasks-items.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MaxRangeDirective } from '../commons/directives/max-range-selection-strategy.directive';
import { MatStepperModule } from '@angular/material/stepper';
import { MatRadioModule } from '@angular/material/radio';
import { ReportsSuggestedComponent } from './reports-widgets/reports-suggested/reports-suggested.component';
import {
  ReportsDownloadLoaderTemplateComponent,
  ReportsDownloadPostOpenWindowComponent,
} from './reports-widgets/reports-download-post-open-window/reports-download-post-open-window.component';
import {App360SharedModule} from "@nutricontrol/app360-shared";
import {SelectedSensorsComponent} from "../megapop-common/selected-sensors/selected-sensors.component";
import {MegapopCommonModule} from "../megapop-common/megapop-common.module";
import {ReportsRawSensorsComponent} from "./reports-raw-sensors/reports-raw-sensors.component";
import {
  ReportsRawSensorsTableComponent
} from "./reports-raw-sensors/reports-raw-sensors-table/reports-raw-sensors-table.component";
import {ReportsGraphicsSensorsComponent} from "./reports-graphics-sensors/reports-graphics-sensors.component";
import {ChartsComponentComponent} from "../graphics/charts-component/charts-component.component";
import {MatCardModule} from "@angular/material/card";

@NgModule({
  declarations: [
    ReportsPageComponent,
    ReportsDrainagesComponent,
    ReportsIrrigationsComponent,
    ReportsIrrigationsTableComponent,
    ReportsDrainagesTableComponent,
    ReportsConsumptionsComponent,
    ReportsConsumptionsChartsComponent,
    ReportsConsumptionsTableComponent,
    ReportsProgramsConsumptionsComponent,
    ReportsGroupingConsumptionsComponent,
    ReportsProgRealComponent,
    ReportsFertilizersTableComponent,
    ReportsAlarmsComponent,
    ReportsAlarmsTableComponent,
    ReportsTasksComponent,
    ReportsTasksItemsComponent,
    ReportsSuggestedComponent,
    ReportsDownloadPostOpenWindowComponent,
    ReportsDownloadLoaderTemplateComponent,
    MaxRangeDirective,
    ReportsRawSensorsComponent,
    ReportsRawSensorsTableComponent,
    ReportsGraphicsSensorsComponent
  ],
  exports: [MaxRangeDirective],
    imports: [
        CommonModule,
        AppCommonsModule,
        IonicModule,
        MatFormFieldModule,
        MatAutocompleteModule,
        MatInputModule,
        ReactiveFormsModule,
        MatSelectModule,
        MatDatepickerModule,
        MatNativeDateModule,
        AuthModule,
        MatTableModule,
        MatIconModule,
        MatButtonModule,
        HighchartsChartModule,
        MatTooltipModule,
        NgSelectModule,
        FormsModule,
        MatPaginatorModule,
        MatSortModule,
        MatExpansionModule,
        MatTableExporterModule,
        MatMenuModule,
        MatDialogModule,
        MatTabsModule,
        MatSlideToggleModule,
        MatCheckboxModule,
        MatChipsModule,
        MatStepperModule,
        MatRadioModule,
        App360SharedModule,
        SelectedSensorsComponent,
        MegapopCommonModule,
        ChartsComponentComponent,
        MatCardModule,
    ],
})
export class ReportsModule {}
