import { EquipmentModel } from '../farming.model';

/**** TERMINAL LAYOUT CLIMATE ****/
export interface TerminalLayoutModelClimateSubmit {
  layout_configuration: any;
}
export interface TerminalLayoutModeClimate {
  terminal_vid: string;
  user_vid: string;
  type: number;
  layout_configuration: any;
  screens_configuration: ScreensConfigurationClimate;
  is_default: boolean;
  updated_at: string;
  created_at: string;
}

export interface ScreensConfigurationClimate {
  progtvent: Progtvent;
  progventanas: Progventanas;
  progventforzada: Progventforzada;
  progit: Progit;
  progcalefact: Progcalefact;
  progtempcalefaccion: Progtempcalefaccion;
  progtransporte: Progtransporte;
  progtuberias: Progtuberias;
  progpantterm: Progpantterm;
  progpantoscu: Progpantoscu;
  progpantoscusom: Progpantoscusom;
  progdestratificadores: Progdestratificadores;
  proghumidificacion: Proghumidificacion;
  progalarmcomp: Progalarmcomp;
  proggenericos: Proggenericos;
  progCO2: ProgCo2;
}

export interface Progtvent {
  enabled: boolean;
  max_periods: number;
  zones: Zones;
}

export interface Zones {
  available_ids: number[];
}

export interface Progventanas {
  enabled: boolean;
  max_periods: number;
  max_vents: number;
  blocks: Blocks;
  zones: Zones2;
}

export interface Blocks {
  max_curtain_position_leeward: MaxCurtainPositionLeeward;
  max_curtain_position_windward: MaxCurtainPositionWindward;
  fan_step: FanStep;
}

export interface MaxCurtainPositionLeeward {
  show: boolean;
}

export interface MaxCurtainPositionWindward {
  show: boolean;
}

export interface FanStep {
  show: boolean;
}

export interface Zones2 {
  available_ids: number[];
}

export interface Progventforzada {
  enabled: boolean;
  max_periods: number;
  max_steps: number;
  zones: Zones3;
}

export interface Zones3 {
  available_ids: number[];
}

export interface Progit {
  enabled: boolean;
  zones: Zones4;
}

export interface Zones4 {
  available_ids: number[];
}

export interface Progcalefact {
  enabled: boolean;
  max_steps: number;
  max_periods: number;
  zones: Zones5;
}

export interface Zones5 {
  available_ids: number[];
}

export interface Progtempcalefaccion {
  enabled: boolean;
  max_periods: number;
  zones: Zones6;
}

export interface Zones6 {
  available_ids: number[];
}

export interface Progtransporte {
  enabled: boolean;
}

export interface Progtuberias {
  enabled: boolean;
  max_periods: number;
  zones: Zones7;
}

export interface Zones7 {
  available_ids: number[];
}

export interface Progpantterm {
  enabled: boolean;
  zones: Zones8;
}

export interface Zones8 {
  available_ids: number[];
}

export interface Progpantoscu {
  enabled: boolean;
}

export interface Progpantoscusom {
  enabled: boolean;
}

export interface Progdestratificadores {
  enabled: boolean;
  max_periods: number;
  blocks: Blocks2;
  zones: Zones9;
}

export interface Blocks2 {
  curtain_position: CurtainPosition;
  dead_band: DeadBand;
}

export interface CurtainPosition {
  show: boolean;
}

export interface DeadBand {
  show: boolean;
}

export interface Zones9 {
  available_ids: number[];
}

export interface Proghumidificacion {
  enabled: boolean;
  max_periods: number;
  zones: Zones10;
}

export interface Zones10 {
  available_ids: number[];
}

export interface Progalarmcomp {
  enabled: boolean;
  blocks: Blocks3;
  zones: Zones11;
}

export interface Blocks3 {
  min_max_temp_pipe: MinMaxTempPipe;
  storm_alarms: StormAlarms;
  max_CO2: MaxCo2;
  min_CO2: MinCo2;
}

export interface MinMaxTempPipe {
  show: boolean;
}

export interface StormAlarms {
  show: boolean;
  max: number;
  start_wind_speed_influence: StartWindSpeedInfluence;
  step_delay_time: StepDelayTime;
  wind_direction_dead_band: WindDirectionDeadBand;
}

export interface StartWindSpeedInfluence {
  show: boolean;
}

export interface StepDelayTime {
  show: boolean;
}

export interface WindDirectionDeadBand {
  show: boolean;
}

export interface MaxCo2 {
  show: boolean;
}

export interface MinCo2 {
  show: boolean;
}

export interface Zones11 {
  available_ids: number[];
}

export interface Proggenericos {
  enabled: boolean;
  zones: Zones12;
}

export interface Zones12 {
  available_ids: number[];
}

export interface ProgCo2 {
  enabled: boolean;
}

/**** VENTILATION TEMPERATURE ****/
export interface VentilationTemperatureModel {
  zones: VentilationTemperatureZone[];
}

export interface VentilationTemperatureZone {
  periods: VentilationTemperaturePeriod[];
}

export interface VentilationTemperaturePeriod {
  zone: number;
  period: number;
  active: boolean;
  startTime: number;
  startReference: any;
  endTime: number;
  endReference: any;
  ventilationTemp: number;
  maxVentilationTemp: number;
  minVentilationTemp: number;
  radiationInfluence: number;
  startRadiationInfluence: number;
  endRadiationInfluence: number;
  coolingDelay: number;
  dirty?: boolean;
  heatingDelay: number;
  humidityInfluence: VentilationTemperatureHumidityInfluence;
}

export interface VentilationTemperatureHumidityInfluence {
  level0: VentilationTemperatureLevel0;
  level1: VentilationTemperatureLevel1;
  level2: VentilationTemperatureLevel2;
  level3: VentilationTemperatureLevel3;
}

export interface VentilationTemperatureLevel0 {
  hr: number;
  correction: number;
}

export interface VentilationTemperatureLevel1 {
  hr: number;
  correction: number;
}

export interface VentilationTemperatureLevel2 {
  hr: number;
  correction: number;
}

export interface VentilationTemperatureLevel3 {
  hr: number;
  correction: number;
}

export interface VentilationTemperatureSubmitModel {
  zones: {
    periods: VentilationTemperaturePeriod[];
  }[];
}

/**** VENTS ****/
export interface VentModel {
  zones: VentZone[];
}

export interface VentZone {
  vents: VentVent[];
}

export interface VentVent {
  periods: VentPeriod[];
  settings: VentSettings;
}

export interface VentPeriod {
  zone: number;
  dirty?: boolean;
  vent: number;
  period: number;
  active: boolean;
  startTime: number;
  startReference: any;
  endTime: number;
  endReference: any;
  laggingLeeward: number;
  laggingWindward: number;
  pBandLeeward: number;
  pBandWindward: number;
  minPositionLeeward: number;
  minPositionWindward: number;
  maxPositionLeeward: number;
  maxPositionWindward: number;
  maxCurtainPositionLeeward: number;
  maxCurtainPositionWindward: number;
  maxRainPositionLeeward: number;
  maxRainPositionWindward: number;
  fanStep: number;
  stormFixedPositionLeeward: number;
  stormFixedPositionWindward: number;
  windSpeedInfluenceLeeward: number;
  windSpeedInfluenceWindward: number;
}

export interface VentSettings {
  zone: number;
  vent: number;
  minStep: number;
  autocalibration: boolean;
  autocalibrationTime: number;
}

export interface VentSubmitModel {
  zones: {
    vents: {
      periods: VentPeriod[];
      settings: VentSettings;
    }[];
  }[];
}

/**** FAN STEPS ****/
export interface FanStepsModel {
  zones: FanStepsZone[];
}

export interface FanStepsZone {
  steps: FanStepsStep[];
}

export interface FanStepsStep {
  periods: FanStepsPeriod[];
}

export interface FanStepsPeriod {
  zone: number;
  dirty?: boolean;
  step: number;
  period: number;
  active: boolean;
  startTime: number;
  startReference: any;
  endTime: number;
  endReference: any;
  startLag: number;
  endLag: number;
  rainStop: boolean;
  temperatureStop: number;
  minTimeActivation: number;
  minTimeStopped: number;
}

export interface FanStepsSubmitModel {
  zones: {
    steps: {
      periods: FanStepsPeriod[];
    }[];
  }[];
}

/**** THERMAL INVERSION ****/
export interface ThermalInversionModel {
  zones: ThermalInversionZone[];
}

export interface ThermalInversionZone {
  zone: number;
  dirty?: boolean;
  active: boolean;
  startTime: number;
  startReference: any;
  endTime: number;
  endReference: any;
  outsideIndoorTempDiff: number;
  deadBand: number;
}

export interface ThermalInversionSubmitModel {
  zones: ThermalInversionZone[];
}

/**** HEATING ****/
export interface HeatingTemperatureModel {
  zones: HeatingTemperatureZone[];
}

export interface HeatingTemperatureZone {
  periods: HeatingTemperaturePeriod[];
}

export interface HeatingTemperaturePeriod {
  zone: number;
  dirty?: boolean;
  period: number;
  active: boolean;
  startTime: number;
  startReference: any;
  endTime: number;
  endReference: any;
  heatingTemperature: number;
  maxHeatingTemperature: number;
  minHeatingTemperature: number;
  radiationInfluence: number;
  startRadiationInfluence: number;
  endRadiationInfluence: number;
  coolingDelay: number;
  heatingDelay: number;
}

export interface HeatingTemperatureSubmitModel {
  zones: {
    periods: HeatingTemperaturePeriod[];
  }[];
}

/**** HEATERS ****/
export interface HeatersModel {
  zones: HeatersZone[];
}

export interface HeatersZone {
  steps: HeatersStep[];
}

export interface HeatersStep {
  periods: HeatersPeriod[];
}

export interface HeatersPeriod {
  zone: number;
  dirty?: boolean;
  step: number;
  period: number;
  active: boolean;
  startTime: number;
  startReference: any;
  endTime: number;
  endReference: any;
  startLagging: number;
  stopLagging: number;
  startDelay: number;
  stopDelay: number;
  minTimeActivation: number;
  minTimeStopped: number;
}

export interface HeatersSubmitModel {
  zones: {
    steps: {
      periods: HeatersPeriod[];
    }[];
  }[];
}

/**** PIPES ****/
export interface PipesModel {
  zones: PipesZone[];
}

export interface PipesZone {
  periods: PipesPeriod[];
}

export interface PipesPeriod {
  zone: number;
  dirty?: boolean;
  period: number;
  active: boolean;
  startTime: number;
  startReference: any;
  endTime: number;
  endReference: any;
  coolingDelay: number;
  heatingDelay: number;
  maxPipeTemp: number;
  minPipeTemp: number;
  radiationInfluence: number;
  startRadiationInfluence: number;
  endRadiationInfluence: number;
  humidityInfluence: PipesHumidityInfluence;
}

export interface PipesHumidityInfluence {
  level0: PipesLevel0;
  level1: PipesLevel1;
  level2: PipesLevel2;
  level3: PipesLevel3;
}

export interface PipesLevel0 {
  hr: number;
  correction: number;
}

export interface PipesLevel1 {
  hr: number;
  correction: number;
}

export interface PipesLevel2 {
  hr: number;
  correction: number;
}

export interface PipesLevel3 {
  hr: number;
  correction: number;
}

export interface PipesSubmitModel {
  zones: {
    periods: PipesPeriod[];
  }[];
}

/**** MISTING PROGRAMMING ****/
export interface MistingProgrammingModel {
  zones: MistingZone[];
}

export interface MistingZone {
  periods: MistingPeriod[];
}

export interface MistingPeriod {
  zone: number;
  dirty?: boolean;
  period: number;
  active: boolean;
  startTime: number;
  startReference: any;
  endTime: number;
  endReference: any;
  activationHumidity: number;
  humidityDeadBand: number;
  temperatureLimit: number;
  temperatureDeadBand: number;
  maximumHumidity: number;
  cooling: number;
  temperatureInfluence: MistingTemperatureInfluence;
}

export interface MistingTemperatureInfluence {
  level0: MistingLevel0;
  level1: MistingLevel1;
  level2: MistingLevel2;
  level3: MistingLevel3;
}

export interface MistingLevel0 {
  temperature: number;
  correction: number;
}

export interface MistingLevel1 {
  temperature: number;
  correction: number;
}

export interface MistingLevel2 {
  temperature: number;
  correction: number;
}

export interface MistingLevel3 {
  temperature: number;
  correction: number;
}

export interface MistingProgrammingSubmitModel {
  zones: {
    periods: MistingPeriod[];
  }[];
}

/**** TRANSPORT GROUP ****/
export interface TransportGroupModel {
  programming: TransportGroupProgramming;
  control: TransportGroupControl;
}

export interface TransportGroupProgramming {
  active: number;
  dirty?: boolean;
  startTime: number;
  startReference: any;
  endTime: number;
  endReference: any;
  maxPipeTemp: number;
  minPipeTemp: number;
  pumpActivation: string;
}

export interface TransportGroupControl {
  minPulse: number;
  dirty?: boolean;
  valveTime: number;
  delayTime: number;
  startLag: number;
  weekProtection: boolean;
  maxPipeTemp: number;
  loopTime: number;
  zones: boolean[];
}

/**** MISTING REGULATION ****/
export interface MistingRegulationModel {
  zones: MistingRegulationZone[];
}

export interface MistingRegulationZone {
  zone: number;
  dirty?: boolean;
  minTimeON: number;
  maxSprayTime: number;
  stopTime: number;
  fanUse: boolean;
}

export interface MistingRegulationSubmitModel {
  zones: MistingRegulationZone[];
}

/********** Curtains ****************/
export interface CurtainEnergySubmitModel {
  zones: CurtainEnergyZone[];
}

export interface CurtainEnergyModel {
  zones: CurtainEnergyZone[];
}

export interface CurtainEnergyZone {
  zone: number;
  dirty?: boolean;
  active: boolean;
  startTime: number;
  startReference: any;
  endTime: number;
  endReference: any;
  startBy: string;
  outsideTemperature: number;
  temperatureDeadBand: number;
  radiation: number;
  radiationDeadBand: number;
  maxPosition: number;
}

export interface CurtainShadeSubmitModel {
  zones: CurtainShadeZone[];
}

export interface CurtainShadeModel {
  zones: CurtainShadeZone[];
}

export interface CurtainShadeZone {
  zone: number;
  dirty?: boolean;
  active: boolean;
  startTime: number;
  startReference: any;
  endTime: number;
  endReference: any;
  startBy: string;
  insideTemperature: number;
  temperatureDeadBand: number;
  radiation: number;
  radiationDeadBand: number;
  maxPosition: number;
  useTable: boolean;
  radiationTable: CurtainShadeRadiationTable;
}

export interface CurtainShadeRadiationTable {
  pos0: number;
  pos1: number;
  pos2: number;
  pos3: number;
  pos4: number;
  pos5: number;
  rad0: number;
  rad1: number;
  rad2: number;
  rad3: number;
  rad4: number;
  rad5: number;
}

export interface CurtainRegulationSubmitModel {
  zones: CurtainRegulationZone[];
}

export interface CurtainRegulationModel {
  zones: CurtainRegulationZone[];
}

export interface CurtainRegulationZone {
  zone: number;
  dirty?: boolean;
  openingDelaySteps: number;
  openingFirstStep: number;
  closingDelaySteps: number;
  closingFirstStep: number;
  autocalibration: boolean;
}

export interface CurtainGappingSubmitModel {
  zones: CurtainGappingZone[];
}

export interface CurtainGappingModel {
  zones: CurtainGappingZone[];
}

export interface CurtainGappingZone {
  zone: number;
  humidity: CurtainGappingHumidity;
  temperature: CurtainGappingTemperature;
}

export interface CurtainGappingHumidity {
  active: boolean;
  startTime: number;
  startReference: any;
  endTime: number;
  endReference: any;
  maxGap: number;
  minGap: number;
  startValue: number;
  endValue: number;
}

export interface CurtainGappingTemperature {
  active: boolean;
  startTime: number;
  startReference: any;
  endTime: number;
  endReference: any;
  maxGap: number;
  startValue: number;
}

/*****************************************************/

/********** Curtains Darkening ****************/
export interface CurtainDarkeningSubmitModel {
  zones: CurtainDarkeningZone[];
}

export interface CurtainDarkeningModel {
  zones: CurtainDarkeningZone[];
}

export interface CurtainDarkeningZone {
  zone: number;
  dirty?: boolean;
  active: boolean;
  startTime: number;
  startReference: any;
  endTime: number;
  endReference: any;
  radiation: number;
  radiationTable: CurtainDarkeningRadiationTable;
  forceTable: number;
  maxPosition: number;
  autocalibration: number;
}

export interface CurtainDarkeningRadiationTable {
  pos0: number;
  pos1: number;
  pos2: number;
  pos3: number;
  pos4: number;
  pos5: number;
  rad0: number;
  rad1: number;
  rad2: number;
  rad3: number;
  rad4: number;
  rad5: number;
}
/*****************************************************/

/********** Shading Curtains ****************/
export interface ShadingCurtainSubmitModel {
  zones: ShadingCurtainZone[];
}

export interface ShadingCurtainModel {
  zones: ShadingCurtainZone[];
}

export interface ShadingCurtainZone {
  shading_1: ShadingCurtainObject;
  shading_2: ShadingCurtainObject;
}

export interface ShadingCurtainObject {
  zone: number;
  dirty?: boolean;
  active: boolean;
  startTime: number;
  startReference: any;
  endTime: number;
  endReference: any;
  radiation: number;
  radiationTable: ShadingCurtainRadiationTable;
  forceTable: number;
  maxPosition: number;
  autocalibration: number;
}

export interface ShadingCurtainRadiationTable {
  pos0: number;
  pos1: number;
  pos2: number;
  pos3: number;
  pos4: number;
  pos5: number;
  rad0: number;
  rad1: number;
  rad2: number;
  rad3: number;
  rad4: number;
  rad5: number;
}
/*****************************************************/

/********** Switching program ****************/
export interface SwitchingProgramSubmitModel {
  zones: SwitchingProgramZone[];
}

export interface SwitchingProgramModel {
  zones: SwitchingProgramZone[];
}

export interface SwitchingProgramZone {
  zone: number;
  active: boolean;
  startTime: number;
  startReference: any;
  endTime: number;
  endReference: any;
  dirty?: boolean;
  variable1: SwitchingProgramVariable;
  variable2: SwitchingProgramVariable;
  condition: string;
  runTime: number;
  stopTime: number;
  activationTime: number;
  deactivationTime: number;
}

export interface SwitchingProgramVariable {
  type: string;
  zone: number;
  vent: number;
  step: number;
  sensor: any;
  value: number;
}
/*****************************************************/

/**** HAF FANS PROGRAMMING ****/

export interface HafFansProgrammingModel {
  zones: HafFansProgrammingZone[];
}

export interface HafFansProgrammingZone {
  periods: HafFansProgrammingPeriod[];
}

export interface HafFansProgrammingPeriod {
  zone: number;
  dirty?: boolean;
  period: number;
  active: boolean;
  startTime: number;
  startReference: any;
  endTime: number;
  endReference: any;
  stopIfMisting: boolean;
  temperature: HafFansProgrammingTemperature;
  humidity: HafFansProgrammingHumidity;
  curtainPosition: HafFansProgrammingCurtainPosition;
}

export interface HafFansProgrammingTemperature {
  value: number;
  condition: string;
  deadBand: number;
}

export interface HafFansProgrammingHumidity {
  value: number;
  condition: string;
  deadBand: number;
}

export interface HafFansProgrammingCurtainPosition {
  value: number;
  condition: string;
  deadBand: number;
}

export interface HafFansProgrammingSubmitModel {
  zones: {
    periods: HafFansProgrammingPeriod[];
  }[];
}

/**** HAF FANS ADJUSTING ****/

export interface HafFansAdjustingModel {
  zones: HafFansAdjustingZone[];
}

export interface HafFansAdjustingZone {
  periods: HafFansAdjustingPeriod[];
}

export interface HafFansAdjustingPeriod {
  zone: number;
  period: number;
  dirty?: boolean;
  stepTime: number;
  line0: HafFansAdjustingLine0;
  line1: HafFansAdjustingLine1;
  minTimeON: number;
  minTimeOFF: number;
}

export interface HafFansAdjustingLine0 {
  temperature: boolean;
  humidity: boolean;
  curtains: boolean;
}

export interface HafFansAdjustingLine1 {
  temperature: boolean;
  humidity: boolean;
  curtains: boolean;
}

export interface HafFansAdjustingSubmitModel {
  zones: {
    periods: HafFansAdjustingPeriod[];
  }[];
}

/**** CLIMATE EQUIPMENT ****/
export interface ClimateEquipmentModel extends EquipmentModel {
  pendingChanges: boolean;
  serialNumber: string;
  terminal: number;
  states: ClimateEquipmentStates;
  alarms: ClimateEquipmentAlarm[];
  meteo: MeteoClimateNew[];
  climate_configuration: ClimateConfigurationEquipment;
}

export interface MeteoClimateNew {
  key: string;
  value: number;
  name?: string;
  unit: string;
  origin: string;
}

export interface ClimateEquipmentAlarm {
  code: number;
  tag: string;
  zone?: number;
}

export interface ClimateEquipmentStates {
  zones: ClimateEquipmentZone[];
}

export interface ClimateEquipmentZone {
  hum: number;
  temp: number;
  finalHeatTmp: number;
  actualHeatTmp: number;
  fanStep?: ClimateEquipmentFanStep;
  finalVentTmp: number;
  vpd: number;
  humid?: ClimateEquipmentHumid;
  vents: ClimateEquipmentVent[];
  en_sh_curtain?: ClimateEquipmentEnShCurtain;
  zone: number;
  heaters?: ClimateEquipmentHeaters;
  ventTmpPeriod: number;
  heatTmpPeriod: number;
}

export interface ClimateEquipmentFanStep {
  period: number;
  steps: number;
}

export interface ClimateEquipmentHumid {
  period: number;
  TON: number;
  delay: number;
  stateText: string;
  state: number;
  on_off: boolean;
}

export interface ClimateEquipmentVent {
  calcPos: number;
  period: number;
  pos: number;
  nvent: number;
  ventTemp: number;
  wind: string;
}

export interface ClimateEquipmentEnShCurtain {
  finalPos: number;
  state: number;
  actPos: number;
}

export interface ClimateEquipmentHeaters {
  period: number;
  step0: boolean;
  step1: boolean;
}

export interface ClimateEquipmentMeteo {
  TEMPERATURE: number;
  HUMIDITY: number;
  WIND_VELOCITY: number;
  WIND_DIRECTION: number;
  RADIATION: number;
  PLUVIOMETER: number;
  ACCUMULATED_RADIATION: number;
  WIND_LEVEL_0: number;
  WIND_LEVEL_1: number;
}

export interface ClimateConfigurationEquipment {
  zones: ClimateEquipmentZone2[];
  synchroHour: string;
  numVents: number;
  numFanSteps: number;
  numHeaterSteps: number;
  numHafFanSteps: number;
  temperatureUnit: string;
  humidityUnit: string;
  velocityUnit: string;
  directionUnit: string;
  radiationUnit: string;
  scheduleUnit: string;
}

export interface ClimateEquipmentZone2 {
  zone: number;
  name: string;
  vents: Vent2[];
}

export interface Vent2 {
  vent: number;
  name?: string;
}

export interface Root {
  zones: Zone[];
}

export interface Zone {
  periods: Period[];
}

export interface Period {
  zone: number;
  period: number;
  stepTime: string;
  line0: Line0;
  line1: Line1;
  minTimeON: string;
  minTimeOFF: string;
}

export interface Line0 {
  temperature: boolean;
  humidity: boolean;
  curtains: boolean;
}

export interface Line1 {
  temperature: boolean;
  humidity: boolean;
  curtains: boolean;
}

/**** ALARM ZONE ****/
export interface AlarmsZoneModel {
  zones: AlarmsZone[];
}

export interface AlarmsZone {
  zone: number;
  dirty?: boolean;
  common: AlarmsCommon;
  minCO2: AlarmsMinCo2;
  maxCO2: AlarmsMaxCo2;
}

export interface AlarmsCommon {
  minTemperature: number;
  maxTemperature: number;
  minHumidity: number;
  maxHumidity: number;
  minTempPipe: number;
  maxTempPipe: number;
  ONDelay: number;
}

export interface AlarmsMinCo2 {
  value: number;
  ONDelay: number;
  OFFDelay: number;
}

export interface AlarmsMaxCo2 {
  value: number;
  ONDelay: number;
  OFFDelay: number;
}

export interface AlarmsZoneSubmitModel {
  zones: AlarmsZone[];
}

/**** FROST ALARM ****/
export interface FrostAlarmModel {
  frostTemp: number;
  dirty?: boolean;
  ONDelay: number;
  OFFDelay: number;
  radiationCorrectionBegin: number;
  radiationCorrectionEnd: number;
  maxTemperatureCorrection: number;
}

/**** STORM ALARM ****/
export interface StormAlarmModel {
  levels: StormAlarmLevelModel[];
}

export interface StormAlarmLevelModel {
  level: number;
  dirty?: boolean;
  windSpeed: number;
  startWindSpeedInfluence: number;
  ONDelay: number;
  OFFDelay: number;
  windDirectionDeadBand: number;
  stepDelayTime: number;
}

/**** STORM ALARM ****/
export interface AlarmsStormSubmitModel {
  levels: StormAlarmLevelModel[];
}

/**** RAIN ALARM ****/
export interface RainAlarmModel {
  ONDelay: number;
  OFFDelay: number;
  dirty?: boolean;
  flow: number;
}

/**** CO2 ****/
export interface CO2Model {
  zones: CO2Zone[];
}

export interface CO2Zone {
  periods: CO2Period[];
}

export interface CO2Period {
  zone: number;
  dirty?: boolean;
  period: number;
  active: boolean;
  startTime: number;
  startReference: any;
  endTime: number;
  endReference: any;
  idealCO2: number;
  minimumCO2: number;
  deadBandActivation: number;
  deadBandDeactivation: number;
  activation: CO2Activation;
  liquidCO2: boolean;
  heaters: number;
  minHumidity: number;
  maxTemperature: number;
  radiationInfluence: CO2RadiationInfluence[];
  ventInfluence: CO2VentInfluence[];
  fanStepInfluence: CO2FanStepInfluence[];
  windSpeedInfluence: CO2WindSpeedInfluence[];
  humidityInfluence: CO2HumidityInfluence[];
  temperatureInfluence: CO2TemperatureInfluence[];
}

export interface CO2Activation {
  schedule: boolean;
  assimilationLight: boolean;
  external: boolean;
}

export interface CO2RadiationInfluence {
  level0?: CO2Level0;
  level1?: CO2Level1;
  level2?: CO2Level2;
  level3?: CO2Level3;
}

export interface CO2Level0 {
  correction: number;
  radiation: number;
}

export interface CO2Level1 {
  correction: number;
  radiation: number;
}

export interface CO2Level2 {
  correction: number;
  radiation: number;
}

export interface CO2Level3 {
  correction: number;
  radiation: number;
}

export interface CO2VentInfluence {
  level0?: CO2Level02;
  level1?: CO2Level12;
  level2?: CO2Level22;
  level3?: CO2Level32;
}

export interface CO2Level02 {
  correction: number;
  vent: number;
}

export interface CO2Level12 {
  correction: number;
  vent: number;
}

export interface CO2Level22 {
  correction: number;
  vent: number;
}

export interface CO2Level32 {
  correction: number;
  vent: number;
}

export interface CO2FanStepInfluence {
  level0?: CO2Level03;
  level1?: CO2Level13;
  level2?: CO2Level23;
  level3?: CO2Level33;
}

export interface CO2Level03 {
  correction: number;
  fanStep: number;
}

export interface CO2Level13 {
  correction: number;
  fanStep: number;
}

export interface CO2Level23 {
  correction: number;
  fanStep: number;
}

export interface CO2Level33 {
  correction: number;
  fanStep: number;
}

export interface CO2WindSpeedInfluence {
  level0?: CO2Level04;
  level1?: CO2Level14;
  level2?: CO2Level24;
  level3?: CO2Level34;
}

export interface CO2Level04 {
  correction: number;
  wind: number;
}

export interface CO2Level14 {
  correction: number;
  wind: number;
}

export interface CO2Level24 {
  correction: number;
  wind: number;
}

export interface CO2Level34 {
  correction: number;
  wind: number;
}

export interface CO2HumidityInfluence {
  level0?: CO2Level05;
  level1?: CO2Level15;
  level2?: CO2Level25;
  level3?: CO2Level35;
}

export interface CO2Level05 {
  correction: number;
  hr: number;
}

export interface CO2Level15 {
  correction: number;
  hr: number;
}

export interface CO2Level25 {
  correction: number;
  hr: number;
}

export interface CO2Level35 {
  correction: number;
  hr: number;
}

export interface CO2TemperatureInfluence {
  level0?: CO2Level06;
  level1?: CO2Level16;
  level2?: CO2Level26;
  level3?: CO2Level36;
}

export interface CO2Level06 {
  correction: number;
  temperature: number;
}

export interface CO2Level16 {
  correction: number;
  temperature: number;
}

export interface CO2Level26 {
  correction: number;
  temperature: number;
}

export interface CO2Level36 {
  correction: number;
  temperature: number;
}

export interface CO2SubmitModel {
  zones: {
    periods: CO2Period[];
  }[];
}

/**** START BY CO2 BUTTON ****/
export interface StartByCO2ButtonModel {
  label: string;
}

/**** INSTALLER PIPES ****/
export interface InstallerPipeModel {
  zones: InstallerPipeZone[];
}

export interface InstallerPipeZone {
  zone: number;
  dirty?: boolean;
  minPulse: number;
  valveTime: number;
  delayTime: number;
  startLag: number;
  weekProtection: boolean;
  maxPipeTemp: number;
  loopTime: number;
  pFactor: number;
  iFactor: number;
  outsideTempInfluence: number;
}

export interface InstallerPipesSubmitModel {
  zones: InstallerPipeZone[];
}

/**** INSTALLER MISTING ****/
export interface InstallerMistingModel {
  zones: InstallerMistingZone[];
}

export interface InstallerMistingZone {
  zone: number;
  dirty?: boolean;
  pFactor: number;
  iFactor: number;
}

export interface InstallerMistingSubmitModel {
  zones: InstallerMistingZone[];
}

/**** INSTALLER MIXED CURTAINS ****/

export interface InstallerMixedCurtainsModel {
  zones: InstallerMixedCurtainsZone[];
}

export interface InstallerMixedCurtainsZone {
  zone: number;
  dirty?: boolean;
  runTime: number;
  radiationDelay: number;
  securityTime: number;
  prefunctionTime: number;
  closeFirst: boolean;
}

export interface InstallerMixedCurtainsSubmitModel {
  zones: InstallerMixedCurtainsZone[];
}

/**** INSTALLER DARKENING ****/
export interface InstallerDarkeningCurtainsModel {
  zones: InstallerDarkeningCurtainsZone[];
}

export interface InstallerDarkeningCurtainsSubmitModel {
  zones: InstallerDarkeningCurtainsZone[];
}

export interface InstallerDarkeningCurtainsZone {
  zone: number;
  dirty?: boolean;
  runTime: number;
  radiationDelay: number;
  securityTime: number;
  prefunctionTime: number;
}

/**** INSTALLER SHADING CURTAINS ****/
export interface InstallerShadingCurtainsModel {
  zones: InstallerShadingCurtainsZone[];
}

export interface InstallerShadingCurtainsZone {
  shading_1: InstallerShadingCurtainsShading1;
  shading_2: InstallerShadingCurtainsShading2;
}

export interface InstallerShadingCurtainsShading1 {
  zone: number;
  dirty?: boolean;
  runTime: number;
  radiationDelay: number;
  securityTime: number;
  prefunctionTime: number;
}

export interface InstallerShadingCurtainsShading2 {
  zone: number;
  dirty?: boolean;
  runTime: number;
  radiationDelay: number;
  securityTime: number;
  prefunctionTime: number;
}

export interface InstallerShadingCurtainsSubmitModel {
  zones: InstallerShadingCurtainsZone[];
}

/**** INSTALLER VENTS ****/
export interface InstallerVentsModel {
  zones: InstallerVentsZone[];
}

export interface InstallerVentsZone {
  vents: InstallerVentsVent[];
}

export interface InstallerVentsVent {
  zone: number;
  dirty?: boolean;
  vent: number;
  type: string;
  alarmCalibration: boolean;
  direction: number;
  runTime: number;
  prefunctionTime: number;
  leewardStormAlarm: number;
  windwardStormAlarm: number;
  forceSignal: boolean;
  priority: number;
}

export interface InstallerVentsSubmitModel {
  zones: {
    vents: InstallerVentsVent[];
  }[];
}

/**** INSTALLER CO2 ****/
export interface InstallerCO2Model {
  zones: InstallerCO2Zone[];
}

export interface InstallerCO2Zone {
  zone: number;
  dirty?: boolean;
  vent: string;
}

export interface InstallerCO2SubmitModel {
  zones: InstallerCO2Zone[];
}

/**** DESKTOP CONFIGURATION ****/

export interface DesktopClimateModel {
  meteo: DesktopClimateMeteo;
  zones: DesktopClimateZone[];
}

export interface DesktopClimateMeteo {
  hum: number;
  cummRad: number;
  temp: number;
  instRad: number;
  alarms: DesktopClimateAlarms;
  pluv: number;
  windDir: number;
  windSpeed: number;
}

export interface DesktopClimateAlarms {
  rain: boolean;
  storm0: boolean;
  frost: boolean;
  storm1: boolean;
}

export interface DesktopClimateZone {
  hum?: number;
  temp?: number;
  finalHeatTmp?: number;
  actualHeatTmp?: number;
  alarms: DesktopClimateAlarms2;
  fanStep?: FanStep;
  finalVentTmp?: number;
  vpd?: number;
  humid?: DesktopClimateHumid;
  vents: DesktopClimateVent[];
  en_sh_curtain?: DesktopClimateEnShCurtain;
  zone: number;
  heaters?: DesktopClimateHeaters;
  ventTmpPeriod: number;
  heatTmpPeriod: number;
}

export interface DesktopClimateAlarms2 {
  highPipeTemp: boolean;
  lowHum: boolean;
  external: boolean;
  lowPipeTemp: boolean;
  highCO2: boolean;
  thermInv: boolean;
  lowTemp: boolean;
  highTemp: boolean;
  lowCO2: boolean;
  highHum: boolean;
}

export interface DesktopClimateFanStep {
  period: number;
  steps: number;
}

export interface DesktopClimateHumid {
  period: number;
  TON: number;
  delay: number;
  stateText: string;
  state: number;
  on_off: boolean;
}

export interface DesktopClimateVent {
  calcPos: number;
  period: number;
  pos: number;
  nvent: number;
  ventTemp: number;
  wind: string;
}

export interface DesktopClimateEnShCurtain {
  finalPos: number;
  state: number;
  actPos: number;
}

export interface DesktopClimateHeaters {
  period: number;
  step0: boolean;
  step1: boolean;
}

/**** INSTALLER CONFIGURATION ****/
export type InstallerZoneModel = InstallerZone[];

export interface InstallerZone {
  zone: number;
  name: string;
}

export interface InstallerZonesSubmitModel {
  zones: InstallerZone[];
}

/**** NEW DESKTOP CONFIGURATION ****/

export interface NewDesktopClimateModel {
  zones: NewDesktopClimateZone[];
  meteo: NewDesktopClimateMeteo;
}

export interface NewDesktopClimateZone {
  zone: number;
  groups: NewDesktopClimateGroups;
}

export interface NewDesktopClimateGroups {
  vent?: NewDesktopClimateVent;
  common?: NewDesktopClimateCommon;
  alarm: NewDesktopClimateAlarm;
  curtain?: NewDesktopClimateCurtain;
  misting?: NewDesktopClimateMisting;
  heat?: NewDesktopClimateHeat;
}

export interface NewDesktopClimateVent {
  fields: NewDesktopClimateVentFields;
}

export interface NewDesktopClimateValueObject {
  unit: string;
  zone: number;
  value: string;
  key: string;
  group: string;
}

export interface NewDesktopClimateVentFields {
  fanStepState: NewDesktopClimateValueObject;
  fanStepVTemp: NewDesktopClimateValueObject;
  V1_ventPos: NewDesktopClimateValueObject;
  V0_ventPos: NewDesktopClimateValueObject;
  V0_calcVTemp: NewDesktopClimateValueObject;
}

export interface NewDesktopClimateCommon {
  fields: NewDesktopClimateCommonFields;
}

export interface NewDesktopClimateCommonFields {
  zoneDPV: NewDesktopClimateValueObject;
  zoneHum: NewDesktopClimateValueObject;
  zoneTemp: NewDesktopClimateValueObject;
}

export interface NewDesktopClimateAlarm {
  fields: NewDesktopClimateAlarmsFields;
}

export interface NewDesktopClimateAlarmsFields {
  highTempAl: NewDesktopClimateValueObject;
  lowCO2Al: NewDesktopClimateValueObject;
  highHumAl: NewDesktopClimateValueObject;
  lowHumAl: NewDesktopClimateValueObject;
  lowTempAl: NewDesktopClimateValueObject;
  lowPipeTempAl: NewDesktopClimateValueObject;
  highCO2Al: NewDesktopClimateValueObject;
  externalAl: NewDesktopClimateValueObject;
  highPipeTempAl: NewDesktopClimateValueObject;
  thermalInvAl: NewDesktopClimateValueObject;
}

export interface NewDesktopClimateCurtain {
  fields: NewDesktopClimateCurtainFields;
}

export interface NewDesktopClimateCurtainFields {
  e_sCurtainPos: NewDesktopClimateValueObject;
}

export interface NewDesktopClimateMisting {
  fields: NewDesktopClimateMistingFields;
}

export interface NewDesktopClimateMistingFields {
  mistingState: NewDesktopClimateValueObject;
  mistingTON: NewDesktopClimateValueObject;
}

export interface NewDesktopClimateHeat {
  fields: NewDesktopClimateHeatFields;
}

export interface NewDesktopClimateHeatFields {
  heaters?: NewDesktopClimateValueObject;
  calcHTemp: NewDesktopClimateValueObject;
}

export interface NewDesktopClimateMeteo {
  fields: NewDesktopClimateMeteoFields;
}

export interface NewDesktopClimateMeteoFields {
  key?: string;
  unit?: string;
  Met_time: NewDesktopClimateMeteoValueObject;
  Met_temp: NewDesktopClimateMeteoValueObject;
  Met_rain: NewDesktopClimateMeteoValueObject;
  Met_windDir: NewDesktopClimateMeteoValueObject;
  Met_instRad: NewDesktopClimateMeteoValueObject;
  Met_freezeAl: NewDesktopClimateMeteoValueObject;
  Met_pluv: NewDesktopClimateMeteoValueObject;
  Met_day: NewDesktopClimateMeteoValueObject;
  Met_stormAl1: NewDesktopClimateMeteoValueObject;
  Met_stormAl2: NewDesktopClimateMeteoValueObject;
  Met_hum: NewDesktopClimateMeteoValueObject;
  Met_windSpeed: NewDesktopClimateMeteoValueObject;
  Met_cummRad: NewDesktopClimateMeteoValueObject;
}

export interface NewDesktopClimateMeteoValueObject {
  unit: string;
  value: string;
  key: string;
  group: string;
}

/**** STATES STRUCTURE ****/

export interface DesktopClimateStatesStructureModel {
  zones: DesktopClimateStatesStructureZone[];
  meteo: DesktopClimateStatesStructureMeteo;
}

export interface DesktopClimateStatesStructureZone {
  zone: number;
  visible: boolean;
  groups: DesktopClimateStatesStructureGroups;
}

export interface DesktopClimateStatesStructureGroups {
  vent?: DesktopClimateStatesStructureVent;
  common?: DesktopClimateStatesStructureCommon;
  alarm: DesktopClimateStatesStructureAlarm;
  curtain?: DesktopClimateStatesStructureCurtain;
  misting?: DesktopClimateStatesStructureMisting;
  heat?: DesktopClimateStatesStructureHeat;
}

export interface DesktopClimateStatesStructureVent {
  visible: boolean;
  expanded: boolean;
  fields: DesktopClimateStatesStructureVentFields;
}

export interface DesktopClimateStatesStructureVentFields {
  fanStepState: DesktopClimateStatesStructureValueObject;
  fanStepVTemp: DesktopClimateStatesStructureValueObject;
  V1_ventPos: DesktopClimateStatesStructureValueObject;
  V0_ventPos: DesktopClimateStatesStructureValueObject;
  V0_calcVTemp: DesktopClimateStatesStructureValueObject;
}

export interface DesktopClimateStatesStructureValueObject {
  visible: boolean;
  zone: number;
  key: string;
}

export interface DesktopClimateStatesStructureCommon {
  visible: boolean;
  expanded: boolean;
  fields: DesktopClimateStatesStructureCommonFields;
}

export interface DesktopClimateStatesStructureCommonFields {
  zoneDPV: DesktopClimateStatesStructureValueObject;
  zoneHum: DesktopClimateStatesStructureValueObject;
  zoneTemp: DesktopClimateStatesStructureValueObject;
}

export interface DesktopClimateStatesStructureAlarm {
  visible: boolean;
  expanded: boolean;
  fields: DesktopClimateStatesStructureAlarmFields;
}

export interface DesktopClimateStatesStructureAlarmFields {
  highTempAl: DesktopClimateStatesStructureValueObject;
  lowCO2Al: DesktopClimateStatesStructureValueObject;
  highHumAl: DesktopClimateStatesStructureValueObject;
  lowHumAl: DesktopClimateStatesStructureValueObject;
  lowTempAl: DesktopClimateStatesStructureValueObject;
  lowPipeTempAl: DesktopClimateStatesStructureValueObject;
  highCO2Al: DesktopClimateStatesStructureValueObject;
  externalAl: DesktopClimateStatesStructureValueObject;
  highPipeTempAl: DesktopClimateStatesStructureValueObject;
  thermalInvAl: DesktopClimateStatesStructureValueObject;
}

export interface DesktopClimateStatesStructureCurtain {
  visible: boolean;
  expanded: boolean;
  fields: DesktopClimateStatesStructureCurtainFields;
}

export interface DesktopClimateStatesStructureCurtainFields {
  e_sCurtainPos: DesktopClimateStatesStructureValueObject;
}

export interface DesktopClimateStatesStructureMisting {
  visible: boolean;
  expanded: boolean;
  fields: DesktopClimateStatesStructureMistingFields;
}

export interface DesktopClimateStatesStructureMistingFields {
  mistingState: DesktopClimateStatesStructureValueObject;
  mistingTON: DesktopClimateStatesStructureValueObject;
}

export interface DesktopClimateStatesStructureHeat {
  visible: boolean;
  expanded: boolean;
  fields: DesktopClimateStatesStructureHeatFields;
}

export interface DesktopClimateStatesStructureHeatFields {
  heaters?: DesktopClimateStatesStructureValueObject;
  calcHTemp: DesktopClimateStatesStructureValueObject;
}

export interface DesktopClimateStatesStructureMeteo {
  visible: boolean;
  expanded: boolean;
  fields: DesktopClimateStatesStructureMeteoFields;
}

export interface DesktopClimateStatesStructureMeteoFields {
  Met_time: DesktopClimateStatesStructureMeteoValueObject;
  Met_temp: DesktopClimateStatesStructureMeteoValueObject;
  Met_rain: DesktopClimateStatesStructureMeteoValueObject;
  Met_windDir: DesktopClimateStatesStructureMeteoValueObject;
  Met_instRad: DesktopClimateStatesStructureMeteoValueObject;
  Met_freezeAl: DesktopClimateStatesStructureMeteoValueObject;
  Met_pluv: DesktopClimateStatesStructureMeteoValueObject;
  Met_day: DesktopClimateStatesStructureMeteoValueObject;
  Met_stormAl1: DesktopClimateStatesStructureMeteoValueObject;
  Met_stormAl2: DesktopClimateStatesStructureMeteoValueObject;
  Met_hum: DesktopClimateStatesStructureMeteoValueObject;
  Met_windSpeed: DesktopClimateStatesStructureMeteoValueObject;
  Met_cummRad: DesktopClimateStatesStructureMeteoValueObject;
}

export interface DesktopClimateStatesStructureMeteoValueObject {
  visible: boolean;
  key: string;
}

export enum CancelAlarmsType {
  PROGRAM_START = 'PROGRAM_START',
  PROGRAM_STOP = 'PROGRAM_STOP',
  CANCEL_ALARMS = 'CANCEL_ALARMS',
  FILTER_START = 'FILTER_START',
  FILTER_STOP = 'FILTER_STOP',
  BLOCK_WATER_SYSTEM = 'BLOCK_WATER_SYSTEM',
}

export interface CancelAlarmsModel {
  action: CancelAlarmsType;
  number?: number;
}

export const GroupsOrder = [
  'common', // común
  'vent', // ventana
  'curtain', // pantalla
  'heat', // calefacción
  'haffan', // destratificadores
  'misting', // humidificacion
  'swithing',
  'CO2', // co2
  'alarm', // alarmas
];
