<div class="card mx-2 my-4" *ngIf="viewMode ===1">
  <!--<div class="row mx-2">
    <div class="col-9">
      <mat-form-field  appearance="fill" style="border:0px; padding: 0px;">
        <mat-date-range-input [formGroup]="range" [rangePicker]="picker">
          <input matStartDate formControlName="start" placeholder="{{translationsLib.get('graphics_init_date')}}">
          <input matEndDate formControlName="end" placeholder="{{translationsLib.get('graphics_end_date')}}">
        </mat-date-range-input>
        <mat-datepicker-toggle matIconSuffix [for]="picker" ></mat-datepicker-toggle>
        <mat-date-range-picker #picker></mat-date-range-picker>
        <mat-error *ngIf="range.controls.start.hasError('matStartDateInvalid')">{{translationsLib.get('graphics_invalid_start_date')}}</mat-error>
        <mat-error *ngIf="range.controls.end.hasError('matEndDateInvalid')">{{translationsLib.get('graphics_invalid_end_date')}}</mat-error>
      </mat-form-field>
    </div>
    <div class="col-3 text-end">
      <div class="btn btn-primary" (click)="createGraph()">
        {{translationsLib.get('graphics_create_graph')}}
      </div>
    </div>
  </div>-->
  <h4 class="mx-4 text-uppercase" style="color: #ee7869">{{translationsLib.get('modules_irrigation_advisor')}}</h4>
  <lib-loader *ngIf="loadedGraph!== true"></lib-loader>
  <highcharts-chart [Highcharts]="Highcharts"
                    [constructorType]="chartConstructor"
                    [options]="irrigationChart"
                    [(update)]="updateFlag"
                    [callbackFunction]="chartCallback"
                    [oneToOne]="true"
                    style="height: 450px; display: block;"
                    class="mb-2"
  >
  </highcharts-chart>
</div>
<div  *ngIf="viewMode !== 1">
  <mat-tab-group mat-align-tabs="start" (selectedTabChange)="onTabChange($event)">
    <mat-tab [label]="translationsLib.get('DL_24_hours')">
      <ng-container *ngIf="selectedTabIndex === 0">
        <lib-loader *ngIf="loadedGraph !== true"></lib-loader>
        <h4 class="text-muted fw-normal fst-italic text-end mb-1 mx-1">{{translationsLib.get('graphics_interval_advice')}} 5 {{translationsLib.get('graphics_minutes')}}</h4>
        <highcharts-chart [Highcharts]="Highcharts"
                          [constructorType]="chartConstructor"
                          [options]="irrigationChart"
                          [(update)]="updateFlag"
                          [callbackFunction]="chartCallback"
                          [oneToOne]="true"
                          style="height: 450px; display: block;"
                          class="mb-2 mt-4"
        >
        </highcharts-chart>
      </ng-container>
    </mat-tab>
    <mat-tab [label]="translationsLib.get('DL_2_days')">
      <ng-container *ngIf="selectedTabIndex === 1">
        <lib-loader *ngIf="loadedGraph !== true"></lib-loader>
        <h4 class="text-muted fw-normal fst-italic text-end mb-1 mx-1">{{translationsLib.get('graphics_interval_advice')}} 5 {{translationsLib.get('graphics_minutes')}}</h4>
        <highcharts-chart [Highcharts]="Highcharts"
                          [constructorType]="chartConstructor"
                          [options]="irrigationChart"
                          [(update)]="updateFlag"
                          [callbackFunction]="chartCallback"
                          [oneToOne]="true"
                          style="height: 450px; display: block;"
                          class="mb-2 mt-4"
        >
        </highcharts-chart>
      </ng-container>
    </mat-tab>
    <mat-tab [label]="translationsLib.get('DL_7_days')">
      <ng-container *ngIf="selectedTabIndex === 2">
        <lib-loader *ngIf="loadedGraph !== true"></lib-loader>
        <h4 class="text-muted fw-normal fst-italic text-end mb-1 mx-1">{{translationsLib.get('graphics_interval_advice')}} 15 {{translationsLib.get('graphics_minutes')}}</h4>
        <highcharts-chart [Highcharts]="Highcharts"
                          [constructorType]="chartConstructor"
                          [options]="irrigationChart"
                          [(update)]="updateFlag"
                          [callbackFunction]="chartCallback"
                          [oneToOne]="true"
                          style="height: 450px; display: block;"
                          class="mb-2 mt-4"
        >
        </highcharts-chart>
      </ng-container>
    </mat-tab>
    <mat-tab [label]="translationsLib.get('DL_last_month')">
      <ng-container *ngIf="selectedTabIndex === 3">
        <lib-loader *ngIf="loadedGraph !== true"></lib-loader>
        <h4 class="text-muted fw-normal fst-italic text-end mb-1 mx-1">{{translationsLib.get('graphics_interval_advice')}} 30 {{translationsLib.get('graphics_minutes')}}</h4>
        <highcharts-chart [Highcharts]="Highcharts"
                          [constructorType]="chartConstructor"
                          [options]="irrigationChart"
                          [(update)]="updateFlag"
                          [callbackFunction]="chartCallback"
                          [oneToOne]="true"
                          style="height: 450px; display: block;"
                          class="mb-2 mt-4"
        >
        </highcharts-chart>
      </ng-container>
    </mat-tab>
    <mat-tab [label]="translationsLib.get('irrigation_consulting_personalized_dates')">
      <ng-container *ngIf="selectedTabIndex === 4">
        <div class="row">
          <div class="col-12 text-end">
            <mat-form-field class="selector" appearance="fill">
              <mat-date-range-input [formGroup]="range" [rangePicker]="picker">
                <input matStartDate formControlName="start" placeholder="Start date">
                <input matEndDate formControlName="end" placeholder="End date">
              </mat-date-range-input>
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-date-range-picker #picker></mat-date-range-picker>
            </mat-form-field>
            <button class="btn btn-primary mx-2" (click)="createPersonalizedGraph()">{{translationsLib.get('graphics_create_graph')}}</button>
          </div>
        </div>
        <div class="row" >
          <lib-loader style="min-height: 100px" *ngIf="loadedGraph !== true"></lib-loader>
          <highcharts-chart [hidden]="hideChart === true"
                            [Highcharts]="Highcharts"
                            [constructorType]="chartConstructor"
                            [options]="irrigationChart"
                            [(update)]="updateFlag"
                            [callbackFunction]="chartCallback"
                            [oneToOne]="true"
                            style="height: 450px; display: block;"
                            class="mb-2 mt-4"
          >
          </highcharts-chart>
        </div>
      </ng-container>
    </mat-tab>
  </mat-tab-group>
</div>
