import { Injectable } from '@angular/core';
import { TranslationsLibService } from '@nutricontrol/app360-shared';

@Injectable({
  providedIn: 'root',
})
export class HighchartsLibService {
  constructor(public translationsLib: TranslationsLibService) {}

  getGlobalBluePaletteColors(): string[] {
    return [
      '#1D2F6F',
      '#8390FA',
      '#339DB1',
      '#FAC748',
      '#FF7350',
      '#FF1175',
      '#0032b4',
      '#0083FC',
      '#8c288c',
      '#6700ff',
    ];
  }

  getGlobalPurplePaletteColors(): string[] {
    return [
      '#9b20d9',
      '#9215ac',
      '#861ec9',
      '#7a17e6',
      '#7010f9',
      '#691af3',
      '#6225ed',
      '#5b30e7',
      '#533be1',
      '#4c46db',
      '#4551d5',
      '#3e5ccf',
      '#3667c9',
      '#2f72c3',
      '#277dbd',
      '#1f88b7',
      '#1693b1',
      '#0a9eaa',
      '#03c69b',
      '#00f194',
    ];
  }

  getDarkGlobalPaletteColors(random: boolean): string[] {
    const palette = [
      '#1D2F6F',
      '#601c67',
      '#b04f1b',
      '#b0344e',
      '#7A7485',
      '#332C49',
      '#44BEA4',
      '#6700ff',
      '#268828',
      '#b0344e',
      '#88861b',
    ];
    if (random === false) {
      return palette;
    } else {
      return palette
        .map((value) => ({ value, sort: Math.random() }))
        .sort((a, b) => a.sort - b.sort)
        .map(({ value }) => value);
    }
  }
  getGlobalGreenPaletteColors(): string[] {
    return [
      '#1a5657',
      '#1aa657',
      '#537233',
      '#b7efa1',
      '#515359',
      '#b4835c',
      '#178069',
      '#a69f06',
    ];
  }

  getGlobalPaletteColors1(): string[] {
    return ['#1827C2', '#BF00A1', '#FF1175', '#FF7350', '#FFBA45', '#F9F871'];
  }

  getCSSVariableValue(variableName: string): string {
    const root = document.documentElement;
    return getComputedStyle(root).getPropertyValue(variableName).trim();
  }

  getCorporatePaletteColorByKey(key: string): string {
    switch (key.toLowerCase()) {
      case 'terminal_ntc':
        return '#152842';
      case 'terminal_xilema':
        return '#005DB9';
      case 'terminal_irrigation':
        return this.getCSSVariableValue('--irrigation-border');
      case 'terminal_climate':
        return this.getCSSVariableValue('--climate-border');
      case 'terminal_datalogger':
        return this.getCSSVariableValue('--datalogger-border');
      case 'terminal_configurable':
        return this.getCSSVariableValue('--configurable-border');
      case 'terminal_carts':
        return this.getCSSVariableValue('--terminals-border');
      case 'terminal_unknown':
        return this.getCSSVariableValue('--cancel-color');
      default:
        return null;
    }
  }

  getCorporatePaletteColors() {
    return [
      this.getCSSVariableValue('--irrigation-border'),
      this.getCSSVariableValue('--climate-border'),
      this.getCSSVariableValue('--datalogger-border'),
      this.getCSSVariableValue('--configurable-border'),
      this.getCSSVariableValue('--brown-color'),
      this.getCSSVariableValue('--orange-color'),
      this.getCSSVariableValue('--violet-color'),
      this.getCSSVariableValue('--purple-color'),
      this.getCSSVariableValue('--lightblue-color'),
      this.getCSSVariableValue('--green-color'),
    ];
  }

  getLangOptions(): any {
    return {
      contextButtonTitle: 'Chart context menu',
      decimalPoint: '.',
      downloadCSV: this.translationsLib.get('highcharts_download_csv'),
      downloadJPEG: this.translationsLib.get('highcharts_download_jpeg'),
      downloadMIDI: 'Download MIDI',
      downloadPDF: this.translationsLib.get('highcharts_download_pdf'),
      downloadPNG: this.translationsLib.get('highcharts_download_png'),
      downloadSVG: this.translationsLib.get('highcharts_download_svg'),
      downloadXLS: this.translationsLib.get('highcharts_download_xls'),
      exitFullscreen: this.translationsLib.get('highcharts_exit_fullscreen'),
      hideData: this.translationsLib.get('highcharts_hide_data_table'),
      invalidDate: undefined,
      loading: this.translationsLib.get('highcharts_loading'),
      mainBreadcrumb: 'Main',
      months: [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
      ],
      //exportData:{},
      //navigation:{...},
      noData: 'No data to display',
      numericSymbolMagnitude: 1000,
      numericSymbols: ['k', 'M', 'G', 'T', 'P', 'E'],
      playAsSound: 'Play as sound',
      printChart: this.translationsLib.get('highcharts_print_chart'),
      resetZoom: this.translationsLib.get('highcharts_reset_zoom'),
      resetZoomTitle: this.translationsLib.get('highcharts_reset_zoom'),
      shortMonths: [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec',
      ],
      shortWeekdays: undefined,
      thousandsSep: ',',
      viewData: this.translationsLib.get('highcharts_view_data_table'),
      viewFullscreen: this.translationsLib.get('highcharts_enter_full_screen'),
      weekdays: [
        'Sunday',
        'Monday',
        'Tuesday',
        'Wednesday',
        'Thursday',
        'Friday',
        'Saturday',
      ],
    };
  }
}
