import { Component, Input } from '@angular/core';
import {
  WaterSystemFieldFertilizerValue,
  WaterSystemFieldFertilizer,
} from '../../models/water-systems.model';
import { TranslationsLibService } from '@nutricontrol/app360-shared';

@Component({
  selector: 'app-field-fertilizer-formula',
  templateUrl: './field-fertilizer-formula.component.html',
  styleUrls: ['./field-fertilizer-formula.component.scss'],
})
export class FieldFertilizerFormulaComponent {
  @Input() field: WaterSystemFieldFertilizer;
  @Input() isMobile = false;

  constructor(protected translationsLib: TranslationsLibService) {
    //
  }

  protected mobileFertilizersSpaceCol(
    fertilizers: WaterSystemFieldFertilizerValue[]
  ): number {
    return 4 - (fertilizers.length - 8) * 2;
  }
}
