import { Component, Input, OnInit, AfterViewInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import {
  TranslationsLibService,
  UserIdentityDataModel,
} from '@nutricontrol/app360-shared';
import { TerminalsService } from '../../../terminals/terminals.service';
import { PermissionsService } from '../../permissions.service';
import Swal from 'sweetalert2';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';

@Component({
  selector: 'app-permission-table-main',
  templateUrl: './permission-table-main.component.html',
  styleUrls: ['./permission-table-main.component.scss'],
})
export class PermissionTableMainComponent implements OnInit, AfterViewInit {
  @Input() terminalVid = '';
  @Input() mode = '';
  @Input() owner: UserIdentityDataModel = null;
  @Input() installer: UserIdentityDataModel = null;
  @Input() authedUserCanAdminOwner = false;
  @Input() authedUserCanAdminInstaller = false;
  showAttachInstaller = false;
  showAttachOwner = false;
  attachInstallerForm = new FormControl('');
  attachOwnerForm = new FormControl('');
  isMobile = false;
  constructor(
    public translationsLib: TranslationsLibService,
    private terminalService: TerminalsService,
    private permissionService: PermissionsService,
    private breakpointObserver: BreakpointObserver
  ) {}

  ngOnInit() {
    this.owner = this.owner || null;
    this.installer = this.installer || null;
  }

  ngAfterViewInit(): void {
    this.breakpointObserver
      .observe([Breakpoints.XSmall])
      .subscribe((result) => {
        this.isMobile = result.matches;
      });
  }

  attachInstallerToTerminal() {
    this.terminalService
      .postPairing(
        {
          pair_installer: this.attachInstallerForm.value,
        },
        this.terminalVid
      )
      .subscribe((paired) => {
        if (
          paired.paired_installer === this.attachInstallerForm.value &&
          paired.paired_installer_status === 'pending_invitation'
        ) {
          Swal.fire({
            text: this.translationsLib.get('permission_invitation_sent'),
            icon: 'success',
            confirmButtonColor: '#224261',
          }).then((res) => {
            if (res.isConfirmed || res.isDismissed) {
              this.showAttachInstaller = false;
            }
          });
        } else {
          Swal.fire({
            text: this.translationsLib.get(
              'permission_invitation_sent_to_installer_error'
            ),
            icon: 'error',
            confirmButtonColor: '#224261',
          }).then();
        }
      });
  }

  detachInstallerFromTerminal() {
    Swal.fire({
      text: this.translationsLib.get(
        'permission_confirm_detach_installer_from_terminal'
      ),
      icon: 'warning',
      confirmButtonColor: '#224261',
      showDenyButton: true,
      confirmButtonText: this.translationsLib.get('accept'),
      denyButtonText: this.translationsLib.get('cancel'),
    }).then((res) => {
      if (res.isConfirmed) {
        this.permissionService
          .detachInstallerFromTerminal(this.installer.email, this.terminalVid)
          .subscribe(() => {
            Swal.fire({
              text: this.translationsLib.get('save_changes_success'),
              showConfirmButton: true,
              confirmButtonText: this.translationsLib.get('accept'),
              icon: 'success',
            }).then(() => {
              this.installer = null;
              this.ngOnInit();
            });
          });
      }
    });
  }

  attachOwnerToTerminal() {
    this.terminalService
      .postPairing(
        {
          pair_owner: this.attachOwnerForm.value,
        },
        this.terminalVid
      )
      .subscribe((paired) => {
        if (
          paired.paired_owner === this.attachOwnerForm.value &&
          paired.paired_owner_status === 'pending_invitation'
        ) {
          Swal.fire({
            text: this.translationsLib.get('permission_invitation_sent'),
            icon: 'success',
            confirmButtonColor: '#224261',
          }).then((res) => {
            if (res.isConfirmed || res.isDismissed) {
              this.showAttachOwner = false;
            }
          });
        } else {
          Swal.fire({
            text: this.translationsLib.get(
              'permission_invitation_sent_to_owner_error'
            ),
            icon: 'error',
            confirmButtonColor: '#224261',
          }).then();
        }
      });
  }
}
