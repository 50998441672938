import { Injectable } from '@angular/core';
import * as moment from 'moment/moment';

@Injectable({
  providedIn: 'root',
})
export class TimeLibService {
  getModifiedDate(date: Date, amount: number, unit: string) {
    // @ts-ignore
    return moment(date).add(amount, unit).format('YYYY-MM-DD');
  }
  beginDate(date: Date, amount: number, unit: string) {
    return this.getModifiedDate(date, amount, unit) + '+00:00:00';
  }
  endDate(date: Date, amount: number, unit: string) {
    return this.getModifiedDate(date, amount, unit) + '+23:59:59';
  }
  firstDayOfMonth(date: Date) {
    return moment(date).startOf('month').format('YYYY-MM-DD') + '+00:00:00';
  }
  lastDayOfMonth(date: Date) {
    return moment(date).endOf('month').format('YYYY-MM-DD') + '+23:59:59';
  }

  /** Diff seconds
   *
   * @param begin
   * @param end
   */
  diff(begin: Date, end: Date): number {
    return Math.ceil(moment(end).diff(moment(begin)) / 1000);
  }
  secondsToTime(seconds: number): string {
    const duration = moment.duration(seconds, 'seconds');
    const days = duration.days();
    const hours = duration.hours();
    const minutes = duration.minutes();
    let output = minutes + 'm';
    if (hours > 0) {
      output = hours + 'h ' + output;
    }
    if (days > 0) {
      output = days + 'd ' + output;
    }
    return output;
  }
  hoursToTime(hours_coming: number): string {
    const duration = moment.duration(hours_coming, 'hours');
    const days = duration.days();
    const hours = duration.hours();
    const minutes = duration.minutes();
    let output = minutes + 'm';
    if (hours > 0) {
      output = hours + 'h ' + output;
    }
    if (days > 0) {
      output = days + 'd ' + output;
    }
    return output;
  }
}
