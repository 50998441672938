import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppCommonsModule } from '../commons/app-commons.module';
import { InfoScreenComponent } from './info-screen/info-screen.component';
import { IonicModule } from '@ionic/angular';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatInputModule } from '@angular/material/input';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { AuthModule } from '../auth/auth.module';
import { MatTableModule } from '@angular/material/table';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { HighchartsChartModule } from 'highcharts-angular';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NgSelectModule } from '@ng-select/ng-select';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatTableExporterModule } from 'mat-table-exporter';
import { MatMenuModule } from '@angular/material/menu';
import { MatDialogModule } from '@angular/material/dialog';

@NgModule({
  declarations: [InfoScreenComponent],
  exports: [],
  imports: [
    CommonModule,
    AppCommonsModule,
    IonicModule,
    MatFormFieldModule,
    MatAutocompleteModule,
    MatInputModule,
    ReactiveFormsModule,
    MatSelectModule,
    MatDatepickerModule,
    MatNativeDateModule,
    AuthModule,
    MatTableModule,
    MatIconModule,
    MatButtonModule,
    HighchartsChartModule,
    MatTooltipModule,
    NgSelectModule,
    FormsModule,
    MatPaginatorModule,
    MatSortModule,
    MatExpansionModule,
    MatTableExporterModule,
    MatMenuModule,
    MatDialogModule,
  ],
})
export class InfoModule {}
