<div class="mb-4 d-flex justify-content-center mt-5" *ngIf="isFetchingData">
  <div class="spinner-border text-warning" role="status">
    <span class="visually-hidden">{{
      this.translationsLib.get('irrigation_loading')
    }}</span>
  </div>
</div>

<app-dirty-message
  [conditions]="!isFetchingData"
  [dirty]="drainagesV3Form.value.dirty"
></app-dirty-message>

<ng-container
  *ngIf="mode === 'simple' && !isFetchingData && !isDrainagesV3WithoutData"
>
  <form [formGroup]="drainagesV3Form" class="simple-form">
    <div class="card">
      <div class="card-body custom-card-body">
        <div class="row align-items-start">
          <div class="col-sm-5 col-md-5 order-1 order-md-1">
            <h6 class="fs-2">
              {{ translationsLib.get('irrigation_drainage') }}
            </h6>
            <p class="fs-5 fw-lighter">
              {{
                translationsLib.get('irrigation_drainage_help') | sanitizeText
              }}
            </p>
          </div>
          <div class="col-sm-7 col-md-7 order-3 order-md-2 col-inputs">
            <div
              class="row first-row align-items-center justify-content-between"
            >
              <!-- Bandeja de drenaje -->
              <div class="col-12 col-md-6">
                <h6 class="fs-5">
                  {{
                    translationsLib.get('irrigation_drainage_tray')
                      | sanitizeText
                  }}
                </h6>
                <div class="input-group mb-2">
                  <select class="form-select" formControlName="drainingTray">
                    <option
                      *ngFor="let i of drainageTraysAsNumbersArray"
                      [value]="i"
                    >
                      {{
                        i === 255
                          ? translationsLib.get(
                              'irrigation_change_drainage_tray'
                            )
                          : translationsLib.get('irrigation_drainage_tray') +
                            ' ' +
                            (i + 1)
                      }}
                    </option>
                  </select>
                </div>
              </div>

              <!-- Drenaje ideal -->
              <div class="col-12 col-md-6">
                <h6 class="fs-5">
                  {{
                    translationsLib.get('irrigation_drainage_ideal')
                      | sanitizeText
                  }}
                </h6>
                <div class="input-group mb-2 number-input">
                  <button
                    class="minus minus-style"
                    (click)="inputNumberService.decrementValue($event)"
                  ></button>
                  <input
                    formControlName="idealDraining"
                    type="number"
                    class="form-control"
                    placeholder="{{
                      translationsLib.get('irrigation_drainage_ideal')
                    }}"
                    min="-9999"
                    max="9999"
                  />
                  <button
                    class="plus border-plus plus-style"
                    (click)="inputNumberService.incrementValue($event)"
                  ></button>
                </div>
              </div>
            </div>

            <!-- Mínima duración -->
            <div class="row align-items-center justify-content-between">
              <div class="col-12 col-md-4">
                <h6 class="fs-5">
                  {{
                    translationsLib.get('irrigation_min_duration')
                      | sanitizeText
                  }}
                </h6>
                <div class="mb-2">
                  <ng-template #minDurationPicker>
                    <timepicker
                      [showMeridian]="false"
                      [showSeconds]="true"
                      [minuteStep]="1"
                      [secondsStep]="1"
                      [formControlName]="'minDuration'"
                    ></timepicker>
                  </ng-template>
                  <input
                    [ngClass]="{
                      'ng-dirty': drainagesV3Form.controls.minDuration.dirty
                    }"
                    class="form-control text-center"
                    type="text"
                    [popover]="minDurationPicker"
                    [outsideClick]="true"
                    placement="bottom"
                    [value]="drainagesV3Form.value.minDuration | inputTime"
                  />
                </div>
              </div>
              <div class="col-12 col-md-4">
                <h6 class="fs-5">
                  {{ translationsLib.get('irrigation_max_duration') }}
                </h6>
                <div class="mb-2">
                  <ng-template #maxDurationPicker>
                    <timepicker
                      [showMeridian]="false"
                      [showSeconds]="true"
                      [minuteStep]="1"
                      [secondsStep]="1"
                      [formControlName]="'maxDuration'"
                    ></timepicker>
                  </ng-template>
                  <input
                    [ngClass]="{
                      'ng-dirty': drainagesV3Form.controls.maxDuration.dirty
                    }"
                    class="form-control text-center"
                    type="text"
                    [popover]="maxDurationPicker"
                    [outsideClick]="true"
                    placement="bottom"
                    [value]="drainagesV3Form.value.maxDuration | inputTime"
                  />
                </div>
              </div>
              <div class="col-12 col-md-4">
                <h6 class="fs-5">
                  {{ translationsLib.get('irrigation_duration') }}
                </h6>
                <div class="mb-2">
                  <ng-template #durationPicker>
                    <timepicker
                      [showMeridian]="false"
                      [showSeconds]="true"
                      [minuteStep]="1"
                      [secondsStep]="1"
                      [formControlName]="'duration'"
                    ></timepicker>
                  </ng-template>
                  <input
                    [ngClass]="{
                      'ng-dirty': drainagesV3Form.controls.duration.dirty
                    }"
                    class="form-control text-center"
                    type="text"
                    [popover]="durationPicker"
                    [outsideClick]="true"
                    placement="bottom"
                    [value]="drainagesV3Form.value.duration | inputTime"
                  />
                </div>
              </div>
            </div>

            <!-- Mínima duración primer riego -->
            <div class="row align-items-center justify-content-between">
              <div class="col-12 col-md-4">
                <h6 class="fs-5">
                  {{
                    translationsLib.get(
                      'irrigation_min_duration_first_irrigation'
                    ) | sanitizeText
                  }}
                </h6>
                <div class="mb-2">
                  <ng-template #minDurationFirstWateringPicker>
                    <timepicker
                      [showMeridian]="false"
                      [showSeconds]="true"
                      [minuteStep]="1"
                      [secondsStep]="1"
                      [formControlName]="'minDurationFirstWatering'"
                    ></timepicker>
                  </ng-template>
                  <input
                    [ngClass]="{
                      'ng-dirty':
                        drainagesV3Form.controls.minDurationFirstWatering.dirty
                    }"
                    class="form-control text-center"
                    type="text"
                    [popover]="minDurationFirstWateringPicker"
                    [outsideClick]="true"
                    placement="bottom"
                    [value]="
                      drainagesV3Form.value.minDurationFirstWatering | inputTime
                    "
                  />
                </div>
              </div>
              <div class="col-12 col-md-4">
                <h6 class="fs-5">
                  {{
                    translationsLib.get(
                      'irrigation_max_duration_first_irrigation'
                    )
                  }}
                </h6>
                <div class="mb-2">
                  <ng-template #maxDurationFirstWateringPicker>
                    <timepicker
                      [showMeridian]="false"
                      [showSeconds]="true"
                      [minuteStep]="1"
                      [secondsStep]="1"
                      [formControlName]="'maxDurationFirstWatering'"
                    ></timepicker>
                  </ng-template>
                  <input
                    [ngClass]="{
                      'ng-dirty':
                        drainagesV3Form.controls.maxDurationFirstWatering.dirty
                    }"
                    class="form-control text-center"
                    type="text"
                    [popover]="maxDurationFirstWateringPicker"
                    [outsideClick]="true"
                    placement="bottom"
                    [value]="
                      drainagesV3Form.value.maxDurationFirstWatering | inputTime
                    "
                  />
                </div>
              </div>
              <div class="col-12 col-md-4">
                <h6 class="fs-5">
                  {{
                    translationsLib.get('irrigation_first_irrigation_duration')
                  }}
                </h6>
                <div class="mb-2">
                  <ng-template #firstWateringDurationPicker>
                    <timepicker
                      [showMeridian]="false"
                      [showSeconds]="true"
                      [minuteStep]="1"
                      [secondsStep]="1"
                      [formControlName]="'firstWateringDuration'"
                    ></timepicker>
                  </ng-template>
                  <input
                    [ngClass]="{
                      'ng-dirty':
                        drainagesV3Form.controls.firstWateringDuration.dirty
                    }"
                    class="form-control text-center"
                    type="text"
                    [popover]="firstWateringDurationPicker"
                    [outsideClick]="true"
                    placement="bottom"
                    [value]="
                      drainagesV3Form.value.firstWateringDuration | inputTime
                    "
                  />
                </div>
              </div>
            </div>

            <!-- Radiación de control -->
            <div class="row align-items-center justify-content-between">
              <div class="col-12 col-md-4">
                <h6 class="fs-5">
                  {{
                    translationsLib.get('irrigation_control_radiation')
                      | sanitizeText
                  }}
                </h6>
                <div class="input-group mb-2 number-input">
                  <button
                    class="minus minus-style"
                    (click)="inputNumberService.decrementValue($event)"
                  ></button>
                  <input
                    formControlName="controlRadiation"
                    type="number"
                    class="form-control"
                    placeholder="{{
                      translationsLib.get('irrigation_control_radiation')
                    }}"
                    min="-9999"
                    max="9999"
                  />
                  <button
                    class="plus border-plus plus-style"
                    (click)="inputNumberService.incrementValue($event)"
                  ></button>
                </div>
              </div>
              <div class="col-12 col-md-4">
                <h6 class="fs-5">
                  {{ translationsLib.get('irrigation_starting_radiation') }}
                </h6>
                <div class="input-group mb-2 number-input">
                  <button
                    class="minus minus-style"
                    (click)="inputNumberService.decrementValue($event)"
                  ></button>
                  <input
                    formControlName="startingRadiation"
                    type="number"
                    class="form-control"
                    placeholder="{{
                      translationsLib.get('irrigation_starting_radiation')
                    }}"
                    min="-9999"
                    max="9999"
                  />
                  <button
                    class="plus border-plus plus-style"
                    (click)="inputNumberService.incrementValue($event)"
                  ></button>
                </div>
              </div>
              <div class="col-12 col-md-4">
                <h6 class="fs-5">
                  {{ translationsLib.get('irrigation_min_radiation') }}
                </h6>
                <div class="input-group mb-2 number-input">
                  <button
                    class="minus minus-style"
                    (click)="inputNumberService.decrementValue($event)"
                  ></button>
                  <input
                    formControlName="minRadiation"
                    type="number"
                    class="form-control"
                    placeholder="{{
                      translationsLib.get('irrigation_min_radiation')
                    }}"
                    min="-9999"
                    max="9999"
                  />
                  <button
                    class="plus border-plus plus-style"
                    (click)="inputNumberService.incrementValue($event)"
                  ></button>
                </div>
              </div>
            </div>

            <!-- Tolerancia -->
            <div class="row align-items-center justify-content-between">
              <div class="col-12 col-md-6">
                <h6 class="fs-5">
                  {{
                    translationsLib.get('irrigation_threshold') | sanitizeText
                  }}
                </h6>
                <div class="input-group mb-2">
                  <input
                    formControlName="tolerance"
                    type="text"
                    class="form-control"
                    placeholder="{{
                      translationsLib.get('irrigation_threshold')
                    }}"
                  />
                </div>
              </div>
              <div class="col-12 col-md-6">
                <h6 class="fs-5">
                  {{ translationsLib.get('irrigation_watts_drainage') }}
                </h6>
                <div class="input-group mb-2 number-input">
                  <button
                    class="minus minus-style"
                    (click)="inputNumberService.decrementValue($event)"
                  ></button>
                  <input
                    formControlName="wattsDrain"
                    type="number"
                    class="form-control"
                    placeholder="{{
                      translationsLib.get('irrigation_watts_drainage')
                    }}"
                    min="-9999"
                    max="9999"
                  />
                  <button
                    class="plus border-plus plus-style"
                    (click)="inputNumberService.incrementValue($event)"
                  ></button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</ng-container>

<ng-container
  *ngIf="mode === 'advanced' && !isFetchingData && !isDrainagesV3WithoutData"
>
  <form [formGroup]="drainagesV3Form" class="advanced-form">
    <div class="row">
      <div class="col">
        <div class="card">
          <div class="card-body custom-card-body">
            <div class="row">
              <div class="col-12">
                <h6 class="fs-2 input-label">
                  {{ translationsLib.get('irrigation_drainage') }}
                </h6>
              </div>
            </div>

            <!-- Bandeja de drenaje -->
            <div class="row align-items-center">
              <div class="col-sm-12 col-md-6 col-lg-6">
                <h6 class="fs-5">
                  {{ translationsLib.get('irrigation_drainage_tray') }}
                </h6>
                <div class="input-group mb-2">
                  <select class="form-select" formControlName="drainingTray">
                    <option
                      *ngFor="let i of drainageTraysAsNumbersArray"
                      [value]="i"
                    >
                      {{
                        i === 255
                          ? translationsLib.get(
                              'irrigation_change_drainage_tray'
                            )
                          : translationsLib.get('irrigation_drainage_tray') +
                            ' ' +
                            (i + 1)
                      }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-sm-12 col-md-6 col-lg-6">
                <h6 class="fs-5 input-label">
                  {{ translationsLib.get('irrigation_drainage_ideal') }}
                </h6>
                <div class="input-group mb-2 number-input">
                  <button
                    class="minus minus-style"
                    (click)="inputNumberService.decrementValue($event)"
                  ></button>
                  <input
                    formControlName="idealDraining"
                    type="number"
                    class="form-control"
                    placeholder="{{
                      translationsLib.get('irrigation_drainage_ideal')
                    }}"
                    min="-9999"
                    max="9999"
                  />
                  <button
                    class="plus border-plus plus-style"
                    (click)="inputNumberService.incrementValue($event)"
                  ></button>
                </div>
              </div>
            </div>

            <!-- Mínima duración -->
            <div class="row align-items-center">
              <div class="col-sm-12 col-md-4">
                <h6 class="fs-5">
                  {{ translationsLib.get('irrigation_min_duration') }}
                </h6>
                <div class="mb-2">
                  <ng-template #minDurationPicker>
                    <timepicker
                      [showMeridian]="false"
                      [showSeconds]="true"
                      [minuteStep]="1"
                      [secondsStep]="1"
                      [formControlName]="'minDuration'"
                    ></timepicker>
                  </ng-template>
                  <input
                    [ngClass]="{
                      'ng-dirty': drainagesV3Form.controls.minDuration.dirty
                    }"
                    class="form-control text-center"
                    type="text"
                    [popover]="minDurationPicker"
                    [outsideClick]="true"
                    placement="bottom"
                    [value]="drainagesV3Form.value.minDuration | inputTime"
                  />
                </div>
              </div>
              <div class="col-sm-12 col-md-4">
                <h6 class="fs-5 input-label">
                  {{ translationsLib.get('irrigation_max_duration') }}
                </h6>
                <div class="mb-2">
                  <ng-template #maxDurationPicker>
                    <timepicker
                      [showMeridian]="false"
                      [showSeconds]="true"
                      [minuteStep]="1"
                      [secondsStep]="1"
                      [formControlName]="'maxDuration'"
                    ></timepicker>
                  </ng-template>
                  <input
                    [ngClass]="{
                      'ng-dirty': drainagesV3Form.controls.maxDuration.dirty
                    }"
                    class="form-control text-center"
                    type="text"
                    [popover]="maxDurationPicker"
                    [outsideClick]="true"
                    placement="bottom"
                    [value]="drainagesV3Form.value.maxDuration | inputTime"
                  />

                  {{ drainagesV3Form.value.maxDuration | inputTime }}
                </div>
              </div>
              <div class="col-sm-12 col-md-4">
                <h6 class="fs-5 input-label">
                  {{ translationsLib.get('irrigation_duration') }}
                </h6>
                <div class="mb-2">
                  <ng-template #durationPicker>
                    <timepicker
                      [showMeridian]="false"
                      [showSeconds]="true"
                      [minuteStep]="1"
                      [secondsStep]="1"
                      [formControlName]="'duration'"
                    ></timepicker>
                  </ng-template>
                  <input
                    [ngClass]="{
                      'ng-dirty': drainagesV3Form.controls.duration.dirty
                    }"
                    class="form-control text-center"
                    type="text"
                    [popover]="durationPicker"
                    [outsideClick]="true"
                    placement="bottom"
                    [value]="drainagesV3Form.value.duration | inputTime"
                  />
                </div>
              </div>
            </div>

            <!-- Mínima duración primer riego -->
            <div class="row align-items-center">
              <div class="col-sm-12 col-md-4">
                <h6 class="fs-5">
                  {{
                    translationsLib.get(
                      'irrigation_min_duration_first_irrigation'
                    )
                  }}
                </h6>
                <div class="mb-2">
                  <ng-template #minDurationFirstWateringPicker>
                    <timepicker
                      [showMeridian]="false"
                      [showSeconds]="true"
                      [minuteStep]="1"
                      [secondsStep]="1"
                      [formControlName]="'minDurationFirstWatering'"
                    ></timepicker>
                  </ng-template>
                  <input
                    [ngClass]="{
                      'ng-dirty':
                        drainagesV3Form.controls.minDurationFirstWatering.dirty
                    }"
                    class="form-control text-center"
                    type="text"
                    [popover]="minDurationFirstWateringPicker"
                    [outsideClick]="true"
                    placement="bottom"
                    [value]="
                      drainagesV3Form.value.minDurationFirstWatering | inputTime
                    "
                  />
                </div>
              </div>
              <div class="col-sm-12 col-md-4">
                <h6 class="fs-5 input-label">
                  {{
                    translationsLib.get(
                      'irrigation_max_duration_first_irrigation'
                    )
                  }}
                </h6>
                <div class="mb-2">
                  <ng-template #maxDurationFirstWateringPicker>
                    <timepicker
                      [showMeridian]="false"
                      [showSeconds]="true"
                      [minuteStep]="1"
                      [secondsStep]="1"
                      [formControlName]="'maxDurationFirstWatering'"
                    ></timepicker>
                  </ng-template>
                  <input
                    [ngClass]="{
                      'ng-dirty':
                        drainagesV3Form.controls.maxDurationFirstWatering.dirty
                    }"
                    class="form-control text-center"
                    type="text"
                    [popover]="maxDurationFirstWateringPicker"
                    [outsideClick]="true"
                    placement="bottom"
                    [value]="
                      drainagesV3Form.value.maxDurationFirstWatering | inputTime
                    "
                  />
                </div>
              </div>
              <div class="col-sm-12 col-md-4">
                <h6 class="fs-5 input-label">
                  {{
                    translationsLib.get('irrigation_first_irrigation_duration')
                  }}
                </h6>
                <div class="mb-2">
                  <ng-template #firstWateringDurationPicker>
                    <timepicker
                      [showMeridian]="false"
                      [showSeconds]="true"
                      [minuteStep]="1"
                      [secondsStep]="1"
                      [formControlName]="'firstWateringDuration'"
                    ></timepicker>
                  </ng-template>
                  <input
                    [ngClass]="{
                      'ng-dirty':
                        drainagesV3Form.controls.firstWateringDuration.dirty
                    }"
                    class="form-control text-center"
                    type="text"
                    [popover]="firstWateringDurationPicker"
                    [outsideClick]="true"
                    placement="bottom"
                    [value]="
                      drainagesV3Form.value.firstWateringDuration | inputTime
                    "
                  />
                </div>
              </div>
            </div>

            <!-- Radiación de control -->
            <div class="row align-items-center">
              <div class="col-sm-12 col-md-4">
                <h6 class="fs-5">
                  {{ translationsLib.get('irrigation_control_radiation') }}
                </h6>
                <div class="input-group mb-2">
                  <input
                    formControlName="controlRadiation"
                    type="text"
                    class="form-control"
                    placeholder="{{
                      translationsLib.get('irrigation_control_radiation')
                    }}"
                  />
                </div>
              </div>
              <div class="col-sm-12 col-md-4">
                <h6 class="fs-5 input-label">
                  {{ translationsLib.get('irrigation_starting_radiation') }}
                </h6>
                <div class="input-group mb-2 number-input">
                  <button
                    class="minus minus-style"
                    (click)="inputNumberService.decrementValue($event)"
                  ></button>
                  <input
                    formControlName="startingRadiation"
                    type="number"
                    class="form-control"
                    placeholder="{{
                      translationsLib.get('irrigation_starting_radiation')
                    }}"
                    min="-9999"
                    max="9999"
                  />
                  <button
                    class="plus border-plus plus-style"
                    (click)="inputNumberService.incrementValue($event)"
                  ></button>
                </div>
              </div>
              <div class="col-sm-12 col-md-4">
                <h6 class="fs-5 input-label">
                  {{ translationsLib.get('irrigation_min_radiation') }}
                </h6>
                <div class="input-group mb-2 number-input">
                  <button
                    class="minus minus-style"
                    (click)="inputNumberService.decrementValue($event)"
                  ></button>
                  <input
                    formControlName="minRadiation"
                    type="number"
                    class="form-control"
                    placeholder="{{
                      translationsLib.get('irrigation_min_radiation')
                    }}"
                    min="-9999"
                    max="9999"
                  />
                  <button
                    class="plus border-plus plus-style"
                    (click)="inputNumberService.incrementValue($event)"
                  ></button>
                </div>
              </div>
            </div>

            <!-- Tolerancia -->
            <div class="row align-items-center">
              <div class="col-sm-12 col-md-6 col-lg-6">
                <h6 class="fs-5">
                  {{ translationsLib.get('irrigation_threshold') }}
                </h6>
                <div class="input-group mb-2">
                  <input
                    formControlName="tolerance"
                    type="text"
                    class="form-control"
                    placeholder="{{
                      translationsLib.get('irrigation_threshold')
                    }}"
                  />
                </div>
              </div>
              <div class="col-sm-12 col-md-6 col-lg-6">
                <h6 class="fs-5 input-label">
                  {{ translationsLib.get('irrigation_watts_drainage') }}
                </h6>
                <div class="input-group mb-2 number-input">
                  <button
                    class="minus minus-style"
                    (click)="inputNumberService.decrementValue($event)"
                  ></button>
                  <input
                    formControlName="wattsDrain"
                    type="number"
                    class="form-control"
                    placeholder="{{
                      translationsLib.get('irrigation_watts_drainage')
                    }}"
                    min="-9999"
                    max="9999"
                  />
                  <button
                    class="plus border-plus plus-style"
                    (click)="inputNumberService.incrementValue($event)"
                  ></button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</ng-container>
