<div *ngFor="let nexp of getExpansions(sensors)">
  <div class="col-12" *ngIf="nexp !== 240">
    <h4>{{ translationsLib.get('sensors_expansion') }} {{ nexp | toHex }}</h4>
  </div>
  <div [class]="'col-12' + (nexp === 240 && ' mt-5')">
    <div class="list-group">
      <ng-container *ngFor="let sensor of sensors">
        <div
          class="list-group-item list-group-item-action"
          role="button"
          *ngIf="+sensor.nexp === nexp"
          (click)="editSensor.emit(sensor)"
        >
          <span [class]="'badge d-inline-block bg-' + type">
            E{{ +sensor.nent + 1 }}
          </span>
          <span class="mx-3">{{ sensor.name }}</span>
        </div>
      </ng-container>
    </div>
  </div>
</div>
