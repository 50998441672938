import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { TranslationsLibService } from '@nutricontrol/app360-shared';
import { ClimateEquipmentModel } from '../../../farming/climate/climate.model';
import { IrrigationEquipmentModel } from 'src/app/farming/irrigation/irrigation.model';
import { ConfigurableEquipmentModel } from 'src/app/farming/configurable/configurable.model';

@Component({
  selector: 'app-alarms-grid',
  templateUrl: './alarms-grid.component.html',
  styleUrls: ['./alarms-grid.component.scss'],
})
export class AlarmsGridComponent implements OnInit {
  @Input() alarmRows = [];
  @Input() type: 'climate' | 'irrigation' | 'configurable';
  @Output() cancelAlarmsEventEmitter = new EventEmitter<void>();
  @Input() equipmentResponse:
    | ClimateEquipmentModel
    | IrrigationEquipmentModel
    | ConfigurableEquipmentModel;
  cardClass: any;

  constructor(public translationsLib: TranslationsLibService) {}

  generateTag(field) {
    if (this.type === 'configurable')
      return this.translationsLib.get('alarms_configurable_' + field.value.tag);
    return this.translationsLib.get('alarms_irrigation_' + field.value.tag);
  }
  ngOnInit() {
    this.cardClass = this.getClass();
  }

  getClass() {
    return {
      'irrigation-card-border': this.type === 'irrigation',
      'climate-card-border': this.type === 'climate',
      'configurable-card-border': this.type === 'configurable',
    };
  }
}
