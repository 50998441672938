import {
  AfterViewInit,
  Component,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { TranslationsLibService } from '@nutricontrol/app360-shared';
import { CalendarEvent } from 'angular-calendar';
import * as moment from 'moment';
import { HelpersLibService } from '../../services/libraries/helpers-lib.service';
import { MatTabGroup } from '@angular/material/tabs';

@Component({
  selector: 'app-events-calendar-log',
  templateUrl: './events-calendar-log.component.html',
  styleUrls: ['./events-calendar-log.component.scss'],
})
export class EventsCalendarLogComponent implements OnInit, AfterViewInit {
  @Input() current_events: CalendarEvent[] = [];
  @Input() past_events: CalendarEvent[] = [];
  @Input() future_events: CalendarEvent[] = [];
  @ViewChild('tabGroup', { static: false }) tab: MatTabGroup;

  moment: any = moment;

  constructor(
    public translationsLib: TranslationsLibService,
    private helpersLib: HelpersLibService
  ) {}

  ngOnInit() {}

  ngAfterViewInit() {
    this.helpersLib.focusTab(this.tab);
  }
}
