import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AuthService } from '../auth.service';
import { PasswordStrengthComponent } from '../password-strength/password-strength.component';
import { HelpersLibService } from '../../services/libraries/helpers-lib.service';
import { TranslationsLibService } from '@nutricontrol/app360-shared';
import { LegalAgreementsLibService } from '../../services/libraries/legal-agreements-lib.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
})
export class RegisterComponent implements OnInit {
  countries;
  selectedCountry = 0;
  provinces = null;
  phone = ' --';
  private fb: FormBuilder = new FormBuilder();
  // eslint-disable-next-line @typescript-eslint/member-ordering
  signUpForm = this.fb.group(
    {
      name: ['', Validators.required],
      surname: [
        '',
        //Validators.required
      ],
      phone: [''],
      company_name: [''],
      password: ['', Validators.required],
      confirmpassword: ['', Validators.required],
      email: [
        '',
        [
          Validators.required,
          Validators.pattern(
            '^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,4}$'
          ),
        ],
      ],
      country_id: [''],
      province: [''],
      province_id: [''],
      province_name: [''],
      address: [''],
      installer: [false],
      terms: [false, Validators.requiredTrue],
      newsletter: [true],
    },
    {
      validator: this.confirmedValidator('password', 'confirmpassword'),
    }
  );
  private force = 0;
  constructor(
    public router: Router,
    public authService: AuthService,
    public legalAgreementsLib: LegalAgreementsLibService,
    private pass: PasswordStrengthComponent,
    private helpersLib: HelpersLibService,
    public translationsLib: TranslationsLibService
  ) {}

  ngOnInit() {
    this.getCountries();
    this.authService.getLegalAgreements();
    this.helpersLib.setTitle(
      '',
      this.translationsLib.get('auth_register_title'),
      null
    );
  }

  setCountry(countryId) {
    this.selectedCountry = countryId;
    this.signUpForm.patchValue({
      country_id: countryId,
    });
    this.getPhonePrefix();
    this.getProvinces();
  }
  setProvince(provinceId: string) {
    const prov_arr = provinceId.split('@@@');
    this.signUpForm.patchValue({
      province: Number(prov_arr[0]),
      province_id: Number(prov_arr[0]),
      province_name: prov_arr[1],
    });
  }
  checkPass() {
    this.force = this.pass.getStrength(this.signUpForm.value.password);
  }

  getForceCheck() {
    return this.force >= 3 && this.force < 5;
  }

  getCountries() {
    this.authService.getCountries().subscribe((response) => {
      this.countries = response.items;
    });
  }

  getPhonePrefix() {
    this.countries.filter((country) => {
      if (country.id === this.selectedCountry) {
        this.phone = country.phone;
      }
    });
  }
  getProvinces() {
    this.countries.filter((country) => {
      if (country.id === this.selectedCountry) {
        if (country.provinces.length > 0) {
          this.provinces = country.provinces;
        } else {
          this.provinces = null;
          this.signUpForm.patchValue({
            province: '',
            province_id: '',
            province_name: '',
          });
        }
      }
    });
  }

  confirmedValidator(controlName: string, matchingControlName: string) {
    return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];
      if (
        matchingControl.errors &&
        !matchingControl.errors.confirmedValidator
      ) {
        return;
      }
      if (control.value !== matchingControl.value) {
        matchingControl.setErrors({ confirmedValidator: true });
      } else {
        matchingControl.setErrors(null);
      }
    };
  }

  showPassword(password: string) {
    let type;
    if (password === 'conf') {
      type = document.getElementById('passwordconf');
    } else {
      type = document.getElementById('passwordregister');
    }
    if (type.attributes[2].value === 'password') {
      type.attributes[2].value = 'text';
    } else {
      type.attributes[2].value = 'password';
    }
  }

  samePassword() {
    const passConfirm = (
      document.querySelector('#passwordconf') as HTMLInputElement
    ).value;
    const pass = (document.querySelector('#passwordregister') as HTMLInputElement)
      .value;
    if (passConfirm !== '' && pass !== '' && passConfirm === pass) {
      return true;
    } else {
      return passConfirm === '' || pass === '';
    }
  }

  onSubmit(): void {
    if (this.provinces !== null) {
      this.signUpForm.value.province_id = this.signUpForm.value.province;
    } else {
      this.signUpForm.value.province_name = this.signUpForm.value.province;
    }
    if (this.signUpForm.value.installer === true) {
      this.signUpForm.value.installer = 1;
    } else {
      this.signUpForm.value.installer = 0;
    }
    event.preventDefault();
    this.authService.createRegister(this.signUpForm.value).subscribe(
      (response) => {
        this.authService
          .createNewPassword(response.vid, {
            password: this.signUpForm.value.password,
          })
          .subscribe(
            () => {
              this.router.navigate(['./register-confirmation'], {
                queryParams: { identifier: response.vid },
              });
            },
            (error) => {
              if (error.error.includes('user already has a password')) {
                this.router.navigate(['./register-confirmation'], {
                  queryParams: { identifier: response.vid },
                });
              }
            }
          );
      },
      (error) => {
        let userError = '';
        if (error.error.includes('existing user')) {
          userError = this.translationsLib.get(
            'auth_login_error_user_already_exists'
          );
        }
        Swal.fire({
          icon: 'error',
          title: this.translationsLib.get('error'),
          text: userError,
          confirmButtonColor: '#224261',
        });
      }
    );
  }
}
