import { Component, OnInit, ViewChild } from '@angular/core';
import { AuthService } from '../auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, Validators } from '@angular/forms';
import { HelpersLibService } from '../../services/libraries/helpers-lib.service';
import {
  SessionLibService,
  TranslationsLibService,
  UserType,
} from '@nutricontrol/app360-shared';
import Swal from 'sweetalert2';
import { CodeInputComponent } from 'angular-code-input';

@Component({
  selector: 'app-register-confirmation',
  templateUrl: './2fa-confirmation.component.html',
  styleUrls: ['./2fa-confirmation.component.scss'],
})
export class Auth2faConfirmationComponent implements OnInit {
  @ViewChild('codeInput') codeInput!: CodeInputComponent;

  ready = false;
  showResend = false;
  sessionVid: string | null = null;
  userVid: string | null = null;

  private fb: FormBuilder = new FormBuilder();
  // eslint-disable-next-line @typescript-eslint/member-ordering
  auth2faResendConfirmationCode = this.fb.group({
    email: [
      '',
      [
        Validators.required,
        Validators.pattern(
          '^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,4}$'
        ),
      ],
    ],
  });
  constructor(
    private authService: AuthService,
    private route: ActivatedRoute,
    public router: Router,
    private helpersLib: HelpersLibService,
    public translationsLib: TranslationsLibService,
    public sessionLib: SessionLibService
  ) {}

  ngOnInit() {
    this.route.queryParams.subscribe(({ session_vid, user_vid }) => {
      this.sessionVid = session_vid || null;
      this.userVid = user_vid || null;
      if (!this.sessionVid || !this.userVid) {
        Swal.fire({
          icon: 'error',
          title: this.translationsLib.get('error'),
          text: this.translationsLib.get('fill_in_all_inputs_alert'),
          confirmButtonColor: '#224261',
        }).then(() => {
          window.location.href = '/';
        });
      } else {
        this.ready = true;
      }
    });
    this.helpersLib.setTitle(
      '',
      this.translationsLib.get('auth_register_title'),
      '2FA'
    );
  }

  confirmCode($event: any) {
    if (String($event).length !== 6) {
      return false;
    }
    this.authService
      .confirm2fa({
        user_vid: this.userVid,
        confirmation_code: $event,
        session_vid: this.sessionVid,
      })
      .subscribe(
        (authResponse) => {
          this.authService.doLogin(authResponse).subscribe((response) => {
            if (authResponse.next_action === 'auth_success_redirect_to_home') {
              if (
                this.sessionLib.getUserType() === UserType.backofficeUser ||
                this.sessionLib.getUserType() ===
                  UserType.backofficeSuperAdmin ||
                this.sessionLib.getUserType() ===
                  UserType.backofficeSuperAdminDeveloper
              ) {
                location.replace('bo-home');
              } else if (
                this.sessionLib.getUserType() ===
                UserType.backofficeFinancialAdmin
              ) {
                location.replace('bo-subscriptions');
              } else {
                const home = String(this.sessionLib.getHome());
                location.replace(home);
              }
            } else {
              Swal.fire({
                icon: 'error',
                title: this.translationsLib.get('error'),
                text: 'Unexpected backend error :(',
                confirmButtonColor: '#224261',
              });
            }
          });
        },
        (error) => {
          Swal.fire({
            icon: 'error',
            title: this.translationsLib.get('error'),
            text: this.translationsLib.get('auth_registration_code_error'),
            confirmButtonColor: '#224261',
          });
        }
      );
  }
  resendCode() {
    this.codeInput.reset();
    this.authService
      .resend2fa(this.auth2faResendConfirmationCode.value.email)
      .subscribe(
        (authResponse) => {
          if (authResponse.next_action === 'auth_2fa_redirect_to_code') {
            Swal.fire({
              icon: 'success',
              title: this.translationsLib.get('success'),
              text: this.translationsLib.get('auth_forgotten_enter_code_text'),
              confirmButtonColor: '#224261',
            }).then(() => {
              this.showResend = false;
            });
          } else {
            Swal.fire({
              icon: 'error',
              title: this.translationsLib.get('error'),
              text: this.translationsLib.get('auth_forgotten_error'),
              confirmButtonColor: '#224261',
            });
          }
        },
        (error) => {
          Swal.fire({
            icon: 'error',
            title: this.translationsLib.get('error'),
            text: this.translationsLib.get('auth_forgotten_error'),
            confirmButtonColor: '#224261',
          });
        }
      );
  }
}
