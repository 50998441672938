import { NgModule } from '@angular/core';
import { OrganizationsItemProfileComponent } from './organizations-item-profile/organizations-item-profile.component';
import { JsonPipe, NgClass, NgForOf, NgIf } from '@angular/common';

@NgModule({
  declarations: [OrganizationsItemProfileComponent],
  exports: [OrganizationsItemProfileComponent],
  imports: [NgForOf, NgIf, JsonPipe, NgClass],
})
export class OrganizationsModule {}
