import { Component, OnInit } from '@angular/core';
import { FieldsService } from '../fields.service';
import { Router } from '@angular/router';
import { CropDataModel } from '../fields.model';
import {
  TranslationsLibService,
  FieldDataModel,
} from '@nutricontrol/app360-shared';
import { AppCommonsService } from '../../commons/app-commons.service';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-fields-table',
  templateUrl: './fields-table.component.html',
  styleUrls: ['./fields-table.component.scss'],
})
export class FieldsTableComponent implements OnInit {
  cropsList: CropDataModel[] = [];
  fields: FieldDataModel[] = [];
  originalFields: FieldDataModel[] = [];
  itemsPerPage: number = environment.items_per_page;
  term = '';
  p;
  uniqueCropNames: string[] = [];
  selectedCrops: string[] = [];
  loading = false;
  constructor(
    public router: Router,
    private fieldsService: FieldsService,
    private commonService: AppCommonsService,
    public translationsLib: TranslationsLibService
  ) {}

  ngOnInit() {
    this.commonService.getCrops().subscribe((res) => {
      this.cropsList = res;
      this.getFields();
    });
  }

  getFields() {
    this.fieldsService.getFields().subscribe((res) => {
      res.forEach((field) => {
        // En lugar de crop_id tendrá crops
        // Para que no pete si se sube ya incluyo en crops el crop_id actual
        if (
          field.multicrops === undefined ||
          (field.multicrops.length === 0 &&
            field.crop_id !== undefined &&
            field.crop_id !== null)
        ) {
          field.multicrops = [
            {
              vid: null,
              crop_id: field.crop_id,
              crop_typology: null,
              farm_type: null,
              irrigation_method: null,
              irrigation_source: null,
              distance_between_rows: 0,
              distance_between_plants_in_row: 0,
              drippers_per_plant: 0,
              emitter_type: 0,
            },
          ];
        }
      });
      this.fields = res;

      // Necessary to keep the complete list of fields after using the crop filter.
      this.originalFields = res;

      // Get the different crop names
      this.uniqueCropNames = this.getUniqueCrops();

      this.loading = false;
    });
  }

  getUniqueCrops(): string[] {
    const uniqueNames: string[] = [];

    this.fields.forEach((field) => {
      field.multicrops.forEach((multiCrop) => {
        const cropName = this.cropsList.find(
          (crop) => crop.id === multiCrop.crop_id
        );
        if (cropName && !uniqueNames.includes(cropName.name)) {
          uniqueNames.push(cropName.name);
        }
      });
    });

    return uniqueNames.sort();
  }

  onSelectionCropNameChange(selection: string[]) {
    this.selectedCrops = selection;

    if (this.selectedCrops.length !== 0) {
      // Reset the other filters
      this.term = '';

      const selectedCropIds = this.selectedCrops
        .map((name) => this.cropsList.find((crop) => crop.name === name)?.id)
        .filter((id) => id !== undefined) as number[];

      // Filter fields whose 'crop_id' field matches any of the selected crop ids.
      this.fields = this.originalFields.filter((field) =>
        field.multicrops.some((multiCrop) =>
          selectedCropIds.includes(multiCrop.crop_id)
        )
      );
    } else {
      this.fields = this.originalFields;
    }
  }
  formatArea(area) {
    area = area / 10000;
    area = area.toFixed(2);
    return area;
  }
}
