import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import * as moment from 'moment/moment';
import { LineChartsModel } from '../charts-model';
import * as Highcharts from 'highcharts/highstock';
import HC_accessibility from 'highcharts/modules/accessibility';
import theme from 'highcharts/themes/high-contrast-light';
import Exporting from 'highcharts/modules/exporting';
import ExportData from 'highcharts/modules/export-data';
theme(Highcharts);
Exporting(Highcharts);
ExportData(Highcharts);
HC_accessibility(Highcharts);

import {
  App360SharedModule,
  TranslationsLibService,
} from '@nutricontrol/app360-shared';
import { HighchartsLibService } from '../../services/libraries/highcharts-lib.service';
import { GraphicsService } from '../graphics.service';
import { PlatformLibService } from '@nutricontrol/app360-shared';
import { AuthModule } from '../../auth/auth.module';
import { HighchartsChartModule } from 'highcharts-angular';
import { NgForOf, NgIf } from '@angular/common';
import { SwiperModule } from 'swiper/angular';
import { AppCommonsModule } from '../../commons/app-commons.module';
import { IonicModule } from '@ionic/angular';

@Component({
  standalone: true,
  selector: 'app-charts-component',
  templateUrl: './charts-component.component.html',
  styleUrls: ['./charts-component.component.scss'],
  imports: [
    AuthModule,
    HighchartsChartModule,
    NgIf,
    NgForOf,
    SwiperModule,
    AppCommonsModule,
    IonicModule,
    App360SharedModule,
  ],
})
export class ChartsComponentComponent implements OnInit {
  @Input() terminal_vid;
  @Input() time = null;
  @Input() sensorConnected;
  @Input() elementsDisposition = 1;
  @Input() equipment;
  @Input() multiDeviceData = null;
  @Input() fields = [];
  @Input() viewMode = 'analytics';
  @Input() reportData;
  @ViewChild('swiper') swiper;
  @Output() isData: EventEmitter<any> = new EventEmitter();
  chartError = false;
  operationInCharts = [];
  chart;
  updateFlag = false;
  chartConstructor = 'chart';
  chartCallback;
  Highcharts = Highcharts;
  consumptionChart: any = LineChartsModel;
  gotData = null;
  multipleCursor: any = {};
  loaded = false;
  content;
  magnitudesInChart = 0;
  showTable = false;
  constructor(
    private highchartsLib: HighchartsLibService,
    private graphicsService: GraphicsService,
    public translationsLib: TranslationsLibService,
    public platformLib: PlatformLibService
  ) {}

  ngOnInit() {
    Highcharts.setOptions({
      colors: this.highchartsLib.getGlobalBluePaletteColors(),
      lang: this.highchartsLib.getLangOptions(),
    });
    if (this.viewMode === 'analytics') {
      this.getChartsData();
    } else {
      this.createGraph(this.reportData);
    }
  }
  getOrientation(): string {
    return window.innerWidth > window.innerHeight ? 'landscape' : 'portrait';
  }
  getChartsData() {
    setTimeout(() => {
      if (this.multiDeviceData === null) {
        if (this.sensorConnected !== null) {
          //aqui vienen siempre de la página del datalogger por lo que 1 solo terminal y sin métricas en todos los casos (son gráficas básicas)
          this.gotData = [];
          const date = new Date();
          const date2 = new Date();
          date2.setDate(date.getDate() - this.time);
          const terminals = {
            terminal_vid: this.equipment.serialNumber,
            sensors: this.sensorConnected,
            begin: moment(date2).format('YYYY-MM-DD HH:mm:ss'),
            end: moment(date).format('YYYY-MM-DD HH:mm:ss'),
            interval: 300,
          };
          //estructura que hay que mandarle al backend
          this.multiDeviceData = {
            method: 'post',
            metrics: [],
            terminals: [terminals],
          };
          this.multiGraph();
        }
      } else {
        this.multiGraph();
      }
    }, 500);
  }
  private multiGraph() {
    this.graphicsService
      .postLineChart(this.multiDeviceData)
      .subscribe((res) => {
        if (res.chart && res.chart.series && res.chart.series.length > 0) {
          this.createGraph(res);
        } else {
          this.gotData = [];
          this.loaded = true;
        }
      });
  }
  private createGraph(data) {
    this.gotData = [];
    let rawSensors = [];
    if (this.viewMode === 'reports') {
      rawSensors = data.raw_sensor_items;
      data = data.chart_table_paired_items;
    }
    // eslint-disable-next-line no-eval
    data.chart.chart.events.selection = eval(
      `(${data.chart.chart.events.selection})`
    );
    // eslint-disable-next-line no-eval
    data.chart.dataLabels.formatter = eval(
      `(${data.chart.dataLabels.formatter})`
    );
    // eslint-disable-next-line no-eval
    data.chart.navigator.xAxis.tickPositioner = eval(
      `(${data.chart.navigator.xAxis.tickPositioner})`
    );
    if (this.platformLib.isNative()) {
      data.chart.exporting.enabled = false;
    }
    this.consumptionChart = data.chart;
    const self = this;
    //Esto hay que ponerlo aquí si no no funciona bien
    this.consumptionChart.tooltip.shared = true;
    this.consumptionChart.tooltip.formatter = function () {
      const points = this.points;
      const pointsLength = points.length;
      let tooltipMarkup = '';
      if (self.multiDeviceData || self.reportData) {
        tooltipMarkup = pointsLength
          ? '<span style="font-size: 10px">' +
            moment.utc(points[0].key).format('DD/MM HH:mm') +
            '</span><br/>'
          : '';
      } else {
        tooltipMarkup = pointsLength
          ? '<span style="font-size: 10px">' + points[0].key + '</span><br/>'
          : '';
      }
      let index;
      let y_value_kwh;
      for (index = 0; index < pointsLength; index += 1) {
        y_value_kwh = (points[index].y / 1000).toFixed(2);
        self.multipleCursor[points[index].series.name] = points[index].y;
        if (points[index].series.userOptions.unit != null) {
          tooltipMarkup +=
            '<span style="color:' +
            points[index].series.color +
            '">\u25CF</span> ' +
            points[index].series.name +
            ': <b>' +
            points[index].y +
            points[index].series.userOptions.unit +
            ' </b><br/>';
        } else {
          tooltipMarkup +=
            '<span style="color:' +
            points[index].series.color +
            '">\u25CF</span> ' +
            points[index].series.name +
            ': <b>' +
            points[index].y +
            ' </b><br/>';
        }
      }

      return tooltipMarkup;
    };
    this.sensorConnected = data.data_table;
    if (this.viewMode === 'reports') {
      rawSensors.forEach((sensor) => {
        if (sensor.data.length > 0) {
          this.gotData.push(sensor.data);
        }
      });
    } else {
      data.raw_sensors.forEach((sensor) => {
        if (sensor.data.length > 0) {
          this.gotData.push(sensor.data);
        }
      });
    }
    setTimeout(() => {
      this.isData.emit(this.gotData);
    }, 500);
    this.operationInCharts = data.operations || [];
    this.loaded = true;
  }
}
