<lib-loader *ngIf="ready === false"></lib-loader>
<div class="card" *ngIf="ready === true">
  <div class="card-header">
    <h2 class="text-header-primary m-0">
      {{ translationsLib.get('auth_2fa_login_log') }}
    </h2>
  </div>
  <div class="card-body">
    <div class="row">
      <div class="col-12">

        <div class="mat-elevation-z8 overflow-scroll">
          <table mat-table [dataSource]="dataSource" class="mat-table w-100">

            <ng-container matColumnDef="auth_2fa_type">
              <th class="d-none d-lg-table-cell text-center" mat-header-cell *matHeaderCellDef> {{ translationsLib.get('auth_2fa_user_type_device_used') }} </th>
              <td class="d-none d-lg-table-cell text-center" mat-cell *matCellDef="let element">
                <ng-container *ngIf="element.auth_2fa_user_preference === 'disabled'">
                  -
                </ng-container>
                <ng-container *ngIf="element.auth_2fa_user_preference !== 'disabled'">
                  {{ translationsLib.get('auth_2fa_types_' + element.auth_2fa_type) }}
                </ng-container>
              </td>
            </ng-container>

            <ng-container matColumnDef="auth_2fa_user_preference">
              <th class="d-none d-lg-table-cell text-center" mat-header-cell *matHeaderCellDef> {{ translationsLib.get('auth_2fa_user_preference_behaviour') }} </th>
              <td class="d-none d-lg-table-cell text-center" mat-cell *matCellDef="let element">
                {{ translationsLib.get('auth_2fa_user_preferences_' + element.auth_2fa_user_preference) }}
              </td>
            </ng-container>

            <ng-container matColumnDef="ip">
              <th class="text-center" mat-header-cell *matHeaderCellDef> IP </th>
              <td class="text-center" mat-cell *matCellDef="let element"> {{ element.ip }} </td>
            </ng-container>

            <ng-container matColumnDef="country_code">
              <th class="text-center" mat-header-cell *matHeaderCellDef>{{ translationsLib.get('profile_account_country') }}</th>
              <td class="text-center" mat-cell *matCellDef="let element"> {{ element.country_code | uppercase }} </td>
            </ng-container>

            <ng-container matColumnDef="region">
              <th class="text-center" mat-header-cell *matHeaderCellDef>{{ translationsLib.get('profile_account_province') }}</th>
              <td class="text-center" mat-cell *matCellDef="let element"> {{ element.region }} </td>
            </ng-container>

            <ng-container matColumnDef="city">
              <th class="text-center" mat-header-cell *matHeaderCellDef> {{ translationsLib.get('profile_account_city') }} </th>
              <td class="text-center" mat-cell *matCellDef="let element"> {{ element.city }} </td>
            </ng-container>

            <ng-container matColumnDef="platform">
              <th class="text-center" mat-header-cell *matHeaderCellDef> {{ translationsLib.get('operative_system') }} </th>
              <td class="text-center" mat-cell *matCellDef="let element"> {{ element.platform }} </td>
            </ng-container>

            <ng-container matColumnDef="browser">
              <th class="text-center" mat-header-cell *matHeaderCellDef> {{ translationsLib.get('browser') }} </th>
              <td class="text-center" mat-cell *matCellDef="let element"> {{ element.browser }} </td>
            </ng-container>

            <ng-container matColumnDef="first_session_at">
              <th class="d-none d-lg-table-cell text-center" mat-header-cell *matHeaderCellDef> {{ translationsLib.get('auth_2fa_first_session_at') }} </th>
              <td class="d-none d-lg-table-cell text-center" mat-cell *matCellDef="let element"> {{ element.first_session_at }} </td>
            </ng-container>

            <ng-container matColumnDef="last_session_at">
              <th class="text-center" mat-header-cell *matHeaderCellDef> {{ translationsLib.get('auth_2fa_last_session_at') }} </th>
              <td class="text-center" mat-cell *matCellDef="let element"> {{ element.last_session_at }} </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          </table>

          <mat-paginator
            [length]="totalItems"
            [pageSize]="pageSize"
            [pageSizeOptions]="[20]"
            showFirstLastButtons
            (page)="onPageChange($event)">
          </mat-paginator>
        </div>

      </div>
    </div>
  </div>
</div>
