import { Component, Input, OnInit } from '@angular/core';
import { TranslationsLibService } from '@nutricontrol/app360-shared';
import { Router } from '@angular/router';

@Component({
  selector: 'app-terminal-temporary-disabled-alert',
  templateUrl: './terminal-temporary-disabled-alert.component.html',
  styleUrls: ['./terminal-temporary-disabled-alert.component.scss'],
})
export class TerminalTemporaryDisabledAlertComponent implements OnInit {
  constructor(
    public translationsLib: TranslationsLibService,
    private router: Router
  ) {}

  ngOnInit() {}

  reviseTerminal() {
    this.router.navigate(['/profile'], {
      fragment: 'administrative_management',
    });
  }
}
