import {Component, Input, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {
  SessionLibService,
  TranslationsLibService,
} from '@nutricontrol/app360-shared';
import { ReportsService } from '../../reports.service';
import { ReportRequestData } from '../../reports.model';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-reports-raw-sensors-table',
  templateUrl: './reports-raw-sensors-table.component.html',
  styleUrls: ['./reports-raw-sensors-table.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ReportsRawSensorsTableComponent implements OnInit {
  @Input() data;
  // eslint-disable-next-line @typescript-eslint/no-inferrable-types
  @Input() tablePageItemNumber: number = 20;
  @Input() count: number;
  @Input() reportName = '';
  @Input() sensorName = '';
  @Input() unit = '';
  @Input() columnsToDisplay = [];
  @Input() requestData: ReportRequestData;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  expandedElement;
  dataSource: MatTableDataSource<any>;
  ready = false;
  downloadPDF = false;
  filterValues = {
    name: '',
    date: '',
    value: '',
  };
  filterForm = new FormGroup({
    name: new FormControl(),
    date: new FormControl(),
    value: new FormControl(),
  });
  //LO DEL NOMBRE ES POR SI LUEGO MEZCLAMOS,DEJO EL FILTRO LISTO
  get name() {
    return this.filterForm.get('name');
  }
  get date() {
    return this.filterForm.get('date');
  }
  get value() {
    return this.filterForm.get('value');
  }
  constructor(
    public translationsLib: TranslationsLibService,
    public reportsService: ReportsService,
    public session: SessionLibService
  ) {}

  ngOnInit() {
    this.ready = true;
    console.log(this.columnsToDisplay);
    this.dataSource = new MatTableDataSource(this.data);
  }
  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.formSubscribe();
    this.getFormsValue();
  }
  formSubscribe() {
    /*this.name.valueChanges.subscribe((beginValue) => {
      this.filterValues.name = beginValue;
      this.dataSource.filter = JSON.stringify(this.filterValues);
    });*/
    this.date.valueChanges.subscribe((endValue) => {
      this.filterValues.date = endValue;
      this.dataSource.filter = JSON.stringify(this.filterValues);
    });
    this.value.valueChanges.subscribe((value) => {
      this.filterValues.value = value;
      this.dataSource.filter = JSON.stringify(this.filterValues);
    });
  }
  getFormsValue() {
    this.dataSource.filterPredicate = (data, filter: string): boolean => {
      const searchString = JSON.parse(filter);
      return (
        /*data.name
          .toString()
          .trim()
          .toLowerCase()
          .indexOf(searchString.name.toLowerCase()) !== -1 &&*/
        data.date
          .toString()
          .trim()
          .toLowerCase()
          .indexOf(searchString.date.toLowerCase()) !== -1 &&
        data.value
          .toString()
          .trim()
          .toLowerCase()
          .indexOf(searchString.value.toLowerCase()) !== -1
      );
    };
    this.dataSource.filter = JSON.stringify(this.filterValues);
  }
  exportToPDF() {
    this.downloadPDF = true;
    setTimeout(() => {
      this.downloadPDF = false;
    }, 4000);
  }
}
