import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { SessionLibService } from '@nutricontrol/app360-shared';
import { TranslationsLibService } from '@nutricontrol/app360-shared';
import { ProfileService } from '../../profile.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { AuthLoginLogDataModel } from '../../profile.model';

@Component({
  selector: 'app-auth-login-log',
  templateUrl: './auth-login-log.component.html',
  styleUrls: ['./auth-login-log.component.scss'],
})
export class AuthLoginLogComponent implements OnInit {
  @Input() userVid: string;

  ready = false;

  displayedColumns: string[] = [
    'auth_2fa_type',
    'auth_2fa_user_preference',
    'ip',
    'country_code',
    'region',
    'city',
    'platform',
    'browser',
    'first_session_at',
    'last_session_at',
  ];

  dataSource = new MatTableDataSource<AuthLoginLogDataModel>([]);
  totalItems = 0;
  pageSize = 0;
  currentPage = 0;

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  constructor(
    public translationsLib: TranslationsLibService,
    public sessionLib: SessionLibService,
    public profileService: ProfileService
  ) {}

  ngOnInit(): void {
    this.loadAuthLoginLogs();
  }

  loadAuthLoginLogs(): void {
    this.ready = false;
    this.profileService
      .getAuthLoginLogs(this.userVid)
      .subscribe((authLoginLogs) => {
        this.dataSource.data = authLoginLogs.items;
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.totalItems = authLoginLogs.total;
        this.pageSize = authLoginLogs.page_items_number;
        this.currentPage = authLoginLogs.current_page;
        this.ready = true;
      });
  }

  onPageChange(event: any): void {
    this.currentPage = event.pageIndex;
    this.pageSize = event.pageSize;
    this.loadAuthLoginLogs();
  }
}
