<div class="row mx-0">
  <div class="d-flex p-0">
    <img src="../../../../assets/img/Icons/UCROP_Grafica_Lineal_Personalizada.svg" style="max-height: 60px">
    <div class="row">
      <!--<button id="edit" class="btn btn-light d-none d-md-block editButton"
        (click)="changeMode()">
        <ion-icon name="create-outline"></ion-icon>
      </button>-->
      <a class="mx-2 mt-4 mb-4">{{translationsLib.get('graphics_custom')}}</a>
      <span class="mx-2 mb-2 fw-bold d-md-flex w-100">
        <span style="margin-top: 4px">{{translationsLib.get('DL_sensors')}}:</span>
        <br>
        <span>
          <app-selected-sensors
            [sensoresOrdenados]="sensoresOrdenados"
            [sensoresSelecteds]="sensoresSelecteds"
            [viewMode]="'megapop_edit'"
            [operation]="operation"
            [grouped]="true">
          </app-selected-sensors>
        </span>
      </span>
      <span class="date mx-2 fw-bold">
        {{translationsLib.get('graphics_dates')}}:
        <span>
          <app-selected-dates [viewMode]="2" [chartGotData]="chartGotData"></app-selected-dates>
        </span>
      </span>
    </div>
  </div>
</div>
