import { Component, Input } from '@angular/core';
import { TranslationsLibService } from '@nutricontrol/app360-shared';

@Component({
  selector: 'app-info-pill',
  templateUrl: './info-pill.component.html',
  styleUrls: ['./info-pill.component.scss'],
})
export class InfoPillComponent {
  @Input() type:
    | 'IRRIGATION'
    | 'CLIMATE'
    | 'DATALOGGER'
    | 'CONFIGURABLE'
    | 'UNKNOWN';
  @Input() class: string;
  @Input() height = '46px';
  @Input() value: number | string | undefined;
  @Input() magnitudeLabel: string;
  @Input() magnitudeUnit: string;
  @Input() magnitudeTooltip: string | undefined;
  @Input() icon: string;
  @Input() virtual = false;
  @Input() hideIconOnMobile = true;

  constructor(public translationsLib: TranslationsLibService) {}
}
