import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RestApiService } from '../services/api/rest-api.service';
import { ModuleDataDataModel, ModuleDataModel } from './modules.model';

@Injectable({
  providedIn: 'root',
})
export class ModulesService {
  private restApiService: RestApiService;

  constructor(restApiService: RestApiService) {
    this.restApiService = restApiService;
  }
  public getModules(): Observable<ModuleDataModel[]> {
    return new Observable((observer) => {
      this.restApiService.getModules().subscribe(
        (response) => {
          observer.next(response);
        },
        (error) => {
          observer.error(error);
        }
      );
    });
  }
  public getModulesByVid(vid: string): Observable<ModuleDataModel> {
    return new Observable((observer) => {
      this.restApiService.getModulesByVid(vid).subscribe(
        (response) => {
          observer.next(response);
        },
        (error) => {
          observer.error(error);
        }
      );
    });
  }
  public getModulesByTerminalVid(vid: string): Observable<ModuleDataModel[]> {
    return new Observable((observer) => {
      this.restApiService.getModulesByTerminalVid(vid).subscribe(
        (response) => {
          observer.next(response);
        },
        (error) => {
          observer.error(error);
        }
      );
    });
  }
  public getModuleDataByVid(
    vid: string,
    data
  ): Observable<ModuleDataDataModel> {
    return new Observable((observer) => {
      this.restApiService.getModuleDataByVid(vid, data).subscribe(
        (response) => {
          observer.next(response);
        },
        (error) => {
          observer.error(error);
        }
      );
    });
  }
}
