<div class="card">
  <div class="card-header">
    <div class="card-header-title my-2">
      <lib-terminal-header
        [equipment]="equipment"
        [link]="'/farming/' + equipment.serialNumber + '/datalogger'"
        [showStatusIcon]="true"
        [connected]="equipment.connected"
      ></lib-terminal-header>
    </div>
  </div>
  <div class="row mx-md-1" *ngIf="module !== undefined">
    <app-datalogger-dashboard-terminals-general
      [terminal_vid]="equipment.serialNumber"
      [elementsDisposition]="2"
      [module]="module"
    ></app-datalogger-dashboard-terminals-general>
  </div>
</div>
