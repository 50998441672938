import * as Highcharts from 'highcharts/highstock';
import { TranslationsLibService } from '@nutricontrol/app360-shared';
export const LineChartsModel: any = {
  chart: {
    marginTop: 50,
    type: 'line',
    zoomType: 'x',
    zooming: {
      mouseWheel: {
        enabled: false,
      },
    },
    panning: true,
    panKey: 'shift',
    events: {
      selection: function (event) {
        var chart = this;
        if (event.xAxis) {
          // Se ha realizado un zoom en el eje X
          if (!chart.resetZoomButton) {
            // @ts-ignore perdón si no no va :(
            const translationsLib = new TranslationsLibService();
            chart.resetZoomButton = chart.renderer
              .button(
                translationsLib.get('reset_zoom'),
                null,
                null,
                // eslint-disable-next-line prefer-arrow/prefer-arrow-functions
                function () {
                  chart.update({
                    marginTop: 50,
                  });
                  chart.xAxis[0].setExtremes(null, null);
                  chart.resetZoomButton.destroy();
                  chart.resetZoomButton = null;
                }
              )
              .attr({
                align: 'left',
                x: 70,
                y: 7,
                zIndex: 7,
                verticalAlign: 'top',
                floating: true,
              })
              .add();
          }
        } else {
          // No se ha realizado un zoom en el eje X
          if (chart.resetZoomButton) {
            chart.resetZoomButton.destroy();
            chart.resetZoomButton = null;
          }
        }
      },
    },
  },
  /*rangeSelector: {
    enabled: true,
    selected: 1,
    inputEnabled: false,
    buttonTheme: {
      width: 125,
      height: 30,
      style: {
        fontSize: '14px',
      },
    },
    buttonPosition: {
      align: 'right',
      x: 0,
      y: 0,
    },
    buttons: [
      {
        type: 'all',
        text: 'Reset Zoom',
        theme: {
          fill: 'white',
          'stroke-width': 1,
          stroke: 'silver',
          r: 0,
        },
      },
    ],
    labelStyle: {
      display: 'none', // Ocultar el texto "Zoom"
    },
  },*/
  navigator: {
    enabled: true,
    xAxis: {
      type: 'category',
      tickPositioner: function () {
        var positions = [],
          tick = 0,
          increment = 100; // Adjust the increment as needed
        for (tick; tick <= this.categories.length; tick += increment) {
          positions.push(tick);
        }
        return positions;
      }
      /*type: 'datetime',
      labels: {
        enabled: false
      }*/
    },
  },
  title: {
    align: 'left',
    text: null,
  },
  subtitle: {
    align: 'left',
    text: '',
  },
  accessibility: {
    announceNewData: {
      enabled: true,
    },
  },
  xAxis: {
    categories: [],
  },
  yAxis: [
    {
      title: {
        text: null,
      },
    },
  ],
  plotOptions: {},
  legend: {
    title: {
      text: null,
    },
    /*layout: 'vertical',
    align: 'right',
    verticalAlign: 'top',
    x: -40,
    y: 0,
    floating: true,
    borderWidth: 1,
    backgroundColor:
      Highcharts.defaultOptions.legend.backgroundColor || '#FFFFFF',
    shadow: true,*/
  },
  credits: {
    enabled: false,
  },
  tooltip: {
    headerFormat: '<span style="font-size:11px">{point.x}</span><br>',
    /*pointFormat: '<b>{point.y:.2f}</b><br/>',*/
    shared: true,
    /* formatter() {
      return Highcharts.numberFormat(this.y, 2);
    },*/
    valueDecimals: 2,
  },
  dataLabels: {
    enabled: true,
    formatter() {
      return Highcharts.numberFormat(this.y, 2);
    },
  },
  series: [
    {
      name: '',
      unit: '',
      colorByPoint: false,
      data: [],
      id: '',
    },
  ],
  exporting: {
    filename: 'ucrop chart',
    buttons: {
      contextButton: {
        menuItems: [
          'printChart',
          'separator',
          'downloadPNG',
          'downloadJPEG',
          'downloadPDF',
          'separator',
          'downloadCSV',
          'downloadXLS',
          //"viewData",
          'openInCloud',
        ],
      },
    },
  },
};
