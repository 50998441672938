import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HelpersLibService } from '../../services/libraries/helpers-lib.service';
import { Breadcrumb } from '../../farming/irrigation/shared/shared.model';
import { ReportsService } from '../reports.service';
import { TerminalsLibService } from '../../services/libraries/terminals-lib.service';
import { TerminalsService } from '../../terminals/terminals.service';
import { FormControl, FormGroup } from '@angular/forms';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from '@angular/material/core';
import {
  AvailableReportModel,
  CreatedReportModel,
  FieldReportModel,
  RandomReportModel,
  SensorReportType,
  TerminalReportModel,
} from '../reports.model';

import Swal from 'sweetalert2';
import * as moment from 'moment';
import { MatStepper } from '@angular/material/stepper';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import {
  SessionLibService,
  TranslationsLibService,
} from '@nutricontrol/app360-shared';
import {
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
  MAT_MOMENT_DATE_FORMATS,
  MomentDateAdapter,
} from '@angular/material-moment-adapter';
import { SensorDataModel } from '../../farming/irrigation/irrigation.model';

@Component({
  selector: 'app-reports',
  templateUrl: './reports-page.component.html',
  styleUrls: ['./reports-page.component.scss'],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'es-ES' },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
  ],
})
export class ReportsPageComponent implements OnInit {
  @ViewChild('stepper') private stepper: MatStepper;
  selectedAvailableTerminals: TerminalReportModel[] = [];
  minDate: Date;
  maxDate: Date;
  ready = false;
  selectedReport = '';
  selectedGroupingType: SensorReportType = 'mixed';
  allTerminals = null;
  selectedSensors: [SensorDataModel[]];
  createdReport: CreatedReportModel = null;
  report = new FormControl('');
  range = new FormGroup({
    start: new FormControl<Date | null>(null),
    end: new FormControl<Date | null>(null),
  });
  tablePageItemNumber = 20;
  breadcrumbs: Breadcrumb[] = [];
  availableTerminals: TerminalReportModel[] = [];
  availableTerminalsByField: FieldReportModel[] = [];
  availableReports: AvailableReportModel[] = [];
  selectedButtonDates = null;
  showReportStartDate = '';
  showReportDateSplitter = '';
  showReportEndDate = '';
  simpleTable = false;
  dates = ['24h', '48h', '7d'];
  reportSearched = false;
  otherDates = false;
  stepperOrientation: 'horizontal' | 'vertical' = 'horizontal';
  createdReportBeginString = '';
  createdReportEndString = '';
  datesClick = false;
  selectedItem = null;
  itemText = '';
  selectedReportItem = null;
  sensorReportTypes = ['mixed', 'magnitudes', 'terminal', 'field'];
  itemReportText = '';
  constructor(
    private route: ActivatedRoute,
    private dateAdapter: DateAdapter<Date>,
    private helpersLib: HelpersLibService,
    public router: Router,
    private reportsService: ReportsService,
    private sessionLib: SessionLibService,
    private terminalsLib: TerminalsLibService,
    private terminalsService: TerminalsService,
    public translationsLib: TranslationsLibService,
    private breakpointObserver: BreakpointObserver,
    @Inject(MAT_DATE_LOCALE) private _locale: string
  ) {
    this.dateAdapter.setLocale(sessionLib.getLanguage());
    this.dateAdapter.getFirstDayOfWeek = () => 1;
    this._locale = sessionLib.getLanguage();
    this.breakpointObserver
      .observe([Breakpoints.Handset])
      .subscribe((result) => {
        this.stepperOrientation = result.matches ? 'vertical' : 'horizontal';
      });
  }

  ngOnInit() {
    this.helpersLib.sendEventPageView('Reports');
    this.helpersLib.setTitle(
      '',
      this.translationsLib.get('reports_reports'),
      null
    );
    this.breadcrumbs = [
      {
        text: this.translationsLib.get('reports_reports'),
        disabled: false,
        to: ' ',
        active: true,
      },
    ];

    this.minDate = moment().subtract(60, 'd').toDate();
    this.maxDate = new Date();

    this.getTerminals();
    //this.setIrrigationDevReport();
    //this.setProgramsConsumptionDevReport();
    //this.setGroupingConsumptionDevReport();
    //this.setAlarmsDevReport();
  }
  selectItem(itemId): void {
    this.selectedItem = itemId;
    this.selectedReport = itemId;
    this.itemText = this.translationsLib.get('reports_text_about_' + itemId);
  }
  selectItemReportType(item): void {
    this.selectedReportItem = item;
    this.selectedGroupingType = item;
    this.itemReportText = this.translationsLib.get('reports_text_about_' + item);
  }
  getTerminals() {
    this.availableTerminals = [];
    this.terminalsService.getTerminals().subscribe((terminals) => {
      this.allTerminals = terminals;
      terminals.forEach((terminal) => {
        if (
          (this.terminalsLib.getType(terminal.type) === 'IRRIGATION' ||
            this.selectedReport === 'raw_sensors' ||
            this.selectedReport === 'default_sensors') &&
          terminal.is_demo !== true && terminal.enabled === 1
        ) {
          const irrigationTerminal = {
            vid: terminal.vid,
            name_vid: terminal.name + ' - ' + terminal.vid,
            name: terminal.name,
            field_name: null,
            field_vid: null,
            source_database: terminal.source_database,
            completed: false,
            type: terminal.type,
            enabled: terminal.enabled,
            field: terminal.field
          };
          if (terminal.field !== null) {
            irrigationTerminal.field_name = terminal.field.name;
            irrigationTerminal.field_vid = terminal.field.vid;
          }
          this.availableTerminals.push(irrigationTerminal);
        }
      });
      this.parseParams();
      this.availableReports =
        this.reportsService.getAvailableReports(terminals);
      this.availableTerminalsByField = this.groupByFieldName(
        this.availableTerminals
      );
      this.ready = true;
    });
  }
  groupByFieldName(terminals: TerminalReportModel[]): FieldReportModel[] {
    const grouped = terminals.reduce((acc, record) => {
      const key =
        record.field_name || this.translationsLib.get('devices_no_field');
      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(record);
      return acc;
    }, {} as { [key: string]: TerminalReportModel[] });

    return Object.keys(grouped).map((key) => ({
      key,
      terminals: grouped[key],
    }));
  }
  updateAllComplete() {
    this.selectedAvailableTerminals = [];
    this.availableTerminals.map((terminal) => {
      if (terminal.completed) {
        this.selectedAvailableTerminals.push(terminal);
      }
    });
  }

  parseParams() {
    this.route.queryParams.subscribe((params) => {
      if ('selectedAvailableTerminals' in params) {
        const sit = params.selectedAvailableTerminals.split(';');
        // eslint-disable-next-line @typescript-eslint/prefer-for-of
        for (let x = 0; x < sit.length; x++) {
          this.availableTerminals.forEach((irrigationTerminal) => {
            if (sit[x] === irrigationTerminal.vid) {
              this.selectedAvailableTerminals.push(irrigationTerminal);
            }
          });
        }
      }
      if ('simpleTable' in params) {
        this.simpleTable = params.simpleTable === 'true';
      }
      if ('selectedReport' in params) {
        this.report.setValue(params.selectedReport);
      }
      if ('tablePageItemNumber' in params) {
        this.tablePageItemNumber = parseInt(params.tablePageItemNumber, 10);
      }
      if ('rangeStart' in params && 'rangeEnd' in params) {
        this.showReportStartDate = moment(
          decodeURI(params.rangeStart),
          'YYYY-MM-DD'
        ).format('DD/MM/YYYY');
        const proposedShowReportEndDate = moment(
          decodeURI(params.rangeEnd),
          'YYYY-MM-DD'
        ).format('DD/MM/YYYY');
        if (this.showReportStartDate !== proposedShowReportEndDate) {
          this.showReportDateSplitter = '-';
          this.showReportEndDate = proposedShowReportEndDate;
        }

        this.range.setValue({
          start: moment(decodeURI(params.rangeStart), 'YYYY-MM-DD').toDate(),
          end: moment(decodeURI(params.rangeEnd), 'YYYY-MM-DD').toDate(),
        });
      }
      if ('auto' in params) {
        this.createReport();
      }
    });
  }

  createReport() {
    if (
      this.report.value.length > 0 &&
      this.selectedAvailableTerminals.length > 0 &&
      this.range.value.start !== null &&
      this.range.value.end !== null
    ) {
      this.reportSearched = true;
      this.selectedReport = 'none';
      const self = this;

      this.createdReportBeginString = moment(this.range.value.start).format(
        'YYYY/MM/DD'
      );
      this.createdReportEndString = moment(this.range.value.end).format(
        'YYYY/MM/DD'
      );

      // Force update on regenerate selectedReport
      // eslint-disable-next-line prefer-arrow/prefer-arrow-functions
      setTimeout(() => {
        self.selectedReport = self.report.value;
        self.createdReport = {
          report: self.report.value,
          terminals: self.selectedAvailableTerminals,
          sensors: self.selectedSensors,
          dateRange: {
            start: self.range.value.start,
            end: self.range.value.end,
          },
          tablePageItemNumber: this.tablePageItemNumber,
          simpleTable: this.simpleTable,
        };
      }, 100);
    } else {
      Swal.fire({
        text: this.translationsLib.get('fill_in_all_inputs_alert'),
        showConfirmButton: true,
        confirmButtonText: this.translationsLib.get('accept'),
        icon: 'error',
      });
    }
  }

  compareTerminalReportModel = (item, selected) => {
    if (selected.field_vid && item.field_vid) {
      return item.field_vid === selected.field_vid;
    }
    if (item.vid && selected.vid) {
      return item.vid === selected.vid;
    }
    return false;
  };
  resetAll() {
    this.report.setValue(null);
    this.selectedAvailableTerminals.forEach((terminal) => {
      terminal.completed = false;
    });
    this.reportSearched = false;
    this.selectedGroupingType = 'mixed';
    this.selectedReport = '';
    this.range = new FormGroup({
      start: new FormControl<Date | null>(null),
      end: new FormControl<Date | null>(null),
    });
    this.datesClick = false;
    this.selectedItem = null;
    this.selectedReportItem = null;
  }
  putDates(time) {
    this.datesClick = true;
    const end = new Date();
    const start = new Date();
    if (time === null) {
      const oldSelected = document.getElementById(this.selectedButtonDates);
      oldSelected.style.backgroundColor = '#f0f4fa';
      oldSelected.style.borderColor = '#f0f4fa';
      oldSelected.style.color = '#283e59';
      this.selectedButtonDates = null;
    } else {
      if (this.selectedButtonDates === null) {
        const selected = document.getElementById(time);
        selected.style.backgroundColor = '#66B8BE';
        selected.style.color = '#FFFFFF';
        this.selectedButtonDates = time;
      } else {
        const oldSelected = document.getElementById(this.selectedButtonDates);
        oldSelected.style.backgroundColor = '#f0f4fa';
        oldSelected.style.borderColor = '#f0f4fa';
        oldSelected.style.color = '#283e59';
        const selected = document.getElementById(time);
        selected.style.backgroundColor = '#66B8BE';
        selected.style.color = '#FFFFFF';
        this.selectedButtonDates = time;
      }
      switch (time) {
        case '24h':
          start.setDate(end.getDate() - 1);
          break;
        case '48h':
          start.setDate(end.getDate() - 2);
          break;
        case '7d':
          start.setDate(end.getDate() - 7);
          break;
      }
      this.range.patchValue({
        start,
        end,
      });
    }
  }
  putReportData(report: RandomReportModel) {
    this.report.setValue(report.reportType.id);
    this.selectedAvailableTerminals = [report.terminal];
    this.putDates(report.date);
    this.createReport();
  }
}
