import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { FieldCardComponent } from './fields/card/field-card.component';
import { ConfigurableWaterSystemComponent } from './configurable-water-system.component';
import { FieldCardDoubleComponent } from './fields/cardDouble/field-card-double.component';
import { FieldCardTripleComponent } from './fields/cardTriple/field-card-triple.component';
import { FieldChipsComponent } from './fields/chips/field-chips.component';
import { FieldFertilizerFormulaComponent } from './fields/fertilizerFormula/field-fertilizer-formula.component';
import { FieldProgressComponent } from './fields/progress/field-progress.component';
import { NouisliderComponent } from 'ng2-nouislider';
import { MatIconModule } from '@angular/material/icon';
import { MatChipsModule } from '@angular/material/chips';

@NgModule({
  declarations: [
    FieldCardComponent,
    FieldCardDoubleComponent,
    FieldCardTripleComponent,
    FieldChipsComponent,
    FieldFertilizerFormulaComponent,
    FieldProgressComponent,
  ],
  imports: [
    CommonModule,
    NouisliderComponent,
    MatIconModule,
    MatChipsModule,
    FormsModule,
  ],
  exports: [
    FieldCardComponent,
    FieldCardDoubleComponent,
    FieldCardTripleComponent,
    FieldChipsComponent,
    FieldFertilizerFormulaComponent,
    FieldProgressComponent,
  ],
  providers: [ConfigurableWaterSystemComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ConfigurableWaterSystemsModule {}
