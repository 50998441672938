<app-main-header [breadcrumbs]="breadcrumbs" [name]="translationsLib.get('reports_reports')"></app-main-header>
<ion-content>
  <div class="container-fluid p-1 p-lg-2 mt-4">
    <div class="mx-lg-4">
      <mat-tab-group mat-align-tabs="start">
        <mat-tab label="{{ translationsLib.get('reports_reports') }}">
          <lib-loader *ngIf="ready === false"></lib-loader>
          <ng-container *ngIf="reportSearched === false && ready === true">
            <div class="row mt-4">
              <div class="col-12 col-xl-4">
                <div class="card">
                  <div class="card-body">
                    <div class="d-none d-xl-block">
                      <div class="text-center">
                        <img src="assets/img/reports/welcome_reports.svg" style="max-height: 200px">
                        <hr class="hr hr-blurry mx-4">
                      </div>
                      <section>
                        <h2
                          class="text-center mt-0 mb-4 text-header-primary">{{translationsLib.get('reports_what_are')}}</h2>
                        <p class="m-0">{{translationsLib.get('reports_explain')}}</p>
                      </section>
                    </div>
                    <div class="d-xl-none">
                      <div class="row align-items-center">
                        <div class="d-none d-sm-block col-4">
                          <img src="assets/img/reports/welcome_reports.svg" style="max-height: 200px" class="img-fluid" alt="...">
                        </div>
                        <div class="col-12 col-sm-8">
                          <div class="card-body">
                            <h2 class="text-center mt-0 mb-4 text-header-primary">{{translationsLib.get('reports_what_are')}}</h2>
                            <p class="card-text" style="text-align: justify">{{translationsLib.get('reports_explain')}}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 col-xl-8"  *ngIf="allTerminals!== null && allTerminals.length>0">
                <div class="card">
                  <div class="card-header">
                    <div class="card-header-title">
                      <h2 style="color: #224261">{{translationsLib.get('create_your_report')}}</h2>
                    </div>
                  </div>
                  <div class="card-body px-2 px-lg-4">
                    <div class="alert alert-warning mb-2" role="alert" *ngIf="availableReports.length === 0">
                      {{ translationsLib.get('reports_no_terminals') }}
                    </div>

                    <mat-stepper [linear]="true" [orientation]="stepperOrientation" *ngIf="availableReports.length > 0" #stepper>
                      <ng-template matStepperIcon="edit" let-index="index">
                        {{ index + 1 }}
                      </ng-template>
                      <mat-step>
                        <ng-template matStepLabel>{{translationsLib.get('choose_your_report')}}</ng-template>
                        <mat-radio-group class="example-radio-group" [formControl]="report"
                                         [(ngModel)]="selectedReport">
                          <!--<mat-radio-button class="example-radio-button" *ngFor="let item of availableReports"
                                            [value]="item.id">
                            {{item.name}}
                          </mat-radio-button>-->
                          <div class="row w-100">
                            <div class="card-container col-12 col-lg-6">
                              <mat-card
                                class="example-card mb-1"
                                *ngFor="let item of availableReports"
                                [class.selected]="selectedItem === item.id"
                                (click)="selectItem(item.id)">
                                <div class="row my-0">
                                  <div class="col-2 text-center">
                                    <div class="avatar avatar-sm" style="padding-left: 4px; padding-top:2px; padding-bottom: 2px">
                                      <img [src]="'assets/img/reports/icons/' + item.id + '.svg'" >
                                    </div>
                                  </div>
                                  <div class="col-10 align-content-center">
                                    <h4 style="color: #224261" class="mb-2 mt-2">{{ item.name }}</h4>
                                    <!--<p class="text-muted mb-2" style="font-size: 12px">Subtítulo</p>-->
                                  </div>
                                </div>
                              </mat-card>
                            </div>

                            <div class="col-12 col-lg-6">
                              <div
                                class="d-flex flex-column align-items-center text-center"
                                [hidden]="selectedReport === ''"
                              >
                                <img class="d-none d-md-flex img-fluid mb-3" src="assets/img/reports/icons/reports_gif.gif"
                                     alt="GIF"
                                     style="max-width: 200px">
                                <p class="text-muted">{{itemText}}</p>
                              </div>
                            </div>
                          </div>

                        </mat-radio-group>
                        <div class="float-end">
                          <button matStepperNext class="btn btn-terciary mx-2" (click)="getTerminals()" [disabled]="selectedReport === ''">
                            {{translationsLib.get('next')}}
                            <ion-icon style="vertical-align: middle" name="arrow-forward-circle-outline"></ion-icon>
                          </button>
                        </div>
                      </mat-step>
                      <mat-step [label]="translationsLib.get('reports_choose_devices')">
                        <span class="example-list-section">
                         <ng-container *ngFor="let field of availableTerminalsByField">
                           <h4 class="m-1" style="color:#224261">{{field.key}}</h4>
                           <hr class="hr mt-2 mb-3 hr-blurry">
                           <ng-container *ngFor="let terminal of field.terminals">
                             <mat-checkbox class="ms-0 ms-lg-2" [(ngModel)]="terminal.completed"
                                           (ngModelChange)="updateAllComplete()">
                                {{ terminal.name_vid}}
                              </mat-checkbox><br/>
                           </ng-container><br/>
                         </ng-container>
                        </span>
                        <div class="float-end">
                          <button class="btn btn-terciary mx-1" matStepperPrevious>
                            <ion-icon style="vertical-align: middle" name="arrow-back-circle-outline"></ion-icon>
                            {{translationsLib.get('previous')}}
                          </button>
                          <button class="btn btn-terciary mx-2" matStepperNext
                                  [disabled]="selectedAvailableTerminals.length===0">{{translationsLib.get('next')}}
                            <ion-icon style="vertical-align: middle" name="arrow-forward-circle-outline"></ion-icon>
                          </button>
                        </div>
                      </mat-step>
                      <mat-step [label]="translationsLib.get('reports_choose_sensors')" *ngIf="selectedReport === 'raw_sensors'">
                        <app-megapop *ngIf="stepper.selectedIndex >= 2"
                                     [viewMode]="'reports'"
                                     [reportsTerminals]="selectedAvailableTerminals"
                                     (selectedSensors)="selectedSensors = $event"
                        ></app-megapop>
                        <div class="float-end">
                          <button class="btn btn-terciary mx-1" matStepperPrevious>
                            <ion-icon style="vertical-align: middle" name="arrow-back-circle-outline"></ion-icon>
                            {{translationsLib.get('previous')}}
                          </button>
                          <button class="btn btn-terciary mx-2" matStepperNext
                                  [disabled]="!selectedSensors || !selectedSensors.length || selectedSensors[0]?.length === 0"
                          >{{translationsLib.get('next')}}
                            <ion-icon style="vertical-align: middle" name="arrow-forward-circle-outline"></ion-icon>
                          </button>
                        </div>
                      </mat-step>
                      <mat-step *ngIf="selectedReport === 'default_sensors'">
                        <ng-template matStepLabel>{{translationsLib.get('choose_your_agrupation_type')}}</ng-template>
                        <mat-radio-group class="example-radio-group" [(ngModel)]="selectedGroupingType">
                          <div class="row w-100">
                            <div class="card-container col-12 col-lg-6">
                              <mat-card
                                class="example-card mb-1"
                                *ngFor="let item of sensorReportTypes"
                                [class.selected]="selectedReportItem === item"
                                (click)="selectItemReportType(item)">
                                <div class="row my-0">
                                  <div class="col-2 text-center">
                                    <div class="avatar avatar-sm" style="padding-left: 4px; padding-top:2px; padding-bottom: 2px">
                                      <img [src]="'assets/img/reports/icons/' + item + '.svg'" >
                                    </div>
                                  </div>
                                  <div class="col-10 align-content-center">
                                    <h4 style="color: #224261" class="mb-2 mt-2">{{translationsLib.get('choose_your_agrupation_type_' + item)}}</h4>
                                  </div>
                                </div>
                              </mat-card>
                            </div>

                            <div class="col-12 col-lg-6">
                              <div
                                class="d-flex flex-column align-items-center text-center"
                                [hidden]="selectedReportItem === null"
                              >
                                <img class="d-none d-md-flex img-fluid mb-3" src="assets/img/reports/icons/reports_gif.gif"
                                     alt="GIF"
                                     style="max-width: 200px">
                                <p class="text-muted">{{itemReportText}}</p>
                              </div>
                            </div>
                          </div>
                          <!--<mat-radio-button class="example-radio-button" [value]="'mixed'">
                            {{translationsLib.get('choose_your_agrupation_type_mixed')}}
                          </mat-radio-button>
                          <mat-radio-button class="example-radio-button" [value]="'magnitudes'">
                            {{translationsLib.get('choose_your_agrupation_type_magnitudes')}}
                          </mat-radio-button>
                          <mat-radio-button class="example-radio-button" [value]="'terminal'">
                            {{translationsLib.get('choose_your_agrupation_type_device')}}
                          </mat-radio-button>
                          <mat-radio-button class="example-radio-button" [value]="'field'">
                            {{translationsLib.get('choose_your_agrupation_type_field')}}
                          </mat-radio-button>-->
                        </mat-radio-group>
                        <div class="float-end">
                          <button class="btn btn-terciary mx-1" matStepperPrevious>
                            <ion-icon style="vertical-align: middle" name="arrow-back-circle-outline"></ion-icon>
                            {{translationsLib.get('previous')}}
                          </button>
                          <button matStepperNext class="btn btn-terciary mx-2">
                            {{translationsLib.get('next')}}
                            <ion-icon style="vertical-align: middle" name="arrow-forward-circle-outline"></ion-icon>
                          </button>
                        </div>
                      </mat-step>
                      <mat-step [label]="translationsLib.get('reports_date_range')">
                        <div class="row my-lg-6 align-content-center">
                          <div class=" col-12 col-lg-3 mb-2 mb-lg-0">
                            <button class="btn btn-light " [ngClass]="{ '24hrs': datesClick }" id="24h"
                                    (click)="putDates('24h')">{{translationsLib.get('graphics_24h')}}</button>
                          </div>
                          <div class="col-12 col-lg-3 mb-2 mb-lg-0">
                            <button class="btn btn-light " [ngClass]="{ '48hrs': datesClick }" id="48h"
                                    (click)="putDates('48h')">{{translationsLib.get('graphics_48h')}}</button>
                          </div>
                          <div class="col-12 col-lg-2 mb-2 mb-lg-0">
                            <button class="btn btn-light" [ngClass]="{ '7days': datesClick }"  id="7d"
                                    (click)="putDates('7d')">{{translationsLib.get('graphics_7d')}}</button>
                          </div>
                          <div class="col-12 col-lg-4 ">
                            <mat-form-field appearance="fill"
                                            class="range-container mb-2 mb-lg-0 mt-0 text-center w-100">
                              <mat-label>{{ translationsLib.get('reports_date_range') }}</mat-label>
                              <mat-date-range-input [formGroup]="range" [rangePicker]="picker" [max]="maxDate">
                                <input matStartDate formControlName="start"
                                       placeholder="{{ translationsLib.get('start_date') }}">
                                <input matEndDate formControlName="end"
                                       placeholder="{{ translationsLib.get('end_date') }}">
                              </mat-date-range-input>
                              <mat-datepicker-toggle matIconSuffix [for]="picker"
                                                     (click)="putDates(null)"></mat-datepicker-toggle>
                              <mat-date-range-picker #picker></mat-date-range-picker>
                              <mat-error
                                *ngIf="range.controls.start.hasError('matStartDateInvalid')">{{ translationsLib.get('reports_date_range_invalid_start_date') }}</mat-error>
                              <mat-error
                                *ngIf="range.controls.end.hasError('matEndDateInvalid')">{{ translationsLib.get('reports_date_range_invalid_end_date') }}</mat-error>
                            </mat-form-field>
                          </div>
                        </div>
                        <div class="float-end text-end">
                          <button matStepperPrevious class="btn btn-terciary">
                            <ion-icon style="vertical-align: middle" name="arrow-back-circle-outline"></ion-icon>
                            {{translationsLib.get('previous')}}
                          </button>
                          <button class="btn btn-danger ms-3" (click)="createReport()"
                                  [disabled]="range.value.start===null && range.value.end === null">{{ translationsLib.get('reports_create_report') }}
                            <ion-icon style="position: relative; top: 3px;" name="open-outline"></ion-icon>
                          </button>
                        </div>
                      </mat-step>
                    </mat-stepper>
                  </div>
                </div>
              </div>
              <div class="col-12 col-xl-8" *ngIf="allTerminals!== null && allTerminals.length===0">
                <div class="card" style="border: 3px solid #ffd32c;">
                  <h4
                    class="mb-0 d-flex align-items-center justify-content-center"
                    style="font-weight: normal; padding:12px"
                  >
                    <ion-icon
                      style="font-size: 25px; margin-right: 8px;"
                      name="information-circle-outline"
                    ></ion-icon>
                    {{ translationsLib.get('reports_no_devices') }}
                  </h4>

                  <hr>
                  <div class="row justify-content-center mb-3">
                    <button class="btn add" (click)="router.navigate(['./pair-new-terminal'])">
                      {{ translationsLib.get('terminal_add_terminal') }}
                    </button>
                  </div>
                  <div class="row justify-content-center">
                    <img src="assets/img/fields_and_terminals_empty/Sin%20equipos.svg" style="width: 37%; max-width:270px">
                  </div>
                </div>
              </div>
            </div>
            <app-reports-suggested
              class="d-none d-lg-block"
              [availableTerminals]="this.availableTerminals"
              [availableReports]="this.availableReports"
              [dates]="this.dates"
              (parentPutReportData)="putReportData($event)"
              *ngIf="allTerminals!== null && allTerminals.length>0"
            ></app-reports-suggested>
          </ng-container>
          <!--*ngIf="reportSearched === true"-->
          <div class="card mt-4 mb-2" style="min-height: 100px" *ngIf="reportSearched === true">
            <div class="row mx-2">
              <div class="col-12 col-lg-2 d-flex align-items-center d-none d-lg-block mt-3 mb-0">
                <img *ngIf="selectedReport!== 'default_sensors' " [src]="'assets/img/reports/icons/'+selectedReport+'.svg'" style="max-height: 100px;">
                <img *ngIf="selectedReport=== 'default_sensors' " [src]="'assets/img/reports/icons/'+selectedGroupingType+'.svg'" style="max-height: 100px;">
              </div>
              <div class="col-12 col-lg-10 align-content-center">
                <div class="row align-content-center my-4">
                  <div class="col-12 col-md-4">
                    <h2 style="color: #ee7869"
                        class="mt-4 mt-lg-2 mb-0 report-titles">{{translationsLib.get('reports_selected')}}</h2>
                    <h2 class="mt-2" style="color: #224261">{{translationsLib.get('reports_' + this.report.value)}}
                      <span class="text-lowercase" *ngIf="report.value === 'default_sensors'">
                        ({{translationsLib.get('choose_your_agrupation_type_'+selectedGroupingType)}})
                      </span>
                    </h2>
                  </div>
                  <div class="col-12 col-md-4">
                    <h2 style="color: #ee7869"
                        class="mt-4 mt-lg-2 mb-0 report-titles">{{translationsLib.get('datalaogger_device')}}</h2>
                    <h4 class="mt-2" style="color: #224261"
                        *ngFor="let terminal of selectedAvailableTerminals">{{terminal.name_vid}}</h4>
                  </div>
                  <div class="col-12 col-md-4">
                    <h2 style="color: #ee7869"
                        class="mt-4 mt-lg-2 mb-0 report-titles">{{translationsLib.get('reports_selected_dates')}}</h2>
                    <h4 class="mt-2" style="color: #224261; max-height: 15px " *ngIf="otherDates === false">
                      {{ this.createdReportBeginString | transformDate }}
                      - {{ this.createdReportEndString | transformDate }}
                      <ion-icon class="mx-2" (click)="otherDates = true" style="cursor: pointer"
                                name="create-outline"></ion-icon>
                    </h4>
                    <mat-form-field appearance="fill" class="range-container mb-2 mb-lg-0 mt-0 text-center w-100"
                                    *ngIf="otherDates === true">
                      <mat-date-range-input [formGroup]="range" [rangePicker]="picker" [max]="maxDate">
                        <input matStartDate formControlName="start"
                               placeholder="{{ translationsLib.get('start_date') }}">
                        <input matEndDate formControlName="end" placeholder="{{ translationsLib.get('end_date') }}">
                      </mat-date-range-input>
                      <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                      <mat-date-range-picker #picker></mat-date-range-picker>

                      <mat-error
                        *ngIf="range.controls.start.hasError('matStartDateInvalid')">{{ translationsLib.get('reports_date_range_invalid_start_date') }}</mat-error>
                      <mat-error
                        *ngIf="range.controls.end.hasError('matEndDateInvalid')">{{ translationsLib.get('reports_date_range_invalid_end_date') }}</mat-error>
                    </mat-form-field>
                    <button class="btn btn-sm btn-terciary float-end" *ngIf="otherDates === true"
                            (click)="this.createReport(); otherDates = false">{{translationsLib.get('reports_refresh')}}</button>
                  </div>
                  <div class="col-8 d-none d-md-block" *ngIf="otherDates === false">

                  </div>
                  <div class="col-12 col-md-4 mt-4 mt-lg-0 text-end" *ngIf="otherDates === false">
                    <button class="btn btn-sm btn-danger" (click)="resetAll()">
                      <ion-icon name="arrow-back-circle-outline" style="position: relative; top: 3px;"></ion-icon>
                      <span>{{translationsLib.get('reports_new_report')}}</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <section id="report-section" class="mt-4" *ngIf="reportSearched === true">
            <app-reports-irrigations class="reports-main-component-container" *ngIf="selectedReport === 'irrigations'"
                                     [createdReport]="createdReport"></app-reports-irrigations>
            <app-reports-drainages class="reports-main-component-container" *ngIf="selectedReport === 'drainages'"
                                   [createdReport]="createdReport"></app-reports-drainages>
            <app-reports-alarms class="reports-main-component-container" *ngIf="selectedReport === 'alarms'"
                                [createdReport]="createdReport"></app-reports-alarms>
            <app-reports-consumptions class="reports-main-component-container"
                                      *ngIf="selectedReport === 'programs_consumptions'"
                                      [createdReport]="createdReport"></app-reports-consumptions>
            <app-reports-consumptions class="reports-main-component-container"
                                      *ngIf="selectedReport === 'groups_consumptions'"
                                      [createdReport]="createdReport"></app-reports-consumptions>
            <app-reports-raw-sensors class="reports-main-component-container"
                                      *ngIf="selectedReport === 'raw_sensors'"
                                      [createdReport]="createdReport"></app-reports-raw-sensors>
            <app-reports-graphics-sensors class="reports-main-component-container"
                                     *ngIf="selectedReport === 'default_sensors'"
                                     [createdReport]="createdReport" [type]="selectedGroupingType"></app-reports-graphics-sensors>
          </section>

        </mat-tab>

        <!-- Needs to be shown when ready === true to be sure that input parameters are loaded -->
        <mat-tab label="{{ translationsLib.get('reports_reports_tasks') }}" *ngIf="ready === true">
          <app-reports-tasks
            [availableReports]="availableReports"
            [availableTerminals]="availableTerminals"
            (refreshAvailableTerminals)=" selectedReport = $event; getTerminals();"
          ></app-reports-tasks>
        </mat-tab>

      </mat-tab-group>
    </div>
  </div>
</ion-content>
