<section class="terminal-irrigation-consulting-pill-component">
  <div class="card p-2 m-0 {{ class }}"
       [style.height]="height">
    <div class="row">
      <div class="col-6 text-uppercase lh-1 h-auto fw-bold fs-6 pill-label">
        <div class="d-inline">
          {{ translationsLib.get('modules_irrigation_advisor') }}
        </div>
      </div>
      <div *ngIf="status === 1" class="col-6 text-uppercase lh-1 h-auto fw-bold fs-6 pill-label">
        <div class="d-inline">
          {{ translationsLib.get('asesor_recomendation') }}
        </div>
      </div>
    </div>
    <div class="row h-100" *ngIf="status === 1">
      <div class="col-6 d-flex align-items-center">
        <div>
          <span class="fs-2 magnitude-value">
            {{ tensiometer.value || '-' }}
          </span>
          <span class="text-muted fs-5 magnitude-unit">
            &nbsp;{{ tensiometer.unit }}
          </span>
        </div>
      </div>
      <div class="col-6 d-flex align-items-center">
        <div>
          <span class="fs-2 magnitude-value">
            <ng-container *ngIf="irrigationBand">
              {{ irrigationBand?.bottom || '-' }}-{{ irrigationBand?.upper || '-' }}
            </ng-container>
            <ng-container *ngIf="!irrigationBand">
              -
            </ng-container>
          </span>
          <span class="text-muted fs-5 magnitude-unit">
            &nbsp;{{ tensiometer.unit }}
          </span>
        </div>
      </div>
      <div class="d-lg-block text-end col position-absolute pill-icon">
        <ion-icon name="water-outline" style="color: #ee7869; font-size: 22px; font-weight: bold; margin-left: 7px;"></ion-icon>
      </div>
    </div>
    <div class="row h-100" *ngIf="status === 2">
      <div class="col-auto d-flex align-items-center mb-0 processing fs-5 processing-status">
        <span class="me-2">
          <ion-icon class="align-middle" name="warning-outline"></ion-icon>
        </span>
        <span class="fst-italic lh-1">
          {{translationsLib.get('irrigation_consulting_data_processing')}}
        </span>
      </div>
    </div>
  </div>
</section>
