<div
  class="card-body fs-5 p-0 table-responsive"
  *ngIf="
    statesResponse &&
    statesStructureResponse &&
    meteoResponse &&
    meteoStructureResponse
  "
>
  <div class="elements-table">
    <div
      id="meteo-table-to-export"
      class="table mb-0 meteo-table"
      style="display: table"
    >
      <thead>
        <tr>
          <th scope="col" class="text-uppercase fs-6 terminal-table-header">
            {{ this.translationsLib.get('climate_desktop_meteoData') }}
          </th>
          <th scope="col" class="text-uppercase fs-6 terminal-table-header">
            {{ this.translationsLib.get('climate_desktop_table_value') }}
          </th>
        </tr>
      </thead>
      <tbody class="fs-4">
        <ng-container
          *ngFor="
            let meteo of getVisibleMeteoKeys()
              | desktopMeteoKey : searchMeteoText
          "
        >
          <tr>
            <td class="terminal-table-data">
              <ng-container [ngSwitch]="meteo.key">
                <i
                  *ngSwitchCase="'Met_time'"
                  [innerHTML]="'CLOCK' | sensorIcon"
                ></i>
                <i
                  *ngSwitchCase="'Met_temp'"
                  [innerHTML]="'TEMPERATURE' | sensorIcon"
                ></i>
                <i
                  *ngSwitchCase="'Met_rain'"
                  [innerHTML]="'DRIZZLE' | sensorIcon"
                ></i>
                <i
                  *ngSwitchCase="'Met_windDir'"
                  [innerHTML]="'SIGNPOST' | sensorIcon"
                ></i>
                <i
                  *ngSwitchCase="'Met_instRad'"
                  [innerHTML]="'ACCUMULATED_RADIATION' | sensorIcon"
                ></i>
                <i
                  *ngSwitchCase="'Met_freezeAl'"
                  [innerHTML]="'CHILL_HOURS' | sensorIcon"
                ></i>
                <i
                  *ngSwitchCase="'Met_pluv'"
                  [innerHTML]="'PLUVIOMETER' | sensorIcon"
                ></i>
                <i
                  *ngSwitchCase="'Met_day'"
                  [innerHTML]="'BRIGHTNESS_ALT' | sensorIcon"
                ></i>
                <i
                  *ngSwitchCase="'Met_stormAl1'"
                  [innerHTML]="'WIND_VELOCITY' | sensorIcon"
                ></i>
                <i
                  *ngSwitchCase="'Met_stormAl2'"
                  [innerHTML]="'WIND_VELOCITY' | sensorIcon"
                ></i>
                <i
                  *ngSwitchCase="'Met_hum'"
                  [innerHTML]="'HD' | sensorIcon"
                ></i>
                <i
                  *ngSwitchCase="'Met_windSpeed'"
                  [innerHTML]="'WIND_VELOCITY' | sensorIcon"
                ></i>
                <i
                  *ngSwitchCase="'Met_cummRad'"
                  [innerHTML]="'ACCUMULATED_RADIATION' | sensorIcon"
                ></i>
              </ng-container>
              {{ this.translationsLib.get('climate_' + meteo.key) }}
            </td>
            <td class="terminal-table-data">
              {{ getMeteoValueAndUnit(meteo.key) }}
            </td>
          </tr>
        </ng-container>
        <tr
          *ngIf="
            (getVisibleMeteoKeys() | desktopMeteoKey : searchMeteoText)
              ?.length === 0
          "
        >
          <td colspan="2" class="text-center terminal-table-data">
            {{ this.translationsLib.get('climate_desktop_table_no_results') }}
          </td>
        </tr>
      </tbody>
    </div>
  </div>
</div>
