<lib-loader *ngIf="loader === true"></lib-loader>

<ng-container *ngIf="loader === false">
  <section class="reports-main-subcomponent-container" *ngIf="this.chart !== null && this.chart.totalRealWaterConsumptionTotal > 0 && showCharts === true">
    <!-- DISABLED -->
    <app-reports-consumptions-charts
      [basicIrrigationConsumptionsReportBarsData]="this.chart.basicIrrigationGroupingConsumptionsReportBarsData"
      [basicFertilizerConsumptionsReportBarsData]="this.chart.basicFertilizerGroupingConsumptionsReportBarsData"
      [basicIrrigationConsumptionsReportChartData]="this.chart.basicIrrigationGroupingConsumptionsReportChartData"
      [basicFertilizerConsumptionsReportChartData]="this.chart.basicFertilizerGroupingConsumptionsReportChartData"
      [irrigationConsumptionsReportChartData]="this.chart.irrigationGroupingConsumptionsReportChartData"
      [fertilizerConsumptionsReportChartData]="this.chart.fertilizerGroupingConsumptionsReportChartData"
      [prettyBegin]="this.prettyBegin"
      [prettyEnd]="this.prettyEnd"
      [advancedModeIsAllowed]="advancedModeIsAllowed"
      [selectorModeIsAllowed]="false"
      [terminalCountWithConsumptions]="this.terminalCountWithConsumptions"
      *ngIf="false"
    ></app-reports-consumptions-charts>
  </section>

  <section class="reports-main-subcomponent-container" *ngFor="let reportData of reportsData;">
    <div class="card pb-3">
      <div class="card-header">
        <h2 class="card-header-title mb-3">
          {{ translationsLib.get('reports_irrigation_grouping_consumptions') }}  <!-- {{ reportData.terminalNameVid }} - {{this.terminalVid}} -->
        </h2>
        <!--
        <h2 class="card-header-subtitle">
          {{ reportData.terminalFieldName }}
        </h2>
        -->
      </div>
      <div class="row mx-2 mt-3 print-no-padding">
        <div class="col-12 p-0 p-lg-2 consumption-container print-no-padding">
          <div class="alert alert-info mb-2" role="alert" *ngIf="reportData.groupingConsumptions.length === 0">
            {{ translationsLib.get('reports_no_data') }}
          </div>

          <article *ngIf="reportData.groupingConsumptions.length > 0">
            <!-- No need for simple table in consumptions! -->
            <app-reports-consumptions-table
              [reportName]="reportData.reportName"
              [consumptionColumnsToDisplay]="this.reportsConsumptionService.consumptionColumnsToDisplay"
              [fertilizerColumnsToDisplay]="reportData.fertilizerColumns"
              [totals]="reportData.groupingTotals"
              [data]="reportData.groupingConsumptions"
              [count]="reportData.groupingConsumptions.length"
              [tablePageItemNumber]="createdReport.tablePageItemNumber"
              [requestData]="requestData"
              [reportType]="'groups'"
            ></app-reports-consumptions-table>
          </article>

        </div>
      </div>
    </div>
  </section>
</ng-container>
