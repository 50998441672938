import { Component, Input } from '@angular/core';
import { WaterSystemField } from '../../models/water-system.model';

@Component({
  selector: 'app-field-card',
  templateUrl: './field-card.component.html',
  styleUrls: ['./field-card.component.scss'],
})
export class FieldCardComponent {
  @Input() field: WaterSystemField;
  @Input() isMobile = false;

  constructor() {
    //
  }
}
