<div class="card">
  <div class="card-header" style="height: auto">
    <div class="card-header-title my-2">
      <lib-terminal-header
        [equipment]="equipment"
        [link]="'/farming/' + equipment.serialNumber + '/irrigation'"
        [showStatusIcon]="true"
        [connected]="equipment.connected"
        [irrigating]="isIrrigating(equipment)"
      ></lib-terminal-header>
    </div>
  </div>
  <div class="row mx-0 mx-lg-2 my-3">
    <div class="col-6 col-lg-3 col-xl mb-3">
      <app-info-pill
        [type]="'IRRIGATION'"
        [height]="'56px'"
        [value]="today_consumption"
        [magnitudeLabel]="translationsLib.get('irrigation_today_consumption')"
        [magnitudeUnit]="'m³'"
        [icon]="'HUMIDITY'">
      </app-info-pill>
    </div>
    <div class="col-6 col-lg-3 col-xl mb-3">
      <app-info-pill
        [type]="'IRRIGATION'"
        [height]="'56px'"
        [value]="yesterday_ET0"
        [magnitudeLabel]="translationsLib.get('daily_et0')"
        [magnitudeUnit]="'mm'"
        [icon]="'ET0'">
      </app-info-pill>
    </div>
    <div class="col-6 col-lg-3 col-xl mb-3">
      <app-info-pill
        [type]="'IRRIGATION'"
        [height]="'56px'"
        [value]="TEMPERATURE"
        [magnitudeLabel]="translationsLib.get('temperature')"
        [magnitudeUnit]="'ºC'"
        [icon]="'TEMPERATURE'">
      </app-info-pill>
    </div>
    <div class="col-6 col-lg-3 col-xl mb-3">
      <app-info-pill
        [type]="'IRRIGATION'"
        [height]="'56px'"
        [value]="HUMIDITY"
        [magnitudeLabel]="translationsLib.get('humidity')"
        [magnitudeUnit]="'%'"
        [icon]="'HUMIDITY'">
      </app-info-pill>
    </div>
  </div>
  <div class="row mx-0 mx-lg-2 mb-4">
    <div class="col-12">
      <app-water-systems
        [terminalVid]="equipment.serialNumber"
      ></app-water-systems>
    </div>
  </div>
  <div class="row mx-0 mx-lg-2">
    <div class="col-12 col-xl">
      <div class="row">
        <!-- Calendar -->
        <div class="col-lg-6 col-12">
          <div class="card card-inside">
            <div class="card-header">
              <div class="col-xxl-12 col-6">
                <h4 class="d-inline-block card-header-title me-3">
                  {{ translationsLib.get('calendar_calendar') }}
                </h4>
                <a
                  [routerLink]="[
                    '/terminal-calendar',
                    { vid: equipment.serialNumber }
                  ]"
                  class="btn btn-secondary lift"
                >
                  <ion-icon
                    [ios]="'arrow-redo-outline'"
                    [md]="'arrow-redo-outline'"
                  ></ion-icon>
                </a>
              </div>
            </div>
            <div class="card-body">
              <app-events-calendar-widget
                [mode]="'widget'"
                [terminal_vid]="equipment.serialNumber"
              ></app-events-calendar-widget>
            </div>
          </div>
        </div>
        <!-- Consumptions -->
        <div class="col-lg-6 col-12">
          <div class="card card-inside">
            <div class="card-header">
              <h4 class="d-inline-block card-header-title">
                {{ translationsLib.get('reports_consumptions') }}
              </h4>
            </div>
            <div class="card-body">
              <span class="p-3 text-muted" *ngIf="consumptionNoData === true">
                {{ this.translationsLib.get('reports_no_data') }}
              </span>

              <highcharts-chart
                [Highcharts]="Highcharts"
                [constructorType]="chartConstructor"
                [options]="consumptionChart"
                [(update)]="updateFlag"
                [callbackFunction]="chartCallback"
                [oneToOne]="true"
                style="width: 100%; height: 400px; display: block"
                *ngIf="consumptionNoData !== true"
              >
              </highcharts-chart>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

<!--  <div class="row mx-0 mx-lg-2" *ngIf="equipment.valves.length > 0">-->
<!--    <div class="col-12 col-xl card card-inside">-->
<!--      <div class="card-header">-->
<!--        <h4 class="mx-2 ms-0">{{ translationsLib.get('irrigations_valves_configured') }}</h4>-->
<!--      </div>-->
<!--      <div class="card-body px-0 px-sm-2">-->
<!--        <app-terminal-table-wrapper-->
<!--          [data]="equipment.valves"-->
<!--          [name]="translationsLib.get('irrigation_valves')"-->
<!--          [searchPlaceholder]="translationsLib.get('terminal_name')"-->
<!--          [isFetchingData]="false"-->
<!--          [terminal_vid]="equipment.serialNumber"-->
<!--          [tableHeaders]="!isMobile ? [-->
<!--            translationsLib.get('terminal_name'),-->
<!--            translationsLib.get('irrigation_group'),-->
<!--            translationsLib.get('terminal_status'),-->
<!--            translationsLib.get('irrigation_info'),-->
<!--          ] : [-->
<!--            translationsLib.get('terminal_name'),-->
<!--            translationsLib.get('irrigation_group'),-->
<!--            translationsLib.get('terminal_status'),-->
<!--          ]"-->
<!--        >-->
<!--          <ng-container *appTerminalTableData="let valve">-->
<!--            <tr class="row-pointer">-->
<!--              <ng-container *ngIf="valve.groups.length > 0">-->
<!--                <td class="terminal-table-data"-->
<!--                    [class]="isMobile && valve.irrigationParams ? 'border-bottom-0' : ''">-->
<!--                  {{ valve.name }}-->
<!--                </td>-->
<!--                <td class="terminal-table-data"-->
<!--                    [class]="isMobile && valve.irrigationParams ? 'border-bottom-0' : ''">-->
<!--                  {{ valve.groups.length > 0 ? valve.groups[0].name : '-' }}-->
<!--                </td>-->
<!--                <td class="text-center text-lg-start"-->
<!--                    [class]="isMobile && valve.irrigationParams ? 'border-bottom-0' : ''">-->
<!--                  <span-->
<!--                    class="badge bg-success bg-dot d-lg-none"-->
<!--                    [class]="valve.active ? 'bg-success' : 'bg-secondary'"-->
<!--                    *ngIf="valve.active"-->
<!--                  >-->
<!--                    &nbsp;-->
<!--                  </span>-->
<!--                  <span-->
<!--                    class="badge bg-secondary bg-dot d-lg-none"-->
<!--                    *ngIf="!valve.active"-->
<!--                  >-->
<!--                    &nbsp;-->
<!--                  </span>-->
<!--                  <span-->
<!--                    class="badge bg-success d-none d-lg-inline"-->
<!--                    *ngIf="valve.active"-->
<!--                  >-->
<!--                    {{ translationsLib.get('terminal_valve_active') }} - -->
<!--                      {{ valve.irrigationParams.nameProgram }}-->
<!--                  </span>-->
<!--                  <span-->
<!--                    class="badge bg-secondary d-none d-lg-inline"-->
<!--                    *ngIf="!valve.active"-->
<!--                  >-->
<!--                    {{ translationsLib.get('terminal_valve_inactive') }}-->
<!--                  </span>-->
<!--                </td>-->
<!--                <ng-container *ngIf="!isMobile">-->
<!--                  <td class="terminal-table-data" *ngIf="valve.irrigationParams">-->
<!--                    <ng-container *ngTemplateOutlet="irrigationInfo; context: { valve: valve }"></ng-container>-->
<!--                  </td>-->
<!--                  <td class="terminal-table-data" *ngIf="!valve.irrigationParams">-->
<!--                    - -->
<!--                  </td>-->
<!--                </ng-container>-->
<!--              </ng-container>-->
<!--            </tr>-->
<!--            <tr *ngIf="isMobile && valve.irrigationParams">-->
<!--              <td class="terminal-table-data border-top-0 pt-0" colspan="3">-->
<!--                <div class="row text-muted">-->
<!--                  <ng-container *ngTemplateOutlet="irrigationInfo; context: { valve: valve }"></ng-container>-->
<!--                </div>-->
<!--              </td>-->
<!--            </tr>-->
<!--          </ng-container>-->
<!--        </app-terminal-table-wrapper>-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->

</div>
<!--<ng-template let-valve="valve" #irrigationInfo>-->
<!--  <div [class]="isMobile ? 'col-6 mb-1' : 'mb-0'">-->
<!--    <span [class]="isMobile ? 'fw-bold' : ''">{{ translationsLib.get('irrigation_activations') }}:</span> {{ valve.irrigationParams.activations }}-->
<!--  </div>-->
<!--  <div [class]="isMobile ? 'col-6 mb-1' : 'mb-0'">-->
<!--    <span [class]="isMobile ? 'fw-bold' : ''">{{ translationsLib.get('irrigation_active_group') }}:</span> {{ valve.irrigationParams.activeGroup }}-->
<!--  </div>-->
<!--  <div [class]="isMobile ? 'col-6 mb-1' : 'mb-0'">-->
<!--    <span [class]="isMobile ? 'fw-bold' : ''">{{ translationsLib.get('irrigation_irrigated') }}:</span> {{ valve.irrigationParams.irrigated.quantity }}-->
<!--  </div>-->
<!--  <div [class]="isMobile ? 'col-6 mb-1' : 'mb-0'">-->
<!--    <span [class]="isMobile ? 'fw-bold' : ''">{{ translationsLib.get('reports_fertilize_phase') }}:</span> {{ translationsLib.get('reports_'+ valve.irrigationParams.phase) }}-->
<!--  </div>-->
<!--  <div [class]="isMobile ? 'col-12' : 'mb-0'">-->
<!--    <span [class]="isMobile ? 'fw-bold' : ''">{{ translationsLib.get('irrigation_remaining_time') }}:</span> {{ valve.irrigationParams.remaining }}-->
<!--  </div>-->
<!--</ng-template>-->
