import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IrrigationConsultingMainChartComponent } from './irrigation-consulting-main-chart/irrigation-consulting-main-chart.component';
import { HighchartsChartModule } from 'highcharts-angular';
import { AppCommonsModule } from '../../commons/app-commons.module';
import { IonicModule } from '@ionic/angular';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AuthModule } from '../../auth/auth.module';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { App360SharedModule } from '@nutricontrol/app360-shared';
import {MatTabsModule} from "@angular/material/tabs";

@NgModule({
  declarations: [IrrigationConsultingMainChartComponent],
    imports: [
        CommonModule,
        HighchartsChartModule,
        CommonModule,
        AppCommonsModule,
        IonicModule,
        MatFormFieldModule,
        MatDatepickerModule,
        ReactiveFormsModule,
        FormsModule,
        AuthModule,
        TimepickerModule,
        MatInputModule,
        MatButtonModule,
        App360SharedModule,
        MatTabsModule,
    ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  exports: [IrrigationConsultingMainChartComponent],
  providers: [],
})
export class IrrigationConsultingModule {}
