import { Component, Input } from '@angular/core';
import { WaterSystemFieldDouble } from '../../models/water-system.model';

@Component({
  selector: 'app-field-card-double',
  templateUrl: './field-card-double.component.html',
  styleUrls: ['./field-card-double.component.scss'],
})
export class FieldCardDoubleComponent {
  @Input() field: WaterSystemFieldDouble;
  @Input() isMobile = false;

  constructor() {
    //
  }
}
