import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PairTerminalsComponent } from './pair-terminals/pair-terminals.component';
import { TextAvatarModule } from '../text-avatar';
import { MatTabsModule } from '@angular/material/tabs';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { AppCommonsModule } from '../commons/app-commons.module';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { InfoTerminalsComponent } from './info-terminals/info-terminals.component';
import { PermissionsModule } from '../permissions/permissions.module';
import { TerminalsGeneralComponent } from './terminals-general/terminals-general.component';
import { TerminalsTableComponent } from './terminals-table/terminals-table.component';
import { TerminalActivationComponent } from './terminal-activation/terminal-activation.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { ProfileModule } from '../profile/profile.module';
import { RouterModule } from '@angular/router';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { ProgressBarComponent } from './progress-bar/progress-bar.component';
import { AuthModule } from '../auth/auth.module';
import { TerminalsEditComponent } from './terminals-edit/terminals-edit.component';
import { TerminalsAdminTabsComponent } from './terminals-admin-tabs/terminals-admin-tabs.component';
import { MatInputModule } from '@angular/material/input';
import { BarcodeScanner } from '@ionic-native/barcode-scanner/ngx';
import { SwiperModule } from 'swiper/angular';
import { MatSelectModule } from '@angular/material/select';
import { NgSelectModule } from '@ng-select/ng-select';
import { TerminalsAttachTemplateComponent } from './terminals-attach-template/terminals-attach-template.component';
import { App360BillingSharedModule } from '@nutricontrol/app360-billing-shared';
import {App360SharedModule} from "@nutricontrol/app360-shared";

@NgModule({
  declarations: [
    PairTerminalsComponent,
    InfoTerminalsComponent,
    TerminalsGeneralComponent,
    TerminalsTableComponent,
    TerminalActivationComponent,
    ProgressBarComponent,
    TerminalsEditComponent,
    TerminalsAdminTabsComponent,
    TerminalsAttachTemplateComponent,
  ],
  exports: [
    PairTerminalsComponent,
    TerminalsTableComponent,
    TerminalsAttachTemplateComponent,
  ],
  imports: [
    CommonModule,
    TextAvatarModule,
    MatTabsModule,
    ReactiveFormsModule,
    IonicModule,
    AppCommonsModule,
    MatAutocompleteModule,
    PermissionsModule,
    NgxPaginationModule,
    MatProgressBarModule,
    ProfileModule,
    RouterModule,
    MatProgressSpinnerModule,
    FormsModule,
    Ng2SearchPipeModule,
    AuthModule,
    MatInputModule,
    SwiperModule,
    MatSelectModule,
    NgSelectModule,
    App360BillingSharedModule,
    App360SharedModule,
  ],
  providers: [
    BarcodeScanner,
    TerminalsEditComponent,
    TerminalActivationComponent,
  ],
})
export class TerminalsModule {}
