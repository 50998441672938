import { SensorDataModel } from '../../irrigation/irrigation.model';
import {
  Component,
  Input,
  OnInit,
  ViewChild,
  Output,
  EventEmitter,
} from '@angular/core';
import { TranslationsLibService } from '@nutricontrol/app360-shared';
import { Router } from '@angular/router';
import { IonModal } from '@ionic/angular';
import { FormBuilder, Validators } from '@angular/forms';
import { HelpersLibService } from '../../../services/libraries/helpers-lib.service';
import { IrrigationService } from '../../irrigation/irrigation.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-sensors-list',
  templateUrl: './sensors-list.component.html',
  styleUrls: ['./sensors-list.component.scss'],
})
export class SensorsListComponent implements OnInit {
  @Input() type: 'irrigation' | 'climate' | 'configurable';

  @ViewChild(IonModal) modal: IonModal;

  @Output() isSensorsWithoutDataEventEmitter = new EventEmitter<boolean>();

  terminal_vid: string;
  sensors: SensorDataModel[] = [];
  sensorSelected: SensorDataModel;
  loading = true;
  selectedTab: number;
  redinSensorsIds = [15, 16, 17, 18, 19];
  psicroSensorsIds = [10, 11, 12, 13, 14];
  waterSystemSensors = [6];
  private fb: FormBuilder = new FormBuilder();
  sensorForm = this.fb.group({
    name: ['', [Validators.required]],
    maxView: [0, [Validators.pattern('^(-?)+[0-9]+(.[0-9]*)?$')]],
    minView: [0, [Validators.pattern('^(-?)+[0-9]+(.[0-9]*)?$')]],
    unit: ['', []],
    visible: [true, []],
  });

  constructor(
    private helpersLib: HelpersLibService,
    private irrigationService: IrrigationService,
    private router: Router,
    public translationsLib: TranslationsLibService
  ) {}

  ngOnInit(): void {
    const [, , vid] = this.router.url.split('/');
    this.terminal_vid = vid;
    this.getSensors();
    this.helpersLib.setTitle(
      this.translationsLib.get('sensors_title'),
      this.translationsLib.get('sensors_title'),
      null
    );
  }

  getSensors() {
    this.loading = true;
    this.irrigationService
      .getSensors(this.terminal_vid)
      .subscribe((sensors) => {
        if (sensors.length === 0)
          this.isSensorsWithoutDataEventEmitter.emit(true);
        this.sensors = sensors;
        this.loading = false;
      });
  }

  isAnalogic(sensor: SensorDataModel) {
    return Number(sensor.physicalType) === 1;
  }

  get analogicSensors() {
    return this.sensors.filter((sensor) => this.isAnalogic(sensor));
  }

  isDigital(sensor: SensorDataModel) {
    return Number(sensor.physicalType) === 2;
  }

  get digitalSensors() {
    return this.sensors.filter((sensor) => this.isDigital(sensor));
  }

  get watersystemSensors() {
    return this.sensors.filter((sensor) =>
      this.waterSystemSensors.includes(Number(sensor.physicalType))
    );
  }

  get redinSensors() {
    return this.sensors.filter((sensor) =>
      this.redinSensorsIds.includes(Number(sensor.physicalType))
    );
  }

  get psicroSensors() {
    return this.sensors.filter((sensor) =>
      this.psicroSensorsIds.includes(Number(sensor.physicalType))
    );
  }

  get otherSensors() {
    return this.sensors.filter((sensor) => {
      const physicalType = Number(sensor.physicalType);
      return !(
        this.waterSystemSensors.includes(physicalType) ||
        this.redinSensorsIds.includes(physicalType) ||
        this.psicroSensorsIds.includes(physicalType)
      );
    });
  }

  getExpansions(sensors: SensorDataModel[]) {
    return Array.from(new Set(sensors.map((item) => Number(item.nexp))));
  }

  editSensor(sensor: SensorDataModel) {
    this.sensorSelected = sensor;
    this.sensorForm.setValue({
      name: sensor.name,
      maxView: Math.round(sensor.maxView * 100) / 100,
      minView: Math.round(sensor.minView * 100) / 100,
      unit: sensor.unit,
      visible: sensor.visible,
    });
    this.modal.present();
  }

  saveSensor() {
    this.loading = true;
    const sensorToSave = { ...this.sensorSelected, ...this.sensorForm.value };
    this.irrigationService
      .putSensor(this.terminal_vid, sensorToSave as SensorDataModel)
      .subscribe(
        (_) => {
          this.getSensors();
          Swal.fire({
            title: this.translationsLib.get('sensors_edit_ok'),
            icon: 'success',
          }).then((_) => {
            this.modal.dismiss();
            this.loading = false;
          });
        },
        (error) => {
          Swal.fire({
            title: this.translationsLib.get('sensor_edit_ko'),
            icon: 'error',
          });
        }
      );
  }
}
