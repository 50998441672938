import { Injectable } from '@angular/core';
import { RestApiService } from '../../services/api/rest-api.service';
import { Observable } from 'rxjs';
import {
  ConfigurableEquipmentModel,
  ConfigurableProgramModel,
  EquipmentSubmitModel,
} from './configurable.model';
import { TerminalDataModel } from '@nutricontrol/app360-shared';
import { WeatherCalculateET0Request } from 'src/app/weather/weather.model';

@Injectable({
  providedIn: 'root',
})
export class ConfigurableService {
  private restApiService: RestApiService;

  constructor(restApiService: RestApiService) {
    this.restApiService = restApiService;
  }

  public getEquipment(
    terminalVid: string
  ): Observable<ConfigurableEquipmentModel> {
    return new Observable((observer) => {
      this.restApiService.getConfigurableEquipment(terminalVid).subscribe(
        (response) => {
          observer.next(response);
        },
        (error) => {
          observer.error(error);
        }
      );
    });
  }

  public getTerminal(terminalVid: string): Observable<TerminalDataModel> {
    return new Observable((observer) => {
      this.restApiService.getTerminal(terminalVid).subscribe(
        (response) => {
          observer.next(response);
        },
        (error) => {
          observer.error(error);
        }
      );
    });
  }

  public getPrograms(
    terminalVid: string,
    program?: number,
    active?: boolean
  ): Observable<ConfigurableProgramModel[]> {
    return new Observable((observer) => {
      this.restApiService
        .getConfigurablePrograms(terminalVid, program, active)
        .subscribe(
          (response) => {
            observer.next(response);
          },
          (error) => {
            observer.error(error);
          }
        );
    });
  }

  public postPrograms(
    terminalVid: string,
    programs: ConfigurableProgramModel[]
  ): Observable<ConfigurableProgramModel> {
    return new Observable((observer) => {
      this.restApiService
        .postConfigurablePrograms(terminalVid, programs)
        .subscribe(
          (response) => {
            observer.next(response);
          },
          (error) => {
            observer.error(error);
          }
        );
    });
  }

  public postManualActionsProgram(
    terminalVid: string,
    payload: object
  ): Observable<ConfigurableProgramModel> {
    return new Observable((observer) => {
      this.restApiService
        .postManualActionsConfigurableProgram(terminalVid, payload)
        .subscribe(
          (response) => observer.next(response),
          (error) => observer.error(error)
        );
    });
  }

  public postConfigurableTerminal(
    terminalVid: string,
    info: EquipmentSubmitModel
  ): Observable<WeatherCalculateET0Request> {
    return new Observable((observer) => {
      this.restApiService
        .postIrrigatrionClimateEquipment(terminalVid, info)
        .subscribe(
          (response) => {
            observer.next(response);
          },
          (error) => {
            observer.error(error);
          }
        );
    });
  }
}
