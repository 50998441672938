<div class="card">
  <div class="card-header">
    <h2 class="text-header-primary m-0">{{ translationsLib.get('permission_permissions') }}</h2>
  </div>
  <div class="card-body px-1 px-lg-4 pb-0">
    <mat-tab-group mat-align-tabs="start" [selectedIndex]="0">
      <mat-tab label="{{ this.translationsLib.get('field_fields') }}">
        <br>
        <lib-loader *ngIf="loadedFields !== true"></lib-loader>

        <ng-container *ngIf="loadedFields === true">

          <ng-container *ngFor="let field of fields">
            <mat-expansion-panel *ngIf="field.is_demo !== true"
                                 (opened)="panelOpenState = true; panelOpenVid = field.vid;"
                                 (closed)="panelOpenState = false; panelOpenVid = '';"
                                 class="px-2 px-lg-4 py-4 mb-4 device-field-mat-tab">
              <mat-expansion-panel-header class="px-1 px-sm-4">
                <mat-panel-title class="d-block">
                  <div class="px-1 px-sm-3">
                    <app-fields-header [field]="field" [space]="false"></app-fields-header>
                  </div>
                </mat-panel-title>
              </mat-expansion-panel-header>
              <app-field-permissions

                [vid]="field.vid"
              ></app-field-permissions> <!-- *ngIf="panelOpenVid === field.vid && field.authed_user_can_admin === true" -->
              <section
                class="container-fluid m-3"

              > <!-- *ngIf="field.authed_user_can_admin !== true" -->
                <div class="row">
                  <div class="col-12 text-start">
                    <b>
                      {{ translationsLib.get('permission_no_admin_permission') }}
                    </b>
                  </div>
                </div>
              </section>
            </mat-expansion-panel>
          </ng-container>

          <div class="alert alert-info" *ngIf="validFieldsCount === 0">
            <ion-icon name="information-circle-outline" class="me-1" style="position: relative;top: 2px;"></ion-icon>
            <b>{{ translationsLib.get('field_no_fields') }}</b>
          </div>

        </ng-container>

      </mat-tab>
      <mat-tab label="{{ this.translationsLib.get('terminal_terminals') }}">
        <br>
        <lib-loader *ngIf="loadedTerminals !== true"></lib-loader>

        <ng-container *ngIf="loadedTerminals === true">

          <ng-container *ngFor="let terminal of terminals">

            <mat-expansion-panel
              (opened)="panelOpenState = true; panelOpenVid = terminal.vid;"
              (closed)="panelOpenState = false; panelOpenVid = '';"
              class="px-1 px-lg-4 mb-4 device-header-mat-tab"
              *ngIf="terminal.is_demo !== true"
            >
              <mat-expansion-panel-header>
                <lib-terminal-header
                  [equipment]="terminal"
                  [showPrettyTypeName]="false"
                ></lib-terminal-header>
              </mat-expansion-panel-header>
              <app-terminal-permissions
                [vid]="terminal.vid"
                *ngIf="panelOpenVid === terminal.vid && terminal.authed_user_can_admin === true"
              ></app-terminal-permissions>
              <section
                class="container-fluid m-3"
                *ngIf="terminal.authed_user_can_admin !== true"
              >
                <div class="row">
                  <div class="col-12 text-start">
                    <b>
                      {{ translationsLib.get('permission_no_admin_permission') }}
                    </b>
                  </div>
                </div>
              </section>
            </mat-expansion-panel>
          </ng-container>

          <div class="alert alert-info" *ngIf="validTerminalsCount === 0">
            <ion-icon name="information-circle-outline" class="me-1" style="position: relative;top: 2px;"></ion-icon>
            <b>{{ translationsLib.get('terminal_no_terminals') }}</b>
          </div>

        </ng-container>

      </mat-tab>
      <mat-tab *ngIf="showDemoField" [label]="translationsLib.get('demo_field_title')">
        <br>
        <app-demo-field-card></app-demo-field-card>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>
