export const environment = {
  production: true,
  backend: 'https://api-gw.ucrop.net',
  language: 'en',
  intervalCheckConnectionTimeout: 10000,
  intervalDefaultTimeout: 60000,
  google_maps_api: 'AIzaSyCrzZWsKiBzYcMI3PPuENCXBYa1Hfa1Cgo',
  google_maps_api_android: 'AIzaSyCJQl3fML2VigfChPRrooelDgrBrEK5VPM',
  google_maps_api_ios: 'AIzaSyBqFxfwaxKtwFoCfnnrBD2HaaM-_Xy08HU',
  google_maps_static_api: 'AIzaSyAeVz5BLk8oawep9Hmm6XE28O9enpJduYc',
  mapbox_token:
    'sk.eyJ1IjoibnV0cmljb250cm9sIiwiYSI6ImNtNGk4cnUybDBpYnkybHM4OXRndnYya2wifQ.5ilRaOKgkkBK0DBXKySkgA',
  frontend: 'https://app.ucrop.net/',
  my_ucrop: 'https://my.ucrop.net/',
  timeStamp: '16/01/2025 13:03:05',
  items_per_page: 10,
};
