import { Component, OnInit } from '@angular/core';
import { TerminalsService } from '../terminals.service';
import { Router } from '@angular/router';
import {
  TranslationsLibService,
  TerminalDataModel,
} from '@nutricontrol/app360-shared';
import { TerminalsLibService } from '../../services/libraries/terminals-lib.service';
import { SemanticVersioningService } from '../../services/libraries/semantic-versioning.service';
import * as moment from 'moment';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-terminals-table',
  templateUrl: './terminals-table.component.html',
  styleUrls: ['./terminals-table.component.scss'],
})
export class TerminalsTableComponent implements OnInit {
  term = '';
  terminals: TerminalDataModel[] = [];
  originalTerminals: TerminalDataModel[] = [];
  itemsPerPage: number = environment.items_per_page;
  uniquePrettyTypeNames: string[] = [];
  uniqueFields: string[] = [];
  selectedTypeNames: string[] = [];
  selectedFields: string[] = [];
  p = 1;
  intervalChecker;
  insideOfInterval = false;
  private terminalsApiInfo = [];
  private setInfo = [];
  constructor(
    private terminalsService: TerminalsService,
    public router: Router,
    public translationsLib: TranslationsLibService,
    public terminalsLib: TerminalsLibService,
    public semanticVersioningService: SemanticVersioningService
  ) {}

  ngOnInit() {
    this.getTerminals();
  }

  getTerminals() {
    this.terminalsService.getTerminals().subscribe((res) => {
      this.originalTerminals = res;

      // Get the different models
      this.uniquePrettyTypeNames = this.getUniquePrettyTypesNames();

      // Get the different farms
      this.uniqueFields = this.getUniqueFields();

      this.setTerminals();
    });
  }

  checkChanges() {
    let largoNuevo = 1;
    let oldTerm;
    if (
      (this.term.length >= 1 || this.term.length === largoNuevo) &&
      !this.insideOfInterval
    ) {
      oldTerm = this.term;
      this.insideOfInterval = true;
      this.intervalChecker = setInterval(() => {
        if (oldTerm !== this.term) {
          oldTerm = this.term;
        } else {
          this.insideOfInterval = false;
          clearInterval(this.intervalChecker);
          largoNuevo = this.term.length;
          this.filterArray();
        }
      }, 750);
    } else {
      if (this.term.length === 0) {
        this.insideOfInterval = false;
        clearInterval(this.intervalChecker);
        this.filterArray();
      }
    }
  }

  filterArray() {
    if (
      this.term !== undefined &&
      this.term !== '' &&
      this.terminals.length > 0
    ) {
      this.terminalsApiInfo = [];
      this.terminals = this.terminals.filter(
        (terminal) =>
          terminal.name.toLowerCase().includes(this.term.toLowerCase()) ||
          terminal.name.toLowerCase() === this.term.toLowerCase() ||
          terminal.vid.includes(this.term) ||
          terminal.vid === this.term
      );
      this.terminals.forEach((terminal) => {
        this.terminalsApiInfo.push(terminal.vid);
      });
      this.getTerminalsInfo();
    } else {
      this.setTerminals();
    }
  }

  setTerminals() {
    this.terminals = this.originalTerminals;
    this.terminals.forEach((terminal) => {
      if (terminal.pretty_type_name === '-') {
        terminal.pretty_type_name =
          this.translationsLib.get('terminal_unknown');
      }
      this.terminalsApiInfo.push(terminal.vid);
    });
    this.getTerminalsInfo();
  }

  getTerminalsInfo() {
    const terminalsOnPage = [];
    for (
      let i = (this.p - 1) * this.itemsPerPage;
      i < this.p * this.itemsPerPage;
      i++
    ) {
      if (this.terminalsApiInfo[i] != null) {
        terminalsOnPage.push(this.terminalsApiInfo[i]);
      }
    }
    const data = {
      method: 'get',
      terminals_vid: terminalsOnPage,
    };
    this.terminalsService.getTerminalsApiInfo(data).subscribe((res) => {
      this.setInfo = res;
      this.setInfoInTable();
    });
  }

  setInfoInTable() {
    if (Array.isArray(this.setInfo)) {
      this.setInfo.forEach((info) => {
        if (!('error' in info)) {
          const space = document.getElementById(
            info.serialNumber + '-status'
          ) as HTMLElement;
          const spaceHour = document.getElementById(
            info.serialNumber + '-last-conexion'
          ) as HTMLElement;
          if (space !== null) {
            if (info.connected === true || info.connected === '1') {
              space.innerHTML =
                '<span class="badge bg-success">' +
                this.translationsLib.get('terminal_connected') +
                '</span>';
            }
            if (info.connected === false || info.connected === '0') {
              space.innerHTML =
                '<span class="badge bg-danger">' +
                this.translationsLib.get('terminal_disconnected') +
                '</span>';
            }
          }
          if (spaceHour !== null) {
            spaceHour.innerHTML = moment(info.lastMeasure).format(
              'DD-MM-YYYY HH:mm:ss'
            );
          }
        }
      });
    }
  }

  getFieldName(terminal) {
    if (terminal.field !== null) {
      return terminal.field.name;
    } else {
      return '-';
    }
  }

  clickTable(terminal: TerminalDataModel) {
    if (terminal.type !== 0) {
      this.router.navigate([
        this.terminalsLib.generateRoute(terminal.vid, terminal.type),
      ]);
    } else {
      this.router.navigate([
        this.terminalsLib.generateRoute(terminal.vid, terminal.type),
        {
          vid: terminal.vid,
        },
      ]);
    }
  }

  getUniquePrettyTypesNames(): string[] {
    return this.originalTerminals
      .map((terminal) => terminal.pretty_type_name)
      .filter(
        (value, index, self) => value !== '-' && self.indexOf(value) === index
      )
      .sort();
  }

  onSelectionModelChange(selection: string[]) {
    this.selectedTypeNames = selection;

    if (this.selectedTypeNames.length !== 0) {
      // Reset the other filters
      this.term = '';
      this.selectedFields = [];

      // Filter terminals whose 'pretty_type_name' field is in selectedTypeNames
      this.terminals = this.originalTerminals.filter((terminal) =>
        this.selectedTypeNames.includes(terminal.pretty_type_name)
      );
    } else {
      this.terminals = this.originalTerminals;
    }

    this.terminalsApiInfo = [];
    this.terminals.forEach((terminal) => {
      this.terminalsApiInfo.push(terminal.vid);
    });

    this.getTerminalsInfo();
  }

  getUniqueFields(): string[] {
    return this.originalTerminals
      .map((terminal) => this.getFieldName(terminal))
      .filter(
        (value, index, self) => value !== '-' && self.indexOf(value) === index
      )
      .sort();
  }

  onSelectionFieldChange(selection: string[]) {
    this.selectedFields = selection;

    if (this.selectedFields.length !== 0) {
      // Reset the other filters
      this.term = '';
      this.selectedTypeNames = [];

      // Filter terminals whose 'terminal' field is in the selectedFields
      this.terminals = this.originalTerminals.filter((terminal) =>
        this.selectedFields.includes(this.getFieldName(terminal))
      );
    } else {
      this.terminals = this.originalTerminals;
    }

    this.terminalsApiInfo = [];
    this.terminals.forEach((terminal) => {
      this.terminalsApiInfo.push(terminal.vid);
    });

    this.getTerminalsInfo();
  }
}
