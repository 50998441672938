<div
  class="my-4 d-flex justify-content-center"
  *ngIf="loading; else notLoading"
>
  <div class="spinner-border text-warning" role="status">
    <span class="visually-hidden">Loading...</span>
  </div>
</div>

<ng-template #notLoading>
  <mat-tab-group [(selectedIndex)]="selectedTab">
    <mat-tab *ngIf="analogicSensors.length > 0">
      <ng-template mat-tab-label>
        <span [class]="'badge d-inline-block py-2 px-3 bg-analogic'"></span>
        <span class="mx-3">{{
          translationsLib.get('sensors_analogic').toUpperCase()
        }}</span>
      </ng-template>
      <ng-container>
        <app-sensors-list-item
          type="analogic"
          [sensors]="analogicSensors"
          (editSensor)="editSensor($event)"
        ></app-sensors-list-item>
      </ng-container>
    </mat-tab>

    <mat-tab *ngIf="digitalSensors.length > 0">
      <ng-template mat-tab-label>
        <span [class]="'badge d-inline-block py-2 px-3 bg-digital'"></span>
        <span class="mx-3">{{
          translationsLib.get('sensors_digital').toUpperCase()
        }}</span>
      </ng-template>
      <app-sensors-list-item
        type="digital"
        [sensors]="digitalSensors"
        (editSensor)="editSensor($event)"
      ></app-sensors-list-item>
    </mat-tab>

    <mat-tab *ngIf="watersystemSensors.length > 0">
      <ng-template mat-tab-label>
        <span [class]="'badge d-inline-block py-2 px-3 bg-watersystem'"></span>
        <span class="mx-3">{{
          translationsLib.get('sensors_watersystem').toUpperCase()
        }}</span>
      </ng-template>
      <app-sensors-list-item
        type="watersystem"
        [sensors]="watersystemSensors"
        (editSensor)="editSensor($event)"
      ></app-sensors-list-item>
    </mat-tab>

    <mat-tab *ngIf="redinSensors.length > 0">
      <ng-template mat-tab-label>
        <span [class]="'badge d-inline-block py-2 px-3 bg-redin'"></span>
        <span class="mx-3">{{
          translationsLib.get('sensors_redin').toUpperCase()
        }}</span>
      </ng-template>
      <app-sensors-list-item
        type="redin"
        [sensors]="redinSensors"
        (editSensor)="editSensor($event)"
      ></app-sensors-list-item>
    </mat-tab>

    <mat-tab *ngIf="psicroSensors.length > 0">
      <ng-template mat-tab-label>
        <span [class]="'badge d-inline-block py-2 px-3 bg-psicrometer'"></span>
        <span class="mx-3">{{
          translationsLib.get('sensors_psicrometer').toUpperCase()
        }}</span>
      </ng-template>
      <app-sensors-list-item
        type="psicrometer"
        [sensors]="psicroSensors"
        (editSensor)="editSensor($event)"
      ></app-sensors-list-item>
    </mat-tab>

    <mat-tab *ngIf="otherSensors.length > 0">
      <ng-template mat-tab-label>
        <span [class]="'badge d-inline-block py-2 px-3 bg-other'"></span>
        <span class="mx-3">{{
          translationsLib.get('general').toUpperCase()
          }}</span>
      </ng-template>
      <app-sensors-list-item
        type="other"
        [sensors]="otherSensors"
        (editSensor)="editSensor($event)"
      ></app-sensors-list-item>
    </mat-tab>
  </mat-tab-group>

  <ion-modal [breakpoints]="[1]" #modal id="modal" class="auto-height">
    <ng-template>
      <div class="inner-content pb-0">
        <ion-toolbar>
          <ion-title>{{
            translationsLib.get('sensors_edit_sensor')
          }}</ion-title>
          <ion-buttons slot="end">
            <ion-button color="light" (click)="modal.dismiss()">
              <ion-icon
                [name]="'CLEAR' | sensorIcon : '' : '' : true"
              ></ion-icon>
            </ion-button>
          </ion-buttons>
        </ion-toolbar>
        <div class="container p-4">
          <form [formGroup]="sensorForm">
            <div class="row form-group">
              <label class="col-4 col-form-label">{{
                translationsLib.get('sensors_name')
              }}</label>
              <div class="col-8">
                <input
                  class="form-control"
                  type="text"
                  formControlName="name"
                />
              </div>
            </div>

            <div class="row form-group">
              <label class="col-4 col-form-label">{{
                translationsLib.get('sensors_min_view')
              }}</label>
              <div class="col-8">
                <input
                  class="form-control"
                  type="number"
                  formControlName="minView"
                />
              </div>
            </div>

            <div class="row form-group">
              <label class="col-4 col-form-label">{{
                translationsLib.get('sensors_max_view')
              }}</label>
              <div class="col-8">
                <input
                  class="form-control"
                  type="number"
                  formControlName="maxView"
                />
              </div>
            </div>

            <div class="row form-group">
              <label class="col-4 col-form-label">{{
                translationsLib.get('sensors_unit')
              }}</label>
              <div class="col-8">
                <input
                  class="form-control"
                  type="text"
                  formControlName="unit"
                />
              </div>
            </div>

            <div class="row form-group">
              <label class="col-4 col-form-label">{{
                translationsLib.get('sensors_visible')
              }}</label>
              <div class="col-8">
                <div class="form-check my-2">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    formControlName="visible"
                  />
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-12">
                <h3>{{ translationsLib.get('sensors_properties') }}</h3>
                <ul class="list-group">
                  <li class="list-group-item">
                    <div class="row">
                      <div class="col-4">
                        {{ translationsLib.get('sensors_expansion') }}
                      </div>
                      <div class="col-8">{{ sensorSelected.nexp | toHex }}</div>
                    </div>
                  </li>
                  <li class="list-group-item">
                    <div class="row">
                      <div class="col-4">
                        {{ translationsLib.get('sensors_nent') }}
                      </div>
                      <div class="col-8">{{ +sensorSelected.nent + 1 }}</div>
                    </div>
                  </li>
                  <li class="list-group-item">
                    <div class="row">
                      <div class="col-4">
                        {{ translationsLib.get('sensors_type') }}
                      </div>
                      <div class="col-8">
                        {{ sensorSelected.type }}
                        {{
                          sensorSelected.typeName &&
                            ' - ' + translationsLib.get(sensorSelected.typeName)
                        }}
                      </div>
                    </div>
                  </li>
                  <li class="list-group-item">
                    <div class="row">
                      <div class="col-4">
                        {{ translationsLib.get('sensors_min') }}
                      </div>
                      <div class="col-8">{{ sensorSelected.min }}</div>
                    </div>
                  </li>
                  <li class="list-group-item">
                    <div class="row">
                      <div class="col-4">
                        {{ translationsLib.get('sensors_max') }}
                      </div>
                      <div class="col-8">{{ sensorSelected.max }}</div>
                    </div>
                  </li>
                  <li
                    class="list-group-item"
                    *ngIf="
                      isAnalogic(sensorSelected) || isDigital(sensorSelected)
                    "
                  >
                    <div class="row">
                      <div class="col-4" *ngIf="isAnalogic(sensorSelected)">
                        {{ translationsLib.get('sensors_vmin') }}
                      </div>
                      <div class="col-4" *ngIf="isDigital(sensorSelected)">
                        {{ translationsLib.get('sensors_fmin') }}
                      </div>
                      <div class="col-8">{{ sensorSelected.VMin }}</div>
                    </div>
                  </li>
                  <li
                    class="list-group-item"
                    *ngIf="
                      isAnalogic(sensorSelected) || isDigital(sensorSelected)
                    "
                  >
                    <div class="row">
                      <div class="col-4" *ngIf="isAnalogic(sensorSelected)">
                        {{ translationsLib.get('sensors_vmax') }}
                      </div>
                      <div class="col-4" *ngIf="isDigital(sensorSelected)">
                        {{ translationsLib.get('sensors_fmax') }}
                      </div>
                      <div class="col-8">{{ sensorSelected.VMax }}</div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>

            <div class="row mt-3">
              <div class="col-12 text-end">
                <button
                  type="button"
                  class="btn btn-primary"
                  (click)="saveSensor()"
                >
                  {{ translationsLib.get('save') }}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </ng-template>
  </ion-modal>
</ng-template>
