import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ClimateInstallerService {
  constructor() {}

  checkLocalStorageForCurrentTabIndex() {
    if (localStorage.getItem('currentClimateTabIndex')) {
      localStorage.setItem('currentClimateTabIndex', '0');
    }
  }

  setLocalStorageCurrentTabIndex(tabIndex: string) {
    localStorage.setItem('currentClimateTabIndex', tabIndex);
  }

  getLocalStorageCurrentTabIndex(): string {
    return localStorage.getItem('currentClimateTabIndex');
  }

  removeInstallerOptionSelected() {
    if (localStorage.getItem('installerOption')) {
      localStorage.removeItem('installerOption');
    }
  }

  setInstallerOptionSelected(installerOption: string) {
    localStorage.setItem('installerOption', installerOption);
  }

  getInstallerOptionSelected(): string {
    return localStorage.getItem('installerOption');
  }
}
