import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { WeatherService } from '../weather.service';
import {
  TranslationsLibService,
  TerminalDataModel,
  FieldDataModel,
} from '@nutricontrol/app360-shared';
import { WeatherLibService } from '../../services/libraries/weather-lib.service';
import { FieldsService } from '../../fields/fields.service';
import { HelpersLibService } from '../../services/libraries/helpers-lib.service';
import {
  WeatherCalculateET0Request,
  WeatherForecastDataModel,
  WeatherHistoricDataModel,
  WeatherPredictionDataModel,
  WeatherStationModel,
} from '../weather.model';
import { AppCommonsService } from '../../commons/app-commons.service';
import * as moment from 'moment';
import { MeteogramLargeComponent } from '../meteogram/meteogram-large/meteogram-large.component';
import { MeteogramLargeMobileComponent } from '../meteogram/meteogram-large-mobile/meteogram-large-mobile.component';

@Component({
  selector: 'app-weather-screen',
  templateUrl: './weather-screen.component.html',
  styleUrls: ['./weather-screen.component.scss'],
})
export class WeatherScreenComponent implements OnInit {
  vid;
  weather: WeatherPredictionDataModel;
  forecast: WeatherForecastDataModel;
  historic: WeatherHistoricDataModel;
  today: any = [];
  weather_station: WeatherStationModel = null;
  main_prediction_min = 0;
  main_prediction_max = 60;
  predictionTimezoneDifferenceUTC;
  breadcrumbs;
  private fieldName;
  windDirection;
  hourlyForecast: WeatherPredictionDataModel[] = [];
  forecastLoaded = false;
  weatherLoaded = false;
  et0Loaded = false;
  selectedPrediction = null;
  moment: string;
  field: FieldDataModel;
  calculateET0Request: WeatherCalculateET0Request;
  et0Forecast: any;

  @ViewChild(MeteogramLargeComponent, { static: false })
  meteogramLargeComponent: MeteogramLargeComponent;
  @ViewChild(MeteogramLargeMobileComponent, { static: false })
  meteogramLargeMobileComponent: MeteogramLargeMobileComponent;

  constructor(
    private route: ActivatedRoute,
    private weatherService: WeatherService,
    public translationsLib: TranslationsLibService,
    private helpersLib: HelpersLibService,
    public weatherLib: WeatherLibService,
    private fieldsService: FieldsService,
    private commonService: AppCommonsService
  ) {}

  ngOnInit() {
    this.vid = this.route.snapshot.paramMap.get('vid');
    this.helpersLib.setTitle(
      this.translationsLib.get('meteo_prediction'),
      this.translationsLib.get('meteo_prediction'),
      null
    );
    this.helpersLib.sendEventPageView('predicciones');
    this.getWeatherCurrent();
    this.getWeatherForecastByField();
    this.getHistoricWeather();
    this.getField();
  }

  getWeatherCurrent() {
    this.weatherService.getWeatherCurrentByField(this.vid).subscribe((res) => {
      this.weather = res;
      this.windDirection = this.translationsLib.get(
        this.weatherLib.getWindDirection(res.wind_deg)
      );
      if (res.weather_station !== null) {
        this.weather_station = res.weather_station;
      }
      if (!res.prediction_time_zone_is_utc) {
        this.predictionTimezoneDifferenceUTC =
          res.prediction_time_zone_difference_to_utc;
      }
      this.moment = res.weather_icon[3];

      const background = document.getElementById('wrap');
      background.setAttribute(
        'style',
        '--background: url(' +
          this.weatherLib.getBackgroundImage(
            res.weather_id,
            res.weather_icon[3]
          ) +
          ') no-repeat center center / cover'
      );

      this.getEt0Forecast();

      this.weatherLoaded = true;
    });
  }

  getWeatherForecastByField() {
    this.weatherService.getForecast(this.vid).subscribe((res) => {
      this.forecast = res;

      // Hourly forecast
      for (let i = 0; this.forecast.predictions[i].sublevel.length > 0; i++) {
        this.hourlyForecast.push(...this.forecast.predictions[i].sublevel);
      }

      let main_prediction_min = 100;
      let main_prediction_max = 0;
      // eslint-disable-next-line @typescript-eslint/prefer-for-of
      for (let x = 0; x < this.forecast.predictions.length; x++) {
        if (this.forecast.predictions[x].main_temp_min < main_prediction_min) {
          main_prediction_min = this.forecast.predictions[x].main_temp_min;
        }
        if (this.forecast.predictions[x].main_temp_max > main_prediction_max) {
          main_prediction_max = this.forecast.predictions[x].main_temp_max;
        }
      }
      this.main_prediction_min =
        main_prediction_min - Math.floor(main_prediction_min * 0.5);
      this.main_prediction_max =
        main_prediction_max + Math.ceil(main_prediction_max * 0.5);

      setTimeout(() => {
        this.forecast.predictions.forEach((pred) => {
          const bar = document.getElementById(
            pred.prediction_date
          ) as HTMLElement;
          if (bar) {
            if (main_prediction_min >= 10 && main_prediction_max <= 30) {
              bar.classList.add('incl-range-10-30');
            } else if (main_prediction_max <= 25) {
              bar.classList.add('incl-range-max-25');
            } else if (main_prediction_max <= 20) {
              bar.classList.add('incl-range-max-20');
            } else if (main_prediction_min >= 20) {
              bar.classList.add('incl-range-min-20');
            }
          }
        });
      }, 200);

      // By default, today will be selected
      this.selectedPrediction = this.forecast.predictions[0];

      this.getEt0Forecast();

      this.forecastLoaded = true;
    });
  }

  getHistoricWeather() {
    const todayDate = moment(new Date()).format('YYYY-MM-DD');
    this.weatherService
      .getHistoric(this.vid, todayDate, todayDate, true)
      .subscribe((res) => {
        this.historic = res;

        // Only until the current time
        this.historic.results = this.historic.results.slice(
          0,
          new Date().getHours()
        );

        this.getEt0Forecast();
      });
  }

  getHour(predictionDate) {
    const date = new Date(predictionDate);

    // Format the minutes to ensure they have two digits.
    return (
      date.getHours() + ':' + date.getMinutes().toString().padStart(2, '0')
    );
  }

  getDay(date) {
    const day = new Date(date);
    const today = new Date();
    let realDay;
    if (day.getDay() === today.getDay() && day.getDate() === today.getDate()) {
      realDay = 8;
    } else {
      realDay = day.getDay();
    }
    return realDay;
  }

  getMoment(prediction) {
    const predictionDate = new Date(prediction.prediction_date);
    if (predictionDate.getHours() < 18 && predictionDate.getHours() > 6) {
      return 'd';
    } else {
      return prediction.weather_icon[3];
    }
  }

  getDate(date) {
    const dateFormat = new Date(date);
    let month;
    if (dateFormat.getMonth() + 1 < 10) {
      month = '0' + (dateFormat.getMonth() + 1);
    } else {
      month = dateFormat.getMonth() + 1;
    }
    return dateFormat.getDate() + '/' + month;
  }

  getField() {
    this.fieldsService.getField(this.vid).subscribe(async (response) => {
      this.field = response;
      this.fieldName = response.name;
      this.breadcrumbs = [
        {
          text: this.fieldName,
          disabled: false,
          to: ['/field', { vid: this.vid }],
          active: false,
        },
        {
          text: this.translationsLib.get('meteo_pred'),
          disabled: false,
          to: ' ',
          active: true,
        },
      ];

      this.getEt0Forecast();
    });
  }

  getFormatPrecip(precip: number) {
    if (Number(precip.toFixed(1)) === 0.0) {
      return 0;
    } else {
      return precip.toFixed(1);
    }
  }

  selectPrediction(prediction: any) {
    this.selectedPrediction = prediction;
  }

  getEt0Forecast() {
    if (
      this.field !== undefined &&
      this.weather !== undefined &&
      this.historic !== undefined &&
      this.forecast !== undefined
    ) {
      this.calculateET0Request = {
        lat: 0,
        lon: 0,
        info: [],
      };

      const firstValidTerminal = this.field.terminals_info.find(
        (terminal: TerminalDataModel) =>
          terminal.latitude !== null && terminal.longitude !== null
      );

      let validVid;

      // If no terminal has a location, we use the coordinates of the first polygon of the field
      if (firstValidTerminal) {
        this.calculateET0Request.lat = firstValidTerminal.latitude;
        this.calculateET0Request.lon = firstValidTerminal.longitude;
        validVid = firstValidTerminal.vid;
      } else {
        this.calculateET0Request.lat = this.field.coordinates[0].latitude;
        this.calculateET0Request.lon = this.field.coordinates[0].longitude;
        validVid =
          this.field.terminals_info.length > 0
            ? this.field.terminals_info[0].vid
            : null;
      }

      if (validVid !== null) {
        // Today before the current time
        const items = this.historic.results;

        // Take the current weather by modifying the time
        const currentHour = { ...this.weather };
        currentHour.prediction_date =
          currentHour.prediction_date.slice(0, 11) +
          new Date().getHours().toString().padStart(2, '0') +
          ':00:00';

        items.push(currentHour);

        // Fill the array with the 24 - n missing values
        items.push(
          ...this.forecast.predictions[0].sublevel.slice(-(24 - items.length))
        );

        // Next days with complete forecasts
        for (
          let i = 1;
          this.forecast.predictions[i].sublevel.length === 24;
          i++
        ) {
          items.push(...this.forecast.predictions[i].sublevel);
        }

        items.forEach((item) => {
          this.calculateET0Request.info.push({
            date: item.prediction_date,
            main_humidity: item.main_humidity,
            main_pressure: item.main_pressure,
            main_temp: item.main_temp,
            solar_rad: item.solar_rad,
            wind_speed: item.wind_speed,
            wind_deg: item.wind_deg,
          });
        });

        this.commonService
          .calculateET0ForecastByTerminalVid(validVid, this.calculateET0Request)
          .subscribe((res) => {
            this.et0Forecast = Object.keys(res)
              .filter((key) => !isNaN(Number(key)))
              .map((key) => res[key]);

            this.et0Forecast.forEach((et0) => {
              et0.ET0 = Number(et0.ET0.toFixed(2));
            });

            if (this.et0Forecast.length > 0) {
              this.meteogramLargeComponent.loadEt0Serie(this.et0Forecast);
              this.meteogramLargeMobileComponent.loadEt0Serie(this.et0Forecast);

              this.et0Loaded = true;
            }
          });
      }
    }
  }
}
