import { Component, OnInit } from '@angular/core';
import {
  CdkDragDrop,
  copyArrayItem,
  moveItemInArray,
  transferArrayItem,
} from '@angular/cdk/drag-drop';
import { AppCommonsService } from '../../commons/app-commons.service';
import { Router } from '@angular/router';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { TranslationsLibService } from '@nutricontrol/app360-shared';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-menu-config',
  templateUrl: './menu-config.component.html',
  styleUrls: ['./menu-config.component.scss'],
})
export class MenuConfigComponent implements OnInit {
  private fb: FormBuilder = new FormBuilder();
  // eslint-disable-next-line @typescript-eslint/member-ordering
  upperMenu;
  bottomMenu;
  hiddenMenu;
  homeSelected;
  constructor(
    private commonService: AppCommonsService,
    public translationsLib: TranslationsLibService
  ) {}

  ngOnInit() {
    this.getMenu();
  }

  getMenu() {
    this.commonService.getMenu().subscribe((res) => {
      this.upperMenu = Object.values(res.layout_configuration.upper_menu);
      this.bottomMenu = Object.values(res.layout_configuration.bottom_menu);
      this.hiddenMenu = Object.values(res.layout_configuration.hidden_menu);
    });
  }
  drop(event: CdkDragDrop<string[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    } else {
      if (
        event.previousContainer.data[event.previousIndex]['page'] ===
          'configuration' &&
        event.container.id === 'cdk-drop-list-2'
      ) {
        Swal.fire({
          text: this.translationsLib.get('configuration_cannot_be_hidden'),
          icon: 'error',
          confirmButtonText: this.translationsLib.get('accept'),
        });
      } else {
        transferArrayItem(
          event.previousContainer.data,
          event.container.data,
          event.previousIndex,
          event.currentIndex
        );
      }
    }
  }

  saveMenu() {
    event.preventDefault();
    if (this.homeSelected === undefined) {
      this.homeSelected = this.upperMenu[0].page;
      if (this.upperMenu[0].page.includes('fields')) {
        this.homeSelected = 'fields-general';
      }
    }
    if (
      this.homeSelected.includes('fields') &&
      !this.homeSelected.includes('fields-general')
    ) {
      const splittedhome = this.homeSelected.split(';');
      this.homeSelected = 'field;' + splittedhome[1];
    }

    const home = [
      {
        icon: '',
        page: this.homeSelected,
        items: [],
        title: '',
      },
    ];
    const data = {
      layout_configuration: {
        home,
        upper_menu: this.upperMenu,
        bottom_menu: this.bottomMenu,
        hidden_menu: this.hiddenMenu,
      },
    };

    this.commonService.putMenu(data).subscribe((res) => {
      location.reload();
    });
  }
  saveHome(page) {
    this.homeSelected = page;
  }
}
