<div class="row" *ngIf="loading === true">
  <div class="col-12 text-center">
    <lib-loader></lib-loader>
  </div>
</div>


<div class="row" *ngIf="loading === false">
  <div class="col col-12 p-0">
    <div class="card fields-border">
      <div class="row mx-2 align-items-end">
        <div class="col-md-6">
          <div class="input-group search-container">
            <span class="input-group-text search-icon"><i class="bi bi-search"></i></span>
            <input type="text" class="form-control" placeholder="{{ translationsLib.get('search') }}" [(ngModel)]="term"/>
          </div>
        </div>
        <div class="col-md-6 d-none d-sm-block">
          <ng-select [items]="uniqueCropNames"
                     placeholder="{{ translationsLib.get('field_crop_short') }}"
                     [multiple]="true"
                     [closeOnSelect]="false"
                     [(ngModel)]="selectedCrops"
                     (ngModelChange)="onSelectionCropNameChange($event)"
          ></ng-select>
        </div>
      </div>
    </div>
    <div class="card fields-border">
      <div class="card-body" style="overflow-x: auto">
        <table class="table table-responsive-sm card-table">
          <thead>
          <tr>
            <th class="w-50">
              {{ translationsLib.get('field_name_short') }}
            </th>
            <th class="d-none d-sm-table-cell">
              {{ translationsLib.get('field_crop_short') }}
            </th>
            <th>
              {{ translationsLib.get('field_size') }}
            </th>
            <th>
              {{ translationsLib.get('field_devices_number') }}
            </th>
          </tr>
          </thead>
          <tbody class="list">
          <tr id="{{field.name}}" *ngFor="let field of fields | filterByProperty: 'name': term | paginate: { itemsPerPage: itemsPerPage, currentPage: p }" (click)="router.navigate(['./field',{vid: field.vid}])">
            <td class="w-50" style="color: #224261">
              {{field.name}}
            </td>
            <td class="d-none d-sm-table-cell">
              {{ field | fieldCropsName }}
            </td>
            <td>
              {{formatArea(field.coordinates_area)}} Ha
            </td>
            <td>
              {{field.terminals_info.length}}
            </td>
            <!--
            <td id="{{field.vid}}-status">
              <div class="spinner-border spinner-border-sm" role="status" style="padding:0px;">
              </div>
            </td>
            -->
          </tr>
          </tbody>
        </table>
        <br>
        <pagination-controls (pageChange)="p = $event"
                             [responsive]="true"
                             previousLabel="{{ translationsLib.get('previous') }}"
                             nextLabel="{{ translationsLib.get('next') }}"></pagination-controls>
      </div>
    </div>
  </div>
</div>
