<div class="alert bg-salmon mb-0">
  <div class="row align-items-center">
    <div class="col-auto">
      <img src="assets/img/Icons/icono_info.svg" width="48px" height="48px" />
    </div>
    <div class="col">{{translationsLib.get('field_demo_data_alert')}}</div>
    <div class="col-12 col-sm-3 text-end mt-3 mt-sm-0">
      <button (click)="detachDemoField()" id="unpairDemo" class="btn btn-primary w-100">
        {{translationsLib.get('field_dettach_demo_field')}}
      </button>
    </div>
  </div>
</div>
