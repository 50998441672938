 <section>

  <aside id="main-logo" (click)="goHome()">
    <picture>
      <img src ="./assets/img/ucrop_negative.svg" alt="Home">
    </picture>
  </aside>
  <ion-list id="inbox-list">
    <ion-menu-toggle auto-hide="false" *ngFor="let p of appPages; let i = index">
      <ion-item style="height: 34px" routerDirection="root" [routerLink]="[p.page]" lines="none" detail="false" routerLinkActive="selected" (click)="selectItem('')"  *ngIf="p.items.length === 0 || p.page === 'terminals-general'">
        <ion-icon style="margin-top: -5px;" slot="start" [ios]="p.icon + '-outline'" [md]="p.icon + '-sharp'"></ion-icon>
        <ion-label style="margin-top: 0px;">{{ p.title }}</ion-label>
      </ion-item>
      <mat-expansion-panel *ngIf="p.items.length !== 0 && p.page !== 'terminals-general' " id="desplegable" [disabled]="true" [expanded]="true">
        <mat-expansion-panel-header (click)="router.navigate(['/fields-general'])" routerLinkActive="selected">
        <ion-item style="height: 34px;" routerDirection="root" lines="none" detail="false" routerLinkActive="selected" *ngIf="p.items.length !== 0  " (click)="selectItem('fields')">
          <ion-icon style="margin-top: 0px;" slot="start" [ios]="p.icon + '-outline'" [md]="p.icon + '-sharp'"></ion-icon>
          <ion-label style="margin-top: 0px;">{{ p.title }}</ion-label>
        </ion-item>
        </mat-expansion-panel-header>
        <ng-template matExpansionPanelContent>
        <div *ngIf="p.page === 'fields-general'">
          <ion-item id="{{item.vid}}" routerDirection="root" (click)="selectItem(item)" [routerLink]="['./field',{vid: item.vid}]" *ngFor="let item of p.items" class="ionic-sidebar-items" menuClose lines="none" detail="false">
            <ion-label class="sub-item-menu">
              <ion-icon slot="start" [ios]="'flower-outline'" [md]="'flower-sharp'"></ion-icon>
              <span>{{item.name}}</span>
            </ion-label>
          </ion-item>
        </div>
        </ng-template>
      </mat-expansion-panel>
    </ion-menu-toggle>
  </ion-list>
  <div class="menu-separator" [ngClass]="{'is-native': isNative === true}" *ngIf="instalatorMenu.length > 0"></div>
  <ion-list id="labels-list" *ngIf="instalatorMenu">
    <ion-menu-toggle auto-hide="false" *ngFor="let p of instalatorMenu" >
      <ion-item style="height: 34px" routerDirection="root" [routerLink]="[p.page]" lines="none" detail="false" routerLinkActive="selected" (click)="selectItem('')"  *ngIf="p.items.length === 0 || p.page === 'terminals-general'">
        <ion-icon style="margin-top: 0px;" slot="start" [ios]="p.icon + '-outline'" [md]="p.icon + '-sharp'"></ion-icon>
        <ion-label style="margin-top: 0px;">{{ p.title }}</ion-label>
      </ion-item>
      <mat-expansion-panel *ngIf="p.items.length !== 0 && p.page!== 'terminals-general'" id="desplegable" [disabled]="true" [expanded]="true">
        <mat-expansion-panel-header id="textodesplegable">
          <ion-item style="height: 34px;"routerDirection="root"  lines="none" detail="false" routerLinkActive="selected"  *ngIf="p.items.length !== 0 ">
            <ion-icon style="margin-top: 0px;" slot="start" [ios]="p.icon + '-outline'" [md]="p.icon + '-sharp'"></ion-icon>
            <ion-label style="margin-top: 0px;">{{ p.title }}</ion-label>
          </ion-item>
        </mat-expansion-panel-header>
        <ng-template matExpansionPanelContent>
          <div *ngIf="p.page === 'fields'">
            <ion-item id="{{item.vid}}" [routerLink]="['./field',{vid: item.vid}]" routerLinkActive="'./field;vid='{{+ item.vid}} " (click)="selectItem(item)" *ngFor="let item of p.items" class="ionic-sidebar-items"  menuClose lines="none">
              <ion-label class="sub-item-menu-inferior">{{item.name}}</ion-label>
            </ion-item>
          </div>
        </ng-template>
      </mat-expansion-panel></ion-menu-toggle>
  </ion-list>
 </section>
