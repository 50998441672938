<div class="mb-4 d-flex justify-content-center mt-5" *ngIf="isFetchingData">
  <div class="spinner-border text-warning" role="status">
    <span class="visually-hidden">{{
      this.translationsLib.get('irrigation_loading')
    }}</span>
  </div>
</div>

<!-- Uncomment to activate tour button -->
<!--
<button class="btn btn-success" (click)="startTour()">Start tour here</button>
-->

<app-dirty-message
  [conditions]="layoutConfiguration && !isFetchingData"
  [dirty]="fertilizerForm.value.dirty || isAnyFertilizerFormulaDirtyAfterPost"
></app-dirty-message>

<ng-container
  *ngIf="
    mode === 'simple' &&
    layoutConfiguration &&
    !isFetchingData &&
    !isFertilizerFormulasWithoutData
  "
>
  <form [formGroup]="fertilizerForm" class="simple-form">
    <!-- Nombre de la receta -->
    <div class="card" *ngIf="layoutConfiguration.blocks.name.show">
      <div class="card-body custom-card-body">
        <div class="row align-items-center">
          <div class="col-sm-5 col-md-5 order-1 order-md-1">
            <h6 class="fs-2">
              {{ translationsLib.get('irrigation_formula_name') }}
            </h6>
            <p class="fs-5 fw-lighter">
              {{
                translationsLib.get('irrigation_formula_name_help')
                  | sanitizeText
              }}
            </p>
          </div>
          <div class="col-sm-7 col-md-7 order-3 order-md-2 col-inputs">
            <div class="row first-row">
              <div class="col-sm-4 col-md-4">
                <div class="input-group mb-2">
                  <input
                    formControlName="name"
                    type="text"
                    class="form-control"
                    placeholder="{{
                      translationsLib.get('irrigation_formula_name')
                    }}"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Configuración CE -->
    <div class="card" *ngIf="layoutConfiguration.blocks.ce_configuration.show">
      <div class="card-body custom-card-body">
        <div class="row">
          <div class="col-sm-5 col-md-5 order-1 order-md-1">
            <h6 class="fs-2">
              {{ translationsLib.get('irrigation_ce_configuration') }}
            </h6>
            <p class="fs-5 fw-lighter">
              {{
                translationsLib.get('irrigation_ce_configuration_help')
                  | sanitizeText
              }}
            </p>
          </div>
          <div class="col-sm-7 col-md-7 order-3 order-md-2 col-inputs">
            <div
              class="row first-row align-items-center justify-content-between"
            >
              <div class="col-12 col-md-4">
                <h6 class="fs-5">
                  {{ translationsLib.get('irrigation_fertilizer_control') }}
                </h6>
                <div class="input-group mb-2">
                  <div class="form-check form-switch">
                    <input
                      formControlName="ECControl"
                      class="form-check-input"
                      type="checkbox"
                      role="switch"
                      placeholder="{{
                        translationsLib.get('irrigation_fertilizer_control')
                      }}"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="row align-items-center justify-content-between">
              <div class="col-12 col-md-4">
                <h6 class="fs-5">
                  {{ translationsLib.get('irrigation_max_ce') }}
                </h6>
                <div class="input-group mb-2 number-input">
                  <button
                    class="minus minus-style"
                    (click)="inputNumberService.decrementValue($event)"
                  ></button>
                  <input
                    formControlName="relMaxEC"
                    type="number"
                    class="form-control"
                    placeholder="{{ translationsLib.get('irrigation_max_ce') }}"
                    min="-9999"
                    max="9999"
                  />
                  <button
                    class="plus border-plus plus-style"
                    (click)="inputNumberService.incrementValue($event)"
                  ></button>
                </div>
              </div>
              <div class="col-12 col-md-4">
                <h6 class="fs-5">
                  {{ translationsLib.get('irrigation_ce_type') }}
                </h6>
                <div class="input-group mb-2">
                  <select
                    formControlName="ideal_incremental"
                    class="form-select"
                    [(ngModel)]="fertilizerForm.value.ideal_incremental"
                  >
                    <option selected value="INCREMENTAL">
                      {{ translationsLib.get('irrigation_incremental') }}
                    </option>
                    <option selected value="IDEAL">
                      {{ translationsLib.get('irrigation_absolute') }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-12 col-md-4">
                <h6 class="fs-5">
                  {{ translationsLib.get('irrigation_ideal_ce_correction') }}
                </h6>
                <div class="input-group mb-2 number-input">
                  <button
                    class="minus minus-style"
                    (click)="inputNumberService.decrementValue($event)"
                  ></button>
                  <input
                    formControlName="idealEC"
                    step="0.1"
                    type="number"
                    class="form-control"
                    placeholder="{{
                      translationsLib.get('irrigation_ideal_ce_correction')
                    }}"
                    min="-9999"
                    max="9999"
                  />
                  <button
                    class="plus border-plus plus-style"
                    (click)="inputNumberService.incrementValue($event)"
                  ></button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Corrección CE ideal -->
    <div
      class="card"
      *ngIf="
        fertilizerForm.value.ideal_incremental === 'IDEAL' &&
        layoutConfiguration.blocks.ce_ideal.show
      "
    >
      <div class="card-body custom-card-body">
        <div class="row">
          <div class="col-sm-5 col-md-5 order-1 order-md-1">
            <h6 class="fs-2">
              {{ translationsLib.get('irrigation_ideal_ce_correction_title') }}
            </h6>
            <p class="fs-5 fw-lighter">
              {{
                translationsLib.get('irrigation_ideal_ce_correction_help')
                  | sanitizeText
              }}
            </p>
          </div>
          <div class="col-sm-7 col-md-7 order-3 order-md-2 col-inputs">
            <div class="row first-row align-items-center">
              <div class="col-sm-4 col-md-4">
                <h6 class="fs-5">
                  {{ translationsLib.get('irrigation_sensor') }}
                </h6>
                <div class="input-group mb-2">
                  <select
                    class="form-select"
                    [(ngModel)]="selectedSensor"
                    [ngModelOptions]="{
                      standalone: true
                    }"
                    (ngModelChange)="setDirty(true)"
                  >
                    <option value="255_255">-</option>
                    <ng-container *ngFor="let sensor of sensorsList">
                      <ng-container
                        *ngIf="
                          sensor.type === 0 ||
                          sensor.type === 7 ||
                          sensor.type === 16
                        "
                      >
                        <option [value]="sensor.id">{{ sensor.name }}</option>
                      </ng-container>
                    </ng-container>
                  </select>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-4">
                <h6 class="fs-5">
                  {{ translationsLib.get('irrigation_correction') }}
                </h6>
                <div class="input-group mb-2 number-input">
                  <button
                    class="minus minus-style"
                    (click)="inputNumberService.decrementValue($event)"
                  ></button>
                  <input
                    formControlName="idealECCorrection"
                    type="number"
                    class="form-control"
                    placeholder="{{
                      translationsLib.get('irrigation_correction')
                    }}"
                    min="-9999"
                    max="9999"
                  />
                  <button
                    class="plus border-plus plus-style"
                    (click)="inputNumberService.incrementValue($event)"
                  ></button>
                </div>
              </div>
              <div class="col-sm-4">
                <h6 class="fs-5">
                  {{ translationsLib.get('irrigation_correction_start') }}
                </h6>
                <div class="input-group mb-2 number-input">
                  <button
                    class="minus minus-style"
                    (click)="inputNumberService.decrementValue($event)"
                  ></button>
                  <input
                    formControlName="startECCorrection"
                    type="number"
                    class="form-control"
                    placeholder="{{
                      translationsLib.get('irrigation_correction_start')
                    }}"
                    min="-9999"
                    max="9999"
                  />
                  <button
                    class="plus border-plus plus-style"
                    (click)="inputNumberService.incrementValue($event)"
                  ></button>
                </div>
              </div>
              <div class="col-sm-4">
                <h6 class="fs-5">
                  {{ translationsLib.get('irrigation_correction_end') }}
                </h6>
                <div class="input-group mb-2 number-input">
                  <button
                    class="minus minus-style"
                    (click)="inputNumberService.decrementValue($event)"
                  ></button>
                  <input
                    formControlName="endECCorrection"
                    type="number"
                    class="form-control"
                    placeholder="{{
                      translationsLib.get('irrigation_correction_end')
                    }}"
                    min="-9999"
                    max="9999"
                  />
                  <button
                    class="plus border-plus plus-style"
                    (click)="inputNumberService.incrementValue($event)"
                  ></button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Corección CE incremental -->
    <div
      class="card"
      *ngIf="
        fertilizerForm.value.ideal_incremental === 'INCREMENTAL' &&
        layoutConfiguration.blocks.ce_incremental.show
      "
    >
      <div class="card-body custom-card-body">
        <div class="row">
          <div class="col-sm-5 col-md-5 order-1 order-md-1">
            <h6 class="fs-2">
              {{ translationsLib.get('irrigation_incremental_ce_correction') }}
            </h6>
            <p class="fs-5 fw-lighter">
              {{
                translationsLib.get('irrigation_incremental_ce_correction_help')
                  | sanitizeText
              }}
            </p>
          </div>
          <div class="col-sm-7 col-md-7 order-3 order-md-2 col-inputs">
            <div class="row first-row align-items-center">
              <div class="col-sm-4 col-md-4">
                <h6 class="fs-5">
                  {{ translationsLib.get('irrigation_sensor') }}
                </h6>
                <div class="input-group mb-2">
                  <select
                    class="form-select"
                    [(ngModel)]="selectedSensor"
                    [ngModelOptions]="{
                      standalone: true
                    }"
                    (ngModelChange)="setDirty(true)"
                  >
                    <option value="255_255">-</option>
                    <ng-container *ngFor="let sensor of sensorsList">
                      <ng-container
                        *ngIf="
                          sensor.type === 0 ||
                          sensor.type === 7 ||
                          sensor.type === 16
                        "
                      >
                        <option [value]="sensor.id">{{ sensor.name }}</option>
                      </ng-container>
                    </ng-container>
                  </select>
                </div>
              </div>
            </div>
            <div class="row align-items-center">
              <div class="col">
                <div class="row">
                  <div class="col-sm-4">
                    <h6 class="fs-5">
                      {{ translationsLib.get('irrigation_correction') }}
                    </h6>
                    <div class="input-group mb-2 number-input">
                      <button
                        class="minus minus-style"
                        (click)="inputNumberService.decrementValue($event)"
                      ></button>
                      <input
                        formControlName="incrECCorrection"
                        type="number"
                        class="form-control"
                        placeholder="{{
                          translationsLib.get('irrigation_correction')
                        }}"
                        min="-9999"
                        max="9999"
                      />
                      <button
                        class="plus border-plus plus-style"
                        (click)="inputNumberService.incrementValue($event)"
                      ></button>
                    </div>
                  </div>
                  <div class="col-sm-4">
                    <h6 class="fs-5">
                      {{ translationsLib.get('irrigation_correction_start') }}
                    </h6>
                    <div class="input-group mb-2 number-input">
                      <button
                        class="minus minus-style"
                        (click)="inputNumberService.decrementValue($event)"
                      ></button>
                      <input
                        formControlName="startIncrECCorrection"
                        type="number"
                        class="form-control"
                        placeholder="{{
                          translationsLib.get('irrigation_correction_start')
                        }}"
                        min="-9999"
                        max="9999"
                      />
                      <button
                        class="plus border-plus plus-style"
                        (click)="inputNumberService.incrementValue($event)"
                      ></button>
                    </div>
                  </div>
                  <div class="col-sm-4">
                    <h6 class="fs-5">
                      {{ translationsLib.get('irrigation_correction_end') }}
                    </h6>
                    <div class="input-group mb-2 number-input">
                      <button
                        class="minus minus-style"
                        (click)="inputNumberService.decrementValue($event)"
                      ></button>
                      <input
                        formControlName="endIncrECCorrection"
                        type="number"
                        class="form-control"
                        placeholder="{{
                          translationsLib.get('irrigation_correction_end')
                        }}"
                        min="-9999"
                        max="9999"
                      />
                      <button
                        class="plus border-plus plus-style"
                        (click)="inputNumberService.incrementValue($event)"
                      ></button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Volumen previo -->
    <!-- TODO: Add layout configuration. API response not include this field at the moment -->
    <div class="card">
      <div class="card-body custom-card-body">
        <div class="row justify-content-between align-items-center">
          <div class="col-sm-5 col-md-5 order-1 order-md-1">
            <h6 class="fs-2">
              {{ translationsLib.get('irrigation_previous_volume') }}
            </h6>
            <p class="fs-5 fw-lighter">
              {{
                translationsLib.get('irrigation_previous_volume_html')
                  | sanitizeText
              }}
            </p>
          </div>
          <div class="col-sm-7 col-md-7 order-3 order-md-2 col-inputs">
            <div class="row first-row align-items-center">
              <div class="ol-sm-4 col-md-4">
                <div class="input-group number-input">
                  <button
                    class="minus minus-style"
                    (click)="inputNumberService.decrementValue($event)"
                  ></button>
                  <input
                    formControlName="prevIrrVolume"
                    type="number"
                    class="form-control"
                    placeholder="{{
                      translationsLib.get('irrigation_previous_volume')
                    }}"
                    min="-9999"
                    max="9999"
                  />
                  <button
                    class="plus border-plus plus-style"
                    (click)="inputNumberService.incrementValue($event)"
                  ></button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Configuración PH -->
    <div class="card" *ngIf="layoutConfiguration.blocks.ph_configuration.show">
      <div class="card-body custom-card-body">
        <div class="row">
          <div class="col-sm-5 col-md-5 order-1 order-md-1">
            <h6 class="fs-2">
              {{ translationsLib.get('irrigation_ph_configuration') }}
            </h6>
            <p class="fs-5 fw-lighter">
              {{
                translationsLib.get('irrigation_ph_configuration_help')
                  | sanitizeText
              }}
            </p>
          </div>
          <div class="col-sm-7 col-md-7 order-3 order-md-2 col-inputs">
            <div class="row first-row align-items-center">
              <div class="col">
                <h6 class="fs-5">
                  {{ translationsLib.get('irrigation_ph_control') }}
                </h6>
                <div class="input-group mb-2">
                  <div class="form-check form-switch">
                    <input
                      formControlName="phControl"
                      class="form-check-input"
                      type="checkbox"
                      role="switch"
                      placeholder="{{
                        translationsLib.get('irrigation_ph_control')
                      }}"
                    />
                  </div>
                </div>
              </div>
              <div class="col">
                <h6 class="fs-5">
                  {{ translationsLib.get('irrigation_ideal_ph') }}
                </h6>
                <div class="input-group mb-2 number-input">
                  <button
                    class="minus minus-style"
                    (click)="inputNumberService.decrementValue($event)"
                  ></button>
                  <input
                    formControlName="idealPH"
                    type="number"
                    step="0.1"
                    class="form-control"
                    placeholder="{{
                      translationsLib.get('irrigation_ideal_ph')
                    }}"
                    min="-9999"
                    max="9999"
                  />
                  <button
                    class="plus border-plus plus-style"
                    (click)="inputNumberService.incrementValue($event)"
                  ></button>
                </div>
              </div>
              <div class="col">
                <h6 class="fs-5">
                  {{ translationsLib.get('irrigation_min_rel_ph') }}
                </h6>
                <div class="input-group mb-2 number-input">
                  <button
                    class="minus minus-style"
                    (click)="inputNumberService.decrementValue($event)"
                  ></button>
                  <input
                    formControlName="relMinPH"
                    type="number"
                    class="form-control"
                    placeholder="{{
                      translationsLib.get('irrigation_min_rel_ph')
                    }}"
                    min="-9999"
                    max="9999"
                  />
                  <button
                    class="plus border-plus plus-style"
                    (click)="inputNumberService.incrementValue($event)"
                  ></button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Abonos de la receta -->
    <div class="card" *ngIf="layoutConfiguration.blocks.fertilizers.show">
      <div class="card-body custom-card-body">
        <div class="row">
          <div class="col-sm-5 col-md-5 order-1 order-md-1">
            <h6 class="fs-2">
              {{ translationsLib.get('irrigation_fertilizer_recipe') }}
            </h6>
            <p class="fs-5 fw-lighter">
              {{
                translationsLib.get('irrigation_fertilizer_recipe_help')
                  | sanitizeText
              }}
            </p>
          </div>
          <div class="col-sm-7 col-md-7 order-3 order-md-2 col-inputs">
            <div formArrayName="fertilizers">
              <ng-container
                *ngFor="let control of fertilizersArr.controls; let i = index"
              >
                <ng-container [formGroupName]="i">
                  <div class="row align-items-center">
                    <div class="col-12 col-md-6">
                      <h6 class="fs-5">
                        {{ translationsLib.get('irrigation_fertilizer') }}
                        {{ i + 1 }}
                      </h6>
                      <div class="input-group mb-2">
                        <select
                          formControlName="fertilizerType"
                          class="form-select"
                        >
                          <ng-container *ngFor="let type of fertilizerTypes">
                            <option
                              *ngIf="
                                getFertilizerType(
                                  type,
                                  layoutConfiguration.blocks.fertilizers
                                    .sub_blocks
                                ) !== undefined
                              "
                              [ngValue]="type"
                            >
                              {{
                                getFertilizerType(
                                  type,
                                  layoutConfiguration.blocks.fertilizers
                                    .sub_blocks
                                )
                              }}
                            </option>
                          </ng-container>
                        </select>
                      </div>
                    </div>
                    <div class="col-12 col-md-6">
                      <h6 class="fs-5">
                        {{ translationsLib.get('irrigation_value') }}
                      </h6>

                      <ng-template
                        [ngIf]="control.value.fertilizerType === 'PERCENTAGE'"
                      >
                        <div class="input-group number-input mb-2">
                          <button
                            class="minus minus-style"
                            (click)="inputNumberService.decrementValue($event)"
                          ></button>
                          <input
                            (ngModelChange)="setDirty(true)"
                            formControlName="quantity"
                            type="number"
                            class="form-control"
                            placeholder="{{
                              translationsLib.get('irrigation_fertilizer')
                            }}"
                            min="-9999"
                            max="9999"
                          />
                          <button
                            class="plus border-plus plus-style"
                            (click)="inputNumberService.incrementValue($event)"
                          ></button>
                          <div class="input-group-prepend">
                            <span class="input-group-text">%</span>
                          </div>
                        </div>
                      </ng-template>

                      <ng-template
                        [ngIf]="
                          control.value.fertilizerType ===
                          'SPECIAL_CONTRIBUTION'
                        "
                      >
                        <div class="input-group number-input mb-2">
                          <button
                            class="minus minus-style"
                            (click)="inputNumberService.decrementValue($event)"
                          ></button>
                          <input
                            (ngModelChange)="setDirty(true)"
                            formControlName="quantity"
                            type="number"
                            class="form-control"
                            placeholder="{{
                              translationsLib.get('irrigation_fertilizer')
                            }}"
                            min="-9999"
                            max="9999"
                          />
                          <button
                            class="plus border-plus plus-style"
                            (click)="inputNumberService.incrementValue($event)"
                          ></button>
                          <div class="input-group-prepend">
                            <span class="input-group-text">{{
                              translationsLib.get('irrigation_liters')
                            }}</span>
                          </div>
                        </div>
                      </ng-template>

                      <ng-template
                        [ngIf]="control.value.fertilizerType === 'DISCREET'"
                      >
                        <div class="input-group number-input mb-2">
                          <button
                            class="minus minus-style"
                            (click)="inputNumberService.decrementValue($event)"
                          ></button>
                          <input
                            (ngModelChange)="setDirty(true)"
                            formControlName="quantity"
                            type="number"
                            class="form-control"
                            placeholder="{{
                              translationsLib.get('irrigation_fertilizer')
                            }}"
                            min="-9999"
                            max="9999"
                          />
                          <button
                            class="plus border-plus plus-style"
                            (click)="inputNumberService.incrementValue($event)"
                          ></button>
                          <div class="input-group-prepend">
                            <span class="input-group-text">l/m³</span>
                          </div>
                        </div>
                      </ng-template>

                      <ng-template
                        [ngIf]="control.value.fertilizerType === 'VOLUME'"
                      >
                        <div class="input-group number-input mb-2">
                          <button
                            class="minus minus-style"
                            (click)="inputNumberService.decrementValue($event)"
                          ></button>
                          <input
                            (ngModelChange)="setDirty(true)"
                            formControlName="quantity"
                            type="number"
                            class="form-control"
                            placeholder="{{
                              translationsLib.get('irrigation_fertilizer')
                            }}"
                            min="-9999"
                            max="9999"
                          />
                          <button
                            class="plus border-plus plus-style"
                            (click)="inputNumberService.incrementValue($event)"
                          ></button>
                          <div class="input-group-prepend">
                            <span class="input-group-text">l/m³</span>
                          </div>
                        </div>
                      </ng-template>

                      <ng-template
                        [ngIf]="control.value.fertilizerType === 'TIME'"
                      >
                        <div class="mb-2">
                          <ng-template #timeSimplePicker>
                            <timepicker
                              appOnlyNumbers
                              [showMeridian]="false"
                              [showSeconds]="true"
                              [minuteStep]="1"
                              [secondsStep]="1"
                              [formControlName]="'myTime'"
                            ></timepicker>
                          </ng-template>
                          <input
                            appOnlyNumbers
                            [ngClass]="{
                              'ng-dirty': control.get('myTime').dirty
                            }"
                            class="form-control text-center"
                            type="text"
                            [popover]="timeSimplePicker"
                            [outsideClick]="true"
                            placement="bottom"
                            [value]="
                              handleTimeValue(
                                control.value.myTime | inputTime,
                                control
                              )
                            "
                            (change)="
                              onChangeInputTimeWithKeyboard($event, control)
                            "
                          />
                        </div>
                      </ng-template>

                      <ng-template
                        [ngIf]="control.value.fertilizerType === 'SEQUENTIAL'"
                      >
                        <div class="mb-2">
                          <ng-template #secuencialSimplePicker>
                            <timepicker
                              appOnlyNumbers
                              [showMeridian]="false"
                              [showSeconds]="true"
                              [minuteStep]="1"
                              [secondsStep]="1"
                              [formControlName]="'myTime'"
                            ></timepicker>
                          </ng-template>
                          <input
                            appOnlyNumbers
                            [ngClass]="{
                              'ng-dirty': control.get('myTime').dirty
                            }"
                            class="form-control text-center"
                            type="text"
                            [popover]="secuencialSimplePicker"
                            [outsideClick]="true"
                            placement="bottom"
                            [value]="
                              handleTimeValue(
                                control.value.myTime | inputTime,
                                control
                              )
                            "
                            (change)="
                              onChangeInputTimeWithKeyboard($event, control)
                            "
                          />
                        </div>
                      </ng-template>
                    </div>
                  </div>
                </ng-container>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</ng-container>

<ng-container
  *ngIf="
    mode === 'advanced' &&
    layoutConfiguration &&
    !isFetchingData &&
    !isFertilizerFormulasWithoutData
  "
>
  <form [formGroup]="fertilizerForm" class="advanced-form">
    <div class="row row-cols-md-2">
      <div class="col-12 col-md-6">
        <!-- Nombre de la receta -->
        <div
          id="recipeNameAdvance"
          class="card"
          *ngIf="layoutConfiguration.blocks.name.show"
        >
          <div class="card-body custom-card-body">
            <div class="row">
              <div class="col-md-12">
                <h6 class="fs-2 input-label">
                  {{ translationsLib.get('irrigation_formula_name') }}
                </h6>
              </div>
            </div>
            <div class="row">
              <div class="col-12 col-md-6">
                <div class="input-group mb-2">
                  <input
                    formControlName="name"
                    type="text"
                    class="form-control"
                    placeholder="{{
                      translationsLib.get('irrigation_formula_name')
                    }}"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Configuración de PH -->
        <div
          id="phConfigurationAdvance"
          class="card"
          *ngIf="layoutConfiguration.blocks.ph_configuration.show"
        >
          <div class="card-body custom-card-body">
            <div class="row">
              <div class="col-12">
                <h6 class="fs-2 input-label">
                  {{
                    translationsLib.get('irrigation_ph_configuration_recipe')
                  }}
                </h6>
              </div>
            </div>
            <div class="row align-items-center">
              <div class="col-12">
                <div class="row align-items-center">
                  <div class="col-6">
                    <h6 class="fs-5">
                      {{ translationsLib.get('irrigation_ph_control') }}
                    </h6>
                  </div>
                  <div class="col-6">
                    <div class="input-group">
                      <div class="form-check form-switch">
                        <input
                          formControlName="phControl"
                          class="form-check-input"
                          type="checkbox"
                          role="switch"
                          placeholder="{{
                            translationsLib.get('irrigation_ph_control')
                          }}"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12">
                <div class="row jus">
                  <div class="col-lg-6">
                    <h6 class="fs-5">
                      {{ translationsLib.get('irrigation_ideal_ph') }}
                    </h6>
                  </div>
                  <div class="col-lg-6">
                    <div class="input-group mb-2 number-input">
                      <button
                        class="minus minus-style"
                        (click)="inputNumberService.decrementValue($event)"
                      ></button>
                      <input
                        formControlName="idealPH"
                        step="0.1"
                        type="number"
                        class="form-control"
                        placeholder="{{
                          translationsLib.get('irrigation_ideal_ph')
                        }}"
                        min="-9999"
                        max="9999"
                      />
                      <button
                        class="plus border-plus plus-style"
                        (click)="inputNumberService.incrementValue($event)"
                      ></button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12">
                <div class="row jus">
                  <div class="col-lg-6">
                    <h6 class="fs-5">
                      {{ translationsLib.get('irrigation_min_rel_ph') }}
                    </h6>
                  </div>
                  <div class="col-lg-6">
                    <div class="input-group mb-2 number-input">
                      <button
                        class="minus minus-style"
                        (click)="inputNumberService.decrementValue($event)"
                      ></button>
                      <input
                        formControlName="relMinPH"
                        type="number"
                        class="form-control"
                        placeholder="{{
                          translationsLib.get('irrigation_min_rel_ph')
                        }}"
                        min="-9999"
                        max="9999"
                      />
                      <button
                        class="plus border-plus plus-style"
                        (click)="inputNumberService.incrementValue($event)"
                      ></button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Configuración de CE -->
        <div
          class="card"
          *ngIf="layoutConfiguration.blocks.ce_configuration.show"
        >
          <div class="card-body custom-card-body">
            <div class="row">
              <div class="col-12">
                <h6 class="fs-2">
                  {{
                    translationsLib.get('irrigation_ce_configuration_recipe')
                  }}
                </h6>
              </div>
            </div>
            <div class="row align-items-center">
              <div class="col-sm-12 col-md-12 col-lg-4">
                <div class="row justify-content-between align-items-center">
                  <div class="col">
                    <h6 class="fs-5">
                      {{ translationsLib.get('irrigation_fertilizer_control') }}
                    </h6>
                  </div>
                </div>
                <div class="row justify-content-between align-items-center">
                  <div class="col text-end">
                    <div class="form-check form-switch">
                      <input
                        formControlName="ECControl"
                        class="form-check-input"
                        type="checkbox"
                        role="switch"
                        placeholder="{{
                          translationsLib.get('irrigation_fertilizer_control')
                        }}"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-sm-12 col-md-6 col-lg-4">
                <h6 class="fs-5">
                  {{ translationsLib.get('irrigation_ce_type') }}
                </h6>
                <div class="input-group mb-2">
                  <select
                    formControlName="ideal_incremental"
                    class="form-select"
                    [(ngModel)]="fertilizerForm.value.ideal_incremental"
                  >
                    <option selected value="INCREMENTAL">
                      {{ translationsLib.get('irrigation_incremental') }}
                    </option>
                    <option selected value="IDEAL">
                      {{ translationsLib.get('irrigation_absolute') }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-sm-12 col-md-6 col-lg-4">
                <h6 class="fs-5">
                  {{ translationsLib.get('irrigation_ideal_ce_correction') }}
                </h6>
                <div class="input-group mb-2 number-input">
                  <button
                    class="minus minus-style"
                    (click)="inputNumberService.decrementValue($event)"
                  ></button>
                  <input
                    formControlName="idealEC"
                    type="number"
                    step="0.1"
                    class="form-control"
                    placeholder="{{
                      translationsLib.get('irrigation_ideal_ce_correction')
                    }}"
                    min="-9999"
                    max="9999"
                  />
                  <button
                    class="plus border-plus plus-style"
                    (click)="inputNumberService.incrementValue($event)"
                  ></button>
                </div>
              </div>
              <div class="col-sm-12 col-md-6 col-lg-4">
                <h6 class="fs-5">
                  {{ translationsLib.get('irrigation_max_ce') }}
                </h6>
                <div class="input-group mb-2 number-input">
                  <button
                    class="minus minus-style"
                    (click)="inputNumberService.decrementValue($event)"
                  ></button>
                  <input
                    formControlName="relMaxEC"
                    type="number"
                    class="form-control"
                    placeholder="{{ translationsLib.get('irrigation_max_ce') }}"
                    min="-9999"
                    max="9999"
                  />
                  <button
                    class="plus border-plus plus-style"
                    (click)="inputNumberService.incrementValue($event)"
                  ></button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Corección CE ideal -->
        <div
          class="card"
          *ngIf="
            fertilizerForm.value.ideal_incremental === 'IDEAL' &&
            layoutConfiguration.blocks.ce_ideal.show
          "
        >
          <div class="card-body custom-card-body">
            <div class="row">
              <div class="col-12">
                <h6 class="fs-2 input-label">
                  {{
                    translationsLib.get('irrigation_ideal_ce_correction_title')
                  }}
                </h6>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-6">
                <h6 class="fs-5">
                  {{ translationsLib.get('irrigation_sensor') }}
                </h6>
              </div>
              <div class="col-lg-6">
                <div class="input-group mb-2">
                  <select
                    class="form-select"
                    [(ngModel)]="selectedSensor"
                    [ngModelOptions]="{
                      standalone: true
                    }"
                    (ngModelChange)="setDirty(true)"
                  >
                    <option value="255_255">-</option>
                    <ng-container *ngFor="let sensor of sensorsList">
                      <ng-container
                        *ngIf="
                          sensor.type === 0 ||
                          sensor.type === 7 ||
                          sensor.type === 16
                        "
                      >
                        <option [value]="sensor.id">{{ sensor.name }}</option>
                      </ng-container>
                    </ng-container>
                  </select>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-6">
                <h6 class="fs-5">
                  {{ translationsLib.get('irrigation_correction') }}
                </h6>
              </div>
              <div class="col-lg-6">
                <div class="input-group mb-2 number-input">
                  <button
                    class="minus minus-style"
                    (click)="inputNumberService.decrementValue($event)"
                  ></button>
                  <input
                    formControlName="idealECCorrection"
                    type="number"
                    class="form-control"
                    placeholder="{{
                      translationsLib.get('irrigation_correction')
                    }}"
                    min="-9999"
                    max="9999"
                  />
                  <button
                    class="plus border-plus plus-style"
                    (click)="inputNumberService.incrementValue($event)"
                  ></button>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-6">
                <h6 class="fs-5">
                  {{ translationsLib.get('irrigation_correction_start') }}
                </h6>
              </div>
              <div class="col-lg-6">
                <div class="input-group mb-2 number-input">
                  <button
                    class="minus minus-style"
                    (click)="inputNumberService.decrementValue($event)"
                  ></button>
                  <input
                    formControlName="startECCorrection"
                    type="number"
                    class="form-control"
                    placeholder="{{
                      translationsLib.get('irrigation_correction_start')
                    }}"
                    min="-9999"
                    max="9999"
                  />
                  <button
                    class="plus border-plus plus-style"
                    (click)="inputNumberService.incrementValue($event)"
                  ></button>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-6">
                <h6 class="fs-5">
                  {{ translationsLib.get('irrigation_correction_end') }}
                </h6>
              </div>
              <div class="col-lg-6">
                <div class="input-group mb-2 number-input">
                  <button
                    class="minus minus-style"
                    (click)="inputNumberService.decrementValue($event)"
                  ></button>
                  <input
                    formControlName="endECCorrection"
                    type="number"
                    class="form-control"
                    placeholder="{{
                      translationsLib.get('irrigation_correction_end')
                    }}"
                    min="-9999"
                    max="9999"
                  />
                  <button
                    class="plus border-plus plus-style"
                    (click)="inputNumberService.incrementValue($event)"
                  ></button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Corrección CE incremental -->
        <div
          class="card"
          *ngIf="
            fertilizerForm.value.ideal_incremental === 'INCREMENTAL' &&
            layoutConfiguration.blocks.ce_incremental.show
          "
        >
          <div class="card-body custom-card-body">
            <div class="row">
              <div class="col-12">
                <h6 class="fs-2 input-label">
                  {{
                    translationsLib.get('irrigation_incremental_ce_correction')
                  }}
                </h6>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-6">
                <h6 class="fs-5">
                  {{ translationsLib.get('irrigation_sensor') }}
                </h6>
              </div>
              <div class="col-lg-6">
                <div class="input-group mb-2">
                  <select
                    class="form-select"
                    [(ngModel)]="selectedSensor"
                    [ngModelOptions]="{
                      standalone: true
                    }"
                    (ngModelChange)="setDirty(true)"
                  >
                    <option value="255_255">-</option>
                    <ng-container *ngFor="let sensor of sensorsList">
                      <ng-container
                        *ngIf="
                          sensor.type === 0 ||
                          sensor.type === 7 ||
                          sensor.type === 16
                        "
                      >
                        <option [value]="sensor.id">{{ sensor.name }}</option>
                      </ng-container>
                    </ng-container>
                  </select>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-6">
                <h6 class="fs-5">
                  {{ translationsLib.get('irrigation_correction') }}
                </h6>
              </div>
              <div class="col-lg-6">
                <div class="input-group mb-2 number-input">
                  <button
                    class="minus minus-style"
                    (click)="inputNumberService.decrementValue($event)"
                  ></button>
                  <input
                    formControlName="incrECCorrection"
                    type="number"
                    class="form-control"
                    placeholder="{{
                      translationsLib.get('irrigation_correction')
                    }}"
                    min="-9999"
                    max="9999"
                  />
                  <button
                    class="plus border-plus plus-style"
                    (click)="inputNumberService.incrementValue($event)"
                  ></button>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-6">
                <h6 class="fs-5">
                  {{ translationsLib.get('irrigation_correction_start') }}
                </h6>
              </div>
              <div class="col-lg-6">
                <div class="input-group mb-2 number-input">
                  <button
                    class="minus minus-style"
                    (click)="inputNumberService.decrementValue($event)"
                  ></button>
                  <input
                    formControlName="startIncrECCorrection"
                    type="number"
                    class="form-control"
                    placeholder="{{
                      translationsLib.get('irrigation_correction_start')
                    }}"
                    min="-9999"
                    max="9999"
                  />
                  <button
                    class="plus border-plus plus-style"
                    (click)="inputNumberService.incrementValue($event)"
                  ></button>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-6">
                <h6 class="fs-5">
                  {{ translationsLib.get('irrigation_correction_end') }}
                </h6>
              </div>
              <div class="col-lg-6">
                <div class="input-group mb-2 number-input">
                  <button
                    class="minus minus-style"
                    (click)="inputNumberService.decrementValue($event)"
                  ></button>
                  <input
                    formControlName="endIncrECCorrection"
                    type="number"
                    class="form-control"
                    placeholder="{{
                      translationsLib.get('irrigation_correction_end')
                    }}"
                    min="-9999"
                    max="9999"
                  />
                  <button
                    class="plus border-plus plus-style"
                    (click)="inputNumberService.incrementValue($event)"
                  ></button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Volumen previo -->
        <!-- TODO: Add layout configuration. API response not include this field at the moment -->
        <div id="previousVolumeAdvance" class="card">
          <div class="card-body custom-card-body">
            <div class="row">
              <div class="col-12">
                <h6 class="fs-2 input-label">
                  {{ translationsLib.get('irrigation_previous_volume') }}
                </h6>
              </div>
            </div>
            <div class="row">
              <div class="col-12 col-md-6">
                <div class="input-group number-input">
                  <button
                    class="minus minus-style"
                    (click)="inputNumberService.decrementValue($event)"
                  ></button>
                  <input
                    formControlName="prevIrrVolume"
                    type="number"
                    class="form-control"
                    placeholder="{{
                      translationsLib.get('irrigation_previous_volume')
                    }}"
                    min="-9999"
                    max="9999"
                  />
                  <button
                    class="plus border-plus plus-style"
                    (click)="inputNumberService.incrementValue($event)"
                  ></button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-6">
        <!-- Abonos de la receta -->
        <div id="fertilizersAdvance" class="card">
          <div
            class="card-body custom-card-body"
            *ngIf="layoutConfiguration.blocks.fertilizers.show"
          >
            <div class="row">
              <div class="col-12 col-md">
                <h6 class="fs-2">
                  {{ translationsLib.get('irrigation_fertilizer_recipe') }}
                </h6>
              </div>
            </div>
            <div class="row align-items-center">
              <div class="col">
                <div formArrayName="fertilizers">
                  <ng-container
                    *ngFor="
                      let control of fertilizersArr.controls;
                      let i = index
                    "
                  >
                    <ng-container [formGroupName]="i">
                      <div class="row align-items-center">
                        <div class="col-12 col-md">
                          <h6 class="fs-5">
                            {{ translationsLib.get('irrigation_fertilizer') }}
                            {{ i + 1 }}
                          </h6>
                          <div class="input-group mb-2">
                            <select
                              formControlName="fertilizerType"
                              class="form-select"
                            >
                              <ng-container
                                *ngFor="let type of fertilizerTypes"
                              >
                                <option
                                  *ngIf="
                                    getFertilizerType(
                                      type,
                                      layoutConfiguration.blocks.fertilizers
                                        .sub_blocks
                                    ) !== undefined
                                  "
                                  [ngValue]="type"
                                >
                                  {{
                                    getFertilizerType(
                                      type,
                                      layoutConfiguration.blocks.fertilizers
                                        .sub_blocks
                                    )
                                  }}
                                </option>
                              </ng-container>
                            </select>
                          </div>
                        </div>
                        <div class="col-12 col-md">
                          <h6 class="fs-5">
                            {{ translationsLib.get('irrigation_value') }}
                          </h6>

                          <ng-template
                            [ngIf]="
                              control.value.fertilizerType === 'PERCENTAGE'
                            "
                          >
                            <div class="input-group number-input mb-2">
                              <button
                                class="minus minus-style"
                                (click)="inputNumberService.decrementValue($event)"
                              ></button>
                              <input
                                (ngModelChange)="setDirty(true)"
                                formControlName="quantity"
                                type="number"
                                class="form-control"
                                placeholder="{{
                                  translationsLib.get('irrigation_fertilizer')
                                }}"
                                min="-9999"
                                max="9999"
                              />
                              <button
                                class="plus border-plus plus-style"
                                (click)="inputNumberService.incrementValue($event)"
                              ></button>
                              <div class="input-group-prepend">
                                <span class="input-group-text">%</span>
                              </div>
                            </div>
                          </ng-template>

                          <ng-template
                            [ngIf]="
                              control.value.fertilizerType ===
                              'SPECIAL_CONTRIBUTION'
                            "
                          >
                            <div class="input-group number-input mb-2">
                              <button
                                class="minus minus-style"
                                (click)="inputNumberService.decrementValue($event)"
                              ></button>
                              <input
                                (ngModelChange)="setDirty(true)"
                                formControlName="quantity"
                                type="number"
                                class="form-control"
                                placeholder="{{
                                  translationsLib.get('irrigation_fertilizer')
                                }}"
                                min="-9999"
                                max="9999"
                              />
                              <button
                                class="plus border-plus plus-style"
                                (click)="inputNumberService.incrementValue($event)"
                              ></button>
                              <div class="input-group-prepend">
                                <span class="input-group-text">{{
                                  translationsLib.get('irrigation_liters')
                                }}</span>
                              </div>
                            </div>
                          </ng-template>

                          <ng-template
                            [ngIf]="control.value.fertilizerType === 'DISCREET'"
                          >
                            <div class="input-group number-input mb-2">
                              <button
                                class="minus minus-style"
                                (click)="inputNumberService.decrementValue($event)"
                              ></button>
                              <input
                                (ngModelChange)="setDirty(true)"
                                formControlName="quantity"
                                type="number"
                                class="form-control"
                                placeholder="{{
                                  translationsLib.get('irrigation_fertilizer')
                                }}"
                                min="-9999"
                                max="9999"
                              />
                              <button
                                class="plus border-plus plus-style"
                                (click)="inputNumberService.incrementValue($event)"
                              ></button>
                              <div class="input-group-prepend">
                                <span class="input-group-text">l/m³</span>
                              </div>
                            </div>
                          </ng-template>

                          <ng-template
                            [ngIf]="control.value.fertilizerType === 'VOLUME'"
                          >
                            <div class="input-group number-input mb-2">
                              <button
                                class="minus minus-style"
                                (click)="inputNumberService.decrementValue($event)"
                              ></button>
                              <input
                                (ngModelChange)="setDirty(true)"
                                formControlName="quantity"
                                type="number"
                                class="form-control"
                                placeholder="{{
                                  translationsLib.get('irrigation_fertilizer')
                                }}"
                                min="-9999"
                                max="9999"
                              />
                              <button
                                class="plus border-plus plus-style"
                                (click)="inputNumberService.incrementValue($event)"
                              ></button>
                              <div class="input-group-prepend">
                                <span class="input-group-text">l/m³</span>
                              </div>
                            </div>
                          </ng-template>

                          <ng-template
                            [ngIf]="control.value.fertilizerType === 'TIME'"
                          >
                            <div class="mb-2">
                              <ng-template #timeAdvancedPicker>
                                <timepicker
                                  appOnlyNumbers
                                  [showMeridian]="false"
                                  [showSeconds]="true"
                                  [minuteStep]="1"
                                  [secondsStep]="1"
                                  [formControlName]="'myTime'"
                                ></timepicker>
                              </ng-template>
                              <input
                                appOnlyNumbers
                                pattern="[0-9]*"
                                [ngClass]="{
                                  'ng-dirty': control.get('myTime').dirty
                                }"
                                class="form-control text-center"
                                type="text"
                                [popover]="timeAdvancedPicker"
                                [outsideClick]="true"
                                placement="bottom"
                                [value]="
                                  handleTimeValue(
                                    control.value.myTime | inputTime,
                                    control
                                  )
                                "
                                (change)="
                                  onChangeInputTimeWithKeyboard($event, control)
                                "
                              />
                            </div>
                          </ng-template>
                          <ng-template
                            [ngIf]="
                              control.value.fertilizerType === 'SEQUENTIAL'
                            "
                          >
                            <div class="mb-2">
                              <ng-template #secuencialAdvancedPicker>
                                <timepicker
                                  appOnlyNumbers
                                  [showMeridian]="false"
                                  [showSeconds]="true"
                                  [minuteStep]="1"
                                  [secondsStep]="1"
                                  [formControlName]="'myTime'"
                                ></timepicker>
                              </ng-template>
                              <input
                                appOnlyNumbers
                                [ngClass]="{
                                  'ng-dirty': control.get('myTime').dirty
                                }"
                                class="form-control text-center"
                                type="text"
                                [popover]="secuencialAdvancedPicker"
                                [outsideClick]="true"
                                placement="bottom"
                                [value]="
                                  handleTimeValue(
                                    control.value.myTime | inputTime,
                                    control
                                  )
                                "
                                (change)="
                                  onChangeInputTimeWithKeyboard($event, control)
                                "
                              />
                            </div>
                          </ng-template>
                        </div>
                      </div>
                    </ng-container>
                  </ng-container>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</ng-container>
