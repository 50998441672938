<div class="card h-100 py-2 px-3 mb-2">
  <div class="row p-0">
    <div class="col-6">
      <div class="text-nowrap text-uppercase fs-6 fw-bold"
           style="color: var(--primary-color)">
        {{ field.labelLeft }}
      </div>
      <div [class]="isMobile ? 'fs-6' : 'fs-4'" class="fw-bold">
        {{ field.valueLeft }}
        <span *ngIf="field.valueLeft === undefined"
              [class]="isMobile ? 'fs-6' : 'fs-4'"
              class="fw-normal text-muted">
          -
        </span>
        <span *ngIf="field.unit"
              [class]="isMobile ? 'fs-6' : 'fs-4'"
              class="fw-normal text-muted">
          {{ field.unit }}
        </span>
      </div>
    </div>
    <div class="col-6">
      <div class="text-nowrap text-uppercase fs-6 fw-bold"
           style="color: var(--primary-color)">
        {{ field.labelRight }}
      </div>
      <div [class]="isMobile ? 'fs-6' : 'fs-4'" class="fw-bold">
        {{ field.valueRight }}
        <span *ngIf="field.valueRight === undefined"
              [class]="isMobile ? 'fs-6' : 'fs-4'"
              class="fw-normal text-muted">
          -
        </span>
        <span *ngIf="field.unit"
              [class]="isMobile ? 'fs-6' : 'fs-4'"
              class="fw-normal text-muted">
          {{ field.unit }}
        </span>
      </div>
    </div>
  </div>
</div>
