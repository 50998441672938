import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-reports-prog-real',
  templateUrl: './reports-prog-real.component.html',
  styleUrls: ['./reports-prog-real.component.scss'],
})
export class ReportsProgRealComponent implements OnInit {
  @Input() public prog: number;
  @Input() public real: number;
  @Input() public extra_class?: string = '';
  @Input() public is_exporting?: boolean = false;
  @Input() public unit = 'L';
  public processedProg: string;
  constructor() {}

  ngOnInit() {
    if (this.prog === 0 && this.real > 0) {
      this.processedProg = '-';
    } else {
      this.processedProg = String(this.prog);
    }
  }
}
