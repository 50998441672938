import { Component } from '@angular/core';
import {
  SessionLibService,
  TranslationsLibService,
  UserType,
} from '@nutricontrol/app360-shared';

@Component({
  selector: 'app-non-verified-installer-alert',
  templateUrl: './non-verified-installer-alert.component.html',
  styleUrls: ['./non-verified-installer-alert.component.scss'],
})
export class NonVerifiedInstallerAlertComponent {
  nonVerifiedInstaller = UserType.nonVerifiedInstaller;
  constructor(
    public sessionLib: SessionLibService,
    public translationsLib: TranslationsLibService
  ) {}

  public refresh() {
    location.reload();
  }
}
