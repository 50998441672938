<ion-content>
  <div class="container-fluid password-reset-container" id="password-reset">
    <div class="row justify-content-center">
      <div class="col-12 col-md-8 col-lg-6 col-xl-4 px-lg-6 my-5 align-self-center">
        <div id="login-loader">
          <div class="spinner-container">
            <div class="spinner-logo text-center">
              <img src="../../../assets/img/ucrop.svg" alt="LOGO"  style="max-width: 200px">
            </div>
          </div>
        </div>
        <!-- Heading -->
        <h1 class="text-center mb-3" *ngIf="emailSend === 0">
          {{ translationsLib.get('auth_forgotten_request_recover') }}
        </h1>
        <h1 class="text-center mb-3" *ngIf="emailSend === 1">
          {{ translationsLib.get('auth_forgotten_enter_code') }}
        </h1>
        <h1 class="text-center mb-3" *ngIf="emailSend === 2">
          {{ translationsLib.get('auth_forgotten_type_your_new_password') }}
        </h1>
        <!-- Subheading -->
        <p class="text-muted text-center mb-5" *ngIf="emailSend === 0">
          {{ translationsLib.get('auth_forgotten_request_recover_text') }}
        </p>

        <p class="text-muted text-center mb-5" *ngIf="emailSend === 1">
          {{ translationsLib.get('auth_forgotten_enter_code_text') }}
        </p>

        <p class="text-muted text-center mb-5" *ngIf="emailSend === 2">
          {{ translationsLib.get('auth_forgotten_type_your_new_password_text') }}
        </p>

        <!-- Form -->
        <form [formGroup]="resetPasswordEmail" (submit)="onSubmitEmail()" *ngIf="emailSend === 0">

          <!-- Email address -->
          <div class="form-group">

            <!-- Label -->
            <label class="form-label">
              {{ translationsLib.get('auth_login_email') }}
            </label>

            <!-- Input -->
            <input id="email" type="email" class="form-control" placeholder="{{ translationsLib.get('auth_login_email_example') }}" formControlName="email">

          </div>

          <!-- Submit -->
          <button type ="submit" class="btn btn-lg w-100 btn-primary mb-3" [disabled]="resetPasswordEmail.invalid" (click)="showLoading('show')">
            {{ translationsLib.get('auth_forgotten_request_new') }}
          </button>

          <!-- Link -->
          <div class="text-center">
            <span class="text-muted text-center">
              {{ translationsLib.get('auth_forgotten_do_you_remember_your_password') }}
              <a style="cursor: pointer; color:#3d6493" (click)="router.navigate(['/login']);">{{ translationsLib.get('auth_forgotten_password_login') }}</a>.
            </span>
          </div>

        </form>

        <!--Code-->
        <div *ngIf="emailSend === 1">
        <code-input inputType="text"
                    [isCodeHidden]="false"
                    [codeLength]="5"
                    [isCharsCode]="true"
                    (codeCompleted)="setConfirmationCode($event)">
        </code-input>
        <br>
        <button type="submit"class="btn btn-lg w-100 btn-primary mb-3">
          {{ translationsLib.get('auth_forgotten_new_password_validate') }}
        </button>
          <div class="text-center">
            <span class="text-muted text-center">
              {{translationsLib.get('step_back')}}
              <a style="cursor: pointer; color:#3d6493" (click)="emailSend = 0">{{translationsLib.get('back')}}</a>.
            </span><br/>
            <span class="text-muted text-center">
              {{ translationsLib.get('auth_forgotten_do_you_remember_your_password') }}
              <a style="cursor: pointer; color:#3d6493" (click)="router.navigate(['/login']);">{{ translationsLib.get('auth_forgotten_password_login') }}</a>.
            </span>
          </div>
        </div>

        <!-- Form2 -->
        <form [formGroup]="resetPasswordPass" (submit)="onSubmitPassword()" *ngIf="emailSend === 2">
          <!-- Email address -->
          <div class="form-group">

            <!-- Label -->
            <label class="form-label">
              {{ translationsLib.get('auth_forgotten_new_password') }}
            </label>

            <!-- Input -->
            <input id="passReseting" type="password" class="form-control" placeholder="" formControlName="password">
            <div id="msg" style="color:red; position: absolute " *ngIf="resetPasswordPass.value.password.length>1 && resetPasswordPass.value.password.length<6">
              {{ translationsLib.get('auth_register_password_length') }}
            </div>
            <br>
            <div style="height: 70px" *ngIf="resetPasswordPass.value.password.length>=6">
              <app-password-strength [passwordToCheck]="resetPasswordPass.value.password"></app-password-strength>
            </div>
          </div>

          <!-- Submit -->
          <button type ="submit" class="btn btn-lg w-100 btn-primary mb-3" [disabled]="resetPasswordPass.value.password.length<6 || resetPasswordPass.invalid">
            {{ translationsLib.get('save') }}
          </button>

          <!-- Link -->
          <div class="text-center">
            <span class="text-muted text-center">
              {{translationsLib.get('step_back')}}
              <a style="cursor: pointer; color:#3d6493" (click)="emailSend = 1">{{translationsLib.get('back')}}</a>.
            </span><br/>
            <span class="text-muted text-center">
              {{ translationsLib.get('auth_forgotten_do_you_remember_your_password') }}
              <a style="cursor: pointer; color:#3d6493" (click)="router.navigate(['/login']);">{{ translationsLib.get('auth_forgotten_password_login') }}</a>
            </span>
          </div>

        </form>

      </div>
      <div class="col-12 col-md-7 col-lg-6 col-xl-8 d-none d-lg-block">

        <!-- Image -->
        <div class="bg-cover h-100 min-vh-100 mt-n1 me-n3" style="background-image: url(assets/img/covers/Cover_login.jpg)"></div>

      </div>
    </div> <!-- / .row -->
  </div>
</ion-content>
