<lib-loader *ngIf="ready === false"></lib-loader>
<div class="card" *ngIf="ready === true">
  <div class="card-header">
    <h2 class="text-header-primary m-0">
      {{ translationsLib.get('auth_2fa') }}
    </h2>
  </div>
  <div class="card-body">
    <div class="row">
      <div class="col-12 mb-2" [innerHTML]="translationsLib.get('auth_2fa_what_is')"></div>
      <div class="col-12">
        <form novalidate [formGroup]="auth2faForm" (submit)="onSubmit()">
          <div class="row">
            <div class="col-12 col-lg-5">
              <div class="form-group">
                <label class="form-label d-block">
                  <span>{{ translationsLib.get('auth_2fa_user_preference_behaviour') }}</span>
                </label>
                <mat-form-field appearance="fill" class="w-100">
                  <mat-select id="auth_2fa_user_preference" [(value)]="selectedAuth2faUserPreference">
                    <mat-option *ngFor="let auth2faUserPreference of auth2faUserPreferences" [value]=auth2faUserPreference>
                      {{ translationsLib.get('auth_2fa_user_preferences_' + auth2faUserPreference) }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
            <div class="col-12 col-lg-5">
              <div class="form-group">
                <label class="form-label d-block">
                  <span>{{ translationsLib.get('auth_2fa_user_type_device_used') }}</span>
                </label>
                <mat-form-field appearance="fill" class="w-100">
                  <mat-select id="auth_2fa_type" [(value)]="selectedAuth2faType">
                    <mat-option *ngFor="let auth2faType of auth2faTypes" [value]=auth2faType>
                      {{ translationsLib.get('auth_2fa_types_' + auth2faType) }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
            <div class="col-12 col-lg-2 text-end">
              <div class="form-group">
                <label class="form-label d-none d-lg-block">
                  <span>&nbsp;</span>
                </label>
                <button type="button"  class="btn btn-primary" (click)="onSubmit();">
                  {{ translationsLib.get('save') }}
                </button>
              </div>
            </div>
          </div>
          <div class="col-12 mb-2 text-muted" [innerHTML]="translationsLib.get('auth_2fa_what_is_advanced')"></div>
        </form>
      </div>
    </div>
  </div>
</div>
