import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { AuthService } from '../auth.service';
import { Router } from '@angular/router';
import { LoadingController } from '@ionic/angular';
import { TranslationsLibService } from '@nutricontrol/app360-shared';
import Swal from 'sweetalert2';
import { LoginRequestDataModel } from '../auth.model';

@Component({
  selector: 'app-password-reset',
  templateUrl: './password-reset.component.html',
  styleUrls: ['./password-reset.component.scss'],
})
export class PasswordResetComponent implements OnInit {
  //PROCESO 0-> EMAIL 1->CODIGO DE CONFIRMACION 2->NUEVA CONTRASEÑA
  emailSend = 0;
  private code;
  private email;
  private fb: FormBuilder = new FormBuilder();
  // eslint-disable-next-line @typescript-eslint/member-ordering
  resetPasswordEmail = this.fb.group({
    email: [
      '',
      [
        Validators.required,
        Validators.pattern(
          '^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,4}$'
        ),
      ],
    ],
  });
  // eslint-disable-next-line @typescript-eslint/member-ordering
  resetPasswordPass = this.fb.group({
    password: ['', Validators.required],
    confirmation_code: [''],
    email: [''],
  });

  constructor(
    private authService: AuthService,
    public router: Router,
    private loadingCtrl: LoadingController,
    public translationsLib: TranslationsLibService
  ) {}

  ngOnInit() {}

  setConfirmationCode(code) {
    this.emailSend = 2;
    this.code = code;
  }

  onSubmitEmail() {
    this.authService
      .resetPasswordEmail(this.resetPasswordEmail.value)
      .subscribe(
        (response) => {
          this.emailSend = 1;
          this.showLoading('quit').then((r) => {});
          this.email = String(this.resetPasswordEmail.value.email);
        },
        (error) => {
          this.emailSend = 0;
          Swal.fire({
            icon: 'error',
            title: this.translationsLib.get('error'),
            text: this.translationsLib.get('auth_forgotten_error'),
            confirmButtonColor: '#224261',
          });
          this.showLoading('quit').then((r) => {});
        }
      );
  }

  onSubmitPassword() {
    this.resetPasswordPass.value.confirmation_code = this.code;
    this.resetPasswordPass.value.email = this.email;
    this.authService.checkResetPassword(this.resetPasswordPass.value).subscribe(
      (response) => {
        const data: LoginRequestDataModel = {
          email: this.email,
          password: this.resetPasswordPass.value.password,
        };
        this.authService.login(data).subscribe((authResponse) => {
          if (authResponse.next_action === 'auth_2fa_redirect_to_code') {
            this.router.navigate(['/2fa-confirmation'], {
              queryParams: {
                session_vid: authResponse.session_vid,
                user_vid: authResponse.user_vid,
              },
            });
          } else {
            this.authService
              .doLogin(authResponse, true)
              .subscribe((response) => {
                console.log('[LOGIN]: doLogin result ', response);
              });
          }
        });
      },
      (error) => {
        Swal.fire({
          icon: 'error',
          title: this.translationsLib.get('error'),
          text: this.translationsLib.get(
            'auth_forgotten_error_resetting_password'
          ),
          confirmButtonColor: '#224261',
        });
      }
    );
  }

  async showLoading(msg) {
    const loading = await this.loadingCtrl.create({
      message: this.translationsLib.get('auth_loading'),
      spinner: 'circles',
    });

    if (msg === 'show') {
      await loading.present();
    }
    if (msg === 'quit') {
      await this.loadingCtrl.dismiss();
    }
  }
}
