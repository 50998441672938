import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { FormControl, FormGroup } from '@angular/forms';
import { DateAdapter } from '@angular/material/core';
import {
  AvailableReportModel,
  RandomReportModel,
  ReportRequestData,
  TerminalReportModel,
} from '../../reports.model';
import {
  SessionLibService,
  TranslationsLibService,
} from '@nutricontrol/app360-shared';

@Component({
  selector: 'app-reports-suggested',
  templateUrl: './reports-suggested.component.html',
  styleUrls: ['./reports-suggested.component.scss'],
})
export class ReportsSuggestedComponent implements OnInit {
  @Input() availableTerminals: TerminalReportModel[] = [];
  @Input() availableReports: AvailableReportModel[] = [];
  @Input() dates = [];
  @Output() parentPutReportData = new EventEmitter<RandomReportModel>();
  recommendedReports: RandomReportModel[] = [];
  requestData: ReportRequestData = null;
  range = new FormGroup({
    start: new FormControl<Date | null>(null),
    end: new FormControl<Date | null>(null),
  });
  downloadPDF = false;
  constructor(
    private dateAdapter: DateAdapter<Date>,
    public router: Router,
    private sessionLib: SessionLibService,
    public translationsLib: TranslationsLibService
  ) {
    this.dateAdapter.setLocale(sessionLib.getLanguage());
    this.dateAdapter.getFirstDayOfWeek = () => 1;
  }

  ngOnInit() {
    this.createRandomRecommendedReports();
  }
  createRandomRecommendedReports() {
    const array = [];
    const terminalsArray = this.getRandomValuesWithoutRepeat(
      this.availableTerminals,
      5
    );
    // eslint-disable-next-line @typescript-eslint/prefer-for-of
    for (let i = 0; i < terminalsArray.length; i++) {
      const terminal = terminalsArray[i];
      const date = this.getRandomValueFromArray(this.dates);
      const reportType = this.getRandomValueFromArray(this.availableReports);
      const data = { terminal, date, reportType };
      array.push(data);
    }
    this.recommendedReports = array;
  }
  getRandomValueFromArray(arr): string {
    if (arr.length === 0) return undefined;
    const randomIndex = Math.floor(Math.random() * arr.length);
    return arr[randomIndex];
  }
  getRandomValuesWithoutRepeat(arr, count) {
    if (arr.length < count) return arr;
    const shuffledArray = [...arr];
    for (let i = shuffledArray.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [shuffledArray[i], shuffledArray[j]] = [
        shuffledArray[j],
        shuffledArray[i],
      ];
    }
    return shuffledArray.slice(0, count);
  }
  callParentPutReportData(report: RandomReportModel) {
    this.parentPutReportData.emit(report);
  }
  /*
  exportToPDF(report: RandomReportModel) {
    this.requestData.terminals_vid = [report.terminal.vid];

    if (reportId === 'groups_consumptions') {
      reportId = 'consumption/groups';
    }
    if (reportId === 'program_consumptions') {
      reportId = 'consumption/programs';
    }
    this.downloadPDF = true;
    setTimeout(() => {
      this.downloadPDF = false;
    }, 4000);
  }
  */
}
