import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RestApiService } from 'src/app/services/api/rest-api.service';
import { FAODataModel } from './datalogger.model';

@Injectable({
  providedIn: 'root',
})
export class DataloggerService {
  private restApiService: RestApiService;

  constructor(restApiService: RestApiService) {
    this.restApiService = restApiService;
  }

  public getConnections(vid): Observable<any> {
    return new Observable((observer) => {
      this.restApiService.getConnections(vid).subscribe(
        (response) => {
          observer.next(response);
        },
        (error) => {
          observer.error(error);
        }
      );
    });
  }
  public postConnections(vid, data): Observable<any> {
    return new Observable((observer) => {
      this.restApiService.postConnections(vid, data).subscribe(
        (response) => {
          observer.next(response);
        },
        (error) => {
          observer.error(error);
        }
      );
    });
  }
  public putConnections(vid, data): Observable<any> {
    return new Observable((observer) => {
      this.restApiService.putConnections(vid, data).subscribe(
        (response) => {
          observer.next(response);
        },
        (error) => {
          observer.error(error);
        }
      );
    });
  }
  public putCalculations(vid, data): Observable<any> {
    return new Observable((observer) => {
      this.restApiService.putCalculations(vid, data).subscribe(
        (response) => {
          observer.next(response);
        },
        (error) => {
          observer.error(error);
        }
      );
    });
  }
  public postCalcs(vid, data): Observable<any> {
    return new Observable((observer) => {
      this.restApiService.postCalculations(vid, data).subscribe(
        (response) => {
          observer.next(response);
        },
        (error) => {
          observer.error(error);
        }
      );
    });
  }
  public postReprogram(vid, data): Observable<any> {
    return new Observable((observer) => {
      this.restApiService.postReprogram(vid, data).subscribe(
        (response) => {
          observer.next(response);
        },
        (error) => {
          observer.error(error);
        }
      );
    });
  }
  public getCalculationsDependencies(vid, data): Observable<any> {
    return new Observable((observer) => {
      this.restApiService.getCalculationsdependencies(vid, data).subscribe(
        (response) => {
          observer.next(response);
        },
        (error) => {
          observer.error(error);
        }
      );
    });
  }
  public getConnectionsDependencies(vid, data): Observable<any> {
    return new Observable((observer) => {
      this.restApiService.getConnectionsdependencies(vid, data).subscribe(
        (response) => {
          observer.next(response);
        },
        (error) => {
          observer.error(error);
        }
      );
    });
  }
  public deleteCalculation(vid, data): Observable<any> {
    return new Observable((observer) => {
      this.restApiService.deleteCalculation(vid, data).subscribe(
        (response) => {
          observer.next(response);
        },
        (error) => {
          observer.error(error);
        }
      );
    });
  }
  public deleteConnection(vid, data): Observable<any> {
    return new Observable((observer) => {
      this.restApiService.deleteConnection(vid, data).subscribe(
        (response) => {
          observer.next(response);
        },
        (error) => {
          observer.error(error);
        }
      );
    });
  }
  public getConnectionsAvailable(vid): Observable<any> {
    return new Observable((observer) => {
      this.restApiService.getConnectionsAvailable(vid).subscribe(
        (response) => {
          observer.next(response);
        },
        (error) => {
          observer.error(error);
        }
      );
    });
  }
  public getDataloggerData(vid): Observable<any> {
    return new Observable((observer) => {
      this.restApiService.getDataloggerData(vid).subscribe(
        (response) => {
          observer.next(response);
        },
        (error) => {
          observer.error(error);
        }
      );
    });
  }
  public getCalculations(vid): Observable<any> {
    return new Observable((observer) => {
      this.restApiService.getCalculations(vid).subscribe(
        (response) => {
          observer.next(response);
        },
        (error) => {
          observer.error(error);
        }
      );
    });
  }
  public getCalculationsAvailable(vid): Observable<any> {
    return new Observable((observer) => {
      this.restApiService.getCalculationsAvailable(vid).subscribe(
        (response) => {
          observer.next(response);
        },
        (error) => {
          observer.error(error);
        }
      );
    });
  }
  public getChartsData(vid, data): Observable<any> {
    return new Observable((observer) => {
      this.restApiService.getChartsData(vid, data).subscribe(
        (response) => {
          observer.next(response);
        },
        (error) => {
          observer.error(error);
        }
      );
    });
  }
  public postChartsData(vid, data): Observable<any> {
    return new Observable((observer) => {
      this.restApiService.postChartsData(vid, data).subscribe(
        (response) => {
          observer.next(response);
        },
        (error) => {
          observer.error(error);
        }
      );
    });
  }
  public getET0Prediction(vid, data): Observable<any> {
    return new Observable((observer) => {
      this.restApiService.getET0Prediction(vid, data).subscribe(
        (response) => {
          observer.next(response);
        },
        (error) => {
          observer.error(error);
        }
      );
    });
  }
  public getFAOValue(vid: string): Observable<FAODataModel[]> {
    return new Observable((observer) => {
      this.restApiService.getFAOValue(vid).subscribe(
        (response) => {
          observer.next(response);
        },
        (error) => {
          observer.error(error);
        }
      );
    });
  }
}
