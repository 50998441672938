<lib-loader *ngIf="loader === true"></lib-loader>
<!--<app-loader *ngIf="ready === false"></app-loader>-->
<ng-container  *ngIf="ready === true && keys.length>0">
  <section class="reports-main-subcomponent-container" *ngFor="let key of keys; index as i;">
    <div class="card pb-3">
      <div class="card-header my-1">
        <div class="row">
          <div class="col-12 col-md-9">
            <app-fields-header [field]="orderedReport[key].field" [buttons]="false" *ngIf="type ==='field'"></app-fields-header>
            <lib-terminal-header [equipment]="orderedReport[key].terminal" [showButton]="false" *ngIf="type ==='terminal'"></lib-terminal-header>
            <div class="row" *ngIf="type === 'mixed' || type === 'magnitudes'">
              <div class="d-flex px-3 px-lg-0" >
                <div class="d-inline-block pe-2">
                  <img src="../../../../assets/img/Icons/UCROP_Grafica_Lineal_Personalizada.svg" width="100%">
                </div>
                <div class="d-inline-block align-content-start id-container">
                  <div class="row">
                    <div class="col-auto align-self-center mx-2">
                      <h2 class="mt-2 mb-0" style="color: #ee7869">{{orderedReport[key].name}} </h2>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-3 text-end" *ngIf="orderedReport[key].data.length>0">
              <a [matMenuTriggerFor]="menu">
                <span>{{ translationsLib.get('reports_data_tools') }}</span>
                <button mat-icon-button>
                  <ion-icon name="ellipsis-vertical-outline"></ion-icon>
                </button>
              </a>
              <mat-menu #menu="matMenu">
                <button mat-menu-item>
                  <ion-icon name="document-outline"></ion-icon>
                  <button mat-button (click)="exportToPDF()">
                    {{ translationsLib.get('highcharts_download_pdf') }}
                  </button>
                </button>
              </mat-menu>
          </div>
        </div>

      </div>
      <ng-container *ngIf="type!== 'magnitudes' && orderedReport[key].data.length>0 ">
        <div class="row mx-2 mt-3 print-no-padding" *ngFor="let reportData of orderedReport[key].data">
          <div class="col-12 p-0 p-lg-2 alarms-container print-no-padding">
            <div class="alert alert-info mb-2" role="alert" *ngIf="reportData && reportData['raw_sensor_items'].length <= 0">
              {{ translationsLib.get('reports_no_data') }}
            </div>
          </div>
          <article *ngIf="reportData && reportData['raw_sensor_items'].length > 0">
            <div class="card">
              <h2 class="card-header-title mx-4 mb-0" style="color: #66B8BE">{{reportData['chart_table_paired_items'].name}}</h2>
              <app-charts-component [viewMode]="'reports'" [reportData]="reportData"></app-charts-component>
            </div>
          </article>
        </div>
      </ng-container>
      <ng-container *ngIf="type!== 'magnitudes' && orderedReport[key].data.length===0 ">
        <div class="alert alert-info m-2 mt-3" role="alert">
          {{ translationsLib.get('reports_no_sensors_available') }}
        </div>
      </ng-container>
      <ng-container *ngIf="type === 'magnitudes'">
        <mat-tab-group (selectedTabChange)="onTabChange($event)">
          <ng-container *ngFor="let reportData of orderedReport[key].data; let i = index">
            <mat-tab *ngIf="reportData && reportData['raw_sensor_items'].length > 0"
                     label="{{translationsLib.get(reportData['chart_table_paired_items'].name)}}">
              <ng-container *ngIf="selectedTabIndex === i">
                <app-charts-component [viewMode]="'reports'" [reportData]="reportData"></app-charts-component>
              </ng-container>
            </mat-tab>
          </ng-container>
        </mat-tab-group>
      </ng-container>
    </div>
  </section>
</ng-container>
<ng-container *ngIf="ready === true && keys.length===0">
  <div class="alert alert-info mb-2" role="alert">
    {{ translationsLib.get('reports_no_data') }}
  </div>
</ng-container>
<app-reports-download-post-open-window [requestData]="this.requestData" *ngIf="this.downloadPDF === true"></app-reports-download-post-open-window>


