<!-- Current user has no permissions for at least one terminal of this field -->
<div class="row">
  <div class="col-12">
    <div class="card">
      <div class="card-header">
        <div class="card-header-title">
          <h2 class="mb-0 text-start">
            {{ translationsLib.get('field_error_terminal_title') }}
          </h2>
        </div>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-12 mb-4">
            {{ translationsLib.get('field_error_terminal_text') }}
          </div>
        </div>
        <div class="row" *ngFor="let terminal of terminalsError">
          <div class="col-12">
            <div class="card">
              <div class="card-body">
                <lib-terminal-header [equipment]="terminal"></lib-terminal-header>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
