import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-terminal-placeholder-data',
  templateUrl: './terminal-placeholder-data.component.html',
  styleUrls: ['./terminal-placeholder-data.component.scss'],
})
export class TerminalPlaceholderDataComponent implements OnInit {
  @Input() terminalType:
    | 'climate'
    | 'irrigation'
    | 'configurable'
    | 'datalogger';
  borderStyle: any;
  meteoRows = [];

  constructor() {}

  ngOnInit() {
    this.borderStyle = this.getBorderStyle();

    this.meteoRows = [
      {
        key: 'ET0',
        value: 0,
        name: 'ET0',
        unit: 'mm',
        origin: null,
      },
      {
        key: 'TEMPERATURE',
        value: 0,
        name: null,
        unit: 'º',
        origin: 'VIRTUAL',
      },
      {
        key: 'HUMIDITY',
        value: 0,
        name: null,
        unit: '%',
        origin: 'VIRTUAL',
      },
      {
        key: 'WIND_VELOCITY',
        value: 0,
        name: null,
        unit: 'Km/h',
        origin: 'VIRTUAL',
      },
      {
        key: 'WIND_DIRECTION',
        value: 0,
        name: null,
        unit: 'º',
        origin: 'VIRTUAL',
      },
      {
        key: 'RADIATION',
        value: 0,
        name: null,
        unit: 'W/m²',
        origin: 'VIRTUAL',
      },
      {
        key: 'PLUVIOMETER',
        value: 0,
        name: null,
        unit: 'l/m²',
        origin: 'VIRTUAL',
      },
    ];
  }

  getBorderStyle() {
    return {
      'irrigation-card-border': this.terminalType === 'irrigation',
      'climate-card-border': this.terminalType === 'climate',
      'configurable-card-border': this.terminalType === 'configurable',
      'datalogger-card-border': this.terminalType === 'datalogger',
    };
  }
}
