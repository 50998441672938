<span class="text-muted text-center">{{translationsLib.get('auth_select_language')}}</span>
<a *ngIf="language === 'es'" [matMenuTriggerFor]="languageMenu">
  &nbsp;<img src="assets/img/flags/es.svg" style="max-width: 15px"> Español
</a>
<a *ngIf="language === 'en'" [matMenuTriggerFor]="languageMenu">
  &nbsp;<img src="assets/img/flags/gb.svg" style="max-width: 15px"> English
</a>
<a *ngIf="language === 'fr'" [matMenuTriggerFor]="languageMenu">
  &nbsp;<img src="assets/img/flags/fr.svg" style="max-width: 15px"> Français
</a>
<a *ngIf="language === 'pt'" [matMenuTriggerFor]="languageMenu">
  &nbsp;<img src="assets/img/flags/pt.svg" style="max-width: 15px"> Português
</a>
<mat-menu #languageMenu="matMenu">
  <a mat-menu-item (click)="changeLanguage('es')">
    <img src="assets/img/flags/es.svg" style="max-width: 15px"> Español
  </a>
  <a mat-menu-item (click)="changeLanguage('en')">
    <img src="assets/img/flags/gb.svg" style="max-width: 15px"> English
  </a>
  <a mat-menu-item (click)="changeLanguage('fr')">
    <img src="assets/img/flags/fr.svg" style="max-width: 15px"> Français
  </a>
  <a mat-menu-item (click)="changeLanguage('pt')">
    <img src="assets/img/flags/pt.svg" style="max-width: 15px"> Português
  </a>
</mat-menu>
