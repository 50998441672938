import Step from 'shepherd.js/src/types/step';

export const STEPS_BUTTONS = {
  back: {
    classes: 'back-button',
    secondary: true,
    text: 'Back',
    type: 'back',
  },
  cancel: {
    classes: 'cancel-button',
    secondary: true,
    text: 'Exit',
    type: 'cancel',
  },
  next: {
    classes: 'next-button',
    text: 'Next',
    type: 'next',
  },
};

export const defaultStepOptions: Step.StepOptions = {
  classes: 'shepherd-theme-arrows custom-default-class',
  scrollTo: true,
  cancelIcon: {
    enabled: true,
  },
};

export const steps: Step.StepOptions[] = [
  {
    attachTo: {
      element: '#recipeNameAdvance',
      on: 'bottom',
    },
    buttons: [STEPS_BUTTONS.cancel, STEPS_BUTTONS.back, STEPS_BUTTONS.next],
    classes: 'custom-class-name-1 custom-class-name-2',
    id: 'recipeNameAdvance',
    title: 'Step in h1',
    text: `Content in h1 at bottom. This content can be html code as string like:
    <a href="https://www.google.com" rel="_blank">Google Link</a>
    `,
  },
  {
    attachTo: {
      element: '#phConfigurationAdvance',
      on: 'bottom',
    },
    buttons: [STEPS_BUTTONS.cancel, STEPS_BUTTONS.back, STEPS_BUTTONS.next],
    classes: 'custom-class-name-1 custom-class-name-2',
    id: 'phConfigurationAdvance',
    title: 'Step in h1',
    text: `Content in h1 at bottom. This content can be html code as string like:
    <a href="https://www.google.com" rel="_blank">Google Link</a>
    `,
  },
  {
    attachTo: {
      element: '#previousVolumeAdvance',
      on: 'bottom',
    },
    buttons: [STEPS_BUTTONS.cancel, STEPS_BUTTONS.back, STEPS_BUTTONS.next],
    classes: 'custom-class-name-1 custom-class-name-2',
    id: 'previousVolumeAdvance',
    title: 'Step in h1',
    text: `Content in h1 at bottom. This content can be html code as string like:
    <a href="https://www.google.com" rel="_blank">Google Link</a>
    `,
  },
  {
    attachTo: {
      element: '#fertilizersAdvance',
      on: 'bottom',
    },
    buttons: [STEPS_BUTTONS.cancel, STEPS_BUTTONS.back, STEPS_BUTTONS.next],
    classes: 'custom-class-name-1 custom-class-name-2',
    id: 'fertilizersAdvance',
    title: 'Step in h1',
    text: `Content in h1 at bottom. This content can be html code as string like:
    <a href="https://www.google.com" rel="_blank">Google Link</a>
    `,
  },
];
