import { Component, OnInit } from '@angular/core';
import { TranslationsLibService } from '@nutricontrol/app360-shared';

@Component({
  selector: 'app-cannot-write-alert',
  templateUrl: './cannot-write-alert.component.html',
  styleUrls: ['./cannot-write-alert.component.scss'],
})
export class CannotWriteAlertComponent implements OnInit {
  constructor(public translationsLib: TranslationsLibService) {}

  ngOnInit() {}
}
