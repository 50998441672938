import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FieldsService } from '../fields.service';
import { HelpersLibService } from '../../services/libraries/helpers-lib.service';
import { TerminalsService } from '../../terminals/terminals.service';
import {
  FieldDataModel,
  TerminalDataModel,
  TranslationsLibService,
} from '@nutricontrol/app360-shared';
import { PlatformLibService } from '@nutricontrol/app360-shared';
import { Breadcrumb } from '../../farming/irrigation/shared/shared.model';
import { MapFieldsDataModel } from '@nutricontrol/app360-shared/lib/commons/components/maps/maps-model';

@Component({
  selector: 'app-fields-general',
  templateUrl: './fields-general.component.html',
  styleUrls: ['./fields-general.component.scss'],
})
export class FieldsGeneralComponent implements OnInit {
  /*private map: google.maps.Map;*/
  fields: FieldDataModel[] = [];
  fieldsMap: MapFieldsDataModel[] = [];
  loading = true;
  breadcrumbs: Breadcrumb[] = [];
  private terminalsVid: string[] = [];
  userTerminals: TerminalDataModel[] = [];
  platform: boolean;
  constructor(
    public router: Router,
    private helpersLib: HelpersLibService,
    private fieldsService: FieldsService,
    private terminalsService: TerminalsService,
    public translationsLib: TranslationsLibService,
    private platformLib: PlatformLibService
  ) {}

  ngOnInit() {
    this.helpersLib.setTitle(
      this.translationsLib.get('field_fields'),
      this.translationsLib.get('general'),
      null
    );
    this.platform = this.platformLib.isNative();
    this.getFields();
    this.getUserTerminals();
    this.breadcrumbs = [
      {
        text: this.translationsLib.get('field_fields'),
        disabled: false,
        to: '/fields-general',
        active: false,
      },
      {
        text: this.translationsLib.get('general'),
        disabled: false,
        to: ' ',
        active: true,
      },
    ];
  }

  getUserTerminals() {
    this.terminalsService.getTerminals().subscribe((terminals) => {
      terminals.forEach((terminal) => {
        this.userTerminals.push(terminal);
      });
    });
  }

  getFields() {
    this.fieldsService.getFields().subscribe(async (res) => {
      this.fields = res;
      if (this.fields.length === 0) {
        this.loading = false;
      } else {
        this.fields.forEach((field) => {
          field.terminals_info.forEach((terminal) => {
            this.terminalsVid.push(terminal.vid);
          });
          const data = {
            method: 'get',
            terminals_vid: this.terminalsVid,
          };
          if (this.terminalsVid.length > 0) {
            this.terminalsService.getTerminalsApiInfo(data).subscribe((res) => {
              this.terminalsVid = [];
              if (!res.status) {
                res.forEach((term) => {
                  if (
                    term.states.irrigating === false &&
                    field.is_irrigating !== true
                  ) {
                    field.is_irrigating = term.states.irrigating;
                  } else {
                    field.is_irrigating = term.states.irrigating;
                  }
                });
                setTimeout(() => {
                  this.loading = false;
                }, 400);
                if (this.fields.length > 0) {
                  this.hideLoaderAndLoadMap();
                }
              } else {
                this.hideLoaderAndLoadMap();
              }
            });
          } else {
            field.is_irrigating = false;
            this.hideLoaderAndLoadMap();
          }
          const dataField = {
            field,
            allValves: null,
            terminal: null,
            terminals: null,
          };
          this.fieldsMap.push(dataField);
        });
      }
    });
  }

  get terminalsWithoutField() {
    return this.userTerminals.filter((terminal) => terminal.field === null);
  }

  hideLoaderAndLoadMap() {
    setTimeout(() => {
      this.loading = false;
    }, 1000);
  }
}
