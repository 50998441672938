<div class="mb-4 d-flex justify-content-center mt-5" *ngIf="isFetchingData">
  <div class="spinner-border text-warning" role="status">
    <span class="visually-hidden">{{
      this.translationsLib.get('irrigation_loading')
    }}</span>
  </div>
</div>

<app-dirty-message
  [conditions]="layoutConfiguration && !isFetchingData"
  [dirty]="valvesForm.value.dirty || isAnyValveDirtyAfterPost"
></app-dirty-message>

<ng-template
  [ngIf]="
    mode === 'simple' &&
    layoutConfiguration &&
    !isFetchingData &&
    !isValvesWithoutData
  "
>
  <form [formGroup]="valvesForm" class="simple-form">
    <div class="mb-2">
      <div *ngIf="layoutConfiguration.blocks.progval_Mult.show">
        <div class="card-body custom-card-body">
          <div class="row">
            <div class="col-12">
              <h6 class="fs-2">
                {{ translationsLib.get('irrigation_valve') }}
              </h6>
              <p class="fs-5 fw-lighter">
                {{
                  translationsLib.get('irrigation_valve_help') | sanitizeText
                }}
              </p>
            </div>
            <div class="col-12 col-inputs">
              <div class="row first-row">
                <div class="col-sm-12 col-md-6">
                  <h6 class="fs-5">
                    {{ translationsLib.get('irrigation_name') }}
                  </h6>
                  <div class="input-group mb-2">
                    <input
                      formControlName="name"
                      type="text"
                      class="form-control"
                      placeholder="{{ translationsLib.get('irrigation_name') }}"
                    />
                  </div>
                </div>
              </div>
              <div
                class="row"
                *ngIf="
                  layoutConfiguration.blocks.progval_Mult.fields
                    .progvalvulam3k_cabezal.show
                "
              >
                <div class="col-sm-12 col-md-6">
                  <h6 class="fs-5">
                    {{ translationsLib.get('irrigation_water_system') }}
                  </h6>
                  <div class="input-group mb-2">
                    <select class="form-select" formControlName="waterSystem">
                      <option
                        [value]="waterSystem.waterSystem"
                        *ngFor="let waterSystem of waterSystemsList"
                      >
                        {{ translationsLib.get('irrigation_water_system') }}
                        {{ waterSystem.waterSystem + 1 }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-6 col-md-6">
                  <h6 class="fs-5">
                    {{ translationsLib.get('irrigation_flow') }}
                  </h6>
                  <div class="input-group mb-2 number-input">
                    <button
                      class="minus minus-style"
                      (click)="inputNumberService.decrementValue($event)"
                    ></button>
                    <input
                      formControlName="flow"
                      type="number"
                      class="form-control"
                      placeholder="{{ translationsLib.get('irrigation_flow') }}"
                      min="-9999"
                      max="9999"
                    />
                    <button
                      class="plus border-plus plus-style"
                      (click)="inputNumberService.incrementValue($event)"
                    ></button>
                  </div>
                </div>
                <div class="col-sm-6 col-md-6">
                  <h6 class="fs-5">
                    {{ translationsLib.get('irrigation_valves_counter_pulse') }}
                  </h6>
                  <div class="input-group mb-2 number-input">
                    <button
                      class="minus minus-style"
                      (click)="inputNumberService.decrementValue($event)"
                    ></button>
                    <input
                      formControlName="counterPulse"
                      type="number"
                      class="form-control"
                      placeholder="{{
                        translationsLib.get('irrigation_valves_counter_pulse')
                      }}"
                      min="-9999"
                      max="9999"
                    />
                    <button
                      class="plus border-plus plus-style"
                      (click)="inputNumberService.incrementValue($event)"
                    ></button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</ng-template>

<ng-template
  [ngIf]="
    mode === 'advanced' &&
    layoutConfiguration &&
    !isFetchingData &&
    !isValvesWithoutData
  "
>
  <form [formGroup]="valvesForm" class="advanced-form">
    <div class="row">
      <div class="col-lg-12 col-md-12">
        <div class="card" *ngIf="layoutConfiguration.blocks.progval_Mult.show">
          <div class="card-body custom-card-body">
            <div class="row">
              <div class="col-md-12">
                <h6 class="fs-2">
                  {{ translationsLib.get('irrigation_valve') }}
                </h6>
              </div>
            </div>
            <div class="row">
              <div class="col-md-8">
                <h6 class="fs-5">
                  {{ translationsLib.get('irrigation_name') }}
                </h6>
              </div>
              <div class="col-md-4">
                <div class="input-group mb-2">
                  <input
                    formControlName="name"
                    type="text"
                    class="form-control"
                    placeholder="{{ translationsLib.get('irrigation_name') }}"
                  />
                </div>
              </div>
            </div>
            <div
              class="row"
              *ngIf="
                layoutConfiguration.blocks.progval_Mult.fields
                  .progvalvulam3k_cabezal.show
              "
            >
              <div class="col-md-8">
                <h6 class="fs-5">
                  {{ translationsLib.get('irrigation_water_system') }}
                </h6>
              </div>
              <div class="col-md-4">
                <div class="input-group mb-2">
                  <select class="form-select" formControlName="waterSystem">
                    <option
                      [value]="waterSystem.waterSystem"
                      *ngFor="let waterSystem of waterSystemsList"
                    >
                      {{ translationsLib.get('irrigation_water_system') }}
                      {{ waterSystem.waterSystem + 1 }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12 col-md-12">
                <div class="row">
                  <div class="col-md-8">
                    <h6 class="fs-5">
                      {{ translationsLib.get('irrigation_flow') }}
                    </h6>
                  </div>
                  <div class="col-md-4">
                    <div class="input-group mb-2 number-input">
                      <button
                        class="minus minus-style"
                        (click)="inputNumberService.decrementValue($event)"
                      ></button>
                      <input
                        formControlName="flow"
                        type="number"
                        class="form-control"
                        placeholder="{{
                          translationsLib.get('irrigation_flow')
                        }}"
                        min="-9999"
                        max="9999"
                      />
                      <button
                        class="plus border-plus plus-style"
                        (click)="inputNumberService.incrementValue($event)"
                      ></button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-sm-12 col-md-12">
                <div class="row">
                  <div class="col-md-8">
                    <h6 class="fs-5">
                      {{
                        translationsLib.get('irrigation_valves_counter_pulse')
                      }}
                    </h6>
                  </div>
                  <div class="col-md-4">
                    <div class="input-group mb-2 number-input">
                      <button
                        class="minus minus-style"
                        (click)="inputNumberService.decrementValue($event)"
                      ></button>
                      <input
                        formControlName="counterPulse"
                        type="number"
                        class="form-control"
                        placeholder="{{
                          translationsLib.get('irrigation_valves_counter_pulse')
                        }}"
                        min="-9999"
                        max="9999"
                      />
                      <button
                        class="plus border-plus plus-style"
                        (click)="inputNumberService.incrementValue($event)"
                      ></button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</ng-template>
