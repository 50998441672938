import {
  AfterViewInit,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { TerminalsService } from '../../terminals/terminals.service';
import { DataloggerLibService } from '../../farming/libraries/datalogger-lib.service';
import { IrrigationService } from '../../farming/irrigation/irrigation.service';
import { FormControl, FormGroup } from '@angular/forms';
import { TranslationsLibService } from '@nutricontrol/app360-shared';
import { SessionLibService } from '@nutricontrol/app360-shared';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from '@angular/material/core';
import * as moment from 'moment';
import { IonModal } from '@ionic/angular';
import { FieldsService } from '../../fields/fields.service';
import { GraphicsService } from '../../graphics/graphics.service';
import Swal from 'sweetalert2';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { GraphicsLibService } from '../../services/libraries/graphics-lib.service';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { DataloggerService } from 'src/app/farming/datalogger/datalogger.service';
import {
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
  MAT_MOMENT_DATE_FORMATS,
  MomentDateAdapter,
} from '@angular/material-moment-adapter';
@Component({
  selector: 'app-megapop',
  templateUrl: './megapop.component.html',
  styleUrls: ['./megapop.component.scss'],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'es-ES' },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
  ],
})
export class MegapopComponent implements OnInit, AfterViewInit {
  @Input() finca;
  @Input() viewMode;
  @Input() devicesCompleted = 0;
  @Input() reportsTerminals;
  @Output() dateChange: EventEmitter<MatDatepickerInputEvent<any>> =
    new EventEmitter();
  @Output() selectedSensors: EventEmitter<any> = new EventEmitter();
  @ViewChild(IonModal) modal: IonModal;
  range = new FormGroup({
    start: new FormControl<Date>(new Date()),
    end: new FormControl<Date>(new Date()),
  });
  chartGotData;
  updating = false;
  fields;
  term = '';
  panelOpenState = true;
  fieldTerminals = [];
  fieldTerminalsLoaded = false;
  allComplete = false;
  actuadoresTotales = 0;
  metricasTitle: any = 'Magnitud';
  sensores = [];
  sensoresPushed = [];
  sensoresMagnitud;
  sensoresOrdenados: { valor: any; key: any }[] = [];
  sensoresSelecteds = [];
  fieldsCompleted = [];
  chartCreated = false;
  data: any;
  displayDatepicker = false;
  oldId = null;
  savedInMainDashboard = false;
  payload;
  terminals_selected: any = [];
  loadingDevicesCount = 0;
  createdFirstTime = false;
  magnitudesOrder = [
    'METEO',
    'TEMP',
    'RH',
    'VPD',
    'RADIATION',
    'ET0',
    'WIND_SPEED',
    'WIND_DIR',
    'PLUVIOMETER',
    'PH',
    'EC',
    'SOIL_HUMIDITY',
    'SOIL_TENSION',
    'WINDOW_POS',
    'GENERIC',
    'DIGITAL',
    'WATER_TEMP',
    'AH',
    'SIGNAL',
    'HD',
    'VOLTAGE',
  ];
  savedInFIeld: any;
  savedInDevices: any;
  intervalSelected: any = 300;
  intervals: any;
  operation: string;
  wantOperation: any = null;
  savedMetrics: any = null;
  metricName: any = '';
  allDevices = [];
  sensorTypes: any = 0;
  viewEditMode = false;
  loadedDates = false;
  sensorsCharging = false;
  peticiones = [];
  reportsLoader: boolean = true;
  constructor(
    private terminalsService: TerminalsService,
    private dataloggerService: DataloggerService,
    private dataloggerLib: DataloggerLibService,
    private irrigationService: IrrigationService,
    public translationsLib: TranslationsLibService,
    private sessionLib: SessionLibService,
    private dateAdapter: DateAdapter<Date>,
    private fieldsService: FieldsService,
    private graphicsService: GraphicsService,
    private graphicsLib: GraphicsLibService,
    @Inject(MAT_DATE_LOCALE) private _locale: string
  ) {
    this._locale = sessionLib.getLanguage();
    this.dateAdapter.setLocale(sessionLib.getLanguage());
    this.dateAdapter.getFirstDayOfWeek = () => 1;
  }

  ngOnInit() {
    this.loadedDates = true;
    this.range.get('end').valueChanges.subscribe((changed) => {
      this.getIntervals();
    });
    this.getMetrics();
    if (this.viewMode === 'metrics') {
      const date1 = new Date();
      const date2 = new Date();
      date2.setDate(date1.getDate() - 1);
      this.range.patchValue({
        start: date2,
        end: date1,
      });
    }
    if(this.viewMode === 'reports' ) {
      const data = this.getDevices(this.reportsTerminals);
      this.devicesInFields(data.fields, data.devices);
      this.getSensors();
    }
    else {
      this.getFields();
      this.getTerminals();
    }
  }
  ngAfterViewInit() {
    this.setDate(2, '48hrs');
  }
  getFields() {
    this.fieldsService.getFields().subscribe((res) => {
      this.fields = res;
    });
  }
  getMetrics() {
    this.graphicsService.getSavedMetrics().subscribe((res) => {
      this.savedMetrics = res;
      this.savedMetrics.forEach((metric) => {
        metric.metrica = true;
      });
    });
  }
  getTerminals() {
    this.terminalsService.getTerminals().subscribe((res) => {
      const data = this.getDevices(res);
      this.devicesInFields(data.fields, data.devices);
      this.fieldTerminalsLoaded = true;
    });
  }
  getDevices(terminals){
    const fields = [];
    const devices = [];
    terminals.forEach((terminal) => {
      if (
        terminal.type !== 50 &&
        terminal.type !== 51 &&
        terminal.type !== 0 &&
        terminal.enabled === 1
      ) {
        if (terminal.field) {
          if (fields.length === 0) {
            fields.push(terminal.field.name);
          } else {
            if (!fields.includes(terminal.field.name)) {
              fields.push(terminal.field.name);
            }
          }
        } else {
          if (!fields.includes(this.translationsLib.get('devices_no_field'))) {
            fields.push(this.translationsLib.get('devices_no_field'));
          }
        }
        devices.push(terminal);
      }
    });
    if (fields.length === 0) {
      this.reportsLoader = false;
    }
    return {fields,devices};
  }
  devicesInFields(fields, devices) {
    fields.forEach((field) => {
      const terms = [];
      devices.forEach((terminal) => {
        /*terms.push(terminal);*/
        if (
          terminal.field &&
          field === terminal.field.name &&
          terminal.type !== 0
        ) {
          terms.push(terminal);
        } else {
          if (
            !terminal.field &&
            field === this.translationsLib.get('devices_no_field') &&
            terminal.type !== 0
          ) {
            terms.push(terminal);
          }
        }
      });
      if (terms.length > 0) {
        this.fieldTerminals.push({
          name: field,
          devices: terms,
        });
      }
    });
  }
  updateAllComplete() {
    this.updating = true;
    this.allComplete =
      this.fieldTerminals != null &&
      this.fieldTerminals.every((t) => t.completed);
    const timeout = setInterval(() => {
      if (this.sensorsCharging === false) {
        this.sensorsCharging = true;
        this.loadingDevicesCount++;
        this.getSensors();
        clearInterval(timeout);
      }
      // Force timeout
      setTimeout(() => {
        this.sensorsCharging = false;
        this.loadingDevicesCount = 0;
        clearInterval(timeout);
      }, 10000);
    }, 100);
  }
  updateAllMagnitudeComplete() {
    this.allComplete =
      this.sensores != null && this.sensores.every((t) => t.completed);
    const completeds = [];
    this.sensores.forEach((sensor) => {
      if (sensor.completed) {
        completeds.push(sensor);
      }
    });
    this.sensoresSelecteds = completeds;
    if (this.viewMode === 'reports') {
      this.selectedSensors.emit(this.sensoresSelecteds);
    }
  }
  someComplete(sensor): boolean {
    if (this.sensoresOrdenados == null) {
      return false;
    }
    /*this.updateAllMagnitudeComplete();*/
    return (
      sensor.valor.filter((t) => t.completed).length > 0 && !this.allComplete
    );
  }

  setAll(completed: boolean, sensor) {
    /*this.allComplete = completed;*/
    if (this.sensoresOrdenados == null) {
      return;
    }
    const res = sensor.valor.forEach((t) => (t.completed = completed));
    const completeds = [];
    this.sensores.forEach((sensor) => {
      if (sensor.completed) {
        completeds.push(sensor);
      }
    });
    this.sensoresSelecteds = completeds;
    if(this.viewMode === 'reports') {
      this.selectedSensors.emit(this.sensoresSelecteds);
    }
    return res;
  }
  //TENGO QUE HACER QUE ESTO PUEDA FUNCIONAR CON LOS DATOS QUE YO NECESITO
  getSensors() {
    let contador = 0;
    this.fieldTerminals.forEach((field) => {
      field.devices.forEach((device) => {
        contador++;
      });
    });
    /*const sensoresAnt = [...this.sensoresPushed];
    this.sensoresPushed = [];*/
    let completedSensors = [];
    this.sensores.forEach((sensor) => {
      if (sensor.completed) {
        completedSensors = completedSensors.concat(sensor);
      }
    });
    this.sensores = [];
    let devicesChecked = 0;
    this.fieldTerminals.forEach((field) => {
      field.devices.forEach(async (device) => {
        if (device.completed) {
          devicesChecked++;
          this.devicesCompleted++;
          if (device.source_database === 'mclima') {
            await this.terminalsService
              .getDevices(device.vid)
              .subscribe((res) => {
                this.allDevices = res;
                if (this.allDevices.length > 0) {
                  this.allDevices.forEach((actuador) => {
                    actuador.device_name = device.name;
                    actuador.device_vid = device.vid;
                    actuador.actuador = true;
                  });
                  if (!this.sensoresPushed.includes(this.allDevices)) {
                    this.sensoresPushed.push(this.allDevices);
                    this.sensores = this.sensores.concat(this.allDevices);
                  } /*else {
                  if (sensoresAnt.length> 0 && !sensoresAnt.includes(this.allDevices)) {
                    this.sensoresPushed.push(this.allDevices);
                    this.sensores = this.sensores.concat(this.allDevices);
                  }
                }*/
                }
              });
          }
          const sensoresAnt = [...this.sensoresPushed];
          this.sensoresPushed = [];
          this.irrigationService.getSensorsSubtypes(device.vid).subscribe(
            (res) => {
              const sensoresActivados = [];
              if (!('error' in res)) {
                res.forEach((sensor) => {
                  sensor.device_name = device.name;
                  sensor.device_vid = device.vid;
                  sensor.actuador = false;
                  /*
                });
                if (
                  this.sensoresPushed.length === 0 &&
                  !sensoresAnt.includes(res)
                ) {
                  this.sensoresPushed.push(res);
                  this.sensores = this.sensores.concat(res);
                } else {
                  if (!sensoresAnt.includes(res)) {
                    this.sensoresPushed.push(res);
                    this.sensores = this.sensores.concat(res);
                  }
                }*/
                  if (
                    Boolean(sensor.visible) === true ||
                    device.source_database === 'datalogger'
                  ) {
                    sensoresActivados.push(sensor);
                  }
                });
                if (
                  this.sensoresPushed.length === 0 &&
                  !sensoresAnt.includes(sensoresActivados)
                ) {
                  this.sensoresPushed.push(sensoresActivados);
                  this.sensores = this.sensores.concat(sensoresActivados);
                } else {
                  if (!sensoresAnt.includes(sensoresActivados)) {
                    this.sensoresPushed.push(sensoresActivados);
                    this.sensores = this.sensores.concat(sensoresActivados);
                  }
                }
              }
              contador--;
              if (contador === 0) {
                setTimeout(() => {
                  this.sensorsCharging = false;
                  this.sensores.forEach((sen, index) => {
                    if (sen.physicalTypeName === 'SDI12' && sen.readingPoints) {
                      sen.readingPoints.forEach((point) => {
                        point.actuador = false;
                        if (!point.device_vid) {
                          point.device_name = sen.device_name;
                          point.device_vid = sen.device_vid;
                        }
                      });
                      this.sensores = this.sensores.concat(sen.readingPoints);
                      this.deleteSensor(sen);
                    }
                  });
                  this.getSensorsByMagnitude(completedSensors);
                  setTimeout(() => {
                    this.loadingDevicesCount--;
                  }, 100);
                }, 1100);
              }
            },
            (error) => {
              this.loadingDevicesCount--;
            }
          );
        } else {
          contador--;
          if (contador === 0) {
            if (devicesChecked === 0) {
              this.devicesCompleted = 0;
            }
            this.sensorsCharging = false;
            this.sensores.forEach((sen, index) => {
              if (sen.physicalTypeName === 'SDI12' && sen.readingPoints) {
                sen.readingPoints.forEach((point) => {
                  point.actuador = false;
                  if (!point.device_vid) {
                    point.device_vid = sen.device_vid;
                  }
                });
                this.sensores = this.sensores.concat(sen.readingPoints);
                this.sensores.splice(index, 1);
              }
            });
            this.getSensorsByMagnitude(completedSensors);
            setTimeout(() => {
              this.loadingDevicesCount--;
            }, 100);
          }
        }
      });
    });
  }
  deleteSensor(sensor) {
    this.sensores.forEach((sen, index) => {
      if (sensor === sen) {
        this.sensores.splice(index, 1);
      }
    });
  }
  getSensorsByMagnitude(completedSensors) {
    this.reportsLoader = true;
    completedSensors.forEach((completed) => {
      this.sensores.forEach((sensor) => {
        if (
          sensor.ncomp === completed.ncomp &&
          sensor.ref1 === completed.ref1 &&
          sensor.ref2 === completed.ref2 &&
          sensor.device_vid === completed.device_vid &&
          this.translationsLib.get(sensor.name) === completed.name
        ) {
          sensor.completed = true;
        } else {
          if (
            !sensor.ncomp &&
            sensor.name === completed.name &&
            sensor.nent === completed.nent &&
            sensor.nexp === completed.nexp &&
            sensor.physicalType === completed.physicalType
          ) {
            sensor.completed = true;
          }
        }
      });
    });
    this.sensoresMagnitud = [];
    this.sensores.forEach((sensor) => {
      if (sensor.magnitude) {
        sensor.subtype = sensor.magnitude;
      }
      if (sensor.ncomp) {
        sensor.subtipo = sensor.subtype;
        if (Number(sensor.ncomp) === 255) {
          sensor.subtype = 'METEO';
          sensor.name = this.translationsLib.get(sensor.name);
        } else {
          sensor.name = this.translationsLib.get(sensor.name);
          sensor.subtype =
            this.translationsLib.get('graphics_COMP') +
            ' ' +
            (Number(sensor.ncomp) + 1);
        }
      }
      if (!this.sensoresMagnitud[sensor.subtype]) {
        this.sensoresMagnitud[sensor.subtype] = [sensor];
      } else {
        this.sensoresMagnitud[sensor.subtype].push(sensor);
      }
    });
    if (
      this.savedMetrics != null &&
      this.savedMetrics.length > 0 &&
      this.viewMode === 'megapop'
    ) {
      setTimeout(() => {
        this.sensores = this.sensores.concat(this.savedMetrics);
      }, 500);
      this.sensoresMagnitud.graphics_metrics = this.savedMetrics;
    } else {
      this.sensoresMagnitud.graphics_metrics = [];
    }
    this.sensoresOrdenados = Object.keys(this.sensoresMagnitud).map((key) => ({
      key: this.translationsLib.get(key),
      valor: this.sensoresMagnitud[key],
    }));
    this.checkActuadores();
    let magnitudedSensors = [];
    this.magnitudesOrder.forEach((magnitud) => {
      this.sensoresOrdenados.forEach((sensor, index) => {
        if (this.translationsLib.get(magnitud) === sensor.key) {
          magnitudedSensors.push(sensor);
          this.sensoresOrdenados.splice(index, 1);
        }
      });
    });
    magnitudedSensors = magnitudedSensors.concat(this.sensoresOrdenados);
    this.sensoresOrdenados = magnitudedSensors;
    this.reportsLoader = false;
  }
  setTitle(selected) {
    this.metricasTitle = selected;
  }
  metricsPreSend() {
    if (this.operation != null) {
      this.send();
    } else {
      Swal.fire({
        text: this.translationsLib.get('graphics_error_no_operation'),
        icon: 'error',
      });
    }
  }
  send() {
    this.chartGotData = undefined;
    this.createdFirstTime = false;
    this.chartCreated = false;
    let completeds = [];
    const sensorsToSend = [];
    this.fieldTerminals.forEach((field) => {
      field.devices.forEach((device) => {
        if (device.completed) {
          this.fields.forEach((fieldToPass) => {
            fieldToPass.terminals_info.forEach((terminal) => {
              if (terminal.vid === device.vid) {
                fieldToPass.completed = true;
                terminal.completed = true;
              }
            });
            const time = setInterval(() => {
              if (
                fieldToPass.completed &&
                !this.fieldsCompleted.includes(fieldToPass)
              ) {
                this.fieldsCompleted.push(fieldToPass);
                clearInterval(time);
              }
            }, 100);
          });
        }
      });
    });
    if (this.operation !== 'dif') {
      this.sensores.forEach((sensor) => {
        if (sensor.completed && !sensor.metrica) {
          completeds.push(sensor);
        }
      });
    } else {
      completeds = this.sensoresSelecteds;
    }
    const metricsCompleted = [];
    if (this.savedMetrics != null) {
      this.savedMetrics.forEach((metric) => {
        //esto lo hago para que las métricas cojan correctamente las fechas seleccionadas y no las que tenian guardadas previamente.
        metric.payload.forEach((devicePayload) => {
          devicePayload.begin = moment(this.range.value.start).format(
            'YYYY-MM-DD HH:mm:ss'
          );
          devicePayload.end = moment(this.range.value.end).format(
            'YYYY-MM-DD HH:mm:ss'
          );
          devicePayload.interval = Number(this.intervalSelected);
        });
        if (metric.completed === true) {
          if (metric.payload.length > 0) {
            metric.payload[0].operationName = metric.name;
          }
          metricsCompleted.push({
            vid: metric.vid,
            payload: metric.payload,
          });
        }
      });
    }
    if (completeds.length > 0 || metricsCompleted.length > 0) {
      this.panelOpenState = false;
      this.fieldTerminals.forEach((field) => {
        field.devices.forEach((device) => {
          const sensoresEquipo = [];
          const actuadoresEquipo = [];
          completeds.forEach((completed) => {
            if (device.vid === completed.device_vid) {
              if (completed.ncomp) {
                completed.deviceName = device.name;
                completed.subtype = completed.subtipo;
                completed.terminal_vid = device.vid;
                actuadoresEquipo.push(completed);
              } else {
                completed.deviceName = device.name;
                completed.terminal_vid = device.vid;
                sensoresEquipo.push(completed);
              }
            }
          });
          if (sensoresEquipo.length > 0 || actuadoresEquipo.length > 0) {
            if (
              (sensoresEquipo.length > 0 && actuadoresEquipo.length > 0) ||
              (actuadoresEquipo.length > 0 && sensoresEquipo.length === 0)
            ) {
              sensorsToSend.push({
                terminal_vid: device.vid,
                begin: moment(this.range.value.start).format(
                  'YYYY-MM-DD HH:mm:ss'
                ),
                end: moment(this.range.value.end).format('YYYY-MM-DD HH:mm:ss'),
                interval: Number(this.intervalSelected),
                coming: this.viewMode,
                operation: this.operation,
                /*operations: metricsCompleted,*/
                sensors: sensoresEquipo,
                devices: actuadoresEquipo,
              });
            }
            if (sensoresEquipo.length > 0 && actuadoresEquipo.length === 0) {
              //sensoresEquipo['terminalVid'] = device.vid;//Esto no se usa con el terminal_vid que hay ahora
              sensorsToSend.push({
                terminal_vid: device.vid,
                begin: moment(this.range.value.start).format(
                  'YYYY-MM-DD HH:mm:ss'
                ),
                end: moment(this.range.value.end).format('YYYY-MM-DD HH:mm:ss'),
                interval: Number(this.intervalSelected),
                coming: this.viewMode,
                operation: this.operation,
                /*operations: metricsCompleted,*/
                sensors: sensoresEquipo,
              });
            }
          }
        });
      });
      // @ts-ignore
      if (sensorsToSend.length === 0) {
        //por si solo mandaran la operacion, la enviamos como un sensor para que nos pueda calcular las noches, etc.
        const metricsAsSensors = [];
        metricsCompleted.forEach((metrica) => {
          metrica.payload.forEach((device) => {
            metricsAsSensors.push(device);
          });
        });
        this.data = {
          method: 'post',
          metrics: [],
          terminals: metricsAsSensors,
        };
      } else {
        this.data = {
          method: 'post',
          metrics: metricsCompleted,
          terminals: sensorsToSend,
        };
      }

      if (this.viewMode === 'megapop') {
        this.payload = {
          metrics: this.data.metrics,
          terminals: this.data.terminals,
        };
      } else {
        this.payload = sensorsToSend;
      }

      setTimeout(() => {
        this.chartCreated = true;
        this.createdFirstTime = true;
      }, 100);
    }
  }

  setDate(number, id) {
    if (this.oldId != null) {
      const oldSelected = Array.from(
        document.getElementsByClassName(
          this.oldId
        ) as HTMLCollectionOf<HTMLElement>
      );
      oldSelected.forEach((uncheck) => {
        uncheck.style.backgroundColor = '#f0f4fa';
        uncheck.style.borderColor = '#f0f4fa';
        uncheck.style.color = '#283e59';
      });
    }
    const selected = Array.from(
      document.getElementsByClassName(id) as HTMLCollectionOf<HTMLElement>
    );
    selected.forEach((check) => {
      check.style.backgroundColor = '#66B8BE';
      check.style.color = '#FFFFFF';
    });
    /*selected.style.backgroundColor = '#66B8BE';
    selected.style.color = '#FFFFFF';*/
    this.oldId = id;
    if (number != null) {
      const date1 = new Date();
      const date2 = new Date();
      date2.setDate(date1.getDate() - number);
      this.range.patchValue({
        start: date2,
        end: date1,
      });
    }
  }
  getIntervals() {
    setTimeout(() => {
      const end = new Date(this.range.value.end);
      const start = new Date(this.range.value.start);
      const diferencia =
        (end.getTime() - start.getTime()) / (1000 * 60 * 60 * 24);
      this.intervals = this.graphicsLib.getIntervalTime(diferencia);
      const firsAvailableInterval = this.intervals.find(option => option.disabled === false);
      this.intervalSelected = firsAvailableInterval.value;
    }, 200);
  }
  openSaveModal() {
    this.modal.present();
  }
  saveGraph(name, time) {
    if (name === '' || name === null) {
      Swal.fire({
        icon: 'error',
        text: this.translationsLib.get('graphics_name_required'),
        confirmButtonText: this.translationsLib.get('accept'),
      });
    } else {
      this.terminals_selected = [];
      this.payload.terminals.forEach((term) => {
        term.saved = true;
        this.terminals_selected.push(term.terminal_vid);
      });
      const data = {
        name,
        terminals_vid: this.terminals_selected,
        graph_period: time,
        payload: this.payload,
        featured_main_dashboard: this.savedInMainDashboard,
        featured_field_dashboard: this.savedInFIeld,
        featured_terminal_dashboard: this.savedInDevices,
      };
      this.graphicsService.postSaveGraph(data).subscribe((res) => {
        Swal.fire({
          icon: 'success',
          text: this.translationsLib.get('graphics_saved_graph'),
          confirmButtonText: this.translationsLib.get('accept'),
        }).then((result) => {
          if (result.isDismissed || result.isConfirmed) {
            this.modal.dismiss();
          }
        });
      });
    }
  }
  saveMetric(name) {
    if (name == '') {
      Swal.fire({
        icon: 'error',
        text: this.translationsLib.get('graphics_name_required'),
        confirmButtonText: this.translationsLib.get('accept'),
      });
    } else {
      this.terminals_selected = [];
      this.payload.forEach((term) => {
        term.saved = true;
        this.terminals_selected.push(term.terminal_vid);
      });
      const data = {
        name,
        terminals_vid: this.terminals_selected,
        payload: this.payload,
      };
      this.graphicsService.postSavedMetric(data).subscribe((res) => {
        Swal.fire({
          icon: 'success',
          text: this.translationsLib.get('graphics_saved_metric'),
          confirmButtonText: this.translationsLib.get('accept'),
        }).then((result) => {
          if (result.isDismissed || result.isConfirmed) {
            this.metricName = '';
            this.getMetrics();
            this.modal.dismiss();
            Swal.fire({
              icon: 'question',
              text: this.translationsLib.get('graphics_saved_metric_view'),
              confirmButtonText: this.translationsLib.get('accept'),
            });
          }
        });
      });
    }
  }
  drop(event: CdkDragDrop<any>) {
    moveItemInArray(
      this.sensoresSelecteds,
      event.previousIndex,
      event.currentIndex
    );
  }
  checkActuadores() {
    this.actuadoresTotales = 0;
    this.sensoresOrdenados.forEach((fieldSensor) => {
      fieldSensor.valor.forEach((sensor) => {
        if (sensor.actuador === true) {
          this.actuadoresTotales++;
        }
      });
    });
  }
}
