<div class="row ps-0 mx-0 sticky-sm-bottom">
  <div class="col-12" [ngClass]="(viewMode==='megapop_edit')?'col-md-12':'col-md-10'"*ngIf="operation!== 'dif'">
    <ng-container *ngIf="viewMode!=='megapop_edit'">
      <div class="row mb-2 mt-4" [hidden]="sensoresOrdenados.length===0" *ngIf="margin===2">
        <h2 class="mb-0 ps-0">{{translationsLib.get('graphics_selected_sensors')}}</h2>
      </div>
      <div class="row mb-2 mt-0" [hidden]="sensoresOrdenados.length===0" *ngIf="margin===4">
        <h2 class="mb-0 ps-0">{{translationsLib.get('graphics_selected_sensors')}}</h2>
      </div>
    </ng-container>
    <mat-chip-list class="mx-4 mb-4 mt-2" style="display: contents" [hidden]="sensoresOrdenados.length===0">

      <ng-container *ngIf="viewAll === false && firstFive.length>0">
        <ng-container *ngFor="let sensor of firstFive">
            <div class="row">
              <div class="col">
                <mat-chip *ngIf="sensor.completed ===true">
                  <span style="font-size: 11px">{{sensor.name}}</span><span *ngIf="grouped ===true" style="font-size:.8125rem" class="deviceText">&nbsp;(<span *ngIf="sensor.deviceName">{{sensor.deviceName}}</span><span *ngIf="sensor.device_name && !sensor.deviceName">{{sensor.device_name}}</span><span *ngIf="sensor.ncomp"> {{Number(sensor.ncomp)}}</span>)</span>
                  <button matChipRemove *ngIf="viewMode!=='megapop_edit' " (click)="changeSensor(sensor); megapop.updateAllMagnitudeComplete(); ">
                    <ion-icon style="color: #FFFFFF; opacity: 1" name="close-outline"></ion-icon>
                  </button>
                  <button matChipRemove *ngIf="viewMode==='megapop_edit' " (click)="changeSensor(sensor); megapop.updateAllMagnitudeComplete(); megapop.send()">
                    <ion-icon style="color: #FFFFFF; opacity: 1" name="close-outline"></ion-icon>
                  </button>
                </mat-chip>
              </div>
            </div>
          </ng-container>
        </ng-container>
      <ng-container *ngIf="viewAll===true">
        <ng-container *ngFor="let sensortype of sensoresOrdenados">
          <ng-container *ngFor="let sensor of sensortype.valor">
            <div class="row">
              <div class="col">
                <mat-chip *ngIf="sensor.completed ===true">
                  <span style="font-size: 11px">{{sensor.name}}</span><span *ngIf="!sensor.metrica" style="font-size: .8125rem" class="deviceText">&nbsp;(<span *ngIf="sensor.deviceName">{{sensor.deviceName}}</span><span *ngIf="sensor.device_name && !sensor.deviceName">{{sensor.device_name}}</span>{{Number(sensor.ncomp)}})</span>
                  <button matChipRemove *ngIf="viewMode!=='megapop_edit' " (click)="changeSensor(sensor); megapop.updateAllMagnitudeComplete(); ">
                    <ion-icon style="color: #FFFFFF; opacity: 1" name="close-outline"></ion-icon>
                  </button>
                  <button matChipRemove *ngIf="viewMode==='megapop_edit' " (click)="changeSensor(sensor); megapop.updateAllMagnitudeComplete(); megapop.send()">
                    <ion-icon style="color: #FFFFFF; opacity: 1" name="close-outline"></ion-icon>
                  </button>
                </mat-chip>
              </div>
            </div>
          </ng-container>
        </ng-container>
      </ng-container>
      <a style="cursor: pointer; font-size: .8125rem; padding-right: 17px" class="mat-chip mat-focus-indicator mat-primary mat-standard-chip mat-chip-with-trailing-icon _mat-animation-noopable ng-star-inserted pruebachip" (click)="viewAll=true" *ngIf="firstFive.length>0 && viewAll===false">+ {{restOfSensors}} &nbsp; <span *ngIf="restOfSensors===1">{{translationsLib.get('DL-sensor')}}</span><span *ngIf="restOfSensors>1">{{translationsLib.get('DL_sensors')}}</span></a>
      <a style="cursor: pointer; font-size: .8125rem; padding-right: 17px" class="mat-chip mat-focus-indicator mat-primary mat-standard-chip mat-chip-with-trailing-icon _mat-animation-noopable ng-star-inserted pruebachip" (click)="viewAll=false" *ngIf="firstFive.length>0 && viewAll===true">{{translationsLib.get('hide')}} &nbsp; <span class="text-lowercase">{{translationsLib.get('DL_sensors')}}</span></a>
      <a style="cursor: pointer; font-size: .8125rem; padding-right: 17px" class="mat-chip mat-focus-indicator mat-primary mat-standard-chip mat-chip-with-trailing-icon _mat-animation-noopable ng-star-inserted chipadd" (click)="megapop.chartCreated=false; megapop.panelOpenState = true" *ngIf="viewMode==='megapop_edit'"><ion-icon name="add-outline"></ion-icon>{{translationsLib.get('add')}}</a>
    </mat-chip-list>
  </div>
  <!--<ng-container *ngIf="sensoresOrdenados.length === 0">
        <h4 style="font-style: italic"> Sin sensores seleccionados</h4>
      </ng-container>-->
  <div class="col-12 col-md-10" *ngIf="operation=== 'dif' && viewMode === 'metrics'">
    <div class="row" [hidden]="sensoresOrdenados.length===0">
      <h2 class="mb-0 ps-0">{{translationsLib.get('graphics_difference_order')}}</h2>
      <span class="text-muted">{{translationsLib.get('graphics_difference_order_explanation')}}</span>
    </div>
    <mat-chip-list cdkDropList
                   cdkDropListOrientation="horizontal"
                   (cdkDropListDropped)="megapop.drop($event)"
                   class="mx-4 mb-4" [hidden]="sensoresOrdenados.length===0">
      <ng-container *ngFor="let sensor of sensoresSelecteds; index as i">
        <div class="row">
          <div class="col">
            <h6 class="mt-0 mx-2 text-uppercase text-muted">{{translationsLib.get('grphics_operation_operator')}} {{i+1}}</h6>
            <mat-chip  cdkDrag *ngIf="sensor.completed ===true">
              {{sensor.name}}
              <button matChipRemove (click)="changeSensor(sensor); megapop.updateAllMagnitudeComplete()">
                <ion-icon style="color: #FFFFFF; opacity: 1" name="close-outline"></ion-icon>
              </button>
            </mat-chip>
          </div>
        </div>
      </ng-container>1
    </mat-chip-list>
  </div>
  <div class="col-12 col-md-2 align-self-center text-end"  style="padding-right: 0px">
    <button class="btn btn-danger" (click)="megapop.send()" *ngIf="viewMode=== 'megapop'">
      {{translationsLib.get('graphics_create_graph')}}
    </button>
    <!--<button class="btn btn-danger" (click)="megapop.send()" *ngIf="viewMode=== 'megapop_edit'">
      {{translationsLib.get('graphics_update_graph')}}
    </button>-->
    <button class="btn btn-danger" (click)="megapop.metricsPreSend()" *ngIf="viewMode=== 'metrics'">
      {{translationsLib.get('graphics_preview_metric')}}
    </button>
  </div>
</div>
