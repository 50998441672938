import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AccountPermissionsComponent } from './account-permissions/account-permissions.component';
import { IonicModule } from '@ionic/angular';
import { AppCommonsModule } from '../commons/app-commons.module';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTreeModule } from '@angular/material/tree';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FieldPermissionsComponent } from './field-permissions/field-permissions.component';
import { PermissionsTableComponent } from './permission-table/permissions-table.component';
import { TerminalPermissionsComponent } from './terminal-permissions/terminal-permissions.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { AuthModule } from '../auth/auth.module';
import { MatRadioModule } from '@angular/material/radio';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { PermissionTableMainComponent } from './permission-table/permission-table-main/permission-table-main.component';
import { PermissionsTableInjectedUsersComponent } from './permission-table/permission-table-injected-users/permissions-table-injected-users.component';
import { PermissionsAdministrativeManagementComponent } from './permission-table/permission-administrative-management/permissions-administrative-management.component';
import { PermissionsTableRegularUsersComponent } from './permission-table/permission-table-regular-users/permissions-table-regular-users.component';
import { App360SharedModule } from '@nutricontrol/app360-shared';

@NgModule({
  declarations: [
    AccountPermissionsComponent,
    FieldPermissionsComponent,
    PermissionsTableComponent,
    PermissionTableMainComponent,
    PermissionsTableInjectedUsersComponent,
    PermissionsAdministrativeManagementComponent,
    PermissionsTableRegularUsersComponent,
    TerminalPermissionsComponent,
  ],
  exports: [
    FieldPermissionsComponent,
    TerminalPermissionsComponent,
    AccountPermissionsComponent,
    PermissionsTableComponent,
  ],
  imports: [
    CommonModule,
    IonicModule,
    AppCommonsModule,
    MatExpansionModule,
    MatTabsModule,
    MatTreeModule,
    MatButtonModule,
    MatTooltipModule,
    MatAutocompleteModule,
    MatInputModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    AuthModule,
    MatRadioModule,
    MatIconModule,
    MatMenuModule,
    App360SharedModule,
  ],
})
export class PermissionsModule {}
