import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { OrganizationDataModel } from '@nutricontrol/app360-shared';

@Injectable({
  providedIn: 'root',
})
export class OrganizationsService {
  private host = environment.backend;
  constructor() {}
  public getOrganizationAvatarLogo(organizationVid: string): string {
    return this.host + '/organizations/' + organizationVid + '/avatar-logo';
  }
  parsedOrganizations(organizations: OrganizationDataModel[]): OrganizationDataModel[] {
    organizations.forEach((organization, index) => {
      organizations[index] = this.parseOrganization(organization);
    });
    return organizations;
  }
  public parseOrganization(organization: OrganizationDataModel): OrganizationDataModel {
    if (organization.avatar_logo && organization.avatar_logo.trim() !== '') {
      organization.avatar_logo = this.getOrganizationAvatarLogo(
        organization.vid
      );
    }
    return organization;
  }
}
