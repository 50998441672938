import { Injectable } from '@angular/core';
import { Device } from '@capacitor/device';
import { environment } from '../../../environments/environment';
import { RegisterPushTokenDataModel } from '../../auth/auth.model';

@Injectable({
  providedIn: 'root',
})

// @ts-ignore
export class DeviceLibService {
  async getInfo() {
    const info = await Device.getInfo();
    const id = await Device.getId();
    const idString = id.identifier;
    const data: RegisterPushTokenDataModel = {
      device_id: idString,
      os: info.platform,
      push_token: '',
      os_version: info.osVersion,
      model: info.model,
      app_version: environment.timeStamp,
      build_number: '',
      language: environment.language,
    };
    return data;
  }
}
