import { Injectable } from '@angular/core';
import Swal from 'sweetalert2';
import { TranslationsLibService } from '@nutricontrol/app360-shared';
import { Observable, Subject } from 'rxjs';
import { FormArray, FormControl, FormGroup } from '@angular/forms';

@Injectable({
  providedIn: 'root',
})
export class FormFieldHandlerService {
  constructor(public translationsLib: TranslationsLibService) {}

  /** IMPORTANT:
   * In some cases you will have to remove the 'Validators.required'
   * from the 'startReference' and 'endReference' keys in the form builder.
   * This is a well known error due to the start/end reference component
   * uses 'null' possible as a value.
   */

  private formFieldErrorSubject: Subject<boolean> = new Subject<boolean>();
  formFieldError$: Observable<boolean> =
    this.formFieldErrorSubject.asObservable();

  showErrorsAndAlerts(form): void {
    const errors = this.getFormValidationErrors(form);

    if (errors.length > 0) {
      setTimeout(() => {
        this.formFieldErrorSubject.next(true);
      }, 0);

      let errorMessage =
        this.translationsLib.get('required_fields_error') + ': ';

      errors.forEach((error, index) => {
        errorMessage += `${error.key}${index < errors.length - 1 ? ',' : ''}\n`;
      });

      this.showAlert(errorMessage, 'error');
    } else {
      this.showAlert(this.translationsLib.get('no_errors_found'), 'success');
    }
  }

  getFormValidationErrorsNested(formGroup: FormGroup | FormArray): any[] {
    const errorsArray = [];

    Object.keys(formGroup.controls).forEach((key) => {
      const control = formGroup.get(key);

      if (control instanceof FormGroup || control instanceof FormArray) {
        const nestedErrors = this.getFormValidationErrorsNested(control);
        if (nestedErrors.length > 0) {
          errorsArray.push({ key, nestedErrors });
        }
      } else if (control.errors !== null) {
        errorsArray.push({ key, controlErrors: control.errors });

        if (control instanceof FormGroup || control instanceof FormArray) {
          const nestedControlErrors =
            this.getFormValidationErrorsNested(control);
          if (nestedControlErrors.length > 0) {
            errorsArray.push(
              ...nestedControlErrors.map((nestedError) => ({
                key: `${key}.${nestedError.key}`,
                nestedErrors: nestedError.nestedErrors,
              }))
            );
          }
        }
      }
    });

    return errorsArray;
  }

  showInvalidDateOrNullsPopUp(invalidFields) {
    if (invalidFields.length > 0) {
      setTimeout(() => {
        this.formFieldErrorSubject.next(true);
      }, 0);

      let errorMessage =
        this.translationsLib.get('required_fields_error') + ': ';

      invalidFields.forEach((fieldWithError, index) => {
        errorMessage += `${fieldWithError}${
          index < invalidFields.length - 1 ? ',' : ''
        }\n`;
      });

      this.showAlert(errorMessage, 'error');
    }
  }

  checkInvalidDate(form): { hasInvalid: boolean; invalidFields: string[] } {
    let hasInvalid = false;
    const invalidFields: string[] = [];

    Object.keys(form.controls).forEach((key) => {
      const control = form.get(key);

      if (control.value == 'Invalid Date') {
        hasInvalid = true;
        invalidFields.push(key);
      } else if (control instanceof FormGroup) {
        const recursiveResult = this.checkInvalidDateRecursive(control);
        hasInvalid = hasInvalid || recursiveResult.hasInvalid;
        if (recursiveResult.invalidFields.length > 0) {
          invalidFields.push(
            ...recursiveResult.invalidFields.map((field) => `${key}.${field}`)
          );
        }
      }
    });

    return { hasInvalid, invalidFields };
  }

  checkInvalidDateRecursive(formGroup: FormGroup): {
    hasInvalid: boolean;
    invalidFields: string[];
  } {
    let hasInvalid = false;
    const invalidFields: string[] = [];

    Object.keys(formGroup.controls).forEach((key) => {
      const control = formGroup.get(key);

      if (control.value == 'Invalid Date') {
        hasInvalid = true;
        invalidFields.push(key);
      } else if (control instanceof FormGroup) {
        const recursiveResult = this.checkInvalidDateRecursive(control);
        hasInvalid = hasInvalid || recursiveResult.hasInvalid;
        if (recursiveResult.invalidFields.length > 0) {
          invalidFields.push(
            ...recursiveResult.invalidFields.map((field) => `${key}.${field}`)
          );
        }
      }
    });

    return { hasInvalid, invalidFields };
  }

  private getFormValidationErrors(form): any[] {
    const errorsArray = [];

    Object.keys(form.controls).forEach((key) => {
      const controlErrors = form.get(key).errors;

      if (controlErrors != null) {
        const keyError = Object.keys(controlErrors)[0];

        if (keyError) {
          errorsArray.push({ key, keyError });
        }
      }
    });

    return errorsArray;
  }

  genericAlert(message: string, icon: 'success' | 'error'): void {
    setTimeout(() => {
      this.formFieldErrorSubject.next(true);
    }, 0);

    this.showAlert(message, icon);
  }

  private showAlert(message: string, icon: 'success' | 'error'): void {
    Swal.fire({
      text: message,
      showConfirmButton: true,
      confirmButtonText: this.translationsLib.get('accept'),
      icon,
    });
  }

  checkNullValues(form): { hasNullValues: boolean; nullValueFields: string[] } {
    let hasNullValues = false;
    const nullValueFields: string[] = [];

    Object.keys(form.controls).forEach((key) => {
      const control = form.get(key);

      if (Array.isArray(control.value)) {
        control.value.forEach((item, index) => {
          Object.keys(item).forEach((nestedKey) => {
            const nestedControl = control.get([index, nestedKey]);

            if (
              nestedControl.value === null &&
              !(nestedKey === 'startReference' || nestedKey === 'endReference')
            ) {
              hasNullValues = true;
              const fieldPath = `${key}[${index}].${nestedKey}`;
              nullValueFields.push(this.getLastSegment(fieldPath));
            }
          });
        });
      } else if (
        control.value === null &&
        key !== 'isDirty' &&
        key !== 'startReference' &&
        key !== 'endReference'
      ) {
        hasNullValues = true;
        nullValueFields.push(this.getLastSegment(key));
      } else if (
        control instanceof FormGroup &&
        key !== 'variable1' &&
        key !== 'variable2'
      ) {
        const recursiveResult = this.checkNullValuesRecursive(control);
        hasNullValues = hasNullValues || recursiveResult.hasNullValues;
        if (recursiveResult.nullValueFields.length > 0) {
          nullValueFields.push(
            ...recursiveResult.nullValueFields.map((field) => `${key}.${field}`)
          );
        }
      }
    });

    return { hasNullValues, nullValueFields };
  }

  checkNullValuesRecursive(formGroup: FormGroup): {
    hasNullValues: boolean;
    nullValueFields: string[];
  } {
    let hasNullValues = false;
    const nullValueFields: string[] = [];

    Object.keys(formGroup.controls).forEach((key) => {
      const control = formGroup.get(key);

      if (Array.isArray(control.value)) {
        control.value.forEach((item, index) => {
          Object.keys(item).forEach((nestedKey) => {
            const nestedControl = control.get([index, nestedKey]);

            if (
              nestedControl.value === null &&
              !(nestedKey === 'startReference' || nestedKey === 'endReference')
            ) {
              hasNullValues = true;
              const fieldPath = `${key}[${index}].${nestedKey}`;
              nullValueFields.push(this.getLastSegment(fieldPath));
            }
          });
        });
      } else if (
        control.value === null &&
        key !== 'isDirty' &&
        key !== 'startReference' &&
        key !== 'endReference'
      ) {
        hasNullValues = true;
        nullValueFields.push(this.getLastSegment(key));
      } else if (
        control instanceof FormGroup &&
        key !== 'variable1' &&
        key !== 'variable2'
      ) {
        const recursiveResult = this.checkNullValuesRecursive(control);
        hasNullValues = hasNullValues || recursiveResult.hasNullValues;
        if (recursiveResult.nullValueFields.length > 0) {
          nullValueFields.push(
            ...recursiveResult.nullValueFields.map((field) => `${key}.${field}`)
          );
        }
      }
    });

    return { hasNullValues, nullValueFields };
  }

  getLastSegment(path: string): string {
    const segments = path.split('.');
    return segments[segments.length - 1];
  }

  // Use this function to check errors in forms with tabs
  // for example, transport group
  // When the error shows up, simple remove its 'Validators.required'
  getFormValidationErrorsInFormsWithTabs(form): any[] {
    const errorsArray = [];

    Object.keys(form.controls).forEach((key) => {
      const control = form.get(key);

      if (control instanceof FormGroup || control instanceof FormArray) {
        const nestedErrors = this.getFormValidationErrors(control);
        errorsArray.push(...nestedErrors.map((error) => `${key}.${error.key}`));
      } else {
        const controlErrors = control.errors;

        if (controlErrors != null) {
          const keyError = Object.keys(controlErrors)[0];

          if (keyError) {
            errorsArray.push({ key, keyError });
          }
        }
      }
    });

    return errorsArray;
  }
}
