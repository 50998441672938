import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterByPropertyIrrigation',
})
export class FilterByPropertyIrrigationPipe implements PipeTransform {
  transform<T>(
    array: T[],
    property: string = '',
    searchText: string,
    includeText = '',
    isIndex = false
  ): T[] {
    if (array && array.length) {
      return array.filter((item, index) => {
        let propValue = includeText
          ? `${includeText} ${index + 1}`
          : item[property];

        if (propValue === null) propValue = '';

        if (typeof propValue === 'string' || typeof propValue === 'number') {
          return propValue
            .toString()
            .toLowerCase()
            .includes(searchText.toLowerCase());
        } else {
          return false;
        }
      });
    } else {
      return array;
    }
  }
}
