import { Injectable } from '@angular/core';
import { PushNotifications } from '@capacitor/push-notifications';
import { DeviceLibService } from './device-lib.service';
import { RestApiService } from '../api/rest-api.service';

@Injectable({
  providedIn: 'root',
})
export class NotificationsLibService {
  constructor(private device: DeviceLibService, private api: RestApiService) {}
  async addListeners() {
    await PushNotifications.addListener('registration', async (token) => {
      const data = await this.device.getInfo();
      data.push_token = token.value;
      sessionStorage.setItem('notifications_data', JSON.stringify(data));
    });

    await PushNotifications.addListener('registrationError', (err) => {
      console.error('Registration error: ', err.error);
    });

    await PushNotifications.addListener(
      'pushNotificationReceived',
      (notification) => {
        console.log('Push notification received: ', notification);
      }
    );

    await PushNotifications.addListener(
      'pushNotificationActionPerformed',
      (notification) => {
        console.log(
          'Push notification action performed',
          notification.actionId,
          notification.inputValue
        );
      }
    );
  }
  async registerNotifications() {
    let permStatus = await PushNotifications.checkPermissions();

    if (permStatus.receive === 'prompt') {
      permStatus = await PushNotifications.requestPermissions();
    }

    if (permStatus.receive !== 'granted') {
      throw new Error('User denied permissions!');
    }

    await PushNotifications.register();
  }

  async getDeliveredNotifications() {
    const notificationList =
      await PushNotifications.getDeliveredNotifications();
    console.log('delivered notifications', notificationList);
  }
}
