<div class="row justify-content-center">
        <div class="col-12 col-md-8 col-lg-6 col-xl-4 px-lg-6 my-5 align-self-center">
          <div id="login-loader">
            <div class="spinner-container">
              <div class="spinner-logo text-center">
                <img src="../../../assets/img/ucrop.svg" alt="LOGO"  style="max-width: 200px">
              </div>
            </div>
          </div>
          <!-- PREVIOUSLY REGISTERED BUT NOT FINISHED -->
          <section *ngIf="validate===false">
            <h2 class="text-center mb-3">
              {{translationsLib.get('auth_login_error_user_unconfirmed_put_email')}}
            </h2>
            <div class="text-center">
              <form [formGroup]="emailSend" (submit)="resendCode()">
                <!--<label class="text-center" >Introduzca el correo</label>-->
                <div class="input-group">
                  <input type="email" class="form-control" placeholder="{{translationsLib.get('auth_email')}}" formControlName="email" >
                  <button type="submit" class="btn btn-primary"  [disabled]="emailSend.invalid">{{translationsLib.get('auth_send_mail')}}</button>
                </div>
              </form>
            </div>
          </section>

          <!-- JUST REGISTERED, CONFIRM IT! -->
          <section *ngIf="validate===true">
            <h2 class="text-center mb-3">
              {{translationsLib.get('auth_login_error_user_unconfirmed')}}
            </h2>

            <!-- Subheading -->
            <p class="text-muted text-center mb-5">
              {{translationsLib.get('auth_forgotten_enter_code_text')}}
            </p>
          </section>

          <br>
          <code-input id="codeInput"
                      inputType="text"
                      [hidden]="validate===false"
                      [isCodeHidden]="false"
                      [codeLength]="5"
                      (codeCompleted)="confirmRecentlyRegistration($event)" #code>
          </code-input>
          <br>
          <button type="submit"class="btn btn-lg w-100 btn-primary mb-3" (click)="confirmRecentlyRegistration(code)" *ngIf="validate===true">
            {{translationsLib.get('auth_forgotten_new_password_validate')}}
          </button>
          <div class="text-center" *ngIf="validate===true">
            <span class="text-muted text-center">
              {{translationsLib.get('auth_no_mail_yet')}} <a style="cursor: pointer; color:#3d6493" (click)="resend = true">{{translationsLib.get('auth_resend_mail')}}</a>.
            </span>
          </div>
          <br *ngIf="resend === true && validate===true">
          <div class="text-center" *ngIf="resend === true && validate===true">
            <form [formGroup]="emailSend" (submit)="resendCode()">
              <!--<label class="text-center" >Introduzca el correo</label>-->
              <div class="input-group">
                <input type="email" class="form-control" placeholder="{{translationsLib.get('auth_email')}}" formControlName="email" >
                <button type="submit"class="btn btn-primary"  [disabled]="emailSend.invalid">{{translationsLib.get('auth_resend_mail')}}</button>
              </div>
            </form>
          </div>
          <br *ngIf="resend === true">
          <p class="text-center">
            <span class="text-muted text-center">
              <a style="cursor: pointer; color:#3D6493" (click)="router.navigate(['/register']);">{{ translationsLib.get('auth_return_register') }}</a>.
            </span>
          </p>

          <p class="text-center">
            <span class="text-muted text-center">
              {{ translationsLib.get('auth_login_go_to_main_screen') }}
              <a style="cursor: pointer; color:#3d6493" (click)="router.navigate(['/login']);">
                {{ translationsLib.get('auth_login_sign_up_text') }}
              </a>
            </span>
          </p>

        </div>
        <div class="col-12 col-md-7 col-lg-6 col-xl-8 d-none d-lg-block">
          <!-- Image -->
          <div class="bg-cover h-100 min-vh-100 mt-n1 me-n3" style="background-image: url(assets/img/covers/Cover_login.jpg);"></div>
        </div>
</div>
