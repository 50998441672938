import { DragDropModule } from '@angular/cdk/drag-drop';
import { NO_ERRORS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { MatTabsModule } from '@angular/material/tabs';
import { MatBadgeModule } from '@angular/material/badge';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatOptionModule } from '@angular/material/core';
import { TerminalDashboardComponent } from './terminal-dashboard/terminal-dashboard.component';
import { ProgramsComponent } from './programs/programs.component';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { MatExpansionModule } from '@angular/material/expansion';
import { GroupsComponent } from './groups/groups.component';
import { MatSelectModule } from '@angular/material/select';
import { MatChipsModule } from '@angular/material/chips';
import { MatRadioModule } from '@angular/material/radio';
import { FarmingModule } from '../farming.module';
import { ConfigurableRoutingModule } from './configurable-routing.module';
import { AppCommonsModule } from 'src/app/commons/app-commons.module';
import { ConfigurableStatesComponent } from './configurable-states/configurable-states.component';
import { NotificationsModule } from '../../notifications/notifications.module';
import { IrrigationModule } from '../irrigation/irrigation.module';
import { App360BillingSharedModule } from '@nutricontrol/app360-billing-shared';
import { ConfigurableWaterSystemComponent } from './configurable-water-system/configurable-water-system.component';
import { ConfigurableWaterSystemsModule } from './configurable-water-system/configurable-water-systems.module';

@NgModule({
  declarations: [
    TerminalDashboardComponent,
    ProgramsComponent,
    GroupsComponent,
    ConfigurableWaterSystemComponent,
  ],
  schemas: [NO_ERRORS_SCHEMA],
  imports: [
    ConfigurableRoutingModule,
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    IonicModule,
    MatTabsModule,
    MatBadgeModule,
    MatTooltipModule,
    MatFormFieldModule,
    MatInputModule,
    MatAutocompleteModule,
    MatSelectModule,
    MatOptionModule,
    MatExpansionModule,
    MatChipsModule,
    MatRadioModule,
    DragDropModule,
    TimepickerModule,
    PopoverModule,
    FarmingModule,
    AppCommonsModule,
    ConfigurableStatesComponent,
    NotificationsModule,
    IrrigationModule,
    App360BillingSharedModule,
    ConfigurableWaterSystemsModule,
  ],
  exports: [ConfigurableWaterSystemComponent],
})
export class ConfigurableModule {}
