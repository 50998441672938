<div class="row mx-1 mb-4">
  <div class="d-flex">
    <img src="../../../../assets/img/Icons/Icono_UCROP_Grafica_Metrica.svg" style="max-height: 60px">
    <div class="row">
      <a class="mx-2 mb-0" style="font-weight: bold; color: #EE7869; font-size: 18px; line-height: 10px">{{translationsLib.get('graphics_metrics')}}</a>
      <span class="mx-2 text-muted" style="font-size: 14px; line-height: 8px">
        {{translationsLib.get('DL_sensors')}} :
        <span *ngFor="let sensor of graphic.sensors; index as i">
          <span *ngIf="i>0">/</span>
          {{sensor.name}}
        </span>
      </span>
      <span class="mx-2 text-muted" style="font-size: 14px">
        {{translationsLib.get('graphics_operations')}} :
        <span>
         {{ translationsLib.get(graphic.operation)}}
        </span>
      </span>
    </div>
  </div>
</div>

