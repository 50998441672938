<ng-container *ngIf="injectedRegularUsers.length > 0">
  <div class="card">
    <div class="card-header p-0">
      <div class="card-header-title">
        <div class="row">
          <div class="col-12">
            <h1 class="text-start">{{ translationsLib.get('permission_injected_users') }}</h1>
          </div>
        </div>
      </div>
    </div>
    <div class="card-body p-0">

      <!-- INJECTED USERS TABLE -->
      <table class="table table-responsive-sm card-table"  *ngIf="injectedRegularUsers.length > 0">
        <thead>
        <tr>
          <th class="col-7 px-0 py-3">
            {{ translationsLib.get('permission_user_s') }}
          </th>
          <th class="col-3 text-center px-0 py-3">
            {{ translationsLib.get('permission_permissions') }}
          </th>
          <th class="col-2 text-end px-0 py-3">
            {{ translationsLib.get('permission_injection_reason') }}
          </th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let user of injectedRegularUsers">
          <td style="padding-left: 0px !important; color: #444444; font-size: 15px" class="text-start">
            <b>
              {{user.name}} {{user.surname}}<br>
              <span class="text-muted text-break">{{ user.email }}</span>
            </b>
          </td>
          <td class="text-center">
            {{ this.permissionsTerminal.getPermissionText(user.permission_regular_user.permission || 0) }}
          </td>
          <td  class="p-0 text-center">
            {{ this.permissionsTerminal.getPermissionText(user.permission_regular_user.injection_reason || '') }}
          </td>
        </tr>
        </tbody>
      </table>

    </div>
  </div>
</ng-container>
