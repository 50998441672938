<div class="card h-100 py-2 px-3">
  <div class="text-nowrap text-uppercase fs-5 fw-bold text-center bg-dark-soft"
       style="color: var(--primary-color)">
    {{ translationsLib.get('irrigation_drainage') }} {{ field.drainage }}
  </div>
  <table class="table drainage-table text-center align-middle drainage-table fs-5">
    <tbody>
    <tr>
      <td colspan="2" class="titles border-right">{{ translationsLib.get('irrigation_activations') }}</td>
      <td>{{ field.activations }}</td>
    </tr>
    <tr>
      <td class="titles"></td>
      <td class="titles border-right">{{ translationsLib.get('last') }}</td>
      <td class="titles">{{ translationsLib.get('calendar_today') }}</td>
    </tr>
    <tr>
      <td class="titles border-right">{{ translationsLib.get('irrigation_drainage') }}</td>
      <td class="border-right">{{ field.lastDrainage }}%</td>
      <td>{{ field.todayDrainage }}%</td>
    </tr>
    <tr>
      <td class="titles border-right">{{ translationsLib.get('irrigation_irrigated') }}</td>
      <td class="border-right">{{ field.lastIrrigation }}L</td>
      <td>{{ field.todayIrrigation }}L</td>
    </tr>
    <tr>
      <td class="titles border-right">{{ translationsLib.get('irrigation_drained') }}</td>
      <td class="border-right">{{ field.lastDrained }}L</td>
      <td>{{ field.todayDrained }}L</td>
    </tr>
    <tr>
      <td class="titles border-right">{{ translationsLib.get('reports_pulses') }}</td>
      <td class="border-right">{{ field.lastPulse }}</td>
      <td>{{ field.todayPulse }}</td>
    </tr>
    <tr>
      <td colspan="2" class="titles border-right">{{ translationsLib.get('accumulated_radiation')}}</td>
      <td>{{ field.accumulatedRadiation }}Wh/m²</td>
    </tr>
    <tr>
      <td class="titles border-right">{{ translationsLib.get('irrigation_cut') }}</td>
      <td class="titles border-right">{{ translationsLib.get('previous') }}</td>
      <td class="titles">{{ translationsLib.get('irrigation_correction') }}</td>
    </tr>
    <tr>
      <td class="border-right">{{ field.cutRadiation }}Wh/m²</td>
      <td class="border-right">{{ field.previousRadiation }}Wh/m²</td>
      <td>{{ field.correcRadiation }}Wh/m²</td>
    </tr>
    </tbody>
  </table>
</div>
