import { Component, Input, OnInit } from '@angular/core';
import { CreatedReportModel } from '../reports.model';
import { TimeLibService } from '../../services/libraries/time-lib.service';
import * as moment from 'moment/moment';
import { TranslationsLibService } from '@nutricontrol/app360-shared';

@Component({
  selector: 'app-reports-consumptions',
  templateUrl: './reports-consumptions.component.html',
  styleUrls: ['./reports-consumptions.component.scss'],
  animations: [],
})
export class ReportsConsumptionsComponent implements OnInit {
  @Input() createdReport: CreatedReportModel;
  advancedModeIsAllowed = false;
  begin: string;
  end: string;
  prettyBegin: string;
  prettyEnd: string;
  constructor(
    private timeLib: TimeLibService,
    public translationsLib: TranslationsLibService
  ) {}
  ngOnInit() {
    this.begin = this.timeLib.beginDate(
      this.createdReport.dateRange.start,
      0,
      'days'
    );
    this.end = this.timeLib.endDate(
      this.createdReport.dateRange.end,
      0,
      'days'
    );
    this.prettyBegin = moment(this.createdReport.dateRange.start).format(
      'DD/MM/YYYY'
    );
    this.prettyEnd = moment(this.createdReport.dateRange.end).format(
      'DD/MM/YYYY'
    );
    if (
      moment(this.createdReport.dateRange.start).isSame(
        this.createdReport.dateRange.end,
        'day'
      )
    ) {
      this.advancedModeIsAllowed = true;
    }
  }
}
