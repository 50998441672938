import { Component, Input, OnInit } from '@angular/core';
import { SessionLibService } from '@nutricontrol/app360-shared';
import { TranslationsLibService } from '@nutricontrol/app360-shared';

@Component({
  selector: 'app-auth-config',
  templateUrl: './auth-config.component.html',
  styleUrls: ['./auth-config.component.scss'],
})
export class AuthConfigComponent implements OnInit {
  userVid: string;
  @Input() show2faConfig = true;
  @Input() show2faLog = true;

  constructor(
    public translationsLib: TranslationsLibService,
    public sessionLib: SessionLibService
  ) {}

  ngOnInit(): void {
    this.userVid = this.sessionLib.getSessionUserVid();
  }
}
