import { Component, Input, ViewEncapsulation } from '@angular/core';
import { WaterSystemFieldProgress } from '../../models/water-system.model';
import { TranslationsLibService } from '@nutricontrol/app360-shared';

@Component({
  selector: 'app-field-progress',
  templateUrl: './field-progress.component.html',
  styleUrls: ['./field-progress.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class FieldProgressComponent {
  @Input() field: WaterSystemFieldProgress;
  @Input() isMobile = false;
  protected progressBarsConfig = {
    behaviour: 'none',
    margin: 0,
    connect: [true, true, true, true],
    min: 0,
  };

  constructor(protected translationsLib: TranslationsLibService) {
    //
  }
}
