<div class="mx4">
  <div class="col-12 py-5" *ngIf="loading">
    <section class="py-5">
      <lib-loader></lib-loader>
    </section>
  </div>
  <div class="col-12" *ngIf="!loading">
    <!-- MAIN FORM -->
    <section>
      <div class="card">
        <div class="row justify-content-between align-items-center">
          <div class="col">
            <div class="row align-items-center">
              <div class="col-auto">

                <!-- Avatar -->
                <div style="height: 45px; width: 45px">
                  <text-avatar id="textavatar"  [text]="avatar['name']" [color]="avatar['background']" [textColor]="avatar['color']"></text-avatar>
                </div>
              </div>
              <div class="col ms-n2">

                <!-- Heading -->
                <h4 class="mb-1">
                  {{ translationsLib.get('profile_account_your_avatar') }}
                </h4>

                <!-- Text -->
                <small class="text-muted">
                  {{ translationsLib.get('profile_account_your_avatar') }}
                </small>

              </div>
            </div> <!-- / .row -->
          </div>
          <div class="col-auto">
            <!-- Button -->
            <button type="button"  class="btn btn-sm" style="background-color: #6f9ad1; color: white" [disabled]="changeAvatar === true" (click)="changeAvatar = true">
              {{ translationsLib.get('change') }}
            </button>
          </div>
        </div> <!-- / .row -->
        <div class="row justify-content-between align-items-center" *ngIf="changeAvatar">
          <div class="col-12">
            <hr class="mt-4 mb-3">
          </div>
          <div class="col-12 col-md-10">
            <div class="row align-items-center">
              <div class="col-auto"  id="circuloAvatar">

                <!-- Avatar -->
                <div id="avatarSize">
                  <text-avatar id="modifyavatar"  [text]="avatar['name']" [color]="avatar['background']" [textColor]="avatar['color']"></text-avatar>
                  <!-- Text -->
                </div>

              </div>
              <div class="col" style="padding-left: 0.3em;">
                <h4 class="mb-1">
                  {{ translationsLib.get('profile_account_customize_your_avatar') }}
                </h4>
                <div class="row">
                  <div class="col-4" >

                    <!-- Heading -->
                    <small class="text-muted">
                      {{ translationsLib.get('profile_account_avatar_background_color') }}
                    </small>

                    <input readonly id="colorpicker" class="form-control text-muted" [(colorPicker)]="color" [value]= "color" (colorPickerChange)="setBgColor()">

                  </div>
                  <div class="col-4" >
                    <!-- Heading -->
                    <small class="text-muted">
                      {{ translationsLib.get('profile_account_avatar_text_color') }}
                    </small>

                    <input readonly id="colorpicker" class="form-control text-muted" [(colorPicker)]="textcolor" [value]= "textcolor" (colorPickerChange)="setTextColor()">

                  </div>
                </div>
              </div>

            </div> <!-- / .row -->
          </div>
          <div class="col col-md-2" style="text-align: end" id="colorFondo">
            <!-- Button -->
            <button type="button"  class="btn btn-sm btn-primary" (click)="changeAvatar = false; refreshAvatar();">
              {{ translationsLib.get('save') }}
            </button>

          </div>

        </div>

      </div>
      <!-- Divider -->
      <hr class="my-5">
      <div class="card">
        <form novalidate [formGroup]="editData" (submit)="onSubmit()">

          <div class="card p-0">
            <div class="card-body">
              <div class="row">
                <div class="col-12 col-md-6">
                  <div class="row">
                    <div class="col-12">
                      <label class="form-label">
                        {{ translationsLib.get('profile_account_email') }}:
                      </label>
                      <span id="email">
                    {{editData.value.email}}
                  </span> &nbsp;
                    </div>
                    <div class="col-12">
                  <span class="text-muted">
                    {{translationsLib.get('email_non_editable')}}
                  </span>
                    </div>
                  </div>
                </div>
                <div class="col-12 mt-2 mt-md-0 col-md-6">
                  <div class="row">
                    <div class="col-12">
                      <label class="form-label">
                        {{ translationsLib.get('profile_user_type') }}:
                      </label>
                      <span>
                    {{userTypeText}}
                  </span> &nbsp;
                    </div>
                    <div class="col-12">
                  <span class="text-muted">

                  </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-12 col-md-6">

              <!-- First name -->
              <div class="form-group">
                <label class="form-label d-block">
                  <span>{{ translationsLib.get('profile_account_name') }}</span>
                </label>

                <input id="name" type="text" class="form-control" formControlName="name" [value]="editData.value.name">

              </div>

            </div>
            <div class="col-12 col-md-6">
              <!-- Last name -->
              <div class="form-group">
                <label class="form-label d-block">
                  <span>{{ translationsLib.get('profile_account_surname') }}</span>
                  <small> {{ translationsLib.get('not_mandatory') }} </small>
                </label>
                <input id="surname" type="text" class="form-control" formControlName="surname" [value]="editData.value.surname">

              </div>

            </div>
            <div class="col-12 col-md-6" *ngIf="this.userIsInstaller === true">
              <!-- Birthday -->
              <div class="form-group">
                <!-- Label -->
                <label class="form-label d-block">
                  <span>{{ translationsLib.get('profile_account_business_name') }}</span>
                  <small> {{ translationsLib.get('not_mandatory') }} </small>
                </label>
                <!-- Input -->
                <input type="text" class="form-control" formControlName="company_name" [value]="editData.value.company_name">
              </div>
            </div>
            <div class="col-12" [ngClass]="{'col-md-6': this.userIsInstaller === true}">
              <!-- Birthday -->
              <div class="form-group">
                <!-- Label -->
                <label class="form-label d-block">
                  <span>{{ translationsLib.get('profile_account_address') }}</span>
                  <small> {{ translationsLib.get('not_mandatory') }} </small>
                </label>
                <!-- Input -->
                <input id="address" type="text" class="form-control" formControlName="address" [value]="editData.value.address">
              </div>
            </div>

            <div class="col-12 col-md-6">
              <div class="form-group">
                <!-- Label -->
                <label class="form-label d-block">
                  <span>{{ translationsLib.get('profile_account_country') }}</span>
                  <small> {{ translationsLib.get('not_mandatory') }} </small>
                </label>

                <mat-form-field appearance="fill" class="w-100">
                  <mat-select id="country" (selectionChange)="setCountry($event.value);" [(value)]="selectedCountry">
                    <mat-option [value]="0">-</mat-option>
                    <mat-option *ngFor="let country of countries" [value]="country.id">{{country.name}}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>

            <div class="col-12 col-md-6" *ngIf="provinces !== null">
              <div class="form-group">
                <!-- Label -->
                <label class="form-label d-block">
                  <span>{{ translationsLib.get('profile_account_province') }}</span>
                  <small> {{ translationsLib.get('not_mandatory') }} </small>
                </label>
                <input id="province"  *ngIf="provinces===null" type="text" class="form-control" placeholder="" formControlName="province_name">
                <mat-form-field appearance="fill" class="w-100" *ngIf="provinces !== null">
                  <mat-select id="province" (selectionChange)="setProvince($event.value);" [(value)]="selectedProvinceId">
                    <mat-option *ngFor="let province of provinces" [value]="province.id">{{province.name}}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
            <div class="col-12 col-md-6" *ngIf="provinces===null">
              <div class="form-group">
                <!-- Label -->
                <label class="form-label d-block">
                  <span>{{ translationsLib.get('profile_account_province') }}</span>
                  <small> {{ translationsLib.get('not_mandatory') }} </small>
                </label>
                <input  *ngIf="provinces===null" type="text" class="form-control" placeholder="" formControlName="province_name">
              </div>
            </div>
            <div class="col-12 col-md-6">
              <!-- Last name -->
              <div class="form-group">
                <!-- Label -->
                <label class="form-label d-block">
                  <span>{{ translationsLib.get('profile_account_phone') }}</span>
                  <small> {{ translationsLib.get('not_mandatory') }} </small>
                </label>
                <!-- Input -->
                <input id="phone" type="text" class="form-control mb-3"formControlName="phone" [value]="editData.value.phone">
              </div>
            </div>
            <div class="col-12 col-md-6">
              <div class="form-group">
                <!-- Label -->
                <label class="form-label d-block">
                  <span>{{ translationsLib.get('profile_account_language') }}</span>
                </label>
                <!--<input  *ngIf="provinces===null" type="text" class="form-control" placeholder="" formControlName="province_name">-->
                <select  class="form-select" formControlName="language">
                  <option *ngFor="let lang of languages" [value]="lang.language">{{lang.name}}</option>
                </select>
              </div>
            </div>
          </div>
          <!-- Button -->
          <button type="sumbit" class="btn btn-primary" style="float: right">
            {{ translationsLib.get('save') }}
          </button>
        </form>
      </div>
    </section>

    <!-- ORGANIZATIONS -->
    <section>
      <hr class="my-4">
      <div class="card">
        <div class="col-12 col-md-6" id="organizations">
          <div class="row">
            <div class="col-12">
              <h4 class="title">
                {{ translationsLib.get('organizations_organizations') }}
              </h4>
            </div>
          </div>
          <app-organizations-item-profile
                                [showTitle]="false"
                                [organizations]="this.organizations" class="p-0"
                              ></app-organizations-item-profile>
        </div>
      </div>
    </section>

    <!-- CHANGE PASSWORD -->
    <section>
      <hr class="my-4">
      <div class="card pb-md-0">
        <div class="row">
          <div class="col-12 col-md-9">
            <!-- Heading -->
            <h4 class="title">
              {{ translationsLib.get('profile_account_change_password') }}
            </h4>
            <!-- Text -->
            <div class="small text-muted" [innerHTML]="translationsLib.get('profile_account_change_password_text')"></div>
          </div>
          <div class="col-12 col-md-3 text-end">
            <button class="btn btn-primary" (click)="changePassword()">
              {{ translationsLib.get('change') }}
            </button>
          </div>
        </div>
      </div>
    </section>

    <!-- LOGOUT -->
    <section>
      <hr class="my-4">
      <div class="card pb-md-0">
        <div class="row">
          <div class="col-12 col-md-9">
            <!-- Heading -->
            <h4 class="title">
                {{ translationsLib.get('profile_close_session_title') }}
            </h4>
            <!-- Text -->
            <div class="small text-muted" [innerHTML]="translationsLib.get('profile_close_session_text')"></div>
          </div>
          <div class="col-12 col-md-3 text-end">
            <button class="btn btn-warning" (click)="session.destroySession()">
              {{ translationsLib.get('profile_close_session_button') }}
            </button>
          </div>
        </div>
      </div>
    </section>

    <!-- DELETE ACCOUNT -->
    <section>
      <hr class="my-4">
      <div class="card pb-md-0">
        <div class="row">
          <div class="col-12 col-md-9">
            <!-- Heading -->
            <h4 class="title">
              {{ translationsLib.get('profile_account_delete_account') }}
            </h4>
            <!-- Text -->
            <div class="small text-muted" [innerHTML]="translationsLib.get('profile_account_delete_account_text')"></div>
          </div>
          <div class="col-12 col-md-3 text-end">
            <button class="btn btn-danger" (click)="deleteUser()">
              {{ translationsLib.get('delete') }}
            </button>
          </div>
        </div>
      </div>
    </section>
  </div>
</div>

