<div *ngIf="field.type === 'card'"
     class="card h-100 py-2 px-3 mb-2">
  <div class="text-nowrap text-uppercase fs-6 fw-bold"
       style="color: var(--primary-color)">
    {{ field.label }}
  </div>
  <div [class]="isMobile ? 'fs-6' : 'fs-4'" class="fw-bold">
    {{ field.value }}
    <span *ngIf="field.value === undefined"
          [class]="isMobile ? 'fs-6' : 'fs-4'"
          class="fw-normal text-muted">
      -
    </span>
    <span *ngIf="field.unit"
          [class]="isMobile ? 'fs-6' : 'fs-4'"
          class="fw-normal text-muted">
      {{ field.unit }}
    </span>
  </div>
</div>
