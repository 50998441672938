import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { EventsCalendarScreenComponent } from './events-calendar-screen/events-calendar-screen.component';
import { EventsCalendarWidgetComponent } from './events-calendar-widget/events-calendar-widget.component';
import { EventsCalendarLogComponent } from './events-calendar-log/events-calendar-log.component';
import { TextAvatarModule } from '../text-avatar';
import { MatTabsModule } from '@angular/material/tabs';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppCommonsModule } from '../commons/app-commons.module';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { PermissionsModule } from '../permissions/permissions.module';
import { NgxPaginationModule } from 'ngx-pagination';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { ProfileModule } from '../profile/profile.module';
import { RouterModule } from '@angular/router';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { AuthModule } from '../auth/auth.module';
import {
  CalendarCommonModule,
  CalendarDayModule,
  CalendarModule,
  CalendarMonthModule,
  CalendarWeekModule,
  DateAdapter,
} from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { EventsCalendarTableComponent } from './events-calendar-table/events-calendar-table.component';
import { MatTableModule } from '@angular/material/table';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { App360SharedModule } from '@nutricontrol/app360-shared';
//import { NgbModalModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  declarations: [
    EventsCalendarScreenComponent,
    EventsCalendarWidgetComponent,
    EventsCalendarLogComponent,
    EventsCalendarTableComponent,
  ],
  exports: [EventsCalendarWidgetComponent, EventsCalendarTableComponent],
  imports: [
    CommonModule,
    TextAvatarModule,
    MatTabsModule,
    ReactiveFormsModule,
    IonicModule,
    AppCommonsModule,
    MatAutocompleteModule,
    PermissionsModule,
    NgxPaginationModule,
    MatProgressBarModule,
    ProfileModule,
    RouterModule,
    MatProgressSpinnerModule,
    FormsModule,
    Ng2SearchPipeModule,
    AuthModule,
    CalendarCommonModule,
    CalendarMonthModule,
    CalendarWeekModule,
    CalendarDayModule,
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory,
    }),
    MatTableModule,
    MatFormFieldModule,
    MatInputModule,
    MatPaginatorModule,
    App360SharedModule,
  ],
})
export class EventsCalendarModule {}
