import { Component, OnInit, Input } from '@angular/core';
import {
  DesktopClimateStatesStructureMeteo,
  DesktopClimateStatesStructureModel,
  NewDesktopClimateMeteo,
  NewDesktopClimateModel,
} from '../../climate.model';
import { TranslationsLibService } from '@nutricontrol/app360-shared';
import { NgForOf, NgIf, NgSwitch, NgSwitchCase } from '@angular/common';
import { FarmingModule } from '../../../farming.module';

@Component({
  standalone: true,
  selector: 'app-climate-meteo-table',
  templateUrl: './climate-meteo-table.component.html',
  styleUrls: ['./climate-meteo-table.component.scss'],
  imports: [NgIf, NgForOf, NgSwitch, NgSwitchCase, FarmingModule],
})
export class ClimateMeteoTableComponent implements OnInit {
  @Input() statesResponse: NewDesktopClimateModel;
  @Input() meteoResponse: NewDesktopClimateMeteo;
  @Input() statesStructureResponse: DesktopClimateStatesStructureModel;
  @Input() meteoStructureResponse: DesktopClimateStatesStructureMeteo;
  @Input() meteoVisibility: { [key: string]: any } = {};
  @Input() searchMeteoText = '';
  @Input() meteoArray: any[];

  constructor(public translationsLib: TranslationsLibService) {}

  ngOnInit() {}

  getVisibleMeteoKeys() {
    if (!this.meteoVisibility) {
      return [];
    }

    return this.meteoVisibility.filter((meteo) => meteo.visible === true);
  }

  getMeteoValueAndUnit(key: string) {
    const meteoItem = this.meteoArray.find((meteo) => meteo.key === key);
    if (meteoItem) {
      let valueText = meteoItem.value;

      const valueMappings = {
        TRUE: this.translationsLib.get('climate_desktop_yes'),
        FALSE: this.translationsLib.get('climate_desktop_no'),
        TRAMO_0: this.translationsLib.get('climate_desktop_level0'),
        TRAMO_1: this.translationsLib.get('climate_desktop_level1'),
        TRAMO_2: this.translationsLib.get('climate_desktop_level2'),
        TRAMO_3: this.translationsLib.get('climate_desktop_level3'),
        TRAMO_4: this.translationsLib.get('climate_desktop_level4'),
        TRAMO_5: this.translationsLib.get('climate_desktop_level5'),
      };

      if (valueMappings.hasOwnProperty(meteoItem.value)) {
        valueText = valueMappings[meteoItem.value];
      }

      return `${valueText} ${meteoItem.unit}`;
    }
    return '';
  }
}
