import {Component, Input, OnInit} from '@angular/core';
import {TranslationsLibService} from "@nutricontrol/app360-shared";
import {ReportsService} from "../reports.service";
import {TimeLibService} from "../../services/libraries/time-lib.service";
import {CreatedReportModel} from "../reports.model";

@Component({
  selector: 'app-reports-raw-sensors',
  templateUrl: './reports-raw-sensors.component.html',
  styleUrls: ['./reports-raw-sensors.component.scss'],
})
export class ReportsRawSensorsComponent implements OnInit {

  @Input() createdReport: CreatedReportModel;
  reportsData;
  loader = false;
  ready = false;
  columnsToDisplay = ['name', 'date', 'value'];
  reportTable: any;
  requestData;
  constructor(
    public translationsLib: TranslationsLibService,
    private reportsService: ReportsService,
    private timeLib: TimeLibService
  ) {}

  ngOnInit() {
    console.log(this.createdReport);
    this.loader = true;
    this.getReportData();
  }

  getReportData(){
    const terminals = [];
    this.createdReport.terminals.forEach((terminal) => {
      terminals.push(terminal.vid);
    });
    const data = {
      terminals_vid : terminals,
      sensors : this.createdReport.sensors,
    };
    const begin = this.timeLib.beginDate(
      this.createdReport.dateRange.start,
      0,
      'days'
    );
    const end = this.timeLib.endDate(
      this.createdReport.dateRange.end,
      0,
      'days'
    );
    this.requestData = {
      terminals_vid: terminals,
      sensors: this.createdReport.sensors,
      type: 'sensors/raw',
      begin,
      end,
    };
    this.reportsService
      .getSensorsRawReport(data, begin, end, null)
      .subscribe((res) => {
        this.reportsData = [];
        this.reportTable = [];
        res.sensors_reports.forEach((report) => {
          this.reportsData = this.reportsData.concat(
            report['raw_sensor_items']
          );
          this.reportTable = this.reportTable.concat(
            report['chart_table_paired_items']['data_table']
          );
        });
        console.log(this.reportTable);
        this.loader = false;
        this.ready = true;
      });
  }
}
