<div class="row justify-content-between">
  <div class="col-12">
    <div class="row" *ngIf="showTitle === true">
      <div class="col-12">
        <h2>
          {{ translationsLib.get('organizations_organizations') }}
        </h2>
      </div>
    </div>
    <div class="row">
      <div class="col-12" *ngIf="parsedOrganizations.length > 0">
        <div class="row mb-2">
          <div class="col-12 col-lg-6" *ngFor="let parsedOrganization of parsedOrganizations">
            <h5 class="mt-2">
              <ng-container *ngIf="parsedOrganization.root_organization !== null && parsedOrganization.vid !== parsedOrganization.root_organization.vid">
                {{ parsedOrganization.root_organization.name }}:
              </ng-container>
              <ng-container *ngIf="parsedOrganization.name === 'CATCH_ALL_OU'">
                {{ translationsLib.get('organizations_CATCH_ALL_OU') }}
              </ng-container>
              <ng-container *ngIf="parsedOrganization.name !== 'CATCH_ALL_OU'">
                {{ parsedOrganization.name }}
              </ng-container>
            </h5>
            <img class="item-avatar-logo" src="{{ parsedOrganization.avatar_logo }}" *ngIf="parsedOrganization.avatar_logo"/>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="small text-muted mb-md-0" [innerHTML]="translationsLib.get('organizations_help_text')"></div>
          </div>
        </div>
      </div>
      <div class="col-12" *ngIf="parsedOrganizations.length === 0">
        <p class="small text-muted mb-md-0">
          {{ translationsLib.get('organizations_no_organization') }}
        </p>
      </div>
    </div>
  </div>
</div>
