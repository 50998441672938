import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { IrrigationService } from '../../irrigation.service';
import { HelpersLibService } from '../../../../services/libraries/helpers-lib.service';
import { Breadcrumb } from '../../shared/shared.model';
import {
  DigitalSensor2,
  Expansion,
  Input,
  Output,
} from '../../irrigation.model';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import { Platform } from '@ionic/angular';
import { TranslationsLibService } from '@nutricontrol/app360-shared';

@Component({
  selector: 'app-input-output',
  templateUrl: './input-output.component.html',
  styleUrls: ['./input-output.component.scss'],
})
export class InputOutputComponent implements OnInit, AfterViewInit {
  isFetchingData = false;
  breadcrumbs: Breadcrumb[] = [];
  terminal_vid: string;
  isFetchingEquipmentData = false;
  isFormSubmitted = false;
  terminalName = '';
  expansionsResponse: Expansion[];
  isIOReportWithoutData = false;
  inputsResponse: Input[];
  outputsResponse: Output[];
  showCreatePDFButton: boolean;

  constructor(
    private router: Router,
    private irrigationService: IrrigationService,
    private helpersLib: HelpersLibService,
    private activatedRoute: ActivatedRoute,
    private platform: Platform,
    public translationsLib: TranslationsLibService
  ) {}

  ngAfterViewInit() {
    this.activatedRoute.paramMap.subscribe((_) => {
      this.helpersLib.setTitle(
        '',
        this.translationsLib.get('irrigation_input_output_io_report'),
        null
      );
    });
  }

  ngOnInit() {
    const [, , vid, , , ,] = this.router.url.split('/');
    this.terminal_vid = vid;

    this.activatedRoute.params.subscribe((_) => {
      this.getIOReportData();
    });

    if (this.platform.is('ios') || this.platform.is('android')) {
      this.showCreatePDFButton = false;
    } else {
      this.showCreatePDFButton = true;
    }

    this.generateBreadcrumbs();
  }

  formatSensorValue(sensor: any): string {
    if (sensor.nexp < 10) {
      return '0' + sensor.nexp;
    } else {
      const suffix = Number(sensor.nexp) < 10 ? '-0' : '-';
      return sensor.nexp + '-ED-' + suffix + (sensor.nent + 1);
    }
  }

  generateBreadcrumbs() {
    this.isFetchingEquipmentData = true;
    this.breadcrumbs = [];
    this.irrigationService.getTerminal(this.terminal_vid).subscribe((res) => {
      this.terminalName = res.name;

      if (res.field) {
        this.breadcrumbs.push({
          text: res.field.name,
          disabled: false,
          to: ['/field', { vid: res.field.vid }],
          active: false,
        });
      }
      this.breadcrumbs.push(
        {
          text: res.name,
          disabled: false,
          to: `/farming/${this.terminal_vid}/irrigation`,
          active: false,
        },
        {
          text: 'Configuración',
          disabled: false,
          to: `/farming/${this.terminal_vid}/irrigation/5`,
          active: false,
        },
        {
          text: this.translationsLib.get('irrigation_input_output_io_report'),
          disabled: false,
          active: true,
        }
      );

      this.isFetchingEquipmentData = false;
    });
  }

  getIOReportData() {
    this.isFetchingData = true;

    this.irrigationService
      .getIrrigationInstallerIOReport(this.terminal_vid)
      .subscribe((res) => {
        this.expansionsResponse = res.expansions;
        this.inputsResponse = res.inputs;
        this.outputsResponse = res.outputs;

        if (
          this.expansionsResponse.length === 0 ||
          this.inputsResponse.length === 0 ||
          this.outputsResponse.length === 0
        ) {
          this.isIOReportWithoutData = true;
        }

        this.isFetchingData = false;
      });
  }

  createInputOutputReportPDF() {
    let isExpansionTableFirstPage = true;
    let isInputTableFirstPage = true;
    let isOutputTableFirstPage = true;

    const doc = new jsPDF();

    const { day, month, year, time } = this.getCurrentDateHoursAndMinutes();

    /*******************
     ****** TITLE ******
     ******************/
    doc.text(
      `${this.translationsLib.get('irrigation_input_output_io_list')} ${
        this.terminalName
      }`,
      10,
      20
    );

    // Reset styles
    doc.setFontSize(10);

    /********************
     ****** TABLES ******
     *******************/
    // Exapansions
    autoTable(doc, {
      html: '#expansions-table',
      margin: { top: 40 },
      didDrawPage: (data) => {
        if (isExpansionTableFirstPage) {
          doc.text(
            this.translationsLib.get('irrigation_input_output_expansions'),
            data.settings.margin.left,
            36
          );
          isExpansionTableFirstPage = false;
        }
      },
      styles: { fontSize: 7 },
    });

    // Digital Sensors
    this.expansionsResponse.forEach((expansion) => {
      if (expansion.digitalSensors && expansion.digitalSensors.length > 0) {
        autoTable(doc, {
          html: '#expansions-table-digital-sensors',
          margin: { top: 40 },
          didDrawPage: (data) => {
            if (isExpansionTableFirstPage) {
              doc.text(
                this.translationsLib.get('irrigation_input_output_expansions'),
                data.settings.margin.left,
                36
              );
              isExpansionTableFirstPage = false;
            }
          },
          styles: { fontSize: 7 },
        });
      }
    });

    // Analogic Sensors
    this.expansionsResponse.forEach((expansion) => {
      if (expansion.analogicSensors && expansion.analogicSensors.length > 0) {
        autoTable(doc, {
          html: '#expansions-table-analogic-sensors',
          margin: { top: 40 },
          didDrawPage: (data) => {
            if (isExpansionTableFirstPage) {
              doc.text(
                this.translationsLib.get('irrigation_input_output_expansions'),
                data.settings.margin.left,
                36
              );
              isExpansionTableFirstPage = false;
            }
          },
          styles: { fontSize: 7 },
        });
      }
    });

    doc.addPage();

    // Inputs
    autoTable(doc, {
      html: '#inputs-table',
      margin: { top: 20 },
      didDrawPage: (data) => {
        if (isInputTableFirstPage) {
          doc.text(
            this.translationsLib.get('irrigation_input_output_input'),
            data.settings.margin.left,
            15
          );
          isInputTableFirstPage = false;
        }
      },
      styles: { fontSize: 7 },
    });

    // Digital Sensors
    this.inputsResponse.forEach((input) => {
      if (input.digitalSensors && input.digitalSensors.length > 0) {
        autoTable(doc, {
          html: '#inputs-table-digital-sensors',
          margin: { top: 20 },
          didDrawPage: (data) => {
            if (isInputTableFirstPage) {
              doc.text(
                this.translationsLib.get('irrigation_input_output_input'),
                data.settings.margin.left,
                15
              );
              isInputTableFirstPage = false;
            }
          },
          styles: { fontSize: 7 },
        });
      }
    });

    // Analogic Sensors
    this.inputsResponse.forEach((input) => {
      if (input.analogicSensors && input.analogicSensors.length > 0) {
        autoTable(doc, {
          html: '#inputs-table-analogic-sensors',
          margin: { top: 20 },
          didDrawPage: (data) => {
            if (isInputTableFirstPage) {
              doc.text(
                this.translationsLib.get('irrigation_input_output_input'),
                data.settings.margin.left,
                15
              );
              isInputTableFirstPage = false;
            }
          },
          styles: { fontSize: 7 },
        });
      }
    });

    doc.addPage();

    // Outputs
    autoTable(doc, {
      html: '#outputs-table',
      margin: { top: 20 },
      didDrawPage: (data) => {
        if (isOutputTableFirstPage) {
          doc.text(
            this.translationsLib.get('irrigation_input_output_output'),
            data.settings.margin.left,
            15
          );
          isOutputTableFirstPage = false;
        }
      },
      styles: { fontSize: 7 },
    });

    /********************
     ****** FOOTER ******
     *******************/
    this.getHeaderAndPageNumber(doc, day, month, year, time);

    doc.save('input_output.pdf');
  }

  getHeaderAndPageNumber(doc: jsPDF, day, month, year, time) {
    const pages = (doc.internal as any).getNumberOfPages();
    const pageWidth = doc.internal.pageSize.width;
    const pageHeight = doc.internal.pageSize.height;
    doc.setFontSize(7);
    doc.setTextColor(150);

    for (let j = 1; j < pages + 1; j++) {
      const horizontalPos = pageWidth / 2;
      const verticalPos = pageHeight - 10;
      doc.setPage(j);
      doc.text(`${j}/${pages}`, horizontalPos, verticalPos, {
        align: 'center',
      });
      doc.text(`${day}/${month}/${year} ${time}`, 5, 5);
      doc.text(
        this.translationsLib.get('irrigation_input_output_io_report'),
        185,
        5
      );
    }
  }

  getCurrentDateHoursAndMinutes() {
    const now = new Date();
    const day = now.getDate();
    const month = now.getMonth() + 1;
    const year = now.getFullYear();
    const hours = now.getHours();
    const minutes = now.getMinutes();

    const formattedTime = `${hours.toString().padStart(2, '0')}:${minutes
      .toString()
      .padStart(2, '0')}`;

    return { day, month, year, time: formattedTime };
  }

  formatExpInput(data: any, analogic = false, digital = false) {
    let expSal = data.nexp < 10 ? '0' + data.nexp : data.nexp;
    if (expSal === '00') expSal = 'PB';

    if (digital && (data as DigitalSensor2)) {
      expSal +=
        '-ED-' +
        (data.nent + 1 < 10 ? '0' + data.nent + 1 : '-' + data.nent + 1);
    }

    return expSal;
  }
  formatExpOutput(data: any, analogic = false, digital = false) {
    let expSal = data.nexp < 10 ? '0' + data.nexp : data.nexp;
    if (expSal === '00') expSal = 'PB';

    if (digital && (data as Output)) {
      expSal +=
        '-ED-' +
        (data.nsal + 1 < 10 ? '0' + data.nsal + 1 : '-' + data.nsal + 1);
    }

    return expSal;
  }
}
