import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { TerminalsService } from '../../../../terminals/terminals.service';
import { IrrigationService } from '../../../../farming/irrigation/irrigation.service';
import { WaterSystemsFieldsConfig } from '../models/water-systems-config.model';
import { IrrigationEquipmentModel } from '../../../../farming/irrigation/irrigation.model';
import { defaultFieldsConfig } from '../models/water-systems-config.model';

@Injectable({
  providedIn: 'root',
})
export class WaterSystemsDataService {
  constructor(
    private terminalsService: TerminalsService,
    private irrigationService: IrrigationService
  ) {}

  public fetchEquipment(
    terminalVid: string
  ): Observable<IrrigationEquipmentModel> {
    return this.irrigationService.getIrrigationEquipmentWithWaterSystems(
      terminalVid
    );
  }

  public fetchConfig(): Observable<WaterSystemsFieldsConfig> {
    return new Observable((observer) => {
      observer.next(defaultFieldsConfig);
      observer.complete();
    });
  }
}
