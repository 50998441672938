<lib-loader *ngIf="loader === true"></lib-loader>

<section class="reports-main-subcomponent-container" *ngFor="let reportData of reportsData;">
  <div class="card pb-3" *ngIf="reportData.ready === true">
    <div class="card-header">
      <!-- Title -->
      <h2 class="card-header-title mb-3">
        {{ reportData.reportName }} <span style="color: #ee7869 !important;">- {{reportData.terminalVid}}</span>
      </h2>
      <h2 class="card-header-subtitle">
        {{ reportData.terminalFieldName }}
      </h2>
    </div>
    <div class="row mx-2 mt-3 print-no-padding">
      <div class="col-12 p-0 p-lg-2 drainages-container print-no-padding">

        <div class="alert alert-info mb-2" role="alert" *ngIf="reportData.drainages.length === 0">
          {{ translationsLib.get('reports_no_data') }}
        </div>

        <article *ngIf="reportData.drainages.length > 0">
          <app-reports-drainages-table
            [drainageColumnsToDisplay]="drainageColumnsToDisplay"
            [reportName]="reportData.reportName"
            [data]="reportData.drainages"
            [requestData]="{terminals_vid: [reportData.terminalVid], end: reportData.end, begin: reportData.begin, type: 'drainages', order_type: null}"
            [count]="reportData.drainages.length"
            [tablePageItemNumber]="createdReport.tablePageItemNumber"
          ></app-reports-drainages-table>
        </article>
      </div>
    </div>
  </div>
</section>
