import { Injectable } from '@angular/core';
import * as moment from 'moment';
import SunCalc from 'suncalc';
@Injectable({
  providedIn: 'root',
})
export class GraphicsLibService {
  constructor() {}
  combineAndSortArrays(array, series) {

    // Combina todos los arrays en uno solo
    const combinedArray = array.flat();

    // Extrae los timestamps únicos
    const uniqueTimestamps = new Set(
      combinedArray.map((item) => item.timestamp)
    );

    // Utiliza un Map para eliminar duplicados por timestamp
    const uniqueTimestampsMap = new Map();
    combinedArray.forEach((item) => {
      uniqueTimestampsMap.set(item.timestamp, item);
    });

    // Convierte el Map de nuevo a un array
    const uniqueTimestampsArray = Array.from(uniqueTimestampsMap.values());

    // Ordena los objetos por timestamp
    uniqueTimestampsArray.sort((a, b) => new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime());

    // Agrega los timestamps faltantes en series con valor null
    series.forEach(serie => {
      const existingDates = new Set(serie.categories.map(category => category.timestamp));

      uniqueTimestamps.forEach(timestamp => {
        if (!existingDates.has(timestamp)) {
          serie.categories.push({ timestamp, value: null });
        }
      });
      // Ordena las categorías por timestamp
      serie.categories.sort((a, b) =>
        a.timestamp-b.timestamp
      );
      serie.data = serie.categories.map((categories) => categories.value);
    });

    const formattedTimestampsArray = uniqueTimestampsArray.map(item =>
      moment.utc(item.timestamp).format('DD/MM HH:mm')
    );
    return { series_x: formattedTimestampsArray, series };
  }
  getPlotBands(sensor, latitude, longitude){
    let sunrise;
    let sunset;
    const plotBands = [];
    sensor.categories.forEach((category, index) => {
      const times = SunCalc.getTimes(
        category.timestamp,
        latitude,
        longitude
      );
      if (
        moment.utc(category.timestamp).format('DD/MM HH:MM') ===
        moment.utc(times.sunrise).format('DD/MM HH:MM')
      ) {
        sunrise = index;
      }
      if (
        moment.utc(category.timestamp).format('DD/MM HH:MM') ===
        moment.utc(times.sunset).format('DD/MM HH:MM')
      ) {
        sunset = index;
      }
      if (
        sunrise > sunset &&
        sunrise !== undefined &&
        sunset !== undefined
      ) {
        const i = plotBands.findIndex((x) => x.from === sunset);
        if (i === -1) {
          plotBands.push({
            color: 'rgba(3,27,128,0.1)',
            fillOpacity: 0.1,
            from: sunset,
            to: sunrise,
          });
        }
      }
    });
    return plotBands;
  }
  getIntervalTime(diferencia) {
    let data;
    if (diferencia > 0 && diferencia <= 2) {
      data = [
        { value: 300, option: '5m', disabled: false },
        { value: 600, option: '10m', disabled: false },
        { value: 900, option: '15m', disabled: false },
        { value: 1800, option: '30m', disabled: false },
        { value: 3600, option: '1h', disabled: false },
        { value: 21600, option: '6h', disabled: false },
      ];
    }
    if (diferencia >= 3 && diferencia <= 7) {
      data = [
        { value: 300, option: '5m', disabled: true },
        { value: 600, option: '10m', disabled: true },
        { value: 900, option: '15m', disabled: false },
        { value: 1800, option: '30m', disabled: false },
        { value: 3600, option: '1h', disabled: false },
        { value: 21600, option: '6h', disabled: false },
        { value: 43200, option: '12h', disabled: false },
        { value: 86400, option: '24h', disabled: false },
      ];
    }
    if (diferencia >= 8 && diferencia<=32) {
      data = [
        { value: 300, option: '5m', disabled: true },
        { value: 600, option: '10m', disabled: true },
        { value: 900, option: '15m', disabled: true },
        { value: 1800, option: '30m', disabled: false },
        { value: 3600, option: '1h', disabled: false },
        { value: 21600, option: '6h', disabled: false },
        { value: 43200, option: '12h', disabled: false },
        { value: 86400, option: '24h', disabled: false },
      ];
    }
    if (diferencia >= 33 && diferencia<=366) {
      data = [
        { value: 300, option: '5m', disabled: true },
        { value: 600, option: '10m', disabled: true },
        { value: 900, option: '15m', disabled: true },
        { value: 1800, option: '30m', disabled: true },
        { value: 3600, option: '1h', disabled: false },
        { value: 21600, option: '6h', disabled: false },
        { value: 43200, option: '12h', disabled: false },
        { value: 86400, option: '24h', disabled: false },
      ];
    }
    if (diferencia >= 367) {
      data = [
        { value: 300, option: '5m', disabled: true },
        { value: 600, option: '10m', disabled: true },
        { value: 900, option: '15m', disabled: true },
        { value: 1800, option: '30m', disabled: true },
        { value: 3600, option: '1h', disabled: true },
        { value: 21600, option: '6h', disabled: false },
        { value: 43200, option: '12h', disabled: false },
        { value: 86400, option: '24h', disabled: false },
      ];
    }
    return data;
  }
}
