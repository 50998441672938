<div class="my-4">
  <mat-expansion-panel [ngClass]="{'no-card':viewMode==='reports'}" [(expanded)]="panelOpenState"
                       (opened)="panelOpenState = true"
                       (closed)="panelOpenState = false" [hidden]="chartCreated === true">
      <mat-expansion-panel-header *ngIf="viewMode !== 'reports'">
        <mat-panel-title>
          <div class="d-flex align-items-center">
            <img src="../../../assets/img/Icons/Icono_UCROP_Filtro_Grafica_Personalizada.svg" style="max-height: 60px" *ngIf="viewMode==='megapop'">
            <img src="../../../assets/img/Icons/Icono_UCROP_Filtro_Grafica_Metrica.svg" style="max-height: 60px" *ngIf="viewMode==='metrics'">
            <div class="row">
              <a class="mx-2 mb-0" style="font-weight: bold; color: #EE7869; font-size: 18px"  *ngIf="viewMode==='megapop'">{{translationsLib.get('graphics_create_custom_graph')}}</a>
              <span class="mx-2 text-muted" style="font-size: 14px" *ngIf="viewMode==='megapop'">
                  {{translationsLib.get('graphics_custom_graph_explanation')}}
                </span>
              <a class="mx-2 mb-0" style="font-weight: bold; color: #EE7869; font-size: 18px"  *ngIf="viewMode==='metrics'">{{translationsLib.get('graphics_create_custom_metric')}}</a>
              <span class="mx-2 text-muted" style="font-size: 14px"  *ngIf="viewMode==='metrics'">
                  {{translationsLib.get('graphics_create_metrics_explanation')}}
                </span>
            </div>
          </div>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <ng-container *ngIf="viewMode === 'megapop'">
        <div class="col-12">
          <div class="row mx-2 mt-2">
            <div class="col-12 col-lg-7">
              <app-selected-dates></app-selected-dates>
            </div>
            <div class="col-12 col-lg-5">
              <div class="row" *ngIf="loadedDates===true">
                <h2 class="mb-0">{{translationsLib.get('graphics_interval')}}</h2>
                <span class="text-muted mb-2">{{translationsLib.get('graphics_interval_help')}}</span>
              </div>
              <div class="row" *ngIf="loadedDates===true">
                <select class="form-select" style="max-width: 200px; margin-left: 12px" [(ngModel)]="intervalSelected">
                  <option *ngFor="let interval of intervals" [value]="interval.value" [disabled]="interval.disabled">{{interval.option}}</option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
      <div class="row mx-2 mt-4"  *ngIf="viewMode !== 'reports'">
        <div class="col-12" [ngClass]="{'col-lg-5':viewMode==='metrics'}">
          <div class="row">
            <h2 class="mb-0">{{translationsLib.get('graphics_devices')}}</h2>
            <span class="text-muted mb-4">{{translationsLib.get('graphics_devices_help')}}</span>
          </div>
          <div class="row mx-1">
            <div class="col-12 position-relative">
              <lib-loader *ngIf="fieldTerminalsLoaded !== true"></lib-loader>
              <ng-container *ngIf="fieldTerminalsLoaded === true">
                <app-selected-devices [fieldTerminals]="fieldTerminals" [viewmode]="viewMode" *ngIf="fieldTerminals.length > 0"></app-selected-devices>
                <span *ngIf="fieldTerminals.length === 0">
                  {{ translationsLib.get('graphics_field_no_devices_or_no_sensors') }}
                </span>
              </ng-container>
            </div>
          </div>
        </div>
        <ng-container *ngIf="viewMode==='metrics'">
          <div class="col-12 col-lg-7">
            <div class="row">
              <h2 class="mb-0">{{translationsLib.get('graphics_operations')}}</h2>
              <span class="text-muted mb-2">{{translationsLib.get('graphics_operations_help')}}</span>
            </div>
            <section class="mb-0">
              <mat-radio-group [(ngModel)]="operation">
                <mat-radio-button class="col-5 mb-2" value="min">{{translationsLib.get('graphics_min')}}</mat-radio-button>
                <mat-radio-button class="col-5 mb-2" value="max">{{translationsLib.get('graphics_max')}}</mat-radio-button>
                <mat-radio-button class="col-5 mb-2" value="med">{{translationsLib.get('graphics_med')}}</mat-radio-button>
                <mat-radio-button class="col-5 mb-2" value="sum">{{translationsLib.get('graphics_sum')}}</mat-radio-button>
                <mat-radio-button class="col-5 mb-2" value="dif">{{translationsLib.get('graphics_difference')}}</mat-radio-button>
                <mat-radio-button class="col-5 mb-2" value="acum">{{translationsLib.get('graphics_acum')}}</mat-radio-button>
                <mat-radio-button class="col-5 mb-2" value="acumMagnitude">{{translationsLib.get('graphics_acumMagnitude')}}</mat-radio-button>
                <mat-radio-button class="col-5 mb-2" value="esc">{{translationsLib.get('graphics_esc')}}</mat-radio-button>
              </mat-radio-group>
            </section>
            <div class="container" *ngIf="operation">
              <div class="row">
                <div class="col-12">
                  <i class="ps-1 text-muted">{{translationsLib.get('graphics_help_' + operation)}}</i>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
      <hr class="m-4" *ngIf="loadedDates===true && viewMode !== 'reports'">
    <div class="row mx-0" style="height: 200px" *ngIf="loadingDevicesCount > 0">
    <lib-loader style="position: relative"></lib-loader>
    </div>
    <div class="row mx-0" *ngIf="(loadedDates===true && devicesCompleted>0  && loadingDevicesCount === 0) || viewMode === 'reports'">
      <div class="row">
        <div class="col-12 col-lg-8 align-content-end">
          <h2 class="my-0">{{translationsLib.get('graphics_magnitudes')}}</h2>
          <span class="text-muted mb-2">{{translationsLib.get('graphics_magnitudes_help')}}</span>
        </div>
        <div class="col-12 col-lg-4 text-end">
          <div class="inner-addon left-addon">
         <!-- <ion-icon name="search-outline"></ion-icon> -->
            <input type="text" class="form-control" placeholder="{{translationsLib.get('search_magnitude')}}" [(ngModel)]="term"/>
          </div>
        </div>
      </div>
      <div class="row mt-0 mb-4" *ngIf="loadedDates===true || viewMode === 'reports'">
          <div class="col-12 col-md-6">
            <h2 class="mb-0">{{translationsLib.get('graphics_choose_sensors_actuators')}}</h2>
            <span class="text-muted mb-2">{{translationsLib.get('graphics_choose_sensors_actuators_help')}}</span>
          </div>
          <div class="col-12 col-md-6 align-self-center text-md-end">
            <mat-radio-group
              aria-labelledby="example-radio-group-label"
              class="example-radio-group"
              [(ngModel)]="sensorTypes">
              <mat-radio-button class="radio-button" [value]="0">
                {{translationsLib.get('graphics_sensors')}}
              </mat-radio-button>
              <mat-radio-button class="radio-button" [value]="1" (click)="checkActuadores()">
                {{translationsLib.get('graphics_actuators_meteo')}}
              </mat-radio-button>
              <mat-radio-button [value]="2">
                {{translationsLib.get('graphics_view_all')}}
              </mat-radio-button>
            </mat-radio-group>
          </div>
      </div>
      <div class="row">
        <lib-loader *ngIf="viewMode === 'reports' && reportsLoader === true"></lib-loader>
        <ng-container *ngIf="!(sensorTypes === 1 && actuadoresTotales === 0)">
        <ng-container  *ngFor="let sensortype of sensoresOrdenados | filter: term ; index as i  ">
          <ng-container *ngIf="sensortype.valor.length>0">
            <div class="col-12 col-md-6" *ngIf="sensorTypes === 0 && sensortype.valor[0].actuador === false ||
                                  sensorTypes === 1 && sensortype.valor[0].actuador === true ||
                                  sensorTypes ===2 || sensortype.key === translationsLib.get('graphics_metrics')">
              <span class="example-list-section">
                 <ul class="p-0">
                    <li class="magnitudeBar">
                      <mat-checkbox
                        [checked]="allComplete"
                        [indeterminate]="someComplete(sensortype)"
                        (change)="setAll($event.checked,sensortype)"
                      >
                        <h4 class="magnitude mt-0" *ngIf="sensortype.key === 'undefined' || sensortype.key === 'UKNOWN' ">
                          {{translationsLib.get('graphics_other_sensors')}}
                        </h4>
                        <h4 class="magnitude mt-0" *ngIf="sensortype.key !== 'undefined' && sensortype.key !== 'UKNOWN'">
                          {{sensortype.key}}
                        </h4>
                      </mat-checkbox>
                    </li>
                  </ul>
              </span>
              <span class="example-list-section">
                <div class="row mx-0" >
                  <div [ngClass]="viewMode === 'reports' ? 'col-12 col-xl-6' : 'col-6 col-xl-4'" class="mb-2" *ngFor="let sensor of sensortype.valor | filter: term " >
                    <mat-checkbox [(ngModel)]="sensor.completed"
                                  (ngModelChange)="updateAllMagnitudeComplete()" style="white-space: initial"
                                  *ngIf="sensorTypes === 0 && sensor.actuador === false ||
                                  sensorTypes === 1 && sensor.actuador === true ||
                                  sensorTypes ===2 || sensortype.key === translationsLib.get('graphics_metrics')">
                      <h5 class="mt-0 mb-0">{{sensor.name}}</h5>
                      <span class="text-muted">{{sensor.device_name}}</span>
                    </mat-checkbox>
                  </div>
                </div>
                  <br>
            </span>
            </div>
          </ng-container>
        </ng-container>
        </ng-container>
        <ng-container *ngIf="sensorTypes === 1 && actuadoresTotales === 0">
          <h4 class="text-center" style="font-style: italic">
            {{translationsLib.get('graphics_device_no_actuators')}}
          </h4>
        </ng-container>
        <ng-container *ngIf="sensorTypes === 0 && sensoresOrdenados.length===0">
          <h4 class="text-center" style="font-style: italic">
            {{translationsLib.get('graphics_device_no_sensors')}}
          </h4>
        </ng-container>
        <ng-container *ngIf="sensorTypes === 2 && sensoresOrdenados.length===0 && actuadoresTotales === 0">
          <h4 class="text-center" style="font-style: italic">
            {{translationsLib.get('graphics_device_no_sensors_and_actuators')}}
          </h4>
        </ng-container>
      </div>
    </div>
    <div class="row" *ngIf="(devicesCompleted>0 || viewMode ==='reports')">
      <div class="col-12">
        <hr class="m-4" *ngIf="loadedDates===true && range.value.start && range.value.end && sensoresSelecteds.length > 0 && sensoresOrdenados.length >0">
      </div>
    </div>
    <div class="row mx-0 mb-4 sticky-sm-bottom" *ngIf="loadedDates===true && range.value.start && range.value.end && (devicesCompleted>0 || viewMode ==='reports') && sensoresSelecteds.length>0 && sensoresOrdenados.length>0">
      <div class="col-12">
        <app-selected-sensors
          [margin]="4"
          [sensoresOrdenados]="sensoresOrdenados"
          [sensoresSelecteds]="sensoresSelecteds"
          [viewMode]="viewMode"
          [operation]="operation">
        </app-selected-sensors>
      </div>
    </div>

  </mat-expansion-panel>
  <div class="mt-4">
    <div class="card" style="overflow-x: hidden" [hidden]="chartCreated === false && createdFirstTime===false">
      <app-graphic-header *ngIf="(viewMode==='megapop' && chartCreated===true) || createdFirstTime===true"
                          [graphic]="{sensors: sensoresSelecteds, dates: range}"
                          [viewEmitMode]="viewEditMode"
                          [sensoresOrdenados]="sensoresOrdenados"
                          [sensoresSelecteds]="sensoresSelecteds"
                          [operation]="operation"
                          [chartGotData]="chartGotData"
                          (viewModeEvent)="viewEditMode= $event"></app-graphic-header>
      <app-metrics-header [graphic]="{sensors: sensoresSelecteds, operation: operation}" *ngIf="viewMode==='metrics' && chartCreated===true"></app-metrics-header>
      <app-charts-component
        *ngIf="(chartCreated === true && (data.terminals.length>0 || data.metrics.length>0))||createdFirstTime===true"
        [multiDeviceData]="data"
        [fields]="fieldsCompleted"
        (isData)="chartGotData = $event"
      ></app-charts-component>
      <ng-container *ngIf="createdFirstTime===true || chartCreated === true && (data.terminals.length>0 || data.metrics.length>0)">
        <div class="row mx-2 mt-4 align-self-end">
          <button class="btn btn-primary" (click)="openSaveModal()" *ngIf="viewMode === 'megapop'" style="width: fit-content">
            {{translationsLib.get('save')}}
          </button>
          <div class="col-12 input-group text-end" *ngIf="viewMode==='metrics'">
            <input type="text" class="form-control" placeholder="{{translationsLib.get('graphics_metric_name')}}" [(ngModel)]="metricName" [ngModelOptions]="{standalone: true}">
            <button class="btn btn-danger" style="width: fit-content"  [disabled]="metricName.length === 0" (click)="saveMetric(metricName)">{{translationsLib.get('save')}}</button>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>
<div class="my-4" *ngIf="viewMode==='metrics' && savedMetrics && savedMetrics.length>0">
  <app-metrics-saved [metrics]="savedMetrics"></app-metrics-saved>
</div>
<ion-modal [breakpoints]="[1]" #modal trigger="open-modal" class="auto-height">
  <ng-template>
    <div class="inner-content">
      <ion-toolbar>
        <ng-container class="row">
          <ion-buttons slot="end" style="line-height: 5px; position: absolute; top:12px;right:5px">
            <ion-button color="light" (click)="modal.dismiss()">
              <ion-icon name="close-outline"></ion-icon>
            </ion-button>
          </ion-buttons>
        </ng-container>
      </ion-toolbar>
      <form>
        <div class="card mt-4 mb-2 mx-4">
          <div class="form-group">
            <label class="form-label">{{translationsLib.get('graphics_name')}}</label>
            <input type="text" class="form-control" #name>
          </div>
          <div class="form-group">
            <label class="form-label">{{translationsLib.get('graphics_time_default')}}</label>
            <select class="form-select" #time>
              <option value="24h" selected>{{translationsLib.get('graphics_24h')}}</option>
              <option value="48h">{{translationsLib.get('graphics_48h')}}</option>
              <option value="7d">{{translationsLib.get('graphics_7d')}}</option>
              <option value="1m">{{translationsLib.get('graphics_1m')}}</option>
            </select>
          </div>

          <!--
          <h4 class="mb-0 mt-0" style="color: #224261">{{translationsLib.get('graphics_attach_field')}}</h4>
          <div class="form-check">
            <input class="form-check-input" type="checkbox" value="" [(ngModel)]="savedInFIeld" [ngModelOptions]="{standalone: true}">
            <label class="form-check-label" >
              {{translationsLib.get('graphics_add_field')}}
            </label>
          </div>

          <h4 class="mb-0"  style="color: #224261">{{translationsLib.get('graphics_attach_device')}}</h4>
          <div class="form-check">
            <input class="form-check-input" type="checkbox" value="" [(ngModel)]="savedInDevices" [ngModelOptions]="{standalone: true}">
            <label class="form-check-label" >
              {{translationsLib.get('graphics_add_device')}}
            </label>
          </div>

          <h4 class="mb-0"  style="color: #224261">{{translationsLib.get('graphics_attach_main_dashboard')}}</h4>
          <div class="form-check">
            <input class="form-check-input" type="checkbox" value="" [(ngModel)]="savedInMainDashboard" [ngModelOptions]="{standalone: true}">
            <label class="form-check-label" >
              {{translationsLib.get('graphics_add_main_dashboard')}}
            </label>
          </div>
          -->

          <button type="submit" (click)="saveGraph(name.value, time.value)" [disabled]="name.value.length===0" style="align-self: end; max-width: fit-content" class="btn btn-primary">{{translationsLib.get('graphics_save')}}</button>
        </div>
      </form>
    </div>
  </ng-template>
</ion-modal>
