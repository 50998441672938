import { Component, Input, OnInit } from '@angular/core';
import { AlarmsLibService } from '../../services/libraries/alarms-lib.service';
import { TimeLibService } from '../../services/libraries/time-lib.service';
import { HelpersLibService } from '../../services/libraries/helpers-lib.service';
import { ReportsService } from '../reports.service';
import {
  AlarmsReportModel,
  CreatedReportModel,
  ReportRequestData,
} from '../reports.model';
import { TranslationsLibService } from '@nutricontrol/app360-shared';

@Component({
  selector: 'app-reports-alarms',
  templateUrl: './reports-alarms.component.html',
  styleUrls: ['./reports-alarms.component.scss'],
  animations: [],
})
export class ReportsAlarmsComponent implements OnInit {
  reportsData: AlarmsReportModel[] = [];
  alarmColumnsToDisplay = [
    'type_str',
    'active',
    'who_activates_str',
    'begin',
    'end',
  ];
  @Input() createdReport: CreatedReportModel;
  loader = false;
  requestData: ReportRequestData;
  constructor(
    private alarmsLib: AlarmsLibService,
    private helpersLib: HelpersLibService,
    private reportsService: ReportsService,
    private timeLib: TimeLibService,
    public translationsLib: TranslationsLibService
  ) {}
  ngOnInit() {
    this.processAlarms();
  }
  public processAlarms(): void {
    const begin = this.timeLib.beginDate(
      this.createdReport.dateRange.start,
      0,
      'days'
    );
    const end = this.timeLib.endDate(
      this.createdReport.dateRange.end,
      0,
      'days'
    );
    this.loader = true;
    const terminals = [];
    this.createdReport.terminals.forEach((terminalReport, terminalId) => {
      terminals.push(terminalReport.vid);
    });
    this.reportsService
      .getAlarms({ terminals_vid: terminals }, begin, end, null)
      .subscribe((res) => {
        res.alarms_reports.forEach((alarmReport, index) => {
          this.createdReport.terminals.forEach((terminalReport, terminalId) => {
            if (alarmReport.terminal_vid === terminalReport.vid) {
              alarmReport.terminal_field_name = terminalReport.field_name;
              alarmReport.terminal_field_vid = terminalReport.field_vid;
              alarmReport.terminal_name_vid = terminalReport.name_vid;
            }
          });
          this.helpersLib.sendEventPageView(
            'Reports | Alarms | ' +
              alarmReport.terminal_name +
              '-' +
              alarmReport.terminal_vid
          );
          this.requestData = {
            terminals_vid: [alarmReport.terminal_vid],
            begin,
            end,
            type: 'alarms',
            order_type: null
          };
          alarmReport.items.forEach((alarmReportDataModel, i) => {
            alarmReport.items[i].type_str = this.alarmsLib.getName(
              alarmReport.items[i].type_str
            );
          });
          this.reportsData.push({
            reportName: alarmReport.terminal_name,
            terminalVid: alarmReport.terminal_vid,
            terminalNameVid: alarmReport.terminal_name_vid,
            terminalFieldName: alarmReport.terminal_field_name,
            terminalFieldVid: alarmReport.terminal_field_vid,
            begin: this.requestData.begin,
            end: this.requestData.end,
            ready: true,
            alarms: alarmReport.items,
          });
          if (index === res.alarms_reports.length - 1) {
            this.loader = false;
          }
        });
      });
  }
}
