<section class="fertilizer-table-container">
  <h5 class="mt-1">{{ translationsLib.get('reports_fertilizing_report') }}</h5>
  <table mat-table matSort [dataSource]="dataSource" class="mat-elevation-z8 fertilizer-table">

    <ng-container matColumnDef="groupName">
      <th mat-header-cell mat-sort-header *matHeaderCellDef class="px-2">
        {{ translationsLib.get('reports_group_name') }}
      </th>
      <td mat-cell *matCellDef="let fertilize" class="px-2">
        <span>{{ fertilize.groupName }}</span>
        <span class="warning-container" *ngIf="fertilize.incidence">
          <ion-icon mat-raised-button
                    matTooltip="{{ alarmsLib.getName(fertilize.incidence) }}"
                    name="warning-outline"></ion-icon>
        </span>
      </td>
    </ng-container>

    <ng-container matColumnDef="phase">
      <th mat-header-cell mat-sort-header *matHeaderCellDef class="px-2">
        {{ translationsLib.get('reports_fertilize_phase') }}
      </th>
      <td mat-cell *matCellDef="let fertilize" class="px-2">
        {{ fertilize.phase }}
      </td>
    </ng-container>

    <ng-container matColumnDef="timeBegin">
      <th mat-header-cell mat-sort-header *matHeaderCellDef class="px-2">
        {{ translationsLib.get('reports_timeBegin') }}
      </th>
      <td mat-cell *matCellDef="let fertilize" class="px-2">
        {{ fertilize.timeBegin }}
      </td>
    </ng-container>

    <ng-container matColumnDef="timeEnd">
      <th mat-header-cell mat-sort-header *matHeaderCellDef class="px-2">
        {{ translationsLib.get('reports_timeEnd') }}
      </th>
      <td mat-cell *matCellDef="let fertilize" class="px-2">
        {{ fertilize.timeEnd }}
      </td>
    </ng-container>

    <ng-container matColumnDef="duration">
      <th mat-header-cell mat-sort-header *matHeaderCellDef class="px-2">
        {{ translationsLib.get('reports_duration') }}
      </th>
      <td mat-cell *matCellDef="let fertilize" class="px-2">
        {{ fertilize.duration }}
      </td>
    </ng-container>
    <ng-container matColumnDef="fertilizer1">
      <th class="text-center" mat-header-cell *matHeaderCellDef class="px-2">
        {{ translationsLib.get('irrigation_fertilizer') }} 1 {{ translationsLib.get('reports_fertilizer_prog_real_l') }}
      </th>
      <td mat-cell *matCellDef="let fertilize" class="px-2">
        <app-reports-prog-real [prog]="fertilize.progConsumption.fertilizer1"  [real]="fertilize.realConsumption.fertilizer1"></app-reports-prog-real>
      </td>
    </ng-container>
    <ng-container matColumnDef="fertilizer2">
      <th class="text-center" mat-header-cell *matHeaderCellDef class="px-2">
        {{ translationsLib.get('irrigation_fertilizer') }} 2 {{ translationsLib.get('reports_fertilizer_prog_real_l') }}
      </th>
      <td mat-cell *matCellDef="let fertilize" class="px-2">
        <app-reports-prog-real [prog]="fertilize.progConsumption.fertilizer2"  [real]="fertilize.realConsumption.fertilizer2"></app-reports-prog-real>
      </td>
    </ng-container>
    <ng-container matColumnDef="fertilizer3">
      <th class="text-center" mat-header-cell *matHeaderCellDef class="px-2">
        {{ translationsLib.get('irrigation_fertilizer') }} 3 {{ translationsLib.get('reports_fertilizer_prog_real_l') }}
      </th>
      <td mat-cell *matCellDef="let fertilize" class="px-2">
        <app-reports-prog-real [prog]="fertilize.progConsumption.fertilizer3"  [real]="fertilize.realConsumption.fertilizer3"></app-reports-prog-real>
      </td>
    </ng-container>
    <ng-container matColumnDef="fertilizer4">
      <th class="text-center" mat-header-cell *matHeaderCellDef class="px-2">
        {{ translationsLib.get('irrigation_fertilizer') }} 4 {{ translationsLib.get('reports_fertilizer_prog_real_l') }}
      </th>
      <td mat-cell *matCellDef="let fertilize" class="px-2">
        <app-reports-prog-real [prog]="fertilize.progConsumption.fertilizer4"  [real]="fertilize.realConsumption.fertilizer4"></app-reports-prog-real>
      </td>
    </ng-container>
    <ng-container matColumnDef="fertilizer5">
      <th class="text-center" mat-header-cell *matHeaderCellDef class="px-2">
        {{ translationsLib.get('irrigation_fertilizer') }} 5 {{ translationsLib.get('reports_fertilizer_prog_real_l') }}
      </th>
      <td mat-cell *matCellDef="let fertilize" class="px-2">
        <app-reports-prog-real [prog]="fertilize.progConsumption.fertilizer5"  [real]="fertilize.realConsumption.fertilizer5"></app-reports-prog-real>
      </td>
    </ng-container>
    <ng-container matColumnDef="fertilizer6">
      <th class="text-center" mat-header-cell *matHeaderCellDef class="px-2">
        {{ translationsLib.get('irrigation_fertilizer') }} 6 {{ translationsLib.get('reports_fertilizer_prog_real_l') }}
      </th>
      <td mat-cell *matCellDef="let fertilize" class="px-2">
        <app-reports-prog-real [prog]="fertilize.progConsumption.fertilizer6"  [real]="fertilize.realConsumption.fertilizer6"></app-reports-prog-real>
      </td>
    </ng-container>
    <ng-container matColumnDef="fertilizer7">
      <th class="text-center" mat-header-cell *matHeaderCellDef class="px-2">
        {{ translationsLib.get('irrigation_fertilizer') }} 7 {{ translationsLib.get('reports_fertilizer_prog_real_l') }}
      </th>
      <td mat-cell *matCellDef="let fertilize" class="px-2">
        <app-reports-prog-real [prog]="fertilize.progConsumption.fertilizer7"  [real]="fertilize.realConsumption.fertilizer7"></app-reports-prog-real>
      </td>
    </ng-container>
    <ng-container matColumnDef="fertilizer8">
      <th class="text-center" mat-header-cell *matHeaderCellDef class="px-2">
        {{ translationsLib.get('irrigation_fertilizer') }} 8 {{ translationsLib.get('reports_fertilizer_prog_real_l') }}
      </th>
      <td mat-cell *matCellDef="let fertilize" class="px-2">
        <app-reports-prog-real [prog]="fertilize.progConsumption.fertilizer8"  [real]="fertilize.realConsumption.fertilizer8"></app-reports-prog-real>
      </td>
    </ng-container>
    <ng-container matColumnDef="fertilizer9">
      <th class="text-center" mat-header-cell *matHeaderCellDef class="px-2">
        {{ translationsLib.get('irrigation_fertilizer') }} 9 {{ translationsLib.get('reports_fertilizer_prog_real_l') }}
      </th>
      <td mat-cell *matCellDef="let fertilize" class="px-2">
        <app-reports-prog-real [prog]="fertilize.progConsumption.fertilizer9"  [real]="fertilize.realConsumption.fertilizer9"></app-reports-prog-real>
      </td>
    </ng-container>
    <ng-container matColumnDef="valves">
      <th mat-header-cell mat-sort-header *matHeaderCellDef> {{ translationsLib.get('irrigation_valves') }} </th>
      <td mat-cell *matCellDef="let fertilize"> {{ fertilize.valves }} </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="fertilizerColumnsToDisplay"></tr>
    <tr mat-row *matRowDef="let row; columns: fertilizerColumnsToDisplay;"></tr>

    <ng-container matColumnDef="water">
      <th class="text-center" mat-header-cell *matHeaderCellDef class="px-2">
        {{ translationsLib.get('reports_consumptions') }} {{ translationsLib.get('reports_fertilizer_prog_real_m3') }}
      </th>
      <td mat-cell *matCellDef="let fertilize" class="px-2">
        <app-reports-prog-real [prog]="fertilize.progConsumption.water" [unit]="'m3'" [real]="fertilize.realConsumption.water"></app-reports-prog-real>
      </td>
    </ng-container>
    <ng-container matColumnDef="recipe">
      <th mat-header-cell mat-sort-header *matHeaderCellDef class="px-2">
        {{ translationsLib.get('irrigation_recipe') }}
      </th>
      <td mat-cell *matCellDef="let fertilize" class="px-2">
        {{ fertilize.recipes || '-' }}
      </td>
    </ng-container>

  </table>
</section>
