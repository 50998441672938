<div class="mb-4 d-flex justify-content-center mt-5" *ngIf="isFetchingData">
  <div class="spinner-border text-warning" role="status">
    <span class="visually-hidden">{{
      this.translationsLib.get('irrigation_loading')
    }}</span>
  </div>
</div>

<app-dirty-message
  [conditions]="layoutConfiguration && waterSystemsResponse && !isFetchingData"
  [dirty]="miscellaneousForm.value.dirty"
></app-dirty-message>

<ng-container
  *ngIf="
    mode === 'simple' &&
    layoutConfiguration &&
    waterSystemsResponse &&
    !isFetchingData &&
    !isMiscellaneousWithoutData
  "
>
  <form [formGroup]="miscellaneousForm" class="simple-form">
    <!-- Misceláneo -->
    <div class="card">
      <div class="card-body custom-card-body">
        <div class="row">
          <div class="col-sm-5 col-md-5 order-1 order-md-1">
            <h6 class="fs-2">
              {{ translationsLib.get('irrigation_misc_title') }}
            </h6>
            <p class="fs-5 fw-lighter">
              {{
                translationsLib.get('irrigation_misc_title_help') | sanitizeText
              }}
            </p>
          </div>
          <div class="col-sm-7 col-md-7 order-3 order-md-2 col-inputs">
            <div class="row first-row">
              <!-- Alarma caudal -->
              <div class="col-sm-6 col-md-6">
                <h6 class="fs-5">
                  {{ translationsLib.get('irrigation_flow_alarm') }}
                </h6>
                <div class="input-group mb-2">
                  <select class="form-select" formControlName="flowAlarm">
                    <option value="NO">
                      {{ translationsLib.get('irrigation_misc_no') }}
                    </option>
                    <option value="ONLY_SEE">
                      {{ translationsLib.get('irrigation_misc_only_view') }}
                    </option>
                    <option value="STOP_PROGRAM">
                      {{ translationsLib.get('irrigation_misc_stop_program') }}
                    </option>
                  </select>
                </div>
              </div>
              <!-- Acumula radiación -->
              <div class="col-sm-6 col-md-6">
                <h6 class="fs-5">
                  {{
                    translationsLib.get('irrigation_misc_accumulate_radiation')
                  }}
                </h6>
                <div class="input-group mb-2">
                  <select
                    class="form-select"
                    formControlName="accumulateRadiation"
                  >
                    <option value="ALWAYS">
                      {{ this.translationsLib.get('always') }}
                    </option>
                    <option value="IN_SCHEDULE  ">
                      {{ translationsLib.get('irrigation_misc_on_time') }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
            <div class="row">
              <!-- Tiempo entre bombas -->
              <div class="col-sm-6 col-md-6">
                <h6 class="fs-5">
                  {{ translationsLib.get('irrigation_time_between_pumps') }}
                </h6>
                <div class="mb-2">
                  <ng-template #timeBetweenPumpsTimePicker>
                    <timepicker
                      appOnlyNumbers
                      [showMeridian]="false"
                      [showSeconds]="true"
                      [minuteStep]="1"
                      [secondsStep]="1"
                      [formControlName]="'timeBetweenPumps'"
                    ></timepicker>
                  </ng-template>
                  <input
                    appOnlyNumbers
                    [ngClass]="{
                      'ng-dirty':
                        miscellaneousForm.controls.timeBetweenPumps.dirty
                    }"
                    class="form-control text-center"
                    type="text"
                    [popover]="timeBetweenPumpsTimePicker"
                    [outsideClick]="true"
                    placement="bottom"
                    [value]="
                      miscellaneousForm.value.timeBetweenPumps | inputTime
                    "
                    (change)="
                      onChangeInputTimeWithKeyboard($event, 'timeBetweenPumps')
                    "
                  />
                </div>
              </div>
              <!-- Tiempo de solape -->
              <div class="col-sm-6 col-md-6">
                <h6 class="fs-5">
                  {{ translationsLib.get('irrigation_misc_overlap_time') }}
                </h6>
                <div class="mb-2">
                  <ng-template #overlapTimeTimePicker>
                    <timepicker
                      appOnlyNumbers
                      [showMeridian]="false"
                      [showSeconds]="true"
                      [minuteStep]="1"
                      [secondsStep]="1"
                      [formControlName]="'overlapTime'"
                    ></timepicker>
                  </ng-template>
                  <input
                    appOnlyNumbers
                    [ngClass]="{
                      'ng-dirty': miscellaneousForm.controls.overlapTime.dirty
                    }"
                    class="form-control text-center"
                    type="text"
                    [popover]="overlapTimeTimePicker"
                    [outsideClick]="true"
                    placement="bottom"
                    [value]="miscellaneousForm.value.overlapTime | inputTime"
                    (change)="
                      onChangeInputTimeWithKeyboard($event, 'overlapTime')
                    "
                  />
                </div>
              </div>
            </div>
            <div class="row justify-content-end align-items-center">
              <!-- Respetar presión mínima -->
              <div class="col-sm-6 col-md-6">
                <div class="row align-items-center justify-content-between">
                  <div class="col">
                    <h6 class="fs-5">
                      {{
                        translationsLib.get(
                          'irrigation_misc_respect_min_pressure'
                        )
                      }}
                    </h6>
                  </div>
                  <div class="col">
                    <div class="input-group">
                      <div class="form-check form-switch">
                        <input
                          formControlName="respectMinPressure"
                          class="form-check-input"
                          type="checkbox"
                          role="switch"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- Radiación umbral -->
              <div class="col-sm-6 col-md-6">
                <h6 class="fs-5">
                  {{
                    translationsLib.get('irrigation_misc_threshold_radiation')
                  }}
                </h6>
                <div class="input-group mb-2 number-input">
                  <button
                    class="minus minus-style"
                    (click)="inputNumberService.decrementValue($event)"
                  ></button>
                  <input
                    formControlName="radiationDawn"
                    type="number"
                    class="form-control"
                    placeholder="{{
                      translationsLib.get('irrigation_misc_threshold_radiation')
                    }}"
                    min="-9999"
                    max="9999"
                  />
                  <button
                    class="plus border-plus plus-style"
                    (click)="inputNumberService.incrementValue($event)"
                  ></button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Cabezales -->
    <div formGroupName="waterSystems">
      <mat-tab-group>
        <mat-tab
          *ngFor="let system of waterSystemsResponse"
          label="{{
            translationsLib.get('irrigation_water_system') +
              ' ' +
              (system.waterSystem + 1)
          }}"
        >
          <div
            class="card my-4"
            [formGroupName]="'system_' + system.waterSystem"
          >
            <div class="card-body custom-card-body">
              <div class="row">
                <div class="col-sm-5 col-md-5 order-1 order-md-1">
                  <h6 class="fs-2">
                    {{
                      translationsLib.get(
                        'irrigation_misc_water_system_configuration'
                      ) +
                        ' ' +
                        (system.waterSystem + 1)
                    }}
                  </h6>
                  <p class="fs-5 fw-lighter">
                    {{
                      translationsLib.get(
                        'irrigation_misc_water_system_configuration_help'
                      ) | sanitizeText
                    }}
                  </p>
                </div>
                <div class="col-sm-7 col-md-7 order-3 order-md-2 col-inputs">
                  <div class="row first-row">
                    <!--  Pulso agua (m³/Pulso) -->
                    <div class="col-sm-6 col-md-6">
                      <h6 class="fs-5">
                        {{ translationsLib.get('irrigation_misc_water_pulse') }}
                      </h6>
                      <div class="input-group mb-2 number-input">
                        <button
                          class="minus minus-style"
                          (click)="inputNumberService.decrementValue($event)"
                        ></button>
                        <input
                          formControlName="waterPulse"
                          type="number"
                          class="form-control"
                          placeholder="{{
                            translationsLib.get('irrigation_misc_water_pulse')
                          }}"
                          min="-9999"
                          max="9999"
                        />
                        <button
                          class="plus border-plus plus-style"
                          (click)="inputNumberService.incrementValue($event)"
                        ></button>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <!--  Caudal máximo (%) -->
                    <div class="col-sm-6 col-md-6">
                      <h6 class="fs-5">
                        {{ translationsLib.get('irrigation_misc_max_flow') }}
                      </h6>
                      <div class="input-group mb-2 number-input">
                        <button
                          class="minus minus-style"
                          (click)="inputNumberService.decrementValue($event)"
                        ></button>
                        <input
                          formControlName="maxFlow"
                          type="number"
                          class="form-control"
                          placeholder="{{
                            translationsLib.get('irrigation_misc_max_flow')
                          }}"
                          min="-9999"
                          max="9999"
                        />
                        <button
                          class="plus border-plus plus-style"
                          (click)="inputNumberService.incrementValue($event)"
                        ></button>
                      </div>
                    </div>
                    <!-- Caudal mínimo -->
                    <div class="col-sm-6 col-md-6">
                      <h6 class="fs-5">
                        {{ translationsLib.get('irrigation_misc_min_flow') }}
                      </h6>
                      <div class="input-group mb-2 number-input">
                        <button
                          class="minus minus-style"
                          (click)="inputNumberService.decrementValue($event)"
                        ></button>
                        <input
                          formControlName="minFlow"
                          type="number"
                          class="form-control"
                          placeholder="{{
                            translationsLib.get('irrigation_misc_min_flow')
                          }}"
                          min="-9999"
                          max="9999"
                        />
                        <button
                          class="plus border-plus plus-style"
                          (click)="inputNumberService.incrementValue($event)"
                        ></button>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <!-- Retardo alarma caudal -->
                    <div class="col-sm-6 col-md-6">
                      <h6 class="fs-5">
                        {{ translationsLib.get('irrigation_flow_alarm_delay') }}
                      </h6>
                      <div class="mb-2">
                        <ng-template #flowAlarmDelayTimePicker>
                          <timepicker
                            appOnlyNumbers
                            [showMeridian]="false"
                            [showSeconds]="true"
                            [minuteStep]="1"
                            [secondsStep]="1"
                            [formControlName]="'flowAlarmDelay'"
                          ></timepicker>
                        </ng-template>
                        <input
                          appOnlyNumbers
                          [ngClass]="{
                            'ng-dirty': miscellaneousForm
                              .get('waterSystems')
                              .get(['system_' + system.waterSystem])
                              .get('flowAlarmDelay').dirty
                          }"
                          class="form-control text-center"
                          type="text"
                          [popover]="flowAlarmDelayTimePicker"
                          [outsideClick]="true"
                          placement="bottom"
                          [value]="
                            miscellaneousForm.get(
                              'waterSystems.system_' +
                                system.waterSystem +
                                '.flowAlarmDelay'
                            ).value | inputTime
                          "
                          (change)="
                            onChangeInputTimeWithKeyboard(
                              $event,
                              'flowAlarmDelay',
                              system.waterSystem
                            )
                          "
                        />
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <!--  Retardo presostato máxima -->
                    <div class="col-sm-6 col-md-6">
                      <h6 class="fs-5">
                        {{
                          translationsLib.get(
                            'irrigation_misc_max_pressure_switch_delay'
                          )
                        }}
                      </h6>
                      <div class="mb-2">
                        <ng-template #maxPressureSwitchDelayTimePicker>
                          <timepicker
                            appOnlyNumbers
                            [showMeridian]="false"
                            [showSeconds]="true"
                            [minuteStep]="1"
                            [secondsStep]="1"
                            [formControlName]="'maxPressureSwitchDelay'"
                          ></timepicker>
                        </ng-template>
                        <input
                          appOnlyNumbers
                          [ngClass]="{
                            'ng-dirty': miscellaneousForm
                              .get('waterSystems')
                              .get(['system_' + system.waterSystem])
                              .get('maxPressureSwitchDelay').dirty
                          }"
                          class="form-control text-center"
                          type="text"
                          [popover]="maxPressureSwitchDelayTimePicker"
                          [outsideClick]="true"
                          placement="bottom"
                          [value]="
                            miscellaneousForm.get(
                              'waterSystems.system_' +
                                system.waterSystem +
                                '.maxPressureSwitchDelay'
                            ).value | inputTime
                          "
                          (change)="
                            onChangeInputTimeWithKeyboard(
                              $event,
                              'maxPressureSwitchDelay',
                              system.waterSystem
                            )
                          "
                        />
                      </div>
                    </div>
                    <!-- Retardo presostato mínima -->
                    <div class="col-sm-6 col-md-6">
                      <h6 class="fs-5">
                        {{
                          translationsLib.get(
                            'irrigation_misc_min_pressure_switch_delay'
                          )
                        }}
                      </h6>
                      <div class="mb-2">
                        <ng-template #minPressureSwitchDelayTimePicker>
                          <timepicker
                            appOnlyNumbers
                            [showMeridian]="false"
                            [showSeconds]="true"
                            [minuteStep]="1"
                            [secondsStep]="1"
                            [formControlName]="'minPressureSwitchDelay'"
                          ></timepicker>
                        </ng-template>
                        <input
                          appOnlyNumbers
                          [ngClass]="{
                            'ng-dirty': miscellaneousForm
                              .get('waterSystems')
                              .get(['system_' + system.waterSystem])
                              .get('minPressureSwitchDelay').dirty
                          }"
                          class="form-control text-center"
                          type="text"
                          [popover]="minPressureSwitchDelayTimePicker"
                          [outsideClick]="true"
                          placement="bottom"
                          [value]="
                            miscellaneousForm.get(
                              'waterSystems.system_' +
                                system.waterSystem +
                                '.minPressureSwitchDelay'
                            ).value | inputTime
                          "
                          (change)="
                            onChangeInputTimeWithKeyboard(
                              $event,
                              'minPressureSwitchDelay',
                              system.waterSystem
                            )
                          "
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </mat-tab>
      </mat-tab-group>
    </div>
  </form>
</ng-container>

<ng-container
  *ngIf="
    mode === 'advanced' &&
    layoutConfiguration &&
    waterSystemsResponse &&
    !isFetchingData &&
    !isMiscellaneousWithoutData
  "
>
  <form [formGroup]="miscellaneousForm" class="advanced-form">
    <div class="row">
      <div class="col">
        <!-- Misceláneo -->
        <div class="card">
          <div class="card-body custom-card-body">
            <div class="row">
              <div class="col-md-12">
                <h6 class="fs-2 input-label">
                  {{ translationsLib.get('irrigation_misc_title') }}
                </h6>
              </div>
            </div>
            <div class="row align-items-center">
              <!--  Alarma caudal -->
              <div class="col-sm-12 col-md-6 col-lg-6">
                <div class="row justify-content-between align-items-center">
                  <div class="col-md-6">
                    <h6 class="fs-5">
                      {{ translationsLib.get('irrigation_flow_alarm') }}
                    </h6>
                  </div>
                  <div class="col-md-6">
                    <div class="input-group mb-2">
                      <select class="form-select" formControlName="flowAlarm">
                        <!-- TODO: Change no to value="0" or what are the other values in english? -->
                        <option value="NO">
                          {{ translationsLib.get('irrigation_misc_no') }}
                        </option>
                        <option value="ONLY_SEE">
                          {{ translationsLib.get('irrigation_misc_only_view') }}
                        </option>
                        <option value="STOP_PROGRAM">
                          {{
                            translationsLib.get('irrigation_misc_stop_program')
                          }}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <!-- Acumula radiación -->
              <div class="col-sm-12 col-md-6 col-lg-6">
                <div class="row align-items-center justify-content-between">
                  <div class="col-md-6">
                    <h6 class="fs-5">
                      {{
                        translationsLib.get(
                          'irrigation_misc_accumulate_radiation'
                        )
                      }}
                    </h6>
                  </div>
                  <div class="col-md-6">
                    <div class="input-group mb-2">
                      <select
                        class="form-select"
                        formControlName="accumulateRadiation"
                      >
                        <option value="ALWAYS">
                          {{ this.translationsLib.get('always') }}
                        </option>
                        <option value="IN_SCHEDULE">
                          {{ translationsLib.get('irrigation_misc_on_time') }}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row align-items-center">
              <!-- Tiempo entre bombas -->
              <div class="col-sm-12 col-md-6 col-lg-6">
                <div class="row justify-content-between align-items-center">
                  <div class="col-md-6">
                    <h6 class="fs-5">
                      {{ translationsLib.get('irrigation_time_between_pumps') }}
                    </h6>
                  </div>
                  <div class="col-md-6">
                    <div class="mb-2">
                      <ng-template #timeBetweenPumpsTimePicker>
                        <timepicker
                          appOnlyNumbers
                          [showMeridian]="false"
                          [showSeconds]="true"
                          [minuteStep]="1"
                          [secondsStep]="1"
                          [formControlName]="'timeBetweenPumps'"
                        ></timepicker>
                      </ng-template>
                      <input
                        appOnlyNumbers
                        [ngClass]="{
                          'ng-dirty':
                            miscellaneousForm.controls.timeBetweenPumps.dirty
                        }"
                        class="form-control text-center"
                        type="text"
                        [popover]="timeBetweenPumpsTimePicker"
                        [outsideClick]="true"
                        placement="bottom"
                        [value]="
                          miscellaneousForm.value.timeBetweenPumps | inputTime
                        "
                        (change)="
                          onChangeInputTimeWithKeyboard(
                            $event,
                            'timeBetweenPumps'
                          )
                        "
                      />
                    </div>
                  </div>
                </div>
              </div>
              <!-- Tiempo de solape -->
              <div class="col-sm-12 col-md-6 col-lg-6">
                <div class="row justify-content-between align-items-center">
                  <div class="col-md-6">
                    <h6 class="fs-5">
                      {{ translationsLib.get('irrigation_misc_overlap_time') }}
                    </h6>
                  </div>
                  <div class="col-md-6">
                    <div class="mb-2">
                      <ng-template #overlapTimeTimePicker>
                        <timepicker
                          appOnlyNumbers
                          [showMeridian]="false"
                          [showSeconds]="true"
                          [minuteStep]="1"
                          [secondsStep]="1"
                          [formControlName]="'overlapTime'"
                        ></timepicker>
                      </ng-template>
                      <input
                        appOnlyNumbers
                        [ngClass]="{
                          'ng-dirty':
                            miscellaneousForm.controls.overlapTime.dirty
                        }"
                        class="form-control text-center"
                        type="text"
                        [popover]="overlapTimeTimePicker"
                        [outsideClick]="true"
                        placement="bottom"
                        [value]="
                          miscellaneousForm.value.overlapTime | inputTime
                        "
                        (change)="
                          onChangeInputTimeWithKeyboard($event, 'overlapTime')
                        "
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row align-items-center">
              <!-- Respetar presión mínima -->
              <div class="col-sm-12 col-md-6 col-lg-6">
                <div class="row justify-content-end align-items-center">
                  <div class="col">
                    <h6 class="fs-5">
                      {{
                        translationsLib.get(
                          'irrigation_misc_respect_min_pressure'
                        )
                      }}
                    </h6>
                  </div>
                  <div class="col">
                    <div class="input-group">
                      <div class="form-check form-switch">
                        <input
                          formControlName="respectMinPressure"
                          class="form-check-input"
                          type="checkbox"
                          role="switch"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- Radiación umbral -->
              <div class="col-sm-12 col-md-6 col-lg-6">
                <div class="row justify-content-between align-items-center">
                  <div class="col-md-6">
                    <h6 class="fs-5">
                      {{
                        translationsLib.get(
                          'irrigation_misc_threshold_radiation'
                        )
                      }}
                    </h6>
                  </div>
                  <div class="col-md-6">
                    <div class="input-group mb-2 number-input">
                      <button
                        class="minus minus-style"
                        (click)="inputNumberService.decrementValue($event)"
                      ></button>
                      <input
                        formControlName="radiationDawn"
                        type="number"
                        class="form-control"
                        placeholder="{{
                          translationsLib.get(
                            'irrigation_misc_threshold_radiation'
                          )
                        }}"
                        min="-9999"
                        max="9999"
                      />
                      <button
                        class="plus border-plus plus-style"
                        (click)="inputNumberService.incrementValue($event)"
                      ></button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!--
         Fertilizer pulses form array
        <div formArrayName="fertilizerPulses">

          Iterate over fertilizer pulses
          <div *ngFor="let fertilizer of systemForm.get('fertilizerPulses').controls; let i=index">

            <div [formGroupName]="i">

              <label>Fertilizer</label>
              <input type="text" formControlName="fertilizer">

              <label>Pulse</label>
              <input type="text" formControlName="pulse">

            </div>

          </div>
          End iterate over fertilizer pulses

          <button (click)="addFertilizerPulse(systemForm)">Add Fertilizer Pulse</button>

        </div>
         End fertilizer pulses form array
         -->

        <!-- Cabezales -->
        <div formGroupName="waterSystems">
          <mat-tab-group>
            <mat-tab
              *ngFor="let system of waterSystemsResponse"
              label="{{
                translationsLib.get('irrigation_water_system') +
                  ' ' +
                  (system.waterSystem + 1)
              }}"
            >
              <div
                class="card my-4"
                [formGroupName]="'system_' + system.waterSystem"
              >
                <div class="card-body custom-card-body">
                  <div class="row">
                    <div class="col-md-12">
                      <h6 class="fs-2 input-label">
                        {{
                          translationsLib.get(
                            'irrigation_misc_water_system_configuration'
                          ) +
                            ' ' +
                            (system.waterSystem + 1)
                        }}
                      </h6>
                    </div>
                  </div>
                  <div class="row align-items-center">
                    <!--  Pulso agua (m³/Pulso) -->
                    <div class="col-sm-12 col-md-6 col-lg-6">
                      <div
                        class="row align-items-center justify-content-between"
                      >
                        <div class="col-md-6">
                          <h6 class="fs-5">
                            {{
                              translationsLib.get('irrigation_misc_water_pulse')
                            }}
                          </h6>
                        </div>
                        <div class="col-md-6">
                          <div class="input-group mb-2 number-input">
                            <button
                              class="minus minus-style"
                              (click)="inputNumberService.decrementValue($event)"
                            ></button>
                            <input
                              formControlName="waterPulse"
                              type="number"
                              class="form-control"
                              placeholder="{{
                                translationsLib.get(
                                  'irrigation_misc_water_pulse'
                                )
                              }}"
                              min="-9999"
                              max="9999"
                            />
                            <button
                              class="plus border-plus plus-style"
                              (click)="inputNumberService.incrementValue($event)"
                            ></button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row align-items-center">
                    <!--  Caudal máximo (%) -->
                    <div class="col-sm-12 col-md-6 col-lg-6">
                      <div
                        class="row justify-content-between align-items-center"
                      >
                        <div class="col-md-6">
                          <h6 class="fs-5">
                            {{
                              translationsLib.get('irrigation_misc_max_flow')
                            }}
                          </h6>
                        </div>
                        <div class="col-md-6">
                          <div class="input-group mb-2 number-input">
                            <button
                              class="minus minus-style"
                              (click)="inputNumberService.decrementValue($event)"
                            ></button>
                            <input
                              formControlName="maxFlow"
                              type="number"
                              class="form-control"
                              placeholder="{{
                                translationsLib.get('irrigation_misc_max_flow')
                              }}"
                              min="-9999"
                              max="9999"
                            />
                            <button
                              class="plus border-plus plus-style"
                              (click)="inputNumberService.incrementValue($event)"
                            ></button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- Caudal mínimo -->
                    <div class="col-sm-12 col-md-6 col-lg-6">
                      <div
                        class="row justify-content-between align-items-center"
                      >
                        <div class="col-md-6">
                          <h6 class="fs-5">
                            {{
                              translationsLib.get('irrigation_misc_min_flow')
                            }}
                          </h6>
                        </div>
                        <div class="col-md-6">
                          <div class="input-group mb-2 number-input">
                            <button
                              class="minus minus-style"
                              (click)="inputNumberService.decrementValue($event)"
                            ></button>
                            <input
                              formControlName="minFlow"
                              type="number"
                              class="form-control"
                              placeholder="{{
                                translationsLib.get('irrigation_misc_min_flow')
                              }}"
                              min="-9999"
                              max="9999"
                            />
                            <button
                              class="plus border-plus plus-style"
                              (click)="inputNumberService.incrementValue($event)"
                            ></button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row align-items-center">
                    <!-- Retardo alarma caudal -->
                    <div class="col-sm-12 col-md-6 col-lg-6">
                      <div
                        class="row justify-content-between align-items-center"
                      >
                        <div class="col-md-6">
                          <h6 class="fs-5">
                            {{
                              translationsLib.get('irrigation_flow_alarm_delay')
                            }}
                          </h6>
                        </div>
                        <div class="col-md-6">
                          <div class="mb-2">
                            <ng-template #flowAlarmDelayTimePicker>
                              <timepicker
                                appOnlyNumbers
                                [showMeridian]="false"
                                [showSeconds]="true"
                                [minuteStep]="1"
                                [secondsStep]="1"
                                [formControlName]="'flowAlarmDelay'"
                              ></timepicker>
                            </ng-template>
                            <input
                              appOnlyNumbers
                              [ngClass]="{
                                'ng-dirty': miscellaneousForm
                                  .get('waterSystems')
                                  .get(['system_' + system.waterSystem])
                                  .get('flowAlarmDelay').dirty
                              }"
                              class="form-control text-center"
                              type="text"
                              [popover]="flowAlarmDelayTimePicker"
                              [outsideClick]="true"
                              placement="bottom"
                              [value]="
                                miscellaneousForm.get(
                                  'waterSystems.system_' +
                                    system.waterSystem +
                                    '.flowAlarmDelay'
                                ).value | inputTime
                              "
                              (change)="
                                onChangeInputTimeWithKeyboard(
                                  $event,
                                  'flowAlarmDelay',
                                  system.waterSystem
                                )
                              "
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row align-items-center">
                    <!--  Retardo presostato máxima -->
                    <div class="col-sm-12 col-md-6 col-lg-6">
                      <div
                        class="row justify-content-between align-items-center"
                      >
                        <div class="col-md-6">
                          <h6 class="fs-5">
                            {{
                              translationsLib.get(
                                'irrigation_misc_max_pressure_switch_delay'
                              )
                            }}
                          </h6>
                        </div>
                        <div class="col-md-6">
                          <div class="mb-2">
                            <ng-template #maxPressureSwitchDelayTimePicker>
                              <timepicker
                                appOnlyNumbers
                                [showMeridian]="false"
                                [showSeconds]="true"
                                [minuteStep]="1"
                                [secondsStep]="1"
                                [formControlName]="'maxPressureSwitchDelay'"
                              ></timepicker>
                            </ng-template>
                            <input
                              appOnlyNumbers
                              [ngClass]="{
                                'ng-dirty': miscellaneousForm
                                  .get('waterSystems')
                                  .get(['system_' + system.waterSystem])
                                  .get('maxPressureSwitchDelay').dirty
                              }"
                              class="form-control text-center"
                              type="text"
                              [popover]="maxPressureSwitchDelayTimePicker"
                              [outsideClick]="true"
                              placement="bottom"
                              [value]="
                                miscellaneousForm.get(
                                  'waterSystems.system_' +
                                    system.waterSystem +
                                    '.maxPressureSwitchDelay'
                                ).value | inputTime
                              "
                              (change)="
                                onChangeInputTimeWithKeyboard(
                                  $event,
                                  'maxPressureSwitchDelay',
                                  system.waterSystem
                                )
                              "
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- Retardo presostato mínima -->
                    <div class="col-sm-12 col-md-6 col-lg-6">
                      <div
                        class="row align-items-center justify-content-between"
                      >
                        <div class="col-md-6">
                          <h6 class="fs-5">
                            {{
                              translationsLib.get(
                                'irrigation_misc_min_pressure_switch_delay'
                              )
                            }}
                          </h6>
                        </div>
                        <div class="col-md-6">
                          <div class="mb-2">
                            <ng-template #minPressureSwitchDelayTimePicker>
                              <timepicker
                                appOnlyNumbers
                                [showMeridian]="false"
                                [showSeconds]="true"
                                [minuteStep]="1"
                                [secondsStep]="1"
                                [formControlName]="'minPressureSwitchDelay'"
                              ></timepicker>
                            </ng-template>
                            <input
                              appOnlyNumbers
                              [ngClass]="{
                                'ng-dirty': miscellaneousForm
                                  .get('waterSystems')
                                  .get(['system_' + system.waterSystem])
                                  .get('minPressureSwitchDelay').dirty
                              }"
                              class="form-control text-center"
                              type="text"
                              [popover]="minPressureSwitchDelayTimePicker"
                              [outsideClick]="true"
                              placement="bottom"
                              [value]="
                                miscellaneousForm.get(
                                  'waterSystems.system_' +
                                    system.waterSystem +
                                    '.minPressureSwitchDelay'
                                ).value | inputTime
                              "
                              (change)="
                                onChangeInputTimeWithKeyboard(
                                  $event,
                                  'minPressureSwitchDelay',
                                  system.waterSystem
                                )
                              "
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </mat-tab>
          </mat-tab-group>
        </div>
      </div>
    </div>
  </form>
</ng-container>
