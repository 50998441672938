import {
  AfterViewInit,
  Component,
  HostListener,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Breadcrumb } from '../../../shared/shared.model';
import { ActivatedRoute, Router } from '@angular/router';
import { IrrigationService } from '../../../irrigation.service';
import { HelpersLibService } from '../../../../../services/libraries/helpers-lib.service';
import {
  MixtureValvesIrrigationInstallerModel,
  SafeData,
} from '../../../irrigation.model';
import { map, startWith } from 'rxjs/operators';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import {
  TranslationsLibService,
  TerminalDataModel,
} from '@nutricontrol/app360-shared';
import { IonContent } from '@ionic/angular';

@Component({
  selector: 'app-mixture-valves-edit',
  templateUrl: './mixture-valves-edit.component.html',
  styleUrls: ['./mixture-valves-edit.component.scss'],
})
export class MixtureValvesEditComponent
  implements OnInit, AfterViewInit, SafeData
{
  mode = '';
  isFetchingData = false;
  isFormFieldWithErrors = false;
  mixtureValves: MixtureValvesIrrigationInstallerModel[];
  mixtureValvesControl = new FormControl<
    string | MixtureValvesIrrigationInstallerModel
  >('');
  filteredOptions: Observable<MixtureValvesIrrigationInstallerModel[]>;
  breadcrumbs: Breadcrumb[] = [];
  mixtureValve: any;
  isMixtureValvesWithoutData = false;
  isFetchingEquipmentData = false;
  mixture_valve_id: number;
  mixtureValveValue: string;
  terminalData: TerminalDataModel;

  terminal_vid: string;
  isDirty = false;
  @ViewChild(IonContent, { static: false }) private content: IonContent;
  hasScrollbar = false;
  saveButtonTimeElapsed = false;

  constructor(
    private router: Router,
    private irrigationService: IrrigationService,
    private helpersLib: HelpersLibService,
    private activatedRoute: ActivatedRoute,
    public translationsLib: TranslationsLibService
  ) {}

  /**
   * https://stackoverflow.com/questions/56882873/how-to-detect-if-ion-content-has-a-scrollbar/58579938#58579938
   * Apply CSS class based on vertical scroll (save button)
   */

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.checkForScrollbar();
  }

  async checkForScrollbar() {
    const scrollElement = await this.content.getScrollElement();
    this.hasScrollbar = scrollElement.scrollHeight > scrollElement.clientHeight;
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.saveButtonTimeElapsed = true;
      this.checkForScrollbar();
    }, 3000);

    this.activatedRoute.paramMap.subscribe((_) => {
      this.helpersLib.setTitle(
        '',
        `${this.translationsLib.get('irrigation_mixture_valve')} ${
          this.mixture_valve_id + 1
        }`,
        null
      );
    });
  }

  isDataSaved(): boolean {
    return this.isDirty;
  }

  hasWritePermissions(): boolean {
    return this.terminalData.authed_user_can_write;
  }

  ngOnInit() {
    const [, , vid, , , , data] = this.router.url.split('/');
    this.terminal_vid = vid;
    this.mixture_valve_id = Number(data);

    this.generateBreadcrumbs();

    this.filteredOptions = this.mixtureValvesControl.valueChanges.pipe(
      startWith(''),
      map((value) => {
        const mixtureValve =
          typeof value === 'string' ? value : value?.mixtureValve;
        return mixtureValve
          ? this._filter(mixtureValve as string)
          : this.mixtureValves.slice();
      })
    );

    this.getMixtureValvesList();
  }

  generateBreadcrumbs() {
    this.isFetchingEquipmentData = true;
    this.breadcrumbs = [];
    this.irrigationService.getTerminal(this.terminal_vid).subscribe((res) => {
      this.terminalData = res;
      if (res.field) {
        this.breadcrumbs.push({
          text: res.field.name,
          disabled: false,
          to: ['/field', { vid: res.field.vid }],
          active: false,
        });
      }

      this.breadcrumbs.push(
        {
          text: res.name,
          disabled: false,
          to: `/farming/${this.terminal_vid}/irrigation`,
          active: false,
        },
        {
          text: this.translationsLib.get('irrigation_configuration'),
          disabled: false,
          to: `/farming/${this.terminal_vid}/irrigation/5`,
          active: false,
        },
        {
          text: this.mixtureValveValue
            ? this.mixtureValveValue
            : `${this.translationsLib.get('irrigation_mixture_valve')} ${
                this.mixture_valve_id + 1
              }`,
          disabled: false,
          active: true,
        }
      );

      this.isFetchingEquipmentData = false;
    });
  }

  private _filter(
    mixtureValve: string
  ): MixtureValvesIrrigationInstallerModel[] {
    const filterValue = mixtureValve.toLowerCase();

    return this.mixtureValves.filter((option) =>
      (
        this.translationsLib.get('irrigation_mixture_valves') +
        ' ' +
        (option.mixtureValve + 1).toString()
      )
        .toLowerCase()
        .includes(filterValue)
    );
  }

  onChangePagination(event) {
    this.router.navigate([
      `/farming/${this.terminal_vid}/irrigation/irrigation_installer/mixture_valves/${event.mixtureValve}`,
    ]);
  }

  autocompleteDisplayFn(mixtureValve: MixtureValvesIrrigationInstallerModel) {
    return mixtureValve && mixtureValve.mixtureValve
      ? this.translationsLib.get('irrigation_mixture_valve') +
          ' ' +
          (mixtureValve.mixtureValve + 1)
      : '';
  }

  getMixtureValvesList() {
    this.isFetchingData = true;

    this.irrigationService
      .getIrrigationInstallerMixtureValves(this.terminal_vid)
      .subscribe((res) => {
        this.mixtureValves = res;

        if (this.mixtureValves.length === 0)
          this.isMixtureValvesWithoutData = true;

        this.isFetchingData = false;
      });
  }
}
