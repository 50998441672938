<div class="equipment-data-component equipment-{{ terminalData.pretty_type_name }} position-relative" [ngClass]="cardClass">
  <div class="row g-0 g-md-4 mt-0 p-3 pb-lg-3">
    <div class="col-lg-1 d-none d-lg-block align-self-center m-md-auto">
      <img *ngIf="terminalData?.image_path"
           src="{{ baseURL + '/' + terminalData?.image_path }}"
           class="terminal-image"
           alt="{{ terminalData?.vid }}"
      />
    </div>
    <div class="col-12 col-lg-9 col-md-10 mt-md-0">
      <div class="row g-0 g-md-4">
        <div class="col-6 col-md-5 mb-2 order-md-1">
          <div class="text-uppercase equipment-data-label fs-6 fs-md-5">
            {{ translationsLib.get('terminal_terminal') }}
          </div>
          <div class="equipment-data-value fw-bold fs-4 fs-md-1">
            {{ terminalData?.name }}
          </div>
        </div>

        <div class="col-6 col-md-4 mb-2 order-md-2">
          <div class="text-uppercase equipment-data-label fs-6 fs-md-5">
            {{ translationsLib.get('terminal_serial_number') }}
          </div>
          <div class="equipment-data-value fs-4">
            {{ terminalData?.vid }}
          </div>
        </div>

        <div class="col-6 col-md-5 mb-2 order-md-4 mt-md-0" style="filter: blur(4px)">
          <div class="text-uppercase equipment-data-label fs-6 fs-md-5">
            <ng-container *ngIf="type !== 'datalogger'">
              {{ translationsLib.get('terminal_system_time') }}
            </ng-container>
            <ng-container *ngIf="type === 'datalogger'">
              {{ translationsLib.get('DL_PILLS_BATTERY') }} /
              {{ translationsLib.get('DL_PILLS_SIGNAL') }}
            </ng-container>
          </div>
          <div class="equipment-data-value fs-4">
            <ng-container *ngIf="type !== 'datalogger'">
              XX/XX/XXXX XX:XX:XX
            </ng-container>
            <ng-container *ngIf="type === 'datalogger'">
              XXXX mV / XXX %
            </ng-container>
          </div>
        </div>

        <div class="col-6 col-md-4 mb-2 order-md-5 mt-md-0" style="filter: blur(4px)">
          <div class="text-uppercase equipment-data-label fs-6 fs-md-5">
            {{ translationsLib.get('terminal_model') }}
            /
            {{ translationsLib.get('terminal_version') }}
          </div>
          <div class="equipment-data-value fs-4">
            XXXXXX / XXXXXX
          </div>
        </div>

        <div class="col-6 col-md-3 mb-2 order-md-3" style="filter: blur(4px)">
          <div class="text-uppercase equipment-data-label fs-6 fs-md-5">
            {{ translationsLib.get('terminal_last_connection') }}
          </div>
          <div class="equipment-data-value fs-4">
            XX/XX/XXXX XX:XX:XX
          </div>
        </div>

        <div class="col-6 col-md-3 mb-2 order-md-last mt-md-0">
          <div class="text-uppercase equipment-data-label fs-6 fs-md-5">
            {{ translationsLib.get('terminal_status') }}
          </div>
          <div class="equipment-data-value fs-4">
            <span class="badge bg-warning">
              {{ translationsLib.get('terminal_status_temporary_disconnected') }}
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 col-md-2 m-0 d-flex"></div>
  </div>
</div>
