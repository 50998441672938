import { Component, Input, OnInit } from '@angular/core';
import {
  SessionLibService,
  UserIdentityDataModel,
} from '@nutricontrol/app360-shared';
import { TranslationsLibService } from '@nutricontrol/app360-shared';
import { HelpersLibService } from '../../services/libraries/helpers-lib.service';
import { NotificationsService } from '../notifications.service';
import { UsersLibService } from '../../services/libraries/users-lib.service';
import { MainHeaderComponent } from '../../commons/components/main-header/main-header.component';
import { InvitationDataModel, INVITATIONS_TYPES } from '../notifications.model';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-invitations',
  templateUrl: './invitations.component.html',
  styleUrls: ['./invitations.component.scss'],
})
export class InvitationsComponent implements OnInit {
  @Input() showSideHelp = true;
  @Input() invitations: InvitationDataModel[] = [];
  @Input() user: UserIdentityDataModel = null;
  public userEmail = '';
  //private pendingInvitations = 0;
  ready = false;
  constructor(
    private session: SessionLibService,
    public translationsLib: TranslationsLibService,
    private helpersLib: HelpersLibService,
    private notificationsService: NotificationsService,
    public usersLib: UsersLibService,
    private headerService: MainHeaderComponent
  ) {}

  ngOnInit() {
    this.helpersLib.setTitle(
      this.translationsLib.get('notification_notifications'),
      this.translationsLib.get('notification_notifications'),
      null
    );
    this.helpersLib.sendEventPageView('notificaciones');
    //this.getPending();

    // If invitations has not been passed as input parameter (used in the BO), just ask for current user invitations
    if (this.invitations.length === 0) {
      this.getInvitations();
    }
    // If user has not been passed as input parameter (used in the BO), just check for current user invitations
    if (this.user === null) {
      this.userEmail = this.userEmail = this.session.getSessionEmail();
    } else {
      this.userEmail = this.user.email;
    }
  }
  getInvitations() {
    this.ready = false;
    this.notificationsService.getInvitations().subscribe((res) => {
      this.invitations = res.items;
      setTimeout(() => {
        this.ready = true;
      }, 100);
    });
  }
  //getPending() {
  //  this.notificationsService.getPendingInvitations().subscribe((res) => {
  //    this.pendingInvitations = res.pending_invitations;
  //  });
  //}
  putInvitationResponse(vid, mode, type) {
    const data = {
      status: type,
      mode,
    };
    this.notificationsService.putInvitationResponse(vid, data).subscribe(() => {
      let swal_text = '';
      switch (type) {
        case INVITATIONS_TYPES.ACCEPTED:
          swal_text = this.translationsLib.get(
            'email_invitation_title_accepted'
          );
          break;
        case INVITATIONS_TYPES.REJECTED:
          swal_text = this.translationsLib.get(
            'email_invitation_title_rejected'
          );
          break;
        case INVITATIONS_TYPES.CANCELLED:
          swal_text = this.translationsLib.get(
            'email_invitation_title_cancelled'
          );
          break;
      }
      if (swal_text !== '') {
        Swal.fire({
          text: swal_text,
          icon: 'success',
          confirmButtonColor: '#224261',
        });
      }
      this.getInvitations();
    });
  }
  putResendEmail(vid, mode) {
    const data = {
      mode,
    };
    this.notificationsService.putResendEmail(vid, data).subscribe(() => {
      this.getInvitations();
      this.headerService.getPending();
    });
  }
  getRolText(type) {
    return this.usersLib.getRolText(type);
  }
  reload() {
    location.reload();
  }
}
