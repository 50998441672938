<div *ngIf="loaded">
  <highcharts-chart id="graphics"
                    [Highcharts]="Highcharts"
                    [constructorType]="chartConstructor"
                    [options]="meteoChart"
                    [(update)]="updateFlag"
                    [callbackFunction]="chartCallback"
                    [oneToOne]="true"
                    style="height: 150px; display: block;"
  ></highcharts-chart>
</div>
