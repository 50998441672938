import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { IrrigationService } from '../../../irrigation.service';
import { ActivatedRoute, Router } from '@angular/router';
import {
  DateToSecondsInput,
  removeDirtyInputs,
  secondsInputToDate,
  convertFormattedTimeToSeconds,
  saveDataAndShowModal,
  saveSubmitWithTerminalStatus,
} from '../../../../../commons/helpers/functions';
import { Progalarm } from '../../../irrigation.model';
import Swal from 'sweetalert2';
import { TranslationsLibService } from '@nutricontrol/app360-shared';
import { EquipmentsLibService } from '../../../../../services/libraries/equipments-lib.service';
import { Subscription } from 'rxjs';
import { FormFieldHandlerService } from '../../../../libraries/form-field-handler.service';
import { environment } from '../../../../../../environments/environment';
import { InputNumberService } from '../../../../libraries/input-number.service';

@Component({
  selector: 'app-meteo-alarms-form',
  templateUrl: './meteo-alarms-form.component.html',
  styleUrls: ['./meteo-alarms-form.component.scss'],
})
export class MeteoAlarmsFormComponent implements OnInit, OnDestroy {
  isFetchingData = false;
  isFormSubmitted = false;
  private formFieldErrorSubscription: Subscription;
  isConnected: boolean;
  isMeteoAlarmsWithoutData = false;
  private intervalId: any;
  meteoAlarmsForm: UntypedFormGroup;
  selectedPagination;
  meteoAlarmsResponse;
  @Input() mode: string;
  @Input() terminal_vid: string;
  @Input() meteo_alarm_id: number;
  @Output() dirtyEventEmitter = new EventEmitter<boolean>();
  @Output() formFieldErrorEventEmitter = new EventEmitter<boolean>();
  @Output() isMeteoAlarmsWithoutDataEventEmitter = new EventEmitter<boolean>(
    false
  );

  isErrorInMeteoAlarmsPost = false;

  layoutConfiguration: Progalarm;
  constructor(
    private fb: UntypedFormBuilder,
    private irrigationService: IrrigationService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    public translationsLib: TranslationsLibService,
    private equipmentLib: EquipmentsLibService,
    private formFieldHandlerService: FormFieldHandlerService,
    public inputNumberService: InputNumberService
  ) {
    this.meteoAlarmsForm = this.fb.group({
      rainDelayTime: [null, Validators.required],
      dirty: [''],
      rainNoAlarmConfirmationTime: [null, Validators.required],
      rainPluviometerFlow: [null, Validators.required],
      stormDelayTime: [null, Validators.required],
      stormNoAlarmConfirmationTime: [null, Validators.required],
      stormWindSpeed: [null, Validators.required],
      frostDelayTime: [null, Validators.required],
      frostNoAlarmConfirmationTime: [null, Validators.required],
      frostFrostTemperature: [null, Validators.required],
      frostStartRadiationCorrection: [null, Validators.required],
      frostEndRadiationCorrection: [null, Validators.required],
      frostMaxTemperatureCorrection: [null, Validators.required],
    });
  }

  onChangeInputTimeWithKeyboard(event, formInput: string) {
    this.meteoAlarmsForm.markAsDirty();
    event.target.classList.add('ng-dirty');

    const toSeconds = convertFormattedTimeToSeconds(event.target.value);
    const toDate = secondsInputToDate(toSeconds);

    this.meteoAlarmsForm.get(formInput).setValue(toDate);
  }

  getIrrigationEquipment() {
    this.irrigationService
      .getIrrigationTerminal(this.terminal_vid)
      .subscribe((res) => {
        this.isConnected = res.connected;
      });
  }

  async startInterval() {
    this.intervalId = setInterval(() => {
      this.performTasks();
    }, environment.intervalDefaultTimeout);
  }

  async performTasks() {
    this.meteoAlarmsForm.reset();

    this.getTerminalLayoutConfiguration();
    this.getMeteoAlarms();

    await this.getIrrigationEquipment();

    if (!this.isConnected) {
      this.equipmentLib.showConnectivityAlert();
    }

    this.isFormSubmitted = false;
  }

  ngOnInit() {
    this.meteoAlarmsForm.reset(); // Prevent unsaved data to persist in form inputs

    this.activatedRoute.params.subscribe((_) => {
      this.getTerminalLayoutConfiguration();
      this.getMeteoAlarms();

      removeDirtyInputs(this.meteoAlarmsForm);
    });

    this.meteoAlarmsForm.valueChanges.subscribe((_) => {
      this.dirtyEventEmitter.emit(this.getIsDirty());

      if (this.getIsDirty()) {
        clearInterval(this.intervalId);
      } else {
        clearInterval(this.intervalId);
        this.startInterval();
      }
    });

    this.getIrrigationEquipment();
  }

  getTerminalLayoutConfiguration() {
    this.irrigationService
      .getTerminalLayoutConfiguration(this.terminal_vid)
      .subscribe((res) => {
        this.layoutConfiguration = res.screens_configuration.progalarm;
        if (!this.layoutConfiguration.enabled) {
          this.router.navigate(['/home/dashboard']);
        }
      });
  }

  setDirty(dirty: boolean) {
    this.meteoAlarmsForm.patchValue({ isDirty: dirty });
  }

  getIsDirty() {
    return this.meteoAlarmsForm.dirty || this.meteoAlarmsForm.value.isDirty;
  }

  getMeteoAlarms(isGetAfterPost = false) {
    if (!isGetAfterPost) this.isFetchingData = true;
    const pagination = 0;

    this.irrigationService
      .getMeteoAlarms(this.terminal_vid)
      .subscribe((res) => {
        this.meteoAlarmsResponse = res;

        if (this.meteoAlarmsResponse.length === 0) {
          this.isMeteoAlarmsWithoutData = true;
          this.isMeteoAlarmsWithoutDataEventEmitter.emit(true);
        } else {
          this.selectedPagination = 0;

          this.meteoAlarmsForm.patchValue({
            dirty: res[pagination].dirty,
            rainDelayTime: secondsInputToDate(res[pagination].rainDelayTime),
            rainNoAlarmConfirmationTime: secondsInputToDate(
              res[pagination].rainNoAlarmConfirmationTime
            ),
            rainPluviometerFlow: res[pagination].rainPluviometerFlow,
            stormDelayTime: secondsInputToDate(res[pagination].stormDelayTime),
            stormNoAlarmConfirmationTime: secondsInputToDate(
              res[pagination].stormNoAlarmConfirmationTime
            ),
            stormWindSpeed: res[pagination].stormWindSpeed,
            frostDelayTime: secondsInputToDate(res[pagination].frostDelayTime),
            frostNoAlarmConfirmationTime: secondsInputToDate(
              res[pagination].frostNoAlarmConfirmationTime
            ),
            frostFrostTemperature: res[pagination].frostFrostTemperature,
            frostStartRadiationCorrection:
              res[pagination].frostStartRadiationCorrection,
            frostEndRadiationCorrection:
              res[pagination].frostEndRadiationCorrection,
            frostMaxTemperatureCorrection:
              res[pagination].frostMaxTemperatureCorrection,
          });
        }

        if (!isGetAfterPost) this.isFetchingData = false;
      });
  }

  confirmSave() {
    Swal.fire({
      title: this.translationsLib.get('cannot_be_undone1'),
      text: this.translationsLib.get('irrigation_program_context_warning'),
      showDenyButton: true,
      showCancelButton: true,
      confirmButtonText: this.translationsLib.get('accept'),
      denyButtonText: this.translationsLib.get('cancel'),
    }).then((result) => {
      if (result.isConfirmed) {
        this.submitForm();
      } else if (result.isDenied) {
        Swal.fire(this.translationsLib.get('something_was_wrong'), '', 'info');
      }
    });
  }

  handlePost(showModal: boolean, connected: boolean): Promise<boolean> {
    return new Promise<boolean>((resolve, _) => {
      this.isFormSubmitted = true;

      const meteoAlarmsAsArray = [];

      meteoAlarmsAsArray.push({
        rainDelayTime: DateToSecondsInput(
          this.meteoAlarmsForm.value.rainDelayTime
        ),
        rainNoAlarmConfirmationTime: DateToSecondsInput(
          this.meteoAlarmsForm.value.rainNoAlarmConfirmationTime
        ),
        rainPluviometerFlow: this.meteoAlarmsForm.value.rainPluviometerFlow,
        stormDelayTime: DateToSecondsInput(
          this.meteoAlarmsForm.value.stormDelayTime
        ),
        stormNoAlarmConfirmationTime: DateToSecondsInput(
          this.meteoAlarmsForm.value.stormNoAlarmConfirmationTime
        ),
        stormWindSpeed: this.meteoAlarmsForm.value.stormWindSpeed,
        frostDelayTime: DateToSecondsInput(
          this.meteoAlarmsForm.value.frostDelayTime
        ),
        frostNoAlarmConfirmationTime: DateToSecondsInput(
          this.meteoAlarmsForm.value.frostNoAlarmConfirmationTime
        ),
        frostFrostTemperature: this.meteoAlarmsForm.value.frostFrostTemperature,
        frostStartRadiationCorrection:
          this.meteoAlarmsForm.value.frostStartRadiationCorrection,
        frostEndRadiationCorrection:
          this.meteoAlarmsForm.value.frostEndRadiationCorrection,
        frostMaxTemperatureCorrection:
          this.meteoAlarmsForm.value.frostMaxTemperatureCorrection,
      });

      try {
        this.irrigationService
          .postMeteoAlarms(this.terminal_vid, meteoAlarmsAsArray)
          .subscribe(
            (res) => {
              if (res.error) this.isErrorInMeteoAlarmsPost = true;

              if (
                connected &&
                !this.meteoAlarmsForm.value.dirty &&
                !this.isErrorInMeteoAlarmsPost
              ) {
                saveDataAndShowModal(
                  res,
                  showModal,
                  this.translationsLib.get('error_updating_data'),
                  this.translationsLib.get('save_changes_success'),
                  this.translationsLib.get('accept'),
                  () => {
                    setTimeout(() => {
                      this.getMeteoAlarms(true);
                    }, 4000);
                  }
                );

                this.isFormSubmitted = false;
                resolve(true);
              } else if (this.isErrorInMeteoAlarmsPost) {
                Swal.fire({
                  text: this.translationsLib.get(
                    'irrigation_general_program_error'
                  ),
                  showConfirmButton: true,
                  confirmButtonText: this.translationsLib.get('accept'),
                  icon: 'error',
                });

                this.isFormSubmitted = false;
                resolve(true);
              } else {
                saveSubmitWithTerminalStatus(
                  res,
                  showModal,
                  connected,
                  this.translationsLib.get('error_updating_data'),
                  this.translationsLib.get('save_changes_success'),
                  this.translationsLib.get('accept'),
                  this.translationsLib.get(
                    'irrigation_disconnected_terminal_pending_changes'
                  )
                );

                this.isFormSubmitted = false;
                resolve(true);
              }
            },
            (_) => {
              if (showModal) {
                Swal.fire({
                  text: this.translationsLib.get('something_was_wrong'),
                  showConfirmButton: true,
                  confirmButtonText: this.translationsLib.get('accept'),
                  icon: 'error',
                });
              }
              this.isFormSubmitted = false;
              resolve(false);
            }
          );
      } catch (err) {
        throw new Error(err.message);
      }
    });
  }

  submitForm(
    showModal: boolean = true
  ): Promise<{ result: boolean; connected: boolean }> {
    return new Promise<{ result: boolean; connected: boolean }>(
      (resolve, reject) => {
        try {
          this.irrigationService
            .getIrrigationTerminal(this.terminal_vid)
            .subscribe((res) => {
              this.handlePost(showModal, res.connected).then((result) => {
                resolve({ result, connected: res.connected });
              });
            });
        } catch (err) {
          reject({ result: false, connected: false });
        } finally {
          // Remove dirty inputs due to use standalone ngModels
          removeDirtyInputs(this.meteoAlarmsForm);

          this.meteoAlarmsForm.markAsUntouched();

          this.meteoAlarmsForm.markAsPristine();
          this.meteoAlarmsForm.patchValue({ isDirty: false });
        }
      }
    );
  }

  ngOnDestroy(): void {
    clearInterval(this.intervalId);
    this.formFieldErrorSubscription?.unsubscribe();
  }
}
