<app-main-header
  [name]="'Info'"
  [breadcrumbs]="breadcrumbs"
></app-main-header>
<ion-content>
  <div class="container-fluid p-1 p-lg-2">
    <div class="m-4">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="col-12">
              <div class="row">
                <div class="col-12">
                  <h4 class="title">
                    {{ translationsLib.get('cookie_policy') }}
                  </h4>
                </div>
              </div>
              <div class="row">
                <div class="col-12 col-md-8 d-none d-md-block">
                  &nbsp;
                </div>
                <div class="col-12 col-md-4 text-end">
                  <button class="btn btn-warning" (click)="this.legalAgreementsLib.showCookiePolicy()">
                    {{ translationsLib.get('view_more') }}
                  </button>
                </div>
              </div>

              <div class="row">
                <div class="col-12">
                  <h4 class="title">
                    {{ translationsLib.get('legal_advice') }}
                  </h4>
                </div>
              </div>
              <div class="row">
                <div class="col-12 col-md-8 d-none d-md-block">
                  &nbsp;
                </div>
                <div class="col-12 col-md-4 text-end">
                  <button class="btn btn-warning" (click)="legalAgreementsLib.showLegalAdvice()">
                    {{ translationsLib.get('view_more') }}
                  </button>
                </div>
              </div>

              <div class="row">
                <div class="col-12">
                  <h4 class="title">
                    {{ translationsLib.get('privacy_policy') }}
                  </h4>
                </div>
              </div>
              <div class="row">
                <div class="col-12 col-md-8 d-none d-md-block">
                  &nbsp;
                </div>
                <div class="col-12 col-md-4 text-end">
                  <button class="btn btn-warning" (click)="legalAgreementsLib.showPrivacyPolicy()">
                    {{ translationsLib.get('view_more') }}
                  </button>
                </div>
              </div>

            </div>
          </div>
        </div>
        <hr class="my-4 ">
        <div class="card">
          <div class="card-body">
            <div class="col-12">
              <div class="row">
                <div class="col-12 text-end">
                <span class="small text-muted mb-md-0">
                  {{ environmentVersion }} {{ timeStampVersion }}
                </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ion-content>
