<ion-header >
  <ion-toolbar class="barra-superior">
    <ion-buttons slot="start" class="d-none d-lg-flex" *ngIf="!isNative">
      <ion-menu-button></ion-menu-button>
    </ion-buttons>
    <h1 *ngIf="name!==''">{{name}}</h1>
    <app-breadcrumbs *ngIf="name===''" [breadcrumbs]="breadcrumbs"></app-breadcrumbs>
    <ion-buttons slot="end">
      <ion-button id="trigger-button" (click)="router.navigate(['/notifications']);">
        <ion-badge color="danger" *ngIf="pending_number > 0">{{pending_number}}</ion-badge>
        <ion-icon id="notifications-icon" name="{{ router.url === '/notifications' ? 'notifications' : 'notifications-outline' }}" ></ion-icon>
      </ion-button>
      <ion-avatar (click)="router.navigate(['/profile']);" style="cursor: pointer;width: 35px;height: 35px" *ngIf="avatar !== null">
        <text-avatar [text]="avatar['name']" [color]="avatar['background']" [textColor]="avatar['color']"></text-avatar>
      </ion-avatar>
    </ion-buttons>
    <ion-title>
    </ion-title>
  </ion-toolbar>
</ion-header>
