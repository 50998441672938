<div class="row">
  <div class="col-12">
    <ng-container *ngIf="show2faConfig === true">
      <section>
        <app-auth-2fa [userVid]="this.userVid"></app-auth-2fa>
      </section>
    </ng-container>

    <ng-container *ngIf="show2faConfig === true && show2faLog === true">
      <hr class="my-5">
    </ng-container>

    <ng-container *ngIf="show2faLog === true">
      <section>
        <app-auth-login-log [userVid]="this.userVid"></app-auth-login-log>
      </section>
    </ng-container>
  </div>
</div>
