import { Component, Input, OnInit } from '@angular/core';
import {
  TranslationsLibService,
  TerminalDataModel,
} from '@nutricontrol/app360-shared';

@Component({
  selector: 'app-fields-dashboard-terminals-error',
  templateUrl: './fields-dashboard-terminals-error.component.html',
  styleUrls: ['./fields-dashboard-terminals-error.component.scss'],
})
export class FieldsDashboardTerminalsErrorComponent implements OnInit {
  @Input() terminalsError: TerminalDataModel[] = [];

  constructor(public translationsLib: TranslationsLibService) {}

  ngOnInit(): void {}
}
