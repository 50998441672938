import { Injectable } from '@angular/core';
@Injectable({
  providedIn: 'root',
})
export class SidebarLibService {
  constructor() {}

  public showSidebar() {
    return (
      window.location.pathname !== '/' &&
      window.location.pathname !== '/login' &&
      window.location.pathname !== '/bo-login' &&
      window.location.pathname !== '/register' &&
      window.location.pathname !== '/2fa-confirmation' &&
      window.location.pathname !== '/bo-2fa-confirmation' &&
      window.location.pathname.indexOf('/register-confirmation') < 0 &&
      window.location.pathname.indexOf('/reset-password') < 0 &&
      window.location.pathname.indexOf('/reset-user-invitation') < 0
    );
  }
}
