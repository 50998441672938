import { Pipe, PipeTransform } from '@angular/core';
import { FieldDataModel } from '@nutricontrol/app360-shared';

@Pipe({
  name: 'fieldCropsName',
})
export class FieldCropsNamesPipe implements PipeTransform {
  transform(field: FieldDataModel) {
    if (typeof sessionStorage.getItem('crops_data') === 'string') {
      const cropsList = JSON.parse(sessionStorage.getItem('crops_data'));

      if (!field || !cropsList || cropsList.length === 0) return '...';
      if (
        typeof field.multicrops === undefined ||
        field.multicrops === null ||
        (field.multicrops.length === 0 &&
          field.crop_id !== undefined &&
          field.crop_id !== null)
      ) {
        return cropsList.find((crop) => crop.id === field.crop_id)?.name;
      }
      // Hago un objeto {calabazas: 2, higos: 1}
      const countNames = field.multicrops.reduce((prev, curr) => {
        const cropName = cropsList.find((crop) => crop.id === curr.crop_id);
        if (cropName) {
          if (prev[cropName.name]) prev[cropName.name]++;
          else prev[cropName.name] = 1;
        }
        return { ...prev };
      }, {});
      // A partir del objeto monto "calabazas (x2), higos 1"
      return Object.keys(countNames)
        .map((name) =>
          countNames[name] > 1 ? name + ' (x' + countNames[name] + ')' : name
        )
        .join(', ');
    } else {
      return '...';
    }
  }
}
