<app-terminal-temporary-disabled-alert></app-terminal-temporary-disabled-alert>

<div style="filter: blur(4px)">
  <app-meteo-grid
    [meteoRows]="meteoRows"
    [terminalType]="terminalType"
  ></app-meteo-grid>

  <div *ngFor="let _ of [0, 1, 2]">
    <div class="card table-responsive" [ngClass]="borderStyle">
      <table class="table card-table">
        <thead>
        <tr>
          <th scope="col">#</th>
          <th scope="col">First</th>
          <th scope="col">Last</th>
          <th scope="col">Handle</th>
          <th scope="col">Test</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let _ of [0, 1, 2]">
          <th scope="row">1</th>
          <td>Mark</td>
          <td>Otto</td>
          <td>@mdo</td>
          <td>Test</td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
