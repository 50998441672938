import { Injectable } from '@angular/core';
import {
  TranslationsLibService,
  EquipmentGeneralTypes,
} from '@nutricontrol/app360-shared';
import Swal from 'sweetalert2';

@Injectable({
  providedIn: 'root',
})
export class EquipmentsLibService {
  constructor(public translationsLib: TranslationsLibService) {}

  getTypeByEquipmentData(equipmentData: any): string {
    if ('irrigation_configuration' in equipmentData) {
      return EquipmentGeneralTypes.irrigation;
    } else if ('climate_configuration' in equipmentData) {
      return EquipmentGeneralTypes.climate;
    } else if ('datalogger_configuration' in equipmentData) {
      return EquipmentGeneralTypes.datalogger;
    } else if ('configurable_configuration' in equipmentData) {
      return EquipmentGeneralTypes.configurable;
    }
    return EquipmentGeneralTypes.unknown;
  }
  showConnectivityAlert(): void {
    Swal.fire({
      toast: true,
      icon: 'warning',
      title: this.translationsLib.get('irrigation_terminal_disconnected'),
      position: 'bottom',
      showConfirmButton: false,
      background: '#E96055',
      iconColor: '#FFF',
      color: '#FFF',
      timer: 300,
      width: 'auto',
      didOpen: () => {
        Swal.toggleTimer();
        setTimeout(() => {
          Swal.close();
        }, 5000);
      },
      customClass: {
        container: 'connected-container',
        title: 'connected-title',
      },
    });
  }
}
