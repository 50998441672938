import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  FormControl,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import {
  FertilizerFormulasModel,
  GroupsDataModel,
  Progagrup,
  PumpsDataModel,
  ValvesDataModel,
} from '../../irrigation.model';
import { IrrigationService } from '../../irrigation.service';
import Swal from 'sweetalert2';
import {
  convertFormattedTimeToSeconds,
  DateToSecondsInput,
  removeDirtyInputs,
  saveDataAndShowModal,
  saveSubmitWithTerminalStatus,
  secondsInputToDate,
} from '../../../../commons/helpers/functions';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Observable, Subscription } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslationsLibService } from '@nutricontrol/app360-shared';
import { EquipmentsLibService } from '../../../../services/libraries/equipments-lib.service';
import { FormFieldHandlerService } from '../../../libraries/form-field-handler.service';
import { environment } from '../../../../../environments/environment';
import { InputNumberService } from '../../../libraries/input-number.service';

@Component({
  selector: 'app-groups-form',
  templateUrl: './groups-form.component.html',
  styleUrls: ['./groups-form.component.scss'],
})
export class GroupsFormComponent implements OnInit, OnDestroy {
  isFetchingData = false;
  isFormSubmitted = false;
  isGroupWithoutData = false;
  groupsForm: UntypedFormGroup;
  groups: GroupsDataModel[];
  private formFieldErrorSubscription: Subscription;
  valvesList: ValvesDataModel[];
  fertilizerFormulasList: FertilizerFormulasModel[];
  isConnected: boolean;
  private intervalId: any;
  pumpsList: PumpsDataModel[];

  isAnyGroupDirtyAfterPost = false;

  isGroupUpdatedDirty = false;

  UPDATE_INTERVAL = 4000;

  isErrorInGroupsPost;

  @ViewChild(GroupsFormComponent) groupsFormComponent: GroupsFormComponent;

  @Input() mode: string;
  @Input() terminal_vid: string;
  @Input() group_id: number;
  @Input() reload = false;
  @Input() isProgramsScreen = false;

  @Output() dirtyEventEmitter = new EventEmitter<boolean>();
  @Output() updatedGroupsEventEmitter = new EventEmitter<GroupsDataModel[]>();
  @Output() nameEventEmitter = new EventEmitter<string>();
  @Output() groupEventEmitter = new EventEmitter<GroupsDataModel>();
  @Output() clearIntervalEventEmitter = new EventEmitter<boolean>();

  separatorKeysCodes: number[] = [ENTER, COMMA];
  valvesCtrl = new FormControl('');
  filteredValves: Observable<ValvesDataModel[]>;
  valvesSelected: ValvesDataModel[] = [];

  layoutConfiguration: Progagrup;

  @ViewChild('valvesInput') valvesInput: ElementRef<HTMLInputElement>;
  @Output() formFieldErrorEventEmitter = new EventEmitter<boolean>();

  constructor(
    private fb: UntypedFormBuilder,
    private activatedRoute: ActivatedRoute,
    private irrigationService: IrrigationService,
    private router: Router,
    public translationsLib: TranslationsLibService,
    private equipmentLib: EquipmentsLibService,
    private formFieldHandlerService: FormFieldHandlerService,
    public inputNumberService: InputNumberService
  ) {
    this.groupsForm = this.fb.group({
      group: [null],
      name: [''],
      dirty: [''],
      fertilizationTime: [null, Validators.required],
      postIrrigationTime: [null, Validators.required],
      preIrrigationTime: [null, Validators.required],
      fertilizationVolume: [
        null,
        [Validators.required],
        [this.customAsyncValidator('fertilizationVolume')],
      ],
      postIrrigationVolume: [null, Validators.required],
      preIrrigationVolume: [null, Validators.required],
      fertilizerFormula: [null, Validators.required],
      preFertilizerFormula: [null, Validators.required],
      postFertilizerFormula: [null, Validators.required],
      pump1: [null, Validators.required],
      pump2: [null, Validators.required],
      pump3: [null, Validators.required],
      correctionFactor: [null, Validators.required],
      startRadiation: [null, Validators.required],
      endRadiation: [null, Validators.required],
      phControlAtPreFertilization: [null, Validators.required],
      phControlAtPostFertilization: [null, Validators.required],
      valves: [null],
      isDirty: false,
    });
  }

  customAsyncValidator(fieldName: string) {
    return (control) => {
      const value = control.value;

      return new Promise((resolve) => {
        if (value !== null && (value < 4 || value > 10)) {
          resolve({ invalidRange: true });
        } else {
          resolve(null);
        }
      });
    };
  }

  async startInterval() {
    this.intervalId = setInterval(() => {
      this.performTasks();
    }, environment.intervalDefaultTimeout);
  }

  async performTasks() {
    this.groupsForm.reset();

    this.getFertilizerFormulasList();
    this.getPumpsList();
    this.getLayoutConfiguration();
    this.getGroup();

    await this.getIrrigationEquipment();

    if (!this.isConnected) {
      this.equipmentLib.showConnectivityAlert();
    }

    if (this.groupsFormComponent) {
      this.groupsFormComponent.isFormSubmitted = false;
    }
  }

  async ngOnInit() {
    this.groupsForm.reset(); // Prevent unsaved data to persist in form inputs

    this.activatedRoute.params.subscribe((_) => {
      this.getIrrigationEquipment();
      this.getFertilizerFormulasList();
      this.getPumpsList();
      this.getLayoutConfiguration();
      this.getGroup();

      removeDirtyInputs(this.groupsForm);
    });

    this.groupsForm.valueChanges.subscribe((_) => {
      this.dirtyEventEmitter.emit(this.getIsDirty());

      if (this.getIsDirty()) {
        this.clearIntervalEventEmitter.emit(true);
        clearInterval(this.intervalId);
      } else {
        this.clearIntervalEventEmitter.emit(false);
        clearInterval(this.intervalId);
        this.startInterval();
      }
    });

    this.filteredValves = this.valvesCtrl.valueChanges.pipe(
      startWith(null),
      map((value) => {
        // @ts-ignore
        const valve = typeof value === 'string' ? value : value?.name;
        return valve ? this._filterValves(valve as string) : this.valvesList;
      })
    );
  }

  private _filterValves(valve: string): ValvesDataModel[] {
    const filterValue = valve.toLowerCase();
    return this.valvesList.filter(
      (option) => option.name?.toLowerCase().includes(filterValue) // || ('Valvula '+ option.valve?.toString()).includes(filterValue)
    );
  }

  onChangeInputTimeWithKeyboard(event, formInput: string) {
    const toSeconds = convertFormattedTimeToSeconds(event.target.value);
    const toDate = secondsInputToDate(toSeconds);
    this.groupsForm.get(formInput).setValue(toDate);

    this.groupsForm.markAsDirty();
  }

  addValve(event: MatChipInputEvent): void {
    const value = event.value || '';
    // Add
    if (value) {
      const valve = this.valvesList.find(
        // @ts-ignore
        (option) => option.name?.toLowerCase().includes(value.toLowerCase())
      );
      if (valve !== undefined) {
        const index = this.valvesSelected.indexOf(valve);
        if (index < 0) {
          this.valvesSelected.push(valve);
          this.setDirty(true);
        }
      }
    }
    // Clear the input value
    event.chipInput!.clear();

    this.valvesCtrl.setValue(null);
  }
  removeValve(valve: ValvesDataModel): void {
    // console.log('remove valve ', valve);

    const index = this.valvesSelected.indexOf(valve);
    if (index >= 0) {
      this.valvesSelected.splice(index, 1);
      this.setDirty(true);
    }
  }
  selectedValve(event: MatAutocompleteSelectedEvent): void {
    // console.log('selected valve ', event.option.value);
    const index = this.valvesSelected.indexOf(event.option.value);
    if (index < 0) {
      this.valvesSelected.push(event.option.value);
      this.setDirty(true);
    }
    this.valvesInput.nativeElement.value = '';
    this.valvesCtrl.setValue(null);
  }

  setDirty(dirty) {
    this.groupsForm.patchValue({ isDirty: dirty });
  }

  getIsDirty() {
    return this.groupsForm.dirty || this.groupsForm.value.isDirty;
  }

  getGroup(isGetAfterPost = false) {
    if (!isGetAfterPost) this.isFetchingData = true;
    const pagination = 0;

    this.irrigationService
      .getIrrigationGroup(this.terminal_vid, this.group_id)
      .subscribe((res) => {
        this.isAnyGroupDirtyAfterPost = res.some(
          (group) => group.dirty === true
        );

        this.groupEventEmitter.emit(res[0]);
        if (res.length === 0) {
          this.isGroupWithoutData = true;
          this.valvesList = [];
        } else {
          this.groups = res;
          this.nameEventEmitter.emit(res[pagination].name);

          this.isGroupUpdatedDirty = res[pagination].dirty;

          this.irrigationService
            .getValves(this.terminal_vid)
            .subscribe((res) => {
              this.valvesList = res;
              this.valvesSelected = this.groups[pagination].valves.map(
                (value) => {
                  const valve = this.valvesList.find(
                    (option) => Number(option.valve) === value
                  );
                  return valve !== undefined ? valve : null;
                }
              );
              this.valvesSelected = this.valvesSelected.filter(
                (el) => el != null
              );
            });

          this.groupsForm.patchValue({
            group: res[pagination].group,
            name: res[pagination].name,
            dirty: res[pagination].dirty,
            fertilizationTime: secondsInputToDate(
              res[pagination].fertilizationTime
            ),
            postIrrigationTime: secondsInputToDate(
              res[pagination].postIrrigationTime
            ),
            preIrrigationTime: secondsInputToDate(
              res[pagination].preIrrigationTime
            ),
            fertilizationVolume: res[pagination].fertilizationVolume,
            postIrrigationVolume: res[pagination].postIrrigationVolume,
            preIrrigationVolume: res[pagination].preIrrigationVolume,
            fertilizerFormula: res[pagination].fertilizerFormula,
            preFertilizerFormula: res[pagination].preFertilizerFormula,
            postFertilizerFormula: res[pagination].postFertilizerFormula,
            pump1: res[pagination].pump1,
            pump2: res[pagination].pump2,
            pump3: res[pagination].pump3,
            correctionFactor: res[pagination].correctionFactor,
            startRadiation: res[pagination].startRadiation,
            endRadiation: res[pagination].endRadiation,
            phControlAtPreFertilization:
              res[pagination].phControlAtPreFertilization,
            phControlAtPostFertilization:
              res[pagination].phControlAtPostFertilization,
          });
        }

        if (!isGetAfterPost) this.isFetchingData = false;
      });
  }

  getFertilizerFormulasList() {
    this.irrigationService
      .getFertilizerFormulas(this.terminal_vid)
      .subscribe((res) => {
        this.fertilizerFormulasList = res;
      });
  }

  getIrrigationEquipment() {
    this.irrigationService
      .getIrrigationTerminal(this.terminal_vid)
      .subscribe((res) => {
        this.isConnected = res.connected;
      });
  }

  getPumpsList() {
    this.irrigationService.getPumps(this.terminal_vid).subscribe((res) => {
      this.pumpsList = res;
    });
  }

  updateGroupsList() {
    this.irrigationService
      .getIrrigationGroups(this.terminal_vid)
      .subscribe((res) => {
        this.groups = res;
        this.updatedGroupsEventEmitter.emit(res);
      });
  }

  getLayoutConfiguration() {
    this.irrigationService
      .getTerminalLayoutConfiguration(this.terminal_vid)
      .subscribe((res) => {
        this.layoutConfiguration = res.screens_configuration.progagrup;
        if (!this.layoutConfiguration.enabled) {
          this.router.navigate(['/home/dashboard']);
        }
      });
  }

  handlePost(showModal: boolean, connected: boolean): Promise<boolean> {
    return new Promise<boolean>((resolve, _) => {
      this.isFormSubmitted = true;

      const groupsFormAsArray = [];
      groupsFormAsArray.push({
        group: this.groupsForm.value.group,
        name: this.groupsForm.value.name,
        fertilizationTime: DateToSecondsInput(
          this.groupsForm.value.fertilizationTime
        ),
        postIrrigationTime: DateToSecondsInput(
          this.groupsForm.value.postIrrigationTime
        ),
        preIrrigationTime: DateToSecondsInput(
          this.groupsForm.value.preIrrigationTime
        ),
        fertilizationVolume: this.groupsForm.value.fertilizationVolume,
        postIrrigationVolume: this.groupsForm.value.postIrrigationVolume,
        preIrrigationVolume: this.groupsForm.value.preIrrigationVolume,
        fertilizerFormula: Number(this.groupsForm.value.fertilizerFormula),
        preFertilizerFormula: Number(
          this.groupsForm.value.preFertilizerFormula
        ),
        postFertilizerFormula: Number(
          this.groupsForm.value.postFertilizerFormula
        ),
        pump1: Number(this.groupsForm.value.pump1),
        pump2: Number(this.groupsForm.value.pump2),
        pump3: Number(this.groupsForm.value.pump3),
        correctionFactor: this.groupsForm.value.correctionFactor,
        startRadiation: this.groupsForm.value.startRadiation,
        endRadiation: this.groupsForm.value.endRadiation,
        phControlAtPreFertilization:
          this.groupsForm.value.phControlAtPreFertilization,
        phControlAtPostFertilization:
          this.groupsForm.value.phControlAtPostFertilization,
        valves: this.valvesSelected.map((value) => {
          return Number(value.valve);
        }),
      });

      try {
        this.irrigationService
          .postIrrigationGroups(this.terminal_vid, groupsFormAsArray)
          .subscribe(
            (res) => {
              if (res.error) this.isErrorInGroupsPost = true;

              if (
                connected &&
                !this.groupsForm.value.dirty &&
                !this.isErrorInGroupsPost
              ) {
                saveDataAndShowModal(
                  res,
                  showModal,
                  this.translationsLib.get('error_updating_data'),
                  this.translationsLib.get('save_changes_success'),
                  this.translationsLib.get('accept'),
                  () => {
                    setTimeout(() => {
                      this.getGroup(true);
                    }, 4000);
                  }
                );

                this.isFormSubmitted = false;
                resolve(true);
              } else if (this.isErrorInGroupsPost && !this.isProgramsScreen) {
                Swal.fire({
                  text: this.translationsLib.get(
                    'irrigation_general_program_error'
                  ),
                  showConfirmButton: true,
                  confirmButtonText: this.translationsLib.get('accept'),
                  icon: 'error',
                });

                setTimeout(() => {
                  this.getGroup(true);
                }, this.UPDATE_INTERVAL);

                this.isFormSubmitted = false;
                resolve(false);
              } else {
                saveSubmitWithTerminalStatus(
                  res,
                  showModal,
                  connected,
                  this.translationsLib.get('error_updating_data'),
                  this.translationsLib.get('save_changes_success'),
                  this.translationsLib.get('accept'),
                  this.translationsLib.get(
                    'irrigation_disconnected_terminal_pending_changes'
                  )
                );

                setTimeout(() => {
                  this.getGroup(true);
                }, this.UPDATE_INTERVAL);

                this.isFormSubmitted = false;
                resolve(true);
              }
            },
            (_) => {
              if (showModal) {
                Swal.fire({
                  text: this.translationsLib.get('something_was_wrong'),
                  showConfirmButton: true,
                  confirmButtonText: this.translationsLib.get('accept'),
                  icon: 'error',
                });
              }
              this.isFormSubmitted = false;
              resolve(false);
            }
          );
      } catch (err) {
        throw new Error(err.message);
      }
    });
  }

  submitForm(
    showModal: boolean = true
  ): Promise<{ result: boolean; connected: boolean; error: boolean }> {
    return new Promise<{ result: boolean; connected: boolean; error: boolean }>(
      (resolve, reject) => {
        try {
          this.irrigationService
            .getIrrigationTerminal(this.terminal_vid)
            .subscribe((res) => {
              this.handlePost(showModal, res.connected).then((result) => {
                resolve({
                  result,
                  connected: res.connected,
                  error: this.isErrorInGroupsPost,
                });
              });
            });
        } catch (err) {
          reject({ result: false, connected: false });
        } finally {
          // Remove dirty inputs due to use standalone ngModels
          removeDirtyInputs(this.groupsForm);

          this.groupsForm.markAsUntouched();

          this.groupsForm.markAsPristine();
          this.groupsForm.patchValue({ isDirty: false });
        }
      }
    );
  }

  onPreIrrigationTimeChanged(newTime: Date) {
    const preIrrigationTime = this.groupsForm.get('preIrrigationTime');
    if (preIrrigationTime) {
      preIrrigationTime.patchValue(newTime);
      preIrrigationTime.markAsDirty();
    }
  }

  onFertilizationTimeChanged(newTime: Date) {
    const fertilizationTime = this.groupsForm.get('fertilizationTime');
    if (fertilizationTime) {
      fertilizationTime.patchValue(newTime);
      fertilizationTime.markAsDirty();
    }
  }

  onPostIrrigationTimeChanged(newTime: Date) {
    const postIrrigationTime = this.groupsForm.get('postIrrigationTime');
    if (postIrrigationTime) {
      postIrrigationTime.patchValue(newTime);
      postIrrigationTime.markAsDirty();
    }
  }

  onResetIrrigationValues(originalValues: Date[]) {
    const preIrrigationTime = this.groupsForm.get('preIrrigationTime');
    const fertilizationTime = this.groupsForm.get('fertilizationTime');
    const postIrrigationTime = this.groupsForm.get('postIrrigationTime');
    if (preIrrigationTime && fertilizationTime && postIrrigationTime) {
      preIrrigationTime.patchValue(originalValues[0]);
      preIrrigationTime.markAsUntouched();
      preIrrigationTime.markAsPristine();

      fertilizationTime.patchValue(originalValues[1]);
      fertilizationTime.markAsUntouched();
      fertilizationTime.markAsPristine();

      postIrrigationTime.patchValue(originalValues[2]);
      postIrrigationTime.markAsUntouched();
      postIrrigationTime.markAsPristine();
    }
  }

  ngOnDestroy(): void {
    clearInterval(this.intervalId);
    this.formFieldErrorSubscription?.unsubscribe();
  }
}
