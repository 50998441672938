import { Injectable } from '@angular/core';
import { AppCommonsService } from '../../commons/app-commons.service';
import { WeatherService } from '../../weather/weather.service';
import {
  TranslationsLibService,
  TerminalDataModel,
} from '@nutricontrol/app360-shared';
import { TimeLibService } from '../../services/libraries/time-lib.service';
import { WeatherCalculateET0Request } from '../../weather/weather.model';
import { IrrigationEquipmentModel } from '../irrigation/irrigation.model';
import { ClimateEquipmentModel } from '../climate/climate.model';
import { ConfigurableEquipmentModel } from '../configurable/configurable.model';
import { DataloggerEquipmentModel } from '../datalogger/datalogger.model';

@Injectable({
  providedIn: 'root',
})
export class Et0LibService {
  private calculateET0Request: WeatherCalculateET0Request;
  private loadingEquipment = false;
  private isFetchingET0 = false;
  private yesterday_ET0 = '-';
  constructor(
    private commonService: AppCommonsService,
    private weatherService: WeatherService,
    private translationsLib: TranslationsLibService,
    private timeLib: TimeLibService
  ) {}

  getHistoricWeather(terminalData: TerminalDataModel): Promise<{
    calculateET0Request: WeatherCalculateET0Request;
    loadingEquipment: boolean;
    status: 'success' | 'error';
  }> {
    const startingEndingDate = this.timeLib.getModifiedDate(
      new Date(),
      -1,
      'days'
    );

    return new Promise<{
      calculateET0Request: WeatherCalculateET0Request;
      loadingEquipment: boolean;
      status: 'success' | 'error';
    }>((resolve, reject) => {
      if (terminalData?.field !== null && terminalData?.field_vid) {
        this.weatherService
          .getHistoric(
            terminalData.field_vid,
            startingEndingDate,
            startingEndingDate
          )
          .subscribe(
            (historic) => {
              this.calculateET0Request = {
                lat: historic.field_identity.latitude,
                lon: historic.field_identity.longitude,
                info: [],
              };
              historic.results.forEach((h) => {
                this.calculateET0Request.info.push({
                  date: h.prediction_date,
                  main_humidity: h.main_humidity,
                  main_pressure: h.main_pressure,
                  main_temp: h.main_temp,
                  solar_rad: h.solar_rad,
                  wind_speed: h.wind_speed,
                  wind_deg: h.wind_deg,
                });
              });
              this.loadingEquipment = false;

              resolve({
                calculateET0Request: this.calculateET0Request,
                loadingEquipment: this.loadingEquipment,
                status: 'success',
              });
            },
            (error) => {
              this.loadingEquipment = false;
              reject(error);
            }
          );
      } else {
        this.loadingEquipment = false;

        resolve({
          calculateET0Request: this.calculateET0Request,
          loadingEquipment: this.loadingEquipment,
          status: 'error',
        });
      }
    });
  }

  getET0(
    calculateET0Request: WeatherCalculateET0Request,
    equipmentResponse:
      | IrrigationEquipmentModel
      | ClimateEquipmentModel
      | ConfigurableEquipmentModel
      | DataloggerEquipmentModel
  ): Promise<{ isFetchingET0: boolean; yesterday_ET0: string }> {
    return new Promise<{ isFetchingET0: boolean; yesterday_ET0: string }>(
      (resolve, reject) => {
        try {
          if (this.calculateET0Request !== undefined) {
            this.isFetchingET0 = true;

            this.commonService
              .calculateET0ByTerminalVid(
                equipmentResponse.serialNumber.toString(),
                calculateET0Request
              )
              .subscribe((result) => {
                if (result && result.ET0 !== undefined) {
                  this.yesterday_ET0 = result.ET0.toFixed(2);
                } else {
                  this.yesterday_ET0 = 'N/A';
                }

                this.isFetchingET0 = false;

                resolve({
                  isFetchingET0: this.isFetchingET0,
                  yesterday_ET0: this.yesterday_ET0,
                });
              });
          } else {
            this.isFetchingET0 = false;

            resolve({
              isFetchingET0: this.isFetchingET0,
              yesterday_ET0: this.yesterday_ET0,
            });
          }
        } catch (error) {
          console.log(error);
          reject(error);
        }
      }
    );
  }
}
