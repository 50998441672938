<div class="card h-100 py-2 px-3 justify-content-center">
  <div *ngIf="field.irrigation !== undefined"
       [class]="'time-position-' + field.irrigation.phase"
       class="irrigation-progress">
    <div [class]="isMobile ? 'fs-6' : 'fs-4'">
      <span class="text-uppercase fs-6 fw-bold"
            style="color: var(--primary-color)">
        {{ translationsLib.get('irrigation_program_time') }}:
      </span>
      <span class="fw-bold">
        &nbsp;{{ field.irrigation.timeLabel }}
      </span>
    </div>
    <div class="slider-bar">
      <div class="background-bar"></div>
      <nouislider
        [min]="0"
        [max]="field.irrigation.totalTime"
        [ngModel]="field.irrigation.ranges"
        [config]="progressBarsConfig"
        [disabled]="true"
        class="m-0">
      </nouislider>
    </div>
  </div>
</div>
