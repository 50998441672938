import { Component, Input, OnInit } from '@angular/core';
import { TranslationsLibService } from '@nutricontrol/app360-shared';
import { input } from '@ionic/cli/lib/color';
import { MegapopComponent } from '../megapop/megapop.component';
import {MatDatepickerModule} from "@angular/material/datepicker";
import {MatFormFieldModule} from "@angular/material/form-field";
import {ReactiveFormsModule} from "@angular/forms";
import {NgIf} from "@angular/common";

@Component({
  standalone: true,
  selector: 'app-selected-dates',
  templateUrl: './selected-dates.component.html',
  styleUrls: ['./selected-dates.component.scss'],
  imports: [
    MatDatepickerModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    NgIf
  ]
})
export class SelectedDatesComponent implements OnInit {
  @Input() viewMode = 1; //1 es modo normal, 2 es modo edicion
  @Input() chartGotData = [];
  constructor(
    public translationsLib: TranslationsLibService,
    public megapop: MegapopComponent
  ) {}

  ngOnInit() {}
}
