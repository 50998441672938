import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { FieldsService } from '../../fields/fields.service';
import { SessionLibService } from '@nutricontrol/app360-shared';
import SwiperCore, { Keyboard, Pagination, Navigation, Virtual } from 'swiper';
import { AppCommonsService } from '../../commons/app-commons.service';
import { HelpersLibService } from '../../services/libraries/helpers-lib.service';
import { TranslationsLibService } from '@nutricontrol/app360-shared';
SwiperCore.use([Keyboard, Pagination, Navigation, Virtual]);

@Component({
  selector: 'app-wizard-dashboard',
  templateUrl: './wizard-dashboard.component.html',
  styleUrls: ['./wizard-dashboard.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class WizardDashboardComponent implements OnInit {
  private avatar;
  private fields;
  public layout: any = [];
  wizard;
  constructor(
    public router: Router,
    private fieldsService: FieldsService,
    private session: SessionLibService,
    private commonService: AppCommonsService,
    private helpersLib: HelpersLibService,
    public translationsLib: TranslationsLibService
  ) {}

  ngOnInit() {
    this.avatar = this.session.getAvatar();
    this.wizard = false;
    this.getFields();
    this.getLayout();
    this.helpersLib.sendEventPageView('Wizard Dashboard');
    this.helpersLib.setTitle('', this.translationsLib.get('home'), null);
  }

  getFields() {
    this.fieldsService.getFields().subscribe((response) => {
      this.fields = response;
    });
  }

  getLayout() {
    this.commonService.getLayout().subscribe((res) => {
      this.layout = res;
      this.wizard = res.home.show_wizard;
    });
  }
}
