import { Component, OnInit, Input } from '@angular/core';
import { ScreensConfigurationIrrigation } from '../../irrigation.model';
import { ActivatedRoute } from '@angular/router';
import { TranslationsLibService } from '@nutricontrol/app360-shared';

@Component({
  selector: 'app-configuration-grid',
  templateUrl: './configuration-grid.component.html',
  styleUrls: ['./configuration-grid.component.scss'],
})
export class ConfigurationGridComponent implements OnInit {
  @Input() authedUserCanAdmin: boolean;
  @Input() screenConfiguration: ScreensConfigurationIrrigation;

  configurationButtonText = this.translationsLib.get('irrigation_access');
  terminal_vid: string;
  rows = [];

  constructor(
    private activatedRoute: ActivatedRoute,
    public translationsLib: TranslationsLibService
  ) {}

  ngOnInit() {
    this.terminal_vid = this.activatedRoute.snapshot.paramMap?.get('vid');

    const configurationGridValues = [
      {
        ws: 'valves',
        name: this.translationsLib.get('irrigation_valves'),
        enabled: this.screenConfiguration.progval.enabled,
      },
      {
        ws: 'fertilizers',
        name: this.translationsLib.get('irrigation_fertilizers'),
        enabled: this.screenConfiguration.progab.enabled,
      },
      {
        ws: 'pumps',
        name: this.translationsLib.get('irrigation_pumps'),
        enabled: this.screenConfiguration.progbombas.enabled,
      },
      {
        ws: 'filters',
        name: this.translationsLib.get('irrigation_filter'),
        enabled: true,
      },
      {
        ws: 'demands',
        name: this.translationsLib.get('irrigation_demands'),
        enabled: this.screenConfiguration.progdem.enabled,
      },
      {
        ws: 'ph_ce_rearm_alarm',
        name: this.translationsLib.get('irrigation_ph_ce_configuration'),
        enabled: this.screenConfiguration.progcom.enabled,
      },
      {
        ws: 'drainage_trays',
        name: this.translationsLib.get('irrigation_drainages_tray'),
        enabled: this.screenConfiguration.progbdren.enabled,
      },
      {
        ws: 'mixture_valves',
        name: this.translationsLib.get('irrigation_mixture_valves'),
        enabled: this.screenConfiguration.progvmez.enabled,
      },
      {
        ws: 'miscellaneous',
        name: this.translationsLib.get('irrigation_misc_title'),
        enabled: this.screenConfiguration.progmisc.enabled,
      },
      {
        ws: 'meteo_alarms',
        name: this.translationsLib.get('irrigation_alarms'),
        enabled: this.screenConfiguration.progalarm.enabled,
      },
      {
        ws: 'input_output',
        name: this.translationsLib.get('irrigation_input_output_io_report'),
        enabled: true,
      },
      {
        ws: 'sensors',
        name: this.translationsLib.get('sensors_title'),
        enabled: true
      }
    ];

    this.generateGrid(configurationGridValues);
  }

  generateGrid(configurationGridValues) {
    let tempRow = [];

    for (let i = 0; i < configurationGridValues.length; i++) {
      if (configurationGridValues[i].enabled) {
        tempRow.push({ key: i, value: configurationGridValues[i] });
      }

      if (tempRow.length === 4 || i === configurationGridValues.length - 1) {
        this.rows.push(tempRow);
        tempRow = [];
      }
    }
  }
}
