import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, Validators } from '@angular/forms';
import { AuthService } from '../auth.service';
import {
  AuthResponseDataModel,
  SessionLibService,
} from '@nutricontrol/app360-shared';
import { HelpersLibService } from '../../services/libraries/helpers-lib.service';
import { TranslationsLibService } from '@nutricontrol/app360-shared';
import Swal from 'sweetalert2';
import { PlatformLibService } from '@nutricontrol/app360-shared';
import { LegalAgreementsLibService } from '../../services/libraries/legal-agreements-lib.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  private fb: FormBuilder = new FormBuilder();
  // eslint-disable-next-line @typescript-eslint/member-ordering
  loginForm = this.fb.group({
    email: [
      '',
      [
        Validators.required,
        Validators.pattern('^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,4}$'),
      ],
    ],
    password: ['', Validators.required],
  });
  constructor(
    public router: Router,
    public authService: AuthService,
    public legalAgreementsLib: LegalAgreementsLibService,
    public sessionLib: SessionLibService,
    public helpersLib: HelpersLibService,
    public route: ActivatedRoute,
    public translationsLib: TranslationsLibService,
    private platformLib: PlatformLibService
  ) {}

  ngOnInit() {
    this.authService.getLegalAgreements();
    if (this.route.snapshot.queryParamMap.get('token')) {
      console.log('[IMPERSONATE]: Init');
      this.sessionLib.destroySession();
      console.log('[IMPERSONATE]: Session destroyed');

      this.sessionLib.createSession(
        this.route.snapshot.queryParamMap.get('vid'),
        this.route.snapshot.queryParamMap.get('name'),
        this.route.snapshot.queryParamMap.get('email'),
        this.route.snapshot.queryParamMap.get('session_vid'),
        this.route.snapshot.queryParamMap.get('token'),
        this.route.snapshot.queryParamMap.get('accesstoken'),
        Number(this.route.snapshot.queryParamMap.get('type')),
        null
      );
      this.sessionLib.setAvatar({
        name: '!',
        background: '#FFFF00',
        color: '#000000',
      });
      this.sessionLib.setImpersonated(true);
      // Forced
      location.replace('/home/dashboard');
    }

    this.onLoadCheckIfSessionCanBeRefreshed();

    this.helpersLib.setTitle(
      '',
      this.translationsLib.get('auth_forgotten_password_login'),
      null
    );
  }

  onLoadCheckIfSessionCanBeRefreshed(): void {
    if (this.sessionLib.getSessionToken()) {
      const token = {
        user_vid: this.sessionLib.getSessionUserVid(),
        session_vid: this.sessionLib.getSessionSessionVid(),
        token: this.sessionLib.getSessionToken(),
        access_token: this.sessionLib.getAccessToken(),
      };
      this.authService.refreshToken(token).subscribe(async (authResponse) => {
        if (this.platformLib.isNative()) {
          this.authService
            .postRegisterPushToken(
              JSON.parse(sessionStorage.getItem('notifications_data'))
            )
            .subscribe(() => {
              console.log(
                'Registration token: ',
                sessionStorage.getItem('notifications_data')
              );
            });
        }

        this.authService.redirectToNextPostAction(authResponse);

        this.authService.checkUserLegalAgreementsVersion(
          this.sessionLib.getSessionUserVid()
        );
      });
    }
  }

  onSubmit(): void {
    event.preventDefault();
    this.authService.login(this.loginForm.value).subscribe(
      (authResponse) => {
        if (authResponse.next_action === 'auth_2fa_redirect_to_code') {
          this.router.navigate(['/2fa-confirmation'], {
            queryParams: {
              session_vid: authResponse.session_vid,
              user_vid: authResponse.user_vid,
            },
          });
        } else {
          this.authService.doLogin(authResponse, true).subscribe((response) => {
            console.log('[LOGIN]: doLogin result ', response);
          });
        }
      },
      (error) => {
        this.loginForm.setValue({
          email: this.loginForm.value.email,
          password: '',
        });

        let error_text = this.translationsLib.get('auth_login_error');
        if (error.error.indexOf('login error: user does not exist') >= 0) {
          error_text = this.translationsLib.get(
            'auth_login_error_user_does_not_exist'
          );
        } else if (error.error.indexOf('login error: unconfirmed user') >= 0) {
          error_text = this.translationsLib.get(
            'auth_login_error_user_unconfirmed'
          );
        } else if (error.error.indexOf('login error: disabled user') >= 0) {
          error_text = this.translationsLib.get(
            'auth_login_error_user_disabled'
          );
        }

        Swal.fire({
          icon: 'error',
          title: this.translationsLib.get('error'),
          text: error_text,
          confirmButtonColor: '#224261',
        });
      }
    );
  }
  showPassword() {
    const tipo = document.getElementById('password');
    if (tipo.attributes[2].value === 'password') {
      tipo.attributes[2].value = 'text';
    } else {
      tipo.attributes[2].value = 'password';
    }
  }
}
