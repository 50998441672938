import { Injectable } from '@angular/core';
import {
  GroupsDataModel,
  ValvesDataModel,
} from '../../../../farming/irrigation/irrigation.model';
import * as moment from 'moment/moment';

@Injectable({
  providedIn: 'root',
})
export class WaterSystemsUtilsService {
  constructor() {}

  formatSeconds(seconds: number): string {
    return moment().startOf('day').add(seconds, 'seconds').format('HH:mm:ss');
  }

  public mutateGroupsForDataModel(response): GroupsDataModel[] {
    return response.map((item) => ({
      group: Number(item.group),
      name: item.name,
      fertilizationTime: Number(item.fertilizationTime),
      postIrrigationTime: Number(item.postIrrigationTime),
      preIrrigationTime: Number(item.preIrrigationTime),
      fertilizationVolume: parseFloat(item.fertilizationVolume),
      postIrrigationVolume: parseFloat(item.postIrrigationVolume),
      preIrrigationVolume: parseFloat(item.preIrrigationVolume),
      fertilizerFormula: Number(item.fertilizerFormula),
      preFertilizerFormula: Number(item.preFertilizerFormula),
      postFertilizerFormula: Number(item.postFertilizerFormula),
      pump1: Number(item.pump1),
      pump2: Number(item.pump2),
      pump3: Number(item.pump3),
      correctionFactor: Number(item.correctionFactor),
      startRadiation: Number(item.startRadiation),
      endRadiation: Number(item.endRadiation),
      dirty: item.dirty,
      phControlAtPreFertilization: item.phControlAtPreFertilization,
      phControlAtPostFertilization: item.phControlAtPostFertilization,
      valves: item.valves.map((valve) => Number(valve)),
      sector: item.sector,
    }));
  }
}
