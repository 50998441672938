import { Component, OnInit, ViewChild } from '@angular/core';
import { HelpersLibService } from '../../services/libraries/helpers-lib.service';
import {
  SessionLibService,
  TranslationsLibService,
} from '@nutricontrol/app360-shared';
import { MatTabGroup } from '@angular/material/tabs';
import { AppCommonsService } from '../../commons/app-commons.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Breadcrumb } from '../../farming/irrigation/shared/shared.model';

@Component({
  selector: 'app-profile-tabs',
  templateUrl: './profile-tabs.component.html',
  styleUrls: ['./profile-tabs.component.scss'],
})
export class ProfileTabsComponent implements OnInit {
  breadcrumbs: Breadcrumb[] = [];
  tabSelected = this.translationsLib.get('general');
  tabSelectedId = 'general';
  layout;

  tabs = {
    general: 'general',
    auth_configuration: 'profile_auth_menu',
    notifications: 'notification_notifications',
    permissions: 'permission_permissions',
    side_menu: 'configuration_side_menu',
    myucrop: 'myucrop',
    administrative_management: 'permission_administrative_management',
  };

  visibleTabs = {};

  @ViewChild('tabGroup', { static: false }) tab: MatTabGroup;

  constructor(
    private helpersLib: HelpersLibService,
    public translationsLib: TranslationsLibService,
    private commonService: AppCommonsService,
    private router: Router,
    private route: ActivatedRoute,
    public sessionLib: SessionLibService
  ) {}

  ngOnInit() {
    this.helpersLib.sendEventPageView('Profile');
    this.updateVisibleTabs();
    this.getLayout();
    this.updateBreadcrumbs();
  }

  updateVisibleTabs() {
    if (this.sessionLib.getUserHasNonDemoTerminals() === 'true') {
      this.visibleTabs = this.tabs;
    } else {
      this.visibleTabs = {
        general: 'general',
        auth_configuration: 'profile_auth_menu',
        side_menu: 'configuration_side_menu',
      };
    }
  }

  getLayout() {
    this.commonService.getLayout().subscribe((res) => {
      this.layout = res;

      this.route.fragment.subscribe((fragment) => {
        if (fragment) {
          // Convert the fragment to a tab index
          const tabIndex = this.getTabIndex(fragment);
          if (tabIndex !== -1) {
            this.tab.selectedIndex = tabIndex;
          }
        }
      });
    });
  }

  onTabChange(event) {
    const tabIndex = event.index;
    const tabId = Object.keys(this.visibleTabs)[tabIndex];

    // Update URL with English identifier
    this.router.navigate(['/profile'], { fragment: tabId });

    // Update breadcrumbs and other parts of the state
    this.tabSelected = this.translationsLib.get(this.visibleTabs[tabId]);
    this.tabSelectedId = tabId;
    this.updateBreadcrumbs();
  }

  private updateBreadcrumbs() {
    this.breadcrumbs = [
      {
        text: this.translationsLib.get('profile_profile'),
        disabled: false,
        to: '',
        active: false,
      },
      {
        text: this.tabSelected,
        disabled: false,
        to: ' ',
        active: true,
      },
    ];
  }

  private getTabIndex(tabId: string): number {
    return Object.keys(this.visibleTabs).indexOf(tabId);
  }
}
