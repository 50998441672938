import { Injectable } from '@angular/core';
import { WaterSystemUtilsService } from './water-system-utils.service';
import { TranslationsLibService } from '@nutricontrol/app360-shared';
import { ConfigurableProgramStateModel } from '../../configurable.model';

@Injectable({
  providedIn: 'root',
})
export class WaterSystemsService {
  constructor(
    private waterSystemsUtilsService: WaterSystemUtilsService,
    private translationsLib: TranslationsLibService
  ) {}

  public waterSystemIsEnabled(
    programsStates: ConfigurableProgramStateModel[]
  ): boolean {
    return true;
    // TODO
    // const activeProgramState = programsStates.find((programState) => {
    //   return programState.waterSystem === waterSystem.waterSystem;
    // });
    // return (
    //   !(waterSystem.OutEC === -1 && waterSystem.readPH === -1) ||
    //   activeProgramState !== undefined
    // );
  }

  public hasActiveProgram(
    programsStates: ConfigurableProgramStateModel[]
  ): boolean {
    return programsStates.length > 0;
  }

  public getBadgeLabel(
    programsStates: ConfigurableProgramStateModel[]
  ): string {
    const program = this.getWaterSystemProgramState(programsStates);

    if (program) {
      const programState = program.state.toLowerCase();
      return `${program.name} - ${this.translationsLib.get(
        'irrigation_state_' + programState
      )}`;
    }
    this.waterSystemIsEnabled(programsStates);
    return 'Sin programa activo';
  }

  public getWaterSystemProgramState(
    programsState: ConfigurableProgramStateModel[]
  ): ConfigurableProgramStateModel | undefined {
    return programsState.length > 0 ? programsState[0] : undefined;
  }
}
