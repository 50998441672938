<div class="terminal-grid mt-4" *ngIf="meteoRows.length > 0">
  <div class="card" [ngClass]="cardClass">
    <div class="card-body p-3">
      <div class="row gy-3 gx-1 custom-container">
        <div *ngFor="let field of meteoRows" [ngClass]="meteoRowClass" class="mt-0">
          <app-info-pill
            [class]="'border-0 shadow-none my-3'"
            [value]="field.value | magnitudeValuePrecision: field.key : true"
            [magnitudeLabel]="getDataHeader(field)"
            [magnitudeUnit]="getFieldUnit(field)"
            [magnitudeTooltip]="isVirtualOrigin(field) ? getVirtualTooltip() : undefined"
            [virtual]="isVirtualOrigin(field)"
            [icon]="getSensorIcon(field.key)"
            [hideIconOnMobile]="false"
          >
          </app-info-pill>
        </div>
      </div>
    </div>
  </div>
</div>
