export const INVITATIONS_TYPES = {
  PENDING: 0,
  ACCEPTED: 1,
  REJECTED: 2,
  CANCELLED: 3,
};

export interface InvitationsDataModel {
  page_items_number: number;
  current_page: number;
  last_item_index: number;
  first_item_index: number;
  total_pages: number;
  total: number;
  items: InvitationDataModel[];
}

export interface InvitationDataModel {
  vid: string;
  invitation_status: number;
  invitation_type: string;
  element_permission_type: number;
  element_name: string;
  source_user_email: string;
  source_user_name: string;
  target_user_email: string;
  target_user_name: string;
  last_status_update_by_user_email: string;
  last_status_update_by_user_name: string;
  can_be_accepted: boolean;
  cannot_be_accepted_due_to_permission_conflict: boolean;
  can_be_rejected: boolean;
  can_be_cancelled: boolean;
  can_be_resent: boolean;
  updated_at: string;
  created_at: string;
  status_updated_at: string;
  reverse_invitation: boolean;
}

export interface InvitationsPendingDataModel {
  pending_invitations: number;
}

export interface NotificationDataModel {
  id: string;
  notification_user_id: string;
  text: string;
  response: NotificationResponseDataModel;
  send_at: string;
  user_time_zone: string;
  terminal_vid: string;
  terminal_name: string;
}

export interface NotificationResponseDataModel {
  multicast_id: number;
  success: number;
  failure: number;
  canonical_ids: number;
  results: object[];
}

export interface NotificationTerminalDataModel {
  vid: string;
  name_vid: string;
  field_vid: string;
  field_name: string;
}
