import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class WeatherLibService {
  getIcon(code, status) {
    if (code === 800) {
      if (status === 'd') {
        return 'assets/img/Weather_icons/soleado.svg?1';
      } else {
        return 'assets/img/Weather_icons/noche.svg?1';
      }
    }
    if (code >= 700 && code <= 751) {
      return 'assets/img/Weather_icons/niebla.svg?1';
    }
    if (code >= 801 && code <= 803) {
      if (status === 'd') {
        return 'assets/img/Weather_icons/nubes%20y%20claros.svg?1';
      } else {
        return 'assets/img/Weather_icons/noche%20y%20nubes.svg?1';
      }
    }
    if (code === 804) {
      return 'assets/img/Weather_icons/nublado.svg?1';
    }
    if (code >= 300 && code <= 302) {
      return 'assets/img/Weather_icons/lluvia.svg?1';
    }
    if ((code >= 500 && code <= 522) || code === 900) {
      return 'assets/img/Weather_icons/lluvia_intensa.svg?1';
    }
    if (code >= 200 && code <= 233) {
      return 'assets/img/Weather_icons/tormenta.svg?1';
    }
    if ((code >= 600 && code <= 610) || (code >= 621 && code <= 623)) {
      return 'assets/img/Weather_icons/nieve.svg?1';
    }
    if (code >= 611 && code <= 612) {
      return 'assets/img/Weather_icons/viento.svg?1';
    }
  }
  getBackground(code, status) {
    if (code === 800) {
      if (status === 'd') {
        return '#77c1dc';
      } else {
        return '#154574';
      }
    }
    if (code >= 700 && code <= 751) {
      return '#99aece';
    }
    if (code >= 801 && code <= 802) {
      if (status === 'd') {
        return '#77c1dc';
      } else {
        return '#154574';
      }
    }
    if (code >= 803 && code <= 804) {
      return '#a6a9b5';
    }
    if ((code >= 300 && code <= 522) || code === 900) {
      return '#99aece';
    }
    if (code >= 200 && code <= 233) {
      return '#99aece';
    }
    if ((code >= 600 && code <= 610) || (code >= 621 && code <= 623)) {
      return '#92b9cc';
    }
    if (code >= 611 && code <= 612) {
      return '#99aece';
    }
  }

  getBackgroundImage(code, status) {
    const url = environment.backend + '/static/images/weather-backgrounds/';

    if (code === 800) {
      if (status === 'd') {
        return url + 'TIEMPO_soleado.jpeg';
      } else {
        return url + 'TIEMPO_noche.jpeg';
      }
    }
    if (code >= 700 && code <= 751) {
      if (status === 'd') {
        return url + 'TIEMPO_niebla.jpeg';
      } else {
        return url + 'TIEMPO_niebla_noche.jpeg';
      }
    }
    if (code >= 801 && code <= 802) {
      if (status === 'd') {
        return url + 'TIEMPO_sol_nubes.jpeg';
      } else {
        return url + 'TIEMPO_nubes_noche.jpeg';
      }
    }
    if (code === 803) {
      if (status === 'd') {
        return url + 'TIEMPO_poco_nuboso.jpeg';
      } else {
        return url + 'TIEMPO_nubes_noche.jpeg';
      }
    }
    if ((code >= 300 && code <= 302) || code === 804) {
      if (status === 'd') {
        return url + 'TIEMPO_nubes.jpeg';
      } else {
        return url + 'TIEMPO_nubes_noche.jpeg';
      }
    }
    if ((code >= 500 && code <= 522) || code === 900) {
      return url + 'TIEMPO_lluvia.jpeg';
    }
    if (code >= 200 && code <= 233) {
      return url + 'TIEMPO_tormenta.jpeg';
    }
    if ((code >= 600 && code <= 610) || (code >= 621 && code <= 623)) {
      if (status === 'd') {
        return url + 'TIEMPO_nieve.jpeg';
      } else {
        return url + 'TIEMPO_nieve_noche.jpeg';
      }
    }
    if (code >= 611 && code <= 612) {
      if (status === 'd') {
        return url + 'TIEMPO_viento.jpeg';
      } else {
        return url + 'TIEMPO_nubes_noche.jpg';
      }
    }
  }
  // Give 20º lower and upper margin for main cardinal points
  getWindDirection(dir) {
    if ((dir >= 0 && dir <= 20) || (dir >= 340 && dir <= 360)) {
      return 'meteo_N';
    }
    if (dir > 20 && dir < 70) {
      return 'meteo_NE';
    }
    if (dir >= 70 && dir <= 110) {
      return 'meteo_E';
    }
    if (dir > 110 && dir < 160) {
      return 'meteo_SE';
    }
    if (dir >= 160 && dir <= 200) {
      return 'meteo_S';
    }
    if (dir > 200 && dir < 250) {
      return 'meteo_SW';
    }
    if (dir >= 250 && dir <= 290) {
      return 'meteo_W';
    }
    if (dir > 290 && dir < 340) {
      return 'meteo_NW';
    }
  }
}
