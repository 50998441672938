import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import {
  OutputModel,
  Progrmezcla,
  SensorDataModel,
  ValvesDataModel,
} from '../../irrigation.model';
import { IrrigationService } from '../../irrigation.service';
import {
  convertFormattedTimeToSeconds,
  DateToSecondsInput,
  removeDirtyInputs,
  saveDataAndShowModal,
  saveSubmitWithTerminalStatus,
  secondsInputToDate,
} from '../../../../commons/helpers/functions';
import Swal from 'sweetalert2';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslationsLibService } from '@nutricontrol/app360-shared';
import { FormFieldHandlerService } from '../../../libraries/form-field-handler.service';
import { Subscription } from 'rxjs';
import { EquipmentsLibService } from 'src/app/services/libraries/equipments-lib.service';
import { InputNumberService } from '../../../libraries/input-number.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-mixture-formulas-form',
  templateUrl: './mixture-formulas-form.component.html',
  styleUrls: ['./mixture-formulas-form.component.scss'],
})
export class MixtureFormulasFormComponent implements OnInit, OnDestroy {
  isFetchingData = false;
  isFormSubmitted = false;
  mixturesForm: UntypedFormGroup;
  isMixtureFormulasWithoutData = false;
  private formFieldErrorSubscription: Subscription;

  isErrorInMixtureFormulasPost = false;

  isAnyMixtureFormulaDirtyAfterPost = false;

  outputsList: OutputModel[];

  valvesList: ValvesDataModel[];
  sensorsList: SensorDataModel[];

  @Input() mode: string;
  @Input() terminal_vid: string;
  @Input() mixture_id: number;
  @Input() reload = false;

  isConnected: boolean;
  private intervalId: any;

  @Output() dirtyEventEmitter = new EventEmitter<boolean>();
  @Output() nameEventEmitter = new EventEmitter<number>();
  @Output() mixtureFormulaEventEmitter = new EventEmitter<any>();
  @Output() formFieldErrorEventEmitter = new EventEmitter<boolean>();
  @Input() isProgramsScreen = false;

  @Output() clearIntervalEventEmitter = new EventEmitter<boolean>();

  layoutConfiguration: Progrmezcla;

  constructor(
    private fb: UntypedFormBuilder,
    private irrigationService: IrrigationService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    public translationsLib: TranslationsLibService,
    private formFieldHandlerService: FormFieldHandlerService,
    private equipmentLib: EquipmentsLibService,
    public inputNumberService: InputNumberService
  ) {
    this.mixturesForm = this.fb.group({
      formula: [null],
      dirty: [''],
      idealEC1: [null, Validators.required],
      idealEC2: [null, Validators.required],
      maxEC1: [null, Validators.required],
      maxEC2: [null, Validators.required],
      toleranceEC1: [null, Validators.required],
      toleranceEC2: [null, Validators.required],
      incrementEC1: [null, Validators.required],
      source1: [null, Validators.required],
      source2: [null, Validators.required],
      drainageSource: [null, Validators.required],
      maxDrainEC: [null, Validators.required],
      valve1: [null, Validators.required],
      valve2: [null, Validators.required],
      ECAlarmDelaySeconds: [null, Validators.required],
      alarmStop: [null, Validators.required],
      drainageSensor: [null],
      isDirty: false,
    });
  }

  getIrrigationEquipment() {
    this.irrigationService
      .getIrrigationTerminal(this.terminal_vid)
      .subscribe((res) => {
        this.isConnected = res.connected;
      });
  }

  async startInterval() {
    this.intervalId = setInterval(() => {
      this.performTasks();
    }, environment.intervalDefaultTimeout);
  }

  async performTasks() {
    this.mixturesForm.reset();

    this.getIrrigationEquipment();
    this.getValvesList();
    this.getOutputsList();
    this.getSensorsList();

    this.getLayoutConfiguration();
    this.getMixture();

    if (!this.isConnected) {
      this.equipmentLib.showConnectivityAlert();
    }

    this.isFormSubmitted = false;
  }

  ngOnInit() {
    this.mixturesForm.reset(); // Prevent unsaved data to persist in form inputs

    this.activatedRoute.params.subscribe((_) => {
      this.getIrrigationEquipment();
      this.getValvesList();
      this.getOutputsList();
      this.getSensorsList();

      this.getLayoutConfiguration();
      this.getMixture();

      removeDirtyInputs(this.mixturesForm);
    });

    this.mixturesForm.valueChanges.subscribe((_) => {
      this.dirtyEventEmitter.emit(this.getIsDirty());

      if (this.getIsDirty()) {
        this.clearIntervalEventEmitter.emit(true);
        clearInterval(this.intervalId);
      } else {
        this.clearIntervalEventEmitter.emit(false);
        clearInterval(this.intervalId);
        this.startInterval();
      }
    });
  }

  onChangeInputTimeWithKeyboard(event, formInput: string) {
    const toSeconds = convertFormattedTimeToSeconds(event.target.value);
    const toDate = secondsInputToDate(toSeconds);
    this.mixturesForm.get(formInput).setValue(toDate);

    this.mixturesForm.markAsDirty();
  }

  getLayoutConfiguration() {
    this.irrigationService
      .getTerminalLayoutConfiguration(this.terminal_vid)
      .subscribe((res) => {
        this.layoutConfiguration = res.screens_configuration.progrmezcla;
        if (!this.layoutConfiguration.enabled) {
          this.router.navigate(['/home/dashboard']);
        }
      });
  }

  setDirty(dirty) {
    this.mixturesForm.patchValue({ isDirty: dirty });
  }

  getIsDirty() {
    return this.mixturesForm.dirty || this.mixturesForm.value.isDirty;
  }

  getValvesList() {
    this.irrigationService.getValves(this.terminal_vid).subscribe((res) => {
      this.valvesList = res;
    });
  }

  getOutputsList() {
    this.irrigationService
      .getInputOutputs(this.terminal_vid, 'OUTPUTS', 29)
      .subscribe((res) => {
        this.outputsList = res.outputs;
      });
  }

  getSensorsList() {
    this.irrigationService.getSensors(this.terminal_vid).subscribe((res) => {
      res.forEach((sensor) => {
        sensor.id = sensor.nexp + '_' + sensor.nent + '_' + sensor.physicalType;
      });
      this.sensorsList = res;
    });
  }

  updateMixtureId(id) {
    this.mixture_id = id;
    this.getMixture();
  }

  getMixture(isGetAfterPost = false) {
    if (!isGetAfterPost) this.isFetchingData = true;
    const pagination = 0;

    this.irrigationService
      .getMixtureFormula(this.terminal_vid, this.mixture_id)
      .subscribe((res) => {
        this.mixtureFormulaEventEmitter.emit(res[0]);

        if (res.length === 0) {
          this.isMixtureFormulasWithoutData = true;
        } else {
          this.isAnyMixtureFormulaDirtyAfterPost = res.some(
            (formula) => formula.dirty === true
          );

          const drainageSensor = res[pagination].drainageSensor;
          let drainageSensorSelected = null;
          if (drainageSensor !== null) {
            drainageSensorSelected =
              drainageSensor.nexp +
              '_' +
              drainageSensor.nent +
              '_' +
              drainageSensor.physicalType;
          }

          this.nameEventEmitter.emit(res[pagination].formula);

          this.mixturesForm.patchValue({
            formula: res[pagination].formula,
            dirty: res[pagination].dirty,
            idealEC1: res[pagination].idealEC1,
            idealEC2: res[pagination].idealEC2,
            maxEC1: res[pagination].maxEC1,
            maxEC2: res[pagination].maxEC2,
            toleranceEC1: res[pagination].toleranceEC1,
            toleranceEC2: res[pagination].toleranceEC2,
            incrementEC1: res[pagination].incrementEC1,
            source1: res[pagination].source1,
            source2: res[pagination].source2,
            drainageSource: res[pagination].drainageSource,
            maxDrainEC: res[pagination].maxDrainEC,
            valve1: res[pagination].valve1,
            valve2: res[pagination].valve2,
            ECAlarmDelaySeconds: secondsInputToDate(
              res[pagination].ECAlarmDelaySeconds
            ),
            alarmStop: res[pagination].alarmStop,
            drainageSensor: drainageSensorSelected,
          });
        }

        if (!isGetAfterPost) this.isFetchingData = false;
      });
  }

  handlePost(showModal: boolean, connected: boolean): Promise<boolean> {
    return new Promise<boolean>((resolve, _) => {
      this.isFormSubmitted = true;

      let drainageSensor = null;
      const drainageSensorSelected = this.mixturesForm.value.drainageSensor;

      if (drainageSensorSelected !== null) {
        const dS = this.sensorsList.find(
          // @ts-ignore
          (option) => option.id === drainageSensorSelected
        );
        if (dS === undefined) {
          drainageSensor = null;
        } else {
          const { id, ...drainageSensorObject } = dS;
          drainageSensor = drainageSensorObject;
        }
      }

      const mixtureFormulasAsArray = [];
      mixtureFormulasAsArray.push({
        formula: this.mixturesForm.value.formula,
        idealEC1: this.mixturesForm.value.idealEC1,
        idealEC2: this.mixturesForm.value.idealEC2,
        maxEC1: this.mixturesForm.value.maxEC1,
        maxEC2: this.mixturesForm.value.maxEC2,
        toleranceEC1: this.mixturesForm.value.toleranceEC1,
        toleranceEC2: this.mixturesForm.value.toleranceEC2,
        incrementEC1: this.mixturesForm.value.incrementEC1,
        source1: this.mixturesForm.value.source1,
        source2: this.mixturesForm.value.source2,
        drainageSource: this.mixturesForm.value.drainageSource,
        maxDrainEC: this.mixturesForm.value.maxDrainEC,
        valve1: this.mixturesForm.value.valve1,
        valve2: this.mixturesForm.value.valve2,
        ECAlarmDelaySeconds: DateToSecondsInput(
          this.mixturesForm.value.ECAlarmDelaySeconds
        ),
        alarmStop: this.mixturesForm.value.alarmStop,
        drainageSensor,
      });

      try {
        this.irrigationService
          .postMixtureFormulas(this.terminal_vid, mixtureFormulasAsArray)
          .subscribe(
            (res) => {
              if (res.error) this.isErrorInMixtureFormulasPost = true;

              if (
                connected &&
                !this.mixturesForm.value.dirty &&
                !this.isErrorInMixtureFormulasPost
              ) {
                saveDataAndShowModal(
                  res,
                  showModal,
                  this.translationsLib.get('error_updating_data'),
                  this.translationsLib.get('save_changes_success'),
                  this.translationsLib.get('accept'),
                  () => {
                    setTimeout(() => {
                      this.getMixture(true);
                    }, 4000);
                  }
                );

                this.isFormSubmitted = false;
                resolve(true);
              } else if (
                this.isErrorInMixtureFormulasPost &&
                !this.isProgramsScreen
              ) {
                Swal.fire({
                  text: this.translationsLib.get(
                    'irrigation_general_program_error'
                  ),
                  showConfirmButton: true,
                  confirmButtonText: this.translationsLib.get('accept'),
                  icon: 'error',
                });

                this.isFormSubmitted = false;
                resolve(true);
              } else {
                saveSubmitWithTerminalStatus(
                  res,
                  showModal,
                  connected,
                  this.translationsLib.get('error_updating_data'),
                  this.translationsLib.get('save_changes_success'),
                  this.translationsLib.get('accept'),
                  this.translationsLib.get(
                    'irrigation_disconnected_terminal_pending_changes'
                  )
                );

                this.isFormSubmitted = false;
                resolve(true);
              }
            },
            (_) => {
              if (showModal) {
                Swal.fire({
                  text: this.translationsLib.get('something_was_wrong'),
                  showConfirmButton: true,
                  confirmButtonText: this.translationsLib.get('accept'),
                  icon: 'error',
                });
              }
              this.isFormSubmitted = false;
              resolve(false);
            }
          );
      } catch (err) {
        throw new Error(err.message);
      }
    });
  }

  submitForm(
    showModal: boolean = true
  ): Promise<{ result: boolean; connected: boolean; error: boolean }> {
    return new Promise<{ result: boolean; connected: boolean; error: boolean }>(
      (resolve, reject) => {
        try {
          this.irrigationService
            .getIrrigationTerminal(this.terminal_vid)
            .subscribe((res) => {
              this.handlePost(showModal, res.connected).then((result) => {
                resolve({
                  result,
                  connected: res.connected,
                  error: this.isErrorInMixtureFormulasPost,
                });
              });
            });
        } catch (err) {
          reject({ result: false, connected: false });
        } finally {
          // Remove dirty inputs due to use standalone ngModels
          removeDirtyInputs(this.mixturesForm);

          this.mixturesForm.markAsUntouched();

          this.mixturesForm.markAsPristine();
          this.mixturesForm.patchValue({ isDirty: false });
        }
      }
    );
  }

  ngOnDestroy() {
    this.formFieldErrorSubscription?.unsubscribe();
  }
}
