import { Component, Input, OnInit } from '@angular/core';
import { TranslationsLibService } from '@nutricontrol/app360-shared';
import { FormBuilder, Validators } from '@angular/forms';
import { ProfileService } from '../../profile.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-auth-2fa',
  templateUrl: './auth-2fa.component.html',
  styleUrls: ['./auth-2fa.component.scss'],
})
export class Auth2faComponent implements OnInit {
  @Input() userVid: string;

  ready = false;

  private fb: FormBuilder = new FormBuilder();

  selectedAuth2faType = '';
  selectedAuth2faUserPreference = '';

  auth2faTypes = ['auth_user_email'];
  auth2faUserPreferences = ['disabled', 'on_new_login', 'always'];

  auth2faForm = this.fb.group({
    auth_2fa_type: ['', Validators.required],
    auth_2fa_user_preference: ['', Validators.required],
  });

  constructor(
    public translationsLib: TranslationsLibService,
    public profileService: ProfileService
  ) {}

  ngOnInit(): void {
    this.loadAuthConfig();
  }

  loadAuthConfig(): void {
    this.ready = false;
    this.profileService.getAuthConfig(this.userVid).subscribe((authConfig) => {
      this.selectedAuth2faType = authConfig.auth_2fa_type;
      this.selectedAuth2faUserPreference = authConfig.auth_2fa_user_preference;
      this.ready = true;
    });
  }

  onSubmit(): void {
    this.profileService
      .putAuthConfig({
        user_vid: this.userVid,
        auth_2fa_type: this.selectedAuth2faType,
        auth_2fa_user_preference: this.selectedAuth2faUserPreference,
      })
      .subscribe(
        (authConfig) => {
          Swal.fire({
            text: this.translationsLib.get('save_changes_success'),
            showConfirmButton: true,
            confirmButtonText: this.translationsLib.get('accept'),
            icon: 'success',
          }).then(() => {
            this.loadAuthConfig();
          });
        },
        (result) => {
          Swal.fire({
            icon: 'error',
            title: this.translationsLib.get('error'),
            text: this.translationsLib.get('fill_in_all_inputs_alert'),
            confirmButtonColor: '#224261',
          });
        }
      );
  }
}
