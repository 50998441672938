import * as Highcharts from 'highcharts';
Highcharts.seriesType('column2', 'column', {});
export const dataloggerEtoBarChartsModel: any = {
  chart: {
    type: 'column',
    events: {
      render() {
        const chart = this;

        if (this.customPlotLines) {
          this.customPlotLines.destroy();
          this.customPlotLines = undefined;
        }

        this.customPlotLines = chart.renderer
          .g('customPlotLines')
          .add()
          .toFront();
        chart.series.forEach((serie) => {
          if (serie.userOptions.model !== 'ETC') {
            serie.points.forEach((point) => {
              if (point.isNull === false) {
                chart.renderer
                  .rect(
                    chart.plotLeft + point.shapeArgs.x,
                    chart.plotTop + point.shapeArgs.y,
                    point.shapeArgs.width,
                    3
                  )
                  .attr({
                    fill: 'black',
                  })
                  .add(this.customPlotLines);
              }
            });
          }
        });
      },
    },
  },
  title: {
    align: 'left',
    text: null,
  },
  subtitle: {
    align: 'left',
    text: '',
  },
  accessibility: {
    announceNewData: {
      enabled: true,
    },
  },
  xAxis: [
    {
      categories: [],
      title: {
        text: null,
      },
    },
    {
      categories: [],
      title: {
        text: null,
      },
      visible: false,
    },
  ],
  yAxis: {
    title: {
      text: null,
    },
  },
  plotOptions: {
    series: {
      centerInCategory: true,
    },
    column: {
      grouping: false,
    },
    column2: {
      grouping: true,
    },
  },
  legend: {
    /*layout: 'vertical',
    align: 'right',
    verticalAlign: 'top',
    x: -40,
    y: 0,
    floating: true,
    borderWidth: 1,
    backgroundColor:
      Highcharts.defaultOptions.legend.backgroundColor || '#FFFFFF',
    shadow: true,*/
  },
  credits: {
    enabled: false,
  },
  tooltip: {
    headerFormat: '<span style="font-size:11px">{point.x}</span><br>',
    /*pointFormat: '<b>{point.y:.2f}</b><br/>',*/
    shared: true,
    valueDecimals: 2,
  },
  dataLabels: {
    enabled: true,
    formatter() {
      return Highcharts.numberFormat(this.y, 2);
    },
  },
  series: [
    {
      name: '',
      colorByPoint: false,
      data: [],
    },
  ],
  exporting: {
    buttons: {
      contextButton: {
        menuItems: [
          'printChart',
          'separator',
          'downloadPNG',
          'downloadJPEG',
          'downloadPDF',
          'downloadSVG',
          'separator',
          'downloadCSV',
          'downloadXLS',
          //"viewData",
          'openInCloud',
        ],
      },
    },
  },
};
