<app-main-header [breadcrumbs]="breadcrumbs"></app-main-header>
<ion-content>
  <lib-loader *ngIf="ready === false"></lib-loader>
  <div class="container-fluid p-1 p-lg-2" *ngIf="ready === true">
    <div class="mx-lg-4">
      <div class="col-12">
        <mat-tab-group class="col-12" (selectedTabChange)="changeToTab($event)" #tabGroup>
          <mat-tab label="{{ translationsLib.get('terminal_terminal') }}">

            <lib-loader *ngIf="recycleMapTab === true"></lib-loader>
            <div class="mt-4" *ngIf="recycleMapTab === false">
              <div class="card mb-4">
                <lib-terminal-header [equipment]="terminal" [showButton]="false"></lib-terminal-header>
              </div>
              <app-terminals-edit></app-terminals-edit>
            </div>
          </mat-tab>
          <mat-tab label="{{ translationsLib.get('permission_permissions') }}">

            <div class="mt-4">
              <div class="card">
              <lib-terminal-header [equipment]="terminal" [showButton]="false"></lib-terminal-header>
            </div>
              <app-terminal-permissions></app-terminal-permissions>
            </div>
          </mat-tab>

          <mat-tab label="{{ translationsLib.get('permission_administrative_management') }}">

            <div class="mt-4">
              <lib-administrative-management-terminal [terminalVid]="terminal.vid" [expanded]="true"></lib-administrative-management-terminal>
            </div>
          </mat-tab>
        </mat-tab-group>
      </div>
    </div>
  </div>
</ion-content>
