import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { IrrigationService } from '../../../irrigation.service';
import { ActivatedRoute, Router } from '@angular/router';
import Swal from 'sweetalert2';
import {
  Progval,
  WaterSystemIrrigationInstallerModel,
} from '../../../irrigation.model';
import {
  removeDirtyInputs,
  saveDataAndShowModal,
  saveSubmitWithTerminalStatus,
} from '../../../../../commons/helpers/functions';
import { TranslationsLibService } from '@nutricontrol/app360-shared';
import { EquipmentsLibService } from '../../../../../services/libraries/equipments-lib.service';
import { Subscription } from 'rxjs';
import { FormFieldHandlerService } from '../../../../libraries/form-field-handler.service';
import { TerminalsService } from '../../../../../terminals/terminals.service';
import { environment } from '../../../../../../environments/environment';
import { InputNumberService } from '../../../../libraries/input-number.service';

@Component({
  selector: 'app-valves-form',
  templateUrl: './valves-form.component.html',
  styleUrls: ['./valves-form.component.scss'],
})
export class ValvesFormComponent implements OnInit, OnDestroy {
  isFetchingData = false;
  private formFieldErrorSubscription: Subscription;
  isFormSubmitted = false;
  valvesForm: UntypedFormGroup;
  isConnected: boolean;
  private intervalId: any;
  isValvesWithoutData = false;

  isAnyValveDirtyAfterPost = false;

  waterSystemsList: WaterSystemIrrigationInstallerModel[];

  @Input() mode: string;
  @Input() terminal_vid: string;
  @Input() valve_id: number;

  @Output() dirtyEventEmitter = new EventEmitter<boolean>();
  @Output() valveEventEmitter = new EventEmitter<any>();
  @Output() formFieldErrorEventEmitter = new EventEmitter<boolean>();

  layoutConfiguration: Progval;

  isErrorInValvesPost = false;

  constructor(
    private fb: UntypedFormBuilder,
    private activatedRoute: ActivatedRoute,
    private irrigationService: IrrigationService,
    private router: Router,
    public translationsLib: TranslationsLibService,
    private equipmentLib: EquipmentsLibService,
    private formFieldHandlerService: FormFieldHandlerService,
    private terminalsService: TerminalsService,
    public inputNumberService: InputNumberService
  ) {
    this.valvesForm = this.fb.group({
      valve: [null],
      dirty: [''],
      name: [null],
      flow: [null, Validators.required],
      waterSystem: [null, Validators.required],
      counterPulse: [null, Validators.required],
      pressure: [null],
      isDirty: false,
    });
  }

  async startInterval() {
    this.intervalId = setInterval(() => {
      this.performTasks();
    }, environment.intervalDefaultTimeout);
  }

  async performTasks() {
    this.valvesForm.reset();

    this.getWaterSystemsList();
    this.getLayoutConfiguration();
    this.getValve();

    await this.getIrrigationEquipment();

    if (!this.isConnected) {
      this.equipmentLib.showConnectivityAlert();
    }

    this.isFormSubmitted = false;
  }

  ngOnInit() {
    this.valvesForm.reset(); // Prevent unsaved data to persist in form inputs

    this.activatedRoute.params.subscribe((_) => {
      this.getWaterSystemsList();
      this.getLayoutConfiguration();
      this.getValve();

      removeDirtyInputs(this.valvesForm);
    });

    this.valvesForm.valueChanges.subscribe((_) => {
      this.dirtyEventEmitter.emit(this.getIsDirty());

      if (this.getIsDirty()) {
        clearInterval(this.intervalId);
      } else {
        clearInterval(this.intervalId);
        this.startInterval();
      }
    });

    this.getIrrigationEquipment();
  }

  getLayoutConfiguration() {
    this.irrigationService
      .getTerminalLayoutConfiguration(this.terminal_vid)
      .subscribe((res) => {
        this.layoutConfiguration = res.screens_configuration.progval;
        if (!this.layoutConfiguration.enabled) {
          this.router.navigate(['/home/dashboard']);
        }
      });
  }

  setDirty(dirty) {
    this.valvesForm.patchValue({ isDirty: dirty });
  }

  getIsDirty() {
    return this.valvesForm.dirty || this.valvesForm.value.isDirty;
  }

  getWaterSystemsList() {
    this.irrigationService
      .getIrrigationInstallerWaterSystems(this.terminal_vid)
      .subscribe((res) => {
        this.waterSystemsList = res;
      });
  }

  getValve(isGetAfterPost = false) {
    if (!isGetAfterPost) this.isFetchingData = true;

    const pagination = 0;

    this.irrigationService
      .getIrrigationInstallerValve(this.terminal_vid, this.valve_id)
      .subscribe((res) => {
        this.isAnyValveDirtyAfterPost = res.some(
          (valve) => valve.dirty === true
        );

        this.valveEventEmitter.emit(res[0]);

        if (res.length === 0) {
          this.isValvesWithoutData = true;
        } else {
          this.valvesForm.patchValue({
            valve: res[pagination].valve,
            dirty: res[pagination].dirty,
            name: res[pagination].name,
            flow: res[pagination].flow,
            waterSystem: res[pagination].waterSystem,
            counterPulse: res[pagination].counterPulse,
            pressure: res[pagination].pressure,
          });
        }

        if (!isGetAfterPost) this.isFetchingData = false;
      });
  }

  handlePost(showModal: boolean, connected: boolean): Promise<boolean> {
    return new Promise<boolean>((resolve, _) => {
      this.isFormSubmitted = true;

      const valvesAsArray = [];
      valvesAsArray.push({
        valve: this.valvesForm.value.valve,
        name: this.valvesForm.value.name,
        flow: this.valvesForm.value.flow,
        waterSystem: Number(this.valvesForm.value.waterSystem),
        counterPulse: this.valvesForm.value.counterPulse,
        pressure: this.valvesForm.value.pressure,
      });

      try {
        this.irrigationService
          .postIrrigationInstallerValves(this.terminal_vid, valvesAsArray)
          .subscribe(
            (res) => {
              if (res.error) this.isErrorInValvesPost = true;

              if (
                connected &&
                !this.valvesForm.value.dirty &&
                !this.isErrorInValvesPost
              ) {
                saveDataAndShowModal(
                  res,
                  showModal,
                  this.translationsLib.get('error_updating_data'),
                  this.translationsLib.get('save_changes_success'),
                  this.translationsLib.get('accept'),
                  () => {
                    setTimeout(() => {
                      this.getWaterSystemsList();
                      this.getValve(true);
                    }, 4000);
                  }
                );

                this.isFormSubmitted = false;
                resolve(true);
              } else if (this.isErrorInValvesPost) {
                Swal.fire({
                  text: this.translationsLib.get(
                    'irrigation_general_program_error'
                  ),
                  showConfirmButton: true,
                  confirmButtonText: this.translationsLib.get('accept'),
                  icon: 'error',
                });

                this.isFormSubmitted = false;
                resolve(true);
              } else {
                saveSubmitWithTerminalStatus(
                  res,
                  showModal,
                  connected,
                  this.translationsLib.get('error_updating_data'),
                  this.translationsLib.get('save_changes_success'),
                  this.translationsLib.get('accept'),
                  this.translationsLib.get(
                    'irrigation_disconnected_terminal_pending_changes'
                  )
                );

                this.isFormSubmitted = false;
                resolve(true);
              }
            },
            (_) => {
              if (showModal) {
                Swal.fire({
                  text: this.translationsLib.get('something_was_wrong'),
                  showConfirmButton: true,
                  confirmButtonText: this.translationsLib.get('accept'),
                  icon: 'error',
                });
              }
              this.isFormSubmitted = false;
              resolve(false);
            }
          );
      } catch (err) {
        throw new Error(err.message);
      }
    });
  }

  getIrrigationEquipment() {
    this.irrigationService
      .getIrrigationTerminal(this.terminal_vid)
      .subscribe((res) => {
        this.isConnected = res.connected;
      });
  }

  submitForm(
    valve,
    showModal: boolean = true
  ): Promise<{ result: boolean; connected: boolean }> {
    const valveData = [];
    valveData.push(valve);
    return new Promise<{ result: boolean; connected: boolean }>(
      (resolve, reject) => {
        try {
          this.irrigationService
            .getIrrigationTerminal(this.terminal_vid)
            .subscribe((res) => {
              this.handlePost(showModal, res.connected).then((result) => {
                resolve({ result, connected: res.connected });
              });
            });
          this.terminalsService
            .putTerminalValves(this.terminal_vid, valveData)
            .subscribe((res) => {
              console.log(res);
            });
        } catch (err) {
          reject({ result: false, connected: false });
        } finally {
          // Remove dirty inputs due to use standalone ngModels
          removeDirtyInputs(this.valvesForm);

          this.valvesForm.markAsUntouched();

          this.valvesForm.markAsPristine();
          this.valvesForm.patchValue({ isDirty: false });
        }
      }
    );
  }

  ngOnDestroy(): void {
    clearInterval(this.intervalId);
    this.formFieldErrorSubscription?.unsubscribe();
  }
}
