<div class="card">
  <div class="card-header">
    <h2 class="text-header-primary m-0">{{ translationsLib.get('notification_notifications') }}</h2>
    <a (click)="reload()" class="btn btn-primary p-2 lh-1">
      <ion-icon name="refresh-outline" class="d-flex fs-2"></ion-icon>
    </a>
  </div>
  <div class="card-body px-2 px-sm-4">
    <div class="row">
      <div class="col-12 col-xl-4 col-xxl-3">
        <div class="card d-none d-xl-block mb-0" style="position: sticky; top: 1.5rem">
          <img src="assets/img/notifications/notificaciones.svg" class="card-img-top" alt="...">
          <div class="card-body pt-0">
            <hr class="hr hr-blurry">
            <h2 class="text-center mt-0 mb-4 text-header-primary">{{ translationsLib.get('notification_question') }}</h2>
            <p class="card-text" style="text-align: justify">{{ translationsLib.get('notification_explain') }}</p>
          </div>
        </div>
        <div class="card d-xl-none">
          <div class="row align-items-center">
            <div class="d-none d-sm-block col-4">
              <img src="assets/img/notifications/notificaciones.svg" class="img-fluid" alt="...">
            </div>
            <div class="col-12 col-sm-8">
              <div class="card-body">
                <h2 class="text-center mt-0 mb-4 text-header-primary">{{ translationsLib.get('notification_question') }}</h2>
                <p class="card-text" style="text-align: justify">{{ translationsLib.get('notification_explain') }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-xl-8 col-xxl-9">
        <div class="row">
          <div class="col-12 col-sm-6">
            <h4 class="d-inline-block mt-0 help-title">
              <span class="me-3">{{ translationsLib.get('notification_detailed_reports') }}</span>
              <a [routerLink]='["/reports"]' class="btn btn-secondary lift">
                <ion-icon [ios]="'arrow-redo-outline'" [md]="'arrow-redo-outline'"></ion-icon>
              </a>
            </h4>
            <p class="help-text">{{ translationsLib.get('notification_detailed_reports_explain') }}</p>
          </div>
          <div class="col-12 col-sm-6">
            <h4 class="d-inline-block mt-0 help-title">
              <span class="me-3">{{ translationsLib.get('notification_preferences') }}</span>
              <a [routerLink]='["/profile"]' [fragment]="'notifications'" class="btn btn-secondary lift">
                <ion-icon [ios]="'arrow-redo-outline'" [md]="'arrow-redo-outline'"></ion-icon>
              </a>
            </h4>
            <p class="help-text">{{ translationsLib.get('notification_preferences_explain') }}</p>
          </div>
        </div>
        <h4 class="help-title">{{ translationsLib.get('notification_select_terminals') }}</h4>
        <app-notifications-table></app-notifications-table>
      </div>
    </div>
  </div>
</div>
