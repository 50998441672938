<app-main-header [breadcrumbs]="breadcrumbs"></app-main-header>

<ion-content>
  <div class="border border-light border-bottom-3 mb-3"></div>

  <div
    class="d-flex justify-content-center align-items-center h-75"
    *ngIf="isFetchingData"
  >
    <div class="spinner-border text-warning" role="status">
      <span class="visually-hidden">{{
        this.translationsLib.get('irrigation_loading')
      }}</span>
    </div>
  </div>

  <div *ngIf="!isFetchingData && !isIOReportWithoutData" #notFetching>
    <div class="container-fluid custom-container-fluid">
      <h1>
        {{
          translationsLib.get('irrigation_input_output_io_list') +
            ' ' +
            terminalName
        }}
      </h1>

      <!-- Expansions -->
      <h2>{{ translationsLib.get('irrigation_input_output_expansions') }}</h2>
      <div class="card">
        <div class="table-responsive card-body card-body-style">
          <table class="table expansion-table" id="expansions-table">
            <thead>
              <tr>
                <th scope="col">
                  {{ translationsLib.get('irrigation_input_output_next') }}
                </th>
                <th scope="col">
                  {{ translationsLib.get('irrigation_input_output_type') }}
                </th>
                <th scope="col">
                  {{
                    translationsLib.get('irrigation_input_output_speed_time')
                  }}
                </th>
                <th scope="col">
                  {{ translationsLib.get('irrigation_input_output_timeout') }}
                </th>
                <th scope="col">
                  {{
                    translationsLib.get(
                      'irrigation_input_output_analogic_inputs'
                    )
                  }}
                </th>
                <th scope="col">
                  {{
                    translationsLib.get(
                      'irrigation_input_output_digital_outputs'
                    )
                  }}
                </th>
                <th scope="col">
                  {{
                    translationsLib.get(
                      'irrigation_input_output_analogic_outputs'
                    )
                  }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let expansion of expansionsResponse; let i = index">
                <td>{{ expansion.nexp }}</td>
                <td>{{ expansion.typeName }}</td>
                <td>{{ expansion.velocityType }}</td>
                <td>{{ expansion.timeout }}</td>
                <td>{{ expansion.analogicInputs }}</td>
                <td>{{ expansion.digitalOutputs }}</td>
                <td>{{ expansion.analogicOutputs }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <!-- Entradas analógicas -->
      <ng-container *ngFor="let expansion of expansionsResponse">
        <div
          class="card"
          *ngIf="
            expansion.analogicSensors && expansion.analogicSensors.length > 0
          "
        >
          <div class="table-responsive card-body card-body-style">
            <table
              class="table expansion-table"
              id="expansions-table-analogic-sensors"
            >
              <ng-container>
                <thead>
                  <tr>
                    <th scope="col">
                      {{
                        translationsLib.get('irrigation_input_output_nent_type')
                      }}
                    </th>
                    <th scope="col">
                      {{ translationsLib.get('irrigation_name') }}
                    </th>
                    <th scope="col">
                      {{ translationsLib.get('irrigation_input_output_min') }}
                    </th>
                    <th scope="col">
                      {{ translationsLib.get('irrigation_input_output_max') }}
                    </th>
                    <th scope="col">
                      {{
                        translationsLib.get('irrigation_input_output_min_freq')
                      }}
                    </th>
                    <th scope="col">
                      {{
                        translationsLib.get('irrigation_input_output_max_freq')
                      }}
                    </th>
                    <th scope="col">
                      {{
                        translationsLib.get('irrigation_input_output_sampling')
                      }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    *ngFor="
                      let sensor of expansion.analogicSensors;
                      let i = index
                    "
                  >
                    <td>
                      {{
                        'EA' +
                          (sensor.nent + 1) +
                          '-' +
                          (sensor.typeName === '' ? i : sensor.typeName)
                      }}
                    </td>
                    <td>{{ sensor.name }}</td>
                    <td>{{ sensor.min }}</td>
                    <td>{{ sensor.max }}</td>
                    <td>{{ sensor.FMin }}</td>
                    <td>{{ sensor.FMax }}</td>
                    <td>{{ sensor.offset }}</td>
                  </tr>
                </tbody>
              </ng-container>
            </table>
          </div>
        </div>
      </ng-container>
      <!-- Entradas digitales -->
      <ng-container *ngFor="let expansion of expansionsResponse">
        <div
          class="card"
          *ngIf="
            expansion.digitalSensors && expansion.digitalSensors.length > 0
          "
        >
          <div class="table-responsive card-body card-body-style">
            <table
              class="table expansion-table"
              id="expansions-table-digital-sensors"
            >
              <ng-container>
                <thead>
                  <tr>
                    <th scope="col">
                      {{
                        translationsLib.get('irrigation_input_output_nent_type')
                      }}
                    </th>
                    <th scope="col">
                      {{ translationsLib.get('irrigation_name') }}
                    </th>
                    <th scope="col">
                      {{ translationsLib.get('irrigation_input_output_min') }}
                    </th>
                    <th scope="col">
                      {{ translationsLib.get('irrigation_input_output_max') }}
                    </th>
                    <th scope="col">
                      {{
                        translationsLib.get('irrigation_input_output_min_freq')
                      }}
                    </th>
                    <th scope="col">
                      {{
                        translationsLib.get('irrigation_input_output_max_freq')
                      }}
                    </th>
                    <th scope="col">
                      {{
                        translationsLib.get('irrigation_input_output_sampling')
                      }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    *ngFor="
                      let sensor of expansion.digitalSensors;
                      let i = index
                    "
                  >
                    <td>
                      {{
                        'ED' +
                          (sensor.nent + 1) +
                          '-' +
                          (sensor.typeName === '' ? i : sensor.typeName)
                      }}
                    </td>
                    <td>{{ sensor.name }}</td>
                    <td>{{ sensor.min }}</td>
                    <td>{{ sensor.max }}</td>
                    <td>{{ sensor.FMin }}</td>
                    <td>{{ sensor.FMax }}</td>
                    <td>{{ sensor.offset }}</td>
                  </tr>
                </tbody>
              </ng-container>
            </table>
          </div>
        </div>
      </ng-container>

      <!-- Inputs -->
      <h2>{{ translationsLib.get('irrigation_input_output_input') }}</h2>
      <div class="card">
        <div class="table-responsive card-body card-body-style">
          <table class="table expansion-table" id="inputs-table">
            <thead>
              <tr>
                <th scope="col">
                  {{
                    translationsLib.get('irrigation_input_output_type_subtype')
                  }}
                </th>
                <th scope="col">
                  {{ translationsLib.get('irrigation_name') }}
                </th>
                <th scope="col">
                  {{ translationsLib.get('irrigation_input_output_nexp_nent') }}
                </th>
                <th scope="col">
                  {{ translationsLib.get('irrigation_water_system') }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let input of inputsResponse; let i = index">
                <td>{{ input.subtypeName === '' ? i : input.subtypeName }}</td>
                <td>{{ input.typeName === '' ? i : input.typeName }}</td>
                <td>
                  {{ formatExpInput(input) }}
                </td>
                <td>{{ input.waterSystem }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <!-- Entradas digitales -->
      <ng-container *ngFor="let input of inputsResponse">
        <div
          class="card"
          *ngIf="input.digitalSensors && input.digitalSensors.length > 0"
        >
          <div class="table-responsive card-body card-body-style">
            <table
              class="table expansion-table"
              id="inputs-table-digital-sensors"
            >
              <thead>
                <tr>
                  <th scope="col">
                    {{
                      translationsLib.get(
                        'irrigation_input_output_type_subtype'
                      )
                    }}
                  </th>
                  <th scope="col">
                    {{ translationsLib.get('irrigation_name') }}
                  </th>
                  <th scope="col">
                    {{
                      translationsLib.get('irrigation_input_output_nexp_nent')
                    }}
                  </th>
                  <th scope="col">
                    {{ translationsLib.get('irrigation_water_system') }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let sensor of input.digitalSensors; let i = index">
                  <td>
                    {{ sensor.typeName }}
                  </td>
                  <td>{{ sensor.name }}</td>
                  <td>
                    {{ formatExpInput(sensor, false, true) }}
                  </td>
                  <td>{{ sensor.physicalType }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </ng-container>
      <!-- Entradas analógicas -->
      <ng-container *ngFor="let input of inputsResponse">
        <div
          class="card"
          *ngIf="input.analogicSensors && input.analogicSensors.length > 0"
        >
          <div class="table-responsive card-body card-body-style">
            <table
              class="table expansion-table"
              id="inputs-table-analogic-sensors"
            >
              <thead>
                <tr>
                  <th scope="col">
                    {{
                      translationsLib.get(
                        'irrigation_input_output_type_subtype'
                      )
                    }}
                  </th>
                  <th scope="col">
                    {{ translationsLib.get('irrigation_name') }}
                  </th>
                  <th scope="col">
                    {{
                      translationsLib.get('irrigation_input_output_nexp_nent')
                    }}
                  </th>
                  <th scope="col">
                    {{ translationsLib.get('irrigation_water_system') }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let sensor of input.digitalSensors; let i = index">
                  <td>
                    {{ sensor.typeName }}
                  </td>
                  <td>{{ sensor.name }}</td>
                  <td>
                    {{ formatSensorValue(sensor) }}
                  </td>
                  <td>{{ sensor.physicalType }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </ng-container>

      <!-- Outputs -->
      <h2>{{ translationsLib.get('irrigation_input_output_output') }}</h2>
      <div class="card">
        <div class="table-responsive card-body card-body-style">
          <table class="table expansion-table" id="outputs-table">
            <thead>
              <tr>
                <th scope="col">
                  {{
                    translationsLib.get('irrigation_input_output_type_subtype')
                  }}
                </th>
                <th scope="col">
                  {{ translationsLib.get('irrigation_input_output_nexp_nent') }}
                </th>
                <th scope="col">
                  {{ translationsLib.get('irrigation_water_system') }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let output of outputsResponse; let i = index">
                <td>{{ output.typeName }}</td>
                <td>
                  {{ formatExpOutput(output) }}
                </td>
                <td>{{ output.waterSystem }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</ion-content>

<footer
  *ngIf="!isIOReportWithoutData"
  class="footer mt-auto px-4 py-3 bg-light-soft border-top-3 border-light border"
>
  <div class="row">
    <div class="col">
      <button
        *ngIf="showCreatePDFButton"
        [disabled]="isFetchingData"
        (click)="createInputOutputReportPDF()"
        class="btn btn-primary float-end"
      >
        <span
          *ngIf="isFormSubmitted"
          class="spinner-border spinner-border-sm"
          role="status"
          aria-hidden="true"
        ></span>
        {{ translationsLib.get('irrigation_input_output_create_pdf') }}
      </button>
    </div>
  </div>
</footer>
