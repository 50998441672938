import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RestApiService } from '../services/api/rest-api.service';
import { FieldDataModel } from '@nutricontrol/app360-shared';

@Injectable({
  providedIn: 'root',
})
export class FieldsService {
  private restApiService: RestApiService;

  constructor(restApiService: RestApiService) {
    this.restApiService = restApiService;
  }
  public getField(data): Observable<FieldDataModel> {
    return new Observable((observer) => {
      this.restApiService.getField(data).subscribe(
        (response) => {
          observer.next(response);
        },
        (error) => {
          observer.error(error);
        }
      );
    });
  }
  public getFields(): Observable<FieldDataModel[]> {
    return new Observable((observer) => {
      this.restApiService.getFields().subscribe(
        (response) => {
          observer.next(response);
        },
        (error) => {
          observer.error(error);
        }
      );
    });
  }

  public postField(data): Observable<FieldDataModel> {
    return new Observable((observer) => {
      this.restApiService.postField(data).subscribe(
        (response) => {
          observer.next(response);
        },
        (error) => {
          observer.error(error);
        }
      );
    });
  }

  public putFields(data, vid): Observable<FieldDataModel> {
    return new Observable((observer) => {
      this.restApiService.putFields(data, vid).subscribe(
        (response) => {
          observer.next(response);
        },
        (error) => {
          observer.error(error);
        }
      );
    });
  }
  public deleteField(vid): Observable<FieldDataModel> {
    return new Observable((observer) => {
      this.restApiService.deleteField(vid).subscribe(
        (response) => {
          observer.next(response);
        },
        (error) => {
          observer.error(error);
        }
      );
    });
  }
}
