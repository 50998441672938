import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'programDate',
})
export class programDate implements PipeTransform {
  transform(date: string): string {
    return moment(date).format('DD/MM');
  }
}
