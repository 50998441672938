<app-main-header [breadcrumbs]="breadcrumbs"></app-main-header>

<lib-billing-popup-terminal [terminalVid]="terminalVid" *ngIf="sessionLib.getUserHasNonDemoTerminals() === 'true'"></lib-billing-popup-terminal>

<ion-content>
  <div
    *ngIf="
      !terminalData &&
        !weather &&
        !equipmentResponse &&
        !programsResponse &&
        !fertilizerFormulasResponse;
      else notLoading
    "
  >
    <div class="d-flex mt-4 justify-content-center align-items-center h-75">
      <div class="spinner-border text-warning" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
  </div>

  <ng-template #notLoading>
    <div class="container-fluid my-4" *ngIf="equipmentResponse">
      <div class="mb-4">
        <app-equipment-data
          type="configurable"
          [terminal_vid]="terminalVid"
          [equipmentResponse]="equipmentResponse"
        ></app-equipment-data>
      </div>

      <app-meteo-grid
        [meteoRows]="meteoRows"
        [terminalType]="'configurable'"
        [yesterday_ET0]="yesterday_ET0"
        [isFetchingET0]="isLoading"
        [calculateET0Request]="calculateET0Request"
      ></app-meteo-grid>

      <app-configurable-water-system
        [equipment]="equipmentResponse"
        [class]="'mb-4'"
      >
      </app-configurable-water-system>

      <app-configurable-states
        [equipment]="equipmentResponse"
        [terminal]="terminalData"
      ></app-configurable-states>

      <app-alarms-grid
        *ngIf="equipmentResponse"
        [equipmentResponse]="equipmentResponse"
        [alarmRows]="alarmRows"
        [type]="'configurable'"
      ></app-alarms-grid>

      <app-terminal-table-wrapper
        [data]="programsResponse"
        [states]="equipmentResponse.states"
        [name]="'configurable_programs'"
        [fertilizerFormulas]="fertilizerFormulasResponse"
        [searchPlaceholder]="translationsLib.get('configurable_name')"
        [isFetchingData]="isTableLoading"
        [terminal_vid]="terminalVid"
        [tableHeaders]="[
          translationsLib.get('configurable_status'),
          translationsLib.get('configurable_name'),
          translationsLib.get('configurable_program_week') +
            ' • ' +
            translationsLib.get('configurable_program_start_time') +
            ' • ' +
            translationsLib.get('configurable_program_end_time'),

          translationsLib.get('configurable_time')
        ]"
        type="configurable"
      >
        <tr
          class="row-pointer"
          *appTerminalTableData="let prog"
          [routerLink]="[
            '/farming',
            terminalVid,
            'configurable',
            'program',
            prog.program,
            'group',
            0
          ]"
        >
          <td class="terminal-table-data" *ngIf="prog.states; else notStates">
            <span
              class="badge"
              [ngClass]="{
                'bg-secondary': prog.active === false,
                'bg-success': prog.active === true
              }"
              >{{
                prog.active
                  ? translationsLib.get('irrigation_program_active')
                  : translationsLib.get('irrigation_program_inactive')
              }}</span
            >
            &nbsp;
            <span
              [class]="prog.states[0].state | badgeClass : 'CONFIGURABLE'"
              >{{
                translationsLib.get(
                  'irrigation_state_' + prog.states[0].state | lowercase
                )
              }}</span
            >
          </td>
          <ng-template #notStates>
            <td #notStates class="terminal-table-data">
              <span
                class="badge"
                [ngClass]="{
                  'bg-secondary': prog.active === false,
                  'bg-success': prog.active === true
                }"
                >{{
                  prog.active
                    ? translationsLib.get('irrigation_program_active')
                    : translationsLib.get('irrigation_program_inactive')
                }}</span
              >
            </td>
          </ng-template>
          <td class="terminal-table-data">
            {{ prog.name }}
          </td>
          <td class="terminal-table-data">
            {{ prog | convertWeekDays }} •
            {{ prog.startTime * 1000 | date : 'HH:mm:ss' : 'UTC' }} •
            {{ prog.endTime * 1000 | date : 'HH:mm:ss' : 'UTC' }}
          </td>
          <td class="terminal-table-data">
            {{
              getProgramState(prog.program)?.actualTime * 1000
                | date : 'HH:mm:ss' : 'UTC'
            }}
            /
            {{
              getProgramState(prog.program)?.todayTime * 1000
                | date : 'HH:mm:ss' : 'UTC'
            }}
          </td>
        </tr>
      </app-terminal-table-wrapper>
    </div>

    <div class="container-fluid my-4" *ngIf="terminalData && terminalEnabled === TERMINAL_ENABLED_TEMPORARY_DISABLED">
      <app-equipment-temporary-disabled-data
        [terminalData]="terminalData"
        [type]="'configurable'"
      ></app-equipment-temporary-disabled-data>

      <app-terminal-placeholder-data [terminalType]="'configurable'"></app-terminal-placeholder-data>
    </div>
  </ng-template>
</ion-content>
