<ng-container *ngFor="let field of fieldTerminals; index as i">
  <h4 class="mt-0 " style="color: #66B8BE">{{field.name}}</h4>
  <span class="example-list-section mb-2">
                    <ul class="ps-3 mb-0 mt-0 row">
                      <li class="ps-0 col-6 col-md-6"
                          [ngClass]="{
                          'col-lg-4':viewmode==='metrics',
                          'col-lg-3':viewmode==='megapop'
                          }" *ngFor="let device of field.devices">
                        <mat-checkbox class="special mb-3" [ngClass]="device.source_database" [(ngModel)]="device.completed"
                                      (ngModelChange)="megapop.sensoresOrdenados= []; megapop.updateAllComplete(); megapop.updating = true;">
                          <h5 class="mt-0 mb-0">{{device.name}}</h5>
                          <span class="text-muted align-self-center">
                            <!--<ng-container *ngIf="device.source_database === 'datalogger'">
                             <img src="{{backend + '/' + device.image_path}}" width="20px" style="margin-left: -25px"> &lt;!&ndash;{{ this.translationsLib.get('graphics_monitoreo') }}&ndash;&gt;
                            </ng-container>
                            <ng-container *ngIf="device.source_database === 'mcom'">
                             <img src="{{backend + '/' + device.image_path}}" width="20px" style="margin-left: -25px">&lt;!&ndash;{{ this.translationsLib.get('graphics_riego') }}&ndash;&gt;
                            </ng-container>
                            <ng-container *ngIf="device.source_database === 'mclima'">
                             <img src="{{backend + '/' + device.image_path}}" width="20px" style="margin-left: -25px">&lt;!&ndash;{{ this.translationsLib.get('graphics_clima') }}&ndash;&gt;
                            </ng-container>-->
                            {{device.pretty_type_name}}
                          </span>
                        </mat-checkbox>
                      </li>
                    </ul>
                </span>
</ng-container>
