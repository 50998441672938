<lib-loader *ngIf="!translationsLoaded || !terminalsLoaded"></lib-loader>
<ion-app *ngIf="translationsLoaded && terminalsLoaded">
  <meta charset="utf-8" />


  <ion-split-pane contentId="main-content">
    <ion-menu
      menuId="main-menu"
      contentId="main-content"
      type="overlay"
      [hidden]="showSidebar === false"
      (ionWillOpen)="menuOpened()"
      (ionWillClose)="menuClosed()"
    >
      <!-- ion-content allows to scroll the sidebar -->
      <ion-content>
        <app-sidebar *ngIf="session.getSessionToken() !== null"></app-sidebar>
      </ion-content>
    </ion-menu>
    <ion-router-outlet id="main-content" class="pb-lg-5">
      <aside id="routing-is-loading" *ngIf="showQueryIsLoading">
        <ion-progress-bar type="indeterminate"></ion-progress-bar>
      </aside>

      <aside id="bottom-tabs" *ngIf="session.getSessionToken() !== null">
        <div class="bottom-tabs-container">
          <app-bottom-tabs [isMenuOpen]="isMenuOpen"></app-bottom-tabs>
        </div>
      </aside>
    </ion-router-outlet>
    <div class="popup-cookies" id="popup-cookies" *ngIf="showCookiePolicyPopup === true">
      <div class="popup-title">{{ translationsLib.get('cookie_policy') }}</div>
      <div class="popup-info">
        <div class="row">
          <div class="col-12 col-xl-9">
            <div class="popup-text">
              <span class="text-muted">
                {{ translationsLib.get('privacy_policy_popup_text') }}
              </span>
              <span style="cursor: pointer; color:#3D6493" (click)="legalAgreementsLib.showCookiePolicy()">
                {{ translationsLib.get('cookie_policy') }}
              </span>
            </div>
          </div>
          <div class="col-12 col-xl-3 text-end">
            <button id="cookieButton" type="submit"class="btn btn-primary" (click)="acceptCookiesPolicy()">
              {{ translationsLib.get('accept') }}
            </button>
          </div>
        </div>
      </div>
    </div>

  </ion-split-pane>
  <lib-billing-popup *ngIf="session.getUserHasNonDemoTerminals() === 'true'" [platform]="'ucrop'"></lib-billing-popup>
</ion-app>
