import { Injectable } from '@angular/core';
import { TranslationsLibService } from '@nutricontrol/app360-shared';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class MapsLibService {
  public translationsLib: TranslationsLibService;
  constructor(translationsLib: TranslationsLibService) {
    this.translationsLib = translationsLib;
  }

  staticMapString(coordenadasString, centro, color, size, zoomDefault) {
    let zoom = 0; //aqui va un switch con los casos
    if (size < 20) {
      zoom = 16;
    }
    if (size > 20 && size < 50) {
      zoom = 15;
    }
    if (size > 50 && size < 300) {
      zoom = 14;
    }
    if (size > 300 && size < 600) {
      zoom = 13;
    }
    if (size > 600 && size < 1000) {
      zoom = 12;
    }
    if (size > 1000) {
      zoom = zoomDefault;
    }
    const map =
      'https://maps.googleapis.com/maps/api/staticmap?center=' +
      centro.lat +
      ', ' +
      centro.lng +
      '&zoom=' +
      zoom +
      '&path=color:0x' +
      color +
      '%7Cweight:1%7Cfillcolor:0x' +
      color +
      '%7C' +
      coordenadasString +
      '&size=700x400&maptype=hybrid' +
      '&key=' +
      environment.google_maps_static_api;
    return map;
  }
}
