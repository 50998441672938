<lib-loader *ngIf="ready === false"></lib-loader>
<div class="row justify-content-center" *ngIf="ready === true">
  <div class="col-12 col-md-8 col-lg-6 col-xl-4 px-lg-6 my-5 align-self-center">
    <div id="login-loader">
      <div class="spinner-container">
        <div class="spinner-logo text-center">
          <img src="../../../assets/img/ucrop.svg" alt="LOGO"  style="max-width: 200px">
        </div>
      </div>
    </div>

    <section>
      <h2 class="text-center mb-3">
        {{translationsLib.get('auth_2fa_pending_code_title')}}
      </h2>

      <!-- Subheading -->
      <p class="text-muted text-center mb-5">
        {{translationsLib.get('auth_2fa_pending_code_subtitle')}}
      </p>
    </section>

    <code-input id="codeInput"
                #codeInput
                inputType="text"
                [isCodeHidden]="false"
                [codeLength]="6"
                (codeChanged)="confirmCode($event)" #code>
    </code-input>

    <div class="text-center mt-4">
      <span class="text-muted text-center" *ngIf="showResend === false">
        <a style="cursor: pointer; color:#3d6493" (click)="showResend = true">
          {{ translationsLib.get('auth_forgotten_request_new') }}
        </a>
      </span>
      <form [formGroup]="auth2faResendConfirmationCode" (submit)="resendCode()" *ngIf="showResend === true">
        <div class="input-group">
          <input type="email" class="form-control" placeholder="{{translationsLib.get('auth_email')}}" formControlName="email" >
          <button type="submit" class="btn btn-primary"  [disabled]="auth2faResendConfirmationCode.invalid">{{translationsLib.get('auth_resend_mail')}}</button>
        </div>
      </form>
    </div>

    <div class="text-center mt-4">
      <span class="text-muted text-center">
        {{ translationsLib.get('auth_login_go_to_main_screen') }}
        <a style="cursor: pointer; color:#3d6493" (click)="router.navigate(['/login']);">
          {{ translationsLib.get('auth_login_sign_up_text') }}
        </a>
      </span>
    </div>

  </div>
  <div class="col-12 col-md-7 col-lg-6 col-xl-8 d-none d-lg-block">
    <!-- Image -->
    <div class="bg-cover h-100 min-vh-100 mt-n1 me-n3" style="background-image: url(assets/img/covers/Cover_login.jpg);"></div>
  </div>
</div>
