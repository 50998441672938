<lib-loader *ngIf="loader === true"></lib-loader>

<ng-container *ngIf="loader === false">
  <section class="reports-main-subcomponent-container" *ngIf="chart !== null && this.chart.totalRealWaterConsumptionTotal > 0 && showCharts === true">
    <app-reports-consumptions-charts
      [basicIrrigationConsumptionsReportBarsData]="chart.basicIrrigationProgramsConsumptionsReportBarsData"
      [basicFertilizerConsumptionsReportBarsData]="chart.basicFertilizerProgramsConsumptionsReportBarsData"
      [basicIrrigationConsumptionsReportChartData]="chart.basicIrrigationProgramsConsumptionsReportChartData"
      [basicFertilizerConsumptionsReportChartData]="chart.basicFertilizerProgramsConsumptionsReportChartData"
      [irrigationConsumptionsReportChartData]="chart.irrigationProgramsConsumptionsReportChartData"
      [fertilizerConsumptionsReportChartData]="chart.fertilizerProgramsConsumptionsReportChartData"
      [prettyBegin]="this.prettyBegin"
      [prettyEnd]="this.prettyEnd"
      [advancedModeIsAllowed]="advancedModeIsAllowed"
      [selectorModeIsAllowed]="true"
      [terminalCountWithConsumptions]="this.terminalCountWithConsumptions"
    ></app-reports-consumptions-charts>
  </section>

  <section class="reports-main-subcomponent-container" *ngFor="let reportData of reportsData;">
    <div class="card pb-3">
      <div class="card-header">
        <h2 class="card-header-title mb-3">
          {{ translationsLib.get('reports_irrigation_programs_consumptions') }}  <!-- {{ reportData.terminalNameVid }} -->
        </h2>
        <!--
        <h2 class="card-header-subtitle">
          {{ reportData.terminalFieldName }}
        </h2>
        -->
      </div>
      <div class="row mx-2 mt-3 print-no-padding">
        <div class="col-12 p-0 p-lg-2 consumption-container print-no-padding">
          <div class="alert alert-info mb-2" role="alert" *ngIf="reportData.programConsumptions.length === 0">
            {{ translationsLib.get('reports_no_data') }}
          </div>

          <article *ngIf="reportData.programConsumptions.length > 0">
            <!-- No need for simple table in consumptions! -->
            <app-reports-consumptions-table
              [reportName]="reportData.reportName"
              [consumptionColumnsToDisplay]="this.reportsConsumptionService.consumptionColumnsToDisplay"
              [fertilizerColumnsToDisplay]="reportData.fertilizerColumns"
              [totals]="reportData.programTotals"
              [data]="reportData.programConsumptions"
              [count]="reportData.programConsumptions.length"
              [tablePageItemNumber]="createdReport.tablePageItemNumber"
              [requestData]="requestData"
              [reportType]="'programs'"
            ></app-reports-consumptions-table>
          </article>

        </div>
      </div>
    </div>
  </section>
</ng-container>
