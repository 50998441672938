import {
  Component,
  Input,
  OnInit,
  OnChanges,
  OnDestroy,
  AfterViewInit,
  SimpleChanges,
  ChangeDetectorRef,
} from '@angular/core';
import { WaterSystemsService } from './services/water-system.service';
import { WaterSystemDataService } from './services/water-system-data.service';
import { WaterSystemTransformerService } from './services/water-system-transformer.service';
import { TranslationsLibService } from '@nutricontrol/app360-shared';
import { interval, Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { WaterSystem } from './models/water-system.model';
import {
  WaterSystemsFieldConfig,
  WaterSystemsFieldsConfig,
} from './models/water-system-config.model';
import { ConfigurableEquipmentModel } from '../configurable.model';

@Component({
  selector: 'app-configurable-water-system',
  templateUrl: './configurable-water-system.component.html',
  styleUrls: ['./configurable-water-system.component.scss'],
})
export class ConfigurableWaterSystemComponent
  implements OnInit, OnChanges, OnDestroy, AfterViewInit
{
  @Input() equipment: ConfigurableEquipmentModel;
  @Input() terminalVid: string;
  @Input() class = '';
  @Input() showLastConnection = true;
  protected isMobile = false;
  protected waterSystem: WaterSystem;
  protected fieldsConfigs: WaterSystemsFieldsConfig;
  protected configLoaded = false;
  protected equipmentLoaded = false;
  private intervalSubscription: Subscription;
  protected showMore = false;

  constructor(
    private waterSystemsService: WaterSystemsService,
    private waterSystemDataService: WaterSystemDataService,
    private waterSystemTransformerService: WaterSystemTransformerService,
    private breakpointObserver: BreakpointObserver,
    private cdRef: ChangeDetectorRef,
    protected translationsLib: TranslationsLibService
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.equipment) {
      this.waterSystemTransformerService
        .extractWaterSystem(changes.equipment.currentValue)
        .then((watersystem) => {
          this.waterSystem = watersystem;
          this.equipmentLoaded = true;
        });
    }
    if (changes.terminalVid) {
      this.loadEquipment(changes.terminalVid.currentValue);

      this.intervalSubscription = interval(
        environment.intervalDefaultTimeout
      ).subscribe(() => {
        this.loadEquipment(changes.terminalVid.currentValue);
      });
    }
  }

  ngOnInit() {
    this.loadConfig();
  }

  ngAfterViewInit() {
    this.breakpointObserver
      .observe([Breakpoints.Small, Breakpoints.XSmall])
      .subscribe((result) => {
        this.isMobile = result.matches;
        this.cdRef.detectChanges();
      });
  }

  ngOnDestroy() {
    if (this.intervalSubscription) {
      this.intervalSubscription.unsubscribe();
    }
  }

  private loadConfig(): void {
    this.waterSystemDataService.fetchConfig().subscribe({
      next: (configs) => {
        this.fieldsConfigs = configs;
        this.configLoaded = true;
      },
      error: (error) => {
        console.error('fetchConfig error:', error);
      },
    });
  }

  private loadEquipment(terminalVid: string) {
    this.waterSystemDataService.fetchEquipment(terminalVid).subscribe({
      next: (configurableEquipment) => {
        this.waterSystemTransformerService
          .extractWaterSystem(configurableEquipment)
          .then((waterSystem) => {
            this.waterSystem = waterSystem;
            this.equipmentLoaded = true;
          })
          .catch((error) => {
            console.error('ExtractWaterSystem error:', error);
          });
      },
      error: (error) => {
        console.error('FetchEquipment error:', error);
      },
    });
  }

  protected isAnyFieldVisibleInRow(
    rowConfig: WaterSystemsFieldConfig[]
  ): boolean {
    if (!rowConfig) {
      return false;
    }
    return rowConfig.some(
      (fieldConfig) => this.waterSystem.fields[fieldConfig.field]?.visible
    );
  }

  protected getFirstVisibleRows(
    configs: WaterSystemsFieldConfig[][],
    count: number
  ): WaterSystemsFieldConfig[][] {
    const visibleRows = configs.filter((rowConfig) =>
      this.isAnyFieldVisibleInRow(rowConfig)
    );
    return visibleRows.slice(0, count);
  }

  protected getRemainingVisibleRows(
    configs: WaterSystemsFieldConfig[][],
    count: number
  ): WaterSystemsFieldConfig[][] {
    const visibleRows = configs.filter((rowConfig) =>
      this.isAnyFieldVisibleInRow(rowConfig)
    );
    return visibleRows.slice(count);
  }

  protected hasRemainingVisibleRows(
    configs: WaterSystemsFieldConfig[][],
    count: number
  ): boolean {
    return this.getRemainingVisibleRows(configs, count).length > 0;
  }

  protected toggleShowMore() {
    this.showMore = !this.showMore;
  }

  protected toRowConfig(
    rowConfig: WaterSystemsFieldConfig[]
  ): WaterSystemsFieldConfig[] {
    return rowConfig;
  }
}
