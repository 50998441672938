<section>
  <mat-form-field *ngIf="showFilter === 'true'">
    <mat-label>{{ translationsLib.get('search') }}</mat-label>
    <input matInput (keyup)="applyFilter($event)" #input>
  </mat-form-field>

  <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">

    <ng-container matColumnDef="color">
      <th class="px-1" mat-header-cell *matHeaderCellDef>&nbsp;</th>
      <td class="px-1" mat-cell *matCellDef="let event">
      <span
        class="cal-event"
        [style.backgroundColor]="event.color.primary">
      </span>
      </td>
    </ng-container>

    <ng-container matColumnDef="title">
      <th class="px-2" mat-header-cell *matHeaderCellDef> {{ dataHeaderLabel1 }} </th>
      <td class="px-2" mat-cell *matCellDef="let event"> {{event.title}} </td>
    </ng-container>

    <ng-container matColumnDef="start" *ngIf="showData2 === 'true'">
      <th class="px-2" mat-header-cell *matHeaderCellDef> {{ dataHeaderLabel2 }} </th>
      <td class="px-2" mat-cell *matCellDef="let event"> {{ moment(event.start).format(this.dateTimeFormat) }} </td>
    </ng-container>

    <ng-container matColumnDef="end" *ngIf="showData3 === 'true'">
      <th class="px-2" mat-header-cell *matHeaderCellDef> {{ dataHeaderLabel3 }} </th>
      <td class="px-2" mat-cell *matCellDef="let event"> {{ moment(event.end).format(this.dateTimeFormat) }} </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

    <!-- Row shown when there is no matching data. -->
    <tr class="mat-row" *matNoDataRow>
      <td class="mat-cell" colspan="4">
        {{ translationsLib.get('calendar_no_events') }}
      </td>
    </tr>
  </table>

  <mat-paginator [pageSizeOptions]="this.paginationItemsNumber"></mat-paginator>
</section>


