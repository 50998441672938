<div class="mb-4 d-flex justify-content-center mt-5" *ngIf="isFetchingData">
  <div class="spinner-border text-warning" role="status">
    <span class="visually-hidden">{{
      this.translationsLib.get('irrigation_loading')
    }}</span>
  </div>
</div>

<app-dirty-message
  [conditions]="layoutConfiguration && !isFetchingData"
  [dirty]="filtersForm.value.dirty"
></app-dirty-message>

<ng-template
  [ngIf]="
    mode === 'simple' &&
    layoutConfiguration &&
    !isFetchingData &&
    !isFiltersWithoutData
  "
>
  <form [formGroup]="filtersForm" class="simple-form">
    <div class="mb-2">
      <div class="card">
        <div class="card-body custom-card-body">
          <div class="row">
            <div class="col-sm-5 col-md-5">
              <h6 class="fs-2">
                {{ translationsLib.get('irrigation_filters') }}
              </h6>
              <p class="fs-5 fw-lighter">
                {{
                  translationsLib.get('irrigation_filters_help') | sanitizeText
                }}
              </p>
            </div>
            <div class="col-sm-7 col-md-7 col-inputs">
              <div class="row">
                <div class="col-6 col-md-6">
                  <div class="row">
                    <div class="col-sm-6">
                      <h6 class="fs-5">
                        {{ translationsLib.get('irrigation_filters_active') }}
                      </h6>
                    </div>
                    <div class="col-sm-6">
                      <div class="input-group my-2">
                        <div class="form-check form-switch">
                          <input
                            formControlName="active"
                            class="form-check-input"
                            type="checkbox"
                            role="switch"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-6 col-md-6">
                  <h6 class="fs-5">
                    {{
                      translationsLib.get('irrigation_pressure_switch_delay')
                    }}
                  </h6>
                  <div class="mb-2">
                    <ng-template #pressureSwitchDelayPicker>
                      <timepicker
                        appOnlyNumbers
                        [showMeridian]="false"
                        [showSeconds]="true"
                        [minuteStep]="1"
                        [secondsStep]="1"
                        [formControlName]="'pressureSwitchDelay'"
                      ></timepicker>
                    </ng-template>
                    <input
                      appOnlyNumbers
                      [ngClass]="{
                        'ng-dirty':
                          filtersForm.controls.pressureSwitchDelay.dirty
                      }"
                      class="form-control text-center"
                      type="text"
                      [popover]="pressureSwitchDelayPicker"
                      [outsideClick]="true"
                      placement="bottom"
                      [value]="
                        filtersForm.value.pressureSwitchDelay | inputTime
                      "
                      (change)="
                        onChangeInputTimeWithKeyboard(
                          $event,
                          'pressureSwitchDelay',
                          true
                        )
                      "
                    />
                  </div>
                </div>
                <div class="col-sm-6 col-md-6">
                  <h6 class="fs-5">
                    {{ translationsLib.get('irrigation_repose_time') }}
                  </h6>
                  <div class="mb-2">
                    <ng-template #reposeTimePicker>
                      <timepicker
                        appOnlyNumbers
                        [showMeridian]="false"
                        [showSeconds]="true"
                        [minuteStep]="1"
                        [secondsStep]="1"
                        [formControlName]="'reposeTime'"
                      ></timepicker>
                    </ng-template>
                    <input
                      appOnlyNumbers
                      [ngClass]="{
                        'ng-dirty': filtersForm.controls.reposeTime.dirty
                      }"
                      class="form-control text-center"
                      type="text"
                      [popover]="reposeTimePicker"
                      [outsideClick]="true"
                      placement="bottom"
                      [value]="filtersForm.value.reposeTime | inputTime"
                      (change)="
                        onChangeInputTimeWithKeyboard(
                          $event,
                          'reposeTime',
                          true
                        )
                      "
                    />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-6 col-md-6">
                  <div class="row">
                    <div class="col-sm-6">
                      <h6 class="fs-5">
                        {{ translationsLib.get('irrigation_rotation') }}
                      </h6>
                    </div>
                    <div class="col-sm-6">
                      <div class="input-group my-2">
                        <div class="form-check form-switch">
                          <input
                            formControlName="rotation"
                            class="form-check-input"
                            type="checkbox"
                            role="switch"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-6 col-md-6">
                  <h6 class="fs-5">
                    {{ translationsLib.get('irrigation_program_stop') }}
                  </h6>
                  <div class="input-group mb-2">
                    <select class="form-select" formControlName="programsStop">
                      <option
                        [value]="programsStop.value"
                        *ngFor="let programsStop of programsStopList"
                      >
                        {{ programsStop.name }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="col-sm-6 col-md-6">
                  <h6 class="fs-5">
                    {{ translationsLib.get('irrigation_sustaining_time') }}
                  </h6>
                  <div class="mb-2">
                    <ng-template #sustainingTimePicker>
                      <timepicker
                        appOnlyNumbers
                        [showMeridian]="false"
                        [showSeconds]="true"
                        [minuteStep]="1"
                        [secondsStep]="1"
                        [formControlName]="'sustainingTime'"
                      ></timepicker>
                    </ng-template>
                    <input
                      appOnlyNumbers
                      [ngClass]="{
                        'ng-dirty': filtersForm.controls.sustainingTime.dirty
                      }"
                      class="form-control text-center"
                      type="text"
                      [popover]="sustainingTimePicker"
                      [outsideClick]="true"
                      placement="bottom"
                      [value]="filtersForm.value.sustainingTime | inputTime"
                      (change)="
                        onChangeInputTimeWithKeyboard(
                          $event,
                          'sustainingTime',
                          true
                        )
                      "
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card">
        <div class="card-body custom-card-body">
          <div class="row">
            <div class="col-sm-5 col-md-5">
              <h6 class="fs-2">
                {{ this.translationsLib.get('irrigation_pumps') }}
              </h6>
              <p class="fs-5 fw-lighter">
                {{
                  translationsLib.get('irrigation_pumps_filter_help')
                    | sanitizeText
                }}
              </p>
            </div>
            <!-- PUMP 1 -->
            <div class="col-sm-7 col-md-7 col-inputs">
              <div class="row first-row">
                <!-- PUMP 1 -->
                <div class="col-sm-4 col-md-4">
                  <h6 class="fs-5">
                    {{ translationsLib.get('irrigation_pump') + ' ' + 1 }}
                  </h6>
                  <div class="input-group mb-2">
                    <select class="form-select" formControlName="pump1">
                      <option [value]="255">-</option>
                      <option
                        [value]="pump.pump"
                        *ngFor="let pump of pumpsList"
                      >
                        {{ translationsLib.get('irrigation_pump') }}
                        {{ pump.pump + 1 }}
                      </option>
                    </select>
                  </div>
                </div>
                <!-- PUMP 2 -->
                <div class="col-sm-4 col-md-4">
                  <h6 class="fs-5">
                    {{ translationsLib.get('irrigation_pump') + ' ' + 2 }}
                  </h6>
                  <div class="input-group mb-2">
                    <select class="form-select" formControlName="pump2">
                      <option [value]="255">-</option>
                      <option
                        [value]="pump.pump"
                        *ngFor="let pump of pumpsList"
                      >
                        {{ translationsLib.get('irrigation_pump') }}
                        {{ pump.pump + 1 }}
                      </option>
                    </select>
                  </div>
                </div>
                <!-- PUMP 3 -->
                <div class="col-sm-4 col-md-4">
                  <h6 class="fs-5">
                    {{ translationsLib.get('irrigation_pump') + ' ' + 3 }}
                  </h6>
                  <div class="input-group mb-2">
                    <select class="form-select" formControlName="pump3">
                      <option [value]="255">-</option>
                      <option
                        [value]="pump.pump"
                        *ngFor="let pump of pumpsList"
                      >
                        {{ translationsLib.get('irrigation_pump') }}
                        {{ pump.pump + 1 }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card">
        <div class="card-body custom-card-body">
          <div class="row">
            <div class="col-sm-5 col-md-5">
              <h6 class="fs-2">
                {{ translationsLib.get('irrigation_filters_more_parameters') }}
              </h6>
              <p class="fs-5 fw-lighter">
                {{
                  translationsLib.get('irrigation_filters_more_parameters_help')
                    | sanitizeText
                }}
              </p>
            </div>
            <div class="col-sm-7 col-md-7 col-inputs">
              <div class="row first-row">
                <div class="col-sm-6 col-md-6">
                  <h6 class="fs-5">
                    {{
                      translationsLib.get('irrigation_filters_cleaning_time')
                    }}
                  </h6>
                  <div class="mb-2">
                    <ng-template #timeBetweenCleaningsPicker>
                      <timepicker
                        appOnlyNumbers
                        [showMeridian]="false"
                        [showSeconds]="true"
                        [minuteStep]="1"
                        [secondsStep]="1"
                        [formControlName]="'timeBetweenCleanings'"
                      ></timepicker>
                    </ng-template>
                    <input
                      appOnlyNumbers
                      [ngClass]="{
                        'ng-dirty':
                          filtersForm.controls.timeBetweenCleanings.dirty
                      }"
                      class="form-control text-center"
                      type="text"
                      [popover]="timeBetweenCleaningsPicker"
                      [outsideClick]="true"
                      placement="bottom"
                      [value]="
                        filtersForm.value.timeBetweenCleanings | inputTime
                      "
                      (change)="
                        onChangeInputTimeWithKeyboard(
                          $event,
                          'timeBetweenCleanings',
                          true
                        )
                      "
                    />
                  </div>
                </div>
                <div class="col-sm-6 col-md-6">
                  <h6 class="fs-5">
                    {{
                      translationsLib.get(
                        'irrigation_filters_volume_between_cleanings'
                      )
                    }}
                  </h6>
                  <div class="input-group mb-2 number-input">
                    <button
                      class="minus minus-style"
                      (click)="inputNumberService.decrementValue($event)"
                    ></button>
                    <input
                      formControlName="volumeBetweenCleanings"
                      type="number"
                      class="form-control"
                      placeholder="{{
                        translationsLib.get(
                          'irrigation_filters_volume_between_cleanings'
                        )
                      }}"
                      min="-9999"
                      max="9999"
                    />
                    <button
                      class="plus border-plus plus-style"
                      (click)="inputNumberService.incrementValue($event)"
                    ></button>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-6 col-md-6">
                  <h6 class="fs-5">
                    {{
                      translationsLib.get(
                        'irrigation_filters_frequent_cleanings'
                      )
                    }}
                  </h6>
                  <div class="input-group mb-2 number-input">
                    <button
                      class="minus minus-style"
                      (click)="inputNumberService.decrementValue($event)"
                    ></button>
                    <input
                      formControlName="frequentCleanings"
                      type="number"
                      class="form-control"
                      placeholder="{{
                        translationsLib.get(
                          'irrigation_filters_frequent_cleanings'
                        )
                      }}"
                      min="-9999"
                      max="9999"
                    />
                    <button
                      class="plus border-plus plus-style"
                      (click)="inputNumberService.incrementValue($event)"
                    ></button>
                  </div>
                </div>
                <div class="col-sm-6 col-md-6">
                  <h6 class="fs-5">
                    {{
                      translationsLib.get(
                        'irrigation_filters_time_frequent_cleanings'
                      )
                    }}
                  </h6>
                  <div class="mb-2">
                    <ng-template #timeFrequentCleaningsPicker>
                      <timepicker
                        appOnlyNumbers
                        [showMeridian]="false"
                        [showSeconds]="true"
                        [minuteStep]="1"
                        [secondsStep]="1"
                        [formControlName]="'timeFrequentCleanings'"
                      ></timepicker>
                    </ng-template>
                    <input
                      appOnlyNumbers
                      [ngClass]="{
                        'ng-dirty':
                          filtersForm.controls.timeFrequentCleanings.dirty
                      }"
                      class="form-control text-center"
                      type="text"
                      [popover]="timeFrequentCleaningsPicker"
                      [outsideClick]="true"
                      placement="bottom"
                      [value]="
                        filtersForm.value.timeFrequentCleanings | inputTime
                      "
                      (change)="
                        onChangeInputTimeWithKeyboard(
                          $event,
                          'timeFrequentCleanings',
                          true
                        )
                      "
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="card"
        *ngIf="layoutConfiguration.blocks.progfiltrosm3k_lavados.show"
      >
        <div class="card-body custom-card-body">
          <div class="row">
            <div class="col-sm-5 col-md-5">
              <h6 class="fs-2">
                {{
                  translationsLib.get('irrigation_filter_wash_time_pressures')
                }}
              </h6>
              <p class="fs-5 fw-lighter">
                {{
                  translationsLib.get('irrigation_filter_wash_time_pressures')
                    | sanitizeText
                }}
              </p>
            </div>
            <div class="col-sm-7 col-md-7 col-inputs">
              <div formArrayName="pressureAndWashingTimes">
                <ng-container
                  *ngFor="
                    let control of pressureAndWashingTimesArr.controls;
                    let i = index
                  "
                >
                  <ng-container [formGroupName]="i">
                    <div class="row align-items-center">
                      <div class="col-12 col-md-2">
                        <h6 class="fs-5">
                          {{ translationsLib.get('irrigation_filter') }}
                          {{ i + 1 }}
                        </h6>
                      </div>
                      <div class="col-12 col-md-5">
                        <h6 class="fs-5">
                          {{ translationsLib.get('irrigation_pressure') }}
                        </h6>
                        <div class="input-group number-input">
                          <button
                            class="minus minus-style"
                            (click)="inputNumberService.decrementValue($event)"
                          ></button>
                          <input
                            formControlName="pressure"
                            type="number"
                            class="form-control"
                            placeholder="{{
                              translationsLib.get('irrigation_pressure')
                            }}"
                            min="-9999"
                            max="9999"
                          />
                          <button
                            class="plus border-plus plus-style"
                            (click)="inputNumberService.incrementValue($event)"
                          ></button>
                        </div>
                      </div>
                      <div class="col-12 col-md-5">
                        <h6 class="fs-5">
                          {{ translationsLib.get('irrigation_filter_time') }}
                        </h6>
                        <div>
                          <ng-template #pressureSimpleTimePicker>
                            <timepicker
                              appOnlyNumbers
                              [showMeridian]="false"
                              [showSeconds]="true"
                              [minuteStep]="1"
                              [secondsStep]="1"
                              [formControlName]="'myTime'"
                            ></timepicker>
                          </ng-template>
                          <input
                            appOnlyNumbers
                            [ngClass]="{
                              'ng-dirty': control.get('myTime').dirty
                            }"
                            class="form-control text-center"
                            type="text"
                            [popover]="pressureSimpleTimePicker"
                            [outsideClick]="true"
                            placement="bottom"
                            [value]="control.value.myTime | inputTime"
                            (change)="
                              onChangeInputTimeWithKeyboard($event, control)
                            "
                          />
                        </div>
                      </div>
                    </div>
                  </ng-container>
                </ng-container>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</ng-template>

<ng-template
  [ngIf]="
    mode === 'advanced' &&
    layoutConfiguration &&
    !isFetchingData &&
    !isFiltersWithoutData
  "
>
  <form [formGroup]="filtersForm" class="advanced-form">
    <div class="row">
      <div class="col-lg-6 col-md-6">
        <div class="card">
          <div class="card-body custom-card-body">
            <div class="row">
              <div class="col-md-12">
                <h6 class="fs-2">
                  {{ translationsLib.get('irrigation_filters') }}
                </h6>
              </div>
            </div>
            <div class="row">
              <div class="col-12 col-md-12">
                <div class="row">
                  <div class="col-sm-6">
                    <h6 class="fs-5">
                      {{ translationsLib.get('irrigation_filters_active') }}
                    </h6>
                  </div>
                  <div class="col-sm-6">
                    <div class="input-group my-2">
                      <div class="form-check form-switch">
                        <input
                          formControlName="active"
                          class="form-check-input"
                          type="checkbox"
                          role="switch"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <div class="row">
                  <div class="col-sm-6 col-md-6">
                    <h6 class="fs-5">
                      {{
                        translationsLib.get('irrigation_pressure_switch_delay')
                      }}
                    </h6>
                  </div>
                  <div class="col-sm-6 col-md-6">
                    <div class="mb-2">
                      <ng-template #pressureSwitchDelayPicker>
                        <timepicker
                          appOnlyNumbers
                          [showMeridian]="false"
                          [showSeconds]="true"
                          [minuteStep]="1"
                          [secondsStep]="1"
                          [formControlName]="'pressureSwitchDelay'"
                        ></timepicker>
                      </ng-template>
                      <input
                        appOnlyNumbers
                        [ngClass]="{
                          'ng-dirty':
                            filtersForm.controls.pressureSwitchDelay.dirty
                        }"
                        class="form-control text-center"
                        type="text"
                        [popover]="pressureSwitchDelayPicker"
                        [outsideClick]="true"
                        placement="bottom"
                        [value]="
                          filtersForm.value.pressureSwitchDelay | inputTime
                        "
                        (change)="
                          onChangeInputTimeWithKeyboard(
                            $event,
                            'pressureSwitchDelay',
                            true
                          )
                        "
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12">
                <div class="row">
                  <div class="col-sm-6 col-md-6">
                    <h6 class="fs-5">
                      {{ translationsLib.get('irrigation_repose_time') }}
                    </h6>
                  </div>
                  <div class="col-sm-6 col-md-6">
                    <div class="mb-2">
                      <ng-template #reposeTimePicker>
                        <timepicker
                          appOnlyNumbers
                          [showMeridian]="false"
                          [showSeconds]="true"
                          [minuteStep]="1"
                          [secondsStep]="1"
                          [formControlName]="'reposeTime'"
                        ></timepicker>
                      </ng-template>
                      <input
                        appOnlyNumbers
                        [ngClass]="{
                          'ng-dirty': filtersForm.controls.reposeTime.dirty
                        }"
                        class="form-control text-center"
                        type="text"
                        [popover]="reposeTimePicker"
                        [outsideClick]="true"
                        placement="bottom"
                        [value]="filtersForm.value.reposeTime | inputTime"
                        (change)="
                          onChangeInputTimeWithKeyboard(
                            $event,
                            'reposeTime',
                            true
                          )
                        "
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12 col-md-12">
                <div class="row">
                  <div class="col-sm-6">
                    <h6 class="fs-5">
                      {{ translationsLib.get('irrigation_rotation') }}
                    </h6>
                  </div>
                  <div class="col-sm-6">
                    <div class="input-group my-2">
                      <div class="form-check form-switch">
                        <input
                          formControlName="rotation"
                          class="form-check-input"
                          type="checkbox"
                          role="switch"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <div class="row">
                  <div class="col-sm-6 col-md-6">
                    <h6 class="fs-5">
                      {{ translationsLib.get('irrigation_program_stop') }}
                    </h6>
                  </div>
                  <div class="col-sm-6 col-md-6">
                    <div class="input-group mb-2">
                      <select
                        class="form-select"
                        formControlName="programsStop"
                      >
                        <option
                          [value]="programsStop.value"
                          *ngFor="let programsStop of programsStopList"
                        >
                          {{ programsStop.name }}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <div class="row">
                  <div class="col-sm-6 col-md-6">
                    <h6 class="fs-5">
                      {{ translationsLib.get('irrigation_sustaining_time') }}
                    </h6>
                  </div>
                  <div class="col-sm-6 col-md-6">
                    <div class="mb-2">
                      <ng-template #sustainingTimePicker>
                        <timepicker
                          appOnlyNumbers
                          [showMeridian]="false"
                          [showSeconds]="true"
                          [minuteStep]="1"
                          [secondsStep]="1"
                          [formControlName]="'sustainingTime'"
                        ></timepicker>
                      </ng-template>
                      <input
                        appOnlyNumbers
                        [ngClass]="{
                          'ng-dirty': filtersForm.controls.sustainingTime.dirty
                        }"
                        class="form-control text-center"
                        type="text"
                        [popover]="sustainingTimePicker"
                        [outsideClick]="true"
                        placement="bottom"
                        [value]="filtersForm.value.sustainingTime | inputTime"
                        (change)="
                          onChangeInputTimeWithKeyboard(
                            $event,
                            'sustainingTime',
                            true
                          )
                        "
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="card-body custom-card-body">
            <div class="row">
              <div class="col-md-12">
                <h6 class="fs-2">
                  {{ this.translationsLib.get('irrigation_pumps') }}
                </h6>
              </div>
            </div>
            <!-- PUMP 1 -->
            <div class="row">
              <div class="col">
                <div class="row">
                  <div class="col">
                    <h6 class="fs-5">
                      {{ translationsLib.get('irrigation_pump') + ' ' + 1 }}
                    </h6>
                  </div>
                  <div class="col">
                    <div class="input-group mb-2">
                      <select class="form-select" formControlName="pump1">
                        <option [value]="255">-</option>
                        <option
                          [value]="pump.pump"
                          *ngFor="let pump of pumpsList"
                        >
                          {{ translationsLib.get('irrigation_pump') }}
                          {{ pump.pump + 1 }}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- PUMP 2 -->
            <div class="row">
              <div class="col">
                <div class="row">
                  <div class="col">
                    <h6 class="fs-5">
                      {{ translationsLib.get('irrigation_pump') + ' ' + 2 }}
                    </h6>
                  </div>
                  <div class="col">
                    <div class="input-group mb-2">
                      <select class="form-select" formControlName="pump2">
                        <option [value]="255">-</option>
                        <option
                          [value]="pump.pump"
                          *ngFor="let pump of pumpsList"
                        >
                          {{ translationsLib.get('irrigation_pump') }}
                          {{ pump.pump + 1 }}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- PUMP 3 -->
            <div class="row">
              <div class="col">
                <div class="row">
                  <div class="col">
                    <h6 class="fs-5">
                      {{ translationsLib.get('irrigation_pump') + ' ' + 3 }}
                    </h6>
                  </div>
                  <div class="col">
                    <div class="input-group mb-2">
                      <select class="form-select" formControlName="pump3">
                        <option [value]="255">-</option>
                        <option
                          [value]="pump.pump"
                          *ngFor="let pump of pumpsList"
                        >
                          {{ translationsLib.get('irrigation_pump') }}
                          {{ pump.pump + 1 }}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="card-body custom-card-body">
            <div class="row">
              <div class="col-md-12">
                <h6 class="fs-2">
                  {{
                    translationsLib.get('irrigation_filters_more_parameters')
                  }}
                </h6>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <div class="row">
                  <div class="col-sm-6 col-md-6">
                    <h6 class="fs-5">
                      {{
                        translationsLib.get('irrigation_filters_cleaning_time')
                      }}
                    </h6>
                  </div>
                  <div class="col-sm-6 col-md-6">
                    <div class="mb-2">
                      <ng-template #timeBetweenCleaningsPicker>
                        <timepicker
                          appOnlyNumbers
                          [showMeridian]="false"
                          [showSeconds]="true"
                          [minuteStep]="1"
                          [secondsStep]="1"
                          [formControlName]="'timeBetweenCleanings'"
                        ></timepicker>
                      </ng-template>
                      <input
                        appOnlyNumbers
                        [ngClass]="{
                          'ng-dirty':
                            filtersForm.controls.timeBetweenCleanings.dirty
                        }"
                        class="form-control text-center"
                        type="text"
                        [popover]="timeBetweenCleaningsPicker"
                        [outsideClick]="true"
                        placement="bottom"
                        [value]="
                          filtersForm.value.timeBetweenCleanings | inputTime
                        "
                        (change)="
                          onChangeInputTimeWithKeyboard(
                            $event,
                            'timeBetweenCleanings',
                            true
                          )
                        "
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <div class="row">
                  <div class="col-sm-6 col-md-6">
                    <h6 class="fs-5">
                      {{
                        translationsLib.get(
                          'irrigation_filters_volume_between_cleanings'
                        )
                      }}
                    </h6>
                  </div>
                  <div class="col-sm-6 col-md-6">
                    <div class="input-group mb-2 number-input">
                      <button
                        class="minus minus-style"
                        (click)="inputNumberService.decrementValue($event)"
                      ></button>
                      <input
                        formControlName="volumeBetweenCleanings"
                        type="number"
                        class="form-control"
                        placeholder="{{
                          translationsLib.get(
                            'irrigation_filters_volume_between_cleanings'
                          )
                        }}"
                        min="-9999"
                        max="9999"
                      />
                      <button
                        class="plus border-plus plus-style"
                        (click)="inputNumberService.incrementValue($event)"
                      ></button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <div class="row">
                  <div class="col-sm-6 col-md-6">
                    <h6 class="fs-5">
                      {{
                        translationsLib.get(
                          'irrigation_filters_frequent_cleanings'
                        )
                      }}
                    </h6>
                  </div>
                  <div class="col-sm-6 col-md-6">
                    <div class="input-group mb-2 number-input">
                      <button
                        class="minus minus-style"
                        (click)="inputNumberService.decrementValue($event)"
                      ></button>
                      <input
                        formControlName="frequentCleanings"
                        type="number"
                        class="form-control"
                        placeholder="{{
                          translationsLib.get(
                            'irrigation_filters_frequent_cleanings'
                          )
                        }}"
                        min="-9999"
                        max="9999"
                      />
                      <button
                        class="plus border-plus plus-style"
                        (click)="inputNumberService.incrementValue($event)"
                      ></button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <div class="row">
                  <div class="col-sm-6 col-md-6">
                    <h6 class="fs-5">
                      {{
                        translationsLib.get(
                          'irrigation_filters_time_frequent_cleanings'
                        )
                      }}
                    </h6>
                  </div>
                  <div class="col-sm-6 col-md-6">
                    <div class="mb-2">
                      <ng-template #timeFrequentCleaningsPicker>
                        <timepicker
                          appOnlyNumbers
                          [showMeridian]="false"
                          [showSeconds]="true"
                          [minuteStep]="1"
                          [secondsStep]="1"
                          [formControlName]="'timeFrequentCleanings'"
                        ></timepicker>
                      </ng-template>
                      <input
                        appOnlyNumbers
                        [ngClass]="{
                          'ng-dirty':
                            filtersForm.controls.timeFrequentCleanings.dirty
                        }"
                        class="form-control text-center"
                        type="text"
                        [popover]="timeFrequentCleaningsPicker"
                        [outsideClick]="true"
                        placement="bottom"
                        [value]="
                          filtersForm.value.timeFrequentCleanings | inputTime
                        "
                        (change)="
                          onChangeInputTimeWithKeyboard(
                            $event,
                            'timeFrequentCleanings',
                            true
                          )
                        "
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-6 col-md-6">
        <div
          class="card"
          *ngIf="layoutConfiguration.blocks.progfiltrosm3k_lavados.show"
        >
          <div class="card-body custom-card-body">
            <div class="row">
              <div class="col-md-12">
                <h6 class="fs-2">
                  {{
                    translationsLib.get('irrigation_filter_wash_time_pressures')
                  }}
                </h6>
              </div>
            </div>
            <div class="row align-items-center">
              <div class="col">
                <div formArrayName="pressureAndWashingTimes">
                  <ng-container
                    *ngFor="
                      let control of pressureAndWashingTimesArr.controls;
                      let i = index
                    "
                  >
                    <ng-container [formGroupName]="i">
                      <div class="row align-items-center">
                        <div class="col-12 col-md-2">
                          <h6 class="fs-5">
                            {{ translationsLib.get('irrigation_filter') }}
                            {{ i + 1 }}
                          </h6>
                        </div>
                        <div class="col-12 col-md-5">
                          <h6 class="fs-5">
                            {{ translationsLib.get('irrigation_pressure') }}
                          </h6>
                          <div class="input-group number-input">
                            <button
                              class="minus minus-style"
                              (click)="inputNumberService.decrementValue($event)"
                            ></button>
                            <input
                              formControlName="pressure"
                              type="number"
                              class="form-control"
                              placeholder="{{
                                translationsLib.get('irrigation_pressure')
                              }}"
                              min="-9999"
                              max="9999"
                            />
                            <button
                              class="plus border-plus plus-style"
                              (click)="inputNumberService.incrementValue($event)"
                            ></button>
                          </div>
                        </div>
                        <div class="col-12 col-md-5">
                          <h6 class="fs-5">
                            {{ translationsLib.get('irrigation_filter_time') }}
                          </h6>
                          <div>
                            <ng-template #pressureAdvanceTimePicker>
                              <timepicker
                                appOnlyNumbers
                                [showMeridian]="false"
                                [showSeconds]="true"
                                [minuteStep]="1"
                                [secondsStep]="1"
                                [formControlName]="'myTime'"
                              ></timepicker>
                            </ng-template>
                            <input
                              appOnlyNumbers
                              [ngClass]="{
                                'ng-dirty': control.get('myTime').dirty
                              }"
                              class="form-control text-center"
                              type="text"
                              [popover]="pressureAdvanceTimePicker"
                              [outsideClick]="true"
                              placement="bottom"
                              [value]="control.value.myTime | inputTime"
                              (change)="
                                onChangeInputTimeWithKeyboard($event, control)
                              "
                            />
                          </div>
                        </div>
                      </div>
                    </ng-container>
                  </ng-container>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</ng-template>
