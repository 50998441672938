<h1 mat-dialog-title>{{ translationsLib.get('pending_changes_title') }}</h1>
<mat-dialog-content>
  <p>{{ message }}</p>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>
    {{ translationsLib.get('cancel') }}
  </button>
  <button mat-button [mat-dialog-close]="true" cdkFocusInitial>
    {{ translationsLib.get('accept') }}
  </button>
</mat-dialog-actions>
