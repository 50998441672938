<div class="form-group">
  <label class="form-label">{{ translationsLib.get('field_crops') }}</label>
  <ul class="list-group">
    <li *ngIf="multicrops.length === 0" class="list-group-item py-2 text-center">
      {{ translationsLib.get('field_add_at_least_one_crop') }}
    </li>
    <li *ngFor="let multicrop of multicrops; index as m" class="list-group-item py-2">
      {{crops && getCropNameById(multicrop.crop_id)}}
      <span class="float-end">
        <button class="mx-2 text-primary bg-white" (click)="editMulticrop(m)">
          <ion-icon name="create-outline"></ion-icon>
        </button>
        <button class="mx-2 text-danger bg-white" (click)="removeMulticrop(m)">
          <ion-icon name="trash-outline"></ion-icon>
        </button>
      </span>
    </li>
  </ul>
</div>

<div class="form-group text-end">
  <button class="btn btn-sm btn-terciary " id="crop" (click)="newMulticrop()">
    {{ translationsLib.get('field_new_crop') }}
  </button>
</div>

<ion-modal [breakpoints]="[1]" #modal id="modal" class="auto-height">
  <ng-template>
    <div class="inner-content pb-0">
      <ion-toolbar>
        <ion-title *ngIf="selectedMulticrop">{{ translationsLib.get('field_edit_crop')}}</ion-title>
        <ion-title *ngIf="!selectedMulticrop">{{ translationsLib.get('field_new_crop') }}</ion-title>
        <ion-buttons slot="end">
          <ion-button color="light" (click)="modal.dismiss()">
            <ion-icon name="close-outline"></ion-icon>
          </ion-button>
        </ion-buttons>
      </ion-toolbar>
      <div class="container p-4">
        <app-multicrop-children
          [crops]="crops"
          [edit]="selectedMulticrop"
          (save)="multicropEvent($event)"
        ></app-multicrop-children>
      </div>
    </div>
  </ng-template>
</ion-modal>
