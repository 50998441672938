<div class="row" *ngIf="recommendedReports.length > 0">
  <div class="col-12">
    <div class="card">
      <div class="card-header">
        <div class="card-header-title">
          <h2 style="color: #224261">{{translationsLib.get('reports_suggested')}}</h2>
        </div>
      </div>
      <div class="card-body">
        <section class="">
          <div class="col-12 mb-4 mb-lg-2" *ngFor="let report of recommendedReports">
            <div class="row">
              <div class="col-10 align-content-center">
                <div class="row">
                  <div class="col-auto px-0 px-lg-2">
                    <div class="avatar avatar-sm">
                      <div class="avatar-title fs-lg rounded-circle">
                        <ion-icon name="reader-outline"></ion-icon>
                      </div>
                    </div>
                  </div>
                  <div class="col-9 col-lg-auto align-content-center">
                    <p class="mb-0">
                      <strong>
                        {{translationsLib.get('reports_of')}}
                        <span class="text-lowercase">{{report.reportType.name}}</span>
                      </strong> {{translationsLib.get('of')}}
                      <strong>{{translationsLib.get('graphics_'+report.date)}}</strong>
                      {{translationsLib.get('repors_for_device')}}
                      <strong>{{report.terminal.name_vid}}</strong>
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-2 text-end">
                <button class="btn btn-outline-secondary mx-md-2 mb-2 mb-md-0" (click)="callParentPutReportData(report)"><ion-icon name="open-outline"></ion-icon></button>
                <!--
                <button class="btn btn-outline-secondary" (click)="exportToPDF(report);"><ion-icon name="download-outline"></ion-icon></button>
                -->
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</div>
<app-reports-download-post-open-window [requestData]="this.requestData" *ngIf="this.downloadPDF === true"></app-reports-download-post-open-window>
