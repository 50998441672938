<form [formGroup]="fieldForm" (submit)="onSubmit()">

  <div class="row">
    <mat-form-field id="cropSelected" class="col-12" appearance="outline" >
      <mat-label>{{ translationsLib.get('field_crop') }}</mat-label>
      <mat-select formControlName="crop_id">
        <mat-option *ngFor="let crop of crops" [value]="crop.id">
          {{ crop.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div class="row">
    <mat-form-field class="col-12" appearance="outline">
      <mat-label>{{ translationsLib.get('field_crop_typologies') }}</mat-label>
      <mat-select formControlName="crop_typology">
        <mat-option *ngFor="let typology of cropTypologies" [value]="typology">
          {{ translationsLib.get('field_'+typology) }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div class="row">
    <mat-form-field class="col-12" appearance="outline">
      <mat-label>{{ translationsLib.get('field_farm') }}</mat-label>
      <mat-select formControlName="farm_type">
        <mat-option *ngFor="let farm of farmTypes" [value]="farm">
          {{ translationsLib.get('field_'+farm) }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div class="row">
    <mat-form-field class="col-12" appearance="outline">
      <mat-label>{{ translationsLib.get('field_irrigation_method') }}</mat-label>
      <mat-select formControlName="irrigation_method">
        <mat-option *ngFor="let method of irrigationMethods" [value]="method">
          {{ translationsLib.get('field_'+method) }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div class="row">
    <mat-form-field class="col-12" appearance="outline">
      <mat-label>{{ translationsLib.get('field_irrigation_source') }}</mat-label>
      <mat-select formControlName="irrigation_source">
        <mat-option *ngFor="let source of irrigationSources" [value]="source">
          {{ translationsLib.get('field_'+source) }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div class="row">
    <mat-form-field class="col-6" appearance="outline">
      <mat-label>{{ translationsLib.get('field_distance_between_rows') }}</mat-label>
      <input matInput type="number" formControlName="distance_between_rows"  placeholder="0"/>
    </mat-form-field>
    <mat-form-field class="col-6" appearance="outline">
      <mat-label>{{ translationsLib.get('field_distance_between_plants_in_row') }}</mat-label>
      <input matInput type="number" formControlName="distance_between_plants_in_row" placeholder="0" />
    </mat-form-field>
  </div>

  <div class="row">
    <mat-form-field class="col-6" appearance="outline">
      <mat-label>{{ translationsLib.get('field_drippers_per_plant') }}</mat-label>
      <input matInput type="number" formControlName="drippers_per_plant" placeholder="0">
    </mat-form-field>
    <mat-form-field class="col-6" appearance="outline">
      <mat-label>{{ translationsLib.get('field_emitter_type') }}</mat-label>
      <input matInput type="number" formControlName="emitter_type"  placeholder="0">
    </mat-form-field>
  </div>

  <div class="row">
    <div class="col-12 text-end">
      <button type="submit" class="btn btn-primary" id="saveCrop">{{ translationsLib.get('save') }}</button>
    </div>
  </div>

</form>
