import * as Highcharts from 'highcharts/highstock';

export const IrrigationConsultingChartModel: any = {
  chart: {
    type: 'arearange',
    zoomType: 'x',
    resetZoomButton: {
      position: {
        // align: 'right', // by default
        // verticalAlign: 'top', // by default
        x: -30,
        y: 0,
      },
    },
    /*events: {
      // Detecta cuando se cambia el rango del zoom
      selection: function (event) {
        if (event.xAxis) {
          let min = event.xAxis[0].min;
          let max = event.xAxis[0].max;

          // Actualiza la otra gráfica (dispersión) con los mismos límites de zoom
          const scatterChart = Highcharts.charts[1]; // La segunda gráfica está en 'container2'
          scatterChart.xAxis[0].setExtremes(min, max);
        }
        return true; // Permitir el zoom en la gráfica actual
      },
    },*/
    panning: true,
    panKey: 'shift',
  },
  title: {
    align: 'left',
    text: null,
  },
  subtitle: {
    align: 'left',
    text: '',
  },
  accessibility: {
    announceNewData: {
      enabled: true,
    },
  },
  xAxis: {
    type: 'datetime',
    labels: {
      formatter() {
        // @ts-ignore
        return Highcharts.dateFormat('%d/%m %H:%M', new Date(this.value));
      },
    },
  },
  yAxis:
    [
      {
        title: {
          text: null,
        },
      },
        {
          title: {
            text: null,
          },
          opposite: true,
        },
      ]

    /*plotBands: [
        {
          from: 20,
          to: 80,
          color: 'rgba(0,0,255,0.2)',
        },
        {
          from: 10,
          to: 20,
          color: 'rgba(0,255,0,0.2)',
        },
        {
          from: 0,
          to: 10,
          color: 'rgba(255,0,0,0.2)',
        },
      ],*/
  ,
  plotOptions: {},
  legend: {
    title: {
      text: null,
    },
  },
  credits: {
    enabled: false,
  },
  tooltip: {
    headerFormat: '<span style="font-size:11px">{point.x}</span><br>',
    /*pointFormat: '<b>{point.y:.2f}</b><br/>',*/
    shared: true,
    /* formatter() {
      return Highcharts.numberFormat(this.y, 2);
    },*/
    valueDecimals: 2,
  },
  dataLabels: {
    enabled: true,
  },
  series: [
    {
      name: '',
      unit: '',
      colorByPoint: false,
      data: [],
      id: '',
    },
  ],
  exporting: {
    buttons: {
      contextButton: {
        menuItems: [
          'printChart',
          'separator',
          'downloadPNG',
          'downloadJPEG',
          'downloadPDF',
          'downloadSVG',
          'separator',
          'downloadCSV',
          'downloadXLS',
          //"viewData",
          'openInCloud',
        ],
      },
    },
  },
};
