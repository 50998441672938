<app-main-header [breadcrumbs]="breadcrumbs"></app-main-header>
<ion-content>
  <div class="container-fluid p-1 p-lg-2">
    <div class="mx-lg-4">
      <div class="col-12">
        <mat-tab-group class="col-12" (selectedTabChange)="onTabChange($event)" #tabGroup>
          <mat-tab label="{{ translationsLib.get(tabs.general) }}">
            <ng-container *ngIf="this.tabSelectedId === 'general'">
              <br>
              <app-account></app-account>
            </ng-container>
          </mat-tab>
          <mat-tab label="{{ translationsLib.get(tabs.auth_configuration) }}">
            <ng-container *ngIf="this.tabSelectedId === 'auth_configuration'">
              <br>
              <app-auth-config></app-auth-config>
            </ng-container>
          </mat-tab>
          <mat-tab label="{{ translationsLib.get(tabs.notifications) }}" *ngIf="sessionLib.getUserHasNonDemoTerminals() === 'true'">
            <ng-container *ngIf="this.tabSelectedId === 'notifications'">
              <br>
              <app-notifications></app-notifications>
            </ng-container>
          </mat-tab>
          <mat-tab label="{{ translationsLib.get(tabs.permissions) }}" *ngIf="sessionLib.getUserHasNonDemoTerminals() === 'true'">
            <ng-container *ngIf="this.tabSelectedId === 'permissions'">
              <br>
              <app-account-permissions *ngIf="layout!== undefined" [showDemoField]="layout.general.show_attach_to_demo"></app-account-permissions>
            </ng-container>
          </mat-tab>
          <mat-tab label="{{ translationsLib.get(tabs.side_menu) }}">
            <ng-container *ngIf="this.tabSelectedId === 'side_menu'">
              <br>
              <app-menu-config></app-menu-config>
            </ng-container>
          </mat-tab>
          <mat-tab [label]="translationsLib.get(tabs.myucrop)" *ngIf="sessionLib.getUserHasNonDemoTerminals() === 'true'">
            <ng-container *ngIf="this.tabSelectedId === 'myucrop'">
              <br>
              <app-my-ucrop></app-my-ucrop>
            </ng-container>
          </mat-tab>
          <mat-tab [label]="translationsLib.get(tabs.administrative_management)" *ngIf="sessionLib.getUserHasNonDemoTerminals() === 'true'">
            <ng-container *ngIf="this.tabSelectedId === 'administrative_management'">
              <br>
              <div style="min-height: 120px">
                <lib-administrative-management [platform]="'ucrop'"></lib-administrative-management>
              </div>
            </ng-container>
          </mat-tab>
        </mat-tab-group>
      </div>
    </div>
  </div>

</ion-content>
