<section>
  <!-- OWNER & INSTALLER USERS TABLE -->
  <app-permission-table-main
    [terminalVid]="vid"
    [owner]="owner"
    [installer]="installer"
    [mode]="mode"
    [authedUserCanAdminInstaller]="authedUserCanAdminInstaller"
    [authedUserCanAdminOwner]="authedUserCanAdminOwner"
  >
  </app-permission-table-main>

  <!-- ADMINISTRATIVE MANAGEMENT -->
  <app-permissions-administrative-management *ngIf="mode === 'terminal'"
    [terminalVid]="vid"
    [ownerVid]="owner?.vid"
    [installerVid]="installer?.vid"
  >
  </app-permissions-administrative-management>

  <!-- NON INJECTED REGULAR USERS TABLE -->
  <app-permissions-table-regular-users
    [canAdmin]="canAdmin"
    [addUser]="addUser"
    [mode]="mode"
    [regularUsers]="regularUsers"
    [terminalVid]="vid"
    [contacts]="contacts"
    (getPermissionsList)="handlePermissionsReload()"
  >
  </app-permissions-table-regular-users>

  <!-- INJECTED USERS TABLE -->
  <app-permissions-table-injected-users
    [regularUsers]="regularUsers"
  >
  </app-permissions-table-injected-users>

  <div class="card p-3 px-1 px-lg-4 shadow-none">
    <div class="row mt-lg-2 mt-4 mb-0" >
      <div class="col-12 text-center">
        <ion-icon id="permissions-support-icon" name="help-circle-outline"></ion-icon>
        <span id="permissions-support-text" [innerHTML]="translationsLib.get('permission_support_help')"></span>
      </div>
    </div>
  </div>
</section>
