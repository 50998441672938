
<div class="container-fluid">
  <div class="row m-2">
    <div class="col-12 col-md-4" *ngFor="let equipment of terminals">
      <div class="container my-2">
        <lib-terminal-header [equipment]="equipment" [showButton]="false"></lib-terminal-header>
      </div>
    </div>
  </div>
</div>

