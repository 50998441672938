<div class="irrigation-empty-time-slider mt-3">
  <div class="range-labels row align-items-center">
    <div class="col-6 d-flex align-items-center h-100">
      <button class="btn btn-secondary fs-6"
              *ngIf="hasInitialValues"
              (click)="resetValues()">
        {{ translationsLib.get('reset_values') }}
      </button>
    </div>

    <div class="col-6 d-flex justify-content-end align-items-center h-100">
      <div class="total-time card d-flex flex-column justify-content-center align-items-center m-0 p-1">
        <div class="row fs-6">
          {{ translationsLib.get('total_time') }}
        </div>
        <div class="row">
          {{ formatSeconds(totalTime) }}
        </div>
      </div>
    </div>
  </div>

  <nouislider
    *ngIf="loaded"
    [min]="0"
    [max]="totalTime"
    [ngModel]="emptyRange"
    [disabled]="true"
    [config]="emptySliderConfig">
  </nouislider>
</div>
<div class="irrigation-time-slider">
  <div class="range-labels row">
    <div class="col-4 d-flex justify-content-center align-items-center">
      <div class="pre-irrigation card d-flex flex-column justify-content-center align-items-center m-0 p-1">
        <div class="row fs-6">
          {{ translationsLib.get('irrigation_pre_fertilization_time') }}
        </div>
        <div class="row">
          {{ formatSeconds(preIrrigationSeconds) }}
        </div>
      </div>
    </div>

    <div class="col-4 d-flex justify-content-center align-items-center">
      <div class="irrigation card d-flex flex-column justify-content-center align-items-center m-0 p-1">
        <div class="row fs-6">
          {{ translationsLib.get('irrigation_fertilization_time') }}
        </div>
        <div class="row">
          {{ formatSeconds(fertilizationSeconds) }}
        </div>
      </div>
    </div>

    <div class="col-4 d-flex justify-content-center align-items-center">
      <div class="post-irrigation card d-flex flex-column justify-content-center align-items-center m-0 p-1">
        <div class="row fs-6">
          {{ translationsLib.get('irrigation_post_fertilization_time') }}
        </div>
        <div class="row">
          {{ formatSeconds(postIrrigationSeconds) }}
        </div>
      </div>
    </div>
  </div>

  <nouislider
    *ngIf="loaded"
    [min]="0"
    [max]="totalTime"
    [step]="1"
    [connect]="[true, true, true]"
    [ngModel]="range"
    [config]="sliderConfig"
    (start)="onSliderStart()"
    (slide)="onSliderSlide($event)"
    (end)="onSliderEnd($event)">
  </nouislider>
</div>
