import { Component, Input, ViewEncapsulation } from '@angular/core';
import { WaterSystemFieldChips } from '../../models/water-systems.model';

@Component({
  selector: 'app-field-chips',
  templateUrl: './field-chips.component.html',
  styleUrls: ['./field-chips.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class FieldChipsComponent {
  @Input() field: WaterSystemFieldChips;
  @Input() isMobile = false;

  constructor() {
    //
  }
}
