<div>
  <div class="alert bg-salmon">
    <div class="row align-items-center">
      <div class="col-auto">
        <img src="assets/img/Icons/icono_info.svg" width="48px" height="48px" />
      </div>
      <div class="col">{{translationsLib.get('graphics_saved_metric_view')}}</div>
    </div>
  </div>
  <mat-expansion-panel [(expanded)]="panelOpenState"
                       (opened)="panelOpenState = true"
                       (closed)="panelOpenState = false">
    <mat-expansion-panel-header class="p-0">
      <mat-panel-title>
        <div class="d-flex align-items-center">
          <img src="assets/img/Icons/Icono_UCROP_Grafica_Metrica.svg" style="max-height: 60px">
          <div class="row">
            <a class="mx-2 mb-0 text-nowrap" style="font-weight: bold; color: #EE7869; font-size: 18px">{{translationsLib.get('graphics_saved_metrics')}}</a>
          </div>
        </div>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <div class="row mt-4">
      <div class="col-12 col-lg-6" *ngFor="let metric of metrics">
        <div class="card mb-4">
          <div class="row">
            <div class="col-10">
              <h4 class="title-graph mt-2 mb-1" (click)="openModal(metric.name, metric.vid)"><a  style="cursor: pointer;">{{metric.name}} <ion-icon name="create-outline"></ion-icon></a> </h4>
            </div>
            <div class="col-2 align-self-center text-end">
                <button class="btn btn-sm btn-danger" (click)="deleteMetric(metric.vid)"><ion-icon name="trash-outline"></ion-icon></button>
            </div>
          </div>
          </div>
      </div>
    </div>
  </mat-expansion-panel>
</div>
<ion-modal [breakpoints]="[1]" #modal trigger="open-modal" class="auto-height">
  <ng-template>
    <div class="inner-content">
      <ion-toolbar>
        <ng-container class="row">
          <ion-buttons slot="end" style="line-height: 5px; position: absolute; top:5px;right:5px">
            <ion-button color="light" (click)="modal.dismiss()">
              <ion-icon name="close-outline"></ion-icon>
            </ion-button>
          </ion-buttons>
        </ng-container>
      </ion-toolbar>
      <form>
        <div class="card m-4">
          <div class="form-group">
            <label class="form-label">{{translationsLib.get('graphics_metrics_name')}}</label>
            <input type="text" class="form-control" [ngModelOptions]="{standalone: true}" [(ngModel)] ="name">
          </div>
          <button type="submit" (click)="putMetric()" style="align-self: end; max-width: fit-content" class="btn btn-primary">{{translationsLib.get('save')}}</button>
        </div>
      </form>
    </div>
  </ng-template>
</ion-modal>
