<div class="reports-consumptions-table-container pt-3 pb-3" *ngIf="ready === true">
  <form>
    <div mat-dialog-content class="filter__form">
      <div class="row">
        <!--
        <div class="col-12">
          &nbsp;
        </div>
        -->
        <div class="col-12 form-group mb-0 text-end">
          <a [matMenuTriggerFor]="menu">
            <span>{{ translationsLib.get('reports_data_tools') }}</span>
            <button mat-icon-button>
              <ion-icon name="ellipsis-vertical-outline"></ion-icon>
            </button>
          </a>
          <mat-menu #menu="matMenu">
            <button mat-menu-item>
              <ion-icon name="reader-outline"></ion-icon>
              <button mat-button (click)="exportToXLSX()">
                {{ translationsLib.get('highcharts_download_xls') }}
              </button>
            </button>
            <button mat-menu-item>
              <ion-icon name="reader-outline"></ion-icon>
              <button mat-button (click)="exportToPDF()">
                {{ translationsLib.get('highcharts_download_pdf') }}
              </button>
            </button>
          </mat-menu>
        </div>
      </div>
    </div>
  </form>

  <table mat-table
         [dataSource]="dataSource"
         matSort
         class="mat-elevation-z8 consumption-table"
         matTableExporter #exporter="matTableExporter"
  >
    <ng-container matColumnDef="item_name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="px-2">
        <span *ngIf="reportType === 'programs'">{{ translationsLib.get('reports_programName') }}</span>
        <span *ngIf="reportType === 'groups'">{{ translationsLib.get('reports_groupName') }}</span>
      </th>
      <td mat-cell *matCellDef="let element" class="px-2">
        {{ element.itemName }}
      </td>
      <td class="footer" mat-footer-cell *matFooterCellDef>
        {{ translationsLib.get('reports_totals') }}
      </td>
    </ng-container>
    <ng-container matColumnDef="duration">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="px-2">
        {{ translationsLib.get('reports_duration') }}
      </th>
      <td mat-cell *matCellDef="let element" class="px-2">
        {{ element.duration}}
      </td>
      <td class="footer" mat-footer-cell *matFooterCellDef>
        {{ getTotal('duration') }}
      </td>
    </ng-container>

    <ng-container matColumnDef="prog_consumption_water">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="px-2">
        {{ translationsLib.get('reports_progConsumption_water') }}
      </th>
      <td mat-cell *matCellDef="let element" class="px-2">
        {{ element.progConsumption_water }} m3
      </td>
      <td class="footer" mat-footer-cell *matFooterCellDef="let element">
        {{ getTotal('progConsumption_water') }} m3
      </td>
    </ng-container>

    <ng-container matColumnDef="real_consumption_water">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="px-2">
        {{ translationsLib.get('reports_realConsumption_water') }}
      </th>
      <td mat-cell *matCellDef="let element" class="px-2">
        {{ element.realConsumption_water }} m3
      </td>
      <td class="footer" mat-footer-cell *matFooterCellDef="let element">
        {{ getTotal('realConsumption_water') }} m3
      </td>
    </ng-container>

    <ng-container matColumnDef="fertilizer1">
      <th class="text-center px-2" mat-header-cell *matHeaderCellDef>
        {{ translationsLib.get('irrigation_fertilizer') }} 1 {{ translationsLib.get('reports_fertilizer_prog_real_l') }}
      </th>
      <td mat-cell *matCellDef="let element">
        <app-reports-prog-real
          [is_exporting]="isExporting"
          [prog]="element.progConsumption_fertilizer1"
          [real]="element.realConsumption_fertilizer1"
        ></app-reports-prog-real>
      </td>
      <td class="footer" mat-footer-cell *matFooterCellDef="let element">
        <app-reports-prog-real
          [is_exporting]="isExporting"
          [prog]="getTotal('progConsumption_fertilizer1')"
          [real]="getTotal('realConsumption_fertilizer1')"
          [extra_class]="'bolder'"
        ></app-reports-prog-real>
      </td>
    </ng-container>
    <ng-container matColumnDef="fertilizer2">
      <th class="text-center px-2" mat-header-cell *matHeaderCellDef> {{ translationsLib.get('irrigation_fertilizer') }} 2 {{ translationsLib.get('reports_fertilizer_prog_real_l') }}</th>
      <td mat-cell *matCellDef="let element">
        <app-reports-prog-real
          [is_exporting]="isExporting"
          [prog]="element.progConsumption_fertilizer2"
          [real]="element.realConsumption_fertilizer2"
        ></app-reports-prog-real>
      </td>
      <td class="footer" mat-footer-cell *matFooterCellDef="let element">
        <app-reports-prog-real
          [is_exporting]="isExporting"
          [prog]="getTotal('progConsumption_fertilizer2')"
          [real]="getTotal('realConsumption_fertilizer2')"
          [extra_class]="'bolder'"
        ></app-reports-prog-real>
      </td>
    </ng-container>
    <ng-container matColumnDef="fertilizer3">
      <th class="text-center px-2" mat-header-cell *matHeaderCellDef> {{ translationsLib.get('irrigation_fertilizer') }} 3 {{ translationsLib.get('reports_fertilizer_prog_real_l') }}</th>
      <td mat-cell *matCellDef="let element">
        <app-reports-prog-real
          [is_exporting]="isExporting"
          [prog]="element.progConsumption_fertilizer3"
          [real]="element.realConsumption_fertilizer3"
        ></app-reports-prog-real>
      </td>
      <td class="footer" mat-footer-cell *matFooterCellDef="let element">
        <app-reports-prog-real
          [is_exporting]="isExporting"
          [prog]="getTotal('progConsumption_fertilizer3')"
          [real]="getTotal('realConsumption_fertilizer3')"
          [extra_class]="'bolder'"
        ></app-reports-prog-real>
      </td>
    </ng-container>
    <ng-container matColumnDef="fertilizer4">
      <th class="text-center px-2" mat-header-cell *matHeaderCellDef> {{ translationsLib.get('irrigation_fertilizer') }} 4 {{ translationsLib.get('reports_fertilizer_prog_real_l') }}</th>
      <td mat-cell *matCellDef="let element">
        <app-reports-prog-real
          [is_exporting]="isExporting"
          [prog]="element.progConsumption_fertilizer4"
          [real]="element.realConsumption_fertilizer4"
        ></app-reports-prog-real>
      </td>
      <td class="footer" mat-footer-cell *matFooterCellDef="let element">
        <app-reports-prog-real
          [is_exporting]="isExporting"
          [prog]="getTotal('progConsumption_fertilizer4')"
          [real]="getTotal('realConsumption_fertilizer4')"
          [extra_class]="'bolder'"
        ></app-reports-prog-real>
      </td>
    </ng-container>
    <ng-container matColumnDef="fertilizer5">
      <th class="text-center px-2" mat-header-cell *matHeaderCellDef> {{ translationsLib.get('irrigation_fertilizer') }} 5 {{ translationsLib.get('reports_fertilizer_prog_real_l') }}</th>
      <td mat-cell *matCellDef="let element">
        <app-reports-prog-real
          [is_exporting]="isExporting"
          [prog]="element.progConsumption_fertilizer5"
          [real]="element.realConsumption_fertilizer5"
        ></app-reports-prog-real>
      </td>
      <td class="footer" mat-footer-cell *matFooterCellDef="let element">
        <app-reports-prog-real
          [is_exporting]="isExporting"
          [prog]="getTotal('progConsumption_fertilizer5')"
          [real]="getTotal('realConsumption_fertilizer5')"
          [extra_class]="'bolder'"
        ></app-reports-prog-real>
      </td>
    </ng-container>
    <ng-container matColumnDef="fertilizer6">
      <th class="text-center px-2" mat-header-cell *matHeaderCellDef> {{ translationsLib.get('irrigation_fertilizer') }} 6 {{ translationsLib.get('reports_fertilizer_prog_real_l') }}</th>
      <td mat-cell *matCellDef="let element">
        <app-reports-prog-real
          [is_exporting]="isExporting"
          [prog]="element.progConsumption_fertilizer6"
          [real]="element.realConsumption_fertilizer6"
        ></app-reports-prog-real>
      </td>
      <td class="footer" mat-footer-cell *matFooterCellDef="let element">
        <app-reports-prog-real
          [is_exporting]="isExporting"
          [prog]="getTotal('progConsumption_fertilizer6')"
          [real]="getTotal('realConsumption_fertilizer6')"
          [extra_class]="'bolder'"
        ></app-reports-prog-real>
      </td>
    </ng-container>
    <ng-container matColumnDef="fertilizer7">
      <th class="text-center px-2" mat-header-cell *matHeaderCellDef> {{ translationsLib.get('irrigation_fertilizer') }} 7 {{ translationsLib.get('reports_fertilizer_prog_real_l') }}</th>
      <td mat-cell *matCellDef="let element">
        <app-reports-prog-real
          [is_exporting]="isExporting"
          [prog]="element.progConsumption_fertilizer7"
          [real]="element.realConsumption_fertilizer7"
        ></app-reports-prog-real>
      </td>
      <td class="footer" mat-footer-cell *matFooterCellDef="let element">
        <app-reports-prog-real
          [is_exporting]="isExporting"
          [prog]="getTotal('progConsumption_fertilizer7')"
          [real]="getTotal('realConsumption_fertilizer7')"
          [extra_class]="'bolder'"
        ></app-reports-prog-real>
      </td>
    </ng-container>
    <ng-container matColumnDef="fertilizer8">
      <th class="text-center px-2" mat-header-cell *matHeaderCellDef> {{ translationsLib.get('irrigation_fertilizer') }} 8 {{ translationsLib.get('reports_fertilizer_prog_real_l') }}</th>
      <td mat-cell *matCellDef="let element">
        <app-reports-prog-real
          [is_exporting]="isExporting"
          [prog]="element.progConsumption_fertilizer8"
          [real]="element.realConsumption_fertilizer8"
        ></app-reports-prog-real>
      </td>
      <td class="footer" mat-footer-cell *matFooterCellDef="let element">
        <app-reports-prog-real
          [is_exporting]="isExporting"
          [prog]="getTotal('progConsumption_fertilizer8')"
          [real]="getTotal('realConsumption_fertilizer8')"
          [extra_class]="'bolder'"
        ></app-reports-prog-real>
      </td>
    </ng-container>
    <ng-container matColumnDef="fertilizer9">
      <th class="text-center px-2" mat-header-cell *matHeaderCellDef> {{ translationsLib.get('irrigation_fertilizer') }} 9 {{ translationsLib.get('reports_fertilizer_prog_real_l') }}</th>
      <td mat-cell *matCellDef="let element">
        <app-reports-prog-real
          [is_exporting]="isExporting"
          [prog]="element.progConsumption_fertilizer9"
          [real]="element.realConsumption_fertilizer9"
        ></app-reports-prog-real>
      </td>
      <td class="footer" mat-footer-cell *matFooterCellDef="let element">
        <app-reports-prog-real
          [is_exporting]="isExporting"
          [prog]="getTotal('progConsumption_fertilizer9')"
          [real]="getTotal('realConsumption_fertilizer9')"
          [extra_class]="'bolder'"
        ></app-reports-prog-real>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="consumptionColumnsToDisplay"></tr>
    <tr mat-row *matRowDef="let row; columns: consumptionColumnsToDisplay;"></tr>
    <tr mat-footer-row *matFooterRowDef="consumptionColumnsToDisplay"></tr>
  </table>

  <mat-paginator
    [length]="count"
    [pageSizeOptions]="[this.tablePageItemNumber]"
    showFirstLastButtons
  >
  </mat-paginator>
</div>
<app-reports-download-post-open-window [requestData]="this.requestData" *ngIf="this.downloadPDF === true"></app-reports-download-post-open-window>
