<div class="card" id="progressBar">
  <div class="row" *ngIf="finished === true">
    <div class="col-12 my-4 text-center">
      <b>{{ translationsLib.get('finished') }}</b>
    </div>
  </div>
  <div class="row" *ngIf="finished === false">
    <div class="col-12">
      <mat-progress-bar mode="determinate" value="{{progress}}"></mat-progress-bar>
    </div>
    <div class="col-12 text-center pt-2">
      <b>{{ progress }}%</b>
    </div>
  </div>
  <div class="row" *ngIf="finished === false">
    <div class="col-6 text-end">
      <button class="btn btn-light">
        <ion-icon name="arrow-redo-outline" style="color: #db9113; font-size: 25px; position: relative; top: 5px;"></ion-icon>
        <span>{{ translationsLib.get('retry') }}</span>
      </button>
    </div>
    <div class="col-6 text-start">
      <button class="btn btn-light">
        <ion-icon name="close-outline" style="color: #bc1a1a; font-size: 25px; position: relative; top: 5px;" (click)="childFunction()"></ion-icon>
        <span>{{ translationsLib.get('cancel') }}</span>
      </button>
    </div>
  </div>
</div>
