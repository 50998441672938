<ng-container *ngIf="viewMode===1">
  <div class="row">
    <h2 class="mb-0" style="color: #224261">{{translationsLib.get('graphics_dates')}}</h2>
    <span class="text-muted mb-2">{{translationsLib.get('graphics_dates_help')}}</span>
  </div>
  <div class="align-self-center">
    <!--{{translationsLib.get('graphics_choose_dates')}}-->
    <!--<div class="col-2">
      <h4>{{translationsLib.get('graphics_lasts')}}</h4>
    </div>-->
    <div class="row text-start">
      <div class=" col-12 col-lg-6 mb-2">
        <button class="btn btn-light 24hrs" (click)="megapop.displayDatepicker = false;megapop.setDate(1,'24hrs')">{{translationsLib.get('graphics_24h')}}</button>
      </div>
      <div class="col-12 col-lg-6  mb-2">
        <button class="btn btn-light 48hrs" (click)="megapop.displayDatepicker = false;megapop.setDate(2,'48hrs')">{{translationsLib.get('graphics_48h')}}</button>
      </div>
      <div class="col-12 col-lg-6  mb-2">
        <button class="btn btn-light 7days" (click)="megapop.displayDatepicker = false;megapop.setDate(7,'7days')">{{translationsLib.get('graphics_7d')}}</button>
      </div>
      <div class="col-12 col-lg-6  mb-2">
        <button class="btn btn-light other" (click)="megapop.displayDatepicker = !megapop.displayDatepicker;megapop.setDate(null,'other')">{{translationsLib.get('graphics_other_dates')}}</button>
      </div>
      <div class="col-12 range-container mb-2 mb-lg-0 mt-2" *ngIf="megapop.displayDatepicker">
        <mat-form-field class="selector"  appearance="fill" style="border:0px; padding: 0px">
          <mat-date-range-input [formGroup]="megapop.range" [rangePicker]="picker">
            <input matStartDate formControlName="start" placeholder="{{translationsLib.get('graphics_init_date')}}">
            <input matEndDate formControlName="end" placeholder="{{translationsLib.get('graphics_end_date')}}">
          </mat-date-range-input>
          <mat-datepicker-toggle matIconSuffix [for]="picker" ></mat-datepicker-toggle>
          <mat-date-range-picker #picker></mat-date-range-picker>
          <mat-error *ngIf="megapop.range.controls.start.hasError('matStartDateInvalid')">{{translationsLib.get('graphics_invalid_start_date')}}</mat-error>
          <mat-error *ngIf="megapop.range.controls.end.hasError('matEndDateInvalid')">{{translationsLib.get('graphics_invalid_end_date')}}</mat-error>
        </mat-form-field>
      </div>
    </div>
  </div>
</ng-container>
<ng-container *ngIf="viewMode===2">
  <mat-form-field class="selectorAlone"  appearance="fill" style="border:0px; padding: 0px; margin-left: 25px">
    <mat-date-range-input [formGroup]="megapop.range" [rangePicker]="picker">
      <input matStartDate formControlName="start" placeholder="{{translationsLib.get('graphics_init_date')}}">
      <input matEndDate formControlName="end" placeholder="{{translationsLib.get('graphics_end_date')}}">
    </mat-date-range-input>
    <mat-datepicker-toggle matIconSuffix [for]="picker" ></mat-datepicker-toggle>
    <mat-date-range-picker #picker></mat-date-range-picker>
    <mat-error *ngIf="megapop.range.controls.start.hasError('matStartDateInvalid')">{{translationsLib.get('graphics_invalid_start_date')}}</mat-error>
    <mat-error *ngIf="megapop.range.controls.end.hasError('matEndDateInvalid')">{{translationsLib.get('graphics_invalid_end_date')}}</mat-error>
  </mat-form-field>
  <!--<a class="mx-2 mt-2 align-self-center checkbox-ok" (click)="megapop.send()">
    <ion-icon name="checkmark-outline"></ion-icon>
  </a>-->
  <button class="btn btn-sm btn-terciary button-special" (click)="megapop.send();" *ngIf="chartGotData!== undefined && chartGotData.length>0" >Refrescar gráfica</button>
  <button class="btn btn-sm btn-terciary button-special-no-data" (click)="megapop.send();" *ngIf="chartGotData!== undefined &&  chartGotData.length===0" >Refrescar gráfica</button>
</ng-container>

