<form [formGroup]="form"
  [ngClass]="{
    'dirty': form.value.days?.dirty || dirtyWeek || form.value.week_days?.dirty
  }">
  <div class="row">
    <div class="input-group mb-2 mt-2 week-days-radios">
      <div class="form-check form-check-inline">
        <input class="form-check-input" type="radio" name="week_days"
          id="week_days_week-{{form.value.program}}"
          formControlName="week_days"
          value="WEEK" />
        <label class="form-check-label" for="week_days_week-{{form.value.program}}">
          <span class="fs-5 week-label">
            {{translationsLib.get('irrigation_program_week')}}
          </span>
        </label>
      </div>
      <div class="form-check form-check-inline">
        <input class="form-check-input" type="radio" name="week_days"
          id="week_days_days-{{form.value.program}}"
          formControlName="week_days"
          value="DAYS" />
        <label class="form-check-label" for="week_days_days-{{form.value.program}}">
          <span class="fs-5 week-label">
            {{translationsLib.get('irrigation_programs_week_days1')}}
          </span>
        </label>
      </div>
    </div>
  </div>
  <div class="row"
    *ngIf="form.value.week_days === 'WEEK'"
  >
    <div class="input-group mb-2 week-selector">
      <div class="form-check form-check-inline">
        <input type="checkbox" class="btn-check" autocomplete="off"
          id="week_monday-{{form.value.program}}"
          (change)="checkWeekDay($event)"
          [(ngModel)]="form.value.week.monday"
          [ngModelOptions]="{ standalone: true }"
        />
        <label class="btn btn-outline-primary" for="week_monday-{{form.value.program}}">
          {{this.translationsLib.get('calendar_monday_short')}}
        </label>
      </div>
      <div class="form-check form-check-inline">
        <input type="checkbox" class="btn-check" autocomplete="off"
          id="week_tuesday-{{form.value.program}}"
          (change)="checkWeekDay($event)"
          [(ngModel)]="form.value.week.tuesday"
          [ngModelOptions]="{ standalone: true }"
        />
        <label class="btn btn-outline-primary" for="week_tuesday-{{form.value.program}}">
          {{this.translationsLib.get('calendar_tuesday_short')}}
        </label>
      </div>
      <div class="form-check form-check-inline">
        <input type="checkbox" class="btn-check" autocomplete="off"
          id="week_wednesday-{{form.value.program}}"
          (change)="checkWeekDay($event)"
          [(ngModel)]="form.value.week.wednesday"
          [ngModelOptions]="{ standalone: true }"
        />
        <label class="btn btn-outline-primary" for="week_wednesday-{{form.value.program}}">
          {{ this.translationsLib.get('calendar_wednesday_short')}}
        </label>
      </div>
      <div class="form-check form-check-inline">
        <input type="checkbox" class="btn-check" autocomplete="off"
          id="week_thursday-{{form.value.program}}"
          (change)="checkWeekDay($event)"
          [(ngModel)]="form.value.week.thursday"
          [ngModelOptions]="{ standalone: true }"
        />
        <label class="btn btn-outline-primary" for="week_thursday-{{form.value.program}}">
          {{this.translationsLib.get('calendar_thursday_short')}}
        </label>
      </div>
      <div class="form-check form-check-inline">
        <input type="checkbox" class="btn-check" autocomplete="off"
          id="week_friday-{{form.value.program}}"
          (change)="checkWeekDay($event)"
          [(ngModel)]="form.value.week.friday"
          [ngModelOptions]="{ standalone: true }"
        />
        <label class="btn btn-outline-primary" for="week_friday-{{form.value.program}}">
          {{this.translationsLib.get('calendar_friday_short')}}
        </label>
      </div>
      <div class="form-check form-check-inline">
        <input type="checkbox" class="btn-check" autocomplete="off"
          id="week_saturday-{{form.value.program}}"
          (change)="checkWeekDay($event)"
          [(ngModel)]="form.value.week.saturday"
          [ngModelOptions]="{ standalone: true }"
        />
        <label class="btn btn-outline-primary" for="week_saturday-{{form.value.program}}">
          {{this.translationsLib.get('calendar_saturday_short')}}
        </label>
      </div>
      <div class="form-check form-check-inline">
        <input type="checkbox" class="btn-check" autocomplete="off"
          id="week_sunday-{{form.value.program}}"
          (change)="checkWeekDay($event)"
          [(ngModel)]="form.value.week.sunday"
          [ngModelOptions]="{ standalone: true }"
        />
        <label class="btn btn-outline-primary" for="week_sunday-{{form.value.program}}">
          {{this.translationsLib.get('calendar_sunday_short')}}
        </label>
      </div>
    </div>
  </div>
  <div class="row"
    *ngIf="form.value.week_days === 'DAYS'"
  >
    <div class="input-group mb-2 number-input">
      <button class="minus minus-style"
        (click)="inputNumberService.decrementValue($event, 0)"
      ></button>
      <input formControlName="days" type="number" class="form-control"
        [placeholder]="translationsLib.get('irrigation_program_number_of_days')"
      />
        <!-- (change)="setDirty(true)" -->
      <button class="plus border-plus plus-style"
        (click)="inputNumberService.incrementValue($event, 0)"
      ></button>
    </div>
  </div>
</form>
