<app-main-header [breadcrumbs]="breadcrumbs"></app-main-header>

<ion-content>
  <div class="container-fluid custom-container-fluid my-2">
    <div
      *ngIf="!isFetchingData && !isFiltersWithoutData && layoutConfiguration"
    >
      <div class="mb-2">
        <div class="row">
          <app-generic-header
            *ngIf="filters && filter"
            [elements]="filters"
            [text]="'name'"
            [selected]="filter"
            [placeholder]="
              translationsLib.get('irrigation_change_water_system')
            "
            [elementSearchId]="'waterSystem'"
            [label]="translationsLib.get('irrigation_water_system')"
            (changeSelected)="onChangePagination($event)"
          >
            <div irrigation>
              <div class="row justify-content-end p-1 p-md-0">
                <div class="col-auto ps-0 dots">
                  <app-mode
                    *ngIf="!isFiltersWithoutData"
                    (changeEventEmitter)="mode = $event"
                  ></app-mode>
                </div>
              </div>
            </div>
          </app-generic-header>
        </div>
      </div>
    </div>
    <app-filters-form
      [mode]="mode"
      [terminal_vid]="terminal_vid"
      [filter_id]="filter_id"
      (dirtyEventEmitter)="isDirty = $event"
      (nameEventEmitter)="filterValue = $event"
      (filterEventEmitter)="filter = $event"
      #filter_form
    ></app-filters-form>
  </div>
</ion-content>

<footer
  *ngIf="!isFiltersWithoutData && !isFetchingEquipmentData"
  class="footer mt-auto px-4 py-3 bg-light-soft border-top-3 border-light border global-irrigation-save-button"
>
  <div
    *ngIf="!terminalData?.authed_user_can_write && saveButtonTimeElapsed"
    class="row"
  >
    <div class="col">
      <app-cannot-write-alert></app-cannot-write-alert>
    </div>
  </div>
  <div class="d-flex justify-content-end" *ngIf="!saveButtonTimeElapsed">
    <div class="spinner-border text-warning" role="status">
      <span class="visually-hidden">{{
        translationsLib.get('climate_loading')
      }}</span>
    </div>
  </div>
  <div
    *ngIf="terminalData?.authed_user_can_write && saveButtonTimeElapsed"
    class="row"
  >
    <div
      class="col irrigation-save-button"
      [ngClass]="{ 'p-0': !hasScrollbar }"
    >
      <button
        (click)="filter_form.submitForm()"
        [disabled]="
          !filter_form.filtersForm.value.isDirty &&
          !filter_form.filtersForm.dirty
        "
        class="btn btn-primary float-end"
        [ngClass]="{
          'btn-danger':
            filter_form.filtersForm.value.isDirty ||
            filter_form.filtersForm.dirty
        }"
      >
        <span
          *ngIf="filter_form.isFormSubmitted"
          class="spinner-border spinner-border-sm"
          role="status"
          aria-hidden="true"
        ></span>
        {{
          filter_form.isFormSubmitted
            ? this.translationsLib.get('saving')
            : translationsLib.get('irrigation_save_filter')
        }}
      </button>
    </div>
  </div>
</footer>
