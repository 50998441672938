import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RestApiService } from '../services/api/rest-api.service';
import {
  AttachDataModel,
  ContactsDataModel,
  DetachDataModel,
  MasterTemplate,
  SpecificTemplateKeys,
  TerminalAdministrativeManagementIdentityModel,
  TerminalValvesDataModel,
} from './terminals.model';
import { TerminalDataModel } from '@nutricontrol/app360-shared';
import { IrrigationEquipmentModel } from '../farming/irrigation/irrigation.model';
import { ClimateEquipmentModel } from '../farming/climate/climate.model';
import { ConfigurableEquipmentModel } from '../farming/configurable/configurable.model';
import { DataloggerEquipmentModel } from '../farming/datalogger/datalogger.model';
import {
  PairingActionStatusDataModel,
  PrePairingDataModel,
} from './pairing.model';

@Injectable({
  providedIn: 'root',
})
export class TerminalsService {
  private restApiService: RestApiService;

  constructor(restApiService: RestApiService) {
    this.restApiService = restApiService;
  }
  public getPrePairing(data): Observable<PrePairingDataModel> {
    return new Observable((observer) => {
      this.restApiService.getPrePairing(data).subscribe(
        (response) => {
          observer.next(response);
        },
        (error) => {
          observer.next(error);
        }
      );
    });
  }

  public postPairing(data, tVid): Observable<PairingActionStatusDataModel> {
    return new Observable((observer) => {
      this.restApiService.postPairing(data, tVid).subscribe(
        (response) => {
          observer.next(response);
        },
        (error) => {
          observer.error(error);
        }
      );
    });
  }
  public getTerminalValves(vid): Observable<[TerminalValvesDataModel]> {
    return new Observable((observer) => {
      this.restApiService.getIrrigationValves(vid).subscribe(
        (response) => {
          observer.next(response);
        },
        (error) => {
          observer.error(error);
        }
      );
    });
  }
  public putTerminalValves(vid, data): Observable<[TerminalValvesDataModel]> {
    return new Observable((observer) => {
      this.restApiService.putIrrigationValves(vid, data).subscribe(
        (response) => {
          observer.next(response);
        },
        (error) => {
          observer.error(error);
        }
      );
    });
  }
  public getNonAttached(): Observable<[TerminalDataModel]> {
    return new Observable((observer) => {
      this.restApiService.getNonAttached().subscribe(
        (response) => {
          observer.next(response);
        },
        (error) => {
          observer.error(error);
        }
      );
    });
  }

  public getNonDemo(): Observable<[TerminalDataModel]> {
    return new Observable((observer) => {
      this.restApiService.getNonDemo().subscribe(
        (response) => {
          observer.next(response);
        },
        (error) => {
          observer.error(error);
        }
      );
    });
  }

  public postAttaching(tVid, fVid): Observable<AttachDataModel> {
    return new Observable((observer) => {
      this.restApiService.postAttaching(tVid, fVid).subscribe(
        (response) => {
          observer.next(response);
        },
        (error) => {
          observer.error(error);
        }
      );
    });
  }

  public getTerminals(): Observable<[TerminalDataModel]> {
    return new Observable((observer) => {
      this.restApiService.getTerminals().subscribe(
        (response) => {
          observer.next(response);
        },
        (error) => {
          observer.error(error);
        }
      );
    });
  }
  public getTerminal(vid): Observable<TerminalDataModel> {
    return new Observable((observer) => {
      this.restApiService.getTerminal(vid).subscribe(
        (response) => {
          observer.next(response);
        },
        (error) => {
          observer.error(error);
        }
      );
    });
  }
  public getUserContacts(vid): Observable<ContactsDataModel> {
    return new Observable((observer) => {
      this.restApiService.getUserContacts(vid).subscribe(
        (response) => {
          observer.next(response);
        },
        (error) => {
          observer.error(error);
        }
      );
    });
  }

  public detachTerminalfromField(tVid, fVid): Observable<DetachDataModel> {
    return new Observable((observer) => {
      this.restApiService.postDetaching(tVid, fVid).subscribe(
        (response) => {
          observer.next(response);
        },
        (error) => {
          observer.error(error);
        }
      );
    });
  }

  public getTerminalsApiInfo(data): Observable<any> {
    return new Observable((observer) => {
      this.restApiService.getTerminalsApiInfo(data).subscribe(
        (response) => {
          observer.next(response);
        },
        (error) => {
          observer.error(error);
        }
      );
    });
  }

  public getTerminalsApiInfoWithWaterSystems(
    data
  ): Observable<
    (
      | IrrigationEquipmentModel
      | ClimateEquipmentModel
      | ConfigurableEquipmentModel
      | DataloggerEquipmentModel
    )[]
  > {
    return new Observable((observer) => {
      this.restApiService.getTerminalsApiInfoWithWaterSystems(data).subscribe(
        (response) => {
          observer.next(response);
        },
        (error) => {
          observer.error(error);
        }
      );
    });
  }

  public getPickUpConfig(vid): Observable<any> {
    return new Observable((observer) => {
      this.restApiService.getPickUpConfig(vid).subscribe(
        (response) => {
          observer.next(response);
        },
        (error) => {
          observer.error(error);
        }
      );
    });
  }
  public getConfigStatus(vid): Observable<any> {
    return new Observable((observer) => {
      this.restApiService.getConfigStatus(vid).subscribe(
        (response) => {
          observer.next(response);
        },
        (error) => {
          observer.error(error);
        }
      );
    });
  }

  public putUpdateTerminal(data, vid): Observable<TerminalDataModel> {
    return new Observable((observer) => {
      this.restApiService.putUpdateTerminal(data, vid).subscribe(
        (response) => {
          observer.next(response);
        },
        (error) => {
          observer.error(error);
        }
      );
    });
  }
  public putEquipment(vid, data): Observable<any> {
    return new Observable((observer) => {
      this.restApiService.putEquipment(vid, data).subscribe(
        (response) => {
          observer.next(response);
        },
        (error) => {
          observer.error(error);
        }
      );
    });
  }
  public setUpConfig(vid): Observable<any> {
    return new Observable((observer) => {
      this.restApiService.setUpConfig(vid).subscribe(
        (response) => {
          observer.next(response);
        },
        (error) => {
          observer.error(error);
        }
      );
    });
  }
  public postActivation(vid): Observable<any> {
    return new Observable((observer) => {
      this.restApiService.postActivateTerminal(vid).subscribe(
        (response) => {
          observer.next(response);
        },
        (error) => {
          observer.error(error);
        }
      );
    });
  }
  public getDevices(vid): Observable<any> {
    return new Observable((observer) => {
      this.restApiService.getDevices(vid).subscribe(
        (response) => {
          observer.next(response);
        },
        (error) => {
          observer.error(error);
        }
      );
    });
  }
  public getTerminalAdministrativeManagement(
    vid: string
  ): Observable<TerminalAdministrativeManagementIdentityModel> {
    return new Observable((observer) => {
      this.restApiService.getTerminalAdministrativeManagement(vid).subscribe(
        (response) => {
          observer.next(response);
        },
        (error) => {
          observer.error(error);
        }
      );
    });
  }
  public claimTerminalAdministrativeManagement(
    vid: string
  ): Observable<TerminalAdministrativeManagementIdentityModel> {
    return new Observable((observer) => {
      this.restApiService.claimTerminalAdministrativeManagement(vid).subscribe(
        (response) => {
          observer.next(response);
        },
        (error) => {
          observer.error(error);
        }
      );
    });
  }
  public transferTerminalAdministrativeManagement(
    vid: string
  ): Observable<TerminalAdministrativeManagementIdentityModel> {
    return new Observable((observer) => {
      this.restApiService
        .transferTerminalAdministrativeManagement(vid)
        .subscribe(
          (response) => {
            observer.next(response);
          },
          (error) => {
            observer.error(error);
          }
        );
    });
  }
  public getTerminalSpecificTemplates(
    vid: string
  ): Observable<SpecificTemplateKeys> {
    return new Observable((observer) => {
      this.restApiService.getTerminalSpecificTemplates(vid).subscribe(
        (response) => {
          observer.next(response);
        },
        (error) => {
          observer.error(error);
        }
      );
    });
  }
  public getTerminalAvailableTemplates(
    vid: string
  ): Observable<[MasterTemplate]> {
    return new Observable((observer) => {
      this.restApiService.getTerminalAvailableTemplates(vid).subscribe(
        (response) => {
          observer.next(response);
        },
        (error) => {
          observer.error(error);
        }
      );
    });
  }
  public postAttachMasterTemplates(
    terminalVid,
    templateId,
    screenName
  ): Observable<TerminalDataModel> {
    return new Observable((observer) => {
      this.restApiService
        .postAttachMasterTemplate(terminalVid, templateId, screenName)
        .subscribe(
          (response) => {
            observer.next(response);
          },
          (error) => {
            observer.error(error);
          }
        );
    });
  }
  public deleteAttachedSpecificTemplates(
    terminalVid,
    specificTemplateVid
  ): Observable<TerminalDataModel> {
    return new Observable((observer) => {
      this.restApiService
        .deleteSpecificTemplate(terminalVid, specificTemplateVid)
        .subscribe(
          (response) => {
            observer.next(response);
          },
          (error) => {
            observer.error(error);
          }
        );
    });
  }
}
