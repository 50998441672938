import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RestApiService } from '../services/api/rest-api.service';
import {
  Auth2faConfirmationCodeModel,
  CheckAuthUserDataModel,
  LatestLegalAgreementsVersionDataModel,
  LegalAgreementsDataModel,
  LoginRequestDataModel,
  ResendCodeModel,
  ResendCodeResponseModel,
  ResetPasswordModel,
  UserLegalAgreementsVersionDataModel,
} from './auth.model';
import { CountriesDataModel } from '../commons/app-commons.model';
import { UsersLibService } from '../services/libraries/users-lib.service';
import {
  AuthResponseDataModel,
  PlatformLibService,
  SessionLibService,
  TokenDataModel,
  UserDataModel,
} from '@nutricontrol/app360-shared';
import { environment } from '../../environments/environment';
import Swal from 'sweetalert2';
import {
  AuthPasswordDataModel,
  CreateAuthPasswordDataModel,
} from '../profile/profile.model';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private restApiService: RestApiService;
  private userLib: UsersLibService;
  private sessionLib: SessionLibService;
  legalAdviceTitle = '';
  legalAdviceText = '';
  cookiePolicyTitle = '';
  cookiePolicyText = '';
  privacyPolicyTitle = '';
  privacyPolicyText = '';

  constructor(
    restApiService: RestApiService,
    userLib: UsersLibService,
    sessionLib: SessionLibService,
    private platformLib: PlatformLibService
  ) {
    this.restApiService = restApiService;
    this.userLib = userLib;
    this.sessionLib = sessionLib;
  }
  public getCountries(): Observable<CountriesDataModel> {
    return new Observable((observer) => {
      this.restApiService.getCountries().subscribe(
        (response) => {
          observer.next(response);
        },
        (error) => {
          observer.error(error);
        }
      );
    });
  }
  public getLegalAgreementsText(): Observable<LegalAgreementsDataModel> {
    return new Observable((observer) => {
      this.restApiService.getLegalAgreementsText().subscribe(
        (response) => {
          observer.next(response);
        },
        (error) => {
          observer.error(error);
        }
      );
    });
  }
  public getLatestLegalAgreementsVersion(): Observable<LatestLegalAgreementsVersionDataModel> {
    return new Observable((observer) => {
      this.restApiService.getLegalAgreementsVersion().subscribe(
        (response) => {
          observer.next(response);
        },
        (error) => {
          observer.error(error);
        }
      );
    });
  }
  public getUserLatestLegalAgreements(
    data
  ): Observable<UserLegalAgreementsVersionDataModel> {
    return new Observable((observer) => {
      this.restApiService.getUserLegalAgreementsVersion(data).subscribe(
        (response) => {
          observer.next(response);
        },
        (error) => {
          observer.error(error);
        }
      );
    });
  }
  public putUserLatestLegalAgreements(
    vid,
    data
  ): Observable<UserLegalAgreementsVersionDataModel> {
    return new Observable((observer) => {
      this.restApiService.putUserLegalAgreementsVersion(vid, data).subscribe(
        (response) => {
          observer.next(response);
        },
        (error) => {
          observer.error(error);
        }
      );
    });
  }
  public getUserData(): Observable<UserDataModel> {
    return new Observable((observer) => {
      this.restApiService.getUserData().subscribe(
        (response) => {
          observer.next(response);
        },
        (error) => {
          observer.error(error);
        }
      );
    });
  }
  public createRegister(data: UserDataModel): Observable<UserDataModel> {
    return new Observable((observer) => {
      this.restApiService.postRegister(data).subscribe(
        (response) => {
          observer.next(response);
        },
        (error) => {
          observer.error(error);
        }
      );
    });
  }
  public confirmCode(data): Observable<AuthResponseDataModel> {
    return new Observable((observer) => {
      this.restApiService.confirmCode(data).subscribe(
        (response) => {
          observer.next(response);
        },
        (error) => {
          observer.error(error);
        }
      );
    });
  }

  public resendCodeEmail(
    identifier: string
  ): Observable<ResendCodeResponseModel> {
    return new Observable((observer) => {
      this.restApiService.resendCode(identifier).subscribe(
        (response) => {
          observer.next(response);
        },
        (error) => {
          observer.error(error);
        }
      );
    });
  }
  public resetPasswordEmail(data): Observable<ResendCodeModel> {
    return new Observable((observer) => {
      this.restApiService.resetPasswordEmail(data).subscribe(
        (response) => {
          observer.next(response);
        },
        (error) => {
          observer.error(error);
        }
      );
    });
  }
  public checkResetPassword(data): Observable<ResetPasswordModel> {
    return new Observable((observer) => {
      this.restApiService.checkResetPassword(data).subscribe(
        (response) => {
          observer.next(response);
        },
        (error) => {
          observer.error(error);
        }
      );
    });
  }
  public confirm2fa(
    data: Auth2faConfirmationCodeModel
  ): Observable<AuthResponseDataModel> {
    return new Observable((observer) => {
      this.restApiService.confirm2fa(data.user_vid, data).subscribe(
        (response) => {
          observer.next(response);
        },
        (error) => {
          observer.error(error);
        }
      );
    });
  }
  public resend2fa(email: string): Observable<AuthResponseDataModel> {
    return new Observable((observer) => {
      this.restApiService.resend2fa(email).subscribe(
        (response) => {
          observer.next(response);
        },
        (error) => {
          observer.error(error);
        }
      );
    });
  }
  public login(data: LoginRequestDataModel): Observable<AuthResponseDataModel> {
    return new Observable((observer) => {
      this.restApiService.login(data).subscribe(
        (response) => {
          observer.next(response);
        },
        (error) => {
          observer.error(error);
        }
      );
    });
  }
  public checkAuth(): Observable<CheckAuthUserDataModel> {
    return new Observable((observer) => {
      this.restApiService.getCheckUserAuth().subscribe(
        (response) => {
          if (response.impersonated_token === true) {
            this.sessionLib.setImpersonated(true);
          } else {
            this.sessionLib.setImpersonated(false);
            if (
              window.location.href.indexOf('localhost') < 0 &&
              this.userLib.isBackoffice(response.user_identity.type) === true &&
              window.location.href.indexOf('backoffice') < 0
            ) {
              const redirect_url = window.location.href.replace(
                'app',
                'backoffice'
              );
              // Avoid infinite redirects by destroying application session
              this.sessionLib.destroySession();
              console.warn('[authService.checkAuth]: Logout + Redirect to BO!');
              window.location.href = redirect_url;
            }
          }
          observer.next(response);
        },
        (error) => {
          observer.error(error);
        }
      );
    });
  }
  public refreshToken(data: TokenDataModel): Observable<AuthResponseDataModel> {
    return new Observable((observer) => {
      this.restApiService.refreshToken(data).subscribe(
        (response) => {
          if (response.user_identity) {
            this.sessionLib.createSession(
              response.user_identity.vid,
              response.user_identity.name,
              response.user_identity.email,
              response.session_vid,
              response.token,
              response.access_token,
              response.user_identity.type,
              response.user_identity.language
            );
          }
          observer.next(response);
        },
        (error) => {
          observer.error(error);
        }
      );
    });
  }
  public postRegisterPushToken(data): Observable<any> {
    return new Observable((observer) => {
      this.restApiService.postRegisterPushToken(data).subscribe(
        (response) => {
          observer.next(response);
        },
        (error) => {
          observer.error(error);
        }
      );
    });
  }
  getLegalAgreements() {
    this.getLegalAgreementsText().subscribe((response) => {
      this.legalAdviceTitle = response.legal_advice_title;
      this.legalAdviceText = response.legal_advice_text;

      this.cookiePolicyTitle = response.cookie_policy_title;
      this.cookiePolicyText = response.cookie_policy_text;

      this.privacyPolicyTitle = response.privacy_policy_title;
      this.privacyPolicyText = response.privacy_policy_text;
    });
  }

  checkUserLegalAgreementsVersion(userVid: string) {
    // Get latest
    this.getLatestLegalAgreementsVersion().subscribe((responseLatest) => {
      const legalVersion = responseLatest.latest_version;

      // Get user's accepted version
      this.getUserLatestLegalAgreements(userVid).subscribe(
        (responseUserLatest) => {
          const userLegalVersion =
            responseUserLatest.accepted_legal_agreement_version;

          // Not accepted
          if (userLegalVersion < legalVersion) {
            // Get text
            this.getLegalAgreementsText().subscribe((response) => {
              Swal.fire({
                title: response.legal_advice_title,
                text: response.legal_advice_text,
                confirmButtonColor: '#224261',
              }).then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                  this.putUserLatestLegalAgreements(
                    this.sessionLib.getSessionUserVid(),
                    legalVersion
                  ).subscribe((legal) => {});
                }
              });
            });
          }
        }
      );
    });
  }

  doLogin(
    authResponse: AuthResponseDataModel,
    callBackRedirect = false
  ): Observable<boolean> {
    this.sessionLib.createSession(
      authResponse.user_vid,
      authResponse.user_identity?.name,
      authResponse.user_identity?.email,
      authResponse.session_vid,
      authResponse.token,
      authResponse.access_token,
      authResponse.user_identity?.type,
      authResponse.user_identity?.language
    );

    environment.language = authResponse.user_identity?.language;
    const initialName = authResponse.user_identity?.name[0];
    const initialSurname = authResponse.user_identity?.surname[0];
    this.checkUserLegalAgreementsVersion(this.sessionLib.getSessionUserVid());
    return new Observable((observer) => {
      this.restApiService.getUserData().subscribe(
        (user) => {
          const avatar = {
            name: (initialName + ' ' + initialSurname).trim(),
            background: user.avatar_colors[0],
            color: user.avatar_colors[1],
          };
          this.sessionLib.setAvatar(avatar);

          if (callBackRedirect) {
            if (this.platformLib.isNative()) {
              console.error('[LOGIN]: Native platform, go ahead');
              this.postRegisterPushToken(
                JSON.parse(sessionStorage.getItem('notifications_data'))
              ).subscribe(
                () => {
                  // eslint-disable-next-line no-console
                  console.info(
                    '[LOGIN]: Registration push token: ',
                    sessionStorage.getItem('notifications_data')
                  );
                  this.redirectToNextPostAction(authResponse);
                  observer.next(true);
                },
                (error) => {
                  console.error('[LOGIN]: Error', error);
                  this.redirectToNextPostAction(authResponse);
                  observer.next(false);
                }
              );
            } else {
              console.error('[LOGIN]: Web platform, go ahead');
              this.redirectToNextPostAction(authResponse);
              observer.next(true);
            }
          } else {
            observer.next(true);
          }
        },
        (error) => {
          console.warn('doLogin: user is not ready yet!');
          observer.error(false);
        }
      );
    });
  }

  public createNewPassword(
    userVid: string,
    data: CreateAuthPasswordDataModel
  ): Observable<AuthPasswordDataModel> {
    return new Observable((observer) => {
      this.restApiService.postNewPassword(userVid, data).subscribe(
        (response) => {
          observer.next(response);
        },
        (error) => {
          observer.error(error);
        }
      );
    });
  }

  redirectToNextPostAction(authResponse: AuthResponseDataModel) {
    console.log('[AuthService]: redirectToNextPostAction', authResponse);
    const home = String(this.sessionLib.getHome());
    switch (authResponse.next_action) {
      case 'auth_success_redirect_to_home':
        if (window.location.href.indexOf(home) < 0) {
          console.log(
            '[AuthService]: redirectToNextPostAction.auth_success_redirect_to_home redirect'
          );
          location.replace(home);
        } else {
          console.log(
            '[AuthService]: redirectToNextPostAction.auth_success_redirect_to_home no_action'
          );
        }
        break;
      case 'auth_expired_redirect_to_login':
        this.sessionLib.destroySession(); // Be sure that session is fully destroyed!
        if (window.location.href.indexOf('/login') < 0) {
          console.log(
            '[AuthService]: redirectToNextPostAction.auth_expired_redirect_to_login redirect'
          );
          window.location.href = '/login';
        } else {
          console.log(
            '[AuthService]: redirectToNextPostAction.auth_expired_redirect_to_login no_action'
          );
        }
        break;
      default:
        if (authResponse.token !== null && authResponse.access_token !== null) {
          console.log(
            '[AuthService]: redirectToNextPostAction.default redirect'
          );
          location.replace(home);
        } else {
          console.log(
            '[AuthService]: redirectToNextPostAction.default no_action'
          );
        }
        break;
    }
    console.log('[AuthService]: redirectToNextPostAction.action_finished!');
  }
}
