import {
  AfterViewInit,
  Component,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import {
  ConsumptionReportDataModel,
  ConsumptionReportTotalsModel,
  ReportRequestData,
} from '../../reports.model';
import { MatSort } from '@angular/material/sort';
import { MatTableExporterDirective } from 'mat-table-exporter';
import {SessionLibService, TranslationsLibService} from '@nutricontrol/app360-shared';

@Component({
  selector: 'app-reports-consumptions-table',
  templateUrl: './reports-consumptions-table.component.html',
  styleUrls: ['./reports-consumptions-table.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition(
        'expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')
      ),
    ]),
  ],
})
export class ReportsConsumptionsTableComponent
  implements OnInit, AfterViewInit
{
  @Input() data: ConsumptionReportDataModel[];
  @Input() reportType;
  // eslint-disable-next-line @typescript-eslint/no-inferrable-types
  @Input() tablePageItemNumber: number = 20;
  @Input() fertilizerColumnsToDisplay: string[];
  @Input() count: number;
  @Input() reportName = '';
  @Input() totals: ConsumptionReportTotalsModel;
  @Input() consumptionColumnsToDisplay = [];
  @Input() requestData: ReportRequestData;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatTableExporterDirective, { static: false })
  exporter: MatTableExporterDirective;
  isExporting = false;
  dataSource: MatTableDataSource<ConsumptionReportDataModel>;
  ready = false;
  downloadPDF = false;
  constructor(
    public translationsLib: TranslationsLibService,
    public session: SessionLibService
  ) {
    this.dataSource = new MatTableDataSource(this.data);
  }

  ngOnInit(): void {
    this.consumptionColumnsToDisplay = this.consumptionColumnsToDisplay.concat(
      this.fertilizerColumnsToDisplay
    );
    this.dataSource = new MatTableDataSource(this.data);
  }
  // Very important to load data on ngAfterViewInit, doing it on ngOnInit freezes the client for several seconds!
  // https://stackoverflow.com/questions/50283659/angular-6-mattable-performance-in-1000-rows
  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    setTimeout(() => {
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.ready = true;
    }, 500);
  }

  getTotal(element) {
    return this.totals[element];
  }
  exportToXLSX() {
    this.isExporting = true;
    this.exporter.exportTable('xlsx', { fileName: this.reportName });
    const self = this;
    this.exporter.exportCompleted.subscribe((item) => {
      self.isExporting = false;
    });
  }
  exportToPDF() {
    this.downloadPDF = true;
    setTimeout(() => {
      this.downloadPDF = false;
    }, 4000);
  }
}
