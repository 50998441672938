import {
  AfterViewInit,
  Component,
  HostListener,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Breadcrumb } from '../../../shared/shared.model';
import { ActivatedRoute, Router } from '@angular/router';
import { IrrigationService } from '../../../irrigation.service';
import { HelpersLibService } from '../../../../../services/libraries/helpers-lib.service';
import { DrainageV4, SafeData } from '../../../irrigation.model';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import {
  TranslationsLibService,
  TerminalDataModel,
} from '@nutricontrol/app360-shared';
import { IonContent } from '@ionic/angular';

@Component({
  selector: 'app-drainages-v4-edit',
  templateUrl: './drainages-v4-edit.component.html',
  styleUrls: ['./drainages-v4-edit.component.scss'],
})
export class DrainagesV4EditComponent
  implements OnInit, AfterViewInit, SafeData
{
  isFetchingData = false;
  isFormFieldWithErrors = false;
  drainages: DrainageV4[];
  drainage: any;
  isDrainagesV4WithoutData = false;
  drainagesControl = new FormControl<string | DrainageV4>('');
  filteredOptions: Observable<DrainageV4[]>;

  mode = '';
  breadcrumbs: Breadcrumb[] = [];
  isFetchingEquipmentData = false;
  drainage_v4_id: number;
  drainageV4Value: number;
  isDirty = false;

  terminal_vid: string;
  terminalData: TerminalDataModel;
  @ViewChild(IonContent, { static: false }) private content: IonContent;
  hasScrollbar = false;
  saveButtonTimeElapsed = false;

  constructor(
    private router: Router,
    private irrigationService: IrrigationService,
    private helpersLib: HelpersLibService,
    private activatedRoute: ActivatedRoute,
    public translationsLib: TranslationsLibService
  ) {}

  /**
   * https://stackoverflow.com/questions/56882873/how-to-detect-if-ion-content-has-a-scrollbar/58579938#58579938
   * Apply CSS class based on vertical scroll (save button)
   */

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.checkForScrollbar();
  }

  async checkForScrollbar() {
    const scrollElement = await this.content.getScrollElement();
    this.hasScrollbar = scrollElement.scrollHeight > scrollElement.clientHeight;
  }

  isDataSaved(): boolean {
    return this.isDirty;
  }

  hasWritePermissions(): boolean {
    return this.terminalData.authed_user_can_write;
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.saveButtonTimeElapsed = true;
      this.checkForScrollbar();
    }, 3000);

    this.activatedRoute.paramMap.subscribe((_) => {
      this.helpersLib.setTitle(
        '',
        `${this.translationsLib.get('irrigation_drainage')}  ${
          this.drainage_v4_id + 1
        }`,
        null
      );
    });
  }

  async ngOnInit() {
    const [, , vid, , , data] = this.router.url.split('/');
    this.terminal_vid = vid;
    this.drainage_v4_id = Number(data);

    this.filteredOptions = this.drainagesControl.valueChanges.pipe(
      startWith(''),
      map((value) => {
        const drainage = typeof value === 'string' ? value : value?.drainage;
        return drainage
          ? this._filter(drainage as string)
          : this.drainages.slice();
      })
    );

    await this.getDrainagesList();
    await this.generateBreadcrumbs();
  }

  generateBreadcrumbs(): Promise<boolean> {
    return new Promise<boolean>((resolve, _) => {
      this.isFetchingEquipmentData = true;
      this.breadcrumbs = [];
      this.irrigationService.getTerminal(this.terminal_vid).subscribe((res) => {
        this.terminalData = res;
        if (res.field) {
          this.breadcrumbs.push({
            text: res.field.name,
            disabled: false,
            to: ['/field', { vid: res.field.vid }],
            active: false,
          });
        }
        this.breadcrumbs.push(
          {
            text: res.name,
            disabled: false,
            to: `/farming/${this.terminal_vid}/irrigation`,
            active: false,
          },
          {
            text: this.drainageV4Value
              ? `${this.translationsLib.get('irrigation_drainage')} ${
                  this.drainage_v4_id + 1
                }`
              : `${this.translationsLib.get('irrigation_drainage')} ${
                  this.drainage_v4_id + 1
                }`,
            disabled: false,
            active: true,
          }
        );

        this.isFetchingEquipmentData = false;
        resolve(true);
      });
    });
  }

  private _filter(drainage: string): DrainageV4[] {
    const filterValue = drainage.toLowerCase();

    return this.drainages.filter((option) =>
      (
        this.translationsLib.get('irrigation_drainage') +
        ' ' +
        (option.drainage + 1).toString()
      )
        .toLowerCase()
        .includes(filterValue)
    );
  }

  onChangePagination(event) {
    this.router.navigate([
      `/farming/${this.terminal_vid}/irrigation/drainages_v4/${event.drainage}`,
    ]);
  }

  autocompleteDisplayFn(drainage: DrainageV4) {
    return drainage && drainage.drainage
      ? this.translationsLib.get('irrigation_drainage') +
          ' ' +
          (drainage.drainage + 1)
      : '';
  }

  getDrainagesList(): Promise<boolean> {
    return new Promise<boolean>((resolve, _) => {
      this.isFetchingData = true;
      this.irrigationService
        .getDrainagesV4(this.terminal_vid)
        .subscribe((res) => {
          this.drainages = res;

          if (this.drainages.length === 0) this.isDrainagesV4WithoutData = true;

          this.isFetchingData = false;
          resolve(true);
        });
    });
  }
}
