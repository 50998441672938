import { Component, OnInit } from '@angular/core';
import { TranslationsLibService } from '@nutricontrol/app360-shared';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss'],
})
export class NotificationsComponent implements OnInit {
  constructor(public translationsLib: TranslationsLibService) {}

  ngOnInit() {}

  reload() {
    location.reload();
  }
}
