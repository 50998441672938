import { Component, Inject, OnInit } from '@angular/core';
import { TranslationsLibService } from '@nutricontrol/app360-shared';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-confirm-leave',
  templateUrl: './confirm-leave.component.html',
  styleUrls: ['./confirm-leave.component.scss'],
})
export class ConfirmLeaveComponent implements OnInit {
  message = this.translationsLib.get('pending_changes_text');

  constructor(
    public translationsLib: TranslationsLibService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.message =
      data?.message || this.translationsLib.get('pending_changes_text');
  }

  ngOnInit() {}
}
