import { Pipe, Injectable, PipeTransform } from '@angular/core';
import { TranslationsLibService } from '@nutricontrol/app360-shared';
import { WeatherLibService } from '../../services/libraries/weather-lib.service';

@Pipe({
  name: 'magnitudeValuePrecision',
})
@Injectable({
  providedIn: 'root',
})
export class MagnitudeValuePrecisionPipe implements PipeTransform {
  constructor(
    public translationsLib: TranslationsLibService,
    public weatherLib: WeatherLibService
  ) {}

  transform(
    value: string | number,
    magnitude: string,
    mutateWindDirection?: boolean
  ): string {
    if (value === null || value === '') {
      return '-';
    }

    if (typeof value === 'string') {
      return value;
    }

    switch (magnitude) {
      case 'SUNRISE':
        return '-';
      case 'SUNSET':
        return '-';
      case 'WIND_DIRECTION':
        const shouldMutateWindDirection = mutateWindDirection ?? false;
        return !isNaN(value)
          ? shouldMutateWindDirection
            ? this.translationsLib.get(this.weatherLib.getWindDirection(value))
            : this.formatNumber(value, 0)
          : '-';
      case 'WIND_VELOCITY':
        return this.formatNumber(value, 0);
      case 'TEMPERATURE':
        return this.formatNumber(value, 1);
      case 'HUMIDITY':
        return this.formatNumber(value, 0);
      case 'RADIATION':
        return this.formatNumber(value, 0);
      case 'ACCUMULATED_RADIATION':
        return this.formatNumber(value, 0);
      case 'PLUVIOMETER':
        return this.formatNumber(value, 0);
      case 'SOIL_TENSION':
        return this.formatNumber(value, 0);
      case 'VOLTAGE':
        return this.formatNumber(value, 0);
      case 'VWC':
        return this.formatNumber(value, 1);
      case 'SOIL_TEMP':
        return this.formatNumber(value, 1);
      case 'VPD':
        return this.formatNumber(value, 2);
      case 'DWP':
        return this.formatNumber(value, 1);
      case 'WBT':
        return this.formatNumber(value, 1);
      default:
        return !isNaN(value) ? this.formatNumber(value, 2) : '-';
    }
  }

  private formatNumber(value: any, decimals: number): string {
    return !isNaN(value) ? Number(value).toFixed(decimals) : '-';
  }
}
