import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MegapopComponent } from './megapop/megapop.component';
import { AuthModule } from '../auth/auth.module';
import { MatRadioModule } from '@angular/material/radio';
import { FormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { GraphicsModule } from '../graphics/graphics.module';
import { IonicModule } from '@ionic/angular';
import { AppCommonsModule } from '../commons/app-commons.module';
import { SelectedSensorsComponent } from './selected-sensors/selected-sensors.component';
import { SelectedDatesComponent } from './selected-dates/selected-dates.component';
import { SelectedDevicesComponent } from './selected-devices/selected-devices.component';
import { ChartsComponentComponent } from '../graphics/charts-component/charts-component.component';
import { MetricsSavedComponent } from '../graphics/metrics-saved/metrics-saved.component';
import {MatExpansionModule} from "@angular/material/expansion";
import {Ng2SearchPipeModule} from "ng2-search-filter";
import {MatChipsModule} from "@angular/material/chips";
import {App360SharedModule} from "@nutricontrol/app360-shared";

@NgModule({
  declarations: [
    MegapopComponent,
    SelectedDevicesComponent,
  ],
    imports: [
        CommonModule,
        AuthModule,
        MatRadioModule,
        FormsModule,
        MatCheckboxModule,
        IonicModule,
        AppCommonsModule,
        ChartsComponentComponent,
        MetricsSavedComponent,
        MatExpansionModule,
        Ng2SearchPipeModule,
        MatChipsModule,
        SelectedSensorsComponent,
        SelectedDatesComponent,
        App360SharedModule,
    ],
  exports: [
    MegapopComponent,
    SelectedDevicesComponent,
    SelectedDatesComponent,
    SelectedSensorsComponent,
  ],
  providers: [MegapopComponent],
})
export class MegapopCommonModule {}
