<app-main-header [breadcrumbs]="breadcrumbs"></app-main-header>

<ion-content>
  <div class="mx-3 my-4" *ngIf="terminalData?.is_demo">
    <div class="row align-items-end">
      <div class="col">
        <app-demo-alert></app-demo-alert>
        <app-non-verified-installer-alert></app-non-verified-installer-alert>
      </div>
    </div>
  </div>

  <ng-container>
    <div class="container-fluid custom-container-fluid my-2">
      <div *ngIf="!isFetchingData && !isDrainagesV3WithoutData">
        <div class="mb-2">
          <div class="row">
            <app-generic-header
              *ngIf="drainages && drainage"
              [elements]="drainages"
              [text]="'name'"
              [selected]="drainage"
              [placeholder]="translationsLib.get('irrigation_change_drainage')"
              [elementSearchId]="'drainage'"
              [label]="translationsLib.get('irrigation_drainage')"
              (changeSelected)="onChangePagination($event)"
            >
              <div irrigation>
                <div class="row justify-content-end">
                  <div class="col-auto ps-0 dots">
                    <app-mode
                      *ngIf="!isDrainagesV3WithoutData"
                      (changeEventEmitter)="mode = $event"
                    ></app-mode>
                  </div>
                </div>
              </div>
            </app-generic-header>
          </div>
        </div>
      </div>
      <app-drainages-v3-form
        [mode]="mode"
        [terminal_vid]="terminal_vid"
        [drainage_v3_id]="drainage_v3_id"
        (nameEventEmitter)="drainageV3Value = $event"
        (dirtyEventEmitter)="isDirty = $event"
        (drainageEventEmitter)="drainage = $event"
        #drainages_v3_form
      ></app-drainages-v3-form>
    </div>
  </ng-container>
</ion-content>

<footer
  *ngIf="!isDrainagesV3WithoutData && !isFetchingEquipmentData"
  class="footer mt-auto px-4 py-3 bg-light-soft border-top-3 border-light border"
>
  <div *ngIf="!terminalData?.authed_user_can_write" class="row">
    <div class="col">
      <app-cannot-write-alert></app-cannot-write-alert>
    </div>
  </div>
  <div *ngIf="terminalData?.authed_user_can_write" class="row">
    <div class="col">
      <button
        (click)="drainages_v3_form.confirmSave()"
        [disabled]="
          !drainages_v3_form.drainagesV3Form.value.isDirty &&
          !drainages_v3_form.drainagesV3Form.dirty
        "
        class="btn btn-primary float-end"
        [ngClass]="{
          'btn-danger':
            drainages_v3_form.drainagesV3Form.value.isDirty ||
            drainages_v3_form.drainagesV3Form.dirty
        }"
      >
        <span
          *ngIf="drainages_v3_form.isFormSubmitted"
          class="spinner-border spinner-border-sm"
          role="status"
          aria-hidden="true"
        ></span>
        {{
          drainages_v3_form.isFormSubmitted
            ? this.translationsLib.get('saving')
            : translationsLib.get('irrigation_save_drainage')
        }}
      </button>
    </div>
  </div>
</footer>
