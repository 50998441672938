import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2';
import { PermissionsService } from '../../../permissions/permissions.service';
import { TranslationsLibService } from '@nutricontrol/app360-shared';

@Component({
  selector: 'app-demo-field-card',
  templateUrl: './demo-field-card.component.html',
  styleUrls: ['./demo-field-card.component.scss'],
})
export class DemoFieldCardComponent implements OnInit {
  constructor(
    private permissionsService: PermissionsService,
    public translationsLib: TranslationsLibService
  ) {}

  ngOnInit() {}
  showAttachDemoFieldAlert() {
    Swal.fire({
      icon: 'question',
      title: this.translationsLib.get('field_modal_title_attach_demo'),
      text: this.translationsLib.get('field_modal_text_attach_demo'),
      confirmButtonColor: '#224261',
      confirmButtonText: this.translationsLib.get('field_attach_demo_field'),
      showCancelButton: true,
      cancelButtonText: this.translationsLib.get('no_thanks'),
    }).then((result) => {
      if (result.isConfirmed) {
        this.permissionsService
          .postPairingAttachToDemo()
          .subscribe((result) => {
            Swal.fire({
              icon: 'success',
              title: this.translationsLib.get(
                'field_modal_title_demo_field_attached'
              ),
              text: this.translationsLib.get(
                'field_modal_text_demo_field_attached'
              ),
            }).then((result) => {
              window.location.reload();
            });
          });
      }
    });
  }
}
