<app-main-header [breadcrumbs]="breadcrumbs"></app-main-header>

<ion-content>
  <div class="mx-3 mt-4" *ngIf="terminalData?.is_demo">
    <div class="row align-items-end">
      <div class="col">
        <app-demo-alert></app-demo-alert>
        <app-non-verified-installer-alert></app-non-verified-installer-alert>
      </div>
    </div>
  </div>

  <div
    *ngIf="!isProgramsWithoutData"
    class="container-fluid custom-container-fluid my-2"
    [ngClass]="{
      'remove-card-margin': programForm.value.dirty
    }"
  >
    <div class="row justify-content-between align-items-center">
      <div class="col-12">
        <div
          class="row justify-content-end align-items-center"
          [ngClass]="{ 'gap-3': !isConnected }"
        >
          <app-generic-header
            *ngIf="programsList && program"
            [image]="'assets/img/Icons/icono_programa.svg'"
            [elements]="programsList"
            [text]="'name'"
            [hasButtons]="terminalData?.authed_user_can_write"
            [isPrograms]="true"
            [selected]="program"
            [elementSearchId]="'program'"
            [placeholder]="translationsLib.get('configurable_change_program')"
            [label]="translationsLib.get('irrigation_program')"
            (changeSelected)="onChangeProgram($event)"
          >
            <div irrigation>
              <div class="row justify-content-end">
                <ng-container *ngIf="terminalData?.authed_user_can_write">
<!--                  <div *ngIf="!currentProgramIsActive()" class="col-auto p-0">-->
                  <div class="col-auto p-0">
                    <ion-button
                      color="success"
                      (click)="manualActionActivate()"
                      [matTooltip]="
                        isConnected
                          ? null
                          : translationsLib.get(
                              'configurable_equipment_disconnected'
                            )
                      "
                    >
                      {{ translationsLib.get('irrigation_program_activation') }}
                      <ion-icon
                        [name]="'PLAY' | sensorIcon : '' : '' : true"
                        class="ms-2"
                      ></ion-icon>
                    </ion-button>
                    <div
                      class="custom-badge"
                      [matBadge]="isConnected ? null : '!'"
                    ></div>
                  </div>
<!--                  <div *ngIf="currentProgramIsActive()" class="col-auto p-0">-->
                  <div class="col-auto p-0">
                    <ion-button
                      color="danger"
                      (click)="manualActionStop()"
                      [matTooltip]="
                        isConnected
                          ? null
                          : translationsLib.get(
                              'configurable_equipment_disconnected'
                            )
                      "
                    >
                      {{
                        translationsLib.get('irrigation_program_deactivation')
                      }}
                      <ion-icon
                        [name]="'STOP' | sensorIcon : '' : '' : true"
                        class="ms-2"
                      ></ion-icon>
                    </ion-button>
                    <div
                      class="custom-badge"
                      [matBadge]="isConnected ? null : '!'"
                    ></div>
                  </div>
                </ng-container>
                <div class="col-auto ps-0 dots">
                  <app-mode
                    [activationButtons]="terminalData?.authed_user_can_write"
                    [currentScreen]="'programas'"
                    [allowEditionButton]="true"
                    (changeEventEmitter)="changeMode($event)"
                    (newEditionEventEmitter)="setDragItem($event)"
                  ></app-mode>
                </div>
              </div>
            </div>
          </app-generic-header>
        </div>
      </div>
    </div>
  </div>

  <!-- <div class="border border-light border-bottom-3 mb-3"></div> -->

  <div class="container-fluid custom-container-fluid">
    <div
      class="mb-4 d-flex justify-content-center mt-5"
      *ngIf="
        isFetchingData ||
        !layoutConfiguration ||
        !groupsList ||
        isFetchingTerminalLayoutConfiguration ||
        isPutNewLayoutConfiguration
      "
    >
      <div class="spinner-border text-warning" role="status">
        <span class="visually-hidden">{{
          this.translationsLib.get('irrigation_loading')
        }}</span>
      </div>
    </div>
    <div
      *ngIf="
        !isFetchingData &&
        layoutConfiguration &&
        groupsList &&
        !isFetchingTerminalLayoutConfiguration &&
        !isPutNewLayoutConfiguration &&
        !isProgramsWithoutData
      "
    >
      <div>
        <div class="row">
          <app-dirty-message
            [conditions]="!isFetchingData && layoutConfiguration && groupsList"
            [dirty]="programForm.value.dirty || isAnyProgramDirtyAfterPost"
          ></app-dirty-message>
        </div>
      </div>

      <ng-template [ngIf]="mode === 'simple' && !isProgramsWithoutData">
        <form [formGroup]="programForm" class="advanced-form mb-4">
          <div class="mb-2">
            <div class="card" *ngIf="layoutConfiguration.blocks.program.show">
              <div class="card-body custom-card-body">
                <div class="row">
                  <div class="col-6 col-md-6 col-lg-3 col-xl-3">
                    <h6 class="fs-5">
                      {{ translationsLib.get('irrigation_name') }}
                    </h6>
                    <div class="input-group mb-2">
                      <input
                        formControlName="name"
                        type="text"
                        class="form-control program-selector-bg"
                        placeholder="{{
                          translationsLib.get('irrigation_name')
                        }}"
                        maxlength="20"
                        (change)="setDirty(true)"
                      />
                    </div>
                  </div>
                  <div class="col-3 col-md-3 col-lg-1 col-xl-1">
                    <h6 class="fs-5 text-nowrap">
                      {{
                        this.translationsLib.get('irrigation_program_active')
                      }}
                    </h6>
                    <div class="input-group my-2 py-2">
                      <div class="form-check form-switch">
                        <input
                          formControlName="active"
                          class="form-check-input"
                          type="checkbox"
                          role="switch"
                          (change)="setDirty(true)"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="col-3 col-md-3 col-lg-2 col-xl-1">
                    <h6 class="fs-5 text-nowrap">
                      {{ this.translationsLib.get('field_status') }}
                    </h6>
                    <div class="input-group my-2 py-2">
                      <div *ngIf="currentProgramState()"
                           class="badge text-wrap"
                           [class]="currentProgramState() | badgeClass : 'IRRIGATION'"
                      >
                        {{ translationsLib.get('configurable_state_' + currentProgramState()) }}
                      </div>
                    </div>
                  </div>
                  <div class="col-6 col-md-6 col-lg-3 col-xl-2">
                    <h6 class="fs-5">
                      {{
                        translationsLib.get('irrigation_program_starting_date')
                      }}
                    </h6>
                    <div class="input-group mb-2">
                      <ion-datetime-button
                        datetime="begin-{{ programForm.value.program }}"
                        class="custom-datetime-button"
                      >
                        <span slot="date-target" class="custom-date-target">
                          <button
                            [ngClass]="{
                              'dirty-timepicker': this.dirtyTimepickerBegin
                            }"
                          >
                            {{ begin | programDate }}
                          </button>
                        </span>
                        <span slot="time-target" class="custom-time-target">
                          <button
                            [ngClass]="{
                              'dirty-timepicker': this.dirtyTimepickerBegin
                            }"
                          >
                            {{ begin | programTime }}
                          </button>
                        </span>
                      </ion-datetime-button>
                      <ion-modal
                        [breakpoints]="[1]"
                        [keepContentsMounted]="true"
                      >
                        <ng-template>
                          <ion-datetime
                            id="begin-{{ programForm.value.program }}"
                            [firstDayOfWeek]="1"
                            [showDefaultButtons]="true"
                            doneText="{{ translationsLib.get('accept') }}"
                            cancelText="{{ translationsLib.get('cancel') }}"
                            [(ngModel)]="begin"
                            [ngModelOptions]="{ standalone: true }"
                            (ngModelChange)="
                              changeDatetime(
                                $event,
                                'begin-' + programForm.value.program
                              )
                            "
                          ></ion-datetime>
                        </ng-template>
                      </ion-modal>
                    </div>
                  </div>
                  <div class="col-6 col-md-6 col-lg-3 col-xl-2">
                    <h6 class="fs-5">
                      {{
                        translationsLib.get('irrigation_program_ending_date')
                      }}
                    </h6>
                    <div class="input-group mb-2">
                      <ion-datetime-button
                        datetime="end-{{ programForm.value.program }}"
                        class="custom-datetime-button"
                      >
                        <span slot="date-target" class="custom-date-target">
                          <button
                            [ngClass]="{
                              'dirty-timepicker': this.dirtyTimepickerEnd
                            }"
                          >
                            {{ end | programDate }}
                          </button>
                        </span>
                        <span slot="time-target" class="custom-time-target">
                          <button
                            [ngClass]="{
                              'dirty-timepicker': this.dirtyTimepickerEnd
                            }"
                          >
                            {{ end | programTime }}
                          </button>
                        </span>
                      </ion-datetime-button>
                      <ion-modal
                        [breakpoints]="[1]"
                        [keepContentsMounted]="true"
                      >
                        <ng-template>
                          <ion-datetime
                            id="end-{{ programForm.value.program }}"
                            [firstDayOfWeek]="1"
                            [showDefaultButtons]="true"
                            doneText="{{ translationsLib.get('accept') }}"
                            cancelText="{{ translationsLib.get('cancel') }}"
                            [(ngModel)]="end"
                            [ngModelOptions]="{ standalone: true }"
                            (ngModelChange)="
                              changeDatetime(
                                $event,
                                'end-' + programForm.value.program
                              )
                            "
                          ></ion-datetime>
                        </ng-template>
                      </ion-modal>
                    </div>
                  </div>
                  <div class="col-sm-12 col-md-12 col-lg-6 col-xl-3">
                    <div class="row">
                      <div class="input-group mb-2 mt-2 week-days-radios">
                        <div class="form-check form-check-inline">
                          <input
                            class="form-check-input"
                            type="radio"
                            name="week_days"
                            id="week_days_week_simple"
                            formControlName="week_days"
                            value="WEEK"
                          />
                          <label class="form-check-label" for="week_days_week">
                            <span class="fs-5 week-label">{{
                              translationsLib.get('irrigation_program_week')
                            }}</span>
                          </label>
                        </div>
                        <div class="form-check form-check-inline">
                          <input
                            class="form-check-input"
                            type="radio"
                            name="week_days"
                            id="week_days_days-{{ programForm.value.program }}"
                            formControlName="week_days"
                            value="DAYS"
                          />
                          <label
                            class="form-check-label"
                            for="week_days_days-{{ programForm.value.program }}"
                          >
                            <span class="fs-5 week-label">{{
                              translationsLib.get(
                                'irrigation_programs_week_days1'
                              )
                            }}</span>
                          </label>
                        </div>
                      </div>
                    </div>
                    <div
                      class="row"
                      *ngIf="
                        this.programForm.value.week_days === this.WEEK_TYPE.WEEK
                      "
                    >
                      <div class="input-group mb-2 week-selector">
                        <div class="form-check form-check-inline">
                          <input
                            type="checkbox"
                            class="btn-check"
                            id="week_monday-{{ programForm.value.program }}"
                            autocomplete="off"
                            (change)="checkWeekDay($event)"
                            [(ngModel)]="this.week_monday"
                            [ngModelOptions]="{ standalone: true }"
                          />
                          <label
                            class="btn btn-outline-primary"
                            for="week_monday-{{ programForm.value.program }}"
                            >{{
                              this.translationsLib.get('calendar_monday_short')
                            }}</label
                          >
                        </div>
                        <div class="form-check form-check-inline">
                          <input
                            type="checkbox"
                            class="btn-check"
                            id="week_tuesday-{{ programForm.value.program }}"
                            autocomplete="off"
                            (change)="checkWeekDay($event)"
                            [(ngModel)]="this.week_tuesday"
                            [ngModelOptions]="{ standalone: true }"
                          />
                          <label
                            class="btn btn-outline-primary"
                            for="week_tuesday-{{ programForm.value.program }}"
                            >{{
                              this.translationsLib.get('calendar_tuesday_short')
                            }}</label
                          >
                        </div>
                        <div class="form-check form-check-inline">
                          <input
                            type="checkbox"
                            class="btn-check"
                            id="week_wednesday-{{ programForm.value.program }}"
                            autocomplete="off"
                            (change)="checkWeekDay($event)"
                            [(ngModel)]="this.week_wednesday"
                            [ngModelOptions]="{ standalone: true }"
                          />
                          <label
                            class="btn btn-outline-primary"
                            for="week_wednesday-{{ programForm.value.program }}"
                            >{{
                              this.translationsLib.get(
                                'calendar_wednesday_short'
                              )
                            }}</label
                          >
                        </div>
                        <div class="form-check form-check-inline">
                          <input
                            type="checkbox"
                            class="btn-check"
                            id="week_thursday-{{ programForm.value.program }}"
                            autocomplete="off"
                            (change)="checkWeekDay($event)"
                            [(ngModel)]="this.week_thursday"
                            [ngModelOptions]="{ standalone: true }"
                          />
                          <label
                            class="btn btn-outline-primary"
                            for="week_thursday-{{ programForm.value.program }}"
                            >{{
                              this.translationsLib.get(
                                'calendar_thursday_short'
                              )
                            }}</label
                          >
                        </div>
                        <div class="form-check form-check-inline">
                          <input
                            type="checkbox"
                            class="btn-check"
                            id="week_friday-{{ programForm.value.program }}"
                            autocomplete="off"
                            (change)="checkWeekDay($event)"
                            [(ngModel)]="this.week_friday"
                            [ngModelOptions]="{ standalone: true }"
                          />
                          <label
                            class="btn btn-outline-primary"
                            for="week_friday-{{ programForm.value.program }}"
                            >{{
                              this.translationsLib.get('calendar_friday_short')
                            }}</label
                          >
                        </div>
                        <div class="form-check form-check-inline">
                          <input
                            type="checkbox"
                            class="btn-check"
                            id="week_saturday-{{ programForm.value.program }}"
                            autocomplete="off"
                            (change)="checkWeekDay($event)"
                            [(ngModel)]="this.week_saturday"
                            [ngModelOptions]="{ standalone: true }"
                          />
                          <label
                            class="btn btn-outline-primary"
                            for="week_saturday-{{ programForm.value.program }}"
                            >{{
                              this.translationsLib.get(
                                'calendar_saturday_short'
                              )
                            }}</label
                          >
                        </div>
                        <div class="form-check form-check-inline">
                          <input
                            type="checkbox"
                            class="btn-check"
                            id="week_sunday-{{ programForm.value.program }}"
                            autocomplete="off"
                            (change)="checkWeekDay($event)"
                            [(ngModel)]="this.week_sunday"
                            [ngModelOptions]="{ standalone: true }"
                          />
                          <label
                            class="btn btn-outline-primary"
                            for="week_sunday-{{ programForm.value.program }}"
                            >{{
                              this.translationsLib.get('calendar_sunday_short')
                            }}</label
                          >
                        </div>
                      </div>
                    </div>
                    <div
                      class="row"
                      *ngIf="
                        this.programForm.value.week_days === this.WEEK_TYPE.DAYS
                      "
                    >
                      <div class="input-group mb-2 number-input">
                        <button
                          class="minus minus-style"
                          (click)="inputNumberService.decrementValue($event)"
                        ></button>
                        <input
                          formControlName="days"
                          type="number"
                          class="form-control"
                          placeholder="{{
                            translationsLib.get(
                              'irrigation_program_number_of_days'
                            )
                          }}"
                          min="-9999"
                          max="9999"
                          (change)="setDirty(true)"
                        />
                        <button
                          class="plus border-plus plus-style"
                          (click)="inputNumberService.incrementValue($event)"
                        ></button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </ng-template>
      <ng-template [ngIf]="mode === 'advanced' && !isProgramsWithoutData">
        <form [formGroup]="programForm" class="advanced-form mb-4">
          <div class="mb-2">
            <div class="card" *ngIf="layoutConfiguration.blocks.program.show">
              <div class="card-body custom-card-body">
                <div class="row">
                  <div class="col-5 col-md-6 col-lg-3 col-xl-3">
                    <h6 class="fs-5">
                      {{ translationsLib.get('irrigation_name') }}
                    </h6>
                    <div class="input-group mb-2">
                      <input
                        formControlName="name"
                        type="text"
                        placeholder="{{
                          translationsLib.get('irrigation_name')
                        }}"
                        class="form-control program-selector-bg"
                        (change)="setDirty(true)"
                        maxlength="20"
                      />
                    </div>
                  </div>
                  <div class="col-3 col-md-3 col-lg-1 col-xl-1">
                    <h6 class="fs-5">
                      {{
                        this.translationsLib.get('irrigation_program_active')
                      }}
                    </h6>
                    <div class="input-group my-2 py-2">
                      <div class="form-check form-switch">
                        <input
                          formControlName="active"
                          class="form-check-input"
                          type="checkbox"
                          role="switch"
                          (change)="setDirty(true)"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="col-4 col-md-3 col-lg-2 col-xl-1">
                    <h6 class="fs-5 text-nowrap">
                      {{ this.translationsLib.get('field_status') }}
                    </h6>
                    <div class="input-group my-2 py-2">
                      <div *ngIf="currentProgramState()"
                           class="badge text-wrap"
                           [class]="currentProgramState() | badgeClass : 'IRRIGATION'"
                      >
                        {{ translationsLib.get('configurable_state_' + currentProgramState()) }}
                      </div>
                    </div>
                  </div>
                  <div class="col-6 col-md-6 col-lg-3 col-xl-2">
                    <h6 class="fs-5">
                      {{
                        translationsLib.get('irrigation_program_starting_date')
                      }}
                    </h6>
                    <div class="input-group mb-2">
                      <ion-datetime-button
                        datetime="begin-{{ programForm.value.program }}"
                        class="custom-datetime-button"
                      >
                        <span slot="date-target" class="custom-date-target">
                          <button
                            [ngClass]="{
                              'dirty-timepicker': this.dirtyTimepickerBegin
                            }"
                          >
                            {{ begin | programDate }}
                          </button>
                        </span>
                        <span slot="time-target" class="custom-time-target">
                          <button
                            [ngClass]="{
                              'dirty-timepicker': this.dirtyTimepickerBegin
                            }"
                          >
                            {{ begin | programTime }}
                          </button>
                        </span>
                      </ion-datetime-button>
                      <ion-modal
                        [breakpoints]="[1]"
                        [keepContentsMounted]="true"
                      >
                        <ng-template>
                          <ion-datetime
                            id="begin-{{ programForm.value.program }}"
                            [firstDayOfWeek]="1"
                            [showDefaultButtons]="true"
                            doneText="{{ translationsLib.get('accept') }}"
                            cancelText="{{ translationsLib.get('cancel') }}"
                            [(ngModel)]="begin"
                            [ngModelOptions]="{ standalone: true }"
                            (ngModelChange)="
                              changeDatetime(
                                $event,
                                'begin-' + programForm.value.program
                              )
                            "
                          ></ion-datetime>
                        </ng-template>
                      </ion-modal>
                    </div>
                  </div>
                  <div class="col-6 col-md-6 col-lg-3 col-xl-2">
                    <h6 class="fs-5">
                      {{
                        translationsLib.get('irrigation_program_ending_date')
                      }}
                    </h6>
                    <div class="input-group mb-2">
                      <ion-datetime-button
                        datetime="end-{{ programForm.value.program }}"
                        class="custom-datetime-button"
                      >
                        <span slot="date-target" class="custom-date-target">
                          <button
                            [ngClass]="{
                              'dirty-timepicker': this.dirtyTimepickerEnd
                            }"
                          >
                            {{ end | programDate }}
                          </button>
                        </span>
                        <span slot="time-target" class="custom-time-target">
                          <button
                            [ngClass]="{
                              'dirty-timepicker': this.dirtyTimepickerEnd
                            }"
                          >
                            {{ end | programTime }}
                          </button>
                        </span>
                      </ion-datetime-button>
                      <ion-modal
                        [breakpoints]="[1]"
                        [keepContentsMounted]="true"
                      >
                        <ng-template>
                          <ion-datetime
                            id="end-{{ programForm.value.program }}"
                            [firstDayOfWeek]="1"
                            [showDefaultButtons]="true"
                            doneText="{{ translationsLib.get('accept') }}"
                            cancelText="{{ translationsLib.get('cancel') }}"
                            [(ngModel)]="end"
                            [ngModelOptions]="{ standalone: true }"
                            (ngModelChange)="
                              changeDatetime(
                                $event,
                                'end-' + programForm.value.program
                              )
                            "
                          ></ion-datetime>
                        </ng-template>
                      </ion-modal>
                    </div>
                  </div>
                  <div class="col-sm-12 col-md-12 col-lg-6 col-xl-3">
                    <div class="row">
                      <div class="input-group mb-2 mt-2 week-days-radios">
                        <div class="form-check form-check-inline">
                          <input
                            class="form-check-input"
                            type="radio"
                            name="week_days"
                            id="week_days_week_advance"
                            formControlName="week_days"
                            value="WEEK"
                          />
                          <label
                            class="form-check-label"
                            for="week_days_week-{{ programForm.value.program }}"
                          >
                            <span class="fs-5 week-label">{{
                              translationsLib.get('irrigation_program_week')
                            }}</span>
                          </label>
                        </div>
                        <div class="form-check form-check-inline">
                          <input
                            class="form-check-input"
                            type="radio"
                            name="week_days"
                            id="week_days_days-{{ programForm.value.program }}"
                            formControlName="week_days"
                            value="DAYS"
                          />
                          <label
                            class="form-check-label"
                            for="week_days_days-{{ programForm.value.program }}"
                          >
                            <span class="fs-5 week-label">{{
                              translationsLib.get(
                                'irrigation_programs_week_days1'
                              )
                            }}</span>
                          </label>
                        </div>
                      </div>
                    </div>
                    <div
                      class="row"
                      *ngIf="
                        this.programForm.value.week_days === this.WEEK_TYPE.WEEK
                      "
                    >
                      <div class="input-group mb-2 week-selector">
                        <div class="form-check form-check-inline">
                          <input
                            type="checkbox"
                            class="btn-check"
                            id="week_monday-{{ programForm.value.program }}"
                            autocomplete="off"
                            (change)="checkWeekDay($event)"
                            [(ngModel)]="this.week_monday"
                            [ngModelOptions]="{ standalone: true }"
                          />
                          <label
                            class="btn btn-outline-primary"
                            for="week_monday-{{ programForm.value.program }}"
                            >{{
                              translationsLib.get('calendar_monday_short')
                            }}</label
                          >
                        </div>
                        <div class="form-check form-check-inline">
                          <input
                            type="checkbox"
                            class="btn-check"
                            id="week_tuesday-{{ programForm.value.program }}"
                            autocomplete="off"
                            (change)="checkWeekDay($event)"
                            [(ngModel)]="this.week_tuesday"
                            [ngModelOptions]="{ standalone: true }"
                          />
                          <label
                            class="btn btn-outline-primary"
                            for="week_tuesday-{{ programForm.value.program }}"
                            >{{
                              translationsLib.get('calendar_tuesday_short')
                            }}</label
                          >
                        </div>
                        <div class="form-check form-check-inline">
                          <input
                            type="checkbox"
                            class="btn-check"
                            id="week_wednesday-{{ programForm.value.program }}"
                            autocomplete="off"
                            (change)="checkWeekDay($event)"
                            [(ngModel)]="this.week_wednesday"
                            [ngModelOptions]="{ standalone: true }"
                          />
                          <label
                            class="btn btn-outline-primary"
                            for="week_wednesday-{{ programForm.value.program }}"
                            >{{
                              translationsLib.get('calendar_wednesday_short')
                            }}</label
                          >
                        </div>
                        <div class="form-check form-check-inline">
                          <input
                            type="checkbox"
                            class="btn-check"
                            id="week_thursday-{{ programForm.value.program }}"
                            autocomplete="off"
                            (change)="checkWeekDay($event)"
                            [(ngModel)]="this.week_thursday"
                            [ngModelOptions]="{ standalone: true }"
                          />
                          <label
                            class="btn btn-outline-primary"
                            for="week_thursday-{{ programForm.value.program }}"
                            >{{
                              translationsLib.get('calendar_thursday_short')
                            }}</label
                          >
                        </div>
                        <div class="form-check form-check-inline">
                          <input
                            type="checkbox"
                            class="btn-check"
                            id="week_friday-{{ programForm.value.program }}"
                            autocomplete="off"
                            (change)="checkWeekDay($event)"
                            [(ngModel)]="this.week_friday"
                            [ngModelOptions]="{ standalone: true }"
                          />
                          <label
                            class="btn btn-outline-primary"
                            for="week_friday-{{ programForm.value.program }}"
                            >{{
                              translationsLib.get('calendar_friday_short')
                            }}</label
                          >
                        </div>
                        <div class="form-check form-check-inline">
                          <input
                            type="checkbox"
                            class="btn-check"
                            id="week_saturday-{{ programForm.value.program }}"
                            autocomplete="off"
                            (change)="checkWeekDay($event)"
                            [(ngModel)]="this.week_saturday"
                            [ngModelOptions]="{ standalone: true }"
                          />
                          <label
                            class="btn btn-outline-primary"
                            for="week_saturday-{{ programForm.value.program }}"
                            >{{
                              translationsLib.get('calendar_saturday_short')
                            }}</label
                          >
                        </div>
                        <div class="form-check form-check-inline">
                          <input
                            type="checkbox"
                            class="btn-check"
                            id="week_sunday-{{ programForm.value.program }}"
                            autocomplete="off"
                            (change)="checkWeekDay($event)"
                            [(ngModel)]="this.week_sunday"
                            [ngModelOptions]="{ standalone: true }"
                          />
                          <label
                            class="btn btn-outline-primary"
                            for="week_sunday-{{ programForm.value.program }}"
                            >{{
                              translationsLib.get('calendar_sunday_short')
                            }}</label
                          >
                        </div>
                      </div>
                    </div>
                    <div
                      class="row"
                      *ngIf="
                        this.programForm.value.week_days === this.WEEK_TYPE.DAYS
                      "
                    >
                      <div class="input-group mb-2 number-input">
                        <button
                          class="minus minus-style"
                          (click)="inputNumberService.decrementValue($event)"
                        ></button>
                        <input
                          formControlName="days"
                          type="number"
                          class="form-control"
                          placeholder="{{
                            translationsLib.get(
                              'irrigation_program_number_of_days'
                            )
                          }}"
                          min="-9999"
                          max="9999"
                          (change)="setDirty(true)"
                        />
                        <button
                          class="plus border-plus plus-style"
                          (click)="inputNumberService.incrementValue($event)"
                        ></button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </ng-template>

      <div
        *ngIf="!isProgramsWithoutData"
        cdkDropList
        class="d-flex flex-column gap-3"
        (cdkDropListDropped)="drop($event)"
      >
        <div
          [style.order]="getCardOrder('fertilizer_formulas')"
          cdkDrag
          [cdkDragDisabled]="isDragItemDisabled"
          class="accordion"
          id="fertilizerFormulasAccordion"
          [ngClass]="!isDragItemDisabled ? 'drag-drop-border' : ''"
          *ngIf="layoutConfiguration.blocks.program.show"
        >
          <div class="accordion-item">
            <h2
              class="accordion-header"
              id="fertilizerFormulasAccordionHeading"
            >
              <button
                class="accordion-button fs-2 irrigation-accordion-button"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#fertilizerFormulasCollapse"
                aria-expanded="true"
                aria-controls="fertilizerFormulasCollapse"
              >
                <div class="accordion-title-div">
                  <span class="float-end">{{
                    translationsLib.get('irrigation_fertilizer_formulas')
                  }}</span>
                </div>
              </button>
            </h2>
            <div
              id="fertilizerFormulasCollapse"
              class="accordion-collapse collapse show"
              aria-labelledby="fertilizerFormulasAccordionHeading"
              data-bs-parent="#fertilizerFormulasAccordion"
            >
              <div class="accordion-body custom-accordion-body">
                <div class="mb-2">
                  <ng-template [ngIf]="mode === 'simple'">
                    <form [formGroup]="programForm" class="simple-form">
                      <div class="mb-2">
                        <div
                          class="card"
                          [ngClass]="{
                            'remove-card-margin': programForm.value.dirty
                          }"
                        >
                          <div class="card-body custom-card-body">
                            <div class="row">
                              <div class="col-sm-5 col-md-5">
                                <h6 class="fs-2">
                                  {{
                                    translationsLib.get(
                                      'irrigation_selected_fertilizer_recipe'
                                    )
                                  }}
                                </h6>
                                <p class="fs-5 fw-lighter">
                                  {{
                                    translationsLib.get(
                                      'irrigation_selected_fertilizer_recipe_help'
                                    ) | sanitizeText
                                  }}
                                </p>
                              </div>
                              <div class="col-sm-7 col-md-7 col-inputs">
                                <div class="row first-row">
                                  <div class="col-sm-12">
                                    <h6 class="fs-5">
                                      {{
                                        translationsLib.get(
                                          'irrigation_selected_fertilizer_recipe'
                                        )
                                      }}
                                    </h6>

                                    <div
                                      class="input-group mb-2 week-days-radios"
                                    >
                                      <div
                                        class="form-check form-check-inline"
                                        *ngIf="
                                          layoutConfiguration.blocks.program
                                            .sub_blocks[0].recipes.fields
                                            .progprogramam3k_recetaabono
                                        "
                                      >
                                        <input
                                          class="form-check-input"
                                          type="radio"
                                          name="fertilizerFormula_type"
                                          id="fertilizerFormula_formula"
                                          [ngModel]="
                                            this.fertilizerFormula_type
                                          "
                                          [ngModelOptions]="{
                                            standalone: true
                                          }"
                                          value="FORMULA"
                                          (change)="onChangeFormulaType($event)"
                                        />
                                        <label
                                          class="form-check-label"
                                          for="fertilizerFormula_formula"
                                        >
                                          {{
                                            translationsLib.get(
                                              'irrigation_fertilizer_formula'
                                            )
                                          }}
                                        </label>
                                      </div>
                                      <div
                                        class="form-check form-check-inline"
                                        *ngIf="
                                          layoutConfiguration.blocks.program
                                            .sub_blocks[0].recipes.fields
                                            .progprogramam3k_recetaabono_groups
                                        "
                                      >
                                        <input
                                          class="form-check-input"
                                          type="radio"
                                          name="fertilizerFormula_type"
                                          id="fertilizerFormula_group"
                                          [ngModel]="
                                            this.fertilizerFormula_type
                                          "
                                          [ngModelOptions]="{
                                            standalone: true
                                          }"
                                          value="GROUP"
                                          (change)="onChangeFormulaType($event)"
                                        />
                                        <label
                                          class="form-check-label"
                                          for="fertilizerFormula_group"
                                        >
                                          {{
                                            translationsLib.get(
                                              'irrigation_group'
                                            )
                                          }}
                                        </label>
                                      </div>
                                    </div>
                                    <div class="row">
                                      <div class="col-12 col-md-6">
                                        <div
                                          class="input-group my-2"
                                          *ngIf="
                                            this.fertilizerFormula_type ===
                                            this.FERTILIZER_FORMULA_TYPE.FORMULA
                                          "
                                        >
                                          <select
                                            class="form-select"
                                            [(ngModel)]="
                                              this.fertilizerFormulaSelected
                                            "
                                            [ngModelOptions]="{
                                              standalone: true
                                            }"
                                            (change)="onChangeFormula()"
                                          >
                                            <option [ngValue]="255">
                                              {{
                                                translationsLib.get(
                                                  'irrigation_no_fertilizer_recipe'
                                                )
                                              }}
                                            </option>
                                            <option
                                              [ngValue]="fertilizer.formula"
                                              *ngFor="
                                                let fertilizer of fertilizerFormulasList
                                              "
                                            >
                                              {{
                                                fertilizer.name
                                                  ? fertilizer.name
                                                  : translationsLib.get(
                                                      'irrigation_fertilizer_formula'
                                                    ) +
                                                    ' ' +
                                                    (fertilizer.formula + 1)
                                              }}
                                            </option>
                                          </select>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </ng-template>
                  <ng-template [ngIf]="mode === 'advanced'">
                    <form [formGroup]="programForm" class="advanced-form">
                      <div class="mb-2">
                        <div class="row">
                          <div class="col-md-12">
                            <div
                              class="card"
                              [ngClass]="{
                                'remove-card-margin': programForm.value.dirty
                              }"
                            >
                              <div class="card-body custom-card-body">
                                <div class="row">
                                  <div class="col-sm-12 col-md-12">
                                    <h6 class="fs-2 input-label">
                                      {{
                                        translationsLib.get(
                                          'irrigation_selected_fertilizer_recipe'
                                        )
                                      }}
                                    </h6>
                                    <div
                                      class="input-group mb-2 week-days-radios"
                                    >
                                      <div
                                        class="form-check form-check-inline"
                                        *ngIf="
                                          layoutConfiguration.blocks.program
                                            .sub_blocks[0].recipes.fields
                                            .progprogramam3k_recetaabono
                                        "
                                      >
                                        <input
                                          class="form-check-input"
                                          type="radio"
                                          name="fertilizerFormula_type"
                                          id="fertilizerFormula_formula"
                                          [ngModel]="
                                            this.fertilizerFormula_type
                                          "
                                          [ngModelOptions]="{
                                            standalone: true
                                          }"
                                          value="FORMULA"
                                          (change)="onChangeFormulaType($event)"
                                        />
                                        <label
                                          class="form-check-label"
                                          for="fertilizerFormula_formula"
                                        >
                                          {{
                                            translationsLib.get(
                                              'irrigation_fertilizer_formula'
                                            )
                                          }}
                                        </label>
                                      </div>
                                      <div
                                        class="form-check form-check-inline"
                                        *ngIf="
                                          layoutConfiguration.blocks.program
                                            .sub_blocks[0].recipes.fields
                                            .progprogramam3k_recetaabono_groups
                                        "
                                      >
                                        <input
                                          class="form-check-input"
                                          type="radio"
                                          name="fertilizerFormula_type"
                                          id="fertilizerFormula_group"
                                          [ngModel]="
                                            this.fertilizerFormula_type
                                          "
                                          [ngModelOptions]="{
                                            standalone: true
                                          }"
                                          value="GROUP"
                                          (change)="onChangeFormulaType($event)"
                                        />
                                        <label
                                          class="form-check-label"
                                          for="fertilizerFormula_group"
                                        >
                                          {{
                                            translationsLib.get(
                                              'irrigation_group'
                                            )
                                          }}
                                        </label>
                                      </div>
                                    </div>
                                    <div class="row">
                                      <div class="col-12 col-md-6">
                                        <div
                                          class="input-group mb-2"
                                          *ngIf="
                                            this.fertilizerFormula_type ===
                                            this.FERTILIZER_FORMULA_TYPE.FORMULA
                                          "
                                        >
                                          <select
                                            class="form-select"
                                            [(ngModel)]="
                                              this.fertilizerFormulaSelected
                                            "
                                            [ngModelOptions]="{
                                              standalone: true
                                            }"
                                            (change)="onChangeFormula()"
                                          >
                                            <option [ngValue]="255">
                                              {{
                                                translationsLib.get(
                                                  'irrigation_no_fertilizer_recipe'
                                                )
                                              }}
                                            </option>

                                            <option
                                              [ngValue]="fertilizer.formula"
                                              *ngFor="
                                                let fertilizer of fertilizerFormulasList
                                              "
                                            >
                                              {{
                                                fertilizer.name
                                                  ? fertilizer.name
                                                  : translationsLib.get(
                                                      'irrigation_fertilizer_formula'
                                                    ) +
                                                    ' ' +
                                                    (fertilizer.formula + 1)
                                              }}
                                            </option>
                                          </select>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </ng-template>
                </div>
                <app-fertilizer-formulas-form
                  *ngIf="
                    this.fertilizerFormula_type ===
                      this.FERTILIZER_FORMULA_TYPE.FORMULA &&
                    this.fertilizerFormulaSelected !== 255
                  "
                  [mode]="mode"
                  [isProgramsScreen]="true"
                  [terminal_vid]="terminal_vid"
                  [fertilizer_formula_id]="this.fertilizerFormulaSelected"
                  (dirtyEventEmitter)="onChangeFormulaDirty($event)"
                  (updatedFertilizerFormulasEventEmitter)="
                    onChangeFertilizerFormulasListData($event)
                  "
                  (updateSelectedValueEventEmitter)="
                    updateFertilizerFormulaSelectedValue($event)
                  "
                  (clearIntervalEventEmitter)="handleClearInterval($event)"
                ></app-fertilizer-formulas-form>
                <!--
              <app-fertilizer-formulas
                *ngIf="
                  this.fertilizerFormula_type ===
                  this.FERTILIZER_FORMULA_TYPE.FORMULA
                "
                [mode]="mode"
                (dirtyEventEmitter)="onChangeFormulaDirty($event)"
              ></app-fertilizer-formulas>-->
              </div>
            </div>
          </div>
        </div>

        <div
          [style.order]="getCardOrder('groups')"
          cdkDrag
          [cdkDragDisabled]="isDragItemDisabled"
          class="accordion"
          id="groupAccordion"
          [ngClass]="!isDragItemDisabled ? 'drag-drop-border' : ''"
          *ngIf="
            layoutConfiguration.blocks.program.show &&
            layoutConfiguration.blocks.program.sub_blocks[0].groups.show
          "
        >
          <div class="accordion-item">
            <h2 class="accordion-header" id="groupAccordionHeading">
              <button
                class="accordion-button fs-2 irrigation-accordion-button"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#groupCollapse"
                aria-expanded="true"
                aria-controls="groupCollapse"
              >
                <div class="accordion-title-div">
                  <span class="float-end">{{
                    translationsLib.get('irrigation_groups')
                  }}</span>
                </div>
              </button>
            </h2>
            <div
              id="groupCollapse"
              class="accordion-collapse collapse show"
              aria-labelledby="groupAccordionHeading"
              data-bs-parent="#groupAccordion"
            >
              <div class="accordion-body custom-accordion-body">
                <div class="mb-2">
                  <ng-template [ngIf]="mode === 'simple'">
                    <form [formGroup]="programForm" class="simple-form">
                      <div class="mb-2">
                        <div class="card">
                          <div class="card-body custom-card-body">
                            <div class="row">
                              <div class="col-sm-5 col-md-5">
                                <h6 class="fs-2">
                                  {{
                                    translationsLib.get(
                                      'irrigation_program_selected_groups'
                                    )
                                  }}
                                </h6>
                                <p class="fs-5 fw-lighter">
                                  {{
                                    translationsLib.get(
                                      'irrigation_program_selected_groups_help'
                                    ) | sanitizeText
                                  }}
                                </p>
                              </div>
                              <div class="col-sm-7 col-md-7 col-inputs">
                                <div class="row first-row">
                                  <div class="col-sm-12">
                                    <h6 class="fs-5">
                                      {{
                                        translationsLib.get(
                                          'irrigation_program_selected_groups'
                                        )
                                      }}
                                    </h6>
                                    <div class="input-group my-2">
                                      <mat-form-field
                                        class="custom-chip-list"
                                        appearance="fill"
                                      >
                                        <mat-chip-list #groupsChipList>
                                          <mat-chip
                                            *ngFor="let group of groupsSelected"
                                            (removed)="removeGroup(group)"
                                          >
                                            {{
                                              group.name
                                                ? group.name
                                                : translationsLib.get(
                                                    'irrigation_group'
                                                  ) +
                                                  ' ' +
                                                  (group.group + 1)
                                            }}
                                            <button matChipRemove>
                                              <i
                                                [innerHTML]="
                                                  'REMOVE' | sensorIcon
                                                "
                                              ></i>
                                            </button>
                                          </mat-chip>
                                          <input
                                            placeholder="{{
                                              translationsLib.get(
                                                'irrigation_choose_groups'
                                              )
                                            }}"
                                            #groupsInput
                                            [formControl]="groupsCtrl"
                                            [matAutocomplete]="auto"
                                            [matChipInputFor]="groupsChipList"
                                            [matChipInputSeparatorKeyCodes]="
                                              separatorKeysCodes
                                            "
                                            (matChipInputTokenEnd)="
                                              addGroup($event)
                                            "
                                          />
                                        </mat-chip-list>
                                        <mat-autocomplete
                                          #auto="matAutocomplete"
                                          (optionSelected)="
                                            selectedGroup($event)
                                          "
                                        >
                                          <mat-option
                                            *ngFor="
                                              let group of filteredGroups
                                                | async
                                            "
                                            [value]="group"
                                          >
                                            {{
                                              group.name
                                                ? group.name
                                                : translationsLib.get(
                                                    'irrigation_group'
                                                  ) +
                                                  ' ' +
                                                  (group.group + 1)
                                            }}
                                          </mat-option>
                                        </mat-autocomplete>
                                      </mat-form-field>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </ng-template>
                  <ng-template [ngIf]="mode === 'advanced'">
                    <form [formGroup]="programForm" class="advanced-form">
                      <div class="mb-2">
                        <div class="row">
                          <div class="col-md-12">
                            <div class="card">
                              <div class="card-body custom-card-body">
                                <div class="row">
                                  <div class="col-sm-12 col-md-12">
                                    <h6 class="fs-2 input-label">
                                      {{
                                        translationsLib.get(
                                          'irrigation_program_selected_groups'
                                        )
                                      }}
                                    </h6>
                                    <div class="input-group mb-2">
                                      <mat-form-field
                                        class="custom-chip-list"
                                        appearance="fill"
                                      >
                                        <mat-chip-list #groupsChipList>
                                          <mat-chip
                                            *ngFor="let group of groupsSelected"
                                            (removed)="removeGroup(group)"
                                          >
                                            {{
                                              group.name
                                                ? group.name
                                                : translationsLib.get(
                                                    'irrigation_group'
                                                  ) +
                                                  ' ' +
                                                  (group.group + 1)
                                            }}
                                            <button matChipRemove>
                                              <i
                                                [innerHTML]="
                                                  'REMOVE' | sensorIcon
                                                "
                                              ></i>
                                            </button>
                                          </mat-chip>
                                          <input
                                            placeholder="{{
                                              translationsLib.get(
                                                'irrigation_choose_groups'
                                              )
                                            }}"
                                            #groupsInput
                                            [formControl]="groupsCtrl"
                                            [matAutocomplete]="auto"
                                            [matChipInputFor]="groupsChipList"
                                            [matChipInputSeparatorKeyCodes]="
                                              separatorKeysCodes
                                            "
                                            (matChipInputTokenEnd)="
                                              addGroup($event)
                                            "
                                          />
                                        </mat-chip-list>
                                        <mat-autocomplete
                                          #auto="matAutocomplete"
                                          (optionSelected)="
                                            selectedGroup($event)
                                          "
                                        >
                                          <mat-option
                                            *ngFor="
                                              let group of filteredGroups
                                                | async
                                            "
                                            [value]="group"
                                          >
                                            {{
                                              group.name
                                                ? group.name
                                                : this.translationsLib.get(
                                                    'irrigation_group'
                                                  ) +
                                                  ' ' +
                                                  (group.group + 1)
                                            }}
                                          </mat-option>
                                        </mat-autocomplete>
                                      </mat-form-field>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </ng-template>
                </div>
                <app-groups
                  [style.display]="groupsSelected.length > 0 ? 'block' : 'none'"
                  [mode]="mode"
                  [isProgramsScreen]="true"
                  [terminal_vid]="terminal_vid"
                  [showAll]="false"
                  [groupsList]="groupsSelected"
                  (dirtyEventEmitter)="onChangeGroupDirty($event)"
                  (updatedGroupsEventEmitter)="onChangeGroupListData($event)"
                  (clearIntervalEventEmitter)="handleClearInterval($event)"
                ></app-groups>
              </div>
            </div>
          </div>
        </div>

        <div
          [style.order]="getCardOrder('mixture_formulas')"
          cdkDrag
          [cdkDragDisabled]="isDragItemDisabled"
          class="accordion"
          id="mixtureAccordion"
          [ngClass]="!isDragItemDisabled ? 'drag-drop-border' : ''"
          *ngIf="
            layoutConfiguration.blocks.program.show &&
            layoutConfiguration.blocks.program.sub_blocks[0].recipes.fields
              .progprogramam3k_recetamezcla.show
          "
        >
          <div class="accordion-item">
            <h2 class="accordion-header" id="mixtureAccordionHeading">
              <button
                class="accordion-button fs-2 irrigation-accordion-button"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#mixtureCollapse"
                aria-expanded="true"
                aria-controls="mixtureCollapse"
              >
                <div class="accordion-title-div">
                  <span class="float-end">{{
                    translationsLib.get('irrigation_mixture_formula')
                  }}</span>
                </div>
              </button>
            </h2>
            <div
              id="mixtureCollapse"
              class="accordion-collapse collapse show"
              aria-labelledby="mixtureAccordionHeading"
              data-bs-parent="#mixtureAccordion"
            >
              <div class="accordion-body custom-accordion-body">
                <div class="mb-2">
                  <ng-template [ngIf]="mode === 'simple'">
                    <form [formGroup]="programForm" class="simple-form">
                      <div class="mb-2">
                        <div class="card">
                          <div class="card-body custom-card-body">
                            <div class="row">
                              <div class="col-sm-5 col-md-5">
                                <h6 class="fs-2">
                                  {{
                                    translationsLib.get(
                                      'irrigation_mixture_formula'
                                    )
                                  }}
                                </h6>
                                <p class="fs-5 fw-lighter">
                                  {{
                                    translationsLib.get(
                                      'irrigation_program_mixture_formula_help'
                                    ) | sanitizeText
                                  }}
                                </p>
                              </div>
                              <div
                                class="col-sm-7 col-md-7 col-inputs align-self-center"
                              >
                                <div class="row first-row">
                                  <div
                                    class="col-12 col-sm-6 col-lg-9 align-self-center"
                                  >
                                    <h6 class="fs-5">
                                      {{
                                        translationsLib.get(
                                          'irrigation_mixture_formula'
                                        )
                                      }}
                                    </h6>
                                  </div>
                                  <div class="col-12 col-sm-6 col-lg-3">
                                    <div class="input-group my-2">
                                      <select
                                        class="form-select"
                                        [ngModel]="this.mixtureFormulaSelected"
                                        [ngModelOptions]="{ standalone: true }"
                                        (change)="onChangeMixture($event)"
                                      >
                                        <option [value]="255">
                                          {{
                                            translationsLib.get(
                                              'irrigation_no_mixing_formula'
                                            )
                                          }}
                                        </option>
                                        <option
                                          [value]="mixture.formula"
                                          *ngFor="
                                            let mixture of mixtureFormulasList
                                          "
                                        >
                                          {{
                                            translationsLib.get(
                                              'irrigation_mixture_formula'
                                            )
                                          }}
                                          {{ mixture.formula + 1 }}
                                        </option>
                                      </select>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </ng-template>
                  <ng-template [ngIf]="mode === 'advanced'">
                    <form [formGroup]="programForm" class="advanced-form">
                      <div class="mb-2">
                        <div class="row">
                          <div class="col-md-12">
                            <div class="card">
                              <div class="card-body custom-card-body">
                                <div class="row">
                                  <div
                                    class="col-12 col-sm-6 col-lg-9 align-self-center"
                                  >
                                    <h6 class="fs-2 input-label mb-md-0">
                                      {{
                                        translationsLib.get(
                                          'irrigation_mixture_formula'
                                        )
                                      }}
                                    </h6>
                                  </div>
                                  <div class="col-12 col-sm-6 col-lg-3">
                                    <div class="input-group mb-2">
                                      <select
                                        class="form-select"
                                        [ngModel]="this.mixtureFormulaSelected"
                                        [ngModelOptions]="{ standalone: true }"
                                        (change)="onChangeMixture($event)"
                                      >
                                        <option [value]="255">
                                          {{
                                            translationsLib.get(
                                              'irrigation_no_mixing_formula'
                                            )
                                          }}
                                        </option>
                                        <option
                                          [value]="mixture.formula"
                                          *ngFor="
                                            let mixture of mixtureFormulasList
                                          "
                                        >
                                          {{
                                            translationsLib.get(
                                              'irrigation_mixture_formula'
                                            )
                                          }}
                                          {{ mixture.formula + 1 }}
                                        </option>
                                      </select>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </ng-template>
                </div>
                <app-mixture-formulas-form
                  [isProgramsScreen]="true"
                  *ngIf="this.mixtureFormulaSelected !== 255"
                  [mode]="mode"
                  [terminal_vid]="terminal_vid"
                  [mixture_id]="this.mixtureFormulaSelected"
                  (dirtyEventEmitter)="onChangeMixtureDirty($event)"
                  (clearIntervalEventEmitter)="handleClearInterval($event)"
                ></app-mixture-formulas-form>
              </div>
            </div>
          </div>
        </div>

        <div
          [style.order]="getCardOrder('activation')"
          cdkDrag
          [cdkDragDisabled]="isDragItemDisabled"
          class="accordion"
          id="activationAccordion"
          [ngClass]="!isDragItemDisabled ? 'drag-drop-border' : ''"
          *ngIf="layoutConfiguration.blocks.activation.show"
        >
          <div class="accordion-item">
            <h2 class="accordion-header" id="activationAccordionHeading">
              <button
                class="accordion-button fs-2 irrigation-accordion-button"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#activationCollapse"
                aria-expanded="true"
                aria-controls="activationCollapse"
              >
                <div class="accordion-title-div">
                  <span class="float-end">{{
                    translationsLib.get('irrigation_program_activation')
                  }}</span>
                </div>
              </button>
            </h2>
            <div
              id="activationCollapse"
              class="accordion-collapse collapse show"
              aria-labelledby="activationAccordionHeading"
              data-bs-parent="#activationAccordion"
            >
              <div class="accordion-body custom-accordion-body">
                <div class="mb-2">
                  <ng-template [ngIf]="mode === 'simple'">
                    <form [formGroup]="programForm" class="simple-form">
                      <div class="mb-2">
                        <div class="card">
                          <div class="card-body custom-card-body">
                            <div class="row">
                              <div class="col-sm-5 col-md-5">
                                <h6 class="fs-2">
                                  {{
                                    translationsLib.get(
                                      'irrigation_program_number_of_conditions'
                                    )
                                  }}
                                </h6>
                                <p class="fs-5 fw-lighter">
                                  {{
                                    translationsLib.get(
                                      'irrigation_program_number_of_conditions_help'
                                    ) | sanitizeText
                                  }}
                                </p>
                              </div>
                              <div
                                class="col-sm-7 col-md-7 col-inputs align-self-center"
                              >
                                <div class="row">
                                  <div class="col-12 col-sm-6 col-lg-9">
                                    <h6 class="fs-5">
                                      {{
                                        translationsLib.get(
                                          'irrigation_program_number_of_conditions'
                                        )
                                      }}
                                    </h6>
                                  </div>
                                  <div class="col-12 col-sm-6 col-lg-3">
                                    <div class="input-group mb-2 number-input">
                                      <button
                                        class="minus minus-style"
                                        (click)="inputNumberService.decrementValue($event)"
                                      ></button>
                                      <input
                                        formControlName="conditionsNumber"
                                        min="0"
                                        max="3"
                                        step="1"
                                        type="number"
                                        class="form-control"
                                        placeholder="{{
                                          translationsLib.get(
                                            'irrigation_program_number_of_conditions'
                                          )
                                        }}"
                                        (change)="setDirty(true)"
                                      />
                                      <button
                                        class="plus border-plus plus-style"
                                        (click)="inputNumberService.incrementValue($event)"
                                      ></button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="card">
                          <div class="card-body custom-card-body">
                            <div class="row">
                              <div class="col-sm-5 col-md-5">
                                <h6 class="fs-2">
                                  {{
                                    translationsLib.get(
                                      'irrigation_program_timetable'
                                    )
                                  }}
                                </h6>
                                <p class="fs-5 fw-lighter">
                                  {{
                                    translationsLib.get(
                                      'irrigation_program_timetable_help'
                                    ) | sanitizeText
                                  }}
                                </p>
                              </div>
                              <div class="col-sm-7 col-md-7 col-inputs">
                                <div class="row">
                                  <div class="col-sm-6">
                                    <h6 class="fs-5">
                                      {{
                                        translationsLib.get(
                                          'irrigation_program_timetable'
                                        )
                                      }}
                                    </h6>
                                  </div>
                                  <div class="col-sm-6">
                                    <div class="input-group my-2">
                                      <div class="form-check form-switch">
                                        <input
                                          formControlName="schedule"
                                          class="form-check-input"
                                          type="checkbox"
                                          role="switch"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="card">
                          <div class="card-body custom-card-body">
                            <div class="row">
                              <div class="col-sm-5 col-md-5">
                                <h6 class="fs-2">
                                  {{ translationsLib.get('irrigation_demand') }}
                                </h6>
                                <p class="fs-5 fw-lighter">
                                  {{
                                    translationsLib.get(
                                      'irrigation_program_demand_help'
                                    ) | sanitizeText
                                  }}
                                </p>
                              </div>
                              <div
                                class="col-sm-7 col-md-7 col-inputs align-self-center"
                              >
                                <div class="row">
                                  <div class="col-12 col-sm-6 col-lg-9">
                                    <h6 class="fs-5">
                                      {{
                                        translationsLib.get('irrigation_demand')
                                      }}
                                    </h6>
                                  </div>
                                  <div class="col-12 col-sm-6 col-lg-3">
                                    <div class="input-group my-2">
                                      <select
                                        class="form-select"
                                        formControlName="demand"
                                      >
                                        <option [value]="255">-</option>
                                        <option
                                          [value]="demand.demand"
                                          *ngFor="let demand of demandsList"
                                        >
                                          {{
                                            translationsLib.get(
                                              'irrigation_demand'
                                            )
                                          }}
                                          {{ demand.demand + 1 }}
                                        </option>
                                      </select>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          class="card"
                          *ngIf="
                            layoutConfiguration.blocks.activation.fields
                              .progprogramam3k_radacum
                          "
                        >
                          <div class="card-body custom-card-body">
                            <div class="row">
                              <div class="col-sm-5 col-md-5">
                                <h6 class="fs-2">
                                  {{
                                    translationsLib.get(
                                      'irrigation_program_radiation'
                                    )
                                  }}
                                </h6>
                                <p class="fs-5 fw-lighter">
                                  {{
                                    translationsLib.get(
                                      'irrigation_program_radiation_help'
                                    ) | sanitizeText
                                  }}
                                </p>
                              </div>
                              <div
                                class="col-sm-7 col-md-7 col-inputs align-self-center"
                              >
                                <div class="row">
                                  <div class="col-12 col-sm-6 col-lg-9">
                                    <h6 class="fs-5">
                                      {{ translationsLib.get('radiation') }}
                                    </h6>
                                  </div>
                                  <div class="col-12 col-sm-6 col-lg-3">
                                    <div class="input-group mb-2 number-input">
                                      <button
                                        class="minus minus-style"
                                        (click)="inputNumberService.decrementValue($event)"
                                      ></button>
                                      <input
                                        formControlName="acummulatedRadiation"
                                        min="0"
                                        max="20000"
                                        step="1"
                                        type="number"
                                        class="form-control"
                                        placeholder="{{
                                          translationsLib.get('radiation')
                                        }}"
                                        (change)="setDirty(true)"
                                      />
                                      <button
                                        class="plus border-plus plus-style"
                                        (click)="inputNumberService.incrementValue($event)"
                                      ></button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          class="card"
                          *ngIf="
                            layoutConfiguration.blocks.activation.fields
                              .progprogramam3k_drenaje
                          "
                        >
                          <div class="card-body custom-card-body">
                            <div class="row">
                              <div class="col-sm-5 col-md-5">
                                <h6 class="fs-2">
                                  {{
                                    translationsLib.get('irrigation_drainage')
                                  }}
                                </h6>
                                <p class="fs-5 fw-lighter">
                                  {{
                                    translationsLib.get(
                                      'irrigation_program_drainage_help'
                                    ) | sanitizeText
                                  }}
                                </p>
                              </div>
                              <div
                                class="col-sm-7 col-md-7 col-inputs align-self-center"
                              >
                                <div class="row">
                                  <div class="col-12 col-sm-6 col-lg-9">
                                    <h6 class="fs-5">
                                      {{
                                        translationsLib.get(
                                          'irrigation_drainage'
                                        )
                                      }}
                                    </h6>
                                  </div>
                                  <div class="col-12 col-sm-6 col-lg-3">
                                    <div class="input-group my-2">
                                      <select
                                        class="form-select"
                                        formControlName="drainage"
                                      >
                                        <option [value]="255">-</option>
                                        <option
                                          [value]="drainage.drainage"
                                          *ngFor="let drainage of drainagesList"
                                        >
                                          {{
                                            translationsLib.get(
                                              'irrigation_drainage'
                                            )
                                          }}
                                          {{ drainage.drainage + 1 }}
                                        </option>
                                      </select>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="card">
                          <div class="card-body custom-card-body">
                            <div class="row">
                              <div class="col-sm-5 col-md-5">
                                <h6 class="fs-2">
                                  {{
                                    translationsLib.get(
                                      'irrigation_program_sensor'
                                    )
                                  }}
                                </h6>
                                <p class="fs-5 fw-lighter">
                                  {{
                                    translationsLib.get(
                                      'irrigation_program_sensor_help'
                                    ) | sanitizeText
                                  }}
                                </p>
                              </div>
                              <div class="col-sm-7 col-md-7 col-inputs">
                                <ng-container
                                  *ngFor="
                                    let condition of conditions;
                                    let i = index
                                  "
                                >
                                  <div
                                    class="row"
                                    *ngIf="
                                      layoutConfiguration.blocks.activation
                                        .fields[
                                        'prog_Sonda' + (i + 1).toString()
                                      ]
                                    "
                                  >
                                    <div class="col-md-4">
                                      <h6 class="fs-5">
                                        {{
                                          translationsLib.get(
                                            'irrigation_sensor'
                                          )
                                        }}
                                        {{ i + 1 }}
                                      </h6>
                                      <div class="input-group mb-2">
                                        <select
                                          class="form-select"
                                          [(ngModel)]="condition.id"
                                          [ngModelOptions]="{
                                            standalone: true
                                          }"
                                          (ngModelChange)="setDirty(true)"
                                        >
                                          <option [value]="'255_255_255'">
                                            -
                                          </option>
                                          <option
                                            [value]="sensor.id"
                                            *ngFor="let sensor of sensorsList"
                                          >
                                            {{ sensor.name }}
                                          </option>
                                        </select>
                                      </div>
                                    </div>
                                    <div class="col-md-4">
                                      <h6 class="fs-5">
                                        {{
                                          translationsLib.get(
                                            'irrigation_program_comparison'
                                          )
                                        }}
                                      </h6>
                                      <div class="input-group mb-2">
                                        <select
                                          class="form-select"
                                          [(ngModel)]="condition.comparisonType"
                                          [ngModelOptions]="{
                                            standalone: true
                                          }"
                                          (ngModelChange)="setDirty(true)"
                                        >
                                          <option
                                            [value]="comparisonType"
                                            *ngFor="
                                              let comparisonType of comparisonTypesList
                                            "
                                          >
                                            {{ comparisonType }}
                                          </option>
                                        </select>
                                      </div>
                                    </div>
                                    <div class="col-md-4">
                                      <h6 class="fs-5">
                                        {{
                                          translationsLib.get(
                                            'irrigation_value'
                                          )
                                        }}
                                      </h6>
                                      <div class="input-group mb-2">
                                        <input
                                          type="text"
                                          class="form-control"
                                          placeholder="{{
                                            translationsLib.get(
                                              'irrigation_value'
                                            )
                                          }}"
                                          [(ngModel)]="condition.value"
                                          [ngModelOptions]="{
                                            standalone: true
                                          }"
                                          (ngModelChange)="setDirty(true)"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </ng-container>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </ng-template>
                  <ng-template [ngIf]="mode === 'advanced'">
                    <form [formGroup]="programForm" class="advanced-form">
                      <div class="mb-2">
                        <div class="row">
                          <div class="col">
                            <div class="card">
                              <div class="card-body custom-card-body">
                                <div class="row">
                                  <div class="col-sm-12 col-md-12">
                                    <div class="row">
                                      <div
                                        class="col-12 col-md-6 col-lg-9 align-self-center"
                                      >
                                        <h6 class="fs-2 input-label mb-md-0">
                                          {{
                                            translationsLib.get(
                                              'irrigation_program_number_of_conditions'
                                            )
                                          }}
                                        </h6>
                                      </div>
                                      <div class="col-12 col-md-6 col-lg-3">
                                        <div
                                          class="input-group mb-2 number-input"
                                        >
                                          <button
                                            class="minus minus-style"
                                            (click)="
                                              inputNumberService.decrementValue($event)
                                            "
                                          ></button>
                                          <input
                                            formControlName="conditionsNumber"
                                            min="0"
                                            max="3"
                                            step="1"
                                            type="number"
                                            class="form-control"
                                            placeholder="{{
                                              translationsLib.get(
                                                'irrigation_program_number_of_conditions'
                                              )
                                            }}"
                                            (change)="setDirty(true)"
                                          />
                                          <button
                                            class="plus border-plus plus-style"
                                            (click)="
                                              inputNumberService.incrementValue($event)
                                            "
                                          ></button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-6">
                            <div class="card">
                              <div class="card-body custom-card-body">
                                <div class="row">
                                  <div
                                    class="col-12 col-md-6 col-lg-9 align-self-center"
                                  >
                                    <h6 class="fs-2 input-label">
                                      {{
                                        translationsLib.get('irrigation_demand')
                                      }}
                                    </h6>
                                  </div>
                                  <div class="col-12 col-md-6 col-lg-3">
                                    <div class="input-group mb-2">
                                      <select
                                        class="form-select"
                                        formControlName="demand"
                                      >
                                        <option [value]="255">-</option>
                                        <option
                                          [value]="demand.demand"
                                          *ngFor="let demand of demandsList"
                                        >
                                          {{
                                            translationsLib.get(
                                              'irrigation_demand'
                                            )
                                          }}
                                          {{ demand.demand + 1 }}
                                        </option>
                                      </select>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div
                              class="card"
                              *ngIf="
                                layoutConfiguration.blocks.activation.fields
                                  .progprogramam3k_radacum
                              "
                            >
                              <div class="card-body custom-card-body">
                                <div class="row">
                                  <div
                                    class="col-12 col-md-6 col-lg-9 align-self-center"
                                  >
                                    <h6 class="fs-2 input-label">
                                      {{ translationsLib.get('radiation') }}
                                    </h6>
                                  </div>
                                  <div class="col-12 col-md-6 col-lg-3">
                                    <div class="input-group mb-2 number-input">
                                      <button
                                        class="minus minus-style"
                                        (click)="inputNumberService.decrementValue($event)"
                                      ></button>
                                      <input
                                        formControlName="acummulatedRadiation"
                                        min="0"
                                        max="20000"
                                        step="1"
                                        type="number"
                                        class="form-control"
                                        placeholder="{{
                                          translationsLib.get('radiation')
                                        }}"
                                        (change)="setDirty(true)"
                                      />
                                      <button
                                        class="plus border-plus plus-style"
                                        (click)="inputNumberService.incrementValue($event)"
                                      ></button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div
                              class="card"
                              *ngIf="
                                layoutConfiguration.blocks.activation.fields
                                  .progprogramam3k_drenaje
                              "
                            >
                              <div class="card-body custom-card-body">
                                <div class="row">
                                  <div
                                    class="col-12 col-md-6 col-lg-9 align-self-center"
                                  >
                                    <h6 class="fs-2 input-label">
                                      {{
                                        translationsLib.get(
                                          'irrigation_drainage'
                                        )
                                      }}
                                    </h6>
                                  </div>
                                  <div class="col-12 col-md-6 col-lg-3">
                                    <div class="input-group mb-2">
                                      <select
                                        class="form-select"
                                        formControlName="drainage"
                                      >
                                        <option [value]="255">-</option>
                                        <option
                                          [value]="drainage.drainage"
                                          *ngFor="let drainage of drainagesList"
                                        >
                                          {{
                                            translationsLib.get(
                                              'irrigation_drainage'
                                            )
                                          }}
                                          {{ drainage.drainage + 1 }}
                                        </option>
                                      </select>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="card">
                              <div class="card-body custom-card-body">
                                <div class="row">
                                  <div class="col-sm-6">
                                    <h6
                                      class="fs-2 input-label"
                                      style="margin-top: 0.65rem"
                                    >
                                      {{
                                        translationsLib.get(
                                          'irrigation_program_timetable'
                                        )
                                      }}
                                    </h6>
                                  </div>
                                  <div class="col-sm-6">
                                    <div class="input-group my-2">
                                      <div class="form-check form-switch">
                                        <input
                                          formControlName="schedule"
                                          class="form-check-input"
                                          type="checkbox"
                                          role="switch"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div class="card">
                              <div class="card-body custom-card-body">
                                <div class="row">
                                  <div class="col-md-12">
                                    <h6 class="fs-2">
                                      {{
                                        translationsLib.get(
                                          'irrigation_program_sensor'
                                        )
                                      }}
                                    </h6>
                                  </div>
                                </div>
                                <ng-container
                                  *ngFor="
                                    let condition of conditions;
                                    let i = index
                                  "
                                >
                                  <div
                                    class="row"
                                    *ngIf="
                                      layoutConfiguration.blocks.activation
                                        .fields[
                                        'prog_Sonda' + (i + 1).toString()
                                      ]
                                    "
                                  >
                                    <div class="col-md-4">
                                      <h6 class="fs-5">
                                        {{
                                          translationsLib.get(
                                            'irrigation_sensor'
                                          )
                                        }}
                                        {{ i + 1 }}
                                      </h6>
                                      <div class="input-group mb-2">
                                        <select
                                          class="form-select"
                                          [(ngModel)]="condition.id"
                                          [ngModelOptions]="{
                                            standalone: true
                                          }"
                                          (ngModelChange)="setDirty(true)"
                                        >
                                          <option [value]="'255_255_255'">
                                            -
                                          </option>
                                          <option
                                            [value]="sensor.id"
                                            *ngFor="let sensor of sensorsList"
                                          >
                                            {{ sensor.name }}
                                          </option>
                                        </select>
                                      </div>
                                    </div>
                                    <div class="col-md-4">
                                      <h6 class="fs-5">
                                        {{
                                          translationsLib.get(
                                            'irrigation_program_comparison'
                                          )
                                        }}
                                      </h6>
                                      <div class="input-group mb-2">
                                        <select
                                          class="form-select"
                                          [(ngModel)]="condition.comparisonType"
                                          [ngModelOptions]="{
                                            standalone: true
                                          }"
                                          (ngModelChange)="setDirty(true)"
                                        >
                                          <option
                                            [value]="comparisonType"
                                            *ngFor="
                                              let comparisonType of comparisonTypesList
                                            "
                                          >
                                            {{ comparisonType }}
                                          </option>
                                        </select>
                                      </div>
                                    </div>
                                    <div class="col-md-4">
                                      <h6 class="fs-5">
                                        {{
                                          translationsLib.get(
                                            'irrigation_value'
                                          )
                                        }}
                                      </h6>
                                      <div class="input-group mb-2">
                                        <input
                                          type="text"
                                          class="form-control"
                                          placeholder="{{
                                            translationsLib.get(
                                              'irrigation_value'
                                            )
                                          }}"
                                          [(ngModel)]="condition.value"
                                          [ngModelOptions]="{
                                            standalone: true
                                          }"
                                          (ngModelChange)="setDirty(true)"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </ng-container>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </ng-template>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          [style.order]="getCardOrder('irrigation_behavior')"
          cdkDrag
          [cdkDragDisabled]="isDragItemDisabled"
          class="accordion"
          id="behaviorAccordion"
          [ngClass]="!isDragItemDisabled ? 'drag-drop-border' : ''"
          *ngIf="layoutConfiguration.blocks.irrigation_behaviour.show"
        >
          <div class="accordion-item">
            <h2 class="accordion-header" id="behaviorAccordionHeading">
              <button
                class="accordion-button fs-2 irrigation-accordion-button"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#behaviorCollapse"
                aria-expanded="true"
                aria-controls="behaviorCollapse"
              >
                <div class="accordion-title-div">
                  <span class="float-end">{{
                    translationsLib.get('irrigation_program_behaviour')
                  }}</span>
                </div>
              </button>
            </h2>
            <div
              id="behaviorCollapse"
              class="accordion-collapse collapse show"
              aria-labelledby="behaviorAccordionHeading"
              data-bs-parent="#behaviorAccordion"
            >
              <div class="accordion-body custom-accordion-body">
                <div class="mb-2">
                  <ng-template [ngIf]="mode === 'simple'">
                    <form [formGroup]="programForm" class="simple-form">
                      <div class="mb-2">
                        <div class="card">
                          <div class="card-body custom-card-body">
                            <div class="row">
                              <div class="col-sm-5 col-md-5">
                                <h6 class="fs-2">
                                  {{
                                    translationsLib.get(
                                      'irrigation_program_max_irrigation_number'
                                    )
                                  }}
                                </h6>
                                <p class="fs-5 fw-lighter">
                                  {{
                                    translationsLib.get(
                                      'irrigation_program_max_irrigation_number_help'
                                    ) | sanitizeText
                                  }}
                                </p>
                              </div>
                              <div
                                class="col-sm-7 col-md-7 col-inputs align-self-center"
                              >
                                <div class="row">
                                  <div class="col-12 col-sm-6 col-lg-9">
                                    <h6 class="fs-5">
                                      {{
                                        translationsLib.get(
                                          'irrigation_program_max_irrigation_number'
                                        )
                                      }}
                                    </h6>
                                  </div>
                                  <div class="col-12 col-sm-6 col-lg-3">
                                    <div class="input-group mb-2 number-input">
                                      <button
                                        class="minus minus-style"
                                        (click)="inputNumberService.decrementValue($event)"
                                      ></button>
                                      <input
                                        formControlName="maxActivations"
                                        min="0"
                                        max="100"
                                        step="1"
                                        type="number"
                                        class="form-control"
                                        placeholder="{{
                                          translationsLib.get(
                                            'irrigation_program_max_irrigation_number'
                                          )
                                        }}"
                                        (change)="setDirty(true)"
                                      />
                                      <button
                                        class="plus border-plus plus-style"
                                        (click)="inputNumberService.incrementValue($event)"
                                      ></button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="card">
                          <div class="card-body custom-card-body">
                            <div class="row">
                              <div class="col-sm-5 col-md-5">
                                <h6 class="fs-2">
                                  {{
                                    translationsLib.get(
                                      'irrigation_program_time'
                                    )
                                  }}
                                </h6>
                                <p class="fs-5 fw-lighter">
                                  {{
                                    translationsLib.get(
                                      'irrigation_program_time_help'
                                    ) | sanitizeText
                                  }}
                                </p>
                              </div>
                              <div class="col-sm-7 col-md-7 col-inputs">
                                <div class="row">
                                  <div class="col-sm-6">
                                    <h6 class="fs-5">
                                      {{
                                        translationsLib.get(
                                          'irrigation_program_max_resting_time'
                                        )
                                      }}
                                    </h6>
                                  </div>
                                  <div class="col-sm-6">
                                    <div class="my-2">
                                      <ng-template #maxRestingTimePicker>
                                        <timepicker
                                          appOnlyNumbers
                                          [showMeridian]="false"
                                          [showSeconds]="true"
                                          [minuteStep]="1"
                                          [secondsStep]="1"
                                          [formControlName]="'maxRestingTime'"
                                        ></timepicker>
                                      </ng-template>
                                      <input
                                        appOnlyNumbers
                                        [ngClass]="{
                                          'ng-dirty':
                                            programForm.controls.maxRestingTime
                                              .dirty
                                        }"
                                        class="form-control text-center"
                                        type="text"
                                        [popover]="maxRestingTimePicker"
                                        [outsideClick]="true"
                                        placement="bottom"
                                        [value]="
                                          programForm.value.maxRestingTime
                                            | inputTime
                                        "
                                        (change)="
                                          onChangeInputTimeWithKeyboard(
                                            $event,
                                            'maxRestingTime'
                                          )
                                        "
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div class="row">
                                  <div class="col-sm-6">
                                    <h6 class="fs-5">
                                      {{
                                        translationsLib.get(
                                          'irrigation_program_min_resting_time'
                                        )
                                      }}
                                    </h6>
                                  </div>
                                  <div class="col-sm-6">
                                    <div class="my-2">
                                      <ng-template #minRestingTimePicker>
                                        <timepicker
                                          appOnlyNumbers
                                          [showMeridian]="false"
                                          [showSeconds]="true"
                                          [minuteStep]="1"
                                          [secondsStep]="1"
                                          [formControlName]="'minRestingTime'"
                                        ></timepicker>
                                      </ng-template>
                                      <input
                                        appOnlyNumbers
                                        [ngClass]="{
                                          'ng-dirty':
                                            programForm.controls.minRestingTime
                                              .dirty
                                        }"
                                        class="form-control text-center"
                                        type="text"
                                        [popover]="minRestingTimePicker"
                                        [outsideClick]="true"
                                        placement="bottom"
                                        [value]="
                                          programForm.value.minRestingTime
                                            | inputTime
                                        "
                                        (change)="
                                          onChangeInputTimeWithKeyboard(
                                            $event,
                                            'minRestingTime'
                                          )
                                        "
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          class="card"
                          *ngIf="
                            layoutConfiguration.blocks.irrigation_behaviour
                              .fields.progprogramam3k_penlazado
                          "
                        >
                          <div class="card-body custom-card-body">
                            <div class="row">
                              <div class="col-sm-5 col-md-5">
                                <h6 class="fs-2">
                                  {{
                                    translationsLib.get(
                                      'irrigation_program_linked_to'
                                    )
                                  }}
                                </h6>
                                <p class="fs-5 fw-lighter">
                                  {{
                                    translationsLib.get(
                                      'irrigation_program_linked_to_help'
                                    ) | sanitizeText
                                  }}
                                </p>
                              </div>
                              <div
                                class="col-sm-7 col-md-7 col-inputs align-self-center"
                              >
                                <div class="row">
                                  <div class="col-12 col-sm-6 col-lg-9">
                                    <h6 class="fs-5">
                                      {{
                                        translationsLib.get(
                                          'irrigation_program'
                                        )
                                      }}
                                    </h6>
                                  </div>
                                  <div class="col-12 col-sm-6 col-lg-3">
                                    <div class="input-group my-2">
                                      <select
                                        class="form-select"
                                        formControlName="linkedProgram"
                                      >
                                        <option [value]="255">-</option>
                                        <option
                                          [value]="linkedProgram.program"
                                          *ngFor="
                                            let linkedProgram of programsListWithoutThis
                                          "
                                        >
                                          {{
                                            linkedProgram.name
                                              ? linkedProgram.name
                                              : translationsLib.get(
                                                  'irrigation_program'
                                                ) +
                                                ' ' +
                                                (linkedProgram.program + 1)
                                          }}
                                        </option>
                                      </select>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </ng-template>
                  <ng-template [ngIf]="mode === 'advanced'">
                    <form [formGroup]="programForm" class="advanced-form">
                      <div class="mb-2">
                        <div class="row">
                          <div class="col-md-6">
                            <div class="card">
                              <div class="card-body custom-card-body">
                                <div class="row">
                                  <div class="col-sm-12 col-md-12">
                                    <div class="row">
                                      <div
                                        class="col-12 col-sm-6 col-lg-9 align-self-center mb-md-0"
                                      >
                                        <h6 class="fs-2 input-label">
                                          {{
                                            translationsLib.get(
                                              'irrigation_program_max_irrigation_number'
                                            )
                                          }}
                                        </h6>
                                      </div>
                                      <div class="col-12 col-md-6 col-lg-3">
                                        <div
                                          class="input-group mb-2 number-input"
                                        >
                                          <button
                                            class="minus minus-style"
                                            (click)="
                                              inputNumberService.decrementValue($event)
                                            "
                                          ></button>
                                          <input
                                            formControlName="maxActivations"
                                            min="0"
                                            max="100"
                                            step="1"
                                            type="number"
                                            class="form-control"
                                            placeholder="{{
                                              translationsLib.get(
                                                'irrigation_program_max_irrigation_number'
                                              )
                                            }}"
                                            (change)="setDirty(true)"
                                          />
                                          <button
                                            class="plus border-plus plus-style"
                                            (click)="
                                              inputNumberService.incrementValue($event)
                                            "
                                          ></button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div
                              class="card"
                              *ngIf="
                                layoutConfiguration.blocks.irrigation_behaviour
                                  .fields.progprogramam3k_penlazado
                              "
                            >
                              <div class="card-body custom-card-body">
                                <div class="row">
                                  <div
                                    class="col-12 col-sm-6 col-lg-9 align-self-center mb-md-0"
                                  >
                                    <h6 class="fs-2 input-label">
                                      {{
                                        translationsLib.get(
                                          'irrigation_program_linked_to'
                                        )
                                      }}
                                    </h6>
                                  </div>
                                  <div class="col-12 col-sm-6 col-lg-3">
                                    <div class="input-group mb-2">
                                      <select
                                        class="form-select"
                                        formControlName="linkedProgram"
                                      >
                                        <option [value]="255">-</option>
                                        <option
                                          [value]="linkedProgram.program"
                                          *ngFor="
                                            let linkedProgram of programsListWithoutThis
                                          "
                                        >
                                          {{
                                            linkedProgram.name
                                              ? linkedProgram.name
                                              : translationsLib.get(
                                                  'irrigation_program'
                                                ) +
                                                ' ' +
                                                (linkedProgram.program + 1)
                                          }}
                                        </option>
                                      </select>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="card">
                              <div class="card-body custom-card-body">
                                <div class="row">
                                  <div class="col-md-12">
                                    <h6 class="fs-2 mb-3">
                                      {{
                                        translationsLib.get(
                                          'irrigation_program_time'
                                        )
                                      }}
                                    </h6>
                                  </div>
                                </div>
                                <div class="row">
                                  <div class="col-sm-6">
                                    <h6 class="fs-5 my-2 inline-label">
                                      {{
                                        translationsLib.get(
                                          'irrigation_program_max_resting_time'
                                        )
                                      }}
                                    </h6>
                                  </div>
                                  <div class="col-sm-6">
                                    <div class="my-2">
                                      <ng-template #maxRestingTimePicker>
                                        <timepicker
                                          appOnlyNumbers
                                          [showMeridian]="false"
                                          [showSeconds]="true"
                                          [minuteStep]="1"
                                          [secondsStep]="1"
                                          [formControlName]="'maxRestingTime'"
                                        ></timepicker>
                                      </ng-template>
                                      <input
                                        appOnlyNumbers
                                        [ngClass]="{
                                          'ng-dirty':
                                            programForm.controls.maxRestingTime
                                              .dirty
                                        }"
                                        class="form-control text-center"
                                        type="text"
                                        [popover]="maxRestingTimePicker"
                                        [outsideClick]="true"
                                        placement="bottom"
                                        [value]="
                                          programForm.value.maxRestingTime
                                            | inputTime
                                        "
                                        (change)="
                                          onChangeInputTimeWithKeyboard(
                                            $event,
                                            'maxRestingTime'
                                          )
                                        "
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div class="row">
                                  <div class="col-sm-6">
                                    <h6 class="fs-5 mb-2 mt-0 inline-label">
                                      {{
                                        translationsLib.get(
                                          'irrigation_program_min_resting_time'
                                        )
                                      }}
                                    </h6>
                                  </div>
                                  <div class="col-sm-6">
                                    <div class="my-2">
                                      <ng-template #minRestingTimePicker>
                                        <timepicker
                                          appOnlyNumbers
                                          [showMeridian]="false"
                                          [showSeconds]="true"
                                          [minuteStep]="1"
                                          [secondsStep]="1"
                                          [formControlName]="'minRestingTime'"
                                        ></timepicker>
                                      </ng-template>
                                      <input
                                        appOnlyNumbers
                                        [ngClass]="{
                                          'ng-dirty':
                                            programForm.controls.minRestingTime
                                              .dirty
                                        }"
                                        class="form-control text-center"
                                        type="text"
                                        [popover]="minRestingTimePicker"
                                        [outsideClick]="true"
                                        placement="bottom"
                                        [value]="
                                          programForm.value.minRestingTime
                                            | inputTime
                                        "
                                        (change)="
                                          onChangeInputTimeWithKeyboard(
                                            $event,
                                            'minRestingTime'
                                          )
                                        "
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </ng-template>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          [style.order]="getCardOrder('deactivation')"
          cdkDrag
          [cdkDragDisabled]="isDragItemDisabled"
          class="accordion"
          id="stopAccordion"
          [ngClass]="!isDragItemDisabled ? 'drag-drop-border' : ''"
          *ngIf="layoutConfiguration.blocks.deactivation.show"
        >
          <div class="accordion-item">
            <h2 class="accordion-header" id="stopAccordionHeading">
              <button
                class="accordion-button fs-2 irrigation-accordion-button"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#stopCollapse"
                aria-expanded="true"
                aria-controls="stopCollapse"
              >
                <div class="accordion-title-div">
                  <span class="float-end">
                    {{ translationsLib.get('irrigation_program_deactivation') }}
                  </span>
                </div>
              </button>
            </h2>
            <div
              id="stopCollapse"
              class="accordion-collapse collapse show"
              aria-labelledby="stopAccordionHeading"
              data-bs-parent="#stopAccordion"
            >
              <div class="accordion-body custom-accordion-body">
                <div class="mb-2">
                  <ng-template [ngIf]="mode === 'simple'">
                    <form [formGroup]="programForm" class="simple-form">
                      <div class="mb-2">
                        <div class="card">
                          <div class="card-body custom-card-body">
                            <div class="row">
                              <div class="col-sm-5 col-md-5">
                                <h6 class="fs-2">
                                  {{
                                    translationsLib.get(
                                      'irrigation_program_priority'
                                    )
                                  }}
                                </h6>
                                <p class="fs-5 fw-lighter">
                                  {{
                                    translationsLib.get(
                                      'irrigation_program_priority_help'
                                    ) | sanitizeText
                                  }}
                                </p>
                              </div>
                              <div
                                class="col-sm-7 col-md-7 col-inputs align-self-center"
                              >
                                <div class="row">
                                  <div class="col-12 col-sm-6 col-lg-9">
                                    <h6 class="fs-5">
                                      {{
                                        translationsLib.get(
                                          'irrigation_program_priority'
                                        )
                                      }}
                                    </h6>
                                  </div>
                                  <div class="col-12 col-sm-6 col-lg-3">
                                    <div class="input-group mb-2">
                                      <select
                                        class="form-select"
                                        formControlName="priority"
                                      >
                                        <option
                                          [value]="priority.value"
                                          *ngFor="
                                            let priority of prioritiesList
                                          "
                                        >
                                          {{ priority.name }}
                                        </option>
                                      </select>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="card">
                          <div class="card-body custom-card-body">
                            <div class="row">
                              <div class="col-sm-5 col-md-5">
                                <h6 class="fs-2">
                                  {{
                                    translationsLib.get(
                                      'irrigation_program_stop_reason'
                                    )
                                  }}
                                </h6>
                                <p class="fs-5 fw-lighter">
                                  {{
                                    translationsLib.get(
                                      'irrigation_program_stop_reason_help'
                                    ) | sanitizeText
                                  }}
                                </p>
                              </div>
                              <div class="col-sm-7 col-md-7 col-inputs">
                                <div class="row">
                                  <div class="col-8">
                                    <h6 class="fs-5">
                                      {{
                                        translationsLib.get(
                                          'irrigation_program_frost_stop'
                                        )
                                      }}
                                    </h6>
                                  </div>
                                  <div class="col-4">
                                    <div class="input-group my-2">
                                      <div class="form-check form-switch">
                                        <input
                                          formControlName="stopFrost"
                                          class="form-check-input"
                                          type="checkbox"
                                          role="switch"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div class="row">
                                  <div class="col-8">
                                    <h6 class="fs-5">
                                      {{
                                        translationsLib.get(
                                          'irrigation_program_rain_stop'
                                        )
                                      }}
                                    </h6>
                                  </div>
                                  <div class="col-4">
                                    <div class="input-group my-2">
                                      <div class="form-check form-switch">
                                        <input
                                          formControlName="stopRain"
                                          class="form-check-input"
                                          type="checkbox"
                                          role="switch"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div class="row">
                                  <div class="col-8">
                                    <h6 class="fs-5">
                                      {{
                                        translationsLib.get(
                                          'irrigation_program_storm_stop'
                                        )
                                      }}
                                    </h6>
                                  </div>
                                  <div class="col-4">
                                    <div class="input-group my-2">
                                      <div class="form-check form-switch">
                                        <input
                                          formControlName="stopStorm"
                                          class="form-check-input"
                                          type="checkbox"
                                          role="switch"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </ng-template>
                  <ng-template [ngIf]="mode === 'advanced'">
                    <form [formGroup]="programForm" class="advanced-form">
                      <div class="mb-2">
                        <div class="row">
                          <div class="col-md-6">
                            <div class="card">
                              <div class="card-body custom-card-body">
                                <div class="row">
                                  <div
                                    class="col-12 col-sm-6 col-lg-9 align-self-center mb-md-0"
                                  >
                                    <h6 class="fs-2 input-label">
                                      {{
                                        translationsLib.get(
                                          'irrigation_program_priority'
                                        )
                                      }}
                                    </h6>
                                  </div>
                                  <div class="col-12 col-sm-6 col-lg-3">
                                    <div class="input-group mb-2">
                                      <select
                                        class="form-select"
                                        formControlName="priority"
                                      >
                                        <option
                                          [value]="priority.value"
                                          *ngFor="
                                            let priority of prioritiesList
                                          "
                                        >
                                          {{ priority.name }}
                                        </option>
                                      </select>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="card">
                              <div class="card-body custom-card-body">
                                <div class="row">
                                  <div class="col-md-12">
                                    <h6 class="fs-2 mb-05">
                                      {{
                                        translationsLib.get(
                                          'irrigation_program_stop_reason'
                                        )
                                      }}
                                    </h6>
                                  </div>
                                </div>
                                <div class="row">
                                  <div class="col-sm-12 col-md-12">
                                    <div class="row">
                                      <div class="col-8">
                                        <h6 class="fs-5">
                                          {{
                                            translationsLib.get(
                                              'irrigation_program_frost_stop'
                                            )
                                          }}
                                        </h6>
                                      </div>
                                      <div class="col-4">
                                        <div class="input-group my-2">
                                          <div class="form-check form-switch">
                                            <input
                                              formControlName="stopFrost"
                                              class="form-check-input"
                                              type="checkbox"
                                              role="switch"
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div class="row">
                                  <div class="col-sm-12 col-md-12">
                                    <div class="row">
                                      <div class="col-8">
                                        <h6 class="fs-5">
                                          {{
                                            translationsLib.get(
                                              'irrigation_program_rain_stop'
                                            )
                                          }}
                                        </h6>
                                      </div>
                                      <div class="col-4">
                                        <div class="input-group my-2">
                                          <div class="form-check form-switch">
                                            <input
                                              formControlName="stopRain"
                                              class="form-check-input"
                                              type="checkbox"
                                              role="switch"
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div class="row">
                                  <div class="col-sm-12 col-md-12">
                                    <div class="row">
                                      <div class="col-8">
                                        <h6 class="fs-5">
                                          {{
                                            translationsLib.get(
                                              'irrigation_program_storm_stop'
                                            )
                                          }}
                                        </h6>
                                      </div>
                                      <div class="col-4">
                                        <div class="input-group my-2">
                                          <div class="form-check form-switch">
                                            <input
                                              formControlName="stopStorm"
                                              class="form-check-input"
                                              type="checkbox"
                                              role="switch"
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </ng-template>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="mb-4"></div>
    </div>
  </div>
</ion-content>

<footer
  *ngIf="!isProgramsWithoutData && !isFetchingTerminalData"
  class="footer mt-auto px-4 py-3 bg-light-soft border-top-3 border-light border global-irrigation-save-button"
>
  <div
    *ngIf="!terminalData?.authed_user_can_write && saveButtonTimeElapsed"
    class="row"
  >
    <div class="col">
      <app-cannot-write-alert></app-cannot-write-alert>
    </div>
  </div>
  <div class="d-flex justify-content-end" *ngIf="!saveButtonTimeElapsed">
    <div class="spinner-border text-warning" role="status">
      <span class="visually-hidden">{{
        translationsLib.get('climate_loading')
      }}</span>
    </div>
  </div>
  <div
    *ngIf="terminalData?.authed_user_can_write && saveButtonTimeElapsed"
    class="row"
  >
    <div
      class="col irrigation-save-button"
      [ngClass]="{ 'p-0': !hasScrollbar }"
    >
      <button
        (click)="submitForm()"
        [disabled]="
          !this.programForm.dirty &&
          !this.isGroupDirty &&
          !this.isFormulaDirty &&
          !this.isMixtureDirty &&
          !this.dateTimeDirty &&
          !this.isGroupChipDirty &&
          !this.dateTimeDirty &&
          !this.isDaysPickerDirty &&
          !this.isDirty
        "
        class="btn btn-primary float-end"
        [ngClass]="{
          'btn-danger':
            this.programForm.dirty ||
            this.isGroupDirty ||
            this.isFormulaDirty ||
            this.isMixtureDirty ||
            this.dateTimeDirty ||
            this.isGroupChipDirty ||
            this.dateTimeDirty ||
            this.isDaysPickerDirty ||
            this.isDirty
        }"
      >
        <span
          *ngIf="isFormSubmitted"
          class="spinner-border spinner-border-sm"
          role="status"
          aria-hidden="true"
        ></span>
        {{
          isFormSubmitted
            ? this.translationsLib.get('saving')
            : this.translationsLib.get('irrigation_save_program')
        }}
      </button>
    </div>
  </div>
</footer>
