import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TerminalsService } from '../terminals.service';
import { FormBuilder } from '@angular/forms';
import { HelpersLibService } from '../../services/libraries/helpers-lib.service';
import { SessionLibService } from '@nutricontrol/app360-shared';
import { TranslationsLibService } from '@nutricontrol/app360-shared';
import { TerminalsLibService } from '../../services/libraries/terminals-lib.service';
import { PlatformLibService } from '@nutricontrol/app360-shared';
import Swal from 'sweetalert2';
import * as moment from 'moment/moment';
import { environment } from '../../../environments/environment';
import { Breadcrumb } from '../../farming/irrigation/shared/shared.model';

@Component({
  selector: 'app-terminal-activation',
  templateUrl: './terminal-activation.component.html',
  styleUrls: ['./terminal-activation.component.scss'],
})
export class TerminalActivationComponent implements OnInit {
  vid;
  terminalData;
  createdAt = '';
  serialNumber = '';
  terminalModel = '';
  terminalImg;
  timezone = '';
  warp;
  activationStatus;
  fb: FormBuilder = new FormBuilder();
  fillData = this.fb.group({
    terminalName: [''],
    coordinates: [],
  });
  markerCoordinates;
  interval;
  progress;
  deviceStatus;
  lastConexion;
  cancelled;
  breadcrumbs: Breadcrumb[] = [];
  isNative = false;
  loading = true;
  constructor(
    public router: Router,
    private route: ActivatedRoute,
    private terminalService: TerminalsService,
    private helpersLib: HelpersLibService,
    private session: SessionLibService,
    public translationsLib: TranslationsLibService,
    private terminalsLib: TerminalsLibService,
    private platformLib: PlatformLibService
  ) {}

  ngOnInit() {
    this.isNative = this.platformLib.isNative();
    this.breadcrumbs = [
      {
        text: this.translationsLib.get('terminal_activation_title'),
        disabled: false,
        to: ' ',
        active: true,
      },
    ];
    this.vid = this.route.snapshot.paramMap.get('vid');
    this.activationStatus = 0;
    this.cancelled = 0;
    this.progress = '0';
    this.getTerminalInfo();
    this.helpersLib.setTitle(
      this.translationsLib.get('terminal_activation_title'),
      this.vid,
      null
    );
    this.helpersLib.sendEventPageView('Activation');
    setInterval(() => {
      this.getConnectionStatus();
    }, environment.intervalDefaultTimeout);
  }

  getTerminalInfo() {
    this.terminalService.getTerminal(this.vid).subscribe((res) => {
      this.terminalData = res;
      this.terminalImg = this.terminalData.image_path;
      this.serialNumber = this.terminalData.vid;
      this.terminalModel = this.terminalData.pretty_type_name;
      this.warp = this.terminalData.warp;

      this.getTerminalApiInfo();
    });
  }

  getConnectionStatus() {
    const data = {
      method: 'get',
      terminals_vid: [this.vid],
    };
    this.terminalService.getTerminalsApiInfo(data).subscribe((res) => {
      this.lastConexion = (moment(res[0].systemHour)).format('DD-MM-YYYY HH:mm:ss');
      if (res[0].connected === 1 || res[0].connected === true) {
        this.deviceStatus = true;
      } else {
        this.deviceStatus = false;
      }
    });
  }
  getTerminalApiInfo() {
    const data = {
      method: 'get',
      terminals_vid: [this.vid],
    };
    this.terminalService.getTerminalsApiInfo(data).subscribe((res) => {
      this.lastConexion = (moment(res[0].systemHour)).format('DD-MM-YYYY HH:mm:ss');
      if (res[0].connected === 1 || res[0].connected === true) {
        this.deviceStatus = true;
      } else {
        this.deviceStatus = false;
      }

      if (
        this.terminalData.latitude !== null &&
        this.terminalData.longitude !== null
      ) {
        this.markerCoordinates = {
          lat: this.terminalData.latitude,
          lng: this.terminalData.longitude,
        };
      } else {
        this.markerCoordinates = null;
      }

      this.fillData.setValue({
        terminalName: this.terminalData.name,
        coordinates:
          this.terminalData.latitude + ',' + this.terminalData.longitude,
        //timeZone: res[0].timezone,
      });
      this.timezone = res[0].timezone;
      this.createdAt = res[0].firstCommunication;

      this.loading = false;
    });
  }

  pickUpData() {
    this.activationStatus = 0.5;
    this.terminalService.getPickUpConfig(this.vid).subscribe(res=>{
        this.activationStatus = 1;
        const element = document.getElementById('activation');
        element.style.opacity = '0.5';
        this.postActivation();
        this.interval = setInterval(() => {
          this.getConfigStatus();
        }, Math.round(environment.intervalDefaultTimeout / 2));
      },
      (error) => {
        this.activationStatus = 0;
        Swal.fire({
          text: this.translationsLib.get('terminal_activation_error'),
          icon: 'success',
          confirmButtonText: this.translationsLib.get('accept'),
        });
      }
    );
  }
  cancelPickUp() {
    this.activationStatus = 0;
    const element = document.getElementById('activation');
    element.style.opacity = '1';
    this.cancelled = 1;
  }
  getConfigStatus() {
    this.terminalService.getConfigStatus(this.vid).subscribe((res) => {
      if (res.percentage >= 100) {
        clearInterval(this.interval);
      }
      if (isNaN(res.percentage)) {
        this.progress = '-';
      } else {
        this.progress = (Math.round(res.percentage * 100) / 100).toFixed(0);
      }
    });
  }

  saveTerminalChanges() {
    const coordenadas = this.fillData.value.coordinates.split(',');
    const data = {
      name: this.fillData.value.terminalName,
      latitude: Number(coordenadas[0]),
      longitude: Number(coordenadas[1]),
    };
    this.terminalService.putUpdateTerminal(data, this.vid).subscribe(res=>{
      Swal.fire({
        text: this.translationsLib.get('save_changes_success'),
        icon: 'success',
        confirmButtonText: this.translationsLib.get('accept'),
        confirmButtonColor: '#224261',
      });
    });
  }

  postActivation() {
    this.terminalService.postActivation(this.vid).subscribe((res) => {
      console.log(res);
    });
  }

  patchValues(event) {
    this.fillData.patchValue({
      coordinates: event,
    });
  }
}
