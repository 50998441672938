import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { TranslationsLibService } from '@nutricontrol/app360-shared';
import {IonicModule, IonModal} from '@ionic/angular';
import { GraphicsService } from '../graphics.service';
import { MegapopComponent } from '../../megapop-common/megapop/megapop.component';
import Swal from 'sweetalert2';
import {FormsModule} from "@angular/forms";
import {MatExpansionModule} from "@angular/material/expansion";
import {NgForOf} from "@angular/common";

@Component({
  standalone: true,
  selector: 'app-metrics-saved',
  templateUrl: './metrics-saved.component.html',
  styleUrls: ['./metrics-saved.component.scss'],
  imports: [
    IonicModule,
    FormsModule,
    MatExpansionModule,
    NgForOf
  ]
})
export class MetricsSavedComponent implements OnInit {
  @Input() metrics: any;
  @ViewChild(IonModal) modal: IonModal;
  panelOpenState = true;
  metricVid;
  name;
  constructor(
    public translationsLib: TranslationsLibService,
    private graphicsService: GraphicsService,
    private megapop: MegapopComponent
  ) {}

  ngOnInit() {}

  openModal(name, vid) {
    this.name = name;
    this.metricVid = vid;
    this.modal.present();
  }
  putMetric() {
    this.graphicsService
      .putSavedMetric({ name: this.name }, this.metricVid)
      .subscribe(
        (res) => {
          Swal.fire({
            icon: 'success',
            text: this.translationsLib.get(
              'graphics_metric_modified_succesfully'
            ),
            confirmButtonText: this.translationsLib.get('accept'),
          }).then((result) => {
            if (result.isConfirmed || result.isDismissed) {
              this.name = '';
              this.metricVid = '';
              this.megapop.getMetrics();
              this.modal.dismiss();
            }
          });
        },
        (error) => {
          Swal.fire({
            icon: 'error',
            text: this.translationsLib.get('graphics_error'),
            confirmButtonText: this.translationsLib.get('accept'),
          }).then((result) => {
            if (result.isConfirmed || result.isDismissed) {
              this.megapop.getMetrics();
              this.modal.dismiss();
            }
          });
        }
      );
  }
  deleteMetric(vid) {
    Swal.fire({
      icon: 'warning',
      text: this.translationsLib.get('graphics_deleting_metrics'),
      confirmButtonColor: '#FF0000',
      showCancelButton: true,
    }).then((res) => {
      if (res.isConfirmed) {
        this.graphicsService.deleteSavedMetric(vid).subscribe(
          (res) => {
            Swal.fire({
              icon: 'success',
              text: this.translationsLib.get('graphics_metrics_deleted'),
            });
            this.megapop.getMetrics();
            this.modal.dismiss();
          },
          (error) => {
            Swal.fire({
              icon: 'error',
              text: this.translationsLib.get('graphics_error'),
              confirmButtonText: this.translationsLib.get('accept'),
            }).then((result) => {
              if (result.isConfirmed || result.isDismissed) {
                this.megapop.getMetrics();
                this.modal.dismiss();
              }
            });
          }
        );
      }
    });
  }
}
