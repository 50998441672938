import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { IrrigationService } from '../../../irrigation.service';
import Swal from 'sweetalert2';
import { ActivatedRoute, Router } from '@angular/router';
import {
  removeDirtyInputs,
  secondsInputToDate,
  DateToSecondsInput,
  saveDataAndShowModal,
  saveSubmitWithTerminalStatus,
} from '../../../../../commons/helpers/functions';
import { DrainageTrayModel } from '../../../irrigation.model';
import { TranslationsLibService } from '@nutricontrol/app360-shared';
import { EquipmentsLibService } from '../../../../../services/libraries/equipments-lib.service';
import { Subscription } from 'rxjs';
import { FormFieldHandlerService } from '../../../../libraries/form-field-handler.service';
import { environment } from '../../../../../../environments/environment';
import { InputNumberService } from '../../../../libraries/input-number.service';

@Component({
  selector: 'app-drainages-v3-form',
  templateUrl: './drainages-v3-form.component.html',
  styleUrls: ['./drainages-v3-form.component.scss'],
})
export class DrainagesV3FormComponent implements OnInit, OnDestroy {
  isFetchingData = false;
  isFormSubmitted = false;
  drainagesV3Form: UntypedFormGroup;
  selectedPagination;
  private formFieldErrorSubscription: Subscription;
  @Output() formFieldErrorEventEmitter = new EventEmitter<boolean>();
  drainageTraysAsNumbersArray: number[] = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 255];
  isDirty = false;
  drainagesV3Response;
  isConnected: boolean;
  private intervalId: any;
  drainageTraysList: DrainageTrayModel[];
  @Input() mode: string;
  @Input() terminal_vid: string;
  isDrainagesV3WithoutData = false;
  @Input() drainage_v3_id: number;
  @Output() dirtyEventEmitter = new EventEmitter<boolean>();
  @Output() nameEventEmitter = new EventEmitter<number>();
  @Output() drainageEventEmitter = new EventEmitter<any>();

  isErrorInDrainagesV3Post = false;

  constructor(
    private fb: UntypedFormBuilder,
    private irrigationService: IrrigationService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    public translationsLib: TranslationsLibService,
    private equipmentLib: EquipmentsLibService,
    private formFieldHandlerService: FormFieldHandlerService,
    public inputNumberService: InputNumberService
  ) {
    this.drainagesV3Form = this.fb.group({
      drainage: [null, Validators.required],
      dirty: [''],
      drainingTray: [null, Validators.required],
      idealDraining: [null, Validators.required],
      minDuration: [null, Validators.required],
      maxDuration: [null, Validators.required],
      duration: [null, Validators.required],
      minDurationFirstWatering: [null, Validators.required],
      maxDurationFirstWatering: [null, Validators.required],
      firstWateringDuration: [null, Validators.required],
      controlRadiation: [null, Validators.required],
      startingRadiation: [null, Validators.required],
      minRadiation: [null, Validators.required],
      tolerance: [null, Validators.required],
      wattsDrain: [null, Validators.required],
      isDirty: false,
    });
  }

  ngOnInit() {
    this.drainagesV3Form.reset(); // Prevent unsaved data to persist in form inputs

    this.activatedRoute.params.subscribe((_) => {
      this.getDrainagesV3List();

      removeDirtyInputs(this.drainagesV3Form);
    });

    this.drainagesV3Form.valueChanges.subscribe((_) => {
      this.dirtyEventEmitter.emit(this.getIsDirty());
    });

    this.getIrrigationEquipment();

    this.intervalId = setInterval(async () => {
      await this.getIrrigationEquipment();

      if (!this.isConnected) {
        this.equipmentLib.showConnectivityAlert();
      }

      this.isFormSubmitted = false;
    }, environment.intervalDefaultTimeout);
  }

  getIsDirty() {
    return this.drainagesV3Form.dirty || this.drainagesV3Form.value.isDirty;
  }

  setDirty(dirty: boolean) {
    this.drainagesV3Form.patchValue({ isDirty: dirty });
  }

  getIrrigationEquipment() {
    this.irrigationService
      .getIrrigationTerminal(this.terminal_vid)
      .subscribe((res) => {
        this.isConnected = res.connected;
      });
  }

  getDrainagesV3List() {
    this.isFetchingData = true;

    this.irrigationService.getDrainages(this.terminal_vid).subscribe((res) => {
      // @ts-ignore
      if (res.drainages.length === 0) {
        this.isDrainagesV3WithoutData = true;
        this.isFetchingData = false;
      } else {
        if (res.version === 3) {
          this.drainagesV3Response = res.drainages;

          this.drainagesV3Response.filter((drainage) => {
            if (drainage.drainage === this.drainage_v3_id) {
              this.drainageEventEmitter.emit(drainage);

              this.nameEventEmitter.emit(drainage.drainage);
              this.selectedPagination = drainage.drainage;

              this.drainagesV3Form.patchValue({
                drainage: drainage.drainage,
                dirty: drainage.dirty,
                drainingTray: drainage.drainingTray,
                idealDraining: drainage.idealDraining,
                minDuration: secondsInputToDate(drainage.minDuration),
                maxDuration: secondsInputToDate(drainage.maxDuration),
                duration: secondsInputToDate(drainage.duration),
                minDurationFirstWatering: secondsInputToDate(
                  drainage.minDurationFirstWatering
                ),
                maxDurationFirstWatering: secondsInputToDate(
                  drainage.maxDurationFirstWatering
                ),
                firstWateringDuration: secondsInputToDate(
                  drainage.firstWateringDuration
                ),
                controlRadiation: drainage.controlRadiation,
                startingRadiation: drainage.startingRadiation,
                minRadiation: drainage.minRadiation,
                tolerance: drainage.tolerance,
                wattsDrain: drainage.wattsDrain,
              });

              this.isFetchingData = false;
            }
          });
        } else {
          this.isFetchingData = false;
        }
      }
    });
  }

  confirmSave() {
    Swal.fire({
      title: this.translationsLib.get('cannot_be_undone1'),
      text: this.translationsLib.get('irrigation_program_context_warning'),
      showDenyButton: true,
      showCancelButton: true,
      confirmButtonText: this.translationsLib.get('accept'),
      denyButtonText: this.translationsLib.get('cancel'),
    }).then((result) => {
      if (result.isConfirmed) {
        this.submitForm();
      } else if (result.isDenied) {
        Swal.fire(this.translationsLib.get('something_was_wrong'), '', 'info');
      }
    });
  }

  handlePost(showModal: boolean, connected: boolean): Promise<boolean> {
    return new Promise<boolean>((resolve, _) => {
      this.isFormSubmitted = true;

      const drainagesV3FormAsArray = [];
      drainagesV3FormAsArray.push({
        drainage: this.drainagesV3Form.value.drainage,
        drainingTray: Number(this.drainagesV3Form.value.drainingTray),
        idealDraining: this.drainagesV3Form.value.idealDraining,
        minDuration: DateToSecondsInput(this.drainagesV3Form.value.minDuration),
        maxDuration: DateToSecondsInput(this.drainagesV3Form.value.maxDuration),
        duration: DateToSecondsInput(this.drainagesV3Form.value.duration),
        minDurationFirstWatering: DateToSecondsInput(
          this.drainagesV3Form.value.minDurationFirstWatering
        ),
        maxDurationFirstWatering: DateToSecondsInput(
          this.drainagesV3Form.value.maxDurationFirstWatering
        ),
        firstWateringDuration: DateToSecondsInput(
          this.drainagesV3Form.value.firstWateringDuration
        ),
        controlRadiation: this.drainagesV3Form.value.controlRadiation,
        startingRadiation: this.drainagesV3Form.value.startingRadiation,
        minRadiation: this.drainagesV3Form.value.minRadiation,
        tolerance: this.drainagesV3Form.value.tolerance,
        wattsDrain: this.drainagesV3Form.value.wattsDrain,
      });

      try {
        this.irrigationService
          .postDrainagesV3(this.terminal_vid, drainagesV3FormAsArray)
          .subscribe(
            (res) => {
              if (res.error) this.isErrorInDrainagesV3Post = true;

              if (
                connected &&
                !this.drainagesV3Form.dirty &&
                !this.isErrorInDrainagesV3Post
              ) {
                saveDataAndShowModal(
                  res,
                  showModal,
                  this.translationsLib.get('error_updating_data'),
                  this.translationsLib.get('save_changes_success'),
                  this.translationsLib.get('accept')
                );

                this.isFormSubmitted = false;
                resolve(true);
              } else if (this.isErrorInDrainagesV3Post) {
                Swal.fire({
                  text: this.translationsLib.get(
                    'irrigation_general_program_error'
                  ),
                  showConfirmButton: true,
                  confirmButtonText: this.translationsLib.get('accept'),
                  icon: 'error',
                });

                this.isFormSubmitted = false;
                resolve(true);
              } else {
                saveSubmitWithTerminalStatus(
                  res,
                  showModal,
                  connected,
                  this.translationsLib.get('error_updating_data'),
                  this.translationsLib.get('save_changes_success'),
                  this.translationsLib.get('accept'),
                  this.translationsLib.get(
                    'irrigation_disconnected_terminal_pending_changes'
                  )
                );

                this.isFormSubmitted = false;
                resolve(true);
              }
            },
            (_) => {
              if (showModal) {
                Swal.fire({
                  text: this.translationsLib.get('something_was_wrong'),
                  showConfirmButton: true,
                  confirmButtonText: this.translationsLib.get('accept'),
                  icon: 'error',
                });
              }
              this.isFormSubmitted = false;
              resolve(false);
            }
          );
      } catch (err) {
        throw new Error(err.message);
      }
    });
  }

  submitForm(
    showModal: boolean = true
  ): Promise<{ result: boolean; connected: boolean }> {
    return new Promise<{ result: boolean; connected: boolean }>(
      (resolve, reject) => {
        try {
          this.irrigationService
            .getIrrigationTerminal(this.terminal_vid)
            .subscribe((res) => {
              this.handlePost(showModal, res.connected).then((result) => {
                resolve({ result, connected: res.connected });
              });
            });
        } catch (err) {
          reject({ result: false, connected: false });
        } finally {
          // Remove dirty inputs due to use standalone ngModels
          removeDirtyInputs(this.drainagesV3Form);

          this.drainagesV3Form.markAsUntouched();

          this.drainagesV3Form.markAsPristine();
          this.drainagesV3Form.patchValue({ isDirty: false });
        }
      }
    );
  }

  ngOnDestroy(): void {
    clearInterval(this.intervalId);
    this.formFieldErrorSubscription?.unsubscribe();
  }
}
