<lib-loader *ngIf="loader === true"></lib-loader>
<!--<app-loader *ngIf="ready === false"></app-loader>-->
<ng-container  *ngIf="ready === true">
  <section class="reports-main-subcomponent-container" *ngFor="let reportData of reportsData; index as i;">
    <div class="card pb-3">
      <div class="card-header">
        <!-- Title -->
        <h2 class="card-header-title mb-3">
          {{ reportData.terminal.terminal_name }} - {{ reportData.terminal_vid }}
        </h2>
        <h2 class="card-header-subtitle">
          {{reportData.name}}
        </h2>
      </div>
      <div class="row mx-2 mt-3 print-no-padding">
        <div class="col-12 p-0 p-lg-2 alarms-container print-no-padding">
          <div class="alert alert-info mb-2" role="alert" *ngIf="reportData && reportData.data.length <= 0">
            {{ translationsLib.get('reports_no_data') }}
          </div>
        </div>
        <article *ngIf="reportData && reportData.data.length > 0">
          <div class="card">
            <div class="row">
              <div class="row">
                <h4 class="mb-1 mt-2 text-uppercase" style="color:#224261">{{translationsLib.get('reports_sensors_summary')}}</h4>
                <hr class="mx-2" style="padding-right: 0px">
              </div>
              <div class="col-6 col-lg-3">
                  <div class="row align-items-center">
                    <div class="col mb-2 text-uppercase data-header">
                      {{translationsLib.get('reports_sensor_name')}}
                    </div>
                  </div>
                  <div class="row align-items-center">
                    <div class="col-12 col-lg-9 data-value">
                      <span class="h2 mb-0"> {{this.reportTable[i].name}}</span>
                    </div>
                  </div>
              </div>
              <div class="col-6 col-lg-3">
                <div class="row align-items-center">
                  <div class="col mb-2 text-uppercase data-header">
                    {{translationsLib.get('graphics_min')}}
                  </div>
                </div>
                <div class="row align-items-center">
                  <div class="col-12  col-lg-9 data-value">
                    <span class="h2 mb-0"> {{this.reportTable[i].min}} {{this.reportTable[i].unit}}</span>
                  </div>
                </div>
              </div>
              <div class="col-6 col-lg-3">
                <div class="row align-items-center">
                  <div class="col mb-2 text-uppercase data-header">
                    {{translationsLib.get('graphics_max')}}
                  </div>
                </div>
                <div class="row align-items-center">
                  <div class="col-12 col-lg-9 data-value">
                    <span class="h2 mb-0"> {{this.reportTable[i].max}} {{this.reportTable[i].unit}}</span>
                  </div>
                </div>
              </div>
              <div class="col-6 col-lg-3">
                <div class="row align-items-center">
                  <div class="col mb-2 text-uppercase data-header">
                    {{translationsLib.get('graphics_media')}}
                  </div>
                </div>
                <div class="row align-items-center">
                  <div class="col-12 col-lg-9 data-value">
                    <span class="h2 mb-0"> {{this.reportTable[i].media}} {{this.reportTable[i].unit}}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <app-reports-raw-sensors-table
            [columnsToDisplay]="columnsToDisplay"
            [reportName]="'raw_sensors_report'"
            [data]="reportData.data"
            [count]="reportData.length"
            [requestData]=""
            [sensorName]= reportData.name
            [unit] = reportData.unit
            [requestData]="requestData"
          ></app-reports-raw-sensors-table>
        </article>
      </div>
    </div>
  </section>
</ng-container>

