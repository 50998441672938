export interface WaterSystemsFieldsConfig {
  activeConfig: WaterSystemsFieldConfig[][];
  inactiveConfig: WaterSystemsFieldConfig[][];
}

export interface WaterSystemsFieldConfig {
  field: string;
  type: WaterSystemFieldsConfigType;
  colSpan: number;
  rowSpan: number;
  colXsSpan: number;
  rowXsSpan: number;
}

export enum WaterSystemFieldsConfigType {
  card = 'card',
  cardDouble = 'cardDouble',
  cardTriple = 'cardTriple',
  progressBar = 'progressBar',
  chips = 'chips',
  cardFertilizer = 'cardFertilizer',
}

export const defaultFieldsConfig: WaterSystemsFieldsConfig = {
  activeConfig: [
    [
      {
        field: 'program',
        type: WaterSystemFieldsConfigType.card,
        colSpan: 2,
        rowSpan: 1,
        colXsSpan: 3,
        rowXsSpan: 1,
      },
      {
        field: 'group',
        type: WaterSystemFieldsConfigType.card,
        colSpan: 2,
        rowSpan: 1,
        colXsSpan: 3,
        rowXsSpan: 1,
      },
      {
        field: 'state',
        type: WaterSystemFieldsConfigType.card,
        colSpan: 2,
        rowSpan: 1,
        colXsSpan: 3,
        rowXsSpan: 1,
      },
      {
        field: 'activation',
        type: WaterSystemFieldsConfigType.card,
        colSpan: 2,
        rowSpan: 1,
        colXsSpan: 3,
        rowXsSpan: 1,
      },
      {
        field: 'flow',
        type: WaterSystemFieldsConfigType.card,
        colSpan: 2,
        rowSpan: 1,
        colXsSpan: 3,
        rowXsSpan: 1,
      },
      {
        field: 'pump',
        type: WaterSystemFieldsConfigType.card,
        colSpan: 2,
        rowSpan: 1,
        colXsSpan: 3,
        rowXsSpan: 1,
      },
    ],
    [
      {
        field: 'irrigationProgress',
        type: WaterSystemFieldsConfigType.progressBar,
        colSpan: 4,
        rowSpan: 1,
        colXsSpan: 6,
        rowXsSpan: 1,
      },
      {
        field: 'valves',
        type: WaterSystemFieldsConfigType.chips,
        colSpan: 4,
        rowSpan: 1,
        colXsSpan: 3,
        rowXsSpan: 1,
      },
      {
        field: 'readCE',
        type: WaterSystemFieldsConfigType.cardDouble,
        colSpan: 2,
        rowSpan: 1,
        colXsSpan: 3,
        rowXsSpan: 1,
      },
      {
        field: 'readPH',
        type: WaterSystemFieldsConfigType.card,
        colSpan: 2,
        rowSpan: 1,
        colXsSpan: 3,
        rowXsSpan: 1,
      },
    ],
    [
      {
        field: 'irrigationTime',
        type: WaterSystemFieldsConfigType.cardTriple,
        colSpan: 4,
        rowSpan: 1,
        colXsSpan: 6,
        rowXsSpan: 1,
      },
      {
        field: 'idealPH',
        type: WaterSystemFieldsConfigType.card,
        colSpan: 2,
        rowSpan: 1,
        colXsSpan: 3,
        rowXsSpan: 1,
      },
      {
        field: 'fertilizerFormula',
        type: WaterSystemFieldsConfigType.cardFertilizer,
        colSpan: 6,
        rowSpan: 1,
        colXsSpan: 6,
        rowXsSpan: 1,
      },
    ],
  ],
  inactiveConfig: [
    [
      {
        field: 'program',
        type: WaterSystemFieldsConfigType.card,
        colSpan: 3,
        rowSpan: 1,
        colXsSpan: 3,
        rowXsSpan: 1,
      },
      {
        field: 'readPH',
        type: WaterSystemFieldsConfigType.card,
        colSpan: 3,
        rowSpan: 1,
        colXsSpan: 3,
        rowXsSpan: 1,
      },
      {
        field: 'cE',
        type: WaterSystemFieldsConfigType.card,
        colSpan: 6,
        rowSpan: 1,
        colXsSpan: 6,
        rowXsSpan: 1,
      },
    ],
  ],
};
