<ng-container *ngIf="terminalVids.length === 0">
  <div class="card">
    <div class="py-0 card-body">
      <div class="row">
        <div class="col-12 col-xxl-6">
          <ng-select [items]="notificationTerminals"
                     bindLabel="name_vid"
                     groupBy="field_name"
                     placeholder="{{ translationsLib.get('terminal_terminal') }}"
                     [multiple]="true"
                     [closeOnSelect]="false"
                     [selectableGroup]="true"
                     [selectableGroupAsModel]="false"
                     [(ngModel)]="selectedTerminals"
                     (ngModelChange)="onSelectionModelChange($event)"
                     [maxSelectedItems]="20"
          ></ng-select>
        </div>
        <div class="col-12 col-xxl-6 mb-4 mb-xxl-0 d-flex flex-wrap justify-content-between">
          <button class="btn btn-light my-auto" [ngClass]="{'btn-selected': daysAgo === 1}" (click)="setStartingDate(1)">{{translationsLib.get('graphics_24h')}}</button>
          <button class="btn btn-light my-auto" [ngClass]="{'btn-selected': daysAgo === 2}" (click)="setStartingDate(2)">{{translationsLib.get('graphics_48h')}}</button>
          <button class="btn btn-light my-auto" [ngClass]="{'btn-selected': daysAgo === 7}" (click)="setStartingDate(7)">{{translationsLib.get('graphics_7d')}}</button>
          <button class="btn btn-light my-auto" [ngClass]="{'btn-selected': daysAgo === 30}" (click)="setStartingDate(30)">{{translationsLib.get('graphics_1m')}}</button>
        </div>
      </div>
    </div>
  </div>
  <div class="mb-0 card d-grid d-sm-flex">
    <div class="card-body p-0 overflow-auto">
      <div *ngIf="notifications.length === 0">
        <h4 class="m-4 text-center text-muted">{{ translationsLib.get('notification_no_notifications') }}</h4>
      </div>
      <table class="table table-responsive-sm mb-0 card-table overflow-hidden" *ngIf="notifications.length > 0">
        <thead>
          <tr>
            <th>
              {{ translationsLib.get('terminal_name') }}
            </th>
            <th class="d-none d-xl-table-cell">
              {{ translationsLib.get('terminal_serial_number') }}
            </th>
            <th class="w-50">
              {{ translationsLib.get('notification_message') }}
            </th>
            <th>
              {{ translationsLib.get('notification_sent_at') }}
            </th>
          </tr>
        </thead>
        <tbody class="list fw-medium">
          <tr *ngFor="let notification of notifications"
            (click)="clickTable(notification.terminal_vid)"
             style= "cursor: pointer"
          >
            <td>
              {{ notification.terminal_name }}
            </td>
            <td class="d-none d-xl-table-cell">
              {{ notification.terminal_vid }}
            </td>
            <td class="w-50">
              {{ notification.text }}
            </td>
            <td>
              {{ notification.send_at | transformDateTime }}
              <div class="d-inline-flex justify-content-center align-items-center fw-normal"
                *ngIf="!notification.response || !notification.response.multicast_id || notification.response.failure === 1"
              >
                <span class="virtual-text">{{translationsLib.get('notification_notifications_not_send')}}</span>
                <ion-icon
                  [name]="'VIRTUAL' | sensorIcon : '' : '' : true"
                  [matTooltip]="translationsLib.get('notification_notifications_not_send_explain')"
                  #tooltip="matTooltip" (click)="tooltip.toggle()"
                  matTooltipPosition="above"
                  class="virtual-icon align-self-center"
                ></ion-icon>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="terminalVids.length > 0">
  <div class="row m-0">
    <div class="col-12 col-sm-6">
      <h3 class="ms-1 text-muted">&nbsp;</h3>
    </div>
    <div class="col-12 col-sm-6 mb-4 mb-sm-0 d-flex flex-wrap justify-content-between">
      <button class="btn btn-light my-auto btn-sm" [ngClass]="{'btn-selected': daysAgo === 1}" (click)="setStartingDate(1)">{{translationsLib.get('graphics_24h')}}</button>
      <button class="btn btn-light my-auto btn-sm" [ngClass]="{'btn-selected': daysAgo === 2}" (click)="setStartingDate(2)">{{translationsLib.get('graphics_48h')}}</button>
      <button class="btn btn-light my-auto btn-sm" [ngClass]="{'btn-selected': daysAgo === 7}" (click)="setStartingDate(7)">{{translationsLib.get('graphics_7d')}}</button>
      <button class="btn btn-light my-auto btn-sm" [ngClass]="{'btn-selected': daysAgo === 30}" (click)="setStartingDate(30)">{{translationsLib.get('graphics_1m')}}</button>
    </div>
  </div>

  <div class="overflow-auto">
    <div *ngIf="notifications.length === 0">
      <h4 class="m-0 p-4 text-center text-muted" style="border-top: 1px solid #edf2f9">
        {{ translationsLib.get('notification_no_notifications') }}
      </h4>
    </div>
    <table class="table table-responsive-sm mb-0 overflow-hidden" *ngIf="notifications.length > 0">
      <thead>
      <tr>
        <th>
          {{ translationsLib.get('terminal_name') }}
        </th>
        <th class="d-none d-md-table-cell">
          {{ translationsLib.get('terminal_serial_number') }}
        </th>
        <th class="w-50">
          {{ translationsLib.get('notification_message') }}
        </th>
        <th>
          {{ translationsLib.get('notification_sent_at') }}
        </th>
      </tr>
      </thead>

      <tbody class="list">
      <tr *ngFor="let notification of notifications">
        <td>
          {{ notification.terminal_name }}
        </td>
        <td class="d-none d-md-table-cell">
          {{ notification.terminal_vid }}
        </td>
        <td class="w-50">
          {{ notification.text }}
        </td>
        <td>
          {{ notification.send_at | transformDateTime }}
          <div class="d-inline-flex justify-content-center align-items-center fw-normal"
               *ngIf="!notification.response || !notification.response.multicast_id || notification.response.failure === 1"
          >
            <span class="virtual-text">{{translationsLib.get('notification_notifications_not_send')}}</span>
            <ion-icon
              [name]="'VIRTUAL' | sensorIcon : '' : '' : true"
              [matTooltip]="translationsLib.get('notification_notifications_not_send_explain')"
              #tooltip="matTooltip" (click)="tooltip.toggle()"
              matTooltipPosition="above"
              class="virtual-icon align-self-center"
            ></ion-icon>
          </div>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</ng-container>
