import { Component, OnInit } from '@angular/core';
import { AuthService } from '../auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, Validators } from '@angular/forms';
import { HelpersLibService } from '../../services/libraries/helpers-lib.service';
import { TranslationsLibService } from '@nutricontrol/app360-shared';
import Swal from 'sweetalert2';
import { ConfirmationCodeModel } from '../auth.model';

@Component({
  selector: 'app-register-confirmation',
  templateUrl: './register-confirmation.component.html',
  styleUrls: ['./register-confirmation.component.scss'],
})
export class RegisterConfirmationComponent implements OnInit {
  identifier = '';
  resend = false;
  validate = true;
  private confirmationCode: ConfirmationCodeModel = {
    identifier: '',
    data: {
      confirmation_code: '',
    },
  };
  private fb: FormBuilder = new FormBuilder();
  // eslint-disable-next-line @typescript-eslint/member-ordering
  emailSend = this.fb.group({
    email: [
      '',
      [
        Validators.required,
        Validators.pattern(
          '^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,4}$'
        ),
      ],
    ],
  });
  constructor(
    private route: ActivatedRoute,
    private authService: AuthService,
    public router: Router,
    private helpersLib: HelpersLibService,
    public translationsLib: TranslationsLibService
  ) {}

  ngOnInit() {
    if (this.route.snapshot.paramMap.get('validated')) {
      this.validate =
        this.route.snapshot.paramMap.get('validated').toLowerCase() === 'true';
    }
    this.helpersLib.setTitle(
      '',
      this.translationsLib.get('auth_register_title'),
      'Registro'
    );
    this.identifier = this.route.snapshot.queryParams.identifier;
  }

  confirmRecentlyRegistration(code) {
    this.confirmationCode.identifier = this.identifier; // Comes from user registration
    if (typeof this.identifier !== 'string') {
      this.confirmationCode.identifier = this.emailSend.value.email; // Comes from user recover confirmation
    }
    if (typeof code !== 'string') {
      this.confirmCode();
    } else {
      // @ts-ignore
      this.confirmationCode.data.confirmation_code = code;
      this.confirmCode();
    }
  }
  confirmCode() {
    this.authService.confirmCode(this.confirmationCode).subscribe(
      (authResponse) => {
        Swal.fire({
          icon: 'success',
          text: this.translationsLib.get('auth_confirmed_email'),
          confirmButtonColor: '#224261',
        }).then((res) => {
          if (res.isDismissed || res.isConfirmed) {
            this.authService.doLogin(authResponse).subscribe(() => {
              window.location.href = '/home';
            });
          }
        });
      },
      (error) => {
        Swal.fire({
          icon: 'error',
          title: this.translationsLib.get('error'),
          text: this.translationsLib.get('auth_registration_code_error'),
          confirmButtonColor: '#224261',
        });
      }
    );
  }
  resendCode() {
    this.resend = false;

    this.authService.resendCodeEmail(this.emailSend.value.email).subscribe(
      (response) => {
        if (this.validate === false) {
          this.validate = true;
        }
        this.identifier = response.vid;
        Swal.fire({
          icon: 'success',
          text: this.translationsLib.get('auth_email_sent_success'),
          confirmButtonColor: '#224261',
        });
      },
      (error) => {
        if (
          error.error &&
          String(error.error).indexOf('user was confirmed before') >= 0
        ) {
          Swal.fire({
            icon: 'error',
            title: this.translationsLib.get('error'),
            text: this.translationsLib.get('auth_email_was_confirmed_before'),
            confirmButtonColor: '#224261',
          }).then((result) => {
            if (result.isConfirmed) {
              this.router.navigate(['/reset-password']);
            }
          });
        } else {
          Swal.fire({
            icon: 'error',
            title: this.translationsLib.get('error'),
            text: this.translationsLib.get('auth_email_error'),
            confirmButtonColor: '#224261',
          });
        }
      }
    );
  }
}
