import { Component, Input, OnInit } from '@angular/core';
import {
  TerminalDataModel,
  TranslationsLibService,
} from '@nutricontrol/app360-shared';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-equipment-temporary-disabled-data',
  templateUrl: './equipment-temporary-disabled-data.component.html',
  styleUrls: ['./equipment-temporary-disabled-data.component.scss'],
})
export class EquipmentTemporaryDisabledDataComponent implements OnInit {
  @Input() terminalData: TerminalDataModel;
  @Input() type: 'climate' | 'irrigation' | 'configurable' | 'datalogger';
  cardClass: any;
  baseURL = environment.backend;

  constructor(public translationsLib: TranslationsLibService) {}

  ngOnInit() {
    this.cardClass = this.getClass();
  }

  getClass() {
    return {
      'irrigation-card-border': this.type === 'irrigation',
      'climate-card-border': this.type === 'climate',
      'configurable-card-border': this.type === 'configurable',
      'datalogger-card-border': this.type === 'datalogger',
    };
  }

}
