import {
  AfterViewInit,
  Component,
  HostListener,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  FiltersIrrigationInstallerModel,
  Progfiltros,
  SafeData,
} from '../../../irrigation.model';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { Breadcrumb } from '../../../shared/shared.model';
import { IrrigationService } from '../../../irrigation.service';
import { FieldsService } from '../../../../../fields/fields.service';
import { ActivatedRoute, Router } from '@angular/router';
import { HelpersLibService } from '../../../../../services/libraries/helpers-lib.service';
import { map, startWith } from 'rxjs/operators';
import {
  TranslationsLibService,
  TerminalDataModel,
} from '@nutricontrol/app360-shared';
import { IonContent } from '@ionic/angular';

@Component({
  selector: 'app-filters-edit',
  templateUrl: './filters-edit.component.html',
  styleUrls: ['./filters-edit.component.scss'],
})
export class FiltersEditComponent implements OnInit, SafeData, AfterViewInit {
  isFetchingData = false;
  isFormFieldWithErrors = false;
  filter: any;

  filters: FiltersIrrigationInstallerModel[];
  filterControl = new FormControl<string | FiltersIrrigationInstallerModel>('');
  filteredOptions: Observable<FiltersIrrigationInstallerModel[]>;
  isFiltersWithoutData = false;

  filter_id: number;
  terminal_vid: string;
  breadcrumbs: Breadcrumb[] = [];
  filterValue: string;
  isDirty = false;
  layoutConfiguration: Progfiltros;
  mode = '';
  isFetchingEquipmentData = false;
  terminalData: TerminalDataModel;
  @ViewChild(IonContent, { static: false }) private content: IonContent;
  hasScrollbar = false;
  saveButtonTimeElapsed = false;

  constructor(
    private irrigationService: IrrigationService,
    private fieldsService: FieldsService,
    private route: ActivatedRoute,
    private router: Router,
    private helpersLib: HelpersLibService,
    private activatedRoute: ActivatedRoute,
    public translationsLib: TranslationsLibService
  ) {}

  /**
   * https://stackoverflow.com/questions/56882873/how-to-detect-if-ion-content-has-a-scrollbar/58579938#58579938
   * Apply CSS class based on vertical scroll (save button)
   */

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.checkForScrollbar();
  }

  async checkForScrollbar() {
    const scrollElement = await this.content.getScrollElement();
    this.hasScrollbar = scrollElement.scrollHeight > scrollElement.clientHeight;
  }

  isDataSaved(): boolean {
    return this.isDirty;
  }

  hasWritePermissions(): boolean {
    return this.terminalData.authed_user_can_write;
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.saveButtonTimeElapsed = true;
      this.checkForScrollbar();
    }, 3000);

    this.activatedRoute.paramMap.subscribe((_) => {
      this.helpersLib.setTitle(
        '',
        `${this.translationsLib.get('irrigation_filter')} ${
          this.filter_id + 1
        }`,
        null
      );
    });
  }

  async ngOnInit() {
    const [, , vid, , , , data] = this.router.url.split('/');
    this.terminal_vid = vid;
    this.filter_id = Number(data);

    await this.getLayoutConfiguration();
    this.generateBreadcrumbs();

    this.filteredOptions = this.filterControl.valueChanges.pipe(
      startWith(''),
      map((value) => {
        const formula = typeof value === 'string' ? value : value?.waterSystem;
        return formula ? this._filter(formula as string) : this.filters.slice();
      })
    );

    this.getFiltersList();
  }

  generateBreadcrumbs() {
    this.isFetchingEquipmentData = true;
    this.breadcrumbs = [];
    this.irrigationService.getTerminal(this.terminal_vid).subscribe((res) => {
      this.terminalData = res;
      if (res.field) {
        this.breadcrumbs.push({
          text: res.field.name,
          disabled: false,
          to: ['/field', { vid: res.field.vid }],
          active: false,
        });
      }

      this.breadcrumbs.push(
        {
          text: res.name,
          disabled: false,
          to: `/farming/${this.terminal_vid}/irrigation`,
          active: false,
        },
        {
          text: 'Configuración',
          disabled: false,
          to: `/farming/${this.terminal_vid}/irrigation/5`,
          active: false,
        },
        {
          text: this.filterValue
            ? this.filterValue
            : `${this.translationsLib.get('irrigation_water_system')} ${
                this.filter_id + 1
              }`,
          disabled: false,
          active: true,
        }
      );

      this.isFetchingEquipmentData = false;
    });
  }

  private _filter(filter: string): FiltersIrrigationInstallerModel[] {
    const filterValue = filter.toLowerCase();

    return this.filters.filter((option) =>
      (
        this.translationsLib.get('irrigation_filter') +
        ' ' +
        (option.waterSystem + 1).toString()
      )
        .toLowerCase()
        .includes(filterValue)
    );
  }

  onChangePagination(event) {
    this.router.navigate([
      `/farming/${this.terminal_vid}/irrigation/irrigation_installer/filters/${event.waterSystem}`,
    ]);
  }

  autocompleteDisplayFn(filter: FiltersIrrigationInstallerModel) {
    return filter && filter.waterSystem
      ? this.translationsLib.get('irrigation_filter') +
          ' ' +
          (filter.waterSystem + 1)
      : '';
  }

  getFiltersList() {
    this.isFetchingData = true;
    this.irrigationService
      .getIrrigationInstallerFilters(this.terminal_vid)
      .subscribe((res) => {
        this.filters = res.filter((ws) =>
          this.layoutConfiguration.available_id_cabezales.includes(
            ws.waterSystem.toString()
          )
        );

        if (this.filters.length === 0) this.isFiltersWithoutData = true;

        this.isFetchingData = false;
      });
  }

  async getLayoutConfiguration(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.irrigationService
        .getTerminalLayoutConfiguration(this.terminal_vid)
        .subscribe((res) => {
          this.layoutConfiguration = res.screens_configuration.progfiltros;

          resolve();
        }, reject);
    });
  }
}
