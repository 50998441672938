import {
  AfterViewInit,
  Component,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  DrainageReportDataModel,
  DrainageReportDataModelItems,
  ReportRequestData,
} from '../../reports.model';
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { FormControl, FormGroup } from '@angular/forms';
import { ReportsService } from '../../reports.service';
import {SessionLibService, TranslationsLibService} from '@nutricontrol/app360-shared';

@Component({
  selector: 'app-reports-drainages-table',
  templateUrl: './reports-drainages-table.component.html',
  styleUrls: ['./reports-drainages-table.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition(
        'expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')
      ),
    ]),
  ],
})
export class ReportsDrainagesTableComponent implements OnInit, AfterViewInit {
  @Input() data: DrainageReportDataModelItems[];
  // eslint-disable-next-line @typescript-eslint/no-inferrable-types
  @Input() tablePageItemNumber: number = 20;
  @Input() drainageColumnsToDisplay = [];
  @Input() count: number;
  @Input() reportName = '';
  @Input() requestData: ReportRequestData;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  expandedElement: DrainageReportDataModel | null;
  dataSource: MatTableDataSource<any>;
  ready = false;
  downloadPDF = false;
  filterValues = {
    drainage: '',
    tray: '',
    datetime: '',
  };
  filterForm = new FormGroup({
    drainage: new FormControl(),
    tray: new FormControl(),
    datetime: new FormControl(),
  });

  get drainage() {
    return this.filterForm.get('drainage');
  }
  get tray() {
    return this.filterForm.get('tray');
  }
  get datetime() {
    return this.filterForm.get('datetime');
  }
  constructor(
    public translationsLib: TranslationsLibService,
    public reportsService: ReportsService,
    public session: SessionLibService
  ) {}
  ngOnInit(): void {
    this.dataSource = new MatTableDataSource(this.data);
    // ready triggers ngIf directive and must stay in ngOnInit!!!!
    this.ready = true;
  }
  // Very important to load data on ngAfterViewInit, doing it on ngOnInit freezes the client for several seconds!
  // https://stackoverflow.com/questions/50283659/angular-6-mattable-performance-in-1000-rows
  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.formSubscribe();
    this.getFormsValue();
  }
  formSubscribe() {
    this.drainage.valueChanges.subscribe((drainageValue) => {
      this.filterValues.drainage = drainageValue;
      this.dataSource.filter = JSON.stringify(this.filterValues);
    });
    this.tray.valueChanges.subscribe((trayValue) => {
      this.filterValues.tray = trayValue;
      this.dataSource.filter = JSON.stringify(this.filterValues);
    });
    this.datetime.valueChanges.subscribe((datetimeValue) => {
      this.filterValues.datetime = datetimeValue;
      this.dataSource.filter = JSON.stringify(this.filterValues);
    });
  }
  getFormsValue() {
    this.dataSource.filterPredicate = (data, filter: string): boolean => {
      const searchString = JSON.parse(filter);
      const resultValue =
        data.drainage
          .toString()
          .trim()
          .toLowerCase()
          .indexOf(searchString.drainage.toLowerCase()) !== -1 &&
        data.tray
          .toString()
          .trim()
          .toLowerCase()
          .indexOf(searchString.tray.toLowerCase()) !== -1 &&
        data.dateTime
          .toString()
          .trim()
          .toLowerCase()
          .indexOf(searchString.datetime.toLowerCase()) !== -1;

      return resultValue;
    };
    this.dataSource.filter = JSON.stringify(this.filterValues);
  }

  exportToPDF() {
    this.downloadPDF = true;
    setTimeout(() => {
      this.downloadPDF = false;
    }, 4000);
  }
  getUnit(column) {
    let unit = '';
    switch (column) {
      case 'irrigatedLtr':
        unit = 'L';
        break;
      case 'drainedLtr':
        unit = 'L';
        break;
      case 'volAcumIrrig':
        unit = 'L';
        break;
      case 'volAcumDrain':
        unit = 'L';
        break;
      case 'drainagePercent':
        unit = '%';
        break;
      case 'accumulatedRadiation':
        unit = 'W/m2';
        break;
      case 'standby':
        unit = 'W/m2';
        break;
      case 'standbyDra':
        unit = '%';
        break;
      default:
        unit = '';
        break;
    }
    return unit;
  }
}
