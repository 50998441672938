import { Component, OnInit, Input } from '@angular/core';
import { TranslationsLibService } from '@nutricontrol/app360-shared';
import { MegapopComponent } from '../megapop/megapop.component';
import {MatChipsModule} from "@angular/material/chips";
import {IonicModule} from "@ionic/angular";
import {NgClass, NgForOf, NgIf} from "@angular/common";
import {DragDropModule} from "@angular/cdk/drag-drop";

@Component({
  standalone: true,
  selector: 'app-selected-sensors',
  templateUrl: './selected-sensors.component.html',
  styleUrls: ['./selected-sensors.component.scss'],
  imports: [
    MatChipsModule,
    IonicModule,
    NgClass,
    NgIf,
    NgForOf,
    DragDropModule
  ]
})
export class SelectedSensorsComponent implements OnInit {
  @Input() sensoresOrdenados;
  @Input() operation;
  @Input() viewMode;
  @Input() grouped;
  @Input() sensoresSelecteds;
  @Input() margin = 2;
  firstFive = [];
  restOfSensors;
  viewAll = true;
  constructor(
    public translationsLib: TranslationsLibService,
    public megapop: MegapopComponent
  ) {}

  ngOnInit() {
    /*const emitter: Observable<any> = of(this.sensoresSelecteds);
    if (this.grouped === true) {
      emitter.subscribe(() => {
        console.log('aqui');
        this.sensoresSelecteds = this.sensoresSelecteds.groupBy(
          this.sensoresSelecteds,
          (sensor) => sensor.deviceName
        );
        console.log(this.sensoresSelecteds);
      });
    }*/
    if (this.sensoresSelecteds.length > 5 && this.viewMode === 'megapop_edit') {
      this.firstFive.push(this.sensoresSelecteds[0]);
      this.firstFive.push(this.sensoresSelecteds[1]);
      this.firstFive.push(this.sensoresSelecteds[2]);
      this.firstFive.push(this.sensoresSelecteds[3]);
      this.firstFive.push(this.sensoresSelecteds[4]);
      this.restOfSensors = this.sensoresSelecteds.length - 5;
      this.viewAll = false;
    }
  }

  Number(ncomp: any) {
    if (ncomp) {
      return (
        this.translationsLib.get('graphics_comp_abreviate') +
        String(Number(ncomp) + 1)
      );
    } else {
      return '';
    }
  }
  changeSensor(sensorObj) {
    this.sensoresOrdenados.forEach((orden) => {
      orden.valor.forEach((sensor) => {
        if (sensor === sensorObj) {
          sensor.completed = false;
        }
      });
    });
    if (this.firstFive.length > 0) {
      this.firstFive.forEach((sensor) => {
        if (sensor === sensorObj) {
          sensor.completed = false;
        }
      });
    }
  }
}
