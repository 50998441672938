<div class="card">
  <div class="card-header" style="height: auto">
    <!-- Title -->
    <lib-terminal-header
      [equipment]="equipment"
      [link]="'/farming/' + equipment.serialNumber + '/climate'"
      [showStatusIcon]="true"
      [connected]="equipment.connected"
    ></lib-terminal-header>
  </div>
  <div class="row mx-2 mt-3">
    <div class="row">
      <app-climate-meteo-table
        *ngIf="equipment"
        [statesResponse]="statesResponse"
        [meteoResponse]="meteoResponse"
        [meteoStructureResponse]="meteoStructureResponse"
        [statesStructureResponse]="statesStructureResponse"
        [meteoVisibility]="meteoVisibility"
        [searchMeteoText]="searchMeteoText"
        [meteoArray]="meteoArray"
      >
      </app-climate-meteo-table>

      <app-terminal-placeholder-data
        *ngIf="terminalData && terminalEnabled === TERMINAL_ENABLED_TEMPORARY_DISABLED"
        [terminalType]="'climate'"
        style="height: 515px; overflow: hidden">
      </app-terminal-placeholder-data>
    </div>
  </div>
</div>
