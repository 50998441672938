import {Component, Input, OnInit} from '@angular/core';
import {TranslationsLibService} from "@nutricontrol/app360-shared";
import {ReportsService} from "../reports.service";
import {TimeLibService} from "../../services/libraries/time-lib.service";
import {CreatedReportModel, SensorReportType} from "../reports.model";
import {DataloggerService} from "../../farming/datalogger/datalogger.service";

@Component({
  selector: 'app-reports-graphics-sensors',
  templateUrl: './reports-graphics-sensors.component.html',
  styleUrls: ['./reports-graphics-sensors.component.scss'],
})
export class ReportsGraphicsSensorsComponent implements OnInit {

  @Input() createdReport: CreatedReportModel;
  @Input() type: SensorReportType;
  reportsData;
  loader = false;
  ready = false;
  requestData;
  keys: string[];
  orderedReport;
  downloadPDF = false;
  selectedTabIndex: number = 0;
  constructor(
    public translationsLib: TranslationsLibService,
    private reportsService: ReportsService,
    private timeLib: TimeLibService,
    private dataloggerService: DataloggerService,
  ) {}

  ngOnInit() {
    console.log(this.createdReport);
    this.loader = true;
    this.getReportData();
  }
  onTabChange(event: any): void {
    this.selectedTabIndex = event.index;
  }
  getReportData(){
    const terminals: string[] = [];
    this.createdReport.terminals.forEach((terminal) => {
      terminals.push(terminal.vid);
    });
    const data = {
      terminals_vid : terminals,
      sensors : this.createdReport.sensors,
    };
    const begin = this.timeLib.beginDate(
      this.createdReport.dateRange.start,
      0,
      'days'
    );
    const end = this.timeLib.endDate(
      this.createdReport.dateRange.end,
      0,
      'days'
    );
    this.requestData = {
      terminals_vid: terminals,
      sensors: [],
      type: 'sensors/graphics',
      begin,
      end,
      order_type: this.type,
    };
    this.reportsService
      .getSensorsGraphicsReport(data, this.type, begin, end, null)
      .subscribe((res) => {
        this.reportsData = res.sensors_reports;
        this.loader = false;
        this.orderedReport = {};
        res.sensors_reports.forEach( report => {
          if (this.type === 'terminal') {
            // Agrupar por terminal_vid
            const key = report.terminal_identity?.vid;
            if (!this.orderedReport[key]) {
              this.orderedReport[key] = {
                terminal: report.terminal_identity,
                data: []
              };
            }
            this.orderedReport[key].data.push(report);
          } else if (this.type === 'field') {
            // Agrupar por field_name
            if(report.field_identity === null){
              //al menos para que salga la cabecera si no tiene finca
              report.field_identity = {
                vid: null,
                latitude: null,
                longitude: null,
                country: null,
                state_name: null,
                geo_pending: null,
                name: this.translationsLib.get('devices_no_field'),
              };
            }
            const key = report.field_identity.vid;
            if (!this.orderedReport[key]) {
              this.orderedReport[key] = {
                field: report.field_identity || null,
                data: []
              };
            }
            this.orderedReport[key].data.push(report);
          } else if(this.type === 'mixed' || this.type === 'magnitudes') {
            const key = this.type;
            if (!this.orderedReport[key]) {
              this.orderedReport[key] = {
                name: this.translationsLib.get('reports_sensors_'+ this.type),
                data: []
              };
            }
            this.orderedReport[key].data.push(report);
          }
        });
        if (this.type === 'terminal') {
          // Añadir terminales faltantes con data vacío
          let terminalsNumber = terminals.length;
          const updateReport = () => {
            terminalsNumber--;
            if (terminalsNumber === 0) {
              this.keys = Object.keys(this.orderedReport);
              console.log(this.keys);
              this.ready = true;
            }
          };

          terminals.forEach((terminalKey) => {
            if (!this.orderedReport[terminalKey]) {
              this.dataloggerService
                .getDataloggerData(terminalKey)
                .subscribe((res) => {
                  this.orderedReport[terminalKey] = {
                    terminal: res,
                    data: [],
                  };
                  updateReport();
                });
            } else {
              updateReport();
            }
          });
        } else {
          this.keys = Object.keys(this.orderedReport);
          this.ready = true;
        }
      });
  }

  exportToPDF() {
    this.downloadPDF = true;
    setTimeout(() => {
      this.downloadPDF = false;
    }, 4000);
  }
}
