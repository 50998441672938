import { Injectable } from '@angular/core';
import { TranslationsLibService } from '@nutricontrol/app360-shared';
import { TerminalsLibService } from '../../../../services/libraries/terminals-lib.service';
import { WaterSystemsUtilsService } from './water-systems-utils.service';
import { WaterSystemsService } from './water-systems.service';
import { TerminalValvesDataModel } from '../../../../terminals/terminals.model';
import {
  FertilizerFormulasModel,
  GroupsDataModel,
  IrrigationEquipmentDrainage,
  IrrigationEquipmentModel,
  IrrigationEquipmentProgram,
  IrrigationEquipmentWaterSystem,
  MixtureFormulasModel,
  ProgramModel,
} from '../../../../farming/irrigation/irrigation.model';
import {
  WaterSystems,
  WaterSystem,
  WaterSystemFields,
  WaterSystemField,
  WaterSystemFieldProgress,
  WaterSystemFieldChips,
  WaterSystemFieldDrainageTable,
  WaterSystemFieldProgressType,
  WaterSystemFieldDouble,
  WaterSystemFieldTriple,
  WaterSystemFieldFertilizer,
  WaterSystemFieldFertilizerValue,
} from '../models/water-systems.model';
import { EquipmentsLibService } from '../../../../services/libraries/equipments-lib.service';

@Injectable({
  providedIn: 'root',
})
export class WaterSystemsTransformerService {
  private equipment: IrrigationEquipmentModel;
  private fertilizerFormulas: FertilizerFormulasModel[];
  private groups: GroupsDataModel[];
  private mixtureFormulas: MixtureFormulasModel[];
  private programs: ProgramModel[];
  private valves: TerminalValvesDataModel[];
  private waterSystemsStates: IrrigationEquipmentWaterSystem[];
  private programsStates: IrrigationEquipmentProgram[];
  private drainagesStates: IrrigationEquipmentDrainage[];
  constructor(
    private waterSystemsService: WaterSystemsService,
    private waterSystemsUtilsService: WaterSystemsUtilsService,
    private translationsLibService: TranslationsLibService,
    private terminalsLibService: TerminalsLibService,
    private equipmentLibService: EquipmentsLibService
  ) {}

  public extractWaterSystemsTabs(
    equipment: IrrigationEquipmentModel
  ): WaterSystems {
    this.equipment = equipment;
    this.waterSystemsStates = equipment.states.waterSystems ?? [];
    this.fertilizerFormulas = equipment.fertilizerFormulas;
    this.mixtureFormulas = equipment.mixtureFormulas;
    this.programs = equipment.programs;
    this.programsStates = equipment.states.programs;
    this.drainagesStates = equipment.states.drainages;
    this.groups = this.waterSystemsUtilsService.mutateGroupsForDataModel(
      equipment.groups
    );
    this.valves = equipment.valves;

    const typeClassMap: { [key: string]: string } = {
      IRRIGATION: 'irrigation-water-systems',
      CONFIGURABLE: 'configurable-water-systems',
    };

    const terminalTypeString =
      this.equipmentLibService.getTypeByEquipmentData(equipment);
    return {
      lastUpdate: this.equipment.lastMeasure,
      terminalType: terminalTypeString,
      cardClass: typeClassMap[terminalTypeString] || '',
      tabs: this.getTabs(),
    };
  }

  private getTabs(): WaterSystem[] {
    return this.waterSystemsStates.map((waterSystem): WaterSystem => {
      return {
        order: waterSystem.waterSystem + 1,
        id: waterSystem.waterSystem,
        active: this.waterSystemsService.waterSystemHasActiveProgram(
          waterSystem,
          this.programsStates
        ),
        enabled: this.waterSystemsService.waterSystemIsEnabled(
          waterSystem,
          this.programsStates
        ),
        badgeLabel: this.waterSystemsService.getBadgeLabel(
          waterSystem,
          this.programsStates
        ),
        programState:
          this.waterSystemsService.getWaterSystemProgramState(
            waterSystem,
            this.programsStates
          )?.state ?? '',
        fields: this.getFields(waterSystem),
      };
    });
  }

  private getFields(
    waterSystem: IrrigationEquipmentWaterSystem
  ): WaterSystemFields {
    const program = this.waterSystemsService.getWaterSystemProgramState(
      waterSystem,
      this.programsStates
    );
    const group = this.waterSystemsService.getWaterSystemGroup(
      waterSystem,
      this.programsStates,
      this.groups
    );
    const fertilizerFormula =
      this.waterSystemsService.getWaterSystemFertilizerFormula(
        waterSystem,
        this.fertilizerFormulas
      );
    const mixtureFormula =
      this.waterSystemsService.getWaterSystemMixtureFormula(
        waterSystem,
        this.mixtureFormulas
      );

    return {
      irrigationProgress: this.getIrrigationProgress(program, group),
      program: this.getProgram(program),
      group: this.getGroup(group),
      state: this.getState(program),
      irrigationVolume: this.getIrrigationVolume(group),
      activation: this.getActivation(program),
      flow: this.getFlow(waterSystem),
      valves: this.getValves(group),
      pump: this.getPump(group),
      irrigationTime: this.getIrrigationTime(group),
      groupVolumes: this.getGroupVolumes(group),
      fertilizerFormula: this.getFertilizerFormula(fertilizerFormula),
      idealCE: this.getIdealCE(waterSystem),
      cE: this.getCE(waterSystem),
      idealPH: this.getIdealPH(waterSystem),
      readPH: this.getReadPH(waterSystem),
      mixtureFormula: this.getMixtureFormula(mixtureFormula),
      mixtureValve: this.getMixtureValve(mixtureFormula),
      mixtureValvePosition: this.getMixtureValvePosition(mixtureFormula),
      mixtureIdealCE1: this.getMixtureIdealCE1(mixtureFormula),
      mixtureIdealCE2: this.getMixtureIdealCE2(mixtureFormula),
      waitingList: this.getWaitingList(),
      drainage1: this.getDrainage(1),
      drainage2: this.getDrainage(2),
      drainage3: this.getDrainage(3),
      drainage4: this.getDrainage(4),
      drainage5: this.getDrainage(5),
      drainage6: this.getDrainage(6),
      drainage7: this.getDrainage(7),
      drainage8: this.getDrainage(8),
    };
  }

  private invisibleField(): WaterSystemField {
    return {
      type: 'card',
      label: undefined,
      unit: undefined,
      value: undefined,
      visible: false,
    };
  }

  private invisibleFieldDouble(): WaterSystemFieldDouble {
    return {
      type: 'cardDouble',
      labelLeft: undefined,
      labelRight: undefined,
      unit: undefined,
      valueLeft: undefined,
      valueRight: undefined,
      visible: false,
    };
  }

  private invisibleFieldTriple(): WaterSystemFieldTriple {
    return {
      type: 'cardTriple',
      labelLeft: undefined,
      labelMiddle: undefined,
      labelRight: undefined,
      unit: undefined,
      valueLeft: undefined,
      valueMiddle: undefined,
      valueRight: undefined,
      visible: false,
    };
  }

  private invisibleChipField(): WaterSystemFieldChips {
    return {
      type: 'chips',
      label: undefined,
      values: [],
      visible: false,
    };
  }

  private invisibleDrainageField(): WaterSystemFieldDrainageTable {
    return {
      type: 'drainageTable',
      drainage: 0,
      activations: 0,
      lastDrainage: 0,
      todayDrainage: 0,
      lastIrrigation: 0,
      todayIrrigation: 0,
      lastDrained: 0,
      todayDrained: 0,
      lastPulse: 0,
      todayPulse: 0,
      accumulatedRadiation: 0,
      cutRadiation: 0,
      previousRadiation: 0,
      correcRadiation: 0,
      visible: false,
    };
  }

  private invisibleFertilizerField(): WaterSystemFieldFertilizer {
    return {
      type: 'cardFertilizer',
      label: undefined,
      fertilizerFormula: undefined,
      fertilizers: [],
      visible: false,
    };
  }

  private getIrrigationProgress(
    program: IrrigationEquipmentProgram | undefined,
    group: GroupsDataModel | undefined
  ): WaterSystemFieldProgress {
    if (program === undefined || group === undefined) {
      return {
        type: 'progressBar',
        irrigation: undefined,
        volume: undefined,
        visible: false,
      };
    }

    const irrigationData = this.getProgressIrrigation(program, group);
    const volumeData = this.getProgressVolume(program, group);

    return {
      type: 'progressBar',
      irrigation: irrigationData.totalTime > 0 ? irrigationData : undefined,
      volume: volumeData.totalTime > 0 ? volumeData : undefined,
      visible: irrigationData.totalTime > 0 || volumeData.totalTime > 0,
    };
  }

  private getProgressIrrigation(
    program: IrrigationEquipmentProgram | undefined,
    group: GroupsDataModel | undefined
  ): WaterSystemFieldProgressType | undefined {
    const sortedRanges = [
      group.preIrrigationTime,
      group.preIrrigationTime + group.fertilizationTime,
      program.currentTime,
    ].sort((a, b) => a - b);

    const totalTimeCalc =
      group.preIrrigationTime +
      group.fertilizationTime +
      group.postIrrigationTime;

    return {
      phase: sortedRanges.indexOf(program.currentTime) + 1,
      totalTime: totalTimeCalc,
      currentTime: program.currentTime,
      ranges: sortedRanges,
      timeLabel:
        this.waterSystemsUtilsService.formatSeconds(program.currentTime) +
        ' / ' +
        this.waterSystemsUtilsService.formatSeconds(totalTimeCalc),
    };
  }

  private getProgressVolume(
    program: IrrigationEquipmentProgram | undefined,
    group: GroupsDataModel | undefined
  ): WaterSystemFieldProgressType | undefined {
    const sortedRanges = [
      group.preIrrigationVolume,
      group.preIrrigationVolume + group.fertilizationVolume,
      program.currentVolume,
    ].sort((a, b) => a - b);

    const totalTimeCalc =
      group.preIrrigationVolume +
      group.fertilizationVolume +
      group.postIrrigationVolume;

    return {
      phase: sortedRanges.indexOf(program.currentVolume) + 1,
      totalTime: totalTimeCalc,
      currentTime: program.currentVolume,
      ranges: sortedRanges,
      timeLabel: `${program.currentVolume.toString()} m3 / ${totalTimeCalc.toString()} m3`,
    };
  }

  private getProgram(
    program: IrrigationEquipmentProgram | undefined
  ): WaterSystemField {
    return {
      type: 'card',
      label: this.translationsLibService.get('irrigation_active_program'),
      unit: undefined,
      value: program
        ? program.name
        : this.translationsLibService.get('irrigation_non_active_program'),
      visible: true,
    };
  }

  private getGroup(group: GroupsDataModel | undefined): WaterSystemField {
    if (!group) return this.invisibleField();

    return {
      type: 'card',
      label: this.translationsLibService.get('irrigation_group'),
      unit: undefined,
      value: !group.name
        ? this.translationsLibService.get('irrigation_group') +
          ' ' +
          (group.group + 1)
        : group.name,
      visible: true,
    };
  }

  private getState(
    program: IrrigationEquipmentProgram | undefined
  ): WaterSystemField {
    if (!program) return this.invisibleField();

    return {
      type: 'card',
      label: this.translationsLibService.get('configurable_phase'),
      unit: undefined,
      value: this.translationsLibService.get(
        'irrigation_state_' + program.state.toLowerCase()
      ),
      visible: true,
    };
  }
  private getIrrigationVolume(
    group: GroupsDataModel | undefined
  ): WaterSystemField {
    if (!group) return this.invisibleField();
    const volume =
      group.fertilizationVolume +
      group.postIrrigationVolume +
      group.preIrrigationVolume;

    return {
      type: 'card',
      label: this.translationsLibService.get('irrigation_irrigation_volume'),
      unit: 'm³',
      value: volume.toString(),
      visible: volume > 0,
    };
  }

  private getActivation(
    program: IrrigationEquipmentProgram | undefined
  ): WaterSystemField {
    if (!program) return this.invisibleField();

    return {
      type: 'card',
      label: this.translationsLibService.get('irrigation_program_activation'),
      unit: undefined,
      value: program.activations.toString(),
      visible: true,
    };
  }
  //   flow: t,
  private getFlow(
    waterSystem: IrrigationEquipmentWaterSystem
  ): WaterSystemField {
    return {
      type: 'card',
      label: this.translationsLibService.get('irrigation_ws_flow'),
      unit: 'm³/h',
      value: waterSystem.flow.toString(),
      visible: true,
    };
  }

  private getValves(group: GroupsDataModel | undefined): WaterSystemFieldChips {
    if (!group) return this.invisibleChipField();

    const valvesNames = group.valves.map((groupValve) => {
      const valveNumber = Number(groupValve);
      const valveFound = this.valves.find(
        (valve) => Number(valve.valve) === valveNumber
      );

      return valveFound?.name
        ? valveFound.name
        : this.translationsLibService.get('irrigation_valve') +
            ' ' +
            (groupValve + 1);
    });

    return {
      type: 'chips',
      label: this.translationsLibService.get('irrigation_valves'),
      values: valvesNames,
      visible: true,
    };
  }
  private getPump(group: GroupsDataModel | undefined): WaterSystemField {
    if (!group) {
      return {
        type: 'card',
        label: this.translationsLibService.get('irrigation_pump'),
        unit: undefined,
        value: undefined,
        visible: true,
      };
    }

    const pumps = [group.pump1, group.pump2, group.pump3]
      .filter((pump) => pump !== 255)
      .map((pump) => pump + 1);

    return {
      type: 'card',
      label: this.translationsLibService.get('irrigation_pump'),
      unit: undefined,
      value: pumps.length > 0 ? pumps.join(', ') : undefined,
      visible: true,
    };
  }
  private getIrrigationTime(
    group: GroupsDataModel | undefined
  ): WaterSystemFieldTriple {
    if (!group) return this.invisibleFieldTriple();

    const totalTime =
      group.preIrrigationTime +
      group.fertilizationTime +
      group.postIrrigationTime;

    return {
      type: 'cardTriple',
      labelLeft: this.translationsLibService.get(
        'irrigation_pre_fertilization_time'
      ),
      labelMiddle: this.translationsLibService.get(
        'irrigation_fertilization_time'
      ),
      labelRight: this.translationsLibService.get(
        'irrigation_post_fertilization_time'
      ),
      unit: undefined,
      valueLeft:
        group.preIrrigationTime > 0
          ? this.waterSystemsUtilsService.formatSeconds(group.preIrrigationTime)
          : undefined,
      valueMiddle:
        group.fertilizationTime > 0
          ? this.waterSystemsUtilsService.formatSeconds(group.fertilizationTime)
          : undefined,
      valueRight:
        group.postIrrigationTime > 0
          ? this.waterSystemsUtilsService.formatSeconds(
              group.postIrrigationTime
            )
          : undefined,
      visible: totalTime > 0,
    };
  }
  private getGroupVolumes(
    group: GroupsDataModel | undefined
  ): WaterSystemFieldTriple {
    if (!group) return this.invisibleFieldTriple();

    const totalVolume =
      group.preIrrigationVolume +
      group.fertilizationVolume +
      group.postIrrigationVolume;

    return {
      type: 'cardTriple',
      labelLeft: this.translationsLibService.get('irrigation_pre_volume'),
      labelMiddle: this.translationsLibService.get('irrigation_irrigation'),
      labelRight: this.translationsLibService.get('irrigation_post_volume'),
      unit: undefined,
      valueLeft:
        group.preIrrigationVolume > 0
          ? group.preIrrigationVolume + ' m³'
          : undefined,
      valueMiddle:
        group.fertilizationVolume > 0
          ? group.fertilizationVolume + ' m³'
          : undefined,
      valueRight:
        group.postIrrigationVolume > 0
          ? group.postIrrigationVolume + ' m³'
          : undefined,
      visible: totalVolume > 0,
    };
  }

  private getFertilizerFormula(
    fertilizerFormula: FertilizerFormulasModel | undefined
  ): WaterSystemFieldFertilizer {
    if (!fertilizerFormula) return this.invisibleFertilizerField();

    const fertilizersData: WaterSystemFieldFertilizerValue[] = [];
    for (let fertilizerNumber = 1; fertilizerNumber <= 9; fertilizerNumber++) {
      const fertilizerValue = this.waterSystemsService.fertilizerValue(
        fertilizerFormula.fertilizers[fertilizerNumber - 1]
      );
      if (fertilizerValue !== undefined) {
        fertilizersData.push({
          fertilizer: fertilizerNumber,
          value: fertilizerValue,
        });
      }
    }

    return {
      type: 'cardFertilizer',
      label: this.translationsLibService.get('irrigation_fertilizer_formula'),
      fertilizerFormula:
        fertilizerFormula.name !== null
          ? fertilizerFormula.name
          : this.translationsLibService.get('irrigation_fertilizer_formula') +
            ' ' +
            (fertilizerFormula.formula + 1),
      fertilizers: fertilizersData,
      visible: true,
    };
  }

  private getIdealCE(
    waterSystem: IrrigationEquipmentWaterSystem
  ): WaterSystemField {
    return {
      type: 'card',
      label: this.translationsLibService.get('irrigation_ws_idealCE'),
      unit: 'mS/cm',
      value: waterSystem.idealCE.toString(),
      visible: true,
    };
  }

  private getCE(
    waterSystem: IrrigationEquipmentWaterSystem
  ): WaterSystemFieldDouble {
    return {
      type: 'cardDouble',
      labelLeft: this.translationsLibService.get('irrigation_ws_InEC'),
      labelRight: this.translationsLibService.get('irrigation_ws_OutEC'),
      unit: 'mS/cm',
      valueLeft:
        waterSystem.InEC !== -1 ? waterSystem.InEC.toString() : undefined,
      valueRight:
        waterSystem.OutEC !== -1 ? waterSystem.OutEC.toString() : undefined,
      visible: true,
    };
  }

  private getIdealPH(
    waterSystem: IrrigationEquipmentWaterSystem
  ): WaterSystemField {
    return {
      type: 'card',
      label: this.translationsLibService.get('irrigation_ideal_ph'),
      unit: undefined,
      value: waterSystem.idealPH.toString(),
      visible: true,
    };
  }

  private getReadPH(
    waterSystem: IrrigationEquipmentWaterSystem
  ): WaterSystemField {
    return {
      type: 'card',
      label: this.translationsLibService.get('irrigation_ws_readPH'),
      unit: undefined,
      value:
        waterSystem.readPH !== -1 ? waterSystem.readPH.toString() : undefined,
      visible: true,
    };
  }

  private getMixtureFormula(
    mixtureFormula: MixtureFormulasModel
  ): WaterSystemField {
    if (!mixtureFormula) return this.invisibleField();

    return {
      type: 'card',
      label: this.translationsLibService.get('irrigation_mixture_formula'),
      unit: undefined,
      value:
        this.translationsLibService.get('irrigation_ws_mixtureFormula') +
        ' ' +
        (mixtureFormula.formula + 1),
      visible: true,
    };
  }

  private getMixtureValve(
    mixtureFormula: MixtureFormulasModel
  ): WaterSystemField {
    if (!mixtureFormula) return this.invisibleField();
    const valve = this.valves.find(
      (valve) => Number(valve.valve) === mixtureFormula.valve1
    );
    return {
      type: 'card',
      label: this.translationsLibService.get('irrigation_mixture_valve'),
      unit: undefined,
      value: valve?.name ?? undefined,
      visible: true,
    };
  }

  private getMixtureValvePosition(
    mixtureFormula: MixtureFormulasModel
  ): WaterSystemField {
    if (!mixtureFormula) return this.invisibleField();
    // const valve = this.valves.find(
    //   (valve) => Number(valve.valve) === mixtureFormula.valve1
    // );

    return {
      type: 'card',
      label: this.translationsLibService.get('climate_valvePos'),
      unit: undefined,
      value: '',
      visible: false, // TODO
    };
  }

  private getMixtureIdealCE1(
    mixtureFormula: MixtureFormulasModel
  ): WaterSystemField {
    if (!mixtureFormula) return this.invisibleField();

    return {
      type: 'card',
      label: this.translationsLibService.get('irrigation_ws_idealCE') + ' 1',
      unit: 'mS/cm',
      value: mixtureFormula.idealEC1.toString(),
      visible: true,
    };
  }

  private getMixtureIdealCE2(
    mixtureFormula: MixtureFormulasModel
  ): WaterSystemField {
    if (!mixtureFormula) return this.invisibleField();

    return {
      type: 'card',
      label: this.translationsLibService.get('irrigation_ws_idealCE') + '2',
      unit: 'mS/cm',
      value: mixtureFormula.idealEC2.toString(),
      visible: true,
    };
  }

  private getDrainage(drainageNumber: number): WaterSystemFieldDrainageTable {
    const drainageState = this.drainagesStates.find((drainageState) => {
      return drainageState.drainage === drainageNumber - 1;
    });
    if (!drainageState) return this.invisibleDrainageField();

    return {
      type: 'drainageTable',
      drainage: drainageNumber,
      activations: drainageState.activations,
      lastDrainage: drainageState.lastDrainage,
      todayDrainage: drainageState.todayDrainage,
      lastIrrigation: drainageState.lastIrrigation,
      todayIrrigation: drainageState.todayIrrigated,
      lastDrained: drainageState.lastDrained,
      todayDrained: drainageState.todayDrained,
      lastPulse: drainageState.lastPulse,
      todayPulse: drainageState.todayPulses,
      accumulatedRadiation: drainageState.accumulatedRadiation,
      cutRadiation: drainageState.cutRadiation,
      previousRadiation:
        drainageState.cutRadiation - drainageState.radiationIncrement,
      correcRadiation: drainageState.radiationIncrement,
      visible: true,
    };
  }

  private getWaitingList(): WaterSystemFieldChips {
    const prioritiesMap: { [key: string]: number } = {
      LOWEST: 0,
      LOW: 1,
      MEDIUM: 2,
      HIGH: 3,
      HIGHEST: 4,
      TOTAL: 5,
    };

    const standbyProgramsInfo = this.programsStates
      .filter((programState) => programState.state === 'STANDBY')
      .map((programState) => {
        const program = this.programs.find(
          (program) => program.program === programState.program
        );
        if (!program) {
          return {
            name: programState.name,
            time: undefined,
            priority: undefined,
          };
        }
        return {
          name: programState.name,
          time: program.begin.split(' ')[1],
          priority: prioritiesMap[program.priority],
        };
      });

    if (standbyProgramsInfo.length === 0) {
      return {
        type: 'chips',
        label: this.translationsLibService.get(
          'irrigation_program_waiting_list'
        ),
        values: [],
        visible: false,
      };
    }
    const programsNamesOrdered = [
      ...standbyProgramsInfo
        .filter((program) => program.time)
        .sort((a, b) => {
          const timeComparison = a.time.localeCompare(b.time);
          if (timeComparison === 0) {
            return (b.priority ?? 0) - (a.priority ?? 0);
          }

          return timeComparison;
        })
        .map((program) => `${program.name} ${program.time}`),
      ...standbyProgramsInfo
        .filter((program) => !program.time)
        .map((program) => `${program.name}`),
    ];
    return {
      type: 'chips',
      label: this.translationsLibService.get('irrigation_program_waiting_list'),
      values: programsNamesOrdered,
      visible: true,
    };
  }
}
