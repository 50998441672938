<ng-container *ngIf="alarmRows.length > 0">
  <div class="card" [ngClass]="cardClass">
    <div class="card-body p-2">
      <div class="d-flex justify-content-between align-items-center mb-2">
        <div>
          <span
            class="breadcrumb-item active alarm-grid-separation align-self-center"
            >{{ translationsLib.get('notifications_alarms') }}</span
          >
        </div>
        <div *ngIf="this.type === 'irrigation'">
          <ion-button
            size="small"
            (click)="this.cancelAlarmsEventEmitter.emit()"
          >
            <ion-label>{{
              translationsLib.get('irrigation_cancel_alarms')
            }}</ion-label>
          </ion-button>
        </div>
      </div>

      <div
        class="row g-2 mb-2 terminal-grid custom-container"
        *ngFor="let row of alarmRows; let rowIndex = index"
      >
        <ng-container *ngFor="let field of row | keyvalue; let isLast = last">
          <div
            [ngClass]="
              row.length === 1
                ? 'col-12 col-md-6 col-lg-4 separator-custom'
                : row.length === 2
                ? 'col-12 col-md-6 separator-custom'
                : rowIndex === alarmRows.length - 1 &&
                  isLast &&
                  row.length % 2 !== 0
                ? 'col-12 col-md-12 col-lg-4 separator-custom'
                : row.length === 3
                ? 'col-12 col-md-6 col-lg-4 separator-custom'
                : row.length === 4
                ? 'col-12 col-md-6 col-lg-3 separator-custom'
                : ''
            "
          >
            <div class="data-container">
              <div class="row align-items-center">
                <div class="col text-uppercase data-header">
                  {{ field | alarmGridTitles : type : equipmentResponse }}
                </div>
              </div>
              <div class="row align-items-center">
                <div class="col-9 data-value alarm-title-value">
                  {{ field | alarmGridTag : type }}
                </div>
                <div class="col-3">
                  <div class="data-icon">
                    <i class="bi bi-alarm"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</ng-container>
