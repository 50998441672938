import { Component, Input, OnInit } from '@angular/core';
import { TranslationsLibService } from '@nutricontrol/app360-shared';
import { MegapopComponent } from '../megapop/megapop.component';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-selected-devices',
  templateUrl: './selected-devices.component.html',
  styleUrls: ['./selected-devices.component.scss'],
})
export class SelectedDevicesComponent implements OnInit {
  @Input() viewmode;
  @Input() fieldTerminals;
  backend;
  constructor(
    public translationsLib: TranslationsLibService,
    public megapop: MegapopComponent
  ) {}

  ngOnInit() {
    this.backend = environment.backend;
  }
}
