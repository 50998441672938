import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { Output, EventEmitter } from '@angular/core';
import { TranslationsLibService } from '@nutricontrol/app360-shared';

@Component({
  selector: 'app-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.scss'],
})
export class ProgressBarComponent implements OnInit, OnChanges {
  @Input() progress = 0;
  @Output() newItemEvent = new EventEmitter<void>();
  finished = false;
  constructor(public translationsLib: TranslationsLibService) {}

  ngOnInit() {}
  ngOnChanges(changes: SimpleChanges) {
    if (
      (typeof changes.progress.previousValue === 'string' ||
        changes.progress.previousValue instanceof String) &&
      changes.progress.currentValue === '0'
    ) {
      this.finished = true;
    } else {
      this.finished = false;
    }
  }
  childFunction() {
    this.newItemEvent.emit();
  }
}
