<div class="popup-disabled p-3 p-md-4 configurable-card-border">
  <div class="popup-title"><i class="bi bi-exclamation-triangle-fill"></i> Este equipo ha sido deshabilitado temporalmente </div>
  <div>
    <p class="mt-3 mb-4 text-muted">
      Para volver a visualizar datos de este equipo, es necesario configurar su gestión administrativa.
    </p>
    <div class="text-end">
      <button id="reviseTerminals" type="submit" class="btn btn-primary" (click)="reviseTerminal()">
        {{ translationsLib.get('terminal_check') }}
      </button>
    </div>
  </div>
</div>
