import { Component, Input } from '@angular/core';
import { WaterSystemFieldDrainageTable } from '../../models/water-systems.model';
import { TranslationsLibService } from '@nutricontrol/app360-shared';

@Component({
  selector: 'app-field-drainages-table',
  templateUrl: './field-drainages-table.component.html',
  styleUrls: ['./field-drainages-table.component.scss'],
})
export class FieldDrainagesTableComponent {
  @Input() field: WaterSystemFieldDrainageTable;
  @Input() isMobile = false;

  constructor(protected translationsLib: TranslationsLibService) {
    //
  }
}
