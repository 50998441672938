<div class="mb-4 d-flex justify-content-center mt-5" *ngIf="isFetchingData">
  <div class="spinner-border text-warning" role="status">
    <span class="visually-hidden">{{
      this.translationsLib.get('irrigation_loading')
    }}</span>
  </div>
</div>

<app-dirty-message
  [conditions]="!isFetchingData"
  [dirty]="pumpsForm.value.dirty"
></app-dirty-message>

<ng-container
  *ngIf="mode === 'simple' && !isFetchingData && !isPumpsWithoutData"
>
  <form [formGroup]="pumpsForm" class="simple-form">
    <div class="card">
      <div class="card-body custom-card-body">
        <div class="row align-items-center">
          <div class="col-sm-5 col-md-5 order-1 order-md-1">
            <h6 class="fs-2">{{ translationsLib.get('irrigation_pump') }}</h6>
            <p class="fs-5 fw-lighter">
              {{ translationsLib.get('irrigation_pump_help') | sanitizeText }}
            </p>
          </div>
          <div class="col-sm-7 col-md-7 order-3 order-md-2 col-inputs">
            <div
              class="row first-row align-items-center justify-content-between"
            >
              <!-- Retardo de arranque / Adelanto de arranque -->
              <div class="col-12 col-md-6">
                <div class="input-group mb-2">
                  <select
                    class="form-select"
                    [(ngModel)]="selectedOptionArranque"
                    (change)="updateArranqueVisibility()"
                    [ngModelOptions]="{ standalone: true }"
                  >
                    <option value="1">
                      {{ translationsLib.get('irrigation_pump_start_delay') }}
                    </option>
                    <option value="-1">
                      {{ translationsLib.get('irrigation_pumps_time_s') }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-12 col-md-6">
                <!-- PARADA -->
                <div class="mb-2" *ngIf="arranqueStop">
                  <ng-template #timeToStartTimePicker>
                    <timepicker
                      appOnlyNumbers
                      [showMeridian]="false"
                      [showSeconds]="true"
                      [minuteStep]="1"
                      [secondsStep]="1"
                      [formControlName]="'timeToStart'"
                    ></timepicker>
                  </ng-template>
                  <input
                    appOnlyNumbers
                    [ngClass]="{
                      'ng-dirty': pumpsForm.controls.timeToStart.dirty
                    }"
                    class="form-control text-center"
                    type="text"
                    [popover]="timeToStartTimePicker"
                    [outsideClick]="true"
                    placement="bottom"
                    [value]="pumpsForm.value.timeToStart | inputTime"
                    (change)="
                      onChangeInputTimeWithKeyboard($event, 'timeToStart')
                    "
                  />
                </div>
                <!-- ARRANQUE -->
                <div class="mb-2" *ngIf="arranqueStart">
                  <ng-template #timeToStartTimePicker>
                    <timepicker
                      appOnlyNumbers
                      [showMeridian]="false"
                      [showSeconds]="true"
                      [minuteStep]="1"
                      [secondsStep]="1"
                      [formControlName]="'timeToStart'"
                    ></timepicker>
                  </ng-template>
                  <input
                    appOnlyNumbers
                    [ngClass]="{
                      'ng-dirty': pumpsForm.controls.timeToStart.dirty
                    }"
                    class="form-control text-center"
                    type="text"
                    [popover]="timeToStartTimePicker"
                    [outsideClick]="true"
                    placement="bottom"
                    [value]="pumpsForm.value.timeToStart | inputTime"
                    (change)="
                      onChangeInputTimeWithKeyboard($event, 'timeToStart')
                    "
                  />
                </div>
              </div>
            </div>

            <!-- Retardo de parada / Adelanto de parada -->
            <div class="row align-items-center justify-content-between">
              <div class="col-12 col-md-6">
                <div class="input-group mb-2">
                  <select
                    class="form-select"
                    [(ngModel)]="selectedOptionParada"
                    (change)="updateParadaVisibility()"
                    [ngModelOptions]="{ standalone: true }"
                  >
                    <option value="1">Retardo parada</option>
                    <option value="-1">Adelanto parada</option>
                  </select>
                </div>
              </div>
              <div class="col-12 col-md-6">
                <!-- PARADA -->
                <div class="mb-2" *ngIf="paradaStop">
                  <ng-template #timeToStopTimePicker>
                    <timepicker
                      appOnlyNumbers
                      [showMeridian]="false"
                      [showSeconds]="true"
                      [minuteStep]="1"
                      [secondsStep]="1"
                      [formControlName]="'timeToStop'"
                    ></timepicker>
                  </ng-template>
                  <input
                    appOnlyNumbers
                    [ngClass]="{
                      'ng-dirty': pumpsForm.controls.timeToStop.dirty
                    }"
                    class="form-control text-center"
                    type="text"
                    [popover]="timeToStopTimePicker"
                    [outsideClick]="true"
                    placement="bottom"
                    [value]="pumpsForm.value.timeToStop | inputTime"
                    (change)="
                      onChangeInputTimeWithKeyboard($event, 'timeToStop')
                    "
                  />
                </div>
                <!-- ARRANQUE -->
                <div class="mb-2" *ngIf="paradaStart">
                  <ng-template #timeToStopTimePicker>
                    <timepicker
                      appOnlyNumbers
                      [showMeridian]="false"
                      [showSeconds]="true"
                      [minuteStep]="1"
                      [secondsStep]="1"
                      [formControlName]="'timeToStop'"
                    ></timepicker>
                  </ng-template>
                  <input
                    appOnlyNumbers
                    [ngClass]="{
                      'ng-dirty': pumpsForm.controls.timeToStop.dirty
                    }"
                    class="form-control text-center"
                    type="text"
                    [popover]="timeToStopTimePicker"
                    [outsideClick]="true"
                    placement="bottom"
                    [value]="pumpsForm.value.timeToStop | inputTime"
                    (change)="
                      onChangeInputTimeWithKeyboard($event, 'timeToStop')
                    "
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</ng-container>

<ng-container
  *ngIf="mode === 'advanced' && !isFetchingData && !isPumpsWithoutData"
>
  <form [formGroup]="pumpsForm" class="advanced-form">
    <div class="row">
      <div class="col">
        <div class="card">
          <div class="card-body custom-card-body">
            <div class="row">
              <div class="col-12">
                <h6 class="fs-2 input-label">
                  {{ translationsLib.get('irrigation_pump') }}
                </h6>
              </div>
            </div>

            <!-- Retardo de arranque / Adelanto de arranque -->
            <div class="row align-items-center">
              <div class="col-sm-12 col-md-6 col-lg-6">
                <div class="input-group mb-2">
                  <select
                    class="form-select"
                    [(ngModel)]="selectedOptionArranque"
                    (change)="updateArranqueVisibility()"
                    [ngModelOptions]="{ standalone: true }"
                  >
                    <option value="1">
                      {{ translationsLib.get('irrigation_pump_start_delay') }}
                    </option>
                    <option value="-1">
                      {{ translationsLib.get('irrigation_pumps_time_s') }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-sm-12 col-md-6 col-lg-6">
                <!-- ARRANQUE -->
                <div class="mb-2" *ngIf="arranqueStop">
                  <ng-template #timeToStartTimePicker>
                    <timepicker
                      appOnlyNumbers
                      [showMeridian]="false"
                      [showSeconds]="true"
                      [minuteStep]="1"
                      [secondsStep]="1"
                      [formControlName]="'timeToStart'"
                    ></timepicker>
                  </ng-template>
                  <input
                    appOnlyNumbers
                    [ngClass]="{
                      'ng-dirty': pumpsForm.controls.timeToStart.dirty
                    }"
                    class="form-control text-center"
                    type="text"
                    [popover]="timeToStartTimePicker"
                    [outsideClick]="true"
                    placement="bottom"
                    [value]="pumpsForm.value.timeToStart | inputTime"
                    (change)="
                      onChangeInputTimeWithKeyboard($event, 'timeToStart')
                    "
                  />
                </div>
                <!-- PARADA -->
                <div class="mb-2" *ngIf="arranqueStart">
                  <ng-template #timeToStartTimePicker>
                    <timepicker
                      appOnlyNumbers
                      [showMeridian]="false"
                      [showSeconds]="true"
                      [minuteStep]="1"
                      [secondsStep]="1"
                      [formControlName]="'timeToStart'"
                    ></timepicker>
                  </ng-template>
                  <input
                    appOnlyNumbers
                    [ngClass]="{
                      'ng-dirty': pumpsForm.controls.timeToStart.dirty
                    }"
                    class="form-control text-center"
                    type="text"
                    [popover]="timeToStartTimePicker"
                    [outsideClick]="true"
                    placement="bottom"
                    [value]="pumpsForm.value.timeToStart | inputTime"
                    (change)="
                      onChangeInputTimeWithKeyboard($event, 'timeToStart')
                    "
                  />
                </div>
              </div>
            </div>

            <!-- Retardo de parada / Adelanto de parada -->
            <div class="row align-items-center">
              <div class="col-sm-12 col-md-6 col-lg-6">
                <div class="input-group mb-2">
                  <select
                    class="form-select"
                    [(ngModel)]="selectedOptionParada"
                    (change)="updateParadaVisibility()"
                    [ngModelOptions]="{ standalone: true }"
                  >
                    <option value="1">Retardo parada</option>
                    <option value="-1">Adelanto parada</option>
                  </select>
                </div>
              </div>
              <div class="col-sm-12 col-md-6 col-lg-6">
                <!-- PARADA -->
                <div class="mb-2" *ngIf="paradaStop">
                  <ng-template #timeToStopTimePicker>
                    <timepicker
                      appOnlyNumbers
                      [showMeridian]="false"
                      [showSeconds]="true"
                      [minuteStep]="1"
                      [secondsStep]="1"
                      [formControlName]="'timeToStop'"
                    ></timepicker>
                  </ng-template>
                  <input
                    appOnlyNumbers
                    [ngClass]="{
                      'ng-dirty': pumpsForm.controls.timeToStop.dirty
                    }"
                    class="form-control text-center"
                    type="text"
                    [popover]="timeToStopTimePicker"
                    [outsideClick]="true"
                    placement="bottom"
                    [value]="pumpsForm.value.timeToStop | inputTime"
                    (change)="
                      onChangeInputTimeWithKeyboard($event, 'timeToStop')
                    "
                  />
                </div>
                <!-- ADELANTO -->
                <div class="mb-2" *ngIf="paradaStart">
                  <ng-template #timeToStopTimePicker>
                    <timepicker
                      appOnlyNumbers
                      [showMeridian]="false"
                      [showSeconds]="true"
                      [minuteStep]="1"
                      [secondsStep]="1"
                      [formControlName]="'timeToStop'"
                    ></timepicker>
                  </ng-template>
                  <input
                    appOnlyNumbers
                    [ngClass]="{
                      'ng-dirty': pumpsForm.controls.timeToStop.dirty
                    }"
                    class="form-control text-center"
                    type="text"
                    [popover]="timeToStopTimePicker"
                    [outsideClick]="true"
                    placement="bottom"
                    [value]="pumpsForm.value.timeToStop | inputTime"
                    (change)="
                      onChangeInputTimeWithKeyboard($event, 'timeToStop')
                    "
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</ng-container>
