<div class="container-fluid">
  <div class="row">
    <div class="col-12 p-0">
      <lib-loader *ngIf="loaded === false"></lib-loader>

      <div class="card" *ngIf="loaded === true">
        <div class="card-header p-0">
          <div class="card-header-title">
            <h1 class="text-start">
              {{ translationsLib.get('permission_administrative_management') }}
            </h1>
          </div>
        </div>
        <div class="card-body px-0 px-lg-4">
          <div class="row">
            <div class="col-12 text-start" *ngIf="terminalAdministrativeManagement">
              <div class="row">
                <!-- CLAIM -->
                <ng-container *ngIf="terminalAdministrativeManagement.administrative_management_type === 'none'">
                    <div class="col-12 col-lg-6">
                      <p class="m-0">
                        <b>
                          {{ translationsLib.get('permission_administrative_nobody') }}
                        </b>
                      </p>
                    </div>
                    <div class="col-12 col-lg-6" *ngIf="canClaim === true">
                      <p class="m-0">
                        <b>
                          {{ translationsLib.get('permission_you_can_claim_administrative_management') }}
                        </b>
                      </p>
                      <p class="m-0">
                        <button class="btn btn-sm btn-info" (click)="claim()">
                          {{ translationsLib.get('claim') }}
                        </button>
                      </p>
                    </div>
                </ng-container>

                <!-- SHOW AM -->
                <ng-container *ngIf="terminalAdministrativeManagement.administrative_management_type !== 'none'">
                  <ng-container *ngIf="terminalAdministrativeManagement.administrative_management_type === 'organization'">
                    <div class="col-12 col-lg-6">
                      <b>
                        <ng-container *ngIf="terminalAdministrativeManagement.organization_identity !== null">
                          {{ terminalAdministrativeManagement.organization_identity.name }}
                        </ng-container>
                        <ng-container *ngIf="terminalAdministrativeManagement.organization_identity === null">
                          {{ translationsLib.get('permissions_administrative_management_anonymous_organization') }}
                        </ng-container>
                      </b>
                    </div>
                  </ng-container>

                  <ng-container *ngIf="terminalAdministrativeManagement.administrative_management_type === 'owner'">
                    <div class="col-12 col-lg-6">
                      <b>
                        <ng-container *ngIf="terminalAdministrativeManagement.user_identity !== null">
                          {{ terminalAdministrativeManagement.user_identity.name }} {{ terminalAdministrativeManagement.user_identity.surname }} <br/>
                          <a class="text-muted" style="cursor: pointer;" href="mailto:{{ terminalAdministrativeManagement.user_identity.email }}">{{ terminalAdministrativeManagement.user_identity.email }}</a>
                        </ng-container>
                        <ng-container *ngIf="terminalAdministrativeManagement.user_identity === null">
                          {{ translationsLib.get('permissions_administrative_management_anonymous_user') }}
                        </ng-container>
                      </b>
                    </div>
                  </ng-container>
                </ng-container>

                <!-- TRANSFER TO OWNER -->
                <ng-container *ngIf="canTransferToOwner === true">
                  <div class="col-12 col-lg-6">
                    <p class="m-0">
                      <b>
                        {{ translationsLib.get('permission_you_can_transfer_to_owner_administrative_management') }}
                      </b>
                    </p>
                    <p class="m-0">
                      <button class="btn btn-sm btn-info" (click)="transferToOwner()">
                        {{ translationsLib.get('transfer') }}
                      </button>
                    </p>
                  </div>
                </ng-container>
              </div>
            </div>
            <!-- CLAIM ADMINISTRATIVE MANAGEMENT -->
            <div class="col-12 text-start" *ngIf="!terminalAdministrativeManagement">
                <i>{{ translationsLib.get('no_results') }}</i>
            </div>
            <div class="col-lg-6 col-12 my-2 text-start text-muted">
              <i [innerHTML]="translationsLib.get('permission_administrative_management_help')"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
