<div class="mb-4 d-flex justify-content-center" *ngIf="isFetchingData">
  <div class="spinner-border text-warning" role="status">
    <span class="visually-hidden">{{
      translationsLib.get('irrigation_loading')
    }}</span>
  </div>
</div>
<div *ngIf="!isFetchingData">
  <div class="mb-2">
    <div class="row">
      <div class="input-group">
        <!-- Autocomplete -->
        <form class="custom-autocomplete-form">
          <mat-form-field class="autocomplete-full-width" appearance="fill">
            <!--<mat-label>{{ translationsLib.get('irrigation_group') }}</mat-label>-->
            <input
              type="text"
              placeholder="{{ translationsLib.get('irrigation_select_group') }}"
              matInput
              [formControl]="groupControl"
              [matAutocomplete]="auto"
            />
            <mat-autocomplete
              #auto="matAutocomplete"
              (optionSelected)="onChangePagination($event)"
              [displayWith]="autocompleteDisplayFn"
            >
              <mat-option
                *ngFor="let group of filteredOptions | async"
                [value]="group.group"
              >
                {{
                  group.name
                    ? group.name
                    : translationsLib.get('irrigation_group') +
                      ' ' +
                      (group.group + 1)
                }}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </form>
      </div>
    </div>
  </div>
  <div class="mb-2">
    <app-groups-form
      [isProgramsScreen]="isProgramsScreen"
      *ngIf="group_id !== null"
      [mode]="mode"
      [terminal_vid]="terminal_vid"
      [group_id]="group_id"
      (dirtyEventEmitter)="onChangeGroupDirty($event)"
      (updatedGroupsEventEmitter)="onChangeGroupList($event)"
      (clearIntervalEventEmitter)="handleClearInterval($event)"
    ></app-groups-form>
  </div>
</div>
