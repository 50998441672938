import { ErrorHandler, Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { SessionLibService } from '@nutricontrol/app360-shared';
import { PlatformLibService } from '@nutricontrol/app360-shared';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  constructor(
    public platformLib: PlatformLibService,
    public sessionLib: SessionLibService
  ) {}

  handleError(error: any) {
    const originalError = this._findOriginalError(error);
    const errorInfo = this._extractErrorInfo(originalError);

    if (
      'newrelic' in window &&
      (window.location.href.indexOf('localhost') < 0 ||
        this.platformLib.isNative() === true)
    ) {
      const info = {
        ...errorInfo,
        info_platform_native: this.platformLib.isNative(),
        info_platform_choose: this.platformLib.choosePlatform(),
        info_session_vid: this.sessionLib.getSessionUserVid(),
        info_session_email: this.sessionLib.getSessionEmail(),
        info_session_impersonated: this.sessionLib.getImpersonated(),
        info_window_location: window.location.href,
        info_environment_timestamp: environment.timeStamp,
        info_environment_backend: environment.backend,
        info_environment_frontend: environment.frontend,
        info_environment_production: environment.production,
      };
      // @ts-ignore
      window.newrelic.noticeError(new Error(error.message), info);
      console.log('[GlobalErrorHandler] Error sent to NewRelic');
    }

    console.error('[GlobalErrorHandler]', originalError);
  }

  private _findOriginalError(error: any): any {
    while (error && error.originalError) {
      error = error.originalError;
    }
    return error;
  }

  private _extractErrorInfo(error: any): any {
    let errorMessage =
      'UNDEFINED ERROR AT _extractErrorInfo. Improve GlobalErrorInterceptor to see more details';
    if (typeof error !== 'undefined') {
      errorMessage = error.message || error.toString();
    }
    const errorInfo = {
      message: errorMessage,
      stack: '',
      fileName: '',
      lineNumber: '',
      columnNumber: '',
    };

    if (typeof error === 'object') {
      if (error.stack) {
        const stackLines = error.stack.split('\n');
        if (stackLines.length > 1) {
          const location = stackLines[1].match(/\((.*):(\d+):(\d+)\)/);
          if (location) {
            errorInfo.fileName = location[1];
            errorInfo.lineNumber = location[2];
            errorInfo.columnNumber = location[3];
          }
        }
        errorInfo.stack = error.stack;
      }
    }
    return errorInfo;
  }
}
